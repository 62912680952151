import React, { createContext, useContext, useEffect, useState } from 'react';

const INITIAL_STATE = {
    selectedLanguage: 'en-US',
    setSelectedLanguage: () => {},
    supportedLanguages: [],
    setSupportedLanguages: () => {},
    botId: '',
    setBotId: () => {},
    messages: new Map(),
    setMessages: () => {},
};

export const TARGET_LANGUAGES = [
    { label: 'Arabic', value: 'ar' },
    { label: 'Bulgarian', value: 'bg' },
    { label: 'Cantonese', value: 'yue' },
    { label: 'Chinese (Simplified)', value: 'zh-Hans' },
    { label: 'Croatian', value: 'hr' },
    { label: 'Czech', value: 'cs' },
    { label: 'Danish', value: 'da' },
    { label: 'Dutch', value: 'nl' },
    { label: 'English', value: 'en' },
    { label: 'Estonian', value: 'et' },
    { label: 'Finnish', value: 'fi' },
    { label: 'French', value: 'fr' },
    { label: 'German', value: 'de' },
    { label: 'Greek', value: 'el' },
    { label: 'Hebrew', value: 'he' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Hungarian', value: 'hu' },
    { label: 'Icelandic', value: 'is' },
    { label: 'Indonesian', value: 'id' },
    { label: 'Italian', value: 'it' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Korean', value: 'ko' },
    { label: 'Latvian', value: 'lv' },
    { label: 'Lithuanian', value: 'lt' },
    { label: 'Malay', value: 'ms' },
    { label: 'Norwegian', value: 'nb' },
    { label: 'Persian', value: 'fa' },
    { label: 'Polish', value: 'pl' },
    { label: 'Portuguese', value: 'pt' },
    { label: 'Romanian', value: 'ro' },
    { label: 'Russian', value: 'ru' },
    { label: 'Slovak', value: 'sk' },
    { label: 'Slovenian', value: 'sl' },
    { label: 'Spanish', value: 'es' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Thai', value: 'th' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Ukrainian', value: 'uk' },
    { label: 'Vietnamese', value: 'vi' },
];

export const SPEECH_RECOGNITION_LANGUAGES = [
    { label: 'German (Germany)', value: 'de-DE' },
    { label: 'English', value: 'en-US' },
    { label: 'Spanish', value: 'es-ES' },
    { label: 'French', value: 'fr-FR' },
    { label: 'Italian', value: 'it-IT' },
    { label: 'Japanese', value: 'ja-JP' },
    { label: 'Dutch', value: 'nl-NL' },
    { label: 'Portuguese', value: 'pt-PT' },
    { label: 'Chinese (Simplified)', value: 'zh-CN' },
];

const TranslationsStoreContext = createContext(INITIAL_STATE);

export const TranslationsStoreProvider = ({ children }) => {
    const [supportedLanguages, setSupportedLanguages] = useState(TARGET_LANGUAGES);
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [messages, setMessages] = useState(new Map());

    const [previousLanguages, setPreviousLanguages] = useState([]);

    useEffect(() => {
        setPreviousLanguages(val => [selectedLanguage, ...val]);
    }, [selectedLanguage]);

    const value = {
        supportedLanguages,
        setSupportedLanguages,
        selectedLanguage,
        setSelectedLanguage,
        messages,
        setMessages,
        previousLanguages,
    };

    return (
        <TranslationsStoreContext.Provider value={value}>
            {' '}
            {children}{' '}
        </TranslationsStoreContext.Provider>
    );
};

export const useTranslationsStore = () => useContext(TranslationsStoreContext);

import { eurekaRequest } from '../../../services/api/eureka';

export const isUserBlockedBySessionIdAndUserId = (sessionId, userId) => {
    if (!sessionId || !userId) {
        return new Promise(resolve => {
            resolve({ blocked: false });
        });
    }

    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/virtualEventBlockedUsers/session/${sessionId}/user/${userId}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

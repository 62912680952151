import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { navigateTo } from '../../../components/Navigation/NavigationLink';
import { TimezoneContext } from '../../Timezone/context';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 16px;
    ${props =>
        props.isFaded &&
        `
        & > * {
            opacity: 0.38;
        }
   `};

    @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
    }
`;

const StartingTime = styled.div`
    width: 90px;
    font-family: ${props => (props.bold ? 'Cabin' : 'Roboto')}, sans-serif;
    font-size: ${props => (props.bold ? 15 : 17)}px;
    font-weight: ${props => (props.bold ? 700 : 400)};
    line-height: ${props => (props.bold ? 20 : 24)}px;
    color: #1e1e1f;
    text-align: center;
    padding-bottom: 8px;
`;

const SessionNameContainer = styled.div`
    width: 100%;
`;

const Timezone = styled.span`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: rgba(30, 30, 31, 0.38);
    cursor: pointer;
`;

const SessionName = styled.div`
    font-family: Cabin, sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
`;

const SessionSubtitle = styled.div`
    font-family: ${props => (props.bold ? 'Cabin' : 'Roboto')}, sans-serif;
    font-weight: ${props => (props.bold ? 700 : 400)};
    font-size: ${props => (props.bold ? 15 : 14)}px;
    line-height: ${props => (props.bold ? 20 : 24)}px;
    color: rgba(30, 30, 30, 0.54);
`;

const SessionUserRole = styled(SessionSubtitle)`
    width: 206px;
    color: rgba(30, 30, 30, 1);

    @media (max-width: 767px) {
        display: none;
    }
`;

const LinkStyled = styled.button`
    text-decoration: none !important;
    list-style: none;
    color: #1e1e1f;
    cursor: pointer;
    border: none;
    background: transparent;
    text-align: left;
    &:hover {
        opacity: 0.85;
        background-color: rgba(230, 230, 230, 0.15);
    }
`;

const New = styled.span`
    padding: 4px;
    margin-left: 8px;
    font-size: 10px;
    font-weight: 700;
    background-color: red;
    color: white;
    align-self: flex-start;
`;

const SessionOption = ({
    isListTitle,
    session,
    event,
    onSelectSession,
    match,
    history,
    location,
    isSociety,
}) => {
    const [displayObject, setDisplayObject] = useState({});
    const {
        getTimezoneLabel,
        showTimezoneSelectDialog,
        getEventTimezoneLabel,
        getUtcToSelectedTimezone,
        selectedTimezone,
    } = useContext(TimezoneContext);

    useEffect(() => {
        if (!session) {
            return;
        }
        const { object, actions } = session || { object: {}, actions: [] };
        let obj = {
            ...object,
        };
        let dur = '';
        let sub = '';
        if (obj.start) {
            if (obj && obj.start && obj.end) {
                const startTime = moment(obj.start);
                const endTime = moment(obj.end);
                const duration = moment.duration(endTime.diff(startTime));
                const total = duration.asMinutes();
                const hours = parseInt(total / 60);
                const minutes = total % 60;
                dur = (hours ? hours + 'h' : '') + (minutes ? ' ' + minutes + 'm' : '');
                if (isSociety) {
                    let startMoment = moment.utc(obj.start);
                    if (event?.hasStudioReleases) {
                        startMoment = startMoment.tz(event?.timezone);
                    }
                    obj.startTime = startMoment.format('HH:mm');
                } else {
                    obj.startTime = getUtcToSelectedTimezone(obj.start, 'HH:mm');
                }
            }
        } else {
            obj.startTime = '';
        }

        if (obj && obj.subNameList) {
            sub = obj.subNameList;
        }

        obj.subtitle = `${dur}${sub ? (dur ? ' | ' : '') + sub : ''}`;
        let role;
        const roles = {
            ask: 'Vote and Ask Moderator',
            moderator: 'Moderator',
            presenter: 'Speaker',
            roundTableHost: 'Presenter',
        };

        Object.keys(roles).forEach(key => {
            if (actions.includes(key)) {
                role = roles[key];
            }
        });
        obj.role = role;

        setDisplayObject(obj);
    }, [session, selectedTimezone]);

    const isFaded = false;

    if (isListTitle) {
        return (
            <Wrapper smallerHeight={isListTitle ? 1 : 0}>
                <StartingTime bold>
                    Time <br></br>
                    <Timezone onClick={isSociety ? () => {} : showTimezoneSelectDialog}>
                        {isSociety ? getEventTimezoneLabel(event?.timezone) : getTimezoneLabel()}
                    </Timezone>
                </StartingTime>
                <SessionNameContainer>
                    <SessionName>Session name</SessionName>
                </SessionNameContainer>
                <SessionUserRole bold>Role</SessionUserRole>
            </Wrapper>
        );
    } else if (!displayObject.role) {
        return null;
    }

    const onClick = () => {
        onSelectSession(displayObject);
        navigateTo({
            match,
            history,
            location,
            path: `/${
                displayObject.role === 'Vote and Ask Moderator'
                    ? 'qa-moderation'
                    : 'virtual-moderation'
            }/${displayObject.reference}${event._id ? '/' + event._id : ''}`,
        });
    };

    return (
        <LinkStyled onClick={onClick}>
            <Wrapper isFaded={isFaded ? 1 : 0}>
                <StartingTime>{displayObject.startTime || ''}</StartingTime>
                <SessionNameContainer>
                    <SessionName>
                        {displayObject.title}
                        {displayObject.isNew && <New>New</New>}
                    </SessionName>
                    <SessionSubtitle>{displayObject.subtitle}</SessionSubtitle>
                </SessionNameContainer>
                <SessionUserRole>{displayObject.role}</SessionUserRole>
            </Wrapper>
        </LinkStyled>
    );
};

export default withRouter(SessionOption);

import {
    GET_UNREAD_NOTIFICATIONS,
    FETCH_NOTIFICATIONS,
    INCREASE_UNREAD_NOTIFICATIONS_COUNT,
} from '../actions';

const INITIAL_STATE = {
    unreadNotifications: null,
    unreadNotificationsCount: 0,
    unreadChatNotifications: 0,
    fetchingUnreadNotifications: false,
    notifications: [],
    fetchingNotifications: false,
};

const notificationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_UNREAD_NOTIFICATIONS.REQUEST:
            return {
                ...state,
                fetchingUnreadNotifications: true,
            };
        case GET_UNREAD_NOTIFICATIONS.FAILURE:
            return {
                ...state,
                fetchingUnreadNotifications: false,
            };
        case GET_UNREAD_NOTIFICATIONS.SUCCESS:
            const { unreadNotifications } = action.payload;
            const unreadNotificationsCount = unreadNotifications.total - unreadNotifications.chat;
            const unreadChatNotificationsCount = unreadNotifications.chat;

            return {
                ...state,
                fetchingUnreadNotifications: false,
                unreadNotifications,
                unreadNotificationsCount,
                unreadChatNotificationsCount,
            };
        case FETCH_NOTIFICATIONS.REQUEST:
            return {
                ...state,
                fetchingNotifications: true,
            };
        case FETCH_NOTIFICATIONS.FAILURE:
            return {
                ...state,
                fetchingNotifications: false,
            };
        case FETCH_NOTIFICATIONS.SUCCESS:
            return {
                ...state,
                fetchingNotifications: false,
                notifications: action.payload.notifications,
            };
        case INCREASE_UNREAD_NOTIFICATIONS_COUNT:
            return {
                ...state,
                unreadNotificationsCount: state.unreadNotificationsCount + 1,
            };
        default:
            return state;
    }
};

export default notificationReducer;

import React, { useCallback } from 'react';
import {
    SPEECH_RECOGNITION_LANGUAGES,
    useTranslationsStore,
} from '../../../../../../../../stores/TranslationsStore';
import { FormControl, MenuItem, Select, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    selectRoot: {
        padding: 12,
    },
    base: {
        borderRadius: 8,
    },
}));

function LanguageSelect({ value, onChange, id, useSpeechRecognitionLanguages = false }) {
    const classes = useStyles();
    const { supportedLanguages, setSelectedLanguage, selectedLanguage } = useTranslationsStore();

    const onChangeLanguage = useCallback(
        event => {
            if (onChange) {
                return onChange(event.target.value);
            }

            setSelectedLanguage(event.target.value);
        },
        [setSelectedLanguage, onChange],
    );

    // This is a hack to make sure that we use the correct language for setting speech recognition language because the list is different from the list of supported languages
    const languagesToDisplay = useSpeechRecognitionLanguages
        ? SPEECH_RECOGNITION_LANGUAGES
        : supportedLanguages;

    return (
        <>
            <FormControl className={classes.formControl}>
                <Select
                    labelId={`byrdhouse-language-select-label${id ? '-' + id : ''}`}
                    id={`byrdhouse-language-select-label${id ? '-' + id : ''}`}
                    value={value || selectedLanguage}
                    sx={{
                        '& .MuiSelect-select': {
                            padding: '9px 14px',
                        },
                    }}
                    onChange={onChangeLanguage}
                    className={classes.base}
                    classes={{
                        root: classes.selectRoot,
                    }}
                    variant="filled"
                    disableUnderline
                >
                    {languagesToDisplay.map(language => (
                        <MenuItem key={language.value} value={language.value}>
                            {language.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}
export default LanguageSelect;

import React from 'react';
import { Button, DialogContainer } from 'react-md';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    color: #44bb90 !important;
`;

const WarningDialog = ({ open, title, content, onClose }) => {
    return (
        <DialogContainer
            id="simple-confirmation-dialog"
            visible={!!open}
            title={title}
            onHide={onClose}
            actions={[
                <StyledButton flat onClick={onClose}>
                    OK
                </StyledButton>,
            ]}
        >
            {content}
        </DialogContainer>
    );
};

export default WarningDialog;

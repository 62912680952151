import React from 'react';
import TextField from 'react-md/lib/TextFields';
import styled from 'styled-components';
import { useTheme } from '../Theme/ThemeContext';

const StyledTextField = styled(TextField)`
    .md-text-field {
        font-size: 24px;
        font-weight: 500;
    }

    .md-divider--text-field::after {
        background: ${props => props.color};
    }

    .md-text--error {
        color: #f52a40;
    }
`;

const UnderlinedTextInput = ({ value, onChange, placeholder, name, error }) => {
    const { theme } = useTheme();

    return (
        <StyledTextField
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            color={theme.primary}
            error={!!error}
            errorText={error}
        />
    );
};

export default UnderlinedTextInput;

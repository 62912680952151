import React from 'react';
import Auth from '../../../services/api/auth';
import { ButtonsContainer, Card, CloseCardIcon, Subtitle, Title } from '../style/signIn';
import Button, { buttonTypes } from '../../common/Button';
import { getString } from '../../../services/api/store';
import { Flex } from '../../common/Flex';

const UnauthorizedGroupContent = ({ theme, isModal, onClose }) => {
    let title =
        getString('attendeesOnlySigninSignoutTitle') || 'Signing in restricted to attendees';
    let description = getString(
        'attendeesOnlySigninSignoutSubtitle',
        'The email address you specified is not allowed to sign in in this event. You will need to sign out before you can access the event.',
    );
    let label = getString('attendeesOnlySigninSignoutConfirmButton', 'Sign out');

    const onClick = () => {
        Auth.signOut();
        onClose();
    };

    return (
        <Card customHeight={'240px'} isModal={isModal}>
            <Flex alignItems={'center'} justifyContent={'center'}>
                <Title>{title}</Title>
            </Flex>
            <Subtitle>{description}</Subtitle>
            <ButtonsContainer primarycolor={theme.primary}>
                <Button
                    type={buttonTypes.GREEN_LONG}
                    background={theme.primary}
                    text={label}
                    onClick={onClick}
                    data-qa="button-sign-in-with-another-account"
                />
            </ButtonsContainer>
        </Card>
    );
};

export default UnauthorizedGroupContent;

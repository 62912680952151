import React from 'react';
import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import styled from 'styled-components';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import { useTheme } from '../../../../components/Theme/ThemeContext';
import entities from '../../constants/entities';

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;

const IconButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    color: ${props => (props.selected ? props.color : 'rgba(0, 0, 0, 0.6)')};
    border-radius: 20px;
    cursor: pointer;
    ${props => !props.nomarginright && 'margin-right: 8px;'}

    &:hover {
        background-color: ${props => props.color}1F;
        color: ${props => props.color};
    }
`;

const RightSectionFooter = () => {
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();
    const { theme } = useTheme();
    const { roundTableMode } = stateCtx;
    const isAttendeesMode = roundTableMode === entities.hostedSessionMode.attendees;
    const isChatMode = roundTableMode === entities.hostedSessionMode.chat;

    const onChangeHostMode = mode => {
        if (mode === roundTableMode) {
            return;
        }

        mutationCtx.setRoundTableMode(mode);
    };

    return (
        <ButtonsContainer>
            <IconButtonWrapper
                onClick={() => onChangeHostMode(entities.hostedSessionMode.attendees)}
                selected={isAttendeesMode ? 1 : 0}
                color={theme.primary}
            >
                {isAttendeesMode ? <PeopleIcon /> : <PeopleOutlinedIcon />}
            </IconButtonWrapper>
            <IconButtonWrapper
                onClick={() => onChangeHostMode(entities.hostedSessionMode.chat)}
                selected={isChatMode ? 1 : 0}
                color={theme.primary}
            >
                {isChatMode ? <ChatBubbleIcon /> : <ChatBubbleOutlineIcon />}
            </IconButtonWrapper>
        </ButtonsContainer>
    );
};

export default RightSectionFooter;

import axios from 'axios';
import { stringify } from 'flatted';
import { eurekaRequest, asyncEurekaRequest } from '../../../../../services/api/eureka';
import { getLocalAppStateAsync } from '../../../../../services/api/db';

// create: sessionId => $http.post(eventUrl, { sessionId }),
//         disableActiveItems: sessionId => $http.post(`${eventUrl}/session/${sessionId}/disableActiveItems`),
//         getBySession: sessionId => $http.get(`${eventUrl}/session/${sessionId}`),
//         getPlaceHolderImage: data => $http.post(`${eventUrl}/getPlaceHolderImage`, data),
//         updateSession: (sessionId, data) => $http.put(`${eventUrl}/session/${sessionId}`, data),
//         // recording
//         queryRecordingStatus: (sessionId, data) => $http.get(`${eventUrl}/session/${sessionId}/queryRecordingStatus`),
//         queryStreamUid: (sessionId, uid) => $http.get(`${eventUrl}/session/${sessionId}/queryStreamUid/${uid}`),
//         startRecording: (sessionId, data) => $http.post(`${eventUrl}/session/${sessionId}/startRecording`, data),
//         stopRecording: (sessionId, data) => $http.post(`${eventUrl}/session/${sessionId}/stopRecording`, data),
//         updateRecording: (sessionId, data) => $http.post(`${eventUrl}/session/${sessionId}/updateRecording`, data),
//         // play prerecording
//         playPreRecordings: sessionId => $http.post(`${eventUrl}/session/${sessionId}/playPreRecordings`),
//         stopPreRecordings: sessionId => $http.post(`${eventUrl}/session/${sessionId}/stopPreRecordings`),
//         removePreRecordings: (sessionId, userId) =>
//             $http.delete(`${eventUrl}/session/${sessionId}/removePreRecordings/${userId}`),
//         // uploaded video urls
//         createRTMPUrl: (sessionId, data) => $http.post(`${eventUrl}/session/${sessionId}/createRTMPUrl`, data),
//         addVideos: (sessionId, data) => $http.post(`${eventUrl}/session/${sessionId}/videos`, data),
//         deleteVideos: (id, sessionId, data) => $http.delete(`${eventUrl}/session/${sessionId}/videos/${id}`, data),
//         reorderVideos: (sessionId, data) => $http.post(`${eventUrl}/session/${sessionId}/videos/reorder`, data),
//         // peers
//         getPeerList: sessionId => $http.get(`${eventUrl}/session/${sessionId}/getPeerList`),

// Extrnal objects
export const getExternalObjectById = id => {
    return new Promise((resolve, reject) => {
        eurekaRequest('get', `/api/entities/externalObjects/${id}`, null, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

// Virtual event sessions
export const getPlaceHolderImage = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventSession/getPlaceHolderImage`,
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const getBySession = sessionId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/virtualEventSession/session/${sessionId}`,
            null,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};
export const getPeerList = sessionId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/virtualEventSession/session/${sessionId}/getPeerList`,
            null,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const addVideos = (sessionId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventSession/session/${sessionId}/videos`,
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};
export const deleteVideos = (id, sessionId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'delete',
            `/api/entities/virtualEventSession/session/${sessionId}/videos/${id}`,
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};
export const queryRecordingStatus = sessionId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/virtualEventSession/session/${sessionId}/queryRecordingStatus`,
            null,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};
export const updateRecording = (sessionId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventSession/session/${sessionId}/updateRecording`,
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const stopRecording = (sessionId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventSession/session/${sessionId}/stopRecording`,
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const uploadVideoToConfigurator = (sessionId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventSession/session/${sessionId}/sendUploadedVideoToConfigurator`,
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const stopAudioRecording = (sessionId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventSession/session/${sessionId}/stopAudioRecording`,
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const startRecording = (sessionId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventSession/session/${sessionId}/startRecording`,
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const startAudioRecording = (sessionId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventSession/session/${sessionId}/startAudioRecording`,
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const playPreRecordings = sessionId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventSession/session/${sessionId}/playPreRecordings`,
            {},
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const stopPreRecordings = sessionId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventSession/session/${sessionId}/stopPreRecordings`,
            {},
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const removePreRecordings = (sessionId, userId) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'delete',
            `/api/entities/virtualEventSession/session/${sessionId}/removePreRecordings/${userId}`,
            {},
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const createRTMPUrl = (sessionId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventSession/session/${sessionId}/createRTMPUrl`,
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const createRtmpPlayer = (sessionId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventSession/session/${sessionId}/createRtmpPlayer`,
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

// Virtual event token
export const createVirtualEventToken = sessionId => {
    return new Promise((resolve, reject) => {
        const data = { sessionId, role: 'SUBSCRIBER' };
        eurekaRequest('post', `/api/entities/virtualEventToken`, data, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

// Virtual event user
// getBySession: sessionId => $http.get(`${eventUrl}/session/${sessionId}`),
//         create: data => $http.post(eventUrl, data),
//         update: (virtualEventUserId, data) => $http.put(`${eventUrl}/${virtualEventUserId}`, data),
//         reorderPreRecordedPresentation: data => $http.post(`${eventUrl}/reorderPreRecordedPresentation`, data),

export const createVirtualEventUser = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest('post', `/api/entities/virtualEventUser`, data, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

export const updateVirtualEventUser = (virtualEventUserId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventUser/${virtualEventUserId}`,
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

//Others

export const logAppState = async (data, sessionId) => {
    let result = true;

    try {
        // removing circular dependencies
        const parsedData = stringify(data.info);

        const signedLinkResponse = await asyncEurekaRequest(
            'post',
            `/api/entities/virtualSessionLog/${sessionId}`,
            {
                data: parsedData,
                reason: data.reason,
            },
            true,
        );

        if (signedLinkResponse.status !== 200) {
            result = false;
        }
    } catch (err) {
        console.log(err);
        result = false;
    }

    return result;
};

export const uploadPollImagePlaceholderToS3 = async (file, sessionId, pollSetId, timestamp) => {
    try {
        const signedLinkResponse = await asyncEurekaRequest(
            'post',
            '/api/entities/virtualEventSession/getUploadPollImageSignedLink',
            { filename: file.name, sessionId, pollSetId, timestamp },
            true,
        );

        await axios.put(signedLinkResponse.url, file, {
            headers: { 'Content-Type': file.type },
        });

        return signedLinkResponse;
    } catch (error) {
        //handle error
    }
};

export const uploadVideoToS3 = async (file, sessionId, videoType, onProgress) => {
    try {
        const signedLinkResponse = await asyncEurekaRequest(
            'post',
            '/api/entities/virtualEventSession/getUploadVideoSignedLink',
            { filename: file.name, sessionId, videoType },
            true,
        );

        await axios.put(signedLinkResponse.url, file, {
            headers: { 'Content-Type': file.type },
            onUploadProgress: event => {
                const { loaded, total } = event;

                const percentage = (loaded * 100) / total;
                if (onProgress) {
                    onProgress(percentage);
                }
            },
        });

        return signedLinkResponse;
    } catch (error) {
        //handle error
    }
};

export const uploadPlaceholderToS3 = async (file, sessionId) => {
    try {
        const signedLinkResponse = await asyncEurekaRequest(
            'post',
            '/api/entities/virtualEventSession/getUploadSignedLink',
            { filename: file.name, sessionId },
            true,
        );

        await axios.put(signedLinkResponse.url, file, {
            headers: { 'Content-Type': file.type },
        });

        return signedLinkResponse;
    } catch (error) {
        //handle error
    }
};

export const uploadPresentationToS3 = async (file, sessionId) => {
    try {
        const signedLinkResponse = await asyncEurekaRequest(
            'post',
            '/api/entities/virtualEventUser/getUploadSignedLink',
            { filename: file.name, sessionId },
            true,
        );

        await axios.put(signedLinkResponse.url, file, {
            headers: { 'Content-Type': file.type },
        });

        return signedLinkResponse;
    } catch (error) {
        //handle error
    }
};

export const deleteRtmpPlayer = async (sessionId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/virtualEventSession/session/${sessionId}/deleteRtmpPlayer`,
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const overwritePdfInConfigurator = async data => {
    const config = await getLocalAppStateAsync();
    const url = config.configuratorUrl + '/api/data/content/updatePdf';
    const response = await axios.post(url, data);
    return response;
};

// uploadToS3: function (signedLinkUrl, file, type, extra, getViewLink) {
//     var me = this;
//     var link = '';
//     var deferred = $q.defer();
//     var data = { filename: file.name, type: type };
//     if (extra) {
//         data = merge(data, extra);
//     }
//     $http
//         .post(signedLinkUrl, data)
//         .then(function (response) {
//             link = response.data.link;
//             return $http.put(response.data.url, file, { headers: { 'Content-Type': file.type } });
//         })
//         .then(function () {
//             if (getViewLink) {
//                 return me.getLinkToS3File(data.filename);
//             }
//
//             deferred.resolve(link);
//         })
//         .then(function (url) {
//             deferred.resolve(url);
//         })
//         .catch(function (err) {
//             deferred.reject(err);
//         });
//     return deferred.promise;
// },
// getFileFromS3: async key => {
//     const response = await $http.post('/api/entities/virtualEventSession/getS3File', { key: key });
//
//     return response.data.body;
// },
// uploadProfileImageToS3: function (file, type, extra) {
//     return this.uploadToS3('/api/entities/users/getUploadSignedLink', file, type, extra);
// },
// uploadPresentationToS3: async (file, sessionId) => {
//     const signedLinkResponse = await $http.post('/api/entities/virtualEventUser/getUploadSignedLink', {
//         filename: file.name,
//         sessionId,
//     });
//     const s3UploadResponse = await $http.put(signedLinkResponse.data.url, file, {
//         headers: { 'Content-Type': file.type },
//     });
//
//     if (s3UploadResponse.status !== 200) {
//         // handle error
//     }
//
//     return signedLinkResponse.data;
// },
// uploadPlaceholderToS3: async (file, sessionId) => {
//     const signedLinkResponse = await $http.post('/api/entities/virtualEventSession/getUploadSignedLink', {
//         filename: file.name,
//         sessionId,
//     });
//     const s3UploadResponse = await $http.put(signedLinkResponse.data.url, file, {
//         headers: { 'Content-Type': file.type },
//     });
//
//     if (s3UploadResponse.status !== 200) {
//         // handle error
//     }
//
//     return signedLinkResponse.data;
// },
// uploadPollImagePlaceholderToS3: async (file, sessionId, pollSetId, timestamp) => {
//     const signedLinkResponse = await $http.post(
//         '/api/entities/virtualEventSession/getUploadPollImageSignedLink',
//         { filename: file.name, sessionId, pollSetId, timestamp },
//     );
//     const s3UploadResponse = await $http.put(signedLinkResponse.data.url, file, {
//         headers: { 'Content-Type': file.type },
//     });
//
//     if (s3UploadResponse.status !== 200) {
//         // handle error
//     }
//
//     return signedLinkResponse.data;
// },
// uploadVideoToS3: async (file, sessionId, videoType, onProgress) => {
//     const signedLinkResponse = await $http.post('/api/entities/virtualEventSession/getUploadVideoSignedLink', {
//         filename: file.name,
//         sessionId,
//         videoType,
//     });
//     const s3UploadResponse = await $http.put(signedLinkResponse.data.url, file, {
//         headers: { 'Content-Type': file.type },
//         uploadEventHandlers: {
//             progress: function (event) {
//                 const { loaded, total } = event;
//
//                 const percentage = (loaded * 100) / total;
//                 if (onProgress) {
//                     onProgress(percentage);
//                 }
//             },
//         },
//     });
//
//     if (s3UploadResponse.status !== 200) {
//         // handle error
//     }
//
//     return signedLinkResponse.data;
// },

/*
INIT: ensure Babel/Eslint/Flow is configured for ES Class Fields & Static Properties
JSX USAGE: <Iframe src='http://web.site' onLoad={myOnloadFunction}/>
*/
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { checkCCLinks } from '../../helper/CCLink';
import { withRouter } from 'react-router-dom';

const IframeContainer = styled.div`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: ${props => window.innerHeight - 64}px;
`;

const iframeStyle = {
    width: '100%',
    height: '100%',
    border: '0',
    display: 'block',
};

const Iframe = ({ html, match, history, location }) => {
    const iframe = useRef();
    useEffect(() => {
        if (html && iframe.current) {
            const frame = iframe.current;
            frame.contentWindow.document.open();
            frame.contentWindow.document.write(html);
            frame.contentWindow.document.close();

            checkCCLinks(frame.contentDocument, match, history, location);
        }
    }, [html, iframe.current]);

    return (
        <IframeContainer>
            <iframe
                title="webpage"
                ref={iframe}
                frameBorder={'0'}
                width={'100%'}
                height={window.innerHeight + 'px'}
                style={iframeStyle}
            />
        </IframeContainer>
    );
};

export default withRouter(Iframe);

import React from 'react';
import NotificationsCenter from './NotificationsCenter';
import MyConversations from '../../MyConversationsPage';
import { default as Store } from '../../../services/api/store';

const Notifications = ({ isSociety, light }) => {
    const networkingEnabled = Store.argument && Store.argument.app.enableChat;

    return (
        <>
            <NotificationsCenter isSociety={isSociety} light={light} />
            {networkingEnabled && <MyConversations isSociety={isSociety} light={light} />}
        </>
    );
};

export default Notifications;

import get from 'lodash/get';
import Auth from '../../../services/api/auth';

export const selectAllPosts = state => {
    const { posts } = state.socialFeed;
    return Object.keys(posts);
};

export const selectPostById = (id, state) => {
    const you = Auth.getUser() || {};
    const post = get(state, `socialFeed.posts.${id}`, {});
    if (post && post.id) {
        const user = get(state, `socialFeed.users.${post.User}`, {});
        if (user.id === you.id) {
            post.isMine = true;
        }
        return {
            ...post,
            user,
        };
    }

    return {
        comments: [],
        likes: [],
        user: {},
    };
};

export const selectLikeUsersByPostId = (id, state) => {
    const youLabel = 'You';
    const andLabel = 'and';
    const otherLabel = 'other';
    const otherPluralLabel = 'others';

    const post = get(state, `socialFeed.posts.${id}`, {});
    if (post && post.id) {
        const you = Auth.getUser() || {};
        const personNames = [];
        let yourLikeId = 0;
        const users = post.likes.map(likeId => {
            const like = get(state, `socialFeed.likes.${likeId}`, {});
            const user = get(state, `socialFeed.users.${like.User}`, {});
            const isYourLike = user.id === you.id;
            if (isYourLike) {
                yourLikeId = likeId;
                personNames.unshift(youLabel);
            } else {
                personNames.push(`${user.firstName} ${user.lastName}`);
            }
            return user;
        });
        let label = '';
        let more = 0;
        if (personNames.length === 1) {
            label = `${personNames[0]}`;
        } else if (personNames.length > 1) {
            more = personNames.length === 2 ? 0 : personNames.length - 2;
            label = `${personNames[0]}${more ? ', ' : ` ${andLabel} `}${personNames[1]}${
                more ? ` ${andLabel} ${more} ${more > 1 ? otherPluralLabel : otherLabel}` : ''
            }`;
        }

        return {
            users,
            label,
            yourLikeId,
        };
    }
    return {};
};

export const selectCommentById = (id, state) => {
    const comment = get(state, `socialFeed.comments.${id}`, {});
    comment.user = get(state, `socialFeed.users.${comment.User}`, {});
    return comment;
};

export const getPost = (id, state) => {
    if (!id) {
        return {};
    }
    const post = get(state, `socialFeed.posts.${id}`, {});
    return post;
};

import React, { useRef, useState } from 'react';
import {
    ButtonsContainer,
    Card,
    CloseCardIcon,
    DetailsContainer,
    SignInBanner,
    StyledTextField,
    Subtitle,
    SubtitleAccessRestriction,
    Title,
} from '../style/signIn';
import { getString } from '../../../services/api/store';
import * as string from '../constants/strings';
import Button, { buttonTypes } from '../../common/Button';
import ReactHtmlParser from 'react-html-parser';
import * as palette from '../../../components/General/Variables';
import { Flex } from '../../common/Flex';

const LoginIntro = ({
    currentPage,
    goTo,
    email,
    updateEmail,
    isValid,
    onKeyDown,
    checkEmail,
    theme,
    auth,
    authorize,
    eurekaOnly = false,
    attendingOnly = false,
    groupCanSignOnly = 0,
    groupCanAccessOnly = 0,
    error,
    eventDetails,
    onClose,
    isModal,
    isSociety,
}) => {
    const [readAll, setReadAll] = useState(false);
    const initiallyReadAll = useRef(null);

    const SIGN_IN_TITLE = getString('profileSignInScreenTitle') || string.SIGN_IN_TITLE;
    const CONTINUE_BUTTON_LABEL =
        getString('generalContinueButton') || string.CONTINUE_BUTTON_LABEL;
    let TEXT_SUBTITLE = null;
    const TEXT_EMAIL = getString('loginMainButtonEureka') || string.TITLE_EMAIL_LOGIN;
    const TEXT_INPUT_EMAIL =
        getString('profileSignInEnterEmailSubtitle') || string.INPUT_EMAIL_MESSAGE;
    const READ_MORE = getString('announcementsReadMore', 'Read more');
    const READ_LESS = getString('announcementsReadLess', 'Read less');

    if (attendingOnly || groupCanAccessOnly) {
        TEXT_SUBTITLE =
            getString('authAttendeeRequiredSubtitleForEvent') || string.ATTENDEES_LOGIN_DESCRIPTION;
    }

    if (eurekaOnly) {
        TEXT_SUBTITLE =
            getString('authSignInRequiredSubtitleForEvent') || string.EVENT_LOGIN_DESCRIPTION;
    }

    if (groupCanSignOnly) {
        TEXT_SUBTITLE =
            getString('authAttendeeRequiredSubtitleSignin') || string.GROUP_LOGIN_DESCRIPTION;
    }

    const detailsText = eventDetails?.info;
    const textElem = document.getElementById('description-container');
    const showMore = initiallyReadAll.current
        ? textElem?.offsetHeight <= textElem?.scrollHeight
        : textElem?.offsetHeight < textElem?.scrollHeight;
    if (!initiallyReadAll.current) {
        initiallyReadAll.current = showMore;
    }

    const hasRestriction = attendingOnly || eurekaOnly || groupCanAccessOnly;

    const renderCard = theme => {
        switch (currentPage) {
            case 'intro':
                return (
                    <Card
                        customHeight={'unset'}
                        customPadding={'24px'}
                        maxHeight={'688px'}
                        overflow={'scroll'}
                        intro={true}
                        isModal={isModal}
                        society={isSociety ? 1 : 0}
                    >
                        {!hasRestriction && (
                            <Flex
                                alignItems={'center'}
                                justifyContent={'center'}
                                margin={'0 0 16px 0'}
                            >
                                <Title customFontSize={'17px'}>{SIGN_IN_TITLE}</Title>
                                <CloseCardIcon onClick={onClose}>
                                    {palette.ICON_CLOSE}
                                </CloseCardIcon>
                            </Flex>
                        )}
                        {eventDetails.banner && (
                            <SignInBanner
                                src={eventDetails.banner}
                                alt="banner"
                                society={eventDetails.society}
                            />
                        )}
                        <Title intro={true}>{eventDetails.name}</Title>
                        {eventDetails.subtitle && (
                            <Subtitle intro={true}>{eventDetails.subtitle}</Subtitle>
                        )}
                        {detailsText && (
                            <>
                                <DetailsContainer
                                    id={'description-container'}
                                    lines={readAll ? 'unset' : 3}
                                    contrast={theme.contrast}
                                    primary={theme.primary}
                                >
                                    {ReactHtmlParser(detailsText)}
                                </DetailsContainer>
                                {showMore && (
                                    <Button
                                        onClick={() => {
                                            setReadAll(!readAll);
                                        }}
                                        type={buttonTypes.GREY_LONG}
                                        text={!readAll ? READ_MORE : READ_LESS}
                                        style={{ margin: '16px 0 12px 0' }}
                                    />
                                )}
                            </>
                        )}
                        {auth && auth.organization && (
                            <ButtonsContainer primarycolor={theme.primary} firstStep={true}>
                                <Button
                                    type={buttonTypes.GREEN_LONG}
                                    background={theme.primary}
                                    text={`${auth.buttonTitle}`}
                                    onClick={() => {
                                        authorize(attendingOnly);
                                    }}
                                    data-qa="button-go-to-sign-in-with-organization"
                                    image={auth.organizationLogoUrl}
                                    imageStyle={{
                                        color: theme.primary,
                                        backgroundColor: 'white',
                                        paddingTop: '0px',
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '15px',
                                        marginRight: '8px',
                                    }}
                                />
                                {auth.addEmail && (
                                    <Button
                                        type={buttonTypes.GREEN_LONG}
                                        background={theme.primary}
                                        text={TEXT_EMAIL}
                                        onClick={() => {
                                            goTo('email', attendingOnly);
                                        }}
                                        data-qa="button-go-to-sign-in-with-email"
                                        style={{ marginTop: '16px' }}
                                    />
                                )}
                            </ButtonsContainer>
                        )}
                        {!(auth && auth.organization) && (
                            <ButtonsContainer primarycolor={theme.primary} firstStep={true}>
                                <Button
                                    type={buttonTypes.GREEN_LONG}
                                    background={theme.primary}
                                    text={TEXT_EMAIL}
                                    onClick={() => {
                                        goTo('email', attendingOnly);
                                    }}
                                    data-qa="button-go-to-sign-in-with-email"
                                />
                                {/*<GoogleLogin*/}
                                {/*    clientId={Auth.googleClientId}*/}
                                {/*    buttonText={TEXT_GOOGLE_LOGIN}*/}
                                {/*    onSuccess={responseGoogle}*/}
                                {/*    onFailure={responseGoogle}*/}
                                {/*    render={renderProps => (*/}
                                {/*        <ButtonStyled*/}
                                {/*            onClick={renderProps.onClick}*/}
                                {/*            style={{*/}
                                {/*                display: 'flex',*/}
                                {/*                alignItems: 'center',*/}
                                {/*                justifyContent: 'center',*/}
                                {/*            }}*/}
                                {/*        >*/}
                                {/*            <SVGIcon>*/}
                                {/*                <svg*/}
                                {/*                    xmlns="http://www.w3.org/2000/svg"*/}
                                {/*                    viewBox="0 0 48 48"*/}
                                {/*                    width="26px"*/}
                                {/*                    height="26px"*/}
                                {/*                >*/}
                                {/*                    <path*/}
                                {/*                        fill="#FFC107"*/}
                                {/*                        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"*/}
                                {/*                    />*/}
                                {/*                    <path*/}
                                {/*                        fill="#FF3D00"*/}
                                {/*                        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"*/}
                                {/*                    />*/}
                                {/*                    <path*/}
                                {/*                        fill="#4CAF50"*/}
                                {/*                        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"*/}
                                {/*                    />*/}
                                {/*                    <path*/}
                                {/*                        fill="#1976D2"*/}
                                {/*                        d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"*/}
                                {/*                    />*/}
                                {/*                </svg>*/}
                                {/*            </SVGIcon>*/}
                                {/*            <div>{TEXT_GOOGLE_LOGIN}</div>*/}
                                {/*        </ButtonStyled>*/}
                                {/*    )}*/}
                                {/*/>*/}
                            </ButtonsContainer>
                        )}
                        {TEXT_SUBTITLE && (
                            <SubtitleAccessRestriction>{TEXT_SUBTITLE}</SubtitleAccessRestriction>
                        )}
                    </Card>
                );
            case 'email':
                return (
                    <Card customHeight={'330px'} isModal={isModal}>
                        <Flex alignItems={'center'} justifyContent={'center'}>
                            <Title>{SIGN_IN_TITLE}</Title>
                            {!hasRestriction && (
                                <CloseCardIcon onClick={onClose}>
                                    {palette.ICON_CLOSE}
                                </CloseCardIcon>
                            )}
                        </Flex>
                        <Subtitle customMarginTop={'0'}>{TEXT_INPUT_EMAIL}</Subtitle>
                        <StyledTextField
                            id="signin-email"
                            required
                            value={email}
                            onChange={mail => {
                                updateEmail(mail);
                            }}
                            label={'Email'}
                            type="email"
                            errorText={getString(
                                'signInEmailErrorMessage',
                                string.ERROR_MESSAGE_WRONG_EMAIL,
                            )}
                            onKeyDown={e => {
                                if (isValid) {
                                    onKeyDown(e, checkEmail);
                                }
                            }}
                            error={error}
                        />
                        <ButtonsContainer primarycolor={theme.primary}>
                            <Button
                                type={buttonTypes.GREEN_LONG}
                                background={theme.primary}
                                text={CONTINUE_BUTTON_LABEL}
                                onClick={checkEmail}
                                data-qa="button-continue-sign-in-with-email"
                            />
                        </ButtonsContainer>
                    </Card>
                );
            default:
                return null;
        }
    };

    return renderCard(theme);
};

export default LoginIntro;

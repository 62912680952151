import styled from 'styled-components';

export const TabsContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    height: 72px;
    box-shadow: inset 0 -1px 0 0 #e6e6e6;
    width: 100%;
`;

export const Tab = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    cursor: pointer;

    & > :nth-child(1) {
        font-family: 'Cabin', sans-serif;
        font-size: 12px;
        text-transform: uppercase;
        color: ${props => (props.active ? props.color : 'rgba(0, 0, 0, 0.54)')};
    }

    & > :nth-child(2) {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: ${props => (props.active ? '#ffffff' : 'rgba(0, 0, 0, 0.54)')};
        background-color: ${props => (props.active ? props.color : 'transparent')};
    }
`;

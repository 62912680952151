import { useEffect, useMemo, useRef, useState } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import get from 'lodash/get';

import throttle from 'lodash/throttle';
import { useVMMutation, useVMState } from '../containers/main';
import RTCClient from '../utils/rtcClient';
import useStream from '../hooks/useStream';
import useScreenSharingStream from '../hooks/useScreenSharingStream';
import entities from '../constants/entities';
import { TEN_MILL } from '../constants/values';
import Stream from '../utils/Stream';
import { sleep } from '../../../utils';

const { virtualEventSessionEntity } = entities;
const { roomType: roomTypeEntity } = virtualEventSessionEntity;

const useStreamHandling = audioOnlyForRemote => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const {
        canvasSharing,
        virtualEventSession,
        virtualEventUser,
        screenSharing,
        speakerViewMode,
        publishShareStream,
        recreateStreams,
        enableTCPProxy,
        socket,
    } = stateCtx;
    const { isMicrophoneOn, isVideoOn, isActive } = virtualEventUser.data;
    const { roomType } = virtualEventSession.data;
    const isVisibleInVideo = isActive;
    const isRoomRoundTable = roomType === roomTypeEntity.roundTable;
    const vUserId = get(virtualEventUser, 'data.UserId');
    const questionModeratorId = get(virtualEventSession, 'data.questionModeratorId');

    const shouldPublishScreenSharing = useMemo(
        () =>
            isVisibleInVideo ||
            isRoomRoundTable ||
            publishShareStream ||
            questionModeratorId === vUserId,
        [isVisibleInVideo, isRoomRoundTable, publishShareStream, questionModeratorId, vUserId],
    );

    const [update, setUpdate] = useState(Date.now());
    const localStream = useRef(null);
    const me = useRef(null);

    const videoClient = useMemo(() => {
        const client = new RTCClient();

        if (!client._created) {
            client.createClient({ codec: stateCtx.codec, mode: stateCtx.mode });
            client._created = true;
        }

        return client;
    }, [stateCtx.codec, stateCtx.mode]);

    [localStream.current] = useStream(videoClient, audioOnlyForRemote);

    const screenSharingStream = useRef(null);

    const screenSharingClient = useMemo(() => {
        const client = new RTCClient();

        if (!client._created) {
            client.createClient({ codec: 'vp8', mode: stateCtx.mode });
            client._created = true;
        }

        return client;
    }, [stateCtx.mode]);

    window.videoClient = videoClient;
    window.screenSharingClient = screenSharingClient;

    [screenSharingStream.current] = useScreenSharingStream(screenSharingClient, audioOnlyForRemote);

    const stopStream = () => {
        stateCtx.socket.emit('leaveSession', {
            objectId: stateCtx.sessionId,
        });
        mutationCtx.clearAllStream();
        videoClient &&
            videoClient.leave().then(() => {
                if (localStream.current) {
                    localStream.current.stop();
                    localStream.current.close();
                }
            });
        screenSharingClient &&
            screenSharingClient.leave().then(() => {
                if (screenSharingStream.current) {
                    screenSharingStream.current.stop();
                    screenSharingStream.current.close();
                }
            });
    };

    useEffect(() => {
        let myStream;

        (async () => {
            const { config: currentConfig } = me.current;
            const videoTrack = await AgoraRTC.createCameraVideoTrack({
                encoderConfig: '720p_1',
                cameraId: currentConfig.cameraId,
            });

            myStream = new Stream({
                video: videoTrack,
                uid: currentConfig.uid,
            });

            mutationCtx.setMyStream(myStream);
        })();
        return () => {
            if (myStream) {
                myStream.close();
                myStream.stop();
                mutationCtx.setMyStream(null);
            }
            stopStream();
        };
    }, []);

    const config = useMemo(() => {
        return {
            appID: stateCtx.config.appID,
            token: stateCtx.config.token,
            channel: stateCtx.config.channelName,
            microphoneId: stateCtx.config.microphoneId,
            cameraId: stateCtx.config.cameraId,
            resolution: stateCtx.config.resolution,
            muteVideo: true,
            muteAudio: true,
            uid: stateCtx.config.uid,
            host: stateCtx.config.host || !!stateCtx.canvasSharing,
            role: stateCtx.config.role,
        };
    }, [stateCtx]);

    me.current = {
        config,
        screenSharing,
        canvasSharing,
    };

    useEffect(() => {
        if (
            videoClient &&
            config.channel &&
            videoClient._created &&
            videoClient._joined === false
        ) {
            videoClient
                .join(config)
                .then(uid => {
                    mutationCtx.addPeer({ uid });
                })
                .catch(err => {
                    console.error(`Media ${err}`);
                    console.log('Wut');
                });
        }
    }, [videoClient, config, update, socket]);

    useEffect(() => {
        if (
            screenSharingClient &&
            config.channel &&
            screenSharingClient._created &&
            screenSharingClient._joined === false
        ) {
            screenSharingClient
                .join({
                    ...config,
                    host: !!screenSharing,
                    screenSharing: true,
                    // + 1Million
                    uid: config.uid + TEN_MILL,
                })
                .then(async uid => {
                    mutationCtx.addPeer({ uid });
                })
                .catch(err => {
                    console.error(`Media ${err}`);
                    console.log('Wut');
                });
        }
    }, [screenSharingClient, config, update]);

    useEffect(() => {
        const handleClientsJoined = () => {
            if (videoClient._joined && screenSharingClient._joined) {
                mutationCtx.hideJoiningLoader();
            }
        };

        if (videoClient) {
            videoClient.once('longJoin', mutationCtx.showJoiningLoader);
            videoClient.once('clientJoined', handleClientsJoined);
        }

        if (screenSharingClient) {
            screenSharingClient.once('longJoin', mutationCtx.showJoiningLoader);
            screenSharingClient.once('clientJoined', handleClientsJoined);
        }

        return () => {
            // these are here just in case they are called after the user leaves the session or something
            if (videoClient) {
                videoClient.off('longJoin', mutationCtx.showJoiningLoader);
                videoClient.off('clientJoined', handleClientsJoined);
            }

            if (screenSharingClient) {
                screenSharingClient.off('longJoin', mutationCtx.showJoiningLoader);
                screenSharingClient.off('clientJoined', handleClientsJoined);
            }
        };
    }, [videoClient, screenSharingClient]);

    const handleUpdate = () => setUpdate(Date.now());

    const onSharePublishChange = () => {
        socket.emit('sharingMedia', {
            objectId: stateCtx.sessionId,
            userId: get(virtualEventUser, 'data.UserId', null),
            publishing: screenSharingClient._published === true,
        });
    };

    const clientJoinedHandling = () => {
        const { config: currentConfig } = me.current;

        if (
            videoClient._joined === true &&
            videoClient._isHost === false &&
            currentConfig.host &&
            ((isVideoOn && currentConfig.cameraId) ||
                (isMicrophoneOn && currentConfig.microphoneId))
        ) {
            videoClient.makeHost(currentConfig);
        }
    };

    const clientIsHostHandling = () => {
        if (videoClient._isHost === true && videoClient._published === false) {
            videoClient.publish();
        }
    };

    useEffect(() => {
        videoClient.on('clientJoined', clientJoinedHandling);
        videoClient.on('clientIsHost', clientIsHostHandling);
        videoClient.on('updateClient', handleUpdate);

        return () => {
            videoClient.off('clientJoined', clientJoinedHandling);
            videoClient.off('clientIsHost', clientIsHostHandling);
            videoClient.off('updateClient', handleUpdate);
        };
    }, []);

    const screenSharingJoined = async () => {
        const { config: currentConfig, screenSharing, canvasSharing } = me.current;

        if (
            screenSharingClient._joined === true &&
            currentConfig.host &&
            ((stateCtx.useDevices && screenSharing) || canvasSharing)
        ) {
            const isNotPending =
                screenSharingClient._shareType !== 'pending' &&
                screenSharingClient._isHost !== 'pending';
            const shouldCreateOtherHost =
                screenSharingClient._isHost === false ||
                // switch between canvas sharing and screen sharing
                (screenSharing && screenSharingClient._shareType === 'canvas') ||
                // switch between screen sharing and canvas sharing
                (canvasSharing && screenSharingClient._shareType === 'screen') ||
                (canvasSharing && typeof screenSharingClient._shareType === 'undefined') ||
                // switch between different canvas to share
                (canvasSharing &&
                    screenSharingClient._shareType === 'canvas' &&
                    canvasSharing !== screenSharingClient._canvas);

            const shouldCreateHost = isNotPending && shouldCreateOtherHost;

            if (shouldCreateHost) {
                await screenSharingClient.makeHost({ screenSharing, canvasSharing });
                setUpdate(Date.now);
            }
        }
    };

    useEffect(() => {
        screenSharingClient.on('clientJoined', screenSharingJoined);
        screenSharingClient.on('updateClient', handleUpdate);
        screenSharingClient.on('stream-published', onSharePublishChange);
        screenSharingClient.on('stream-unpublished', onSharePublishChange);

        return () => {
            screenSharingClient.off('clientJoined', screenSharingJoined);
            screenSharingClient.off('updateClient', handleUpdate);
            screenSharingClient.off('stream-published', onSharePublishChange);
            screenSharingClient.off('stream-unpublished', onSharePublishChange);
        };
    }, []);

    useEffect(() => {
        clientJoinedHandling();
    }, [isMicrophoneOn, isVideoOn]);

    useEffect(() => {
        screenSharingJoined();
    }, [screenSharing, canvasSharing, update]);

    const handleScreenSharePublish = async () => {
        if (screenSharingClient._isHost === true) {
            if (shouldPublishScreenSharing) {
                await sleep(1000);
                screenSharingClient.publish();
            } else {
                screenSharingClient.unpublish();
            }
        }
    };

    useEffect(() => {
        handleScreenSharePublish();
    }, [shouldPublishScreenSharing, update]);

    const handleMicAndCamera = throttle(async videoStream => {
        if (videoStream) {
            if (!isMicrophoneOn) {
                if (videoStream.isAudioOn()) {
                    await videoStream.muteAudio();
                }
            } else {
                if (!videoStream.isAudioOn()) {
                    await videoStream.unmuteAudio();
                    await videoClient.publishTrack('audio');
                }
            }

            if (!isVideoOn) {
                if (videoStream.isVideoOn()) {
                    await videoStream.muteVideo();
                }
            } else {
                if (!videoStream.isVideoOn()) {
                    await videoStream.unmuteVideo();
                    await videoClient.publishTrack('video');
                }
            }
        }
    }, 500);

    useEffect(() => {
        const videoStream = localStream.current;
        const shareStream = screenSharingStream.current;
        let interval;

        if (videoStream) {
            interval = setInterval(() => handleMicAndCamera(videoStream), 500);
            handleMicAndCamera(videoStream);

            if (!isVideoOn && !isMicrophoneOn) {
                mutationCtx.removeStreamById({ uid: videoStream.getId() });
                videoClient.destroyStream();
            }
        }

        if (shareStream) {
            if (!(screenSharing || canvasSharing)) {
                mutationCtx.removeStreamById({ uid: shareStream.getId() });
                screenSharingClient.destroyStream(true);
            }
        }

        if (!stateCtx.useDevices) {
            mutationCtx.startUsingDevices();
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [
        isVideoOn,
        isMicrophoneOn,
        screenSharing,
        speakerViewMode,
        canvasSharing,
        localStream.current,
    ]);

    useEffect(() => {
        (async () => {
            const videoStream = localStream.current;

            if (recreateStreams && videoStream) {
                mutationCtx.removeStreamById({ uid: videoStream.getId() });
                await videoClient.destroyStream(true);
                setUpdate(Date.now());
                clientJoinedHandling();
                mutationCtx.setRecreateStreams(false);
            }
        })();
    }, [recreateStreams]);

    return { config, localStream, videoClient };
};

export default useStreamHandling;

import { eurekaRequest } from '../../../../../services/api/eureka';

export const block = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            '/api/entities/virtualEventBlockedUsers',
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const unblock = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'delete',
            `/api/entities/virtualEventBlockedUsers/session/${data.sessionId}/user/${data.userId}`,
            null,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const getBySessionId = sessionId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            '/api/entities/virtualEventBlockedUsers/session/' + sessionId,
            null,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

import React from 'react';
import { FontIcon } from 'react-md';
import styled from 'styled-components';
import { useTheme } from '../Theme/ThemeContext';

const StyledLiveChip = styled.div`
    text-align: center;
    border-radius: 4px;
    height: 20px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    color: #fff;
    background-color: ${props => props.color};
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.25px;
    display: flex;
    padding: 0 6px 0 4px;
    line-height: 2.09;
    align-items: center;
    justify-content: center;

    i {
        color: inherit;
        margin-right: 4px;
        font-size: 24px !important;
    }

    &:not(:last-child) {
        margin-right: 4px;
    }
`;

const LiveLabel = ({ customText, customColor }) => {
    const { theme } = useTheme();
    return (
        <StyledLiveChip color={customColor || theme.primary}>
            <FontIcon>videocam</FontIcon>
            {customText || 'LIVE'}
        </StyledLiveChip>
    );
};

export default LiveLabel;

import React from 'react';
import MuiButton from '@mui/material/Button';
import { COLOR_TEXT } from '../General/Variables';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    button: {
        minHeight: 40,
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeight: 500,
        color: COLOR_TEXT,
        borderRadius: 6,
        backgroundColor: 'rgba(239,239,239,1)',

        '&:hover': {
            backgroundColor: 'rgba(239,239,239,1)',
            filter: 'brightness(90%)',
        },
    },
});
const FloqButton = ({ children, onClick, extraStyle, variant = 'text' }) => {
    const classes = useStyles();

    return (
        <MuiButton
            type="button"
            variant={variant}
            onClick={onClick}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    onClick();
                }
            }}
            className={classes.button}
            style={extraStyle}
        >
            {children}
        </MuiButton>
    );
};

export default FloqButton;

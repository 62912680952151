import { eurekaRequest } from '../../../../../services/api/eureka';

export const getPollSetByExternalObject = externalObjectId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            '/api/entities/pollSets/externalObject/' + externalObjectId,
            null,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const getPollSetByExternalObjectReference = externalObjectReference => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            '/api/entities/pollSets/externalObject/event/' + externalObjectReference,
            null,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const savePollSet = pollSet => {
    const data = {
        state: pollSet.state,
        showResults: pollSet.showResults,
        showTotalVotes: pollSet.showTotalVotes,
        showTimer: pollSet.showTimer,
        chartType: pollSet.chartType,
        hideVotesNumber: pollSet.hideVotesNumber,
    };

    return new Promise((resolve, reject) => {
        eurekaRequest('put', '/api/entities/pollSets/' + pollSet.id, data, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

export const reorderPollsInSet = reorder => {
    const data = { reorder };
    return new Promise((resolve, reject) => {
        eurekaRequest('post', '/api/entities/pollSets/reorderPolls', data, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

export const resetPoll = id => {
    return new Promise((resolve, reject) => {
        eurekaRequest('post', '/api/entities/polls/' + id + '/reset', null, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

export const savePoll = poll => {
    return new Promise((resolve, reject) => {
        eurekaRequest('put', '/api/entities/polls/' + poll.id, poll, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

export const deletePoll = id => {
    return new Promise((resolve, reject) => {
        eurekaRequest('delete', '/api/entities/polls/' + id, { id }, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

export const updatePoll = poll => {
    return new Promise((resolve, reject) => {
        eurekaRequest('patch', '/api/entities/polls/' + poll.id, poll, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

export const getPollById = id => {
    return new Promise((resolve, reject) => {
        eurekaRequest('get', '/api/entities/polls/' + id, null, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

export const createPoll = poll => {
    return new Promise((resolve, reject) => {
        eurekaRequest('post', '/api/entities/polls/', poll, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

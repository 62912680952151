import React from 'react';
import { Cell } from 'react-md';
import styled, { css } from 'styled-components';

import * as palette from '../../components/General/Variables';

export const Wrapper = styled.div`
    background-color: #f5f5f5;
    height: 100%;
    padding-top: ${props =>
        props.ismobile === 'true' ? '200px' : props.widthpaddingtop ? '56px' : 0};
`;

export const SnackWrapper = styled.div`
    .md-snackbar {
        bottom: unset;
        max-width: unset;
        top: 0;
        width: 100%;
    }

    .md-snackbar-enter,
    .md-snackbar-leave.md-snackbar-leave-active {
        transform: translate3d(-50%, -100%, 0);
    }

    .md-snackbar-enter.md-snackbar-enter-active,
    .md-snackbar-leave {
        transform: translate3d(-50%, 0, 0);
    }

    .md-btn--snackbar {
        background: unset !important;
        cursor: unset !important;
        padding: 0;
        width: 300px;
    }

    .md-ink-container {
        display: none !important;
    }
`;

export const VideoWrapper = styled.div`
    background: #000;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    flex-direction: column;
`;

export const RoundTableVideoWrapper = styled(VideoWrapper)`
    background: #fff;
    position: relative;
    overflow-y: scroll;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 56px);
    margin-top: 56px;

    ${props =>
        props.withFooter &&
        css`
            height: calc(100vh - 136px);
        `}

    justify-content: center;
    overflow: hidden;
    background-color: #f4f5f5;
    padding: 8px 16px;
    margin-bottom: ${props => (props.withFooter ? '80px' : '0px')};
    box-sizing: border-box;
    flex-flow: row nowrap;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        flex-direction: column;
        justify-content: flex-start;

        .session-info {
            display: none;
        }
    }
`;

export const StyledCell = styled(Cell)`
    background-color: #fff;
`;

export const MainScreen = styled(StyledCell)`
  max-height: 100%;
  height: 100%;
  background-color: #fff;
  margin: 0 !important;
  width: 100% !important;
  ${props =>
      props.video === 'true' &&
      css`
          height: 100%;
      `} @media only screen and(max-width: ${palette.MAX_PHONE}) {
  margin: 0 !important;
  width: 100% !important;
}
`;

export const MainScreenWrapper = styled.div`
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContainerLeft = styled(Cell)`
    position: relative;
    overflow: scroll;
    -ms-overflow-style: none;
    flex: 2;
    margin: 0 16px 0 0 !important;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    &::-webkit-scrollbar {
        display: none;
    }

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        width: 100% !important;
    }
`;

export const ContainerRight = styled(Cell)`
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    height: 100%;
    flex: 1;
    background: #fff;
    border-radius: 8px;
    margin: 0 !important;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        .secondary {
            display: none;
        }
    }
`;

export const ContainerRightRoundTable = styled(Cell)`
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    height: 100%;
    flex: 1;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    margin: 0 !important;
    max-width: 459px;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        .secondary {
            display: none;
        }
    }
`;

export const SecondaryScreen = styled.div`
    background-color: inherit;
    border: 0px solid #ccc;
    flex: 1;
    margin: 16px 16px 0 16px;
    overflow: hidden;
    position: relative;
`;

export const RoundTableSecondaryScreen = styled(SecondaryScreen)`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 0;
`;

export const UserInteractionContainer = styled(StyledCell)`
  box-sizing: border-box;
  flex: 3;
  width: 100% !important;
  ${props => props.windowWidth < 910 && 'display: none'}
  height: ${props =>
      props.interactionwindowheight
          ? props.interactionwindowheight + 'px'
          : props.interactioninitialheight < 750
          ? '40vh'
          : '50vh'};
  margin: 0 !important;

  @media only screen and (max-width: ${palette.MAX_TABLET}) {
    bottom: 40px;
    background: red;
  }
  @media only screen and (max-width: ${palette.MAX_PHONE}) {
    background: blue;
  }
`;

export const SendButton = styled.button`
    background-color: ${props => props.color};
    color: #fff;
    border-radius: 4px;
    width: 69px;
    height: 36px;
    border: none;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    top: 5px !important;
    right: 16px !important;
    cursor: pointer;
`;

export const MakeMainScreenButton = styled.button`
    background-color: rgba(0, 0, 0, 0.54);
    color: #fff;
    border-radius: 6px;
    border: none;
    bottom: 20px;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    opacity: 0.9;
    position: absolute;
    text-align: center;
    right: 20px;
    width: 164px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
        background-color: rgba(0, 0, 0, 0.54);
    }

    i {
        color: inherit;
        margin-right: 8px;
    }

    span {
        font-family: 'Cabin', sans-serif;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.46px;
    }
`;

export const RoundIconButton = styled.div`
    align-items: center;
    border: solid 1px #cccccc;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    height: 48px;
    justify-content: center;
    margin: 0 8px;
    width: 48px;

    &:hover {
        background-color: #cccccc;
    }

    i {
        color: rgba(0, 0, 0, 0.87);
    }
`;

export const ShareScreenWrapper = styled.div`
    cursor: pointer;
    text-align: center;

    i {
        color: rgba(0, 0, 0, 0.87);
        font-size: 24px;
    }
`;

export const NoJoinedUserPlaceholder = styled.div`
    align-items: center;
    background-color: #666666;
    color: rgba(255, 255, 255, 0.54);
    display: flex;
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    height: 100%;
    justify-content: center;
    letter-spacing: normal;
    line-height: normal;
    border-radius: 8px;
`;

export const UserWrapperNoVideo = styled.img`
    position: absolute;
    top: 0;
    width: 100%;
    height: inherit;
`;

export const ActionButtonsWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
`;

export const AccountCircleIconWrapper = styled.div`
  font-size: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #ffffff;
  opacity: 0.6;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('${props => props.imageUrl}');
  width: ${props => (props.width ? props.width : '42px')};
  height: ${props => (props.width ? props.width : '42px')};
  border-radius: 50%;
`;

export const RemoteVideoWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;

export const RemoteVideoMutedOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    border-radius: 8px;
`;

export const ShadowOverlay = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(48, 48, 48, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: 1;
    border-radius: 8px;
`;

export const VirtualUserTitle = styled.span`
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0 0 0 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const VirtualUserInfoWrapper = styled.div`
    display: flex;
    align-items: start;
    height: fit-content;
    position: absolute;
    left: 20px;
    bottom: 10px;
    width: 85%;
    z-index: 10;
`;

export default {
    AccountCircleIconWrapper,
    ActionButtonsWrapper,
    Wrapper,
    SnackWrapper,
    ContentContainer,
    StyledCell,
    MainScreen,
    ContainerLeft,
    ContainerRight,
    SecondaryScreen,
    RoundTableSecondaryScreen,
    UserInteractionContainer,
    MakeMainScreenButton,
    VideoWrapper,
    RoundTableVideoWrapper,
    RoundIconButton,
    UserWrapperNoVideo,
    ContainerRightRoundTable,
    ShadowOverlay,
    VirtualUserInfoWrapper,
    VirtualUserTitle,
};

import { useCallback, useEffect } from 'react';

const useEscapeKeyDown = ({ handler }) => {
    const keyDownHandler = useCallback(e => {
        if (e.key === 'Escape') {
            handler();
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [keyDownHandler]);
};

export default useEscapeKeyDown;

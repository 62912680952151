import { connect } from 'react-redux';

import { getPrivateConversationWithId } from '../../Talk/selectors';
import TalkButtonComponent from '../components/TalkButton';

const mapStateToProps = (state, ownProps) => {
    const conversationId = ownProps.user.id;
    return {
        conversation: getPrivateConversationWithId(state, conversationId),
    };
};

const TalkButton = connect(mapStateToProps)(TalkButtonComponent);
export { TalkButton };

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { DropdownMenu, FontIcon } from 'react-md';

import useEvents from '../hooks/useEvents';
import useSocietySettings from '../hooks/useSocietySettings';
import EventCard from '../components/EventCard';
import SearchBar from '../../../components/TextInput/SearchBar';
import {
    RoundedDropdownMenu,
    StyledDropdownItem,
    ViewButton,
} from '../../../components/General/DropdownMenu';
import NavigationLink from '../../../components/Navigation/NavigationLink';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 120px);
    overflow-y: scroll;
`;

const MaxWidthContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1057.5px;
`;

const EventsContainer = styled.div`
    display: flex;
    width: fit-content;
    flex-flow: row wrap;
    justify-content: flex-start;
`;

const EventsTypeText = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 13px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.38);
    align-self: flex-start;
    margin: 0 0 14px 12px;
`;

const FiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
`;

const SearchBarContainer = styled.div`
    width: 300px;
`;

const DownloadMobileAppText = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    cursor: default;
    ${props =>
        props.link &&
        css`
            cursor: pointer;
            margin: 0 2px;
            color: rgba(50, 46, 244, 1);
        `}
`;

const filterEventsOptions = {
    ALL: 'All',
    UPCOMING: 'Upcoming',
    PAST: 'Past',
};

const EventGrid = () => {
    const [searchFilter, setSearchFilter] = useState('');
    const [dropdownFilter, setDropdownFilter] = useState(filterEventsOptions.ALL);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showInstallMobileAppModal, setShowInstallMobileAppModal] = useState(false);
    const { pastEvents, upcomingEvents } = useEvents({ filter: searchFilter });
    const { branchIoDomain } = useSocietySettings();

    const displayUpcomingEvents =
        dropdownFilter === filterEventsOptions.ALL ||
        dropdownFilter === filterEventsOptions.UPCOMING;
    const displayPastEvents =
        dropdownFilter === filterEventsOptions.ALL || dropdownFilter === filterEventsOptions.PAST;

    const dropdownItems = [
        <StyledDropdownItem
            key="events-grid-dropdown-item-all"
            primaryText="All"
            onClick={() => setDropdownFilter(filterEventsOptions.ALL)}
            active={dropdownFilter === filterEventsOptions.ALL}
        />,
        <StyledDropdownItem
            key="events-grid-dropdown-item-upcoming"
            primaryText="Upcoming events"
            onClick={() => setDropdownFilter(filterEventsOptions.UPCOMING)}
            active={dropdownFilter === filterEventsOptions.UPCOMING}
        />,
        <StyledDropdownItem
            key="events-grid-dropdown-item-past"
            primaryText="Past events"
            onClick={() => setDropdownFilter(filterEventsOptions.PAST)}
            active={dropdownFilter === filterEventsOptions.PAST}
        />,
    ];

    return (
        <Wrapper>
            <MaxWidthContainer>
                <FiltersContainer>
                    <SearchBarContainer>
                        <SearchBar value={searchFilter} onChange={setSearchFilter} />
                    </SearchBarContainer>
                    <RoundedDropdownMenu
                        id="events-grid-dropdown-menu"
                        menuItems={dropdownItems}
                        anchor={{
                            x: DropdownMenu.HorizontalAnchors.INNER_RIGHT,
                            y: DropdownMenu.VerticalAnchors.BOTTOM,
                        }}
                        position={DropdownMenu.Positions.TOP_LEFT}
                        animationPosition="below"
                        sameWidth
                        simplifiedMenu={false}
                        onVisibilityChange={setIsDropdownOpen}
                        visible={isDropdownOpen}
                        light={false}
                        margin="0 8px"
                    >
                        <ViewButton>
                            {dropdownFilter}
                            <FontIcon>arrow_drop_down</FontIcon>
                        </ViewButton>
                    </RoundedDropdownMenu>
                    {/*<DownloadMobileAppText>*/}
                    {/*    Get access to all your events here or*/}
                    {/*</DownloadMobileAppText>*/}
                    {/*<DownloadMobileAppText*/}
                    {/*    link*/}
                    {/*    onClick={() => {*/}
                    {/*        setShowInstallMobileAppModal(true);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    download*/}
                    {/*</DownloadMobileAppText>*/}
                    {/*<DownloadMobileAppText>the mobile app.</DownloadMobileAppText>*/}
                </FiltersContainer>
                {displayUpcomingEvents && (
                    <>
                        <EventsTypeText>Ongoing and upcoming events</EventsTypeText>
                        <EventsContainer>
                            {upcomingEvents.map((event, index) => (
                                <NavigationLink
                                    key={`upcoming-event-item-${index}`}
                                    path={`/events/${event.uniqueName}`}
                                >
                                    <EventCard
                                        key={`event-card-${index}`}
                                        imageUrl={event.banner}
                                        title={event.name}
                                        subtitle={event.subtitle}
                                    />
                                </NavigationLink>
                            ))}
                        </EventsContainer>
                    </>
                )}
                {displayPastEvents && (
                    <>
                        <EventsTypeText>Past events</EventsTypeText>
                        <EventsContainer>
                            {pastEvents.map((event, index) => (
                                <NavigationLink
                                    key={`past-event-item-${index}`}
                                    path={`/events/${event.uniqueName}`}
                                >
                                    <EventCard
                                        key={`event-card-${index}`}
                                        imageUrl={event.banner}
                                        title={event.name}
                                        subtitle={event.subtitle}
                                    />
                                </NavigationLink>
                            ))}
                        </EventsContainer>
                    </>
                )}
            </MaxWidthContainer>
        </Wrapper>
    );
};

export default EventGrid;

import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';

import { ContainerLeft, MainScreen, MainScreenWrapper } from '../../../styles';
import Session from '../../common/Session';
import { useGlobalState } from '../../../../../utils/container';
import entities from '../../../constants/entities';
import useResize from '../../../hooks/useResize';
import PresentationOrScreen from '../../common/PresentationOrScreen';
import AudioPlayer from '../../../../VirtualModeration/features/virtualFeature/components/common/AudioPlayer';
import OnDemandSlides from '../../common/OnDemandSlides';
import OnDemandPoster from '../../common/OnDemandPoster';
import Presenter from '../common/Presenter';
import { navigateTo } from '../../../../../components/Navigation/NavigationLink';
import bestFitLayout from '../../../../../utils/bestFitLayout';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import AnalyticsService from '../../../../../features/analytics/services';
import { useVMState } from '../../../../VirtualModeration/features/virtualFeature/containers/main';

const GridForUsers = styled.div`
    display: grid;
    height: 100%;
    width: 100%;
    overflow: hidden;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
`;

const MainContent = props => {
    const stateCtx = useGlobalState();

    const { streams, virtualEventSession, virtualEventUser, currentVirtualEventUser } = stateCtx;
    const { audioPlaying } = useVMState();
    const { timeslot, match, history, location, virtualEventUsersToDisplay } = props;
    const [screenWrapperRef] = useResize();
    const [onDemandMode, setOnDemandMode] = useState(null);

    const gridLayout = bestFitLayout(virtualEventUsersToDisplay.length);
    const isOnDemand =
        location && location.pathname && location.pathname.includes('/on-demand-poster-room');
    const mainMedia = get(virtualEventUser, 'mode') || 'video';
    const host = get(virtualEventSession, 'VirtualEventUsers', []).find(user => {
        return user.role === entities.virtualEventUserEntity.role.roundTableHost;
    });
    const hostHasShareStream =
        !host ||
        (!!(streams || []).find(st => {
            return st.streamId === host.UserId + 10000000;
        }) &&
            virtualEventUser?.isScreenSharing);
    const slidesMadeVisibleByHost = get(virtualEventSession, 'slidesVisible') || false;
    const shouldRenderVideo =
        (mainMedia === 'video' || !slidesMadeVisibleByHost) && !hostHasShareStream;
    const audioRecordingPresentationUrl = get(host, 'audioRecordingPresentationUrl');
    const list = get(host, 'audioRecordingInfo.list', []);
    const audioInfo = list.find(info => info.type === 'audio');
    const audioUrl =
        audioRecordingPresentationUrl &&
        audioInfo &&
        audioInfo.sid &&
        `${audioRecordingPresentationUrl}/audio/${audioInfo.sid}_${timeslot.id}.mp4`;
    const posterPdf = timeslot && timeslot.posterPdf && JSON.parse(timeslot.posterPdf);
    const posterUrl = posterPdf && posterPdf.url;
    const url = (virtualEventUser && virtualEventUser.uploadedPresentationUrl) || posterUrl;
    const timeslotId = timeslot.id;

    const toggleUserProfile = id => {
        navigateTo({
            match,
            history,
            location,
            params: {
                type: 'profile',
                userId: id,
            },
            state: {
                timeslot: props.timeslot,
            },
        });
    };

    useEffect(() => {
        (async () => {
            if (!url || !isOnDemand || !timeslotId) {
                return;
            }
            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
            const pdfUrl = await pdfjsLib.getDocument(url).promise;
            const numberOfPages = pdfUrl?._pdfInfo?.numPages;

            if (numberOfPages) {
                setOnDemandMode(
                    numberOfPages === 1
                        ? entities.virtualEventUserEntity.mode.poster
                        : entities.virtualEventUserEntity.mode.slide,
                );
            }

            if (url && timeslotId) {
                AnalyticsService.addSample('pdf', url, timeslotId);
            }
        })();
    }, [url, isOnDemand, timeslotId]);

    useEffect(() => {
        let active = true;
        let interval = null;
        let timeout = null;

        if (audioPlaying && active && audioUrl) {
            timeout = setTimeout(() => {
                AnalyticsService.addSample('viewAudioRecording', audioUrl, timeslotId);
            }, 3000);
            interval = setInterval(() => {
                AnalyticsService.addSample('viewAudioRecording', audioUrl, timeslotId);
            }, 60000);
        }

        return () => {
            active = false;
            if (interval) {
                clearInterval(interval);
            }
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [audioPlaying, audioUrl, timeslotId]);

    return (
        <ContainerLeft size={8} tabletSize={8} phoneSize={12}>
            <MainScreenWrapper
                innerRef={shouldRenderVideo ? null : screenWrapperRef}
                style={shouldRenderVideo ? { height: '100%' } : {}}
            >
                {!isOnDemand && (
                    <MainScreen size={12} tabletSize={12} video={shouldRenderVideo ? 1 : 0}>
                        {shouldRenderVideo ? (
                            <GridForUsers
                                style={{
                                    gridTemplateColumns: 'minmax(0, 1fr)  '.repeat(gridLayout),
                                }}
                            >
                                {(virtualEventUsersToDisplay || []).map(
                                    (virtualEventUserData, index) => (
                                        <Presenter
                                            key={index}
                                            index={index}
                                            virtualEventUser={virtualEventUserData}
                                            toggleUserProfile={() =>
                                                toggleUserProfile(virtualEventUserData?.UserId)
                                            }
                                            muted={
                                                currentVirtualEventUser?.UserId ===
                                                virtualEventUserData?.UserId
                                            }
                                            noFixedAspectRatio
                                        />
                                    ),
                                )}
                            </GridForUsers>
                        ) : (
                            <PresentationOrScreen />
                        )}
                    </MainScreen>
                )}
                {isOnDemand && onDemandMode === entities.virtualEventUserEntity.mode.slide && (
                    <OnDemandSlides url={url} />
                )}
                {isOnDemand && onDemandMode === entities.virtualEventUserEntity.mode.poster && (
                    <OnDemandPoster url={url} />
                )}
            </MainScreenWrapper>
            {isOnDemand && audioUrl && (
                <div style={{ marginTop: 16 }}>
                    <AudioPlayer url={audioUrl} primary skipPretesting />
                </div>
            )}
            <Session
                sections={timeslot.sections || []}
                info={timeslot.info || {}}
                isOnDemand={isOnDemand}
            />
        </ContainerLeft>
    );
};

export default MainContent;

import React from 'react';
import { Button, DialogContainer } from 'react-md';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { navigateTo } from '../../../components/Navigation/NavigationLink';
import PersonListItem from '../../../components/ObjectListItem/Person';

const StyledButton = styled(Button)`
    color: #44bb90 !important;
`;

const StyledDialogContainer = styled(DialogContainer)`
    .md-list-item {
        list-style-type: none;
    }
`;

const UsersModal = ({ users, onClose, match, history, location }) => {
    const onUserClick = user => {
        onClose();
        navigateTo({
            match,
            history,
            location,
            params: {
                type: 'profile',
                userId: user.id,
            },
        });
    };

    return (
        <StyledDialogContainer
            id="users-list"
            visible={true}
            title="Likes"
            onHide={onClose}
            actions={[
                <StyledButton key="user-list-close" flat onClick={onClose}>
                    Close
                </StyledButton>,
            ]}
            width={600}
        >
            {users.map((user, indx) => (
                <div
                    role="button"
                    tabIndex="0"
                    key={`like-user-modal${indx}`}
                    onClick={() => onUserClick(user)}
                    onKeyDown={() => {}}
                >
                    <PersonListItem person={user} />
                </div>
            ))}
        </StyledDialogContainer>
    );
};

export default withRouter(UsersModal);

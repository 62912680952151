import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactPlayer from 'react-player';
import { FontIcon } from 'react-md';
import styled from 'styled-components';
import get from 'lodash/get';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useVMMutation, useVMState } from '../../containers/main';
import { BeautifulLoader } from './BeautifulLoader';
import { CLOUDFRONT_URL } from '../../../../../../config';
import { colorTheme } from '../../constants/colors';
import VideoSlider from './VideoSlider';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

const Wrapper = styled.div`
    width: 100%;
    height: fit-content;
    position: relative;
    * > video {
        border-radius: 8px;
        height: 0 !important;
    }
`;

const PlayerWrapper = styled.div`
    cursor: ${props => (props.primary ? 'pointer' : 'default')};
    position: relative;
`;

const PlayIcon = styled(FontIcon)`
    font-size: 24px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    cursor: pointer !important;
    margin-right: 16px;
`;

const Duration = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.6);
    margin: ${props => props.margin || 0};
`;

const SliderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
`;

const SliderWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 696px;
    padding: 0 16px;
    background-color: rgba(235, 235, 235, 1);
    border-radius: 8px;
`;

const iconStyle = {
    color: colorTheme.DARK_GREY,
    cursor: 'pointer',
};

const DEV_RECORDING_BUCKET_URL =
    'https://s3.eu-central-1.amazonaws.com/stream-recordings.dev.indrina.com';
const PROD_RECORDING_BUCKET_URL =
    'https://s3.eu-central-1.amazonaws.com/stream-recordings.web.indrina.com';

const getDuration = audioDurationString => {
    if (!audioDurationString) {
        return 0;
    }

    const [minutes, seconds] = audioDurationString.split(':');
    return parseInt(minutes) * 60 + parseInt(seconds);
};

const AudioPlayer = ({
    url: providedUrl,
    primary,
    muted,
    onDelete,
    playEnabled,
    skipPretesting = false,
    ...props
}) => {
    const playerRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const { audioDuration, audioPlaying, audioPlayedSeconds, audioPlayerRef } = stateCtx;
    const [isSliderHovered, setIsSliderHovered] = useState(false);
    const readyToPlay = useRef(false);

    const canPlay = playEnabled === true || playEnabled === undefined;

    const url = useMemo(() => {
        if (!providedUrl) {
            return;
        }

        return providedUrl
            .replace(DEV_RECORDING_BUCKET_URL, CLOUDFRONT_URL)
            .replace(PROD_RECORDING_BUCKET_URL, CLOUDFRONT_URL);
    }, [providedUrl]);

    useEffect(() => {
        let active = true;
        let interval;
        (async () => {
            if (!url || !active || readyToPlay.current) {
                return;
            }

            if (skipPretesting) {
                setLoading(false);
                readyToPlay.current = true;
                return;
            }

            interval = setInterval(async () => {
                const resp = await fetch(url);

                if (resp.status === 200) {
                    setLoading(false);
                    readyToPlay.current = true;
                    const audioDurationFromHost = get(
                        stateCtx,
                        'virtualEventUser.data.audioRecordingInfo.duration',
                    );
                    const duration = getDuration(audioDurationFromHost);
                    mutationCtx.setAudioDuration(duration);
                    clearInterval(interval);
                }
            }, 5000);
        })();

        return () => {
            active = false;
            clearInterval(interval);
        };
    }, [url, skipPretesting]);

    useEffect(() => {
        if (!loading && readyToPlay.current && playerRef.current) {
            playerRef.current.seekTo(2, 'seconds');
        }
    }, [loading]);

    useEffect(() => {
        if (primary) {
            mutationCtx.setAudioPlayerRef(playerRef);
        }
    }, [playerRef, primary, url]);

    useEffect(() => {
        // Resume video from playedSeconds when mainMedia is switched
        const { playedSeconds } = stateCtx;

        if (playedSeconds && !loading && readyToPlay.current && playerRef.current) {
            playerRef.current.seekTo(playedSeconds, 'seconds');
        }
    }, [muted, loading, readyToPlay.current, playerRef.current]);

    const roundValue = value => Math.round(value * 10) / 10;

    const onPlayClick = () => {
        if (canPlay && primary && readyToPlay.current) {
            if (audioPlaying) {
                mutationCtx.stopAudioPlaying();
            } else {
                mutationCtx.startAudioPlaying();
            }
        }
    };

    const onProgress = progress => {
        const { playedSeconds } = progress;
        const roundedValue = roundValue(playedSeconds);

        if (!primary) {
            return;
        }

        if (roundedValue <= Math.floor(audioDuration)) {
            mutationCtx.setAudioPlayedSeconds(roundedValue);
        } else {
            mutationCtx.stopAudioPlaying();

            if (get(audioPlayerRef, 'current')) {
                audioPlayerRef.current.seekTo(0, 'seconds');
            }
        }
    };

    const onDuration = newDuration => {
        if (primary) {
            mutationCtx.setAudioDuration(newDuration);
        }
    };

    const onEnded = () => mutationCtx.stopAudioPlaying();

    const convertSecondsToTimeString = seconds => {
        return new Date(seconds * 1000).toISOString().substr(14, 5);
    };

    const onSliderValueChanged = (value, event) => {
        const roundedValue = roundValue(value);

        // Pause video while dragging slider
        if (event.type === 'mousemove') {
            mutationCtx.stopPlaying();
        }

        if (get(audioPlayerRef, 'current')) {
            audioPlayerRef.current.seekTo(roundedValue, 'seconds');
        }

        if (event.type === 'mouseup') {
            mutationCtx.startAudioPlaying();
        }
    };

    return (
        <Wrapper className="eureka-react" data-qa={props['data-qa']} id="audio-recording">
            <BeautifulLoader active={!canPlay}>
                <PlayerWrapper primary={primary} onClick={onPlayClick}>
                    {!loading && readyToPlay.current && (
                        <ReactPlayer
                            url={url}
                            playing={audioPlaying}
                            width={'100%'}
                            height={0}
                            onProgress={onProgress}
                            onDuration={onDuration}
                            onEnded={onEnded}
                            progressInterval={100}
                            ref={playerRef}
                            muted={muted}
                            loop={true}
                        />
                    )}
                </PlayerWrapper>
                {primary && (
                    <SliderContainer>
                        <SliderWrapper
                            onMouseEnter={() => setIsSliderHovered(true)}
                            onMouseLeave={() => setIsSliderHovered(false)}
                        >
                            {!loading && readyToPlay.current ? (
                                <>
                                    {canPlay && primary && (
                                        <PlayIcon onClick={onPlayClick}>
                                            {audioPlaying ? 'pause' : 'play_arrow'}
                                        </PlayIcon>
                                    )}
                                    {audioDuration && (
                                        <>
                                            <Duration margin="0 12px 0 0">
                                                {convertSecondsToTimeString(
                                                    audioPlayedSeconds || 0,
                                                )}
                                            </Duration>
                                            <VideoSlider
                                                min={0}
                                                max={roundValue(audioDuration)}
                                                step={0.1}
                                                value={audioPlayedSeconds}
                                                onChange={onSliderValueChanged}
                                                dark
                                            />
                                            <Duration margin="0 12px 0 8px">
                                                -
                                                {convertSecondsToTimeString(
                                                    audioDuration - (audioPlayedSeconds || 0),
                                                )}
                                            </Duration>
                                            {isSliderHovered && onDelete && (
                                                <IconButton
                                                    onClick={onDelete}
                                                    onKeyDown={event => {
                                                        if (event.key === 'Enter') {
                                                            onDelete();
                                                        }
                                                    }}
                                                >
                                                    <DeleteOutlineIcon style={iconStyle} />
                                                </IconButton>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CircularProgress size={24} />
                                    <p style={{ marginBottom: 0, marginLeft: 16 }}>
                                        Processing recording...
                                    </p>
                                </div>
                            )}
                        </SliderWrapper>
                    </SliderContainer>
                )}
            </BeautifulLoader>
        </Wrapper>
    );
};

export default AudioPlayer;

import React from 'react';
import { getImageSource, getPLacesByFloorplan, getItem } from '../services/floorplan';
import Map from './map';

class Floorplan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            reset: false,
        };
        this.mapInfoLoad = React.createRef();
    }

    processData = data => {
        if (data.imageRatio && this.state.imageRatio !== data.imageRatio) {
            this.setState({ imageRatio: data.imageRatio });
        }
    };

    resize = () => {};

    load(floorplanId, placeId) {
        getItem('floorplans', floorplanId, (err, floorplan) => {
            if (floorplan && floorplan.image) {
                getImageSource(floorplan.imageUrl || floorplan.image, (err, result) => {
                    if (result) {
                        getPLacesByFloorplan(floorplan.id, (err, places) => {
                            const node = this.mapInfoLoad.current;
                            if (!node) {
                                return;
                            }
                            const specs = node.getBoundingClientRect();
                            this.setState({
                                loading: false,
                                image: result,
                                places,
                                mapWidth: specs.width,
                            });
                        });
                    }
                });
            }
        });
    }

    componentDidMount() {
        this.load(this.props.match.params.floorplan, this.props.match.params.place);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.floorplan !== prevState.floorplanId) {
            nextProps.onActiveFloorplan(nextProps.match.params.floorplan);
            return {
                loading: true,
                floorplanId: nextProps.match.params.floorplan,
                selectedPlace: nextProps.match.params.place,
            };
        } else if (nextProps.match.params.place !== prevState.selectedPlace) {
            return {
                selectedPlace: nextProps.match.params.place,
            };
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loading) {
            this.load(this.props.match.params.floorplan, this.props.match.params.place);
        }
    }

    render() {
        let height = window.innerHeight - 120 + 'px';
        let maxWidth = window.innerWidth + 'px';
        let width = '100%';
        const containerStyle = {
            width: width,
            height: height,
            maxWidth: maxWidth,
            paddingTop: '24px',
            paddingLeft: '8px',
            paddingRight: '8px',
        };

        return (
            <div>
                <div ref={this.mapInfoLoad}>
                    {this.state.image && (
                        <div style={containerStyle}>
                            <Map
                                id={this.state.floorplanId}
                                selectedPlace={this.state.selectedPlace}
                                resize={this.resize}
                                process={this.processData}
                                initialScale={'auto'}
                                minScale={'auto'}
                                maxScale={4}
                                searchButtonAction={this.props.searchButtonAction}
                            >
                                <img alt="floorplan" src={this.state.image} />
                            </Map>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Floorplan;

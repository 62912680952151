import React, { useEffect, useState } from 'react';
import * as palette from '../General/Variables';
import { Background, Dialog } from '../../scenes/SignInPage/components/index';

import ThemeContext from '../Theme/ThemeContext';
import {
    HIDDEN_PASSWORD_BUTTON_LABEL,
    HIDDEN_PASSWORD_DESCRIPTION,
    HIDDEN_PASSWORD_TITLE,
    PASSWORD_HINT_LABEL,
} from './strings';
import { Flex } from '../../scenes/common/Flex';
import {
    AnchorText,
    Card,
    PoliciesText,
    StyledTextField,
    Subtitle,
    Title,
} from '../../scenes/SignInPage/style/signIn';
import { default as Store, getString } from '../../services/api/store';
import Button, { buttonTypes } from '../../scenes/common/Button';
import { FontIcon } from 'react-md';
import { getImageSource, getLocalAppStateAsync } from '../../services/api/db';
import { aboutLinks } from '../../scenes/SignInPage/constants/strings';

const HiddenPasswordModal = ({ eventId, password, onDone }) => {
    const [value, setValue] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [terms, setTerms] = useState(aboutLinks.termsAndConditions);
    const PASSWORD_HINT = getString('passwordFieldHint') || PASSWORD_HINT_LABEL;

    const labelsWithAsterisk = document.getElementsByTagName('label');
    const signInBackground = Store.argument && Store.argument.signInBackground;

    useEffect(() => {
        if (labelsWithAsterisk?.length) {
            const text = labelsWithAsterisk[0].textContent;
            labelsWithAsterisk[0].innerHTML = text.replace(' *', '');
        }
    }, [labelsWithAsterisk]);

    useEffect(() => {
        if (signInBackground) {
            getImageSource(signInBackground, (err, img) => {
                setBackgroundImage(img);
            });
        }
    }, []);

    useEffect(() => {
        (async () => {
            const appState = await getLocalAppStateAsync();
            if (appState.termsUrl) {
                setTerms(appState.termsUrl);
            }
        })();
    }, []);

    const onChange = value => {
        if (value.length > 0) {
            setIsValid(true);
        }

        setValue(value);
    };

    const onKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            onSubmit();
        }
    };

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        if (value !== password) {
            setIsValid(false);
            return;
        }

        localStorage.setItem(`${eventId}_enteredHiddenPassword`, value);
        onDone();
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    <Background image={backgroundImage} />
                    <Dialog
                        id="speed-boost"
                        modal
                        width={palette.DIALOG_WIDTH}
                        height={palette.DIALOG_HEIGHT}
                        aria-describedby="speed-boost-description"
                        style={{ zIndex: '100 !important' }}
                        visible={true}
                    >
                        <Card isModal={true} customMinHeight={'280px'}>
                            <Flex alignItems={'center'} justifyContent={'center'}>
                                <Title>{HIDDEN_PASSWORD_TITLE}</Title>
                            </Flex>
                            <Subtitle>{HIDDEN_PASSWORD_DESCRIPTION}</Subtitle>
                            <StyledTextField
                                id="hidden-password"
                                required
                                error={value.length > 0 && !isValid}
                                value={value}
                                onChange={onChange}
                                label={PASSWORD_HINT}
                                type="password"
                                errorText={'Password is incorrect.'}
                                onKeyDown={onKeyDown}
                                passwordIcon={{
                                    invisible: <FontIcon>visibility_off</FontIcon>,
                                    visible: <FontIcon>visibility_on</FontIcon>,
                                }}
                            />
                            <Button
                                type={buttonTypes.GREEN_LONG}
                                background={theme.primary}
                                text={HIDDEN_PASSWORD_BUTTON_LABEL}
                                onClick={onSubmit}
                                data-qa="button-sign-in-with-email"
                            />
                            <PoliciesText style={{ marginTop: 16 }}>
                                By signing in, you agree to the{' '}
                                <AnchorText
                                    href={terms || aboutLinks.termsAndConditions}
                                    target="_blank"
                                    color={theme.primary}
                                >
                                    Terms of Use
                                </AnchorText>{' '}
                                and{' '}
                                <AnchorText
                                    href={aboutLinks.privacyPolicy}
                                    target="_blank"
                                    color={theme.primary}
                                >
                                    {' '}
                                    Privacy Policy
                                </AnchorText>{' '}
                                and you accept the{' '}
                                <AnchorText
                                    href={aboutLinks.cookiePolicy}
                                    target="_blank"
                                    color={theme.primary}
                                >
                                    Cookie Policy.
                                </AnchorText>
                            </PoliciesText>
                        </Card>
                    </Dialog>
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default HiddenPasswordModal;

import React, { useState } from 'react';
import styled from 'styled-components';
import FontIcon from 'react-md/lib/FontIcons';
import groupBy from 'lodash/groupBy';

import AchievementCard from './AchievementCard';
import { Flex } from '../../VirtualSession/components/userInteraction/questionsAndAnswers/QuestionsAndAnswers';
import AchievementModal from './AchievementModal';
import useAchievements from '../hooks/useAchievements';

const StyledContainer = styled.div`
    padding-top: 24px;
`;

const CardsFlexContainer = styled(Flex)`
    & > button:not(:last-child) {
        margin-right: 16px;
    }
`;

const StyledSectionContainer = styled.div`
    display: flex;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 8px;
`;

const StyledSectionTitle = styled.div`
    font-family: Cabin;
    font-size: 17px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
`;

export const AchievementFilter = Object.freeze({
    All: 'All',
    Completed: 'Completed',
    Pending: 'Pending',
});

const AchievementsList = ({ filter = AchievementFilter.All }) => {
    const { achievements } = useAchievements({ filter });
    const [selectedAchievement, setSelectedAchievement] = useState();
    const [showReward, setShowReward] = useState(false);

    const sections = groupBy(achievements, 'Achievement.category');
    const allSectionKeys = Object.keys(sections);
    const sectionKeys = allSectionKeys.filter(key => key !== 'Hidden'); // 'Hidden' section should not be displayed
    sectionKeys.sort();

    const handleAchievementClick = achievement => {
        setSelectedAchievement(achievement);
    };

    const handleViewRewardClick = (e, achievement) => {
        e.preventDefault();
        e.stopPropagation();

        setShowReward(true);
        setSelectedAchievement(achievement);
    };

    const handleAchievementModalHide = () => {
        setShowReward(false);
        setSelectedAchievement(undefined);
    };

    return (
        <StyledContainer>
            {sectionKeys.map(section => (
                <React.Fragment key={section}>
                    <StyledSectionContainer>
                        <FontIcon></FontIcon>
                        <StyledSectionTitle>{section}</StyledSectionTitle>
                    </StyledSectionContainer>
                    <CardsFlexContainer wrap={'wrap'}>
                        {sections[section].map(achievement => (
                            <AchievementCard
                                key={`${achievement.AchievementId}`}
                                achievement={achievement}
                                onClick={() => handleAchievementClick(achievement)}
                                onViewRewardClick={e => handleViewRewardClick(e, achievement)}
                            />
                        ))}
                    </CardsFlexContainer>
                </React.Fragment>
            ))}
            <AchievementModal
                visible={!!selectedAchievement}
                onHide={handleAchievementModalHide}
                achievement={selectedAchievement}
                initialShowRewardOnly={showReward}
            />
        </StyledContainer>
    );
};

export default AchievementsList;

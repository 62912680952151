import React, { useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FontIcon } from 'react-md';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import { CCLinkTransform } from '../../helper/CCLink';
import * as palette from '../../components/General/Variables';
import Slideshow from '../../components/Slideshow';
import ThemeContext from '../../components/Theme/ThemeContext';
import { DetailDrawer } from '../../components/General/DetailDrawer';
import { getLocalAppState } from '../../services/api';
import { getString } from '../../services/api/store';
import NewsFeedDetailPage from './NewsFeed/NewsFeedDetailPage';
import { fetchNewsFeed, getTargetedMessages } from './service/newsfeed';
import AnalyticsService from '../../features/analytics/services';
import Auth from '../../services/api/auth';
import { TimezoneContext } from '../Timezone/context';
import Button, { buttonTypes } from '../common/Button';
import useOnScreen from '../../hooks/useOnScreen';
import { AWS_IMAGES_ENDPOINT, S3_ENDPOINT_IMAGES } from '../../config';

const FeedCardContainer = styled.button`
    width: 100%;
    border-radius: 8px;
    box-shadow: ${props => !props.banner && '0 3px 6px 0 rgba(0, 0, 0, 0.3)'};
    background-color: ${props => !props.banner && '#ffffff'};
    margin-bottom: 24px;
    padding: 0;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-weight: normal;
    font-family: Roboto;
    border: none;
    cursor: pointer;
    overflow: hidden;
    text-align: left;
`;

const Wrapper = styled.div`
    ${props => props.maxWidth && `max-width: ${props.maxWidth}px;`}
    padding: ${props => (props.hasPadding ? '24px' : 0)};
`;

const FeedDetailContainer = styled.div`
    padding: 12px 16px 16px 16px;
`;

export const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
`;

export const TimeAndPinned = styled.div`
    font-family: Roboto, sans-serif;
    color: rgba(0, 0, 0, 0.54);
    margin: 8px 0 12px 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #1e1e1f;
    opacity: 0.54;
`;

export const Content = styled.div`
    ${props =>
        props.isPreview &&
        `
        line-height: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    `}

    iframe {
        width: 100%;
        aspect-ratio: 16 / 9;
        border-radius: 4px;
    }

    & > * {
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.43;
        color: rgba(0, 0, 0, 0.87);

        a {
            text-decoration: none;
            color: ${props => props.color} !important;
        }
    }
`;

const HeaderBackground = styled.img`
    background-color: ${palette.COLOR_GREY};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    text-align: center;
    border-radius: 4px;
`;

export const PinIcon = styled(FontIcon)`
    font-size: 16px !important;
    color: #323232 !important;
    margin-right: 4px;
`;

export const Bullet = styled.div`
    display: inline-block;
    gap: 8px;
    width: 3px;
    height: 3px;
    margin: 12px 8px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.54);
`;

export const clearHTML = (htmlText, isProcessed) => {
    const htmlTextWithTargetBlank =
        htmlText && htmlText.split('<a href="').join('<a target="_blank" href="');
    return isProcessed ? htmlText : htmlTextWithTargetBlank;
};

const FeedCard = ({ children, banner, item, hasShowMore, onSendAnalytics, onClick }) => {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    useEffect(() => {
        if (isVisible && item && !hasShowMore && onSendAnalytics) {
            onSendAnalytics(item._id);
        }
    }, [isVisible]);

    return (
        <FeedCardContainer banner={banner} innerRef={ref} onClick={onClick}>
            {children}
        </FeedCardContainer>
    );
};

const FeedPage = props => {
    const READ_MORE_BUTTON = getString('announcementsReadMore') || 'Read More';

    const [newsFeed, setNewsFeed] = useState([]);
    const [eventId, setEventId] = useState([]);
    const [object, setObject] = useState(null);
    const [banner, setBanner] = useState(null);
    const [itemIdsWithViewSampleSent, setItemIdsWithViewSampleSent] = useState([]);

    const { getUtcToSelectedTimezone } = useContext(TimezoneContext);

    const { match, history, location } = props;

    const dateTime = date => getUtcToSelectedTimezone(date).format('D MMM YYYY, HH:mm');

    const isMobile = palette.MIN_TABLET_INT && window.innerWidth < palette.MIN_TABLET_INT;

    const isNewsFeedPage = window.location.href.toLowerCase().includes('/feed');

    useEffect(() => {
        props.setTitle();
        getLocalAppState(async (err, appState) => {
            if (err) {
                console.log(err);
            } else {
                const isAuthenticated = Auth.isUserAuthenticated();
                let targetedMessages = [];

                setEventId(appState.eventId);
                const allNewsFeed = await fetchNewsFeed(appState.eventId, appState.configuratorUrl);

                if (isAuthenticated) {
                    targetedMessages = await getTargetedMessages(
                        appState.eventId,
                        appState.configuratorUrl,
                    );
                }
                const mergedNewsFeed = [...allNewsFeed, ...targetedMessages];
                const availableNewsFeed = mergedNewsFeed
                    .filter(feed => feed.deleted === 0)
                    .sort((item1, item2) => {
                        const date1 = new Date(item1.date);
                        const date2 = new Date(item2.date);

                        return date2 - date1;
                    })
                    .sort((x, y) => (x.pinned ? -1 : y.pinned ? 1 : 0));

                setNewsFeed(availableNewsFeed);
                setBanner(appState.banner);

                // itemId that comes from onesignal notification click
                if (props.location.state && props.location.state.itemId) {
                    const item = availableNewsFeed.find(
                        item => item._id === props.location.state.itemId,
                    );

                    if (item) {
                        onShowMoreClick(item);
                    }
                }
            }
        });
        // getLocalAppState(() => {});
    }, [eventId]);

    const renderBanner = () => (
        <FeedCard banner={true}>
            <Slideshow data={banner.items} noIndicators={true} itemDuration={banner.duration} />
        </FeedCard>
    );

    const transform = node => {
        return CCLinkTransform(node, match, history, location);
    };

    const sendViewSample = (itemId, triggeredByShowMore = false) => {
        if (itemIdsWithViewSampleSent.includes(itemId)) {
            return;
        }

        if (!triggeredByShowMore) {
            setItemIdsWithViewSampleSent([...itemIdsWithViewSampleSent, itemId]);
        }

        AnalyticsService.addSample('announcement', itemId, itemId);
    };

    const onShowMoreClick = item => {
        setObject(item);
        sendViewSample(item._id, true);
    };

    const renderCards = () =>
        newsFeed.map((item, index) => {
            const textElem = document.getElementById(`text-container-${item._id}`);
            const showMore = textElem && textElem.offsetHeight < textElem.scrollHeight;

            let bannerImage;
            if (item.mediaImage && AWS_IMAGES_ENDPOINT && S3_ENDPOINT_IMAGES) {
                bannerImage = item.mediaImage.replace(S3_ENDPOINT_IMAGES, AWS_IMAGES_ENDPOINT);
            }

            return (
                <ThemeContext.Consumer key={`news-feed-${index}`}>
                    {({ theme }) => (
                        <FeedCard
                            item={item}
                            hasShowMore={showMore}
                            onSendAnalytics={sendViewSample}
                            onClick={() => onShowMoreClick(item)}
                        >
                            {bannerImage && (
                                <HeaderBackground
                                    src={bannerImage}
                                    alt={'HeaderBackground'}
                                    isMobile={isMobile}
                                />
                            )}
                            {item.mediaVideoEmbed && item.mediaVideoRaw && (
                                <Content>
                                    {ReactHtmlParser(item.mediaVideoEmbed, { transform })}
                                </Content>
                            )}
                            <FeedDetailContainer>
                                <TimeAndPinned>
                                    {item.pinned && (
                                        <>
                                            <PinIcon>push_pin</PinIcon>
                                            Pinned
                                            <Bullet />
                                        </>
                                    )}
                                    {dateTime(item.date)}
                                </TimeAndPinned>

                                <Title>{item.subject}</Title>
                                <Content
                                    color={theme.contrast}
                                    isPreview={true}
                                    id={`text-container-${item._id}`}
                                >
                                    {ReactHtmlParser(item.body, { transform })}
                                </Content>
                                {showMore && (
                                    <Button
                                        onClick={() => onShowMoreClick(item)}
                                        type={buttonTypes.GREY}
                                        text={READ_MORE_BUTTON}
                                        style={{ marginTop: '20px' }}
                                    />
                                )}
                            </FeedDetailContainer>
                        </FeedCard>
                    )}
                </ThemeContext.Consumer>
            );
        });

    return (
        <Wrapper maxWidth={isNewsFeedPage && '500'} hasPadding={isNewsFeedPage} id="feed-wrapper">
            {banner && banner.items && banner.items.length > 0 && renderBanner()}
            {newsFeed.length > 0 && renderCards()}
            {object && (
                <DetailDrawer>
                    <NewsFeedDetailPage
                        item={object}
                        date={dateTime(object.date)}
                        onCloseModal={() => setObject(null)}
                        transform={transform}
                    />
                </DetailDrawer>
            )}
        </Wrapper>
    );
};

export default withRouter(FeedPage);

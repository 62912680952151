import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Bar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    background-color: ${props => (props.color ? props.color : '#dedee1')};
    width: ${props => (props.width ? props.width : '100%')};

    @media (max-width: 767px) {
        left: 32px;
    }
`;

const BeamerViewBar = styled(Bar)`
    height: 6px;
    border-radius: 6px;
    background-color: ${props => (props.color ? props.color : '#dedee1')};
    width: ${props => (props.width ? props.width : '100%')};
`;

const ProgressBar = ({ activeColor, activePoll, pollSet, isBeamerView }) => {
    const { updatedAt: pollSetLastUpdate, state } = pollSet;
    const { useTimer, seconds: pollDuration } = activePoll;
    const isPollSetOpen = state === 'open';
    const staringTimer =
        pollDuration - Math.floor((Date.now() - new Date(pollSetLastUpdate).getTime()) / 1000);
    const [secondsRemaining, setSecondsRemaining] = useState(staringTimer);

    useEffect(() => {
        if (isPollSetOpen) {
            const timerInterval = setInterval(() => {
                setSecondsRemaining(val => val - 0.1);
            }, 100);

            return () => {
                clearInterval(timerInterval);
            };
        }
    }, [isPollSetOpen, pollSetLastUpdate]);

    const getTimePercentageRemaining = () => `${100 - (secondsRemaining * 100) / pollDuration}%`;

    if (!useTimer) {
        return null;
    }

    if (isBeamerView) {
        return (
            <>
                <BeamerViewBar />
                <BeamerViewBar color={activeColor} width={getTimePercentageRemaining()} />
            </>
        );
    }

    return (
        <>
            <Bar />
            <Bar color={activeColor} width={getTimePercentageRemaining()} />
        </>
    );
};

export default ProgressBar;

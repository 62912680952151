import React, { useState } from 'react';

import SearchResultsContext from './SearchResultsContext';

const withSearchResults = Children => {
    const WithSearchResults = props => {
        const [resultsData, setResultsData] = useState([
            {
                type: '',
                items: [],
            },
        ]);
        return (
            <SearchResultsContext.Provider
                value={{
                    setResultsData,
                    resultsData,
                }}
            >
                <Children {...props} />
            </SearchResultsContext.Provider>
        );
    };
    return WithSearchResults;
};

export default withSearchResults;

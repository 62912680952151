import entities from '../constants/entities';
import { addVideos } from '../services/VirtualEventSession';

const onAddVideoUrl = async (stateCtx, mutationCtx, newVideo, virtualEventUser) => {
    const { sessionId, virtualEventSession, socket } = stateCtx;
    const isPreRecording = !!virtualEventUser;

    mutationCtx.setVirtualEventSession({
        ...virtualEventSession,
        uploadRequestStatus: entities.requestStatus.loading,
    });

    const newVideoUrls = [...(virtualEventSession.data.videoUrls || [])];
    const data = {
        ...newVideo,
        isPreRecording,
    };
    const response = await addVideos(sessionId, data);
    newVideoUrls.push(response);

    mutationCtx.setVirtualEventSession({
        ...virtualEventSession,
        uploadRequestStatus: entities.requestStatus.success,
        data: {
            ...virtualEventSession.data,
            videoUrls: newVideoUrls,
        },
    });

    if (!virtualEventUser) {
        socket.emit('updateData', { objectId: sessionId });
    } else {
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isPrerecording: false,
                        recordingInfo: newVideo,
                    },
                ],
            },
        });
    }
};

export default onAddVideoUrl;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { FontIcon } from 'react-md';

import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import scalingTools from '../../../VirtualModeration/features/virtualFeature/utils/scalingTools';
import { useGlobalState } from '../../../../utils/container';
import { colorTheme } from '../../../VirtualModeration/features/virtualFeature/constants/colors';

const SlidesWrapper = styled.div`
    position: relative;
    text-align: center;
    background-color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
`;

const ButtonsWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 16px;
    left: 16px;
    width: calc(100% - 32px);
    z-index: 2;
`;

const LeftButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    background-color: rgba(64, 64, 64, 0.8);
    border-radius: 8px;
    font-family: Roboto;
    max-height: 40px;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
`;

const RightButtonsContainer = styled(LeftButtonsContainer)`
    padding: 10px;
`;

const StyledArrow = styled(FontIcon)`
    color: #fff !important;
    font-size: 18px !important;
    font-weight: bold;
    cursor: pointer;
`;

const iconStyle = {
    color: colorTheme.WHITE,
    cursor: 'pointer',
};

const OnDemandSlides = url => {
    const stateCtx = useGlobalState();
    const { virtualEventUser, virtualEventSession } = stateCtx;
    const canvasRef = useRef(null);
    const intervalRef = useRef(null);
    const shouldClearInterval = useRef(false);
    const redrawSpeed = 1000;
    const [pageNr, setPageNr] = useState(0);
    const [pdf, setPdf] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [showTools, setShowTools] = useState(false);

    useEffect(() => {
        (async () => {
            if (!url) {
                return;
            }
            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
            const pdf = await pdfjsLib.getDocument(url).promise;
            setPdf(pdf);
        })();
    }, [url]);

    useEffect(() => {
        (async () => {
            try {
                if (!pdf) {
                    return null;
                }

                if (pageNr < 0 || pageNr >= pdf.numPages) {
                    return;
                }

                const page = await pdf.getPage(pageNr + 1);
                const pageSize = {
                    height: page._pageInfo.view[3],
                    width: page._pageInfo.view[2],
                };
                const scalingFactor =
                    pageSize.width / pageSize.height >= 16 / 9 ? 'width' : 'height';
                const DYNAMIC_SCALING_MAP = scalingTools.getDynamicScalingMap(scalingFactor);

                const scale =
                    (DYNAMIC_SCALING_MAP[scalingFactor] * 100) / pageSize[scalingFactor] / 100;
                const viewport = page.getViewport({ scale });

                // Apply page dimensions to the <canvas> element.
                const canvasWithPage = document.createElement('canvas');
                const context = canvasWithPage.getContext('2d');

                canvasWithPage.style.maxWidth = '100%';
                canvasWithPage.style.maxHeight = 'calc(100vh - 250px)';
                canvasWithPage.height = viewport.height;
                canvasWithPage.width = viewport.width;

                // Render the page into the <canvas> element.
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }

                await page.render(renderContext).promise;

                if (canvasRef.current) {
                    canvasRef.current.style.height = 'unset';
                    canvasRef.current.style.width = 'unset';

                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }

                    const drawFn = () => {
                        if (!canvasRef.current) {
                            return;
                        }

                        const canvasContext = canvasRef.current.getContext('2d');

                        canvasRef.current.height = canvasWithPage.height;
                        canvasRef.current.width = canvasWithPage.width;
                        canvasContext.drawImage(
                            canvasWithPage,
                            0,
                            0,
                            canvasWithPage.width,
                            canvasWithPage.height,
                        );

                        /**
                         * This approach is required because we have some async functionality,
                         * so we make sure that the interval is cleared every time that is needed.
                         */
                        if (shouldClearInterval.current) {
                            clearInterval(intervalRef.current);
                        }
                    };

                    drawFn();
                    intervalRef.current = setInterval(drawFn, redrawSpeed);
                }
            } catch (err) {
                console.log(err);
            }

            return () => {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
            };
        })();
    }, [pdf, virtualEventUser, virtualEventSession, canvasRef.current, pageNr, isFullScreen]);

    const previous = () => {
        if (pageNr < 1) {
            return;
        }

        setPageNr(val => val - 1);
    };

    const next = () => {
        if (pageNr > pdf?.numPages - 2) {
            return;
        }

        setPageNr(val => val + 1);
    };

    const openFullscreen = async () => {
        const elem = document.getElementById('on-demand-room-slides');

        if (elem.requestFullscreen) {
            await elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE11 */
            elem.msRequestFullscreen();
        }
        setIsFullScreen(true);
    };

    const closeFullScreen = async () => {
        if (document.exitFullscreen) {
            await document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            /* IE11 */
            document.msExitFullscreen();
        }
        setIsFullScreen(false);
    };

    return (
        <SlidesWrapper
            id="on-demand-room-slides"
            onMouseEnter={() => setShowTools(true)}
            onMouseLeave={() => setShowTools(false)}
        >
            <canvas ref={canvasRef} style={{ maxWidth: '100%', maxHeight: '100%' }} id="pdf" />
            {showTools && (
                <ButtonsWrapper>
                    <LeftButtonsContainer>
                        <StyledArrow style={{ marginRight: 16 }} onClick={previous}>
                            chevron_left
                        </StyledArrow>
                        {pageNr + 1} of {pdf?.numPages}
                        <StyledArrow style={{ marginLeft: 16 }} onClick={next}>
                            chevron_right
                        </StyledArrow>
                    </LeftButtonsContainer>
                    <RightButtonsContainer>
                        {!isFullScreen && (
                            <FullscreenIcon style={iconStyle} onClick={openFullscreen} />
                        )}
                        {isFullScreen && (
                            <FullscreenExitIcon style={iconStyle} onClick={closeFullScreen} />
                        )}
                    </RightButtonsContainer>
                </ButtonsWrapper>
            )}
        </SlidesWrapper>
    );
};

export default OnDemandSlides;

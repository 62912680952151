import styled from 'styled-components';
import { ListItem, ListItemControl } from 'react-md';

export const StyledListItem = styled(ListItem)`
    .md-tile-text--primary {
        font-size: 15px;
        font-family: Cabin, sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 20px;
        color: rgba(30, 30, 31, 0.87);
        ${props => props.maxwidth && `max-width: ${props.maxwidth}`};
        margin-right: 16px;
    }

    .md-tile-text--secondary {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        margin-top: 4px;
        color: rgba(0, 0, 0, 0.57);
        width: ${props => (props.fitTextInContainer ? '89%' : '100%')};
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .md-avatar {
        width: 57px;
        height: 57px;
    }

    .md-tile-addon--avatar {
        height: 57px;
    }

    .md-list-tile--two-lines {
        height: ${props => props.height || '72px'};
    }

    .md-list-tile {
        margin: 0 8px;
    }

    .md-list-tile--active {
        background: #f5f5f5;
        border-radius: 8px;
    }

    .md-tile-addon--avatar {
        height: ${props => props.iconCustomHeight};
    }

    .md-list-item--flex {
        width: 0;
    }

    list-style-type: none !important;

    ${props =>
        props.fitTextInContainer &&
        `
        .md-tile-content--left-avatar {
        		width: 0;
    	        }
        `}
`;

import { asyncEurekaRequest } from '../../../services/api/eureka';
import { AchievementType } from '../hooks/useAchievementActions';

class GamificationService {
    async getUserAchievements({ eventId, filter }) {
        let path = `/api/entities/achievementUser/user/event/${eventId}`;
        if (filter) {
            path = `${path}?filter=${filter.toLowerCase()}`;
        }

        return asyncEurekaRequest('get', path, null, true);
    }

    async claimReward({ achievementId }) {
        return asyncEurekaRequest(
            'put',
            `/api/entities/achievementUser`,
            {
                achievementId,
                rewardClaimed: true,
            },
            true,
        );
    }

    async getLeaderboard({ eventId, page, limit }) {
        return asyncEurekaRequest(
            'get',
            `/api/entities/achievementUser/leaderboard/event/${eventId}?page=${page}&limit=${limit}`,
            null,
            true,
        );
    }

    async getIsGamificationEnabled({ eventId }) {
        if (!eventId) {
            return false;
        }
        const response = await asyncEurekaRequest(
            'get',
            `/api/entities/events/reference/${eventId}`,
            null,
            true,
        );
        return response.gamification;
    }

    async trackAchievement(type, eventId, itemId) {
        const data = { type, event: eventId };
        if (itemId) {
            data.itemId = `${itemId}`;
        }

        if (type === AchievementType.COMPLETE_VIRTUAL_SESSION) {
            data.sessionId = data.itemId;
        }

        return asyncEurekaRequest('post', '/api/entities/achievementUser/add', data, true);
    }

    async deleteAchievementUser(type, eventId) {
        return asyncEurekaRequest(
            'delete',
            '/api/entities/achievementUser',
            { type, event: eventId },
            true,
        );
    }

    async getAvailableAchievementTypes(eventId) {
        return asyncEurekaRequest(
            'get',
            `/api/entities/achievement/availableTypes/${eventId}`,
            null,
            true,
        );
    }
}

export default new GamificationService();

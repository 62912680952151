import async from 'async';
import { populateSections } from '../../../services/api/data';
import { getItem } from '../../../services/api/graphQlRepository';

export const getTimeslot = (sessionId, cb) => {
    if (!sessionId) {
        return cb();
    }

    async.waterfall(
        [
            callback => {
                getItem('timeslot', sessionId, (err, timeslot) => {
                    if (err || !timeslot) {
                        cb(err, {});
                    }

                    callback(null, timeslot);
                });
            },
            (timeslot, callback) => {
                if (timeslot && timeslot.id) {
                    timeslot.objectClass = 'timeslot';
                    callback(null, timeslot);
                } else {
                    getItem('programelement', sessionId, (err, pElement) => {
                        if (err || !pElement) {
                            return cb(err, {});
                        }
                        pElement.objectClass = 'programelement';
                        callback(null, pElement);
                    });
                }
            },
            (timeslot, callback) => {
                getItem('types', timeslot.type, (err, type) => {
                    if (err || !type) {
                        cb(err ? err : 'type not found');
                    } else {
                        callback(null, timeslot, type);
                    }
                });
            },
            (timeslot, type, callback) => {
                populateSections(timeslot, type, (err, sections, interactivity) => {
                    const typeParams = type?.params || {};
                    timeslot = {
                        ...timeslot,
                        sections: sections || [],
                        interactivity: interactivity || [],
                        typeParams,
                        displayPosterIcons: type?.displayPosterIcons,
                        hostCanOverridePoster: type?.hostCanOverridePoster,
                        allowHostToStartMeeting: type?.allowHostToStartMeeting,
                        allowHostToRecordInstructionVideo: type?.allowHostToRecordInstructionVideo,
                        allowHostToRecordAudioDescription: type?.allowHostToRecordAudioDescription,
                        posterAvailableToAttendees: type?.posterAvailableToAttendees,
                    };

                    callback(null, timeslot);
                });
            },
        ],
        (err, timeslot) => {
            cb(err, timeslot);
        },
    );
};

export const getTimeslotAsync = sessionId => {
    return new Promise((resolve, reject) => {
        getTimeslot(sessionId, (err, timeslot) => {
            if (err) {
                reject(err);
            } else {
                resolve(timeslot);
            }
        });
    });
};

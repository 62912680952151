import React from 'react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import {
    ButtonsContainer,
    Card,
    CloseCardIcon,
    ContentHtml,
    HelperText,
    PolicyLink,
    Subtitle,
    Title,
    TitleContainer,
} from '../style/signIn';
import { getString } from '../../../services/api/store';
import Button, { buttonTypes } from '../../common/Button';
import * as string from '../constants/strings';
import * as palette from '../../../components/General/Variables';
import { Flex } from '../../common/Flex';

const PrivacyPolicy = ({
    goToSignIn,
    privacyPolicy,
    updatePolicy,
    theme,
    eurekaOnly,
    attendingOnly,
    onClose,
    isModal,
}) => {
    let TEXT_PRIVACY_POLICY_TITLE = `Privacy Policy`;
    let TEXT_PRIVACY_POLICY_SUBTITLE = `Our Privacy Policy has been updated. Please
review and agree in order to continue.`;
    const TEXT_PRIVACY_POLICY_UPDATED = `Updated on ${moment(privacyPolicy.created).format(
        'DD - MM - YYYY',
    )}`;
    const TEXT_PRIVACY_POLICY_WHATCHANGED_TITLE = 'What’s changed?';
    const TEXT_READ_FULL_POLICY = 'Read full Privacy Policy';
    const CONTINUE_BUTTON_LABEL =
        getString('generalContinueButton') || string.CONTINUE_BUTTON_LABEL;

    const { whatChanged, url } = privacyPolicy;

    return (
        <Card
            alignLeft
            customHeight={whatChanged ? 'auto' : false}
            customTop={whatChanged ? '2vh' : '20vh'}
            isModal={isModal}
        >
            <TitleContainer>
                <Flex alignItems={'center'} justifyContent={'center'}>
                    <Title>{TEXT_PRIVACY_POLICY_TITLE}</Title>
                    {!eurekaOnly && !attendingOnly && (
                        <CloseCardIcon onClick={onClose}>{palette.ICON_CLOSE}</CloseCardIcon>
                    )}
                </Flex>
                <HelperText>{TEXT_PRIVACY_POLICY_UPDATED}</HelperText>
            </TitleContainer>
            <Subtitle>{TEXT_PRIVACY_POLICY_SUBTITLE}</Subtitle>
            {whatChanged && whatChanged.length && (
                <>
                    <Title sub>{TEXT_PRIVACY_POLICY_WHATCHANGED_TITLE}</Title>
                    <ContentHtml>{ReactHtmlParser(whatChanged)}</ContentHtml>
                </>
            )}
            <PolicyLink linkcolor={theme.primary} align={whatChanged} target="_blank" href={url}>
                {TEXT_READ_FULL_POLICY}
            </PolicyLink>
            <ButtonsContainer primarycolor={theme.primary}>
                <Button
                    type={buttonTypes.GREEN_LONG}
                    background={theme.primary}
                    text={CONTINUE_BUTTON_LABEL}
                    onClick={updatePolicy}
                    data-qa="button-continue-privacy-policy"
                    style={{ marginTop: '12px' }}
                />
                <Button
                    type={buttonTypes.GHOST_LONG}
                    background={theme.primary}
                    text={'Decline'}
                    onClick={goToSignIn}
                    data-qa="button-declone-privacy-policy"
                    style={{ marginTop: '12px' }}
                />
            </ButtonsContainer>
        </Card>
    );
};

export default PrivacyPolicy;

import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import AuthUserContext from '../../components/Session/AuthUserContext';
import { getFullProfile } from '../../services/api/eureka';
import PasswordProtect from './PasswordProtect';
import Loader from '../../components/General/Loader';
import AccessDeniedModal from './AccessDeniedModal';

const AccessRestrictions = ({
    pageId,
    accessRestrictions,
    isPasswordProtected,
    password,
    children,
    match,
    history,
    location,
}) => {
    const [userData, setUserData] = useState({ userGroups: [], id: null });
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [hasGroupRestrictions, setHasGroupRestrictions] = useState(false);
    const [loading, setLoading] = useState(true);
    const [allowed, setAllowed] = useState(false);

    const { authUser, showSignIn } = useContext(AuthUserContext);

    useEffect(() => {
        const updateUserProfile = async () => {
            if (userData.id) {
                setLoading(false);
                return;
            }

            try {
                const result = await getFullProfile();
                setUserData(result);
                if (
                    result &&
                    result.id &&
                    !result.userGroups.find(group => `${group.id}` === `${accessRestrictions}`)
                ) {
                    setHasGroupRestrictions(true);
                    setAllowed(false);
                } else {
                    setAllowed(true);
                }
                setLoading(false);
            } catch (e) {
                console.log(e);
            }
        };
        const passwordWasEntered = checkIfPasswordWasEnteredForRestrictedPage(pageId);
        if (password && isPasswordProtected && !passwordWasEntered) {
            setShowPasswordModal(true);
            setLoading(false);
            setAllowed(false);
        } else if (authUser && accessRestrictions && accessRestrictions !== 'eureka') {
            updateUserProfile();
        } else if (accessRestrictions && !authUser) {
            setAllowed(false);
            showSignIn(null, { match, history, location, replace: true });
        } else {
            setAllowed(true);
            setLoading(false);
        }
    }, [isPasswordProtected, pageId, showPasswordModal, accessRestrictions, authUser]);

    const checkIfPasswordWasEnteredForRestrictedPage = pageId => {
        if (!pageId) {
            return false;
        }

        try {
            const allowedRestrictedPages =
                JSON.parse(localStorage.getItem('allowedRestrictedPages')) || [];

            return allowedRestrictedPages.find(id => id === pageId);
        } catch (_) {
            // Parse failed
            return false;
        }
    };

    return (
        <>
            {loading && <Loader />}
            {!loading && (
                <>
                    {showPasswordModal && (
                        <PasswordProtect
                            pageId={pageId}
                            password={password}
                            eventName={'Page is restricted'}
                            hideShowModal={() => {
                                setShowPasswordModal(false);
                            }}
                        />
                    )}
                    {!showPasswordModal && hasGroupRestrictions && (
                        <AccessDeniedModal notVirtualRoom onClose={() => history.goBack()} />
                    )}
                    {!showPasswordModal && !hasGroupRestrictions && allowed && <>{children}</>}
                </>
            )}
        </>
    );
};

export default withRouter(AccessRestrictions);

import React from 'react';

const YoutubeIcon = () => (
    <svg width="20" height="16" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.582 2.186A2.506 2.506 0 0 0 17.814.418C16.254 0 10 0 10 0S3.746 0 2.186.418c-.86.23-1.538.908-1.768 1.768C0 3.746 0 8 0 8s0 4.254.418 5.814c.23.86.908 1.538 1.768 1.768C3.746 16 10 16 10 16s6.254 0 7.814-.418a2.504 2.504 0 0 0 1.768-1.768C20 12.254 20 8 20 8s0-4.254-.418-5.814ZM8 10.598V5.402a.5.5 0 0 1 .75-.433l4.5 2.598a.5.5 0 0 1 0 .866l-4.5 2.598a.5.5 0 0 1-.75-.433Z"
            fill="#B3B3B3"
            fillRule="nonzero"
        ></path>
    </svg>
);

export default YoutubeIcon;

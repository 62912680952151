import React from 'react';
import { useLocation } from 'react-router-dom';
import PageSelector from './PageSelector';
import ContentSelector from './ContentSelector';

const RightPanelView = props => {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const params = {
        display: query.get('display'),
        type: query.get('type'),
        pageId: query.get('pageId'),
        pageType: query.get('pageType'),
        childrenId: query.get('childrenId'),
        objectClass: query.get('objectClass'),
        objectId: query.get('objectId'),
        userId: query.get('userId'),
        personLinkedId: query.get('personLinkedId'),
        conversationId: query.get('conversationId'),
    };

    if (params.pageType) {
        return (
            <PageSelector
                detailPage
                pageId={params.pageId}
                pageType={params.pageType}
                childrenId={params.childrenId}
                installationId={props.installationId}
            />
        );
    }
    if (params.type) {
        return (
            <ContentSelector
                type={params.type}
                objectClass={params.objectClass}
                objectId={params.objectId}
                userId={params.userId}
                personLinkedId={params.personLinkedId}
                conversationId={params.conversationId}
                installationId={props.installationId}
            />
        );
    }
    return null;
};
export default RightPanelView;

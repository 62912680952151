import {
    SET_SHOW_PROFILE_MODAL,
    SET_SHOW_SETTINGS_MODAL,
    SET_SHOW_PLATFORM_INFO,
    SET_SHOW_SIGN_OUT_DIALOG,
    FETCH_PROFILE_DATA,
    SET_SHOW_DIGITAL_BADGE,
} from '../actions';

const INITIAL_STATE = {
    showProfileModal: false,
    showSettingsModal: false,
    showPlatformInfo: false,
    showDigitalBadge: false,
    showSignOutDialog: false,
    profileData: null,
    fetchingProfileData: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SHOW_PROFILE_MODAL:
            return {
                ...state,
                showProfileModal: action.payload.value,
            };
        case SET_SHOW_SETTINGS_MODAL:
            return {
                ...state,
                showSettingsModal: action.payload.value,
            };
        case SET_SHOW_PLATFORM_INFO:
            return {
                ...state,
                showPlatformInfo: action.payload.value,
            };
        case SET_SHOW_DIGITAL_BADGE:
            return {
                ...state,
                showDigitalBadge: action.payload.value,
            };
        case SET_SHOW_SIGN_OUT_DIALOG:
            return {
                ...state,
                showSignOutDialog: action.payload.value,
            };
        case FETCH_PROFILE_DATA.REQUEST:
            return {
                ...state,
                fetchingProfileData: true,
            };
        case FETCH_PROFILE_DATA.FAILURE:
            return {
                ...state,
                fetchingProfileData: false,
            };
        case FETCH_PROFILE_DATA.SUCCESS:
            return {
                ...state,
                fetchingProfileData: false,
                profileData: action.payload.profileData,
            };
        default:
            return state;
    }
};

export default authReducer;

import React, { useEffect, useState } from 'react';
import async from 'async';
import each from 'lodash/each';
import sortBy from 'lodash/sortBy';
import { Cell } from 'react-md';
import styled from 'styled-components';
import Loader from '../../../components/General/Loader';
import NavigationBar from '../../../components/DetailHeader/NavigationBar.js';
import Search from '../../../components/General/Search';
import * as palette from '../../../components/General/Variables';
import Section from '../../DetailPage/Section';
import { getPlacesByType } from '../services/floorplan';
import { DetailDrawer } from '../../../components/General/DetailDrawer';

const StyledDetailDrawer = styled(DetailDrawer)`
    z-index: 10;
`;

const Header = styled(Cell)`
    margin: 0px;
    height: 80px;
    background-color: ${palette.COLOR_WHITE};
    width: 100% !important;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0;
    z-index: 1;
    position: sticky;
    top: 0;

    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        padding-top: 10px;
    }
`;

const Scrollable = styled.section.attrs({
    className: '',
})`
    height: calc(100% - 48px);
    overflow: auto;
`;

const FloorplanDrawer = ({ visible, sections, onClose }) => {
    const [loading, setLoading] = useState(true);
    const [places, setPlaces] = useState({});
    const [searchStr, setSearchStr] = useState({});

    const getPlaces = (str, sections) => {
        let places = {};
        if (sections && sections.length) {
            let types = sections;
            types = sortBy(types, o => o.title);
            async.eachSeries(
                types,
                (item, next) => {
                    getPlacesByType(item.typeId, (err, result) => {
                        if (!err) {
                            result = sortBy(result, ['orderingName', 'name']);
                            places[item.title] = result.filter(place => {
                                return str
                                    ? place.name.toLowerCase().includes(str.toLowerCase())
                                    : true;
                            });
                            next();
                        } else {
                            next(err, null);
                        }
                    });
                },
                err => {
                    if (!err) {
                        setLoading(false);
                        setPlaces(places);
                        setSearchStr(str);
                    }
                },
            );
        }
    };

    useEffect(() => {
        if (visible) {
            getPlaces(null, sections);
        }
    }, [visible, sections]);

    const onSearch = str => {
        getPlaces(str, sections);
    };

    const closeDrawer = () => {
        onClose();
    };

    return (
        <StyledDetailDrawer>
            <NavigationBar
                onClose={closeDrawer}
                typeName={'Locations'}
                background={palette.COLOR_WHITE}
            />

            <Scrollable>
                <Header>
                    <Search
                        action={onSearch}
                        live={true}
                        startingtext={searchStr}
                        isExpandableSearch={true}
                        displayLeft={true}
                    />
                </Header>
                {loading && <Loader />}
                {!loading && (
                    <div
                        onClick={closeDrawer}
                        onKeyDown={e => {
                            if (e.key === 'Escape') {
                                closeDrawer();
                            }
                        }}
                    >
                        {Object.keys(places).map(key => {
                            if (places[key] && places[key].length) {
                                return (
                                    <Section
                                        key={key}
                                        type={'place'}
                                        title={key}
                                        items={places[key]}
                                        floorplanList
                                    />
                                );
                            }

                            return null;
                        })}
                    </div>
                )}
            </Scrollable>
        </StyledDetailDrawer>
    );
};

export default FloorplanDrawer;

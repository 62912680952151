import styled from 'styled-components';
import * as palette from '../General/Variables';

export const StyledInfo = styled.p`
    margin-top: ${props => props.margin || '0px'};
    font-size: 16px;
    text-align: center;
    color: ${palette.COLOR_TEXT};

    a {
        color: ${props => props.linkcolor};
    }
`;

export const StyledTitle = styled(StyledInfo)`
    font-size: 24px;
    font-weight: 500;
    margin: 20px 0;
`;

export const BlueButton = styled.div`
    width: 240px;
    background-color: #054289;
    color: white;
    text-align: center;
    padding: 9px;
    border-radius: 18px;
    margin-top: 50px;
    cursor: pointer;
`;

export const NoAccessImage = styled.div`
    top: 0px;
    margin-top: -30px;
    background-image: url('/images/no_access.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 300px;
    max-width: 300px;
    height: 245px;
`;

export const StyledForm = styled.form`
    min-height: ${props => (props.minHeight ? props.horizontalMargin : '450px')};
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: ${palette.COLOR_TEXT} !important;

    .md-divider--text-field::after {
        background: ${props => props.maincolor};
    }
    .md-text--theme-primary {
        color: ${props => props.maincolor};
    }
    .md-text-field-message,
    .md-floating-label,
    input::placeholder,
    textarea::placeholder {
        font-size: 12px !important;
    }
`;

export const SyncImage = styled.div`
    top: 0px;
    margin-top: -30px;
    background-image: url('/images/auth_feat_sync_your_favourites.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 300px;
    max-width: 300px;
    height: 245px;
`;

import React from 'react';
import styled from 'styled-components';
import ThemeContext from '../Theme/ThemeContext';

const Container = styled.div`
    color: ${props => props.contrastcolor};
    text-align: left;
    padding-top: 10px;
    max-width: 250px;
    white-space: break-spaces;
`;

const VirtualContainer = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.6);
`;

const StartEndTime = props => {
    const StartEndTimeWrapper = props.isVirtual ? VirtualContainer : Container;
    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <StartEndTimeWrapper contrastcolor={theme.contrast}>
                    {props.text}
                </StartEndTimeWrapper>
            )}
        </ThemeContext.Consumer>
    );
};

export default StartEndTime;

import get from 'lodash/get';
import { removePreRecordings } from '../services/VirtualEventSession';

export const onDeleteIntroductionVideo = async (stateCtx, mutationCtx) => {
    const { socket, sessionId, virtualEventUser, virtualEventSession } = stateCtx;
    const list = get(virtualEventUser, 'data.recordingInfo.list') || [];
    const videoUrls = get(virtualEventSession, 'data.videoUrls');

    if (list.length || Array.isArray(videoUrls)) {
        mutationCtx.setExtraState({
            primaryVideoDuration: 0,
            secondaryVideoDuration: 0,
            duration: null,
            playing: false,
            playedSeconds: 2,
            primaryPlayerRef: null,
            secondaryPlayerRef: null,
        });
        await removePreRecordings(sessionId, virtualEventUser.data.UserId);
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        recordingInfo: null,
                        hasVideoActive: false,
                        preRecordedPresentationUrl: null,
                        isPrerecording: false,
                    },
                ],
                videoUrls: null,
            },
        });
    } else {
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        recordingInfo: null,
                        hasVideoActive: false,
                        preRecordedPresentationUrl: null,
                        isPrerecording: false,
                    },
                ],
                videoUrls: null,
            },
        });
    }
};

import React, { useEffect, useRef } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { useGlobalMutation } from '../../../../utils/container';
import { TOAST_TYPES } from './toasts';
import get from 'lodash/get';

StreamPlayer.propTypes = {
    stream: PropTypes.object,
};
const Container = styled.div`
    position: relative;
    ${props =>
        props.maxwidthandheight &&
        `
        height: 100%;
        width: 100%;
        overflow: hidden;
    `}

    &.video-on,
    [id*='player'] {
        min-height: inherit;
        background-color: #fff !important;
    }

    video {
        display: block !important;
        ${props =>
            props.containContainer &&
            `
            object-fit: contain !important;
            background: #000 !important;
            `};
    }
`;

function StreamPlayer(props) {
    const mutationCtx = useGlobalMutation();

    const { stream, domId, isVideoOn, muted, roundTableNoSlides, isPlaying } = props;
    const isStreamPlaying = stream && stream.isPlaying();
    const containerRef = useRef(null);

    AgoraRTC.onAudioAutoplayFailed = () => {
        mutationCtx.setShowToast(TOAST_TYPES.AUDIO);

        const resumeHandler = () => {
            try {
                stream.resume();
            } catch (err) {
                //
            }

            mutationCtx.setShowToast(false);
            window.removeEventListener('click', resumeHandler);
        };

        window.addEventListener('click', resumeHandler);
    };

    const playStream = () => {
        stream.play({
            domId,
            muted,
        });
    };

    useEffect(() => {
        if (!isStreamPlaying) {
            playStream();
        }
    }, [isStreamPlaying]);

    useEffect(() => {
        if (!stream) return;

        playStream();
    }, [stream, domId, muted, get(stream, 'user.videoTrack'), get(stream, 'user.audioTrack')]);

    useEffect(() => {
        return () => {
            if (stream.isPlaying()) {
                stream.stop();
            }
        };
    }, []);

    return (
        <Container
            innerRef={containerRef}
            className={`stream-player ${isVideoOn ? 'video-on' : ''}`}
            id={domId}
            data-qa={props['data-qa']}
            containContainer={
                props.location.pathname && props.location.pathname.includes('virtual-session-room')
            }
            maxwidthandheight={roundTableNoSlides ? 1 : 0}
        >
            {props.children}
        </Container>
    );
}

export default withRouter(StreamPlayer);

import React, { useEffect } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { useVMMutation, useVMState } from '../../containers/main';
import { TOAST_TYPES } from '../../utils/toasts';
import { RedStyledChip } from '../moderator/common/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import entities from '../../constants/entities';
import { TEN_MILL } from '../../constants/values';

StreamPlayer.propTypes = {
    stream: PropTypes.object,
};

const Container = styled.div`
    ${props => !props.nopositionrelative && 'position: relative;'}

    &.stream-player.video-on {
        display: contents !important;
    }

    ${props =>
        !props.black &&
        css`
            [id*='player'] {
                background: #fff !important;
                ${props => (props.isPanelDiscussion ? '' : 'border-radius: 8px')};
            }
        `}

    &.video-on,
    [id*='player'] {
        min-height: inherit;
        ${props => !props.nopositioninitial && 'position: initial !important;'}
    }

    video {
        display: block !important;
        ${props => props.objectfit && `object-fit: ${props.objectfit} !important;`}
    }
`;

const PositionAbsoluteRedChip = styled(RedStyledChip)`
    position: absolute;
    z-index: 10;
    top: 9px;
    left: 9px;
    width: 38px;
`;

const AccountCircleIconWrapper = styled.div`
    font-size: 48px;
    opacity: ${props => (props.imageUrl ? 1 : 0.2)};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${props => props.imageUrl}');
    width: 48px;
    height: 48px;
    border-radius: 50%;
`;

export default function StreamPlayer(props) {
    const mutationCtx = useVMMutation();
    const stateCtx = useVMState();
    const { virtualEventSession, streams, virtualEventUser } = stateCtx;
    const {
        stream,
        domId,
        isVideoOn,
        muted,
        black,
        isPanelDiscussion,
        displayLiveChip,
        imageUrl,
        displayImagePlaceholder,
        isActiveSpeaker,
        noPositionRelative,
        videoObjectFit,
        noPositionInitial,
    } = props;
    const sessionStatus = get(virtualEventSession, 'data.status');
    const isSessionLive = entities.virtualEventSessionEntity.status.broadcasting === sessionStatus;
    const virtualEventUsers = get(virtualEventSession, 'data.VirtualEventUsers') || [];
    const isPrerecording = get(virtualEventUser, 'data.isPrerecording') || false;
    const virtualEventUserRole = get(virtualEventUser, 'data.role');
    const isRoundTableHost =
        virtualEventUserRole === entities.virtualEventUserEntity.role.roundTableHost;
    const isBroadcasting =
        get(virtualEventSession, 'data.status') ===
        entities.virtualEventSessionEntity.status.broadcasting;

    AgoraRTC.onAudioAutoplayFailed = () => {
        mutationCtx.setShowToast(TOAST_TYPES.AUDIO);

        const resumeHandler = () => {
            try {
                stream.resume();
            } catch (err) {
                //
            }

            mutationCtx.setShowToast(false);
            window.removeEventListener('click', resumeHandler);
        };

        window.addEventListener('click', resumeHandler);
    };

    useEffect(() => {
        const playStream = () => {
            stream.play({
                domId,
                muted,
            });
        };

        if (!stream) return;

        playStream();
    }, [stream, domId, muted, isActiveSpeaker]);

    useEffect(() => {
        if (isPrerecording && !isBroadcasting) {
            streams.forEach(st => {
                if (
                    st.streamId !== virtualEventUser.UserId &&
                    st.streamId !== virtualEventUser + TEN_MILL &&
                    !isRoundTableHost
                ) {
                    st.stop();
                }
            });
        }
    }, [streams, isPrerecording]);

    useEffect(() => {
        virtualEventUsers.forEach(vUser => {
            if (vUser.isPrerecording && !isBroadcasting) {
                const streamForUser = streams.find(st => st.streamId === vUser.UserId);
                const shouldStopStream =
                    streamForUser &&
                    streamForUser.streamId !== virtualEventUser.UserId &&
                    streamForUser.streamId !== virtualEventUser + TEN_MILL &&
                    !isRoundTableHost;

                if (shouldStopStream) {
                    streamForUser.stop();
                }
            }
        });
    }, [streams, virtualEventUsers]);

    useEffect(() => {
        return () => {
            if (stream.isPlaying()) {
                stream.stop();
            }
        };
    }, []);

    return (
        <Container
            className={`stream-player ${isVideoOn ? 'video-on' : ''}`}
            id={domId}
            black={black}
            data-qa={props['data-qa']}
            isPanelDiscussion={isPanelDiscussion}
            nopositionrelative={noPositionRelative ? 1 : 0}
            objectfit={videoObjectFit ? videoObjectFit : 0}
            nopositioninitial={noPositionInitial ? 1 : 0}
        >
            {isSessionLive && displayLiveChip && (
                <PositionAbsoluteRedChip>LIVE</PositionAbsoluteRedChip>
            )}
            {displayImagePlaceholder && (!isVideoOn || isActiveSpeaker) && (
                <AccountCircleIconWrapper imageUrl={imageUrl}>
                    {!imageUrl && <AccountCircleIcon fontSize="inherit" />}
                </AccountCircleIconWrapper>
            )}
            {props.children}
        </Container>
    );
}

export default {
    session: {
        live: 'Live',
        notLive: 'Not live',
    },

    presenter: {
        active: 'You are presenting',
        inactive: 'Another speaker is presenting',
        activeSubtitle:
            'Audience can see your presentation. Make sure your camera and microphone are on.',
        inactiveSubtitle: `Be aware that the audience \n can hear you`,
        inactiveSubtitle_NO_MICROPHONE: `Be aware that the audience can \n hear you when your unmute yourself`,
        activePanelDiscussion: 'Panel',
        activePanelDiscussionSubtitle: 'Panel - you are presenting',
        panelDiscussionSubtitle:
            ' - You will be part of the discussion when your microphone and camera are on.',
        notLiveSubtitle: `Only other speakers can hear \n you when your microphone is on.`,
        preRecording: `Others cannot hear or see you when you are pre-recording`,
    },
    moderator: {
        TEXT_NOT_BROADCASTING: 'Only other speakers can hear you if your microphone is on.',
        TEXT_BROADCASTING_MICROPHONE_ON: 'Be aware that the audience can hear you',
        TEXT_BROADCASTING_MICROPHONE_OFF:
            'Be aware that the audience \n can hear you when you unmute yourself',
        TEXT_NOT_BROADCASTING_MICROPHONE_ON: 'Be aware that other speakers can hear you',
        TEXT_NOT_BROADCASTING_MICROPHONE_OFF:
            'Be aware that other speakers \n can hear you when you unmute yourself',
        TEXT_ACTIVE: 'You are presenting',
        TEXT_ACTIVE_NO_STREAMS: ' - Please note that your camera and microphone are still off.',
        TEXT_ACTIVE_NO_MICROPHONE: ' - Please note that your microphone is still off.',
        TEXT_ACTIVE_NO_CAMERA: ' - Please note that your camera is still off.',
        panelDiscussion: {
            TEXT_ACTIVE_NO_STREAMS:
                ' - You will be a part of the discussion when your microphone and camera are on.',
        },
    },
};

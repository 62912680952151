import moment from 'moment';
import { eurekaRequest } from '../../../../../services/api/eureka';

const callEureka = (method, url, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(method, url, data, true, (err, response) => {
            if (err) {
                reject(`Eureka Talk error: ${JSON.stringify(err)}`);
            } else {
                resolve(response);
            }
        });
    });
};

const callEurekaApi = ({ method = 'get', url, data = {} }) => {
    url = url[0] !== '/' ? `/${url}` : url;
    const timeoutPromise = new Promise((resolve, reject) => {
        const timeout = 3000;
        const id = setTimeout(() => {
            clearTimeout(id);
            reject(`${method} ${url} timed out in ${timeout} ms.`);
        }, timeout);
    });
    return Promise.race([callEureka(method, url, data), timeoutPromise]);
};

export const getEurekaInfoWithJid = (jids, getFullError) =>
    callEurekaApi(
        {
            url: '/api/entities/chatRooms/chatUsers',
            method: 'post',
            data: { jids },
        },
        getFullError,
    );

export const getConversations = data =>
    callEurekaApi({
        url: 'api/entities/chatRooms/findRoomsPerUser',
        method: 'post',
        data,
    });

export const getGroupChatMessages = ({ room, limit, lastTimestamp }) => {
    let url = `api/services/chat/getgroupmessages?room=${room}&limit=${limit}`;
    if (lastTimestamp) {
        url = `${url}&last_stamp=${lastTimestamp}`;
    }

    return callEurekaApi({
        url,
    });
};

export const registerChatUser = () =>
    callEurekaApi({
        url: 'api/services/chat/register',
        method: 'post',
    });

/**
 * @method getGroupChatParticipants
 * @description It's the only method that allows us to fetch all participants in a group chat
 * passing only the external object ID (like a session or exhibitor ID). Sometimes this method is used to
 * get the roomId from the first participant properties
 * @param {Object} data
 * @param {String} data.objectId external object ID, like a session or exhibitor ID
 * @param {Boolean} [data.moderatorOnly] gonna fetch for participants in a moderator's only room
 */
export const getGroupChatParticipants = data =>
    callEurekaApi({
        url: 'api/entities/chatRooms/roomUsers',
        method: 'post',
        data,
    });

/**
 * @method getPrivateChatMessages
 * @description Fetch all messages history between current user and friend (from)
 * @param {String} userId current logged user id
 * @param {String} from the target user (AKA friend) Jid + @ + chatHost for example: 136@devtalk.get-eureka.com
 * @param {String} limit How many messages to fetch, default should be 25
 * @param {String} lastStamp last fetched message timestamp
 * @returns {Array} Returns an array of messages
 */
export const getPrivateChatMessages = ({ userId, from, limit, lastStamp }) => {
    let url = `api/services/chat/getmessages?user=${userId}&peer=${from}&limit=${limit}`;
    if (lastStamp) {
        url = `${url}&last_stamp=${lastStamp}`;
    }
    return callEurekaApi({
        url,
    });
};

/**
 * @method startGroupChat
 * @description Creates a chat room for the provided external object ID if it doesn't exist
 * @param {Object} data
 * @param {String} data.objectId external object ID, like a session or exhibitor ID
 * @param {String} data.objectTitle name of the external object
 * @param {String} data.eventId the ID of the event where the external object exists
 * @param {Boolean} [data.moderatorOnly] gonna fetch for participants in a moderator's only room
 */
export const startGroupChat = data =>
    callEurekaApi({
        url: 'api/entities/chatRooms/enter',
        method: 'post',
        data,
    });

/**
 * @method reportOrDeleteGroupChatMessage
 * @description Reports or deletes a chat message
 * @param {Object} message complete message object with all infos needed to construct post data
 * @param {String} conversationId where this message is coming from
 * @param {Boolean} deleteMessage when *false* the message is reported. When *true* the message is really deleted
 */
export const reportOrDeleteGroupChatMessage = (message, conversationId, deleteMessage = false) => {
    const { id, nick, timestamp, txt } = message;

    // "just sent" messages have timestamp as an temporary ID
    const messageWithId = id.toString() !== timestamp.toString();
    const messageTimestamp = parseInt(timestamp, 10) / 1000; // Admin UI requires date in this format
    const data = {
        ...(messageWithId && { ejabberdId: id }), // we shouldn't include ejabberdId prop if message has no ID yet
        reason: deleteMessage ? 'User deleted' : 'Bad content',
        chatRoomId: conversationId,
        from: nick,
        message: `${txt}@:${messageTimestamp}`,
        timestamp,
        delete: deleteMessage,
    };
    return callEurekaApi({
        url: 'api/entities/chatObjections',
        method: 'post',
        data,
    });
};

/**
 * @method downloadRoomHistory
 * @description Fetch all messages history sent in this room and downloads it as a CSV file
 * @param {String} sessionId current logged user id
 * @returns {Array} Returns an array of message objects
 */
export const downloadRoomHistory = async sessionId => {
    if (!sessionId) {
        console.error('Null sessionId on downloadRoomHistory');
        return;
    }

    const url = `api/services/chat/downloadRoomHistory/${sessionId}`;
    const history = await callEurekaApi({ url });

    return history.map(message => {
        const timestamp = parseInt(message.date * 0.001, 10);
        const newDate = moment(timestamp).locale('en').format('YYYY-MM-DD HH:mm:ss');
        return { ...message, date: newDate };
    });
};

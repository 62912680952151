import React from 'react';
import { useSpring, animated } from 'react-spring';
import { FontIcon } from 'react-md';
import styled from 'styled-components';

import { useTheme } from '../../Theme/ThemeContext';

const Button = styled(animated.button)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    bottom: 84px;
    right: 12px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(0, 0, 0, 0.15),
        0px 3px 3px rgba(0, 0, 0, 0.05);
`;

const ICON_NAME = 'expand_more';

const ScrollDownButton = ({ onClick, hidden }) => {
    const { theme } = useTheme();
    const buttonProps = useSpring({
        to: { opacity: 1, transform: 'scale(1, 1)' },
        from: { opacity: 0, transform: 'scale(0, 0)' },
        config: { duration: 150 },
        reverse: hidden,
    });

    return (
        <Button
            onClick={onClick}
            style={buttonProps}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    onClick();
                }
            }}
        >
            <FontIcon style={{ color: theme.primary, fontSize: 26 }}>{ICON_NAME}</FontIcon>
        </Button>
    );
};

export default ScrollDownButton;

import React, { useEffect, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Auth from '../../services/api/auth';
import AuthUserContext from '../Session/AuthUserContext';
import { Background } from '../../scenes/SignInPage/components';
import { default as Store } from '../../services/api/store';
import { getEventDetails, getImageSource, getLocalAppStateAsync } from '../../services/api/db';
import useSocietySettings from '../../scenes/Society/hooks/useSocietySettings';

const AuthenticationProtection = ({
    children,
    routeProtection,
    match,
    history,
    location,
    fullScreen,
}) => {
    const [allowed, setAllowed] = useState(false);
    const isAuthenticated = Auth.isUserAuthenticated();
    const { isSociety } = useSocietySettings();
    const { showSignIn } = useContext(AuthUserContext);
    const [signInImage, setSignInImage] = useState('');
    const [appState, setAppState] = useState(null);
    const [eventDetails, setEventDetails] = useState(null);
    const replace = !!routeProtection;
    const signInBackground = Store.argument && Store.argument.signInBackground;

    useEffect(() => {
        if (!isAuthenticated) {
            setAllowed(false);
            showSignIn(null, { match, history, location, replace, fullScreen });
            return;
        }
        setAllowed(true);
    }, [isAuthenticated, showSignIn]);

    useEffect(() => {
        (async () => {
            const localAppState = await getLocalAppStateAsync();
            setAppState(localAppState);
            if (!isSociety) {
                const eventDetails = await getEventDetails(
                    localAppState.eventId,
                    localAppState.configuratorUrl,
                );
                setEventDetails(eventDetails);
            } else {
                setEventDetails({
                    banner: localAppState.societyLogoUrl,
                    name: localAppState.appName,
                    society: true,
                });
            }
            if (isSociety && localAppState && localAppState.signInBackgroundImageUrl) {
                setSignInImage(localAppState.signInBackgroundImageUrl);
            } else if (signInBackground) {
                getImageSource(signInBackground, (err, img) => {
                    setSignInImage(img);
                });
            }
        })();
    }, [isSociety, signInBackground]);

    if (allowed) {
        return children;
    } else {
        return <Background image={signInImage} />;
    }
};

export default withRouter(AuthenticationProtection);

import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import React from 'react';

export const ConfirmationModel = ({
    showModal,
    title,
    text,
    confirm,
    cancel,
    confirmText,
    cancelText,
}) => (
    <ConfirmDialog
        visible={showModal}
        options={{
            title: title,
            text: text,
            confirmText: confirmText,
            cancelText: cancelText,
            confirm: confirm,
            cancel: cancel,
        }}
        onHideDialog={cancel}
    />
);

import React from 'react';
import styled from 'styled-components';

import SessionSidebar from './sessionSidebar/SessionSidebar';
import SlidesAndPresentationsContainer from '../common/SlidesAndPresentationsContainer';
import Recordings from './sessionView/preRecordingsAndVideos/Recordings';
import VideoAndUserInteractionContainer from './sessionView/VideoAndUserInteractionContainer';
import ModeratorQuestionsList from '../../../Votes/containers/ModeratorQuestionsList';
import UserInteraction from './userInteraction';
import useStreamHandling from '../../hooks/useStreamHandling';
import usePollSet from '../../../Votes/hooks/usePollSet';
import useVote from '../../../Votes/hooks/useVote';
import ModeratorVote from '../../../Votes/containers/ModeratorVote';

const ModeratorWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ModeratorContentWrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-flow: row nowrap;
    overflow: hidden !important;
    padding: 8px;
    background: #f1f1f3;
    height: 100%;
    @media (max-height: 800px) and (orientation: landscape) {
        justify-content: center;
    }
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    margin: 0 24px 0 0;
    justify-content: space-between;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 0;
    @media (max-height: 800px) and (orientation: landscape) {
        max-width: 400px;
    }
`;

const SpeakersAndRecordingsContainer = styled.div`
    display: flex;
    flex: 2;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 0;
    overflow-y: scroll;
    @media (max-height: 800px) and (orientation: landscape) {
        max-width: 400px;
    }
`;

const Padding16px = styled.span`
    padding: 16px 16px 0 16px;
`;

const SessionVoteAndAsk = styled.div`
    flex: 1;
    position: relative;
    bottom: 1px;
    z-index: 2;
    height: 300px;
    min-height: 0;
`;

export default () => {
    const { pollSet, changeFromPollsActiveRef } = usePollSet();
    const useVoteProps = useVote({ pollSet });
    const { localStream } = useStreamHandling();
    const { isUserModeratorAndActivatedAQuestion } = useVoteProps;

    if (isUserModeratorAndActivatedAQuestion()) {
        return (
            <ModeratorWrapper>
                <ModeratorContentWrapper>
                    <ModeratorVote
                        pollSet={pollSet}
                        changeFromPollsActiveRef={changeFromPollsActiveRef}
                        {...useVoteProps}
                    />
                    <FlexColumn style={{ marginRight: 0 }}>
                        <Padding16px>
                            <VideoAndUserInteractionContainer isVotingActive />
                        </Padding16px>

                        <SessionVoteAndAsk>
                            <UserInteraction controls={true} />
                        </SessionVoteAndAsk>
                    </FlexColumn>
                </ModeratorContentWrapper>
            </ModeratorWrapper>
        );
    }

    return (
        <ModeratorWrapper>
            <ModeratorContentWrapper>
                <FlexColumn style={{ justifyContent: 'flex-start' }}>
                    <Padding16px>
                        <SlidesAndPresentationsContainer />
                    </Padding16px>
                    <ModeratorQuestionsList pollSet={pollSet} {...useVoteProps} />
                </FlexColumn>
                <FlexColumn>
                    <Padding16px>
                        <VideoAndUserInteractionContainer />
                    </Padding16px>

                    <SessionVoteAndAsk>
                        <UserInteraction controls={true} />
                    </SessionVoteAndAsk>
                </FlexColumn>
                <SpeakersAndRecordingsContainer>
                    <SessionSidebar localStream={localStream} />
                    <Recordings />
                </SpeakersAndRecordingsContainer>
            </ModeratorContentWrapper>
        </ModeratorWrapper>
    );
};

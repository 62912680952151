import React, { useEffect, useState } from 'react';
import MenuButton from 'react-md/lib/Menus/MenuButton';
import { CheckFontIcon } from '@react-md/material-icons';
import styled from 'styled-components';
import AgoraRTC from 'agora-rtc-sdk-ng';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import { LightTooltip } from '../../../VirtualModeration/features/virtualFeature/components/moderator/common/styles';
import { colorTheme } from '../../../VirtualModeration/features/virtualFeature/constants/colors';
import RightSectionFooter from './RightSectionFooter';

const Wrapper = styled.div`
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    display: flex;
    flex-flow: row;
    height: 72px;
    padding: 0 24px;
    margin-top: 80px;
    left: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 4;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;

const GreyRoundedButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: ${props => (props.isoff ? colorTheme.SYSTEM_ERROR : colorTheme.SECONDARY)};
    color: ${props => (props.isoff ? colorTheme.WHITE : colorTheme.BLACK)};
    cursor: pointer;
    ${props => !props.nomarginright && 'margin-right: 8px;'}
`;

const SettingsWrapper = styled.div`
    #settings-list,
    #video-audio-input-list {
        display: flex;
        flex-direction: column;
    }

    [id*='-toggle'] {
        background: #fff !important;
        color: #000 !important;
        box-shadow: none !important;
        font-family: Roboto, sans-serif;
        font-weight: 500;
        font-size: 15px !important;

        &:hover {
            background: rgba(0, 0, 0, 0.12) !important;
            box-shadow: none !important;
        }
    }

    [id^='settings-toggle'] {
        width: 40px !important;
        height: 40px !important;
        border-radius: 50% !important;
        background-color: ${colorTheme.SECONDARY} !important;
    }

    #settings-toggle {
        min-width: 40px !important;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    #video-audio-input,
    #video-settings,
    #audio-settings {
        height: 48px !important;
    }

    #video-audio-input-toggle,
    #video-settings-toggle,
    #audio-settings-toggle {
        display: flex;
        align-items: center;
        height: inherit !important;
        margin: 0 4px;
        border-radius: 4px;
    }

    [id^='camera-option'],
    [id^='audio-option'] {
        display: flex;
        align-items: center;
        height: inherit !important;
        margin: 0 4px;
        border-radius: 4px;
    }

    .md-list-item {
        height: 48px;
    }

    .md-tile-content {
        padding-left: 16px;
    }

    .md-list-tile {
        padding-left: 16px !important;
    }

    .md-btn--text {
        text-transform: none !important;
    }

    .md-tile-text--primary {
        font-family: Roboto, sans-serif;
        font-weight: 500;
        font-size: 15px !important;
    }
`;

const Footer = props => {
    const mutationCtx = useGlobalMutation();
    const stateCtx = useGlobalState();
    const [isMicOn, setIsMicOn] = useState(true);
    const [isVidOn, setIsVidOn] = useState(true);
    const { config, cameraList, microphoneList, mutedBySystem } = stateCtx;

    const { id, timeslotId } = props.currentVirtualEventUser;

    useEffect(() => {
        const { isMicrophoneOn, isVideoOn } = props.currentVirtualEventUser;
        setIsMicOn(isMicrophoneOn);
        setIsVidOn(isVideoOn);
    }, [props.currentVirtualEventUser]);

    const toggleAudio = async () => {
        if (mutedBySystem) {
            mutationCtx.setMicMutedDialog(true);
            return;
        }

        const isNowMicOn = isMicOn;

        setIsMicOn(!isNowMicOn);

        await props.updateVirtualEventUser({
            id,
            isMicrophoneOn: !isNowMicOn,
        });

        if (props.socket) {
            props.socket.emit('updateData', { objectId: timeslotId });
        }
    };

    const toggleVideo = async () => {
        const isNowVidOn = isVidOn;
        setIsVidOn(!isNowVidOn);

        await props.updateVirtualEventUser({
            id,
            isVideoOn: !isNowVidOn,
        });

        if (props.socket) {
            props.socket.emit('updateData', { objectId: timeslotId });
        }
    };

    const cameraOptions = cameraList.map(camera => ({
        primaryText: camera.label,
        onClick: () => {
            mutationCtx.changeCamera(camera.deviceId);
        },
        rightIcon: config.cameraId === camera.deviceId ? <CheckFontIcon /> : undefined,
    }));
    const microphoneOptions = microphoneList.map(microphone => ({
        primaryText: microphone.label,
        onClick: () => {
            mutationCtx.changeMicrophone(microphone.deviceId);
        },
        rightIcon: config.microphoneId === microphone.deviceId ? <CheckFontIcon /> : undefined,
    }));

    useEffect(() => {
        AgoraRTC.onMicrophoneChanged = deviceInfo => {
            mutationCtx.startUsingDevices(
                undefined,
                deviceInfo.state === 'ACTIVE' ? deviceInfo.device.deviceId : undefined,
                undefined,
                deviceInfo.state === 'INACTIVE',
            );
        };

        AgoraRTC.onCameraChanged = deviceInfo => {
            mutationCtx.startUsingDevices(
                undefined,
                undefined,
                deviceInfo.state === 'ACTIVE' ? deviceInfo.device.deviceId : undefined,
                deviceInfo.state === 'INACTIVE',
            );
        };
    }, []);

    return (
        <Wrapper>
            <div />
            <ButtonsContainer>
                <LightTooltip title={isVidOn ? 'Turn off camera' : 'Turn on camera'}>
                    <GreyRoundedButton isoff={!isVidOn ? 1 : 0} onClick={toggleVideo}>
                        {isVidOn ? <VideocamOutlinedIcon /> : <VideocamOffOutlinedIcon />}
                    </GreyRoundedButton>
                </LightTooltip>
                <LightTooltip
                    title={isMicOn && !mutedBySystem ? 'Turn off microphone' : 'Turn on microphone'}
                >
                    <GreyRoundedButton
                        isoff={!isMicOn || mutedBySystem ? 1 : 0}
                        onClick={toggleAudio}
                    >
                        {isMicOn && !mutedBySystem ? (
                            <MicNoneOutlinedIcon />
                        ) : (
                            <MicOffOutlinedIcon />
                        )}
                    </GreyRoundedButton>
                </LightTooltip>
                <LightTooltip title="Select source">
                    <SettingsWrapper>
                        <MenuButton
                            adjusted={0}
                            raised
                            primary
                            id="settings"
                            menuItems={[
                                <MenuButton
                                    adjusted={0}
                                    raised
                                    primary
                                    key="video-settings"
                                    id="video-settings"
                                    menuItems={cameraOptions}
                                    simplifiedMenu={false}
                                    anchor={{
                                        x: MenuButton.HorizontalAnchors.RIGHT,
                                        y: MenuButton.VerticalAnchors.TOP,
                                    }}
                                    repositionOnScroll={false}
                                >
                                    Video Source
                                </MenuButton>,
                                <MenuButton
                                    adjusted={0}
                                    raised
                                    primary
                                    key="audio-settings"
                                    id="audio-settings"
                                    menuItems={microphoneOptions}
                                    simplifiedMenu={false}
                                    anchor={{
                                        x: MenuButton.HorizontalAnchors.RIGHT,
                                        y: MenuButton.VerticalAnchors.TOP,
                                    }}
                                    repositionOnScroll={false}
                                >
                                    Audio Source
                                </MenuButton>,
                            ]}
                            simplifiedMenu={false}
                            anchor={{
                                x: MenuButton.HorizontalAnchors.RIGHT,
                                y: MenuButton.VerticalAnchors.TOP,
                            }}
                            repositionOnScroll={false}
                        >
                            <MoreHorizOutlinedIcon />
                        </MenuButton>
                    </SettingsWrapper>
                </LightTooltip>
            </ButtonsContainer>
            <RightSectionFooter />
        </Wrapper>
    );
};

export default Footer;

import React from 'react';
import styled from 'styled-components';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import PresentToAllOutlinedIcon from '@mui/icons-material/PresentToAllOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';

import { useVMState } from '../../containers/main';
import { colorTheme } from '../../constants/colors';
import { LightTooltip } from '../moderator/common/styles';
import useMicAndCamera from '../../hooks/useMicAndCamera';
import SelectSourceMenu from './SelectSourceMenu';
import { isBoolean } from 'lodash';
import get from 'lodash/get';

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;

const GreyRoundedButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: ${props => (props.isoff ? colorTheme.SYSTEM_ERROR : colorTheme.SECONDARY)};
    color: ${props => (props.isoff ? colorTheme.WHITE : colorTheme.BLACK)};
    cursor: pointer;
    border: none;
    ${props => !props.nomarginright && 'margin-right: 8px;'}
`;

const MiddleSectionFooter = () => {
    const stateCtx = useVMState();
    const { virtualEventUser, mutedBySystem, screenShareStream, shareType, timeslot } = stateCtx;
    const { isMicrophoneOn, isVideoOn } = virtualEventUser.data;
    const { toggleScreenSharing, toggleMic, toggleCamera } = useMicAndCamera();
    const isScreenSharing = screenShareStream && shareType === 'screen';
    const allowHostToStartMeeting = isBoolean(get(timeslot, 'allowHostToStartMeeting'))
        ? get(timeslot, 'allowHostToStartMeeting')
        : true;
    const allowHostToRecordAudioDescription = isBoolean(
        get(timeslot, 'allowHostToRecordAudioDescription'),
    )
        ? get(timeslot, 'allowHostToRecordAudioDescription')
        : true;
    const allowHostToRecordInstructionVideo = isBoolean(
        get(timeslot, 'allowHostToRecordInstructionVideo'),
    )
        ? get(timeslot, 'allowHostToRecordInstructionVideo')
        : true;

    const showMicrophoneButton =
        allowHostToStartMeeting ||
        allowHostToRecordAudioDescription ||
        allowHostToRecordInstructionVideo;
    const showCameraButton = allowHostToStartMeeting || allowHostToRecordInstructionVideo;
    const showScreenSharingButton = allowHostToStartMeeting;
    const showMoreButton = allowHostToStartMeeting || showMicrophoneButton || showCameraButton;

    return (
        <ButtonsContainer>
            {showCameraButton && (
                <LightTooltip title={isVideoOn ? 'Turn off camera' : 'Turn on camera'}>
                    <GreyRoundedButton
                        isoff={!isVideoOn ? 1 : 0}
                        onClick={toggleCamera}
                        onKeyDown={event => event.key === 'Enter' && toggleCamera()}
                    >
                        {isVideoOn ? <VideocamOutlinedIcon /> : <VideocamOffOutlinedIcon />}
                    </GreyRoundedButton>
                </LightTooltip>
            )}
            {showMicrophoneButton && (
                <LightTooltip
                    title={
                        isMicrophoneOn && !mutedBySystem
                            ? 'Turn off microphone'
                            : 'Turn on microphone'
                    }
                >
                    <GreyRoundedButton
                        isoff={!isMicrophoneOn || mutedBySystem ? 1 : 0}
                        onClick={toggleMic}
                        onKeyDown={event => event.key === 'Enter' && toggleMic()}
                    >
                        {isMicrophoneOn && !mutedBySystem ? (
                            <MicNoneOutlinedIcon />
                        ) : (
                            <MicOffOutlinedIcon />
                        )}
                    </GreyRoundedButton>
                </LightTooltip>
            )}
            {showScreenSharingButton && (
                <LightTooltip title={isScreenSharing ? 'Stop screen sharing' : 'Share screen'}>
                    <GreyRoundedButton
                        isoff={isScreenSharing ? 1 : 0}
                        onClick={toggleScreenSharing}
                        onKeyDown={event => event.key === 'Enter' && toggleScreenSharing()}
                    >
                        {isScreenSharing ? (
                            <CancelPresentationOutlinedIcon />
                        ) : (
                            <PresentToAllOutlinedIcon />
                        )}
                    </GreyRoundedButton>
                </LightTooltip>
            )}
            {showMoreButton && (
                <LightTooltip title="Select source">
                    <SelectSourceMenu isHostedSession />
                </LightTooltip>
            )}
        </ButtonsContainer>
    );
};

export default MiddleSectionFooter;

import React from 'react';
import styled from 'styled-components';

const Tracker = styled.div`
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: ${props => props.barColor || '#4D4D4D'};
    position: relative;
`;

const ProgressInTracker = styled.div`
    height: 100%;
    width: ${props => props.precentage}%;
    background-color: ${props => props.progressColor || '#fea800'};
    border-radius: 2px;
    position: absolute;
    z-index: ${props => (props.firstColor ? '2' : '1')};
`;

const ProgressBar = ({ precentage, barColor, progressColor, className }) => (
    <Tracker barColor={barColor} className={className}>
        <ProgressInTracker precentage={precentage} progressColor={progressColor} firstColor />
    </Tracker>
);

export default ProgressBar;

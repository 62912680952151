import AgoraRTC from 'agora-rtc-sdk-ng';

export default () => {
    const promiseResolve = async (resolve, reject) => {
        let cameraList;
        let microphoneList;

        try {
            cameraList = await AgoraRTC.getCameras();
        } catch (err) {
            cameraList = [];
        }

        try {
            microphoneList = await AgoraRTC.getMicrophones();
        } catch (err) {
            microphoneList = [];
        }

        resolve({
            cameraList,
            microphoneList,
        });

        if (!cameraList.length && !microphoneList.length) {
            navigator.mediaDevices
                .getUserMedia({
                    audio: true,
                    video: true,
                })
                .then(stream => {
                    stream.getTracks().forEach(track => {
                        track.stop();
                    });
                    promiseResolve(resolve, reject);
                })
                .catch(err => {
                    if (err) {
                        reject(err);
                    }
                });
        }
    };

    return new Promise(promiseResolve);
};

let microphoneAllowed = false;
let cameraAllowed = false;

const isAudioOrCameraAllowed = constraints => {
    return new Promise(resolve => {
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(stream => {
                stream.getTracks().forEach(track => {
                    track.stop();
                });
                resolve(true);
            })
            .catch(() => resolve(false));
    });
};

export const isMicrophoneAllowed = async () => {
    if (!microphoneAllowed) {
        microphoneAllowed = await isAudioOrCameraAllowed({ audio: true });
    }

    return microphoneAllowed;
};

export const isCameraAllowed = async () => {
    if (!cameraAllowed) {
        cameraAllowed = await isAudioOrCameraAllowed({ video: true });
    }

    return cameraAllowed;
};

import React from 'react';
import { Slider as MDSlider } from 'react-md';
import { useTheme } from '../Theme/ThemeContext';
import styled from 'styled-components';

const MyStyledSlider = styled(MDSlider)`
    .md-slider-thumb,
    .md-slider-track-fill {
        background-color: ${props => props.color};
    }

    .md-slider-track {
        background: #ebedf0;
    }

    .md-slider-thumb--continuous-off {
        border: unset;
    }
`;

const Slider = ({ min, max, value, onChange, step }) => {
    const { theme } = useTheme();

    return (
        <MyStyledSlider
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChange}
            color={theme.primary}
        />
    );
};

export default Slider;

import * as React from 'react';
import Button from '../../virtualFeature/components/common/Button';

const SubmitButton = props => {
    const regularStyle = {
        position: 'relative',
        height: '40px',
        transition: 'width 0.5s ease-out',
        margin: '24px 0 0 36px',
        borderRadius: '6px',
    };
    const loadingStyle = {
        width: '56px',
        height: '40px',
        borderRadius: '6px',
        border: 'none',
        position: 'relative',
        margin: '24px 0 0 36px',
        transition: 'width 0.5s ease-out',
        cursor: 'default',
    };
    const extraProp = { style: props.loading ? loadingStyle : regularStyle };

    return <Button {...props} extra={extraProp} />;
};

export default SubmitButton;

import { each } from 'async';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import {
    executeListQuery,
    executeQueryWithCallback,
    getItem,
} from '../../../services/api/graphQlRepository';
import pull from 'lodash/pull';
import get from 'lodash/get';

const getSearchPage = function (pageId, next) {
    getItem('pages', pageId, (err, page) => {
        if (page) {
            next(null, page);
        } else {
            next(err, null);
        }
    });
};

/**
 * @summary Returns a filtered collection of elements for a search query.
 * @returns {Promise<any>}
 * @param target
 */

const translateTargetToQuery = target => {
    return `find${target}s`;
};

const keysToSearchFor = ['name', 'subNameDetail', 'subNameList', 'searchTerms', 'info'];

const searchTermsMatchesData = (obj, queryArray, keys = keysToSearchFor) => {
    let matches = false;

    for (let i = 0; i < keys.length; i += 1) {
        const str = get(obj, keys[i], '') || '';
        matches =
            matches ||
            queryArray.every(search => str.toLowerCase().indexOf(search.toLowerCase()) !== -1);

        if (matches) {
            obj.ranking = i + 1;
            break;
        }
    }

    if (matches) {
        return obj;
    }

    return null;
};

const searchData = (queryString, sections, eventId, next) => {
    try {
        const cleanTerm = queryString.replace(/([*+?^${}()|[]\/\\])/gi, '');
        const queryArray = pull(cleanTerm.split(' '), '', undefined, null, '.');
        const [firstWord] = queryArray;

        let hasResults = false;
        let searchResults = [];

        each(
            sections,
            (section, callback) => {
                executeQueryWithCallback(
                    'getTypes',
                    {
                        id: section.typeId,
                    },
                    async (err, type) => {
                        if (!type) {
                            return callback();
                        }

                        const listQuery = translateTargetToQuery(type.target);
                        const params = {
                            type: { eq: type.id },
                            searchTerms: { contains: (firstWord || cleanTerm).toLowerCase() }, //filter search terms with only one word to limit results
                        };

                        const items = await executeListQuery(listQuery, params);

                        //Do the actual filtering and ranking

                        let result = [];

                        items.forEach(item => {
                            const match = searchTermsMatchesData(item, queryArray);
                            if (match) {
                                result.push(match);
                            }
                        });

                        if (result && result.length) {
                            const orderNumber = findIndex(
                                sections,
                                section => section.typeId === type.id,
                            );

                            searchResults.push({
                                listQuery,
                                type,
                                orderNumber,
                                items: sortBy(result, ['ranking', 'start', 'orderingName', 'name']),
                            });

                            hasResults = true;
                        } else {
                            console.log(`NO results found for: ${type.singular}`);
                        }

                        callback();
                    },
                );
            },
            function (err) {
                if (err) {
                    return next({ hasResults: false, searchResults: [] });
                }

                next({
                    hasResults,
                    searchResults: sortBy(searchResults, 'orderNumber'),
                });
            },
        );
    } catch (err) {
        throw new Error(err);
    }
};

export { getSearchPage, searchData };

import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import ThemeContext from '../../components/Theme/ThemeContext';
import { getString } from '../../services/api/store';
import MyProgrammePage from '../MyProgrammePage';
import MyFavoritePage from '../MyFavoritesPage';
import {
    TabsContainerStyled,
    TabsWrapper,
    ToolbarContainer,
    TabElement,
} from '../Programme/style/style';

const MyBookmarksPage = props => {
    let TITLE_MY_PROGRAMME = getString('myProgramme') || 'My programme';
    let TITLE_MY_FAVORITES = getString('myFavorites') || 'My favorites';

    useEffect(() => {
        props.setTitle();
    }, []);

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    <ToolbarContainer>
                        <TabsContainerStyled panelClassName="md-grid" colored heght={'100%'}>
                            <TabsWrapper
                                background={theme.primary}
                                tabId="simple-tab"
                                mobile
                                alignToKeyline={false}
                            >
                                <TabElement
                                    label={TITLE_MY_PROGRAMME}
                                    primary={theme.primary}
                                    active={props.location.pathname.includes('/mybookmarks')}
                                    margin={'24px 8px 24px 0'}
                                >
                                    <MyProgrammePage {...props} />
                                </TabElement>
                                <TabElement label={TITLE_MY_FAVORITES} primary={theme.primary}>
                                    <MyFavoritePage {...props} />
                                </TabElement>
                            </TabsWrapper>
                        </TabsContainerStyled>
                    </ToolbarContainer>
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default withRouter(MyBookmarksPage);

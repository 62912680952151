import { eurekaRequest } from '../../../services/api/eureka';

export const getFriendship = id => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/friendships/getFriendship/${id}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const sendFriendRequest = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/friendships/friendRequest`,
            data,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const cancelFriendRequest = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/friendships/deleteFriend`,
            data,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const acceptFriendRequest = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/friendships/acceptFriendRequest`,
            data,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

const ignoreFriendRequest = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/friendships/ignoreFriendRequest`,
            data,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

// Since we don't have a special endpoint for this, decline will actually remove the friend
const declineFriendRequest = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/friendships/deleteFriend`,
            data,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const updatePassword = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest('put', `/api/entities/users/password`, data, true, (err, response) => {
            if (err) {
                reject(err);
            } else {
                resolve(response);
            }
        });
    });
};

export const deleteAccount = () => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'delete',
            `/api/entities/users/deleteMyAccount`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const checkPasswordMatch = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest('post', `/api/entities/users/checkPassword`, data, true, (err, response) => {
            if (err) {
                reject(err);
            } else {
                resolve(response);
            }
        });
    });
};

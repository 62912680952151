import SocialLinksModal from '../../containers/profile/SocialLinksModal';
import ContactInfoModal from '../../containers/profile/ContactInfoModal';
import React from 'react';
import ChangePasswordModal from '../../containers/profile/ChangePasswordModal';
import DeleteAccountModal from '../../containers/profile/DeleteAccountModal';

export const ProfileContent = ({
    isMobile,
    showSocialLinksModal,
    theme,
    fieldValues,
    setFieldValues,
    setShowSocialLinksModal,
    onChange,
    onClose,
    renderForm,
    closeIcon,
    setCloseIcon,
    editedLinks,
    setEditedLinks,
    showContactInfoModal,
    setShowContactInfoModal,
    afterSignUp,
    suggestedContactEmail,
    showDeleteAccountModal,
    showChangePasswordModal,
    setShowChangePasswordModal,
    setShowDeleteAccountModal,
}) => (
    <React.Fragment>
        {showSocialLinksModal && (
            <SocialLinksModal
                theme={theme}
                fieldValues={fieldValues}
                setFieldValues={setFieldValues}
                onClose={() => setShowSocialLinksModal(false)}
                onChange={onChange}
                closeIcon={closeIcon}
                setCloseIcon={setCloseIcon}
                editedLinks={editedLinks}
                setEditedLinks={setEditedLinks}
                afterSignUp={afterSignUp}
            />
        )}
        {showContactInfoModal && (
            <ContactInfoModal
                theme={theme}
                fieldValues={fieldValues}
                setFieldValues={setFieldValues}
                onClose={() => setShowContactInfoModal(false)}
                onChange={onChange}
                closeIcon={closeIcon}
                setCloseIcon={setCloseIcon}
                afterSignUp={afterSignUp}
                suggestedContactEmail={suggestedContactEmail}
            />
        )}
        {showChangePasswordModal && (
            <ChangePasswordModal
                theme={theme}
                onGoBack={() => setShowChangePasswordModal(false)}
                onClose={onClose}
            />
        )}
        {showDeleteAccountModal && (
            <DeleteAccountModal
                theme={theme}
                onClose={onClose}
                onGoBack={() => setShowDeleteAccountModal(false)}
            />
        )}

        {!showSocialLinksModal &&
            !showContactInfoModal &&
            !showChangePasswordModal &&
            !showDeleteAccountModal &&
            renderForm(theme, isMobile)}
    </React.Fragment>
);

import { getLocalAppStateAsync } from '../../../../services/api/db';
import { navigateTo } from '../../../../components/Navigation/NavigationLink';

export const getDailyProgrammePath = async () => {
    const appState = await getLocalAppStateAsync();
    const { navigation } = appState;

    const dailyProgrammePage = navigation.find(navItem => navItem.type === 'programme') || {};

    return dailyProgrammePage.to;
};

export const goBackFromVirtualSession = async (match, history, location, sessionId) => {
    const firstHistory = sessionStorage.getItem(sessionId + '_history_index');
    const historyNow = history.length;
    if (firstHistory && historyNow > 2) {
        const n = (historyNow - parseInt(firstHistory, 10) + 1) * -1;
        history.go(n);
    } else if (historyNow < 3) {
        navigateTo({
            match,
            history,
            location,
            params: {
                type: 'detail',
                objectClass: 'timeslot',
                objectId: sessionId,
            },
            path: '/',
        });
    } else {
        history.goBack();
    }
};

import { eurekaRequest } from '../../../../../services/api/eureka';
import moment from 'moment';

export const getQuestionSetByExternalObject = externalObjectId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/questionSets/externalObject/${externalObjectId}`,
            null,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const getQuestionSetByEventObject = (eventReference, externalObjectReference) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/questionSets/externalObject/${eventReference}/${externalObjectReference}`,
            null,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const saveQuestion = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest('put', '/api/entities/questions/' + data.id, data, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

export const deleteQuestion = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest('delete', '/api/entities/questions/' + data.id, data, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

export const saveQuestionSet = data => {
    const extendedData = {
        ...data,
        Questions: [],
    };

    return new Promise((resolve, reject) => {
        eurekaRequest(
            'put',
            '/api/entities/questionSets/' + data.id,
            extendedData,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const askQuestion = ({ questionSetId, question }) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            '/api/entities/questions',
            { QuestionSetId: questionSetId, question },
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const likeQuestion = async ({ data }) =>
    new Promise((resolve, reject) => {
        eurekaRequest('post', '/api/entities/questionLikes', data, true, (err, response) => {
            if (err) {
                reject(err);
            } else {
                resolve(response);
            }
        });
    });

export const toggleQuestionLike = async ({ questionLikeId, data }) =>
    new Promise((resolve, reject) => {
        eurekaRequest(
            'put',
            `/api/entities/questionLikes/${questionLikeId}`,
            data,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });

export const calcTimeDifference = (start, showSeconds) => {
    const timedifference = new Date(start).getTimezoneOffset();
    const end = moment().utcOffset(timedifference);
    const timeDifference = moment.duration(end.diff(start));
    const days = timeDifference.days();
    if (days > 0) {
        return `${days}d ago`;
    }
    const hours = timeDifference.hours();
    if (hours > 0) {
        return `${hours}h ago`;
    }
    const minutes = timeDifference.minutes();
    if (showSeconds) {
        if (minutes > 0) {
            return `${minutes}m ago`;
        }
        const seconds = timeDifference.seconds();
        return `${seconds}s ago`;
    }
    return `${minutes}m ago`;
};

import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import HowToVoteIcon from '@material-ui/icons/HowToVoteOutlined';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { votesColorScheme } from '../components/QuestionModal';
import VoteResult from '../components/VoteResult';
import { getPollSetByExternalObject } from '../services/PollingService';
import {
    calcTimeDifference,
    getQuestionSetByExternalObject,
} from '../../virtualFeature/services/QuestionsAndAnswersService';
import { useSocket } from '../../../../../components/Session/SocketContext';
import FullscreenWrapper from '../FullscreenPlaceHolder';
import ProgressBar from '../components/ProgressBar';

const VotingResultsWrapper = styled.div`
    background-color: #ffffff;
    z-index: 1;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: ${props => (props.hastimer ? '48px 24px 24px' : '24px')};
    height: 100%;
`;

const Title = styled.div`
    font-family: Cabin;
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 32px;
`;

const Img = styled.img`
    height: 100%;
    width: 100%;
    z-index: 10;
    object-fit: cover;
    border-radius: 8px;
`;

const ImageContainer = styled.div`
    height: 226px;
    width: 402px;
    border-radius: 8px;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const FlexContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: ${props => props.marginleft || '64px'};
    margin-right: ${props => props.marginright || '24px'};
`;

const TotalVotesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const TotalVotesText = styled.p`
    margin-top: 6px;
    font-family: Roboto;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: 0.86px;
    text-align: right;
    color: rgba(0, 0, 0, 0.87);
`;

const HowToVoteIconStyled = styled(HowToVoteIcon)`
    margin-left: 8px;
    margin-top: -10px;
`;

const POLL_DEFAULT = {
    totalVotes: 0,
    title: '',
    pollSetId: null,
    options: [],
    imageUrl: '',
};

const QuestionsWrapper = styled.div`
    background-color: #ffffff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    padding: 56px;
    height: 100%;
    width: 100%;
`;

const QuestionCard = styled.div`
    border-radius: 8px;
    background-color: #f5f5f5;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    margin-bottom: 8px;
    width: 885px;
`;

const LikesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Likes = styled.p`
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 18px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`;

const NameStyled = styled.p`
    font-family: Cabin;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 8px;
`;

const TimeStyled = styled.p`
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 12px;
    margin-bottom: 8px;
`;

const TextStyled = styled.p`
    font-family: Roboto;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
`;

const ProgressBarWrapper = styled.div`
    position: absolute;
    top: 24px;
    left: 24px;
    width: calc(100% - 48px);
`;

const visibleQuestionSortFn = (a, b) => {
    if (a.order && b.order) {
        return a.order - b.order;
    }
    return new Date(a.updatedAt) - new Date(b.updatedAt);
};

const QAPresentation = () => {
    const [activePoll, setActivePoll] = useState(POLL_DEFAULT);
    const [activeQuestions, setActiveQuestions] = useState([]);
    const [hideVotesNumber, setHideVotesNumber] = useState(false);
    const [questionSet, setQuestionSet] = useState(null);
    const [pollSet, setPollSet] = useState(null);
    const pollSetRef = useRef();
    const questionSetRef = useRef();
    const { socket } = useSocket();

    const { externalObjectId } = useParams();

    const fetchPollSet = async () => {
        const pollSetData = await getPollSetByExternalObject(externalObjectId);
        setHideVotesNumber(pollSetData.hideVotesNumber);
        calculateActivePoll(pollSetData);
        pollSetRef.current = pollSetData;
        setPollSet(pollSetData);
    };

    const fetchQuestionSet = async () => {
        const questionSetData = await getQuestionSetByExternalObject(externalObjectId);
        calculateActiveQuestions(questionSetData);
        questionSetRef.current = questionSetData;
        setQuestionSet(questionSetData);
    };

    const addVote = async pollOptionId => {
        const pollSet = {
            ...pollSetRef.current,
        };
        pollSet.votes[pollOptionId] += 1;
        calculateActivePoll(pollSet);
        pollSetRef.current = pollSet;
        setPollSet(pollSet);
    };

    useEffect(() => {
        (async () => {
            if (!externalObjectId) {
                return;
            }
            await fetchPollSet();
            await fetchQuestionSet();

            socket.emit('joinPolls', { objectId: externalObjectId });
            socket.on('updatePollSet', function (data) {
                if (data.action === 'addVote') {
                    addVote(data.pollOptionId);
                } else if (data.action === 'updatePollSet') {
                    fetchPollSet();
                }
            });

            socket.emit('joinQuestions', {
                objectId: externalObjectId,
            });

            socket.on('updateQuestions', function (data) {
                if (
                    data.action === 'updateQuestions' ||
                    data.action === 'updateQuestion' ||
                    data.action === 'addLike' ||
                    data.action === 'removeLike'
                ) {
                    fetchPollSet();
                    fetchQuestionSet();
                }
            });

            socket.on('setQuestions', function (data) {
                if (data.action === 'setQuestions') {
                    fetchQuestionSet();
                }
            });
        })();
        return () => {
            if (socket && socket.removeAllListeners) {
                socket.removeAllListeners('updatePollSet');
                socket.removeAllListeners('updateQuestions');
                socket.removeAllListeners('setQuestions');
            }
        };
    }, [externalObjectId]);

    const calculateActivePoll = pSet => {
        const { Polls: polls, id, votes } = pSet;

        const activePollData = polls.find(poll => poll.active);
        if (activePollData) {
            const { PollOptions: pollOptions, title, imageUrl } = activePollData;
            const options = pollOptions
                .filter(option => option.text.length > 0)
                .sort((o1, o2) => o1.order - o2.order)
                .map(pollOption => {
                    return {
                        text: pollOption.text,
                        votes: votes[pollOption.id],
                        id: pollOption.id,
                        correct: pollOption.correct,
                    };
                });
            const totalVotes = options.reduce((acc, option) => acc + option.votes, 0);
            setActivePoll({
                ...activePollData,
                totalVotes,
                title,
                pollSetId: id,
                options,
                imageUrl,
            });
        } else {
            setActivePoll(POLL_DEFAULT);
        }
    };

    const calculateActiveQuestions = qSet => {
        const { Questions: questions } = qSet;
        if (questions && questions.length) {
            const visible = questions
                .filter(question => {
                    let likes = 0;
                    if (question.QuestionLikes && question.QuestionLikes.length) {
                        const filtered = question.QuestionLikes.filter(like => like.active);
                        likes = filtered.length;
                    }
                    question.likes = likes;
                    return question.question && question.question.length > 0 && question.visible;
                })
                .sort(visibleQuestionSortFn)
                .map(question => {
                    let userName = 'anonymous';
                    const { User: user } = question;
                    if (user && user.firstName) {
                        userName = `${user.firstName} ${user.lastName}`;
                    }

                    return {
                        text: question.question,
                        likes: question.likes,
                        createdAt: question.createdAt,
                        userName,
                    };
                });
            setActiveQuestions(visible);
        } else {
            setActiveQuestions([]);
        }
    };

    const { totalVotes, title, options, imageUrl } = activePoll;
    const smallerFonts = options.length >= 5;
    const showPolls = activePoll.options.length > 0;
    const showQuestions =
        activePoll &&
        activePoll.options &&
        activePoll.options.length === 0 &&
        activeQuestions.length > 0;
    const activePollHasTimer = activePoll && activePoll.useTimer && activePoll.seconds;
    const isQuestionSetOpen = questionSet && questionSet.state === 'open';
    const showSlider = pollSet && activePollHasTimer && pollSet.state !== 'closed';

    if (!showPolls && !showQuestions) {
        let text = 'Please wait...';

        if (!activeQuestions.length && isQuestionSetOpen && !pollSet.Polls.length) {
            text = 'Ask a question to one of the speakers...';
        } else if (!isQuestionSetOpen && pollSet && pollSet.Polls.length) {
            text = 'Please wait for speaker to start a vote...';
        }

        return <FullscreenWrapper text={text} />;
    }

    return (
        <>
            {showPolls && (
                <div style={{ position: 'relative' }}>
                    {showSlider && (
                        <ProgressBarWrapper>
                            <ProgressBar
                                pollSet={pollSet}
                                activePoll={activePoll}
                                activeColor={'#1fa294'}
                                slimBar
                            />
                        </ProgressBarWrapper>
                    )}
                    <VotingResultsWrapper hastimer={showSlider ? 1 : 0}>
                        <FlexRow>
                            {imageUrl && (
                                <ImageContainer>
                                    <Img src={imageUrl} />
                                </ImageContainer>
                            )}
                        </FlexRow>
                        <FlexContainer
                            marginright={imageUrl ? '24px' : '30%'}
                            marginleft={imageUrl ? '64px' : '40px'}
                        >
                            <Title>{title}</Title>

                            {options.map((option, index) => (
                                <VoteResult
                                    key={`vote-result-${index}`}
                                    label={String.fromCharCode(65 + index)}
                                    option={option}
                                    hideVotesNumber={hideVotesNumber}
                                    totalVotes={totalVotes}
                                    color={votesColorScheme[index]}
                                    presentation
                                    smallerFonts={smallerFonts}
                                />
                            ))}
                        </FlexContainer>
                        {!hideVotesNumber && (
                            <TotalVotesContainer>
                                <TotalVotesText>{totalVotes}</TotalVotesText>
                                <HowToVoteIconStyled style={{ fontSize: 36 }} />
                            </TotalVotesContainer>
                        )}
                    </VotingResultsWrapper>
                </div>
            )}
            {showQuestions && (
                <QuestionsWrapper>
                    {activeQuestions.map((question, index) => (
                        <QuestionCard key={index}>
                            <LikesContainer>
                                {question.likes > 0 && <Favorite />}
                                {question.likes === 0 && <FavoriteBorder />}
                                <Likes>{question.likes}</Likes>
                            </LikesContainer>
                            <TextContainer>
                                <TitleContainer>
                                    <NameStyled>{question.userName}</NameStyled>
                                    <TimeStyled>
                                        {calcTimeDifference(question.createdAt)}
                                    </TimeStyled>
                                </TitleContainer>
                                <TextStyled>{question.text}</TextStyled>
                            </TextContainer>
                        </QuestionCard>
                    ))}
                </QuestionsWrapper>
            )}
        </>
    );
};

export default QAPresentation;

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import * as palette from '../../../../components/General/Variables';
import AuthUserContext from '../../../../components/Session/AuthUserContext';
import Header from '../../components/common/Header';
import Footer from '../../components/roundTable/Footer';
import { getTimeslot } from '../../services/session';
import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import {
    getFullProfile,
    getPeerList,
    getVirtualEventSession,
    updateVirtualEventUser,
} from '../../../../services/api/eureka';
import Auth from '../../../../services/api/auth';
import { ContentContainer, Wrapper } from '../../styles';
import WarningDialog from '../../../../components/Dialog/WarningDialog';
import { goBackFromVirtualSession } from '../common/goBack';
import AnalyticsService from '../../../../features/analytics/services';
import useStreamHandling from '../../hooks/useStreamHandling';
import MainContent from './mainContent/MainContent';
import SideContent from './sideContent/SideContent';
import VirtualWrapper from '../common/VirtualWrapper';
import NotificationService from '../../../Notifications/services/NotificationService';
import { notificationTypes } from '../../../Notifications/constants';
import { checkIfUserHasAuthorizationByTypeRestrictions } from '../../../AccessRestrictions/utils';
import AccessDeniedModal from '../../../AccessRestrictions/AccessDeniedModal';
import NoSlidesView from './NoSlidesView';
import useExpertAchievementTimeTracking from '../../../Achievements/hooks/useExpertAchievementTimeTracking';
import entities from '../../constants/entities';
import { getStreamConfiguration, isEncodingTheSame } from '../../../../utils/streamUtils';
import LoadingVirtualPage from '../common/LoadingVirtualPage';
import useVirtualEventUsersToDisplay from './hooks/useVirtualEventUsersToDisplay';

const NoSlidesViewWrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 136px);
    margin-top: 56px;
    flex-flow: row nowrap;
    overflow: hidden;
`;

const VirtualRoundTableSession = props => {
    const { authUser, match, history, location } = props;
    const { timeslotId } = match.params;
    const [timeslot, setTimeslot] = useState({});
    const mobile = (window.innerWidth < palette.MIN_TABLET_INT).toString();
    const virtualEventUserInfo = useRef(null);
    const virtualEventSessionInfo = useRef(null);
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();
    const stateRef = useRef(null);
    useExpertAchievementTimeTracking(timeslot);

    const { localClient, localStream, socket } = stateCtx;
    const {
        virtualEventSession,
        virtualEventUser,
        currentVirtualEventUser,
        peers,
        streams,
        isInRoundTable,
        roundTableUserInfoError,
        roundTableMode,
    } = stateCtx;
    const [kickedUser, setKickedUser] = useState(false);
    const [restrictedUser, setRestrictedUser] = useState(false);
    const [restrictionChecked, setRestrictionChecked] = useState(false);
    const user = Auth.getUser();
    const isBroadcasting = virtualEventSession && virtualEventSession.status === 'broadcasting';
    const isSessionOnDemand =
        location && location.pathname && location.pathname.includes('/on-demand-poster-room');
    const onlyParticipantsMode = roundTableMode === entities.hostedSessionMode.onlyParticipants;
    const posterPdf = timeslot && timeslot.posterPdf && JSON.parse(timeslot.posterPdf);
    const posterUrl = posterPdf && posterPdf.url;
    const sessionHasPoster =
        (virtualEventUser && virtualEventUser.uploadedPresentationUrl) || posterUrl;
    const isChatMode = roundTableMode === entities.hostedSessionMode.chat;
    const slidesMadeVisibleByHost = get(virtualEventSession, 'slidesVisible') || false;
    const virtualEventUsersToDisplay = useVirtualEventUsersToDisplay();

    stateRef.current = stateCtx;

    const addViewSample = timeslotId => {
        AnalyticsService.addSample('viewVirtualRoom', 'round-table', timeslotId);
    };

    const leaveSession = () => {
        const { localClient } = stateRef.current;

        if (localClient) {
            mutationCtx.setCanJoin(false);
            localClient.leave(true).then(() => {
                mutationCtx.removePeer({ uid: localClient._id });
                if (localStream) {
                    localStream.stop();
                    localStream.close();
                }
            });
        }
    };

    const stopStream = noRefresh => {
        mutationCtx.clearAllStream();

        if (!noRefresh) {
            if (socket) {
                socket.emit('updateData', { objectId: timeslotId });
                socket.emit('updateRoundTableCount', { objectId: timeslotId });
            }
        }

        leaveSession();
    };

    const updateData = data => {
        const { virtualEventSession } = data;

        if (!virtualEventSession || !virtualEventSession.VirtualEventUsers) {
            return false;
        }

        if (virtualEventUserInfo.current && virtualEventUserInfo.current.id) {
            const currentUser = virtualEventSession.VirtualEventUsers.find(
                user => user.id === virtualEventUserInfo.current.id,
            );

            if (currentUser) {
                const updatedUser = {
                    ...virtualEventUserInfo.current,
                    isMicrophoneOn: currentUser.isMicrophoneOn,
                    isVideoOn: currentUser.isVideoOn,
                };

                mutationCtx.setCurrentVirtualEventUser(updatedUser);
                virtualEventUserInfo.current = updatedUser;
            }
        }

        //Round Table : We set virtualEventUser the Host. This is the talking user in the video and the
        //user handling the slides. If we make an active speaker we need to create an extra property in state and replace accordingly in the MainVideo component.
        const virtualEventUserActive = virtualEventSession.VirtualEventUsers.find(
            virtualEventUser => virtualEventUser.role === 'roundTableHost',
        );

        if (virtualEventUserActive) {
            const justClosed =
                get(virtualEventSessionInfo.current, 'status') !== 'closed' &&
                get(virtualEventSession, 'status') === 'closed';
            const justOpened =
                get(virtualEventSessionInfo.current, 'status') === 'closed' &&
                get(virtualEventSession, 'status') === 'broadcasting';

            if (justClosed) {
                leaveSession();
            }

            virtualEventSessionInfo.current = virtualEventSession;
            mutationCtx.setVirtualEventSession(virtualEventSession);
            mutationCtx.setVirtualEventUser(virtualEventUserActive);

            if (justOpened) {
                joinSession();
            }
        } else {
            mutationCtx.setVirtualEventUser(null);
        }

        return true;
    };

    const goBackFunction = () => goBackFromVirtualSession(match, history, location, timeslotId);

    useEffect(() => {
        (async () => {
            if (!isInRoundTable && roundTableUserInfoError === 'alreadyIn') {
                mutationCtx.setShowAlreadyInTheSessionModal(true);
                await goBackFunction();
            }
        })();
    }, [isInRoundTable, roundTableUserInfoError]);

    const handleUserInfo = async info => {
        if (info.error) {
            if (info.error === 'full') {
                mutationCtx.setShowCapacityModal(true);
                await goBackFunction();
                return;
            }

            if (info.error === 'alreadyIn') {
                mutationCtx.setRoundTableUserInfoError('alreadyIn');
            }

            return;
        }

        const { newVirtualEventUser } = info;

        mutationCtx.setCurrentVirtualEventUser(newVirtualEventUser);
        mutationCtx.setIsInRoundTable(true);
        virtualEventUserInfo.current = newVirtualEventUser;

        socket.emit('updateRoundTableCount', { objectId: timeslot.id });
        socket.emit('updateData', { objectId: timeslot.id });

        const config = {
            appID: virtualEventUserInfo.current.appID,
            token: newVirtualEventUser.virtualEventToken.accessToken,
            channelName: timeslot.id,
            uid: newVirtualEventUser.UserId,
        };

        if (!isSessionOnDemand) {
            mutationCtx.startUsingDevices(config);
        }
    };

    const joinSession = () => {
        mutationCtx.setCurrentVirtualEventUser(null);
        socket.emit(
            'joinRoundTableUser',
            {
                sessionId: timeslot.id,
                role: 'roundTableParticipant',
                userId: user.id,
            },
            peers => mutationCtx.setPeers(peers),
        );
    };

    useEffect(() => {
        if (!user) {
            return props.history.push('/');
        }

        if (isEmpty(timeslot)) {
            return;
        }

        const handleMessageNotification = data => {
            if (timeslot.id !== data.roomId) {
                NotificationService.handleNotification({
                    notificationType: notificationTypes.CHAT,
                    userId: data.senderId,
                    type: data.type,
                    roomId: data.roomId,
                });
            }
        };

        (async () => {
            try {
                const { socket } = stateCtx;

                if (!socket || kickedUser || !user) {
                    return;
                }

                joinSession();

                socket.on(`roundTableUser_${user.id}`, handleUserInfo);
                socket.on(`messageNotification_${user.id}`, handleMessageNotification);
                socket.on('disconnect', joinSession);
            } catch (err) {
                await goBackFunction();
            }
        })();

        return () => {
            if (socket) {
                if (user) {
                    socket.removeAllListeners(`roundTableUser_${user.id}`, handleUserInfo);
                    socket.removeAllListeners(`messageNotification_${user.id}`);
                }

                socket.off('disconnect', joinSession);
            }
        };
    }, [timeslot, kickedUser]);

    useEffect(() => {
        let interval = null;

        if (isBroadcasting) {
            interval = setInterval(() => {
                addViewSample(timeslotId);
            }, 60000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [timeslotId, isBroadcasting]);

    window.localClient = localClient;
    window.mutationCtx = mutationCtx;

    useStreamHandling(true);

    const virtualEventUsers = useMemo(() => {
        let result = get(virtualEventSession, 'VirtualEventUsers', []);

        result = result.filter(vUser => peers.find(peerId => peerId === vUser.UserId));

        return result;
    }, [virtualEventSession, localStream, peers, get(virtualEventUser, 'UserId')]);

    const host = useMemo(() => {
        return get(virtualEventSession, 'VirtualEventUsers', []).find(user => {
            return user.role === entities.virtualEventUserEntity.role.roundTableHost;
        });
    }, [get(virtualEventSession, 'VirtualEventUsers', [])]);

    const hostHasShareStream = useMemo(() => {
        return (
            !host ||
            !!(streams || []).find(st => {
                return st.streamId === host.UserId + 10000000;
            })
        );
    }, [host, streams]);

    const hostIsSharingScreen = hostHasShareStream && host && host.isScreenSharing;
    const shouldShowPosterOrShareStream =
        (hostHasShareStream && hostIsSharingScreen) ||
        (sessionHasPoster && slidesMadeVisibleByHost);

    const shouldRenderOnlyParticipants =
        (!shouldShowPosterOrShareStream || onlyParticipantsMode) &&
        !isSessionOnDemand &&
        !isChatMode;

    useEffect(() => {
        if (!localStream || !localStream.videoTrack) {
            return;
        }

        const numberOfStreams = streams.filter(
            st => virtualEventUsers.findIndex(vUser => vUser.UserId === st.streamId) >= 0,
        ).length;
        const encoderConfiguration = getStreamConfiguration({
            roundTable: true,
            streamLength: numberOfStreams,
            isScreenSharing: hostHasShareStream,
        });

        if (!isEncodingTheSame(encoderConfiguration, localStream.videoTrack._encoderConfig)) {
            localStream.setEncoderConfiguration(encoderConfiguration);
        }
    }, [localStream, virtualEventUsers, streams, hostHasShareStream]);

    const handleKickedUser = async data => {
        const { userId: kickedUserId } = data;
        const virtualEventUsers = get(virtualEventSessionInfo.current, 'VirtualEventUsers', []);
        const virtualEventUsersClone = [...virtualEventUsers];
        const currVirtualUserId = get(virtualEventUserInfo.current, 'id');
        const currentUserId = get(virtualEventUserInfo.current, 'UserId');

        const providedVirtualUserId = get(data, 'virtualEventUserId');
        const providedUserId = get(data, 'userId');

        if (currVirtualUserId === providedVirtualUserId || currentUserId === providedUserId) {
            stopStream(true);
            setKickedUser(true);

            if (socket) {
                socket.emit('leaveSession');
            }
        } else {
            const virtualUserIndex = virtualEventUsers.findIndex(
                user => user.UserId === kickedUserId,
            );
            virtualEventUsersClone.splice(virtualUserIndex, 1);

            mutationCtx.setVirtualEventSession({
                ...virtualEventSessionInfo.current,
                VirtualEventUsers: virtualEventUsersClone,
            });
        }
    };

    const handlePeers = async () => {
        const peersResponse = await getPeerList(timeslotId);

        mutationCtx.setPeers(peersResponse.peers);
    };

    useEffect(() => {
        (async () => {
            const virtualEventSession = await getVirtualEventSession(timeslotId);

            if (!virtualEventSession) {
                return;
            }

            updateData({ virtualEventSession });

            const { socket } = stateCtx;

            if (!socket) {
                return;
            }

            if (socket) {
                socket.on(`refreshPeers_${timeslotId}`, handlePeers);
                socket.on(`updateData_${timeslotId}`, updateData);
                socket.on(`kickUser_${timeslotId}`, handleKickedUser);
            }
        })();

        const cleanup = async () => {
            window.removeEventListener('beforeunload', cleanup);

            if (socket) {
                socket.emit('leaveSession');
                socket.removeAllListeners(`refreshPeers_${timeslotId}`, handlePeers);
                socket.removeAllListeners(`updateData_${timeslotId}`);
                socket.removeAllListeners(`kickUser_${timeslotId}`);
            }

            stopStream();
        };

        window.addEventListener('beforeunload', cleanup);

        // Add a 'view' sample every time a user access the virtual round table session page
        AnalyticsService.addSample('object', 'timeslot', timeslotId);

        return async () => {
            await cleanup();
        };
    }, []);

    useEffect(() => {
        if (location.state && location.state.keepHistoryLength) {
            history.replace(location.pathname, {
                ...location.state,
                keepHistoryLength: false,
            });
            sessionStorage.setItem(timeslotId + '_history_index', JSON.stringify(history.length));
        }
        getTimeslot(timeslotId, async (err, timeslot) => {
            if (err || !timeslot) {
                return;
            }

            setTimeslot(timeslot);

            if (Auth.isUserAuthenticated()) {
                const fullProfile = await getFullProfile();
                const canUserAccessSession = checkIfUserHasAuthorizationByTypeRestrictions(
                    timeslot?.typeParams?.virtualRoomRestrictedGroup,
                    fullProfile.userGroups,
                );
                setRestrictedUser(!canUserAccessSession);
            }

            setRestrictionChecked(true);
        });
    }, [timeslotId]);

    const goBack = () => {
        return goBackFunction();
    };

    if (restrictedUser) {
        return <AccessDeniedModal onClose={() => history.push('/')} />;
    }

    if (kickedUser) {
        return (
            <WarningDialog
                open={true}
                title=""
                content="You have been removed from this session."
                onClose={goBack}
            />
        );
    }

    if (
        (!virtualEventSession || !currentVirtualEventUser || !restrictionChecked) &&
        !isSessionOnDemand
    ) {
        return (
            <Wrapper mobile={mobile}>
                <Header goBack={goBack} timeslot={timeslot} authUser={authUser} />
                <LoadingVirtualPage />
            </Wrapper>
        );
    }

    if (virtualEventSession && virtualEventSession.status === 'closed' && !isSessionOnDemand) {
        return (
            <WarningDialog
                open={true}
                title="Virtual room is closed"
                content="This virtual room is now closed"
                onClose={goBack}
            />
        );
    }

    return (
        <React.Fragment>
            {authUser && (currentVirtualEventUser || isSessionOnDemand) && (
                <VirtualWrapper
                    timeslotId={timeslotId}
                    virtualEventSession={virtualEventSession}
                    virtualEventUser={currentVirtualEventUser}
                >
                    <Wrapper mobile={mobile}>
                        <Header goBack={goBack} timeslot={timeslot} authUser={authUser} />
                        {shouldRenderOnlyParticipants ? (
                            <NoSlidesViewWrapper>
                                <NoSlidesView
                                    {...props}
                                    timeslot={timeslot}
                                    virtualEventUsersToDisplay={virtualEventUsersToDisplay}
                                />
                            </NoSlidesViewWrapper>
                        ) : (
                            <ContentContainer withFooter={!isSessionOnDemand ? 1 : 0}>
                                <MainContent
                                    {...props}
                                    timeslot={timeslot}
                                    virtualEventUsersToDisplay={virtualEventUsersToDisplay}
                                />
                                <SideContent {...props} timeslot={timeslot} />
                            </ContentContainer>
                        )}
                        {virtualEventUserInfo.current && !isSessionOnDemand && (
                            <Footer
                                updateVirtualEventUser={updateVirtualEventUser}
                                socket={socket}
                                currentVirtualEventUser={virtualEventUserInfo.current}
                            />
                        )}
                    </Wrapper>
                </VirtualWrapper>
            )}
        </React.Fragment>
    );
};

const ConsumerWrapper = props => {
    return (
        <AuthUserContext.Consumer>
            {authUser => <VirtualRoundTableSession {...props} authUser={authUser} />}
        </AuthUserContext.Consumer>
    );
};

export default withRouter(ConsumerWrapper);

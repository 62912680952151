import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';

import ProgressBar from './ProgressBar';
import { useTheme } from '../../../components/Theme/ThemeContext';
import * as palette from '../../../components/General/Variables';

const StyledContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.87);
    display: flex;
    align-items: flex-start;
    padding: 12px 12px 18px 12px;
    border-radius: 8px;
    position: relative;
`;

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledIconContainer = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4c4c4f;
    border-radius: 50%;
`;

const StyledIcon = styled(FontIcon)`
    width: 18px;
    color: #fff !important;
`;

const StyledCloseIcon = styled(StyledIcon)`
    width: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    opacity: 0.7;
`;

const StyledTitle = styled.div`
    font-size: 15px;
    color: ${palette.COLOR_WHITE};
    font-weight: 500;
    text-overflow: ellipsis;
`;

const StyledSubtitle = styled(StyledTitle)`
    margin-top: 2px;
    margin-bottom: 6px;
    color: rgba(255, 255, 255, 0.7);
`;

const StyledProgressContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledLevel = styled.div`
    color: rgba(255, 255, 255, 0.54);
    font-size: 13px;
    margin-left: 22px;
`;

const StyledProgressBar = styled(ProgressBar)`
    width: 217px;
`;

const CloseButton = ({ closeToast }) => (
    <StyledCloseIcon iconClassName={'material-icons material-icons-round'} onClick={closeToast}>
        close
    </StyledCloseIcon>
);

const AchievementNotification = ({ title, subtitle, level, icon, percentage, onClose }) => {
    const theme = useTheme();

    return (
        <StyledContainer>
            <CloseButton closeToast={onClose} />
            <StyledIconContainer>
                <StyledIcon>{icon}</StyledIcon>
            </StyledIconContainer>
            <StyledContent>
                <StyledTitle>{title}</StyledTitle>
                <StyledSubtitle>{subtitle}</StyledSubtitle>
                <StyledProgressContainer>
                    <StyledProgressBar
                        precentage={percentage}
                        barColor={'#dde1e5'}
                        progressColor={theme.contrast}
                    />
                    <StyledLevel>{level}</StyledLevel>
                </StyledProgressContainer>
            </StyledContent>
        </StyledContainer>
    );
};

export default AchievementNotification;

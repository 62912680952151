import { uploadVideoToConfigurator } from '../services/VirtualEventSession';

const onUploadVideoIntroduction = async (stateCtx, mutationCtx, newVideo) => {
    const { sessionId, socket, virtualEventUser } = stateCtx;

    await uploadVideoToConfigurator(sessionId, {
        shareUrl: newVideo.videoUrl,
        name: newVideo.name,
        duration: newVideo.duration,
        isPreRecording: true,
    });

    socket.emit('updateData', {
        objectId: sessionId,
        virtualEventSession: {
            VirtualEventUsers: [
                {
                    id: virtualEventUser.data.id,
                    isPrerecording: true,
                    recordingInfo: newVideo,
                },
            ],
        },
    });
};

export default onUploadVideoIntroduction;

import {
    HIGH_STREAM_CONFIGURATION,
    LOW_STREAM_CONFIGURATION,
    MEDIUM_STREAM_CONFIGURATION,
    ROUND_TABLE_CONFIGURATIONS,
} from '../features/virtualFeature/constants/values';

const getRoundTableConfig = streamLength => {
    switch (true) {
        case streamLength <= 2:
            return ROUND_TABLE_CONFIGURATIONS.HIGH;
        case streamLength <= 4:
            return ROUND_TABLE_CONFIGURATIONS.MEDIUM;
        case streamLength <= 9:
            return ROUND_TABLE_CONFIGURATIONS.LOW;
        case streamLength > 9:
            return ROUND_TABLE_CONFIGURATIONS.VLOW;
        default:
            return ROUND_TABLE_CONFIGURATIONS.LOW;
    }
};

const getPanelDiscussionConfig = streamLength => {
    let encoderConfiguration = LOW_STREAM_CONFIGURATION;

    if (streamLength <= 2) {
        encoderConfiguration = HIGH_STREAM_CONFIGURATION;
    }

    if (streamLength > 2 && streamLength < 9) {
        encoderConfiguration = MEDIUM_STREAM_CONFIGURATION;
    }

    return encoderConfiguration;
};

export const getStreamConfiguration = ({
    useHighStream,
    isPanelDiscussion,
    isScreenSharing,
    roundTable,
    streamLength,
    forceHighStream,
}) => {
    if (forceHighStream) {
        return HIGH_STREAM_CONFIGURATION;
    }

    if (roundTable) {
        if (isScreenSharing) {
            return ROUND_TABLE_CONFIGURATIONS.LOW;
        }

        return getRoundTableConfig(streamLength);
    }

    if (isPanelDiscussion) {
        return getPanelDiscussionConfig(streamLength);
    }

    return useHighStream ? HIGH_STREAM_CONFIGURATION : LOW_STREAM_CONFIGURATION;
};

export const isEncodingTheSame = (presetEncoding, currentEncoding) =>
    (typeof presetEncoding === 'string'
        ? presetEncoding.replace(/p_[0-9]/g, '')
        : presetEncoding.height) === currentEncoding.height;

export default { isEncodingTheSame, getStreamConfiguration };

import React from 'react';
import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { useHistory, withRouter } from 'react-router-dom';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: ${props => (!props.railnavigation ? '20px' : '210px')} !important;
    transition: margin-left 0.2s linear;
`;

const StyledIconButton = styled(ArrowBackIcon)`
    color: #fff;
    height: 16px;
`;

const StyledText = styled.div`
    font-family: Cabin, sans-serif;
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    ${props => props.marginleft && 'margin-left: 20px;'}
`;

const HeaderTitle = ({ title, isSideBarOpen }) => {
    const { goBack, location } = useHistory();
    const displayBackIcon = location.pathname !== '/';

    return (
        <StyledContainer railnavigation={isSideBarOpen ? 1 : 0}>
            {displayBackIcon && (
                <IconButton aria-label="goback" size="large" onClick={goBack}>
                    <StyledIconButton />
                </IconButton>
            )}
            <StyledText marginleft={!displayBackIcon ? 1 : 0}>{title}</StyledText>
        </StyledContainer>
    );
};

export default withRouter(HeaderTitle);

import React from 'react';
import styled from 'styled-components';

import Slides from '../../virtualFeature/components/presenter/LeftContent/Slides';
import Votes from './Votes';
import entities from '../../virtualFeature/constants/entities';
import { WideRedStyledChip } from '../../virtualFeature/components/presenter/LeftContent/LeftContent';
import { useVMState } from '../../virtualFeature/containers/main';
import usePresentingStartTime from '../hooks/usePresentingStartTime';

const Wrapper = styled.div`
    flex: 4;
    margin: 0 24px 0 0;
    overflow: scroll;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 16px;
`;

const ModeratorVote = props => {
    const { pollSet, changeFromPollsActiveRef } = props;
    const stateCtx = useVMState();
    const { activePoll, virtualEventSession, virtualEventUser } = stateCtx;
    const { getPresentingTime } = usePresentingStartTime();
    const { isActive } = virtualEventUser.data;
    const isBroadcasting =
        virtualEventSession.data.status === entities.virtualEventSessionEntity.status.broadcasting;

    return (
        <Wrapper className="eureka-react">
            <Slides
                pollSet={pollSet}
                changeFromPollsActiveRef={changeFromPollsActiveRef}
                isModerator
            >
                {isBroadcasting && isActive && !activePoll && (
                    <WideRedStyledChip>
                        <p>{`You are presenting - ${getPresentingTime()}`}</p>
                    </WideRedStyledChip>
                )}
            </Slides>
            <Votes
                pollSet={pollSet}
                changeFromPollsActiveRef={changeFromPollsActiveRef}
                isModerator
            />
        </Wrapper>
    );
};

export default ModeratorVote;

import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, FontIcon } from 'react-md';
import { useTheme } from '../Theme/ThemeContext';

const InputTextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 2px;
    left: 0;
    right: 0;
    width: 100%;
    padding: ${props => (props.padding ? props.padding : '12px 16px')};
    background-color: #ffffff;
    border-radius: 8px;
`;

const ButtonStyled = styled(Button)`
    height: 36px !important;
    width: 36px !important;
    min-width: 36px !important;
    vertical-align: middle !important;
    border-radius: 50% !important;
    padding: 0 !important;
    margin-left: 4px;
    &:hover {
        background: rgba(0, 0, 0, 0.1) !important;
    }
    &:active {
        background: rgba(0, 0, 0, 0.2) !important;
    }
`;

const TextInput = styled.textarea`
    border: none;
    outline: none;
    width: 100%;
    padding: 6px 12px;
    border-radius: 20px;
    background-color: #f5f5f5;
    font-size: 15px;
    line-height: 24px;
    caret-color: ${props => props.caretColor};
    resize: none;
    box-sizing: border-box;
    display: block;
    max-width: 100%;
    transition: box-shadow 0.5s ease;
    border: ${props => (props.redBorder ? 'solid 1px #d50000' : 'none')};
    font-smoothing: subpixel-antialiased;
`;

const Icon = styled(FontIcon)`
    color: ${props => props.color} !important;
    vertical-align: top;
    font-size: 24px !important;
`;

const ErrorMessage = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 15px;
    color: #d50000;
    margin-left: 16px;
`;

const textareaLineHeight = 20;

const TextAreaMessageComponent = ({
    sendMessage,
    placeholder,
    onChange,
    maxChar,
    padding,
    disabledTextArea,
    initialMessage,
}) => {
    const [inputMessage, setInputMessage] = useState(initialMessage ? initialMessage : '');
    const [maxCharExceeded, setMaxCharExceeded] = useState(false);
    const [textAreaRows, setRows] = useState({
        rows: 1,
        minRows: 1,
        maxRows: 5,
    });
    const { theme } = useTheme();

    const handleChange = event => {
        const { value, rows } = event.target;
        const { minRows, maxRows } = textAreaRows;
        const previousRows = rows;
        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

        setMaxCharExceeded(false);
        if (maxChar && value.length > maxChar) {
            setMaxCharExceeded(true);
            return;
        }

        setInputMessage(value);
        if (onChange) {
            onChange(value);
        }

        if (value === '') {
            setRows({
                ...textAreaRows,
                rows: 1,
            });
            return;
        }

        event.target.rows = minRows; // reset number of rows in textarea

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        setRows({
            ...textAreaRows,
            rows: currentRows < maxRows ? currentRows : maxRows,
        });
    };

    const onKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            onSendMessage();
        }
    };

    const onSendMessage = () => {
        if (validMessage(inputMessage)) {
            sendMessage(inputMessage);
            setInputMessage('');
            setRows({
                ...textAreaRows,
                rows: 1,
            });
        }
    };

    const validMessage = text => {
        return !(!text || !text.replace(/\s/g, '').length || maxCharExceeded);
    };

    return (
        <>
            <InputTextWrapper padding={padding}>
                <TextInput
                    id="messageInputField"
                    placeholder={placeholder}
                    value={inputMessage}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                    autoComplete="off"
                    rows={textAreaRows.rows}
                    redBorder={maxCharExceeded}
                    caretColor={theme.primary}
                    disabled={disabledTextArea}
                />
                {inputMessage && (
                    <ButtonStyled
                        flat
                        onClick={onSendMessage}
                        disabled={!validMessage(inputMessage)}
                    >
                        <Icon color={validMessage(inputMessage) ? '#1fa294' : '#f0f0f0'}>send</Icon>
                    </ButtonStyled>
                )}
            </InputTextWrapper>
            {maxCharExceeded && maxChar && (
                <ErrorMessage>
                    {maxChar}/{maxChar} characters
                </ErrorMessage>
            )}
        </>
    );
};

export default TextAreaMessageComponent;

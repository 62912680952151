import React from 'react';
import styled from 'styled-components';

import { getString } from '../../../services/api/store';

const Wrapper = styled.div`
    padding: 16px;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
`;

const NoNotificationPlaceholder = () => {
    const NO_NOTIFICATIONS_TEXT =
        getString('noNotificationsMessage') || 'There are no notifications.';
    return <Wrapper>{NO_NOTIFICATIONS_TEXT}</Wrapper>;
};

export default NoNotificationPlaceholder;

import { useCallback } from 'react';

import { useVMMutation, useVMState } from '../containers/main';
import onUpdatePresentationUrl from '../events/onUpdatePresentationUrl';
import entities from '../constants/entities';

const useOnDropPresentationUrlPoster = () => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();

    return useCallback(
        acceptedFiles => {
            return onUpdatePresentationUrl(
                stateCtx,
                mutationCtx,
                acceptedFiles,
                entities.virtualEventUserEntity.mode.poster,
            );
        },
        [stateCtx],
    );
};

export default useOnDropPresentationUrlPoster;

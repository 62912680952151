import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { getObjectPage } from './services/objectPage';
import { getObjectClassWithId } from '../../services/api/db';
import { navigateTo } from '../../components/Navigation/NavigationLink';

const ObjectPage = props => {
    const { match, history, location, pageId } = props;
    useEffect(() => {
        const search = location.search;
        if (!search) {
            const getObjectInfo = () => {
                getObjectPage(pageId, (err, result) => {
                    if (result && result.params && result.params.objectId) {
                        const objectId = result.params.objectId;
                        getObjectClassWithId(objectId, (err, objectClass) => {
                            navigateTo({
                                match,
                                history,
                                location,
                                params: {
                                    type: 'detail',
                                    objectClass,
                                    objectId,
                                },
                                replace: true,
                            });
                        });
                    }
                });
            };
            getObjectInfo();
        }
    }, [pageId, location.search]);

    return <React.Fragment key={'link_' + props.location.key}></React.Fragment>;
};
export default withRouter(ObjectPage);

import React, { useState } from 'react';
import styled from 'styled-components';

import MiddleSectionFooter from './MiddleSectionFooter';
import RightSectionFooter from './RightSectionFooter';
import entities from '../../constants/entities';
import { useVMMutation, useVMState } from '../../containers/main';
import { useTheme } from '../../../../../../components/Theme/ThemeContext';
import { colorTheme } from '../../constants/colors';
import get from 'lodash/get';
import {
    CancelModalButton,
    ContentModalContainer,
    DestructiveModalButton,
} from '../moderator/common/styles';
import ConfirmationDialog from '../common/ConfirmatonDialog';
import { onDeleteIntroductionVideo } from '../../events/onDeleteIntroductionVideo';
import onDeleteAudioDescription from '../../events/onDeleteAudioDescription';
import { isBoolean } from 'lodash';

const { virtualEventSessionEntity } = entities;

const Wrapper = styled.div`
    position: fixed;
    display: flex;
    bottom: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    width: 100%;
    padding: 0 24px;
    background-color: #fff;
    z-index: 3;
`;

const MainFooterButton = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: ${props => (props.width ? props.width : 124)}px;
    height: 40px;
    border-radius: 6px;
    border: none;
    background-color: ${props => (props.backgroundcolor ? props.backgroundcolor : '#6461E9')};
    cursor: pointer;
    font-family: Roboto;
    font-weight: 500;
    font-size: 15px;
    ${props => props.marginright && 'margin-right: 8px;'}
`;

const CancelFooterButton = styled(MainFooterButton)`
    background-color: ${colorTheme.SECONDARY};
    color: ${colorTheme.WASHED_BLACK};
    width: 75px;
`;

const PreviewVideoButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    padding: 0 25px;
    margin-bottom: -3px;
`;

const RoundTableFooter = () => {
    const { theme } = useTheme();
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const {
        virtualEventSession,
        socket,
        sessionId,
        virtualEventUser,
        isReviewingVideo,
        isReviewingAudio,
        timeslot,
    } = stateCtx;
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const virtualEventSessionStatus = virtualEventSession.data.status;
    const isRoomOpen = virtualEventSessionStatus === virtualEventSessionEntity.status.broadcasting;
    const isPrerecording = get(virtualEventUser, 'data.isPrerecording') || false;
    const isAudioRecording = get(virtualEventUser, 'data.isAudioRecording') || false;
    const list = get(virtualEventUser, 'data.recordingInfo.list') || [];
    const uploadedVideoUrl = get(virtualEventUser, 'data.recordingInfo.videoUrl');
    const allowHostToStartMeeting = isBoolean(get(timeslot, 'allowHostToStartMeeting'))
        ? get(timeslot, 'allowHostToStartMeeting')
        : true;
    const isUploadedVideo = uploadedVideoUrl && !list.length;

    const addVideoToRoom = () => {
        const preRecordedPresentationUrl = get(virtualEventUser, 'data.preRecordedPresentationUrl');
        const videoInfo = list.find(info => info.type === 'video');
        const videoUrl = isUploadedVideo
            ? uploadedVideoUrl
            : `${preRecordedPresentationUrl}/video/${videoInfo.sid}_${sessionId}.m3u8`;

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isPrerecording: !isPrerecording,
                    },
                ],
                videoUrls: [videoUrl],
            },
            userId: virtualEventUser.data.UserId,
        });
        mutationCtx.setIsReviewingVideo(false);
    };

    const addAudioToRoom = () => {
        const audioRecordingPresentationUrl = get(
            virtualEventUser,
            'data.audioRecordingPresentationUrl',
        );
        const list = get(virtualEventUser, 'data.audioRecordingInfo.list', []);
        const audioInfo = list.find(info => info.type === 'audio');
        const audioUrl =
            audioRecordingPresentationUrl &&
            audioInfo &&
            audioInfo.sid &&
            `${audioRecordingPresentationUrl}/audio/${audioInfo.sid}_${sessionId}.mp4`;

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isAudioRecording: !isAudioRecording,
                    },
                ],
                audioUrls: [audioUrl],
            },
            userId: virtualEventUser.data.UserId,
        });

        mutationCtx.setIsRecordingAudio(false);
        mutationCtx.setIsReviewingAudio(false);
    };

    const onUpdateVirtualEventSessionStatus = virtualEventSessionStatus => {
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                status: virtualEventSessionStatus,
            },
        });
    };

    const toggleRoomStatus = () => {
        onUpdateVirtualEventSessionStatus(
            isRoomOpen
                ? virtualEventSessionEntity.status.closed
                : virtualEventSessionEntity.status.broadcasting,
        );
    };

    const onDeleteVideo = async () => {
        await onDeleteIntroductionVideo(stateCtx, mutationCtx);

        mutationCtx.setIsReviewingVideo(false);
        setShowDeleteModal(false);
    };

    const onDeleteAudio = async () => {
        await onDeleteAudioDescription(stateCtx, mutationCtx);

        mutationCtx.setIsReviewingAudio(false);
        mutationCtx.setIsRecordingAudio(false);
        setShowDeleteModal(false);
    };

    if (isReviewingVideo || isReviewingAudio) {
        return (
            <Wrapper>
                <PreviewVideoButtonsContainer>
                    <MainFooterButton
                        backgroundcolor={theme.primary}
                        marginright
                        onClick={isReviewingAudio ? addAudioToRoom : addVideoToRoom}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                isReviewingAudio ? addAudioToRoom() : addVideoToRoom();
                            }
                        }}
                        width={190}
                    >
                        {isReviewingAudio
                            ? 'Add as audio description'
                            : 'Add as introduction video'}
                    </MainFooterButton>
                    <CancelFooterButton
                        onClick={() => setShowDeleteModal(true)}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                setShowDeleteModal(true);
                            }
                        }}
                    >
                        Delete
                    </CancelFooterButton>
                </PreviewVideoButtonsContainer>
                <ConfirmationDialog
                    open={showDeleteModal}
                    title={
                        isReviewingAudio ? 'Delete audio description' : 'Delete introduction video'
                    }
                    titleIcon="delete"
                    iconColor="rgba(0, 0, 0, 0.87)"
                    iconWrapperColor="#EFEFEF"
                    withCloseButton
                    outlinedIcon
                    onClose={() => setShowDeleteModal(false)}
                    content={
                        <ExtraStyledModalContentContainer>
                            {isReviewingAudio
                                ? 'Your audio description will be permanently deleted and cannot be recovered.'
                                : 'Your introduction video will be permanently deleted and cannot be recovered.'}
                        </ExtraStyledModalContentContainer>
                    }
                    buttons={[
                        <DestructiveModalButton
                            key="b1"
                            flat
                            onClick={isReviewingAudio ? onDeleteAudio : onDeleteVideo}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    isReviewingAudio ? onDeleteAudio() : onDeleteVideo();
                                }
                            }}
                        >
                            Delete
                        </DestructiveModalButton>,
                        <CancelModalButton
                            key="b2"
                            flat
                            onClick={() => setShowDeleteModal(false)}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    setShowDeleteModal(false);
                                }
                            }}
                        >
                            Cancel
                        </CancelModalButton>,
                    ]}
                />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {!isPrerecording && allowHostToStartMeeting ? (
                <MainFooterButton
                    backgroundcolor={!isRoomOpen ? theme.primary : colorTheme.SYSTEM_ERROR}
                    onClick={toggleRoomStatus}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            toggleRoomStatus();
                        }
                    }}
                >
                    {isRoomOpen ? 'Stop meeting' : 'Start meeting'}
                </MainFooterButton>
            ) : (
                <div />
            )}
            <MiddleSectionFooter />
            {!isPrerecording ? <RightSectionFooter /> : <div />}
        </Wrapper>
    );
};

export default RoundTableFooter;

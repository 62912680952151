import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import AnalyticsService from '../services';

const useAnalyticsTracker = (installationId, parameters) => {
    const [params, setParams] = useState({});

    useEffect(() => {
        if (isEqual(params, parameters)) {
            return;
        }

        setParams(parameters);
    }, [parameters]);

    useEffect(() => {
        const trackPage = (installationId, params) => {
            if (!installationId || isEmpty(params)) {
                return;
            }

            let type;
            let objectId;
            let object;
            const isRightPanelView = !params.pageName;

            if (isRightPanelView) {
                type = 'object';
                object = params.objectClass || params.type || params.pageType;
                objectId =
                    params.objectId ||
                    params.personLinkedId ||
                    params.userId ||
                    params.conversationId ||
                    params.pageId ||
                    params.childrenId;
            } else {
                type = 'page';
                object = params.pageName;
                objectId = params.pagePath || params.pageName;
            }

            AnalyticsService.addSample(type, object, objectId, installationId);
        };

        trackPage(installationId, params);
    }, [installationId, params]);
};

export default useAnalyticsTracker;

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const createRequestTypes = base => {
    const types = [REQUEST, SUCCESS, FAILURE];
    return types.reduce((acc, type) => {
        acc[type] = `${base}_${type}`;
        return acc;
    }, {});
};

import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactPlayer from 'react-player';
import { FontIcon } from 'react-md';
import styled from 'styled-components';
import get from 'lodash/get';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import { useVMMutation, useVMState } from '../../containers/main';
import { BeautifulLoader } from './BeautifulLoader';
import { CLOUDFRONT_URL } from '../../../../../../config';
import { colorTheme } from '../../constants/colors';
import VideoSlider from './VideoSlider';
import getThumbnail from '../../utils/getThumbnail';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    * > video {
        border-radius: 8px;
    }
`;

const PlayerWrapper = styled.div`
    cursor: ${props => (props.primary ? 'pointer' : 'default')};
    position: relative;
    height: 100%;
`;

const PlayIcon = styled(FontIcon)`
    font-size: 24px !important;
    color: ${colorTheme.WHITE} !important;
    cursor: pointer !important;
    margin-right: 16px;
`;

const Duration = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.6);
    margin: 0 8px;
`;

const SliderContainer = styled.div`
    position: absolute;
    bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 8px;
    height: 40px;
    width: calc(100% - 16px);
`;

const SliderWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 696px;
    padding: 0 20px;
    background-color: rgba(64, 64, 64, 0.8);
    border-radius: 8px;
`;

const DeleteIconWrapper = styled.button`
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    background-color: rgba(64, 64, 64, 0.8);
    cursor: pointer;
    border: none;
`;

const CenteredPlayIconWrapper = styled.div`
    position: absolute;
    left: calc(50% - 28px);
    top: calc(50% - 28px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 56px;
    background-color: rgba(64, 64, 64, 0.8);
    border-radius: 50%;
    cursor: pointer;
`;

const Thumbnail = styled.img`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 8px;
`;

const iconStyle = {
    color: colorTheme.WHITE,
    cursor: 'pointer',
};

const DEV_RECORDING_BUCKET_URL =
    'https://s3.eu-central-1.amazonaws.com/stream-recordings.dev.indrina.com';
const PROD_RECORDING_BUCKET_URL =
    'https://s3.eu-central-1.amazonaws.com/stream-recordings.web.indrina.com';

const HostedSessionMediaPlayer = ({
    url: providedUrl,
    primary,
    muted,
    onDelete,
    playEnabled,
    ...props
}) => {
    const playerRef = useRef(null);
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();

    const { duration, playing, playedSeconds, primaryPlayerRef, secondaryPlayerRef } = stateCtx;
    const [isContainerHovered, setIsContainerHovered] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [showControls, setShowControls] = useState(!playing);
    const [videoThumbnail, setVideoThumbnail] = useState('');

    const canPlay = playEnabled === true || playEnabled === undefined;

    const url = useMemo(() => {
        if (!providedUrl) {
            return;
        }

        return providedUrl
            .replace(DEV_RECORDING_BUCKET_URL, CLOUDFRONT_URL)
            .replace(PROD_RECORDING_BUCKET_URL, CLOUDFRONT_URL);
    }, [providedUrl]);

    useEffect(() => {
        if (isContainerHovered && playing) {
            setShowControls(true);
        } else if (!isContainerHovered && playing) {
            setTimeout(() => {
                setShowControls(false);
            }, 3000);
        } else {
            setShowControls(true);
        }
    }, [isContainerHovered, playing]);

    useEffect(() => {
        playerRef.current.seekTo(2, 'seconds');
    }, []);

    useEffect(() => {
        if (primary) {
            mutationCtx.setPrimaryPlayerRef(playerRef);
        } else {
            mutationCtx.setSecondaryPlayerRef(playerRef);
        }
    }, [playerRef, primary, url]);

    useEffect(() => {
        // Resume video from playedSeconds when mainMedia is switched
        const { playedSeconds } = stateCtx;

        if (playedSeconds) {
            playerRef.current.seekTo(playedSeconds, 'seconds');
        }
    }, [muted, url]);

    useEffect(() => {
        if (url) {
            (async () => {
                const imageData = await getThumbnail(url);

                setVideoThumbnail(imageData);
            })();
        }
        return () => {
            mutationCtx.setPrimaryPlayerRef(null);
            mutationCtx.setSecondaryPlayerRef(null);
        };
    }, [url]);

    const roundValue = value => Math.round(value * 10) / 10;

    const onPlayClick = () => {
        if (canPlay && primary) {
            if (playing) {
                mutationCtx.stopPlaying();
            } else {
                mutationCtx.startPlaying();
            }
        }
    };

    const onProgress = progress => {
        const { playedSeconds } = progress;
        const roundedValue = roundValue(playedSeconds);

        if (!primary) {
            return;
        }

        if (roundedValue <= Math.floor(duration)) {
            mutationCtx.setPlayedSeconds(roundedValue);
        } else {
            mutationCtx.stopPlaying();

            if (get(primaryPlayerRef, 'current')) {
                primaryPlayerRef.current.seekTo(2, 'seconds');
            }

            if (get(secondaryPlayerRef, 'current')) {
                secondaryPlayerRef.current.seekTo(2, 'seconds');
            }
        }
    };

    const onDuration = newDuration => {
        if (primary) {
            mutationCtx.setPrimaryVideoDuration(newDuration);
        } else {
            mutationCtx.setSecondaryVideoDuration(newDuration);
        }
    };

    const onEnded = () => mutationCtx.stopPlaying();

    const convertSecondsToTimeString = seconds => {
        return new Date(seconds * 1000).toISOString().substr(14, 5);
    };

    const onSliderValueChanged = (value, event) => {
        const roundedValue = roundValue(value);

        // Pause video while dragging slider
        if (event.type === 'mousemove') {
            mutationCtx.stopPlaying();
        }

        if (get(primaryPlayerRef, 'current')) {
            primaryPlayerRef.current.seekTo(roundedValue, 'seconds');
        }

        if (get(secondaryPlayerRef, 'current')) {
            secondaryPlayerRef.current.seekTo(roundedValue, 'seconds');
        }

        if (event.type === 'mouseup') {
            mutationCtx.startPlaying();
        }
    };

    const openFullscreen = async () => {
        const elem = document.getElementById('introduction-video');

        if (elem.requestFullscreen) {
            await elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE11 */
            elem.msRequestFullscreen();
        }
        setIsFullScreen(true);
    };

    const closeFullScreen = async () => {
        if (document.exitFullscreen) {
            await document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            /* IE11 */
            document.msExitFullscreen();
        }
        setIsFullScreen(false);
    };

    return (
        <Wrapper
            className="eureka-react"
            data-qa={props['data-qa']}
            onMouseEnter={() => setIsContainerHovered(true)}
            onMouseLeave={() => setIsContainerHovered(false)}
            id="introduction-video"
        >
            <BeautifulLoader active={!canPlay}>
                <PlayerWrapper primary={primary} onClick={onPlayClick}>
                    <ReactPlayer
                        url={url}
                        playing={playing}
                        width={'100%'}
                        height={'100%'}
                        onProgress={onProgress}
                        onDuration={onDuration}
                        onEnded={onEnded}
                        progressInterval={100}
                        ref={playerRef}
                        muted={muted}
                        loop={true}
                    />
                    {!playing && playedSeconds === 0 && videoThumbnail && (
                        <Thumbnail src={videoThumbnail} />
                    )}
                    {showControls && (!isFullScreen || !playing) && (
                        <CenteredPlayIconWrapper onClick={onPlayClick}>
                            {!playing ? (
                                <PlayArrowIcon style={iconStyle} />
                            ) : (
                                <PauseIcon style={iconStyle} />
                            )}
                        </CenteredPlayIconWrapper>
                    )}
                </PlayerWrapper>
                {primary && showControls && (
                    <SliderContainer>
                        <SliderWrapper>
                            {canPlay && primary && (
                                <PlayIcon
                                    onClick={onPlayClick}
                                    containerhovered={isContainerHovered.toString()}
                                >
                                    {playing ? 'pause' : 'play_arrow'}
                                </PlayIcon>
                            )}
                            {duration && (
                                <>
                                    <Duration>
                                        {convertSecondsToTimeString(playedSeconds || 0)}
                                    </Duration>
                                    <VideoSlider
                                        min={0}
                                        max={roundValue(duration)}
                                        step={0.1}
                                        value={playedSeconds}
                                        onChange={onSliderValueChanged}
                                    />
                                    <Duration>
                                        -
                                        {convertSecondsToTimeString(
                                            duration - (playedSeconds || 0),
                                        )}
                                    </Duration>
                                    {!isFullScreen && (
                                        <FullscreenIcon
                                            style={iconStyle}
                                            onClick={openFullscreen}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter') {
                                                    openFullscreen();
                                                }
                                            }}
                                        />
                                    )}
                                    {isFullScreen && (
                                        <FullscreenExitIcon
                                            style={iconStyle}
                                            onClick={closeFullScreen}
                                        />
                                    )}
                                </>
                            )}
                        </SliderWrapper>
                    </SliderContainer>
                )}
                {onDelete && (
                    <DeleteIconWrapper
                        onClick={onDelete}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                onDelete();
                            }
                        }}
                    >
                        <DeleteOutlineIcon style={iconStyle} />
                    </DeleteIconWrapper>
                )}
            </BeautifulLoader>
        </Wrapper>
    );
};

export default HostedSessionMediaPlayer;

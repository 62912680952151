import React from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useSocket } from '../../../../components/Session/SocketContext';

import AppWrapper from './AppWrapper';
import { populateDefaultState } from './reducers/main';

AgoraRTC.enableLogUpload();

const VirtualFeature = () => {
    const { socket } = useSocket();
    populateDefaultState({
        socket,
    });

    return <AppWrapper />;
};

export default VirtualFeature;
// eureka is an angular http service.
// angular
//     .module('eurekaApp')
//     .component(
//         'myComponent',
//         react2angular(
//             VirtualFeature,
//             [],
//             [
//                 'Settings',
//                 'socket',
//                 'VirtualEventSession',
//                 'VirtualEventUser',
//                 'VirtualEventToken',
//                 'ExternalObject',
//                 'Auth',
//                 'Polling',
//                 'eureka',
//             ],
//         ),
//     );

// Import as angular component. Can take scope properties and inject all angular services.
// https://github.com/coatue-oss/react2angular
// my-component(foo-bar="3", baz="'baz'") anywhere in angular jade

// Or pure react. (can not be used in injected angular jade-html needs to be accesible on load like index.html)
// <div id='acomponent'></div> in index.html

// const elem = document.getElementById('acomponent');
// ReactDOM.render(<TestComponent/>, elem );

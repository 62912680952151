import sortBy from 'lodash/sortBy';
import { getImageSource, getLocalAppStateAsync } from '../../../services/api/db';
import { executeListQuery, executeQuery, getItem } from '../../../services/api/graphQlRepository';

const getFloorplan = function (pageId, next) {
    if (!pageId) {
        return next('no pageId found');
    }

    getItem('pages', pageId, async (err, page) => {
        if (err || !page) {
            next(err || 'no floorplan page found');
        }

        const floorplans = await executeQuery('getFloorplansWithEvent', {
            event: page.event,
        });

        next(null, {
            page,
            floorplans: sortBy(floorplans, o => o.orderingName),
        });
    });
};

const getPlacesByType = async function (typeId, callback) {
    const places = await executeQuery('getPlacesWithType', { type: typeId });

    callback(
        null,
        sortBy(places, o => o.name),
    );
};

const getPLacesByFloorplan = async function (floorplanId, callback) {
    const places = await executeQuery('getPlacesWithFloorplan', {
        floorplan: floorplanId,
    });

    callback(
        null,
        sortBy(places, o => o.name),
    );
};

const getPlaceById = id => {
    if (!id) {
        return null;
    }
    return executeQuery('getPlaces', { id });
};

const getTimeslotsByPlace = async placeId => {
    const additionalFilter = {
        byLocationId: placeId,
    };
    const response = await executeListQuery(
        'findTimeslots',
        {
            event: {
                eq: `byLocationId:${placeId}`, //will be removed in executeListQuery but will be used for caching
            },
        },
        false,
        false,
        additionalFilter,
    );

    return response;
};

export {
    getFloorplan,
    getPlacesByType,
    getImageSource,
    getPLacesByFloorplan,
    getItem,
    getPlaceById,
    getTimeslotsByPlace,
};

import React from 'react';
import styled, { css } from 'styled-components';
import { FontIcon } from 'react-md';

import VoteOption from './VoteOption';
import { votesColorScheme } from './QuestionModal';
import HowToVoteIcon from '@material-ui/icons/HowToVoteOutlined';
import get from 'lodash/get';

const VotingPreviewWrapper = styled.div`
    ${props => props.isrecording && 'position: relative'};
    background-color: #ffffff;
    z-index: 1;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 40px 0px 16px 0px;
    ${props =>
        props.isrecording &&
        css`
            position: absolute;
            top: -1500px;
            left: 0;
            right: 0;
        `}
`;

const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-bottom: 8px;
`;

const Img = styled.img`
    height: ${props => (props.isrecording ? '176px' : '118px')};
    max-width: ${props => (props.isrecording ? '50%' : '205px')};
    width: ${props => (props.isrecording ? '50%' : '205px')};
    z-index: 10;
    border-radius: 8px;
    margin-right: 16px;
    object-fit: contain;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 16px 0;
`;

const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: ${props => (props.isrecording ? '176px' : '90px')};
    ${props => props.isrecording && 'width: 50%'};
`;

const FlexContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
`;

const StyledIcon = styled(FontIcon)`
    width: 18px;
    height: 21px;
    color: rgba(0, 0, 0, 0.87);
    margin-right: 8px;
`;

const SecondsText = styled.span`
    width: 28px;
    height: 18px;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin: 5px 0 0 0;
`;

const PlaceholderContainer = styled.div`
    width: fit-content;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f3;
    height: 24px;
    padding: 0 8px;
    border-radius: 16px;
`;

const PlaceholderText = styled.span`
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-family: Cabin, sans-serif;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.43px;
    text-align: center;
`;

const TotalVotesContainer = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.86px;
    color: rgba(0, 0, 0, 0.38);
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Bar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    background-color: #dedee1;
    width: 100%;
`;

const inlineStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
};

const VotingPreview = ({ activePoll, elRef, imageUrl, pollSet }) => {
    const { title, PollOptions, seconds, useTimer } = activePoll;
    const options = PollOptions.filter(option => option.text).sort((o1, o2) => o1.order - o2.order);
    const isForRecording = !!elRef;

    const optionsWithVotes = get(activePoll, 'PollOptions', [])
        .filter(option => option.text)
        .sort((o1, o2) => o1.order - o2.order)
        .map(option => ({
            ...option,
            votes: pollSet.votes[option.id],
        }));
    const totalVotes = optionsWithVotes.reduce((acc, option) => acc + option.votes, 0);

    return (
        <VotingPreviewWrapper innerRef={elRef} isrecording={isForRecording ? 1 : 0}>
            {isForRecording && <Bar />}
            {isForRecording && (
                <FirstRow>
                    <PlaceholderContainer>
                        <PlaceholderText>WAIT FOR VOTING TO START...</PlaceholderText>
                    </PlaceholderContainer>
                    <TotalVotesContainer>
                        {totalVotes}
                        <HowToVoteIcon />
                    </TotalVotesContainer>
                </FirstRow>
            )}
            <FlexRow>
                {imageUrl && (
                    <Img
                        src={imageUrl}
                        id="voting-preview-image"
                        isrecording={isForRecording ? 1 : 0}
                    />
                )}
                <FlexColumn isrecording={isForRecording ? 1 : 0}>
                    <Title>{title}</Title>
                    {!isForRecording && useTimer && (
                        <div style={inlineStyle}>
                            <StyledIcon>timer</StyledIcon>
                            <SecondsText>{seconds}s</SecondsText>
                        </div>
                    )}
                </FlexColumn>
            </FlexRow>
            <FlexContainer>
                {options.map((option, index) => (
                    <VoteOption
                        key={`preview-vote-option-${index}`}
                        label={String.fromCharCode(65 + index)}
                        option={option}
                        placeholder="Option"
                        color={votesColorScheme[index]}
                        displayMode
                        disableThisOption
                        isForRecording={isForRecording}
                        marginRight={index % 2 === 0}
                    />
                ))}
            </FlexContainer>
        </VotingPreviewWrapper>
    );
};

export default VotingPreview;

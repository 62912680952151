export default {
    virtualEventUserEntity: {
        role: {
            presenter: 'presenter',
            moderator: 'moderator',
            roundTableHost: 'roundTableHost',
            roundTableParticipant: 'roundTableParticipant',
        },
        mode: {
            slide: 'slide',
            prerecordedPresentation: 'prerecordedPresentation',
            videoFromURL: 'videoFromURL',
            poster: 'poster',
            video: 'video',
            vote: 'vote',
        },
    },

    virtualEventSessionEntity: {
        status: {
            closed: 'closed',
            initial: 'initial',
            broadcasting: 'broadcasting',
            ended: 'ended',
        },
        mode: {
            speakerAndSlider: 'speakerAndSlider',
            panelDiscussion: 'panelDiscussion',
        },
        roomType: {
            moderated: 'moderated',
            roundTable: 'roundTable',
            default: null,
        },
    },

    speakerViewMode: {
        audience: 'audience',
        live: 'live',
        prerecording: 'prerecording',
    },

    requestStatus: {
        initial: 'initial',
        loading: 'loading',
        success: 'success',
        error: 'error',
    },

    hostedSessionHostMode: {
        onlyParticipants: 'onlyParticipants',
        cards: 'cards',
        attendees: 'attendees',
        chat: 'chat',
    },
};

import entities from '../constants/entities';
import { parseVirtualEventSession } from '../utils/sortUtils';
import { TOAST_TYPES } from '../utils/toasts';

const defaultState = {
    // media devices
    peers: [],
    streams: [],
    localStream: null,
    currentStream: null,
    otherStreams: [],
    devicesList: [],
    cameraList: [],
    microphoneList: [],
    // web sdk params
    config: {
        uid: 0,
        host: false,
        role: 'host',
        resolution: '120p_1',
        audioProfile: 'high_quality_stereo',
        microphoneId: '',
        cameraId: '',
    },
    flags: {},
    agoraClient: null,
    mode: 'live',
    codec: 'h264',
    muteVideo: true,
    muteAudio: true,
    screen: false,
    profile: false,
    useDevices: false,
    virtualEventUser: {
        data: {},
        fetchRequestStatus: entities.requestStatus.initial,
        uploadRequestStatus: entities.requestStatus.initial,
    },
    virtualEventSession: {
        data: {},
        fetchRequestStatus: entities.requestStatus.initial,
        uploadRequestStatus: entities.requestStatus.initial,
        // Configurator placeholders
    },
    eventSliderPlaceholder: '',
    eventSpeakerPlaceholder: '',
    externalObject: {
        data: {},
        fetchRequestStatus: entities.requestStatus.initial,
    },
    timeslot: {},
    volumeIndicators: [],
    pdf: null,
    currentUser: null,
    url: '',
    sessionId: null,
    screenSharing: false,
    canvasSharing: null,
    activePoll: null,
    prerecording: false,
    audioRecording: false,
    showModal: false,
    showToast: false,
    audioToastDisplayed: false,
    speakerActivePoll: null,
    speakerViewMode: 'live',
    recreateStreams: false,
    // player
    primaryVideoDuration: 0,
    secondaryVideoDuration: 0,
    duration: null,
    playing: false,
    playedSeconds: 2,
    primaryPlayerRef: null,
    secondaryPlayerRef: null,
    audioDuration: null,
    audioPlaying: false,
    audioPlayerRef: null,
    audioPlayedSeconds: 0,
    // end player
    switchingPreRecording: false,
    isMicMutedModalOpen: false,
    roundTableHostMode: entities.hostedSessionHostMode.cards,
    isReviewingVideo: false,
    isRecordingAudio: false,
    isReviewingAudio: false,
};

export const populateDefaultState = extra => {
    Object.assign(defaultState, extra);
};

const reducer = (state, action) => {
    switch (action.type) {
        // clients
        case 'videoClient': {
            return { ...state, videoClient: action.payload };
        }
        case 'screenSharingClient': {
            return { ...state, screenSharingClient: action.payload };
        }
        // clients
        case 'config': {
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload,
                },
            };
        }
        case 'useDevices': {
            return { ...state, useDevices: action.payload };
        }
        case 'virtualEventUser': {
            return { ...state, virtualEventUser: action.payload };
        }
        case 'virtualEventSession': {
            const parsedVirtualEventSession = {
                ...action.payload,
                data: parseVirtualEventSession(action.payload.data),
            };

            return { ...state, virtualEventSession: parsedVirtualEventSession };
        }
        case 'virtualEventSessionData': {
            return {
                ...state,
                virtualEventSession: { ...state.virtualEventSession, data: action.payload },
            };
        }
        case 'externalObject': {
            return { ...state, externalObject: action.payload };
        }
        case 'extraState': {
            return { ...state, ...action.payload };
        }
        case 'pdf': {
            return { ...state, pdf: action.payload };
        }
        case 'url': {
            return { ...state, url: action.payload };
        }
        case 'codec': {
            return { ...state, codec: action.payload };
        }
        case 'video': {
            return { ...state, muteVideo: action.payload };
        }
        case 'videoIsPlaying': {
            return { ...state, videoIsPlaying: action.payload };
        }
        case 'audio': {
            return { ...state, muteAudio: action.payload };
        }
        case 'timer': {
            return { ...state, timer: action.payload };
        }
        case 'screen': {
            return { ...state, screen: action.payload };
        }
        case 'sessionId': {
            return { ...state, sessionId: action.payload };
        }
        case 'devicesList': {
            return { ...state, devicesList: action.payload };
        }
        case 'localStream': {
            return { ...state, localStream: action.payload };
        }
        case 'screenShareStream': {
            return { ...state, screenShareStream: action.payload };
        }
        case 'shareType': {
            return { ...state, shareType: action.payload };
        }
        case 'showToast': {
            // Audio toast should be displayed only one time.
            if (action.payload === TOAST_TYPES.AUDIO) {
                const { audioToastDisplayed } = state;
                const newAudioToastDisplayed = audioToastDisplayed || !!action.payload;

                return {
                    ...state,
                    showToast: !audioToastDisplayed && action.payload,
                    audioToastDisplayed: newAudioToastDisplayed,
                };
            } else {
                return {
                    ...state,
                    showToast: action.payload,
                };
            }
        }
        case 'shouldCalculatePosition': {
            return { ...state, shouldCalculatePosition: action.payload };
        }
        case 'joiningLoaderVisible': {
            return { ...state, joiningLoaderVisible: action.payload };
        }
        case 'profile': {
            return { ...state, profile: action.payload };
        }
        case 'currentUser': {
            return { ...state, currentUser: action.payload };
        }
        case 'timeslot': {
            return { ...state, timeslot: action.payload };
        }
        case 'currentStream': {
            const { streams } = state;
            const newCurrentStream = action.payload;
            const otherStreams = streams.filter(it => it.getId() !== newCurrentStream.getId());
            return { ...state, currentStream: newCurrentStream, otherStreams };
        }
        case 'screenSharing': {
            return { ...state, screenSharing: action.payload };
        }
        case 'canvasSharing': {
            const newState = { ...state, canvasSharing: action.payload };

            if (action.payload) {
                Object.assign(newState, {
                    shareType: 'canvas',
                });
            }

            return newState;
        }
        case 'addPeer': {
            const { peers } = state;
            const newPeer = action.payload;

            if (peers.find(peer => peer === newPeer)) {
                return state;
            }

            const newPeers = [...peers, newPeer];

            return {
                ...state,
                peers: newPeers,
            };
        }
        case 'setPeers': {
            const peers = action.payload;

            return {
                ...state,
                peers,
            };
        }
        case 'removePeer': {
            const { peers } = state;
            const peerToRemove = action.payload;
            const newPeers = [...peers].filter(peer => peer !== peerToRemove);
            return {
                ...state,
                peers: newPeers,
            };
        }
        case 'addStream': {
            const { streams, localStream, currentStream } = state;
            const newStream = action.payload;
            let newCurrentStream = currentStream;

            if (!newCurrentStream) {
                newCurrentStream = newStream;
            }

            const duplicateRemovedStreams = streams.filter(
                st => st.streamId !== newStream.streamId,
            );
            const newStreams = [...duplicateRemovedStreams, newStream];

            const otherStreams =
                !localStream ||
                !newCurrentStream ||
                newCurrentStream.getId() !== localStream.getId()
                    ? newStreams
                    : newStreams.filter(it => it.getId() !== newCurrentStream.getId());

            return {
                ...state,
                streams: newStreams,
                currentStream: newCurrentStream,
                otherStreams,
            };
        }
        case 'removeStream': {
            const { streams, currentStream } = state;
            const {
                user: { uid },
            } = action;
            const targetId = uid;
            let newCurrentStream = currentStream;
            // we want a new reference
            const newStreams = [...streams].filter(st => st.getId() !== targetId);

            if (currentStream && targetId === currentStream.getId()) {
                if (newStreams.length === 0) {
                    newCurrentStream = null;
                } else {
                    newCurrentStream = newStreams[0];
                }
            }

            const otherStreams = newCurrentStream
                ? newStreams.filter(it => it.getId() !== newCurrentStream.getId())
                : [];
            return {
                ...state,
                streams: newStreams,
                currentStream: newCurrentStream,
                otherStreams,
            };
        }
        case 'clearAllStream': {
            // const {streams, localStream, currentStream, beauty} = state;
            const { streams, localStream, currentStream } = state;
            streams.forEach(stream => {
                if (stream.isPlaying()) {
                    stream.stop();
                }
                // stream.close();
            });

            if (localStream) {
                localStream.isPlaying() && localStream.stop();
                localStream.close();
            }
            if (currentStream) {
                currentStream.isPlaying() && currentStream.stop();
                currentStream.close();
            }
            return {
                ...state,
                currentStream: null,
                screenShareStream: null,
                canvasSharing: null,
                screenSharing: false,
                localStream: null,
                shareType: null,
                streams: [],
            };
        }
        case 'activePoll': {
            const newState = {
                ...state,
                activePoll: action.payload,
            };

            if (action.payload) {
                newState.shareType = 'canvas';
                newState.screenSharing = false;
            }

            return newState;
        }
        case 'speakerActivePoll': {
            return { ...state, speakerActivePoll: action.payload };
        }
        case 'speakerViewMode': {
            return { ...state, speakerViewMode: action.payload };
        }
        case 'mutedBySystem': {
            return { ...state, mutedBySystem: action.payload };
        }
        case 'prerecording': {
            return { ...state, prerecording: action.payload };
        }
        case 'audioRecording': {
            return { ...state, audioRecording: action.payload };
        }
        case 'publishShareStream': {
            return { ...state, publishShareStream: action.payload };
        }
        // player
        case 'primaryVideoDuration': {
            const { secondaryVideoDuration } = state;
            const newState = {
                ...state,
                primaryVideoDuration: action.payload,
            };
            const secondaryValue = secondaryVideoDuration || action.payload;

            if (action.payload <= secondaryValue) {
                newState.duration = action.payload;
            }

            return newState;
        }
        case 'secondaryVideoDuration': {
            const { primaryVideoDuration } = state;
            const newState = {
                ...state,
                secondaryVideoDuration: action.payload,
            };
            const primaryValue = primaryVideoDuration || action.payload;

            if (action.payload <= primaryValue) {
                newState.duration = action.payload;
            }

            return newState;
        }
        case 'duration': {
            const newState = {
                ...state,
                duration: action.payload,
            };

            if (action.payload === null) {
                newState.playedSeconds = 2;
                newState.primaryVideoDuration = null;
                newState.secondaryVideoDuration = null;
            }

            return newState;
        }
        case 'audioDuration': {
            const newState = {
                ...state,
                audioDuration: action.payload,
            };

            if (action.payload === null) {
                newState.audioPlayedSeconds = 0;
            }

            return newState;
        }
        case 'playing': {
            return { ...state, playing: action.payload };
        }
        case 'playedSeconds': {
            return { ...state, playedSeconds: action.payload };
        }
        case 'primaryPlayerRef': {
            return { ...state, primaryPlayerRef: action.payload };
        }
        case 'audioPlaying': {
            return { ...state, audioPlaying: action.payload };
        }
        case 'audioPlayedSeconds': {
            return { ...state, audioPlayedSeconds: action.payload };
        }
        case 'audioPlayerRef': {
            return { ...state, audioPlayerRef: action.payload };
        }
        case 'recreateStreams': {
            return { ...state, recreateStreams: action.payload };
        }
        case 'secondaryPlayerRef': {
            return { ...state, secondaryPlayerRef: action.payload };
        }
        case 'switchingPreRecording': {
            return { ...state, switchingPreRecording: action.payload };
        }
        case 'myStream': {
            return { ...state, myStream: action.payload };
        }
        case 'micModal': {
            return { ...state, isMicMutedModalOpen: action.payload };
        }
        case 'roundTableHostMode': {
            return { ...state, roundTableHostMode: action.payload };
        }
        case 'isReviewingVideo': {
            return { ...state, isReviewingVideo: action.payload };
        }
        case 'isReviewingAudio': {
            return { ...state, isReviewingAudio: action.payload };
        }
        case 'isRecordingAudio': {
            return { ...state, isRecordingAudio: action.payload };
        }
        case 'eventSliderPlaceholder': {
            return {
                ...state,
                eventSliderPlaceholder: action.payload,
            };
        }
        case 'eventSpeakerPlaceholder': {
            return {
                ...state,
                eventSpeakerPlaceholder: action.payload,
            };
        }
        // end player
        default:
            throw new Error('mutation type not defined');
    }
};

const volumeReducer = (state, action) => {
    switch (action.type) {
        case 'volumeIndicators': {
            const { streams } = action.payload;
            const volumeIndicators = streams.map(st => ({
                uid: st.streamId,
                level: st.getAudioLevel() * 12,
            }));

            return { ...state, volumeIndicators };
        }
        // end player
        default:
            throw new Error('mutation type not defined');
    }
};

export { reducer, volumeReducer, defaultState };

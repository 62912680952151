import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { useVMState } from '../../containers/main';
import entities from '../../constants/entities';
import { ButtonClassType } from '../common/Button';

import ConfirmationDialog from '../common/ConfirmatonDialog';
import OpenRoomIcon from '@material-ui/icons/MeetingRoomOutlined';
import CloseRoomIcon from '@material-ui/icons/NoMeetingRoomOutlined';

import { ButtonWithImageContainer } from './Footer';
import BroadcastingButton from './BroadcastingButton';
import Loader from '../common/Loader';
import {
    CancelModalButton,
    ContentModalContainer,
    DestructiveModalButton,
    SaveModalButton,
} from '../moderator/common/styles';
import { sleep, getTimer } from '../../../../utils';
import { startRecording, stopRecording } from '../../services/VirtualEventSession';
import { useTheme } from '../../../../../../components/Theme/ThemeContext';

const { virtualEventSessionEntity } = entities;

const FlexCenter = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const MarginLeft23px = styled.div`
    margin-left: 23px;
`;

const ButtonWrapper = styled.div`
    align-items: center;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    width: 220px;
`;

const BroadcastingLoader = styled(Loader)`
    margin: 0;
    box-shadow: unset !important;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
`;

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    padding: ${props => (props.morePadding ? '0 40px' : '0 25px')};
    margin-bottom: -3px;
`;

const FooterModerator = () => {
    const stateCtx = useVMState();
    const { theme } = useTheme();
    const {
        screenSharingClient,
        virtualEventSession,
        virtualEventUser,
        sessionId,
        socket,
        streams,
        eventSliderPlaceholder,
        eventSpeakerPlaceholder,
    } = stateCtx;
    const virtualEventSessionStatus = virtualEventSession.data.status;
    const [isBroadcastingHovered, setIsBroadcastingHovered] = useState(false);
    const [time, setTime] = useState('00:00');
    const [broadcastingLoading, setBroadcastingLoading] = useState(false);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        if (virtualEventSession.data.status === virtualEventSessionEntity.status.broadcasting) {
            const startTimerMoment = get(virtualEventSession, 'data.startTimerMoment', null);

            if (startTimerMoment) {
                intervalRef.current = setInterval(() => {
                    const newTime = getTimer(parseInt(Date.now() - new Date(startTimerMoment)));

                    setTime(newTime);
                }, 1000);
            }
        } else {
            setTime('00:00');
        }

        setBroadcastingLoading(false);

        return () => clearInterval(intervalRef.current);
    }, [virtualEventSession.data.status]);

    const [modalOpened, setModalOpened] = useState({
        noRecording: false,
        recording: false,
        broadcasting: false,
        closeRoom: false,
        stopBroadcasting: false,
    });

    const handleModalChange = (type, value) =>
        setModalOpened({
            ...modalOpened,
            [type]: value,
        });

    const onUpdateVirtualEventSessionStatus = async (
        virtualEventSessionStatus,
        clearAttendeeLanguages,
    ) => {
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                id: virtualEventSession.id,
                status: virtualEventSessionStatus,
                ...(clearAttendeeLanguages && { attendeeCaptionLanguages: [] }),
            },
        });
    };

    const onSetStatusBroadcasting = async () => {
        if (broadcastingLoading) {
            return;
        }

        if (streams.length === 0) {
            handleModalChange('broadcasting', true);
            return;
        }

        try {
            setIsBroadcastingHovered(false);
            setBroadcastingLoading(true);

            // wait at least 1 second, for the animation to take effect
            await sleep(1000);
            await startRecording(sessionId, {
                eventSpeakerPlaceholder,
                eventSliderPlaceholder,
            });

            socket.emit('updateData', { objectId: sessionId });
            socket.emit('sharingMedia', {
                objectId: stateCtx.sessionId,
                userId: get(virtualEventUser, 'data.UserId', null),
                publishing: screenSharingClient._published === true,
            });
        } catch (e) {
            handleModalChange('noRecording', true);
        }
    };
    const onSetStatusInitial = async broadcasting => {
        if (broadcastingLoading) {
            return;
        }

        if (broadcasting) {
            setBroadcastingLoading(true);
            // wait at least 1 second for the animation to take effect
            await sleep(1000);

            try {
                await stopRecording(sessionId);
            } catch (e) {
                //
            }
        } else {
            await onUpdateVirtualEventSessionStatus(virtualEventSessionEntity.status.initial);
        }

        socket.emit('updateData', { objectId: sessionId });
    };
    const openCloseModal = () => {
        handleModalChange('closeRoom', true);
    };

    const onSetStatusClosed = async () => {
        await onUpdateVirtualEventSessionStatus(virtualEventSessionEntity.status.closed, true);
        handleModalChange('closeRoom', false);
    };

    const handleOpenRoom = async () => {
        const virtualEventUsers = get(virtualEventSession, 'data.VirtualEventUsers', []);
        const isSomeonePrerecording = virtualEventUsers.find(user => {
            return get(user, 'isPrerecording');
        });

        if (isSomeonePrerecording) {
            handleModalChange('recording', true);
        } else {
            await onSetStatusInitial();
        }
    };
    const stopRecordings = async () => {
        const virtualEventUsers = get(virtualEventSession, 'data.VirtualEventUsers', []);
        let promises = [];

        // stopping recording for all users who are prerecording
        virtualEventUsers.forEach(user =>
            promises.push(
                stopRecording(sessionId, {
                    preRecording: true,
                    userId: user.UserId,
                }),
            ),
        );

        await Promise.all(promises);

        // setting the flag off for all users who have it on
        const virtualEventUsersToBeModified = virtualEventUsers.map(user => ({
            id: user.id,
            isPrerecording: false,
        }));

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: virtualEventUsersToBeModified,
                status: virtualEventSessionEntity.status.initial,
            },
        });

        handleModalChange('recording', false);
    };

    if (virtualEventSessionStatus === virtualEventSessionEntity.status.broadcasting) {
        const getBroadcastingContent = () => {
            if (broadcastingLoading) {
                return (
                    <BroadcastingLoader
                        extraCircleStyle={{
                            color: '#fff',
                            marginLeft: '5px',
                            height: '15px',
                            width: '15px',
                        }}
                    />
                );
            }

            return isBroadcastingHovered ? 'Stop Broadcasting' : `Broadcasting ${time}`;
        };

        return (
            <MarginLeft23px>
                <ButtonWrapper>
                    <BroadcastingButton
                        content={getBroadcastingContent()}
                        classType={
                            isBroadcastingHovered || broadcastingLoading
                                ? ButtonClassType.RED_ROUND
                                : ButtonClassType.WHITE_RED_BORDERS_ROUND
                        }
                        data-qa="broadcasting-button"
                        loading={broadcastingLoading}
                        width={'220px'}
                        onMouseEnter={() => setIsBroadcastingHovered(true)}
                        onMouseLeave={() => setIsBroadcastingHovered(false)}
                        callback={() => handleModalChange('stopBroadcasting', true)}
                    />
                </ButtonWrapper>
                <div className="eureka-react">
                    <ConfirmationDialog
                        open={modalOpened.stopBroadcasting}
                        title="Stop broadcasting?"
                        withCloseButton
                        onClose={() => handleModalChange('recording', false)}
                        content={
                            <ExtraStyledModalContentContainer
                                style={{ textAlign: 'left', padding: 0 }}
                            >
                                Are you sure you want to stop broadcasting? Attendees will no longer
                                be able to follow the session and the recording will be ended.
                            </ExtraStyledModalContentContainer>
                        }
                        buttons={[
                            <SaveModalButton
                                key="SMBO"
                                flat
                                backgroundcolor={theme.primary}
                                onClick={() => {
                                    handleModalChange('stopBroadcasting', false);
                                    onSetStatusInitial(true);
                                }}
                            >
                                Stop broadcasting
                            </SaveModalButton>,
                            <CancelModalButton
                                key="SMBOC"
                                flat
                                onClick={() => handleModalChange('stopBroadcasting', false)}
                            >
                                Cancel
                            </CancelModalButton>,
                        ]}
                    />
                </div>
            </MarginLeft23px>
        );
    }

    if (virtualEventSessionStatus === virtualEventSessionEntity.status.initial) {
        const getBroadcastingContent = () => {
            if (broadcastingLoading) {
                return (
                    <BroadcastingLoader
                        extraCircleStyle={{
                            color: '#fff',
                            marginLeft: '5px',
                            height: '15px',
                            width: '15px',
                        }}
                    />
                );
            }

            return isBroadcastingHovered ? 'Start broadcasting' : 'Not broadcasting';
        };

        return (
            <FlexCenter>
                <ButtonWithImageContainer data-qa="close-room-moderator" onClick={openCloseModal}>
                    <CloseRoomIcon />
                    Close room
                </ButtonWithImageContainer>
                <ButtonWrapper>
                    <BroadcastingButton
                        content={getBroadcastingContent()}
                        classType={
                            isBroadcastingHovered || broadcastingLoading
                                ? ButtonClassType.WHITE_RED_BORDERS_ROUND
                                : ButtonClassType.WHITE_ROUND
                        }
                        loading={broadcastingLoading}
                        callback={onSetStatusBroadcasting}
                        data-qa="start-broadcasting-button"
                        width="220px"
                        onMouseEnter={() => setIsBroadcastingHovered(true)}
                        onMouseLeave={() => setIsBroadcastingHovered(false)}
                    />
                </ButtonWrapper>
                <div className="eureka-react">
                    <ConfirmationDialog
                        open={modalOpened.broadcasting}
                        title="Start broadcasting"
                        content="You need at least 1 stream in the channel to start broadcasting. Please open your camera and / or microphone or wait until someone does that."
                        onOk={() => handleModalChange('broadcasting', false)}
                    />
                    <ConfirmationDialog
                        open={modalOpened.noRecording}
                        title="Not recording"
                        content="The session won't be recorded, please ask tech help."
                        onOk={() => handleModalChange('noRecording', false)}
                    />
                </div>
                <div className="eureka-react">
                    <ConfirmationDialog
                        open={modalOpened.closeRoom}
                        title="Close room"
                        titleIcon="no_meeting_room"
                        iconColor="rgba(0, 0, 0, 0.87)"
                        iconWrapperColor="#EFEFEF"
                        withCloseButton
                        onClose={() => handleModalChange('closeRoom', false)}
                        content={
                            <ExtraStyledModalContentContainer morePadding>
                                Close the room will remove all attendees. Are you sure?
                            </ExtraStyledModalContentContainer>
                        }
                        buttons={[
                            <DestructiveModalButton key="DEMBC" flat onClick={onSetStatusClosed}>
                                Close
                            </DestructiveModalButton>,
                            <CancelModalButton
                                key="DEMBCN"
                                flat
                                onClick={() => handleModalChange('closeRoom', false)}
                            >
                                Cancel
                            </CancelModalButton>,
                        ]}
                    />
                </div>
            </FlexCenter>
        );
    }

    return (
        <React.Fragment>
            <ButtonWithImageContainer data-qa="open-room-moderator" onClick={handleOpenRoom}>
                <OpenRoomIcon />
                Open Room
            </ButtonWithImageContainer>
            <div className="eureka-react">
                <ConfirmationDialog
                    open={modalOpened.recording}
                    title="Open the room"
                    titleIcon="meeting_room"
                    iconColor="rgba(0, 0, 0, 0.87)"
                    iconWrapperColor="#EFEFEF"
                    withCloseButton
                    onClose={() => handleModalChange('recording', false)}
                    content={
                        <ExtraStyledModalContentContainer>
                            If you open the room, speakers can no longer pre-record. The button will
                            be disabled.
                        </ExtraStyledModalContentContainer>
                    }
                    buttons={[
                        <SaveModalButton key="SMBO" flat onClick={stopRecordings}>
                            Open
                        </SaveModalButton>,
                        <CancelModalButton
                            key="SMBOC"
                            flat
                            onClick={() => handleModalChange('recording', false)}
                        >
                            Cancel
                        </CancelModalButton>,
                    ]}
                />
            </div>
        </React.Fragment>
    );
};

export default FooterModerator;

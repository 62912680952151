import React, { useEffect, useState } from 'react';
import { AccessibleFakeButton, Avatar, FontIcon, IconSeparator } from 'react-md';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { navigateTo } from '../../../components/Navigation/NavigationLink';
import { getLocalAppStateAsync } from '../../../services/api/db';
import Auth from '../../../services/api/auth';
import styled from 'styled-components';
import * as palette from '../../../components/General/Variables';
import useMyModeratedSessions from '../../Society/hooks/useMyModeratedSessions';
import useEvents from '../../Society/hooks/useEvents';
import {
    displayDigitalBadge as displayDigitalBadgeAction,
    displayPlatformInfo as displayPlatformInfoAction,
    displayProfileModal as displayProfileModalAction,
    displaySettingsModal as displaySettingsModalAction,
    displaySignOutDialog as displaySignOutDialogAction,
    fetchProfileData as fetchProfileDataAction,
    hideDigitalBadge as hideDigitalBadgeAction,
    hidePlatformInfo as hidePlatformInfoAction,
} from '../actions';
import {
    loadAchievements as loadAchievementsAction,
    loadIsGamificationEnabled as loadIsGamificationEnabledAction,
} from '../../Achievements/actions';
import { default as Store, getString } from '../../../services/api/store';

import {
    getMyCheckinsCount,
    getMyFavoritesCount,
    getMyProgramCount,
    getNotesCount,
    isThereAtLeastOneTypeWithCheckinFeatureEnabled,
} from '../../../services/api/data';
import eventBus from '../../../utils/eventBus';
import { getUsersFriendsList } from '../../ExhibitorPage/api';
import PlatformInfoContent from './platformInfo/PlatformInfoContent';
import PlatformInfoTitle from './platformInfo/PlatformInfoTitle';
import { getIsGamificationEnabled, getTotalPoints } from '../../Achievements/selectors';
import useTimeTracking from '../../../hooks/useTimeTracking';
import useAchievementsListener from '../../Achievements/hooks/useAchievementsListener';
import { NewNotficationsBadge } from '../../../scenes/Notifications/components/NotificationButton';
import { useTheme } from '../../../components/Theme/ThemeContext';
import { getBgAndFontColorForAllDifferentStates } from '../../../components/General/Colors';
import DigitalBadgeTitle from './digitalBadge/DigitalBadgeTitle';
import DigitalBadgeContent from './digitalBadge/DigitalBadgeContent';
import {
    LeftIcon,
    RightIcon,
    StyledDropdownMenu,
    StyledIconSeparator,
    StyledListItem,
} from '../style/modalsStyle';
import { useSocket } from '../../../components/Session/SocketContext';

const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    margin: auto;
    margin-right: 8px;
    border-radius: 50%;
    div {
        padding: 0 !important;
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    @media (max-width: 767px) {
        margin-right: 0px;
    }
`;

const CustomAvatar = styled(Avatar)`
    background-color: #bfbfbf;
    border: unset !important;
    width: 32px !important;
    height: 32px !important;

    & > * {
        font-size: 16px !important;
        line-height: 32px;
    }

    @media only screen and (max-width: ${palette.MAX_TABLET}) {
        margin-top: -3px;
    }
`;

const DropdownAvatar = styled(Avatar)`
    background-color: #bfbfbf;
    border: 0px solid rgba(0, 0, 0);
    border-radius: 50% !important;
    margin: 0 16px;
    width: 56px !important;
    height: 56px !important;
`;

const CounterBadge = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: right;
    color: rgba(0, 0, 0, 0.54);
`;

const CloseButton = styled(FontIcon)`
    font-size: 24px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    cursor: pointer;

    @media only screen and (min-width: ${palette.MAX_PHONE}) {
        display: none;
    }
`;

const FlexRow = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
`;

const AuthenticatedUserButton = ({
    profileData,
    displayProfileModal,
    displaySettingsModal,
    displayPlatformInfo,
    hidePlatformInfo,
    displayDigitalBadge,
    hideDigitalBadge,
    displaySignOutDialog,
    fetchProfileData,
    match,
    location,
    showPlatformInfo,
    showDigitalBadge,
    light,
    loadIsGamificationEnabled,
    isGamificationEnabled,
    achievementPoints,
    loadAchievements,
    newVersionAvailable,
    updateServiceWorker,
    waitingWorker,
    isSociety,
}) => {
    const [visible, setVisible] = useState(false);
    const [bookmarksCount, setBookmarksCount] = useState(0);
    const [notesCount, setNotesCount] = useState(0);
    const [checkinsCount, setCheckinsCount] = useState(0);
    const [displayCheckin, setDisplayCheckin] = useState(false);
    const [friendsList, setFrientsList] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [eventId, setEventId] = useState('');

    const networkingEnabled = Store.argument && Store.argument.app.enableChat;

    const SUBTITLE_MY_PROFILE = getString('profileEditSubtitle') || 'Edit your public profile';
    const TITLE_SETTINGS = getString('sideMenuSettingsTitle') || 'Settings';
    const TITLE_ABOUT_THIS_PLATFORM = getString('aboutThisPlatformTitle', 'About this platform');
    const SUBTITLE_ABOUT_THIS_PLATFORM = getString(
        'aboutThisPlatformSubtitle',
        'Updates, Legal information, Release notes',
    );
    const SUBTITLE_SETTINGS =
        getString('profileSettingsSubtitle') || 'Change privacy and visibility settings';
    const TITLE_MY_BOOKMARKS = getString('listFilterFavoritesOption') || 'Favorites';
    const SUBTITLE_MY_BOOKMARKS =
        getString('profileBookmarksSubtitle') ||
        'View saved programme items, people, companies, etc.';
    const TITLE_MY_NOTES = getString('myNotes') || 'My Notes';
    const SUBTITLE_MY_NOTES = getString('myNotesDescription') || 'All notes you have added';
    const TITLE_MY_CONTACTS = getString('contactsWindowTitle') || 'My Contacts';
    const SUBTITLE_MY_CONTACTS = getString(
        'contactsWindowSubtitle',
        'View a list of all your contacts',
    );
    const TITLE_SIGN_OUT = getString('sideMenuSignOut') || 'Sign out';
    const TITLE_ACHIEVEMENTS = getString('achievementsTitle', 'Achievements');
    const SUBTITLE_ACHIEVEMENTS = getString(
        'achievementsSubtitle',
        'View your achievements and leaderboard',
    );
    const TITLE_CHECKINS = getString('myCheckinsTitle', 'My check ins');
    const SUBTITLE_CHECKINS = getString('myCheckinsDescription', 'Your check ins');
    const TITLE_DIGITAL_BADGE = getString('digitalBadge', 'Digital badge');

    const TITLE_MY_SESSIONS = getString('sessionsTitle', 'Sessions');
    const SUBTITLE_MY_SESSIONS = getString('sessionsSubtitle', 'Quick acces to your sessions');

    const { socket } = useSocket();
    const { theme } = useTheme();

    const { allSessions } = useMyModeratedSessions(eventId);
    const { events } = useEvents({});

    const history = useHistory();

    useTimeTracking();
    useAchievementsListener();

    const verifyCheckin = async () => {
        const atLeastOneCheckinEnabled = await isThereAtLeastOneTypeWithCheckinFeatureEnabled();
        setDisplayCheckin(atLeastOneCheckinEnabled);
    };

    useEffect(() => {
        (async () => {
            const { id } = await getLocalAppStateAsync();
            setEventId(id);
        })();
    }, [visible]);

    useEffect(() => {
        fetchProfileData();
        updateBookmarksCount();
        updateNotesCount();
        updateCheckinsCount();
        verifyCheckin();
        eventBus.on('updateMyFavoritesCount', updateBookmarksCount);
        eventBus.on('updateMyProgrammeCount', updateBookmarksCount);
        eventBus.on('updateNotesCount', updateNotesCount);
        eventBus.on('refreshMyCheckins', updateCheckinsCount);
    }, [isSociety]);

    useEffect(() => {
        if (socket && profileData && profileData.id) {
            socket.on(`profileChanged_${profileData.id}`, fetchProfileData);
        }

        return () => {
            if (socket && profileData && profileData.id) {
                socket.removeAllListeners(`profileChanged_${profileData.id}`, fetchProfileData);
            }
        };
    }, [profileData, socket]);

    useEffect(() => {
        (async () => {
            if (visible) {
                const contacts = await getUsersFriendsList();
                setFrientsList(contacts.friends || []);
            }

            if (!visible && showPlatformInfo) {
                hidePlatformInfo();
            }

            if (!visible && showDigitalBadge) {
                hideDigitalBadge();
            }
        })();
    }, [visible]);

    useEffect(() => {
        loadIsGamificationEnabled();
    }, [loadIsGamificationEnabled]);

    useEffect(() => {
        if (isGamificationEnabled && visible) {
            loadAchievements();
        }
    }, [isGamificationEnabled, loadAchievements, visible]);

    useEffect(() => {
        (async () => {
            let menu = [];
            if (showPlatformInfo) {
                menu = platformItems;
            } else if (showDigitalBadge) {
                menu = digitalBadgeItems;
            } else if (isSociety) {
                menu = [...commonItems, ...societyItems, ...logOutItems];
            } else {
                if (profileData.digitalBadge && !isSociety) {
                    commonItems.splice(1, 0, { divider: true });
                    commonItems.splice(2, 0, digitalBadgeItem);
                }

                if (isGamificationEnabled) {
                    eventItems.splice(2, 0, gamificationItem);
                }
                if (displayCheckin) {
                    eventItems.splice(3, 0, checkinItem);
                }
                if (allSessions && allSessions.length) {
                    societyItems.splice(0, 0, { divider: true });
                    societyItems.splice(1, 0, mySessionsItem);
                }
                menu = [...commonItems, ...eventItems, ...societyItems, ...logOutItems];
            }
            setMenuItems(menu);
        })();
    }, [
        isSociety,
        showPlatformInfo,
        isGamificationEnabled,
        displayCheckin,
        allSessions,
        showDigitalBadge,
        newVersionAvailable,
        bookmarksCount,
        notesCount,
        checkinsCount,
        friendsList.length,
        achievementPoints,
        profileData,
    ]);

    const onVisibilityChange = newVisible => setVisible(newVisible);

    const updateBookmarksCount = async () => {
        if (isSociety) {
            return;
        }
        const myFavoritesCount = await getMyFavoritesCount();
        const myProgramCount = await getMyProgramCount();

        setBookmarksCount(myFavoritesCount + myProgramCount);
    };

    const updateNotesCount = async () => {
        if (isSociety) {
            return;
        }
        const myNotesCount = await getNotesCount();
        setNotesCount(myNotesCount);
    };

    const updateCheckinsCount = async () => {
        const myCheckinsCount = await getMyCheckinsCount();
        setCheckinsCount(myCheckinsCount);
    };

    const getInitials = ({ firstName, lastName }) =>
        firstName[0].toUpperCase() + lastName[0].toUpperCase();

    if (!profileData) {
        profileData = Auth.getUser();
    }
    const initials = getInitials(profileData);

    const platformItems = [
        <PlatformInfoTitle key="platform-info-title" />,
        <PlatformInfoContent
            key="platform-info-content"
            updateAvailable={newVersionAvailable}
            updateServiceWorker={updateServiceWorker}
            waitingWorker={waitingWorker}
        />,
    ];

    const digitalBadgeItems = [
        <DigitalBadgeTitle key="digital-badge-title" />,
        <DigitalBadgeContent key="digital-badge-content" badge={profileData.digitalBadge} />,
    ];

    const commonItems = [
        <StyledListItem
            key="auth-dropdown-item-profile"
            primaryText={`${profileData.firstName} ${profileData.lastName}`}
            secondaryText={SUBTITLE_MY_PROFILE}
            leftAvatar={
                profileData.imageUrl ? (
                    <img src={profileData.imageUrl} alt="user" />
                ) : (
                    <DropdownAvatar>{initials}</DropdownAvatar>
                )
            }
            rightIcon={<CloseButton onClick={() => setVisible(false)}>close</CloseButton>}
            onClick={displayProfileModal}
            isTitlePrimaryText={true}
        />,
        { divider: true },
        <StyledListItem
            key="auth-dropdown-item-settings"
            primaryText={TITLE_SETTINGS}
            secondaryText={SUBTITLE_SETTINGS}
            leftIcon={<LeftIcon iconClassName="material-icons-outlined">settings</LeftIcon>}
            rightIcon={<RightIcon>arrow_forward</RightIcon>}
            onClick={displaySettingsModal}
        />,
        <StyledListItem
            key="auth-dropdown-about-this-platform"
            primaryText={TITLE_ABOUT_THIS_PLATFORM}
            secondaryText={SUBTITLE_ABOUT_THIS_PLATFORM}
            leftIcon={
                <FlexRow>
                    <LeftIcon iconClassName="material-icons-outlined">info</LeftIcon>
                    {newVersionAvailable && (
                        <NewNotficationsBadge
                            color={theme.contrast}
                            right={'10px'}
                            top={'-10px'}
                            border={'solid 2px #f5f5f5;'}
                        ></NewNotficationsBadge>
                    )}
                </FlexRow>
            }
            rightIcon={<RightIcon>arrow_forward</RightIcon>}
            onClick={e => {
                e.stopPropagation();
                displayPlatformInfo();
            }}
        />,
    ];

    const eventItems = [
        { divider: true },
        <StyledListItem
            key="auth-dropdown-item-bookmarks"
            primaryText={TITLE_MY_BOOKMARKS}
            secondaryText={SUBTITLE_MY_BOOKMARKS}
            rightIcon={<CounterBadge>{bookmarksCount}</CounterBadge>}
            leftIcon={<LeftIcon>bookmark_border</LeftIcon>}
            onClick={() =>
                navigateTo({
                    match,
                    history,
                    location,
                    path: '/mybookmarks',
                })
            }
        />,
        <StyledListItem
            key="auth-dropdown-item-notes"
            primaryText={TITLE_MY_NOTES}
            secondaryText={SUBTITLE_MY_NOTES}
            leftIcon={<LeftIcon iconClassName="material-icons-outlined">description</LeftIcon>}
            rightIcon={<CounterBadge>{notesCount}</CounterBadge>}
            onClick={() =>
                navigateTo({
                    match,
                    history,
                    location,
                    path: '/mynotes',
                })
            }
        />,
    ];

    if (networkingEnabled) {
        eventItems.push({ divider: true });
        eventItems.push(
            <StyledListItem
                key="auth-dropdown-item-contacts"
                primaryText={TITLE_MY_CONTACTS}
                secondaryText={SUBTITLE_MY_CONTACTS}
                leftIcon={<LeftIcon iconClassName="material-icons-outlined">people</LeftIcon>}
                rightIcon={<CounterBadge>{friendsList.length}</CounterBadge>}
                onClick={() =>
                    navigateTo({
                        match,
                        history,
                        location,
                        path: '/mycontacts',
                    })
                }
            />,
        );
    }

    const digitalBadgeItem = (
        <StyledListItem
            key="auth-dropdown-item-digital-badge"
            primaryText={TITLE_DIGITAL_BADGE}
            leftIcon={<FontIcon iconClassName="material-icons-outlined">badge</FontIcon>}
            rightIcon={<RightIcon>chevron_right</RightIcon>}
            onClick={e => {
                e.stopPropagation();
                displayDigitalBadge();
            }}
        />
    );

    const gamificationItem = (
        <StyledListItem
            key="auth-dropdown-item-achievements"
            primaryText={TITLE_ACHIEVEMENTS}
            secondaryText={SUBTITLE_ACHIEVEMENTS}
            rightIcon={<CounterBadge>{achievementPoints}</CounterBadge>}
            leftIcon={<LeftIcon iconClassName="material-icons-outlined">emoji_events</LeftIcon>}
            onClick={() =>
                navigateTo({
                    match,
                    history,
                    location,
                    path: '/achievements',
                })
            }
        />
    );

    const checkinItem = (
        <StyledListItem
            key="auth-dropdown-item-checkins"
            primaryText={TITLE_CHECKINS}
            secondaryText={SUBTITLE_CHECKINS}
            rightIcon={<CounterBadge>{checkinsCount}</CounterBadge>}
            leftIcon={<LeftIcon iconClassName="material-icons-outlined">beenhere</LeftIcon>}
            onClick={() =>
                navigateTo({
                    match,
                    history,
                    location,
                    path: '/checkins',
                })
            }
        />
    );

    const mySessionsItem = (
        <StyledListItem
            key="auth-dropdown-item-mysessions"
            primaryText={TITLE_MY_SESSIONS}
            secondaryText={SUBTITLE_MY_SESSIONS}
            rightIcon={<CounterBadge>{allSessions.length}</CounterBadge>}
            leftIcon={<LeftIcon iconClassName="material-icons-outlined">list</LeftIcon>}
            onClick={() =>
                navigateTo({
                    match,
                    history,
                    location,
                    path: '/mysessions',
                })
            }
        />
    );

    const societyItems =
        history.location.pathname !== '/'
            ? [
                  <StyledListItem
                      key="auth-dropdown-item-events"
                      primaryText="Events"
                      secondaryText="Access all your events"
                      rightIcon={
                          <>{events.length > 0 && <CounterBadge>{events.length}</CounterBadge>}</>
                      }
                      leftIcon={
                          <LeftIcon iconClassName="material-icons-outlined">dashboard</LeftIcon>
                      }
                      onClick={() =>
                          navigateTo({
                              match,
                              history,
                              location,
                              absolutePath: '/',
                          })
                      }
                  />,
              ]
            : [];

    const logOutItems = [
        { divider: true },
        <StyledListItem
            key="auth-dropdown-item-sign-out"
            primaryText={TITLE_SIGN_OUT}
            leftIcon={<FontIcon>exit_to_app</FontIcon>}
            onClick={displaySignOutDialog}
        />,
    ];

    const {
        contrastBlackOrWhite,
        defaultBg,
        hoverBg,
        activeBg,
        fontColor,
    } = getBgAndFontColorForAllDifferentStates(theme, light);

    return (
        <StyledDropdownMenu
            id="authenticated-user-dropdown-menu"
            menuItems={menuItems}
            animationPosition="below"
            sameWidth
            simplifiedMenu={false}
            onVisibilityChange={onVisibilityChange}
            light={light ? 'true' : undefined}
            width={showPlatformInfo ? '375px' : undefined}
            primary={theme.primary}
            disablePaddingBottom={showDigitalBadge}
            backgroundColor={defaultBg}
            hoverColor={hoverBg}
            activeColor={activeBg}
            fontColor={fontColor}
            contrastBlackOrWhite={light ? palette.BLACK_87 : contrastBlackOrWhite}
            isAuthenticated={true}
        >
            <AccessibleFakeButton
                component={IconSeparator}
                iconBefore
                label={
                    <StyledIconSeparator label={profileData.firstName}>
                        <FontIcon>arrow_drop_down</FontIcon>
                    </StyledIconSeparator>
                }
            >
                <ImgContainer>
                    {profileData.imageUrl ? (
                        <FlexRow>
                            <img src={profileData.imageUrl} alt="user" />
                            {newVersionAvailable && (
                                <NewNotficationsBadge color={theme.contrast} right={'-10px'}>
                                    1
                                </NewNotficationsBadge>
                            )}
                        </FlexRow>
                    ) : (
                        <FlexRow>
                            <CustomAvatar>{initials}</CustomAvatar>
                            {newVersionAvailable && (
                                <NewNotficationsBadge color={theme.contrast} right={'-10px'}>
                                    1
                                </NewNotficationsBadge>
                            )}
                        </FlexRow>
                    )}
                </ImgContainer>
            </AccessibleFakeButton>
        </StyledDropdownMenu>
    );
};

const mapStateToProps = (state, ownProps) => ({
    profileData: state.auth.profileData,
    showPlatformInfo: state.auth.showPlatformInfo,
    showDigitalBadge: state.auth.showDigitalBadge,
    isGamificationEnabled: getIsGamificationEnabled(state),
    achievementPoints: getTotalPoints(state),
    isSociety: ownProps.isSociety,
});

export default connect(mapStateToProps, {
    displayProfileModal: displayProfileModalAction,
    displaySettingsModal: displaySettingsModalAction,
    displayPlatformInfo: displayPlatformInfoAction,
    hidePlatformInfo: hidePlatformInfoAction,
    displayDigitalBadge: displayDigitalBadgeAction,
    hideDigitalBadge: hideDigitalBadgeAction,
    displaySignOutDialog: displaySignOutDialogAction,
    fetchProfileData: fetchProfileDataAction,
    loadIsGamificationEnabled: loadIsGamificationEnabledAction,
    loadAchievements: loadAchievementsAction,
})(withRouter(AuthenticatedUserButton));

import React from 'react';

const LinkedinIcon = () => (
    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.25 0H3.75A3.75 3.75 0 0 0 0 3.75v10.5A3.75 3.75 0 0 0 3.75 18h10.5A3.75 3.75 0 0 0 18 14.25V3.75A3.75 3.75 0 0 0 14.25 0ZM6 14.25H3.75V6H6v8.25ZM4.875 5.049a1.318 1.318 0 0 1-1.313-1.323c0-.73.589-1.323 1.313-1.323s1.313.592 1.313 1.323c0 .73-.588 1.323-1.313 1.323ZM15 14.25h-2.25v-4.203c0-2.526-3-2.335-3 0v4.203H7.5V6h2.25v1.324C10.797 5.384 15 5.24 15 9.18v5.069Z"
            fill="#B3B3B3"
            fillRule="nonzero"
        ></path>
    </svg>
);

export default LinkedinIcon;

import React from 'react';
import { useSelector } from 'react-redux';

import TotalPoints from './TotalPoints';
import { getTotalPoints } from '../selectors';

const MyScore = ({ onClick }) => {
    const totalPoints = useSelector(getTotalPoints);

    return <TotalPoints points={totalPoints} tooltip onClick={onClick} />;
};

export default MyScore;

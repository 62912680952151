import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEvents, getIsLoadingEvents, getEventsError } from '../selectors';
import { loadEvents } from '../actions';

const useEvents = ({ filter }) => {
    const events = useSelector(getEvents);
    const isLoading = useSelector(getIsLoadingEvents);
    const error = useSelector(getEventsError);
    const dispatch = useDispatch();

    const eventsAcc = events.reduce(
        (acc, event) => {
            let endDate = new Date(event.end).getTime();
            const currentDate = Date.now();

            if (isNaN(endDate)) {
                endDate = new Date(Number(event.end)).getTime();
            }

            if (event.uniqueName) {
                if (endDate < currentDate) {
                    acc.pastEvents.push(event);
                } else {
                    acc.upcomingEvents.push(event);
                }
            }

            return acc;
        },
        {
            pastEvents: [],
            upcomingEvents: [],
        },
    );
    const [eventsState, setEventsState] = useState(eventsAcc);

    useEffect(() => {
        dispatch(loadEvents());
    }, [dispatch]);

    useEffect(() => {
        const filteredPastEvents = eventsAcc.pastEvents.filter(
            pastEvent => pastEvent.name && pastEvent.name.toLowerCase().includes(filter),
        );
        const filteredUpcomingEvents = eventsAcc.upcomingEvents.filter(
            pastEvent => pastEvent.name && pastEvent.name.toLowerCase().includes(filter),
        );

        setEventsState({
            ...eventsState,
            pastEvents: filteredPastEvents,
            upcomingEvents: filteredUpcomingEvents,
        });
    }, [filter, events]);

    const getEventByUniqueName = uniqueName => {
        return events.find(event => event.uniqueName === uniqueName);
    };

    return {
        events,
        pastEvents: eventsState.pastEvents,
        upcomingEvents: eventsState.upcomingEvents,
        isLoading,
        error,
        getEventByUniqueName,
    };
};

export default useEvents;

import React from 'react';
import findIndex from 'lodash/findIndex';
import { Tab, Tabs, TabsContainer } from 'react-md';
import { withRouter } from 'react-router-dom';
import NavigationLink from '../../components/Navigation/NavigationLink';
import styled from 'styled-components';
import Loader from '../../components/General/Loader';
import ThemeContext from '../../components/Theme/ThemeContext';
import Floorplan from './components/floorplan';
import FloorplanList from './components/list';
import { getFloorplan, getPlaceById } from './services/floorplan';
import InfoCard from './components/BoothInfoCard';
import PlaceScheduleList from './components/PlaceScheduleList';
import {
    TabElement,
    TabsContainerStyled,
    TabsWrapper,
    ToolbarContainer,
} from '../Programme/style/style';

const StyledLink = styled(NavigationLink)`
    font-weight: 500;
`;

class FloorplanPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showDrawer: false,
            items: [],
            selectedTab: 0,
            loading: true,
            sections: [],
            visible: false,
            currentActivePlace: null,
            currentActivePlaceIsBooth: false,
        };
        this.props.setTitle();
    }

    load = props => {
        getFloorplan(props.pageId, async (err, result) => {
            if (result && result.page && result.floorplans && result.floorplans.length) {
                this.setState({
                    items: result.floorplans,
                    defaultTab: result.page.params.initialItem,
                    sections: result.page.params.sections,
                    loading: false,
                });
                await this.loadPlace(props);
                if (!props.match.params.floorplan) {
                    if (
                        result.floorplans.length &&
                        result.floorplans[result.page.params.initialItem]
                    ) {
                        return props.history.push(
                            `${props.location.pathname}/${
                                result.floorplans[result.page.params.initialItem].id
                            }${props.match.params.place ? `/${props.match.params.place}` : ''}`,
                        );
                    }
                }
            }
        });
    };

    async loadPlace(props) {
        const place = await getPlaceById(props.match.params.place);

        if (!place) {
            this.setState({
                currentActivePlace: null,
                currentActivePlaceIsBooth: false,
            });
            return;
        }

        const type = this.state.sections.find(section => section.typeId === place.type);
        const isBooth = type && type.title.toLowerCase().includes('booth');

        this.setState({
            currentActivePlace: place,
            currentActivePlaceIsBooth: isBooth,
        });
    }

    componentDidMount() {
        this.load(this.props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.place !== this.props.match.params.place) {
            this.loadPlace(this.props);
        }
    }

    openDrawerRight = e => {
        this.setState({
            showDrawer: true,
        });
    };

    onDrawerClose = e => {
        this.setState({
            showDrawer: false,
        });
    };

    onActiveFloorplan = id => {
        const index = findIndex(this.state.items, item => {
            return id === item.id;
        });
        this.setState({ selectedTab: index });
    };

    render() {
        const { currentActivePlace, currentActivePlaceIsBooth, selectedTab } = this.state;

        return (
            <React.Fragment>
                {this.state.loading && <Loader />}
                {!this.state.loading && (
                    <React.Fragment>
                        <ThemeContext.Consumer>
                            {({ theme }) => (
                                <ToolbarContainer>
                                    <TabsContainerStyled
                                        defaultTabIndex={0}
                                        activeTabIndex={this.state.selectedTab}
                                        panelClassName="md-grid"
                                        colored
                                        onTabChange={() => {}}
                                    >
                                        <TabsWrapper
                                            background={theme.primary}
                                            tabId="floorplan"
                                            mobile
                                            alignToKeyline={false}
                                        >
                                            {this.state.items.map((tab, i) => (
                                                <TabElement
                                                    key={i}
                                                    component={StyledLink}
                                                    pathReplaceParams={{
                                                        floorplan: tab.id,
                                                        place: null,
                                                    }}
                                                    label={tab.name}
                                                    primary={theme.primary}
                                                    active={selectedTab === i}
                                                    onClick={() =>
                                                        this.setState({ selectedTab: i })
                                                    }
                                                />
                                            ))}
                                        </TabsWrapper>
                                    </TabsContainerStyled>
                                </ToolbarContainer>
                            )}
                        </ThemeContext.Consumer>
                        {this.props.match.params.floorplan && (
                            <>
                                <Floorplan
                                    {...this.props}
                                    searchButtonAction={this.openDrawerRight}
                                    onActiveFloorplan={this.onActiveFloorplan}
                                />
                                {this.state.showDrawer && (
                                    <FloorplanList
                                        sections={this.state.sections}
                                        visible={this.state.showDrawer}
                                        onClose={this.onDrawerClose}
                                    />
                                )}
                                {currentActivePlace && currentActivePlaceIsBooth && (
                                    <InfoCard item={currentActivePlace} />
                                )}
                                {currentActivePlace && !currentActivePlaceIsBooth && (
                                    <PlaceScheduleList place={currentActivePlace} />
                                )}
                            </>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default withRouter(FloorplanPage);

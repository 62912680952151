import orderBy from 'lodash/orderBy';
import React from 'react';
import { executeQuery } from '../../services/api/graphQlRepository';
import Expanded from './Expanded';

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            selectedFilters:
                this.props.selectedFilters && this.props.selectedFilters.filters
                    ? this.props.selectedFilters.filters
                    : {},
            filterText:
                this.props.selectedFilters && this.props.selectedFilters.text
                    ? this.props.selectedFilters.text
                    : '',
            myProgram:
                this.props.selectedFilters && this.props.selectedFilters.myProgram
                    ? this.props.selectedFilters.myProgram
                    : 'all',
        };
        //initialize filter
        this.props.action({
            myProgram: this.state.myProgram,
            filters: this.state.selectedFilters,
            text: this.state.filterText,
        });
    }

    async load() {
        let filters = [];

        if (this.props.filterTypes && this.props.filterTypes.length) {
            let classifiersa = await executeQuery('getClassifiersWithType', {
                type: this.props.filterTypes[0].type,
            });
            classifiersa = orderBy(classifiersa, 'name', 'asc');

            filters.push({
                id: this.props.filterTypes[0].type,
                name: this.props.filterTypes[0].name,
                options: classifiersa,
            });

            if (this.props.filterTypes.length > 1) {
                let classifiersb = await executeQuery('getClassifiersWithType', {
                    type: this.props.filterTypes[1].type,
                });
                classifiersb = orderBy(classifiersb, 'name', 'asc');
                filters.push({
                    id: this.props.filterTypes[1].type,
                    name: this.props.filterTypes[1].name,
                    options: classifiersb,
                });
            }

            if (this.props.filterTypes.length > 2) {
                let classifiersc = await executeQuery('getClassifiersWithType', {
                    type: this.props.filterTypes[2].type,
                });
                classifiersc = orderBy(classifiersc, 'name', 'asc');

                filters.push({
                    id: this.props.filterTypes[2].type,
                    name: this.props.filterTypes[2].name,
                    options: classifiersc,
                });
            }
            this.setState({ filters: filters });
        }
    }

    componentDidMount() {
        this.load();
    }

    favoriteFilter = myProgram => {
        this.setState({
            myProgram: myProgram,
        });
        this.props.action({
            myProgram: myProgram,
            filters: this.state.selectedFilters,
            text: this.state.filterText,
        });
    };
    actionFilters = filters => {
        this.setState({
            myProgram: filters.myProgram,
            selectedFilters: filters.filters,
            filterText: filters.text,
        });
        this.props.action({
            myProgram: filters.myProgram,
            filters: filters.filters,
            text: filters.text,
        });
    };

    render() {
        return (
            <Expanded
                filters={this.state.filters}
                selectedFilters={this.state.selectedFilters}
                filterText={this.state.filterText}
                action={this.actionFilters}
                closeFilter={this.props.closeFilter}
                myProgram={this.state.myProgram}
            />
        );
    }
}

export default Filter;

import * as StyledComponent from '../../style/modalsStyle';
import { getString } from '../../../../services/api/store';
import React from 'react';
import * as constants from '../../constants';
import * as palette from '../../../../components/General/Variables';
import * as string from '../../../SignInPage/constants/strings';

export const PersonalInformation = ({
    fieldValues,
    onChange,
    countries,
    onSelectChange,
    afterSignUp,
    importedFields,
}) => {
    const PERSONAL_INFORMATION_TITLE =
        getString('personalInfoSubtitle') || constants.PERSONAL_INFORMATION;
    const isImportedField = field => importedFields && importedFields.indexOf(field) !== -1;

    const firstNameIsExternal = isImportedField('firstName');
    const lastNameIsExternal = isImportedField('lastName');
    const titleIsExternal = isImportedField('title');
    const profileJobIsExternal = isImportedField('jobTitle');
    const headlineIsExternal = isImportedField('headline');
    const companyNameIsExternal = isImportedField('companyName');
    const countryIsExternal = isImportedField('CountryId');

    return (
        <StyledComponent.FormWrapper>
            <StyledComponent.SectionText noMarginTop={true} noMarginLeft={true}>
                {PERSONAL_INFORMATION_TITLE}
            </StyledComponent.SectionText>
            <StyledComponent.FlexContainerWithTwoChildren flex1={1} flex2={3}>
                <StyledComponent.StyledTextField
                    id="profile-title"
                    name="title"
                    label={
                        <div>
                            {getString('titlesHintText') || 'Title(s)'}
                            {titleIsExternal && (
                                <StyledComponent.LabelIcon>
                                    {palette.ICON_CLOUD_DOWNLOAD}
                                </StyledComponent.LabelIcon>
                            )}
                        </div>
                    }
                    value={fieldValues.title}
                    onChange={onChange}
                    afterSignUp={afterSignUp}
                    disabled={titleIsExternal}
                />
                <StyledComponent.StyledTextField
                    id="profile-firstName"
                    name="firstName"
                    label={
                        <div>
                            {getString('firstNameHintText') || 'First name'}
                            {firstNameIsExternal && (
                                <StyledComponent.LabelIcon>
                                    {palette.ICON_CLOUD_DOWNLOAD}
                                </StyledComponent.LabelIcon>
                            )}
                        </div>
                    }
                    value={fieldValues.firstName}
                    onChange={onChange}
                    required
                    error={!fieldValues.firstName || fieldValues.firstName.length === 0}
                    errorText="First name is required."
                    afterSignUp={afterSignUp}
                    disabled={firstNameIsExternal}
                />
            </StyledComponent.FlexContainerWithTwoChildren>
            <StyledComponent.StyledTextField
                id="profile-lastName"
                name="lastName"
                label={
                    <div>
                        {getString('lastNameHintText') || 'Last name'}
                        {lastNameIsExternal && (
                            <StyledComponent.LabelIcon>
                                {palette.ICON_CLOUD_DOWNLOAD}
                            </StyledComponent.LabelIcon>
                        )}
                    </div>
                }
                value={fieldValues.lastName}
                onChange={onChange}
                required
                error={!fieldValues.lastName || fieldValues.lastName.length === 0}
                errorText="Last name is required."
                afterSignUp={afterSignUp}
                disabled={lastNameIsExternal}
            />
            <StyledComponent.StyledTextField
                id="profile-companyName"
                name="companyName"
                label={
                    <div>
                        {getString('profileCompany') || string.COMPANY_LABEL}
                        {companyNameIsExternal && (
                            <StyledComponent.LabelIcon>
                                {palette.ICON_CLOUD_DOWNLOAD}
                            </StyledComponent.LabelIcon>
                        )}
                    </div>
                }
                value={fieldValues.companyName}
                onChange={onChange}
                afterSignUp={afterSignUp}
                disabled={companyNameIsExternal}
            />
            <StyledComponent.StyledTextField
                id="profile-jobTitle"
                name="jobTitle"
                label={
                    <div>
                        {getString('profileJobTitle') || 'Job title'}
                        {profileJobIsExternal && (
                            <StyledComponent.LabelIcon>
                                {palette.ICON_CLOUD_DOWNLOAD}
                            </StyledComponent.LabelIcon>
                        )}
                    </div>
                }
                value={fieldValues.jobTitle}
                onChange={onChange}
                afterSignUp={afterSignUp}
                disabled={profileJobIsExternal}
            />
            <StyledComponent.StyledTextField
                id="profile-headline"
                name="headline"
                label={
                    <div>
                        {getString('profileHeadline') || 'Bio'}
                        {headlineIsExternal && (
                            <StyledComponent.LabelIcon>
                                {palette.ICON_CLOUD_DOWNLOAD}
                            </StyledComponent.LabelIcon>
                        )}
                    </div>
                }
                value={fieldValues.headline}
                onChange={onChange}
                rows={1}
                maxRows={5}
                afterSignUp={afterSignUp}
                disabled={headlineIsExternal}
                fixedHeight={112}
            />
            <StyledComponent.StyledSelectField
                id="profile-country"
                name="country"
                label={
                    <div>
                        {getString('profileCountry') || 'Country'}
                        {countryIsExternal && (
                            <StyledComponent.LabelIcon>
                                {palette.ICON_CLOUD_DOWNLOAD}
                            </StyledComponent.LabelIcon>
                        )}
                    </div>
                }
                menuItems={countries}
                value={fieldValues.country}
                onChange={onSelectChange}
                fullWidth
                afterSignUp={afterSignUp}
                disabled={countryIsExternal}
                position="below"
            />
        </StyledComponent.FormWrapper>
    );
};

import React, { useEffect, useState } from 'react';
import each from 'async/each';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import 'moment/min/locales.min';
import { Divider, List } from 'react-md';
import NavigationLink from '../../components/Navigation/NavigationLink';
import styled from 'styled-components';
import Loader from '../../components/General/Loader';
import ClassifierIcon from '../../components/Icons/ClassifierIcon';
import ObjectListItem from '../../components/ObjectListItem';
import ThemeContext from '../../components/Theme/ThemeContext';
import { getAllProgramFavoritesForEvent } from '../../services/api/data';
import { getObjectClassWithId } from '../../services/api/db';
import { getItem } from '../../services/api/graphQlRepository';
import Placeholder from '../SearchPage/components/placeholder';
import { getString } from '../../services/api/store';

const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;
`;

const ListStyled = styled(List)`
    width: 100%;
    max-width: ${props => window.innerWidth}px;
`;

const SectionTitle = styled.div`
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin: 16px;
    font-family: 'Cabin', sans-serif;
`;

const ListItem = ({ item, type }) => (
    <span>
        <ObjectListItem item={item} type={type} />
        <Divider />
    </span>
);

const MyFavoritePage = props => {
    const items = [];
    const types = {};
    const [loading, setLoading] = useState(true);
    const [itemsByType, setItemsByType] = useState({
        results: {},
        types: {},
    });

    useEffect(() => {
        getAllProgramFavoritesForEvent((err, favorites) => {
            setLoading(false);

            each(
                favorites,
                (favorite, callback) => {
                    getObjectClassWithId(favorite.id, (err, objectClass, objectItem) => {
                        if (err) {
                            console.log(err);
                            return callback();
                        }

                        getItem('types', objectItem.type, (err, type) => {
                            if (type.target !== 'Timeslot' || !objectItem.start) {
                                objectItem.type = type.id;
                                types[type.id] = type;
                                items.push(objectItem);
                            }

                            callback();
                        });
                    });
                },
                () => {
                    setItemsByType({
                        types,
                        results: groupBy(sortBy(items, ['orderingName', 'name']), 'type'),
                    });
                },
            );
        });
    }, []);

    const { results } = itemsByType;
    const listItems = Object.keys(results).map(typeId => {
        const type = itemsByType['types'][typeId];
        const items = results[typeId];
        const typeTarget = type.target.toLowerCase();
        const typePlural = type.plural.toLowerCase();

        const list = items.map(item => {
            let params = item.params;
            if (typeof item.params === 'string') {
                params = JSON.parse(item.params);
            }
            let classifierIcon = '';
            if (params && params.icons && params.icons.length) {
                params.icons.forEach((item, idx) => {
                    classifierIcon = <ClassifierIcon key={item} imageId={item} />;
                });
            }

            return (
                <LinkStyled
                    key={'link_' + item.id}
                    params={{
                        type: 'detail',
                        objectClass: typeTarget,
                        objectId: item.id,
                    }}
                    icon={classifierIcon}
                >
                    <ListItem item={item} type={typeTarget} />
                </LinkStyled>
            );
        });

        return (
            <span key={`list_item_${typeId}`}>
                <SectionTitle>{typePlural}</SectionTitle>
                {list}
            </span>
        );
    });

    const content = () => {
        if (!Object.keys(itemsByType['types']).length > 0 && !loading) {
            return (
                <Placeholder
                    type="emptyList"
                    introText={getString('personalNoFavoritesTitle') || 'Nothing saved yet...'}
                />
            );
        } else {
            return <ListStyled>{listItems}</ListStyled>;
        }
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    {loading && <Loader />}
                    {!loading && content()}
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default MyFavoritePage;

import get from 'lodash/get';

import { createRequestTypes } from '../../../utils/types';
import { getFullProfile, getUserSettings, syncExternalProfile } from '../../../services/api/eureka';
import Auth from '../../../services/api/auth';

export const SET_SHOW_PROFILE_MODAL = 'SET_SHOW_PROFILE_MODAL';
export const SET_SHOW_SETTINGS_MODAL = 'SET_SHOW_SETTINGS_MODAL';
export const SET_SHOW_PLATFORM_INFO = 'SET_SHOW_PLATFORM_INFO';
export const SET_SHOW_DIGITAL_BADGE = 'SET_SHOW_DIGITAL_BADGE';
export const SET_SHOW_SIGN_OUT_DIALOG = 'SET_SHOW_SIGN_OUT_DIALOG';
export const FETCH_PROFILE_DATA = createRequestTypes('FETCH_PROFILE_DATA');

export const displayProfileModal = () => dispatch => {
    dispatch({
        type: SET_SHOW_PROFILE_MODAL,
        payload: { value: true },
    });
};

export const hideProfileModal = () => dispatch => {
    dispatch({
        type: SET_SHOW_PROFILE_MODAL,
        payload: { value: false },
    });
};

export const displaySettingsModal = () => dispatch => {
    dispatch({
        type: SET_SHOW_SETTINGS_MODAL,
        payload: { value: true },
    });
};

export const hideSettingsModal = () => dispatch => {
    dispatch({
        type: SET_SHOW_SETTINGS_MODAL,
        payload: { value: false },
    });
};

export const displayPlatformInfo = () => dispatch => {
    dispatch({
        type: SET_SHOW_PLATFORM_INFO,
        payload: { value: true },
    });
};

export const hidePlatformInfo = () => dispatch => {
    dispatch({
        type: SET_SHOW_PLATFORM_INFO,
        payload: { value: false },
    });
};

export const displayDigitalBadge = () => dispatch => {
    dispatch({
        type: SET_SHOW_DIGITAL_BADGE,
        payload: { value: true },
    });
};

export const hideDigitalBadge = () => dispatch => {
    dispatch({
        type: SET_SHOW_DIGITAL_BADGE,
        payload: { value: false },
    });
};

export const displaySignOutDialog = () => dispatch => {
    dispatch({
        type: SET_SHOW_SIGN_OUT_DIALOG,
        payload: { value: true },
    });
};

export const hideSignOutDialog = () => dispatch => {
    dispatch({
        type: SET_SHOW_SIGN_OUT_DIALOG,
        payload: { value: false },
    });
};

export const fetchProfileData = () => async dispatch => {
    dispatch({ type: FETCH_PROFILE_DATA.REQUEST });

    try {
        Auth.getAuthSettings(async (err, config) => {
            const externalEurekaLogin = get(config, 'externalEurekaLogin');
            const profileSync = get(config, 'externalLoginOptions.profileSync');
            const externalProfileSync = get(config, 'externalProfileSync');
            let organizationToSync = null;

            if (profileSync && externalEurekaLogin) {
                organizationToSync = externalEurekaLogin;
            } else if (externalProfileSync) {
                organizationToSync = externalProfileSync;
            }

            if (organizationToSync) {
                await syncExternalProfile(organizationToSync);
            }
            const profileData = await getFullProfile();
            const userSettings = await getUserSettings(config.eventId);

            Object.assign(profileData, userSettings);
            dispatch({ type: FETCH_PROFILE_DATA.SUCCESS, payload: { profileData } });
        });
    } catch (_) {
        dispatch({ type: FETCH_PROFILE_DATA.FAILURE });
    }
};

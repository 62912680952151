import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import Tooltip from '@material-ui/core/Tooltip';
import MicOffIcon from '@material-ui/icons/MicOff';
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { withStyles } from '@material-ui/core/styles/index';

import StreamPlayer from '../common/StreamPlayer';
import entities from '../../constants/entities';
import VoiceIndicator from '../common/VoiceIndicator';
import { ShadowOverlay } from '../moderator/common/styles';
import { useVMState, useVMVolumeState } from '../../containers/main';

const ProfileImageWrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.6);
    position: relative;
    width: 100%;
    ${props => props.nofixedaspectratio && 'height: 100%'};
    ${props => !props.nofixedaspectratio && 'aspect-ratio: 16 / 9'};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
`;

export const VirtualUserInfoWrapper = styled.div`
    display: flex;
    align-items: start;
    height: fit-content;
    position: absolute;
    left: 10px;
    bottom: 8px;
    width: 85%;
    z-index: 2;
`;

export const VirtualUserTitle = styled.span`
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0 0 0 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ActionButtonsWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.87);
    border-radius: 20px;
`;

const ActionIconButtonWrapper = styled.div`
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.87);
    color: #fff;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    transition: all 0.1s linear;
    cursor: pointer;

    :hover {
        background-color: rgba(255, 255, 255, 0.15);
    }
`;

const AccountCircleIconWrapper = styled.div`
    font-size: 56px;
    color: rgba(255, 255, 255, 0.15);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${props => props.imageUrl}');
    width: 56px;
    height: 56px;
    border-radius: 50%;
    ${props =>
        props.video &&
        css`
            opacity: 1;
            height: 100%;
            width: 100%;
            .stream-player {
                height: 100%;
                width: 100%;
            }
        `}
`;

const StyledMicOffIcon = styled(MicOffIcon)`
    color: #ffffff;
    width: 16px !important;
    height: 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const IconsWrapper = styled.div`
    position: absolute;
    bottom: 0 !important;
    left: 0 !important;
    height: 20px;
    width: 85%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const LightTooltip = withStyles(() => ({
    tooltip: {
        borderRadius: '4px',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '10px',
        lineHeight: '1.2',
        textAlign: 'center',
        color: '#ffffff',
    },
}))(Tooltip);

export default ({ virtualEventUserData, onRemove, onTurnMicOff, index, noFixedAspectRatio }) => {
    const stateCtx = useVMState();
    const volumeStateCtx = useVMVolumeState();
    const { currentUser, localStream } = stateCtx;
    const { volumeIndicators } = volumeStateCtx;
    const { isMicrophoneOn, User: user, isVideoOn } = virtualEventUserData;
    const [showActionButtons, setShowActionButtons] = useState(false);
    const { streams } = stateCtx;

    const isCurrentUser = useMemo(() => {
        return currentUser && user.id === currentUser.id;
    }, [get(currentUser, 'id'), get(user, 'id')]);

    const streamToUse = useMemo(() => {
        const streamId = get(user, 'id');

        if (isCurrentUser) {
            return localStream;
        }

        return streams.find(st => st.streamId === streamId);
    }, [isCurrentUser, stateCtx, localStream, streams]);

    const isHost =
        virtualEventUserData.role === entities.virtualEventUserEntity.role.roundTableHost;
    const removalTooltipMessage = isHost ? 'Cannot remove Host' : 'Remove this person';
    const muteTooltipMessage = isMicrophoneOn
        ? 'Mute this person'
        : 'Only the participant can unmute himself';

    const volumeLevel = useMemo(() => {
        const indicator = volumeIndicators.find(
            vInd => `${get(streamToUse, 'streamId')}` === `${vInd.uid}`,
        );

        return indicator ? indicator.level : 0;
    }, [volumeIndicators, streamToUse]);

    return (
        <ProfileImageWrapper
            onMouseEnter={() => !isHost && !showActionButtons && setShowActionButtons(true)}
            onMouseLeave={() => !isHost && showActionButtons && setShowActionButtons(false)}
            nofixedaspectratio={noFixedAspectRatio ? 1 : 0}
        >
            <AccountCircleIconWrapper video={isVideoOn} imageUrl={user.imageUrl}>
                {!user.imageUrl && !isVideoOn && <AccountCircleIcon fontSize="inherit" />}
                {streamToUse && (
                    <StreamPlayer
                        key={`${streamToUse.getId()}-${index}`}
                        className={'main-stream-profile'}
                        isVideoOn={isVideoOn}
                        local={true}
                        muted={currentUser.id === streamToUse.getId()}
                        stream={streamToUse}
                        uid={streamToUse.getId()}
                        domId={`stream-player-${streamToUse.getId()}`}
                        noPositionRelative
                    >
                        <VirtualUserInfoWrapper>
                            <IconsWrapper>
                                {isMicrophoneOn && <VoiceIndicator level={volumeLevel} small />}
                                {!isMicrophoneOn && <StyledMicOffIcon />}
                                <VirtualUserTitle>
                                    {user.firstName} {user.lastName} {isHost ? '(Host)' : ''}
                                </VirtualUserTitle>
                            </IconsWrapper>
                        </VirtualUserInfoWrapper>
                        <ShadowOverlay />
                    </StreamPlayer>
                )}
                {!streamToUse && (
                    <>
                        <VirtualUserInfoWrapper>
                            <IconsWrapper>
                                {isMicrophoneOn && <VoiceIndicator level={volumeLevel} small />}
                                {!isMicrophoneOn && <StyledMicOffIcon />}
                                <VirtualUserTitle>
                                    {user.firstName} {user.lastName} {isHost ? '(Host)' : ''}
                                </VirtualUserTitle>
                            </IconsWrapper>
                        </VirtualUserInfoWrapper>
                        <ShadowOverlay />
                    </>
                )}
            </AccountCircleIconWrapper>
            {showActionButtons && (
                <ActionButtonsWrapper>
                    <LightTooltip title={muteTooltipMessage}>
                        <ActionIconButtonWrapper onClick={() => isMicrophoneOn && onTurnMicOff()}>
                            <MicOffOutlinedIcon fontSize="inherit" />
                        </ActionIconButtonWrapper>
                    </LightTooltip>
                    <LightTooltip title={removalTooltipMessage}>
                        <ActionIconButtonWrapper onClick={onRemove} disabled={isHost}>
                            <RemoveCircleOutlineIcon fontSize="inherit" />
                        </ActionIconButtonWrapper>
                    </LightTooltip>
                </ActionButtonsWrapper>
            )}
        </ProfileImageWrapper>
    );
};

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import HowToVoteIcon from '@mui/icons-material/HowToVoteOutlined';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

import { votesColorScheme } from './constants';
import VoteResult from './VoteResult';
import ProgressBar from './ProgressBar';
import EnlargedImageModal from '../common/EnlargedImageModal';
import { useGlobalState } from '../../../../utils/container';

const VotingResultsWrapper = styled.div`
    background-color: #ffffff;
    z-index: 1;
    display: flex;
    flex: 1;
    flex-direction: row;
`;

const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    height: 100%;
    margin: 16px 70px 32px 0;
    ${props =>
        props.isbeamerview &&
        css`
            font-size: 30px;
            font-weight: bold;
            line-height: 34px;
            color: rgba(0, 0, 0, 0.87);
        `}
`;

const Img = styled.img`
    height: 100%;
    z-index: 1000;
    border-radius: 8px;
    margin: 0 0 16px 0;
    object-fit: contain;
`;

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    height: 118px;
    align-items: center;
    justify-content: flex-start;
    margin-right: 16px;
    overflow: hidden;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 16px 0;
`;

const FlexColumn = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 24px;
    margin-right: 40px;
`;

const GridContainer = styled.div`
    display: grid;
    width: 100%;
`;

const TotalVotesRecordingContainer = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    font-family: Roboto, sans-serif;
    font-size: ${props => (props.isbeamerview ? 28 : 24)}px;
    font-weight: ${props => (props.isbeamerview ? 700 : 500)};
    letter-spacing: 0.86px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ImageWrapper = styled.div`
    position: relative;
    height: 100%;
`;

const ZoomIconContainer = styled.div`
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.54);
    color: #fff;
    height: 32px;
    width: 40px;
    border-radius: 6px;
    z-index: 1000;
`;

const VotingResults = ({
    activePoll,
    pollSet,
    totalVotes,
    optionsWithVotes,
    showVoteResults,
    currentPollAnswer,
    isBeamerView,
}) => {
    const stateCtx = useGlobalState();
    const { virtualEventSession } = stateCtx;
    const hideVotesNumber = virtualEventSession?.hideVotesNumber || false;
    const { title, imageUrl } = activePoll;
    const isCorrectOptionSet = !!optionsWithVotes.find(option => option.correct);
    const [isImageHovered, setIsImageHovered] = useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);

    return (
        <VotingResultsWrapper>
            {!showVoteResults && (
                <ProgressBar
                    pollSet={pollSet}
                    activePoll={activePoll}
                    activeColor={'#1fa294'}
                    isBeamerView={isBeamerView}
                />
            )}
            {!hideVotesNumber && (
                <TotalVotesRecordingContainer isbeamerview={isBeamerView ? 1 : 0}>
                    {totalVotes}
                    <HowToVoteIcon style={isBeamerView ? { fontSize: 32, marginLeft: 8 } : {}} />
                </TotalVotesRecordingContainer>
            )}
            <FlexRow>
                {imageUrl && (
                    <ImageContainer
                        onMouseEnter={() => {
                            setIsImageHovered(true);
                        }}
                        onMouseLeave={() => {
                            setIsImageHovered(false);
                        }}
                        onClick={() => {
                            setIsImageModalOpen(true);
                        }}
                    >
                        <ImageWrapper>
                            <Img src={imageUrl} />
                            {isImageHovered && (
                                <ZoomIconContainer>
                                    <ZoomOutMapIcon />
                                </ZoomIconContainer>
                            )}
                        </ImageWrapper>
                    </ImageContainer>
                )}
            </FlexRow>
            <FlexColumn>
                <Title isbeamerview={isBeamerView ? 1 : 0}>{title}</Title>
                <GridContainer
                    style={{
                        gridTemplateColumns: 'minmax(0, 1fr)  '.repeat(1),
                        gridAutoRows: '1fr',
                        gridGap: optionsWithVotes.length > 4 ? 8 : 16,
                    }}
                >
                    {optionsWithVotes.map((option, index) => (
                        <VoteResult
                            key={`vote-result-${index}`}
                            label={String.fromCharCode(65 + index)}
                            option={option}
                            totalVotes={totalVotes}
                            color={votesColorScheme[index]}
                            showCorrectAnswer={showVoteResults}
                            currentPollAnswer={currentPollAnswer}
                            isCorrectOptionSet={isCorrectOptionSet}
                            isBeamerView={isBeamerView}
                            numberOfOptions={optionsWithVotes.length}
                            hideVotesNumber={hideVotesNumber}
                        />
                    ))}
                </GridContainer>
            </FlexColumn>
            <EnlargedImageModal
                open={isImageModalOpen}
                handleClose={() => {
                    setIsImageModalOpen(false);
                }}
                imageUrl={imageUrl}
            />
        </VotingResultsWrapper>
    );
};

export default VotingResults;

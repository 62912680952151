import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import PriorityHighOutlinedIcon from '@material-ui/icons/PriorityHighOutlined';
import { useDropzone } from 'react-dropzone';

import Slides from './Slides';
import { useVMMutation, useVMState } from '../../../containers/main';
import Votes from '../../../../Votes/containers/Votes';
import StreamPlayer from '../../common/StreamPlayer';
import useResize from '../../../../../../VirtualSession/hooks/useResize';
import entities from '../../../constants/entities';
import MediaPlayer from '../../common/MediaPlayer';
import useOnDropPresentationUrlSlider from '../../../hooks/useOnDropPresentationUrlSlider';
import { FIFTY_MILL, TEN_MILL, THIRTY_MILL, TWENTY_MILL } from '../../../constants/values';
import SlidePlaceholder from '../../../../../../../assets/images/slide-placeholder.png';
import { getImageSource } from '../../../../../../../services/api/db';
import Store from '../../../../../../../services/api/store';
import { BeautifulLoader } from '../../common/BeautifulLoader';
import usePollSet from '../../../../Votes/hooks/usePollSet';
import usePresentingStartTime from '../../../../Votes/hooks/usePresentingStartTime';

const Wrapper = styled.div`
    flex: 2;
    overflow: scroll;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 16px;
`;

const MediaFrameWrapper = styled.div`
    flex: 1;
    display: flex;
`;

const TimerValue = styled.div`
    // position
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // end position

    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    height: 80px;
    text-align: center;
    vertical-align: center;
    width: 80px;
    line-height: 80px;
    z-index: 1;

    span {
        font-family: Roboto, sans-serif;
        font-size: 40px;
        color: #fff;
    }
`;

export const InfoButton = styled.div`
    color: #1fa294;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    text-decoration: underline;

    ${props =>
        props.deleteBtn &&
        css`
            margin: 0 10px 0 0;
        `}
`;

const ScreenSharingWrapper = styled.div`
    display: flex;
    flex: 1;

    ${props =>
        props.noStream &&
        css`
            background: #666;
            border-radius: 8px;
        `}

    .stream-player.video-on {
        height: 100%;
        width: 100%;

        video {
            width: 100% !important;
            height: 100% !important;
            object-fit: contain !important;
        }
    }
`;

const WarningIcon = styled(PriorityHighOutlinedIcon)`
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    border: 2px solid #d50000;
    margin-right: 8px;
`;

const ErrorMessage = styled.div`
    width: 100%;
    padding: 10px 0;
    opacity: 0.87;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #d50000;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const Img = styled.img`
    height: 100%;
    width: 100%;
`;

export const WideRedStyledChip = styled.div`
    width: 182px;
    text-transform: uppercase;
    margin: 0 !important;
    height: 16px;
    padding: 0;
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 10;
    color: #fff !important;
    background-color: #d50000;
    border-radius: 8px;

    p {
        text-align: center;
        font-family: Cabin, sans-serif;
        line-height: 1.2 !important;
        font-size: 10px !important;
        font-weight: bold !important;
        letter-spacing: 0.5px !important;
        margin: 2px !important;
    }
`;

const maxFileSize = 25 * 1048576;

function LeftContent() {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();

    const {
        activePoll,
        screenSharing,
        shareType,
        screenShareStream,
        streams,
        virtualEventUser,
        virtualEventSession,
        sessionId,
        speakerViewMode,
        timer,
        videoClient,
        isLoading,
        eventSliderPlaceholder,
    } = stateCtx;

    const { isActive } = virtualEventUser.data;
    const pollSetValue = useRef(null);
    const isScreenSharing = screenShareStream && shareType === 'screen';
    const forceRenderCanvas = !!activePoll || virtualEventSession.data.showVoteResults;
    const shouldRenderCanvas = !isScreenSharing || forceRenderCanvas;
    const isBroadcasting =
        virtualEventSession.data.status === entities.virtualEventSessionEntity.status.broadcasting;

    const shouldRenderScreenShare = isScreenSharing && !shouldRenderCanvas;

    const activeVideoId = get(virtualEventSession, 'data.activeVideoId', null);
    const videoUrls = get(virtualEventSession, 'data.videoUrls') || [];
    const virtualSessionsSlideHolder = Store.argument && Store.argument.virtualSessionsSlideHolder;
    const [resizeRef] = useResize(null, false, shouldRenderScreenShare);
    const [showActions, setShowActions] = useState(false);

    const { fileRejections } = useDropzone({
        accept: 'application/pdf, application/vnd.ms-powerpoint',
        maxSize: maxFileSize,
        onDrop: useOnDropPresentationUrlSlider(),
    });
    const { pollSet, changeFromPollsActiveRef } = usePollSet();
    const { getPresentingTime } = usePresentingStartTime();

    let isFileTooLarge =
        fileRejections && fileRejections.length > 0 && fileRejections[0].errors.length > 0;

    const intervalRef = useRef(0);
    const [timerValue, setTimerValue] = useState(-2);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const placeHolderUrl =
        virtualEventSession.data.presentationPlaceholderUrl ||
        eventSliderPlaceholder ||
        SlidePlaceholder;

    useEffect(() => {
        (async () => {
            if (virtualSessionsSlideHolder) {
                await getImageSource(virtualSessionsSlideHolder, (err, img) => {
                    mutationCtx.setEventSliderPlaceHolder(img);
                });
            }
        })();
    }, []);

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        if (!timer) {
            setTimerValue(-2);
            return;
        }

        setTimerValue(timer.time);
        intervalRef.current = setInterval(() => {
            setTimerValue(val => val - 1);
        }, 1000);

        return () => clearInterval(intervalRef.current);
    }, [timer]);

    useEffect(() => {
        if (timer && timerValue === -1) {
            const { callback } = timer;

            callback();
            mutationCtx.setTimer(null);
        }
    }, [timer, timerValue]);

    useEffect(() => {
        const presentingTimerInterval = setInterval(() => {
            if (isActive && isBroadcasting) {
                setCurrentTime(Date.now());
            }
        }, 1000);

        return () => {
            clearInterval(presentingTimerInterval);
        };
    }, [isActive, isBroadcasting]);

    const prerecordingMode = speakerViewMode === entities.speakerViewMode.prerecording;
    const preRecordedPresentationUrl = get(virtualEventUser, 'data.preRecordedPresentationUrl');
    const hasPreRecording = !!preRecordedPresentationUrl;
    const recordingInfo = get(virtualEventUser, 'data.recordingInfo');
    const hasUploadedVideo = !!recordingInfo;
    const uploadedVideoUrl = get(virtualEventUser, 'data.recordingInfo.shareUrl', '');
    const shouldRenderPlayer =
        prerecordingMode && (hasPreRecording || (hasUploadedVideo && uploadedVideoUrl));
    const activeUser = get(virtualEventSession, 'data.VirtualEventUsers', []).find(
        a => a.hasVideoActive || a.isActive,
    );
    const isAnyPollActive = !!get(pollSetValue, 'current.Polls', []).find(poll => poll.active);
    const playAudioOnlyFromCamera = get(activeUser, 'recordingInfo.playAudioOnlyFromCamera', false);

    const streamToUse = useMemo(() => {
        if (speakerViewMode === entities.speakerViewMode.audience) {
            const activeVideoId = get(virtualEventSession, 'data.activeVideoId', null);
            const questionModeratorId = get(virtualEventSession, 'data.questionModeratorId');
            const activeVideo = videoUrls.find(video => video.id === activeVideoId);
            const activeUserIsPreRecording = get(activeUser, 'isPrerecording');

            if (activeUserIsPreRecording) {
                return null;
            }

            let streamId = get(activeUser, 'UserId', 0) + TEN_MILL;

            if (get(activeUser, 'hasVideoActive')) {
                streamId = get(activeUser, 'UserId', 0) + THIRTY_MILL;
            } else if (activeVideoId) {
                streamId = virtualEventSession.data.id + FIFTY_MILL;
            }

            let foundStream = streams.find(st => st.streamId === streamId);

            if (activeVideo && activeVideo.isRTMP) {
                foundStream = streams.find(st => st.streamId === activeVideo.uid);
            }

            if (questionModeratorId) {
                foundStream = streams.find(st => st.streamId === questionModeratorId + TEN_MILL);
            }
            if (foundStream && videoClient) {
                // videoClient.subscribe(foundStream);

                return foundStream;
            }
        }

        return null;
    }, [speakerViewMode, streams, virtualEventSession, videoClient]);

    let muted = playAudioOnlyFromCamera;
    if (activeVideoId) {
        const video = videoUrls.find(video => video.id === activeVideoId);

        // If there is no uploaded video for the speaker, unmute the slides video
        if (video && !video.videoUrl) {
            muted = false;
        }
    }

    const streamId = get(streamToUse, 'streamId');

    // make sure the screensharing is unmuted
    if (streamId >= TEN_MILL && streamId <= TWENTY_MILL) {
        muted = false;
    }

    if (shouldRenderPlayer) {
        const list = get(virtualEventUser, 'data.recordingInfo.list', []);
        let videoUrl;
        if (list.length) {
            const shareInfo = list.find(info => info.type === 'share');
            videoUrl = `${preRecordedPresentationUrl}/share/${shareInfo.sid}_${sessionId}.m3u8`;
        } else {
            const recordingInfoName = recordingInfo.name;
            const recordingFromVideoUrls = videoUrls.find(
                video => video.name === recordingInfoName,
            );
            const videoFullyEncoded =
                recordingFromVideoUrls &&
                (recordingFromVideoUrls.encoding === 'complete' ||
                    recordingFromVideoUrls.shareEncoding === 'complete');
            videoUrl = videoFullyEncoded ? recordingFromVideoUrls.shareUrl : uploadedVideoUrl;
        }

        return (
            <Wrapper>
                <MediaPlayer
                    primary
                    muted={playAudioOnlyFromCamera}
                    url={videoUrl}
                    data-qa="pre-recording-video-feed"
                />
            </Wrapper>
        );
    }

    if (speakerViewMode === entities.speakerViewMode.audience) {
        const showPlaceHolder = !streamToUse;

        return (
            <Wrapper>
                <ScreenSharingWrapper
                    innerRef={resizeRef}
                    noStream={!streamToUse}
                    ispollsharing={isAnyPollActive ? 1 : 0}
                >
                    {streamToUse && (
                        <StreamPlayer
                            key={streamToUse.getId()}
                            className={'main-stream-profile'}
                            showProfile={stateCtx.profile}
                            isVideoOn={true}
                            muted={muted}
                            stream={streamToUse}
                            uid={streamToUse.getId()}
                            domId={`stream-player-${streamToUse.getId()}`}
                        />
                    )}
                    {showPlaceHolder && <Img alt="preview" src={placeHolderUrl} />}
                </ScreenSharingWrapper>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <MediaFrameWrapper
                onMouseEnter={() => setShowActions(true)}
                onMouseLeave={() => setShowActions(false)}
            >
                {shouldRenderScreenShare && (
                    <React.Fragment>
                        <ScreenSharingWrapper innerRef={resizeRef}>
                            {timerValue >= 0 && (
                                <TimerValue data-qa="pre-recording-timer-countdown">
                                    <span>{timerValue}</span>
                                </TimerValue>
                            )}
                            {isBroadcasting && isActive && (
                                <WideRedStyledChip>
                                    <p>{`You are presenting - ${getPresentingTime()}`}</p>
                                </WideRedStyledChip>
                            )}
                            <BeautifulLoader active={isLoading}>
                                <StreamPlayer
                                    key={screenShareStream.getId()}
                                    className={'main-stream-profile'}
                                    showProfile={stateCtx.profile}
                                    isVideoOn={screenSharing}
                                    local={true}
                                    muted
                                    stream={screenShareStream}
                                    uid={screenShareStream.getId()}
                                    domId={`stream-player-${screenShareStream.getId()}`}
                                />
                            </BeautifulLoader>
                        </ScreenSharingWrapper>
                    </React.Fragment>
                )}

                {shouldRenderCanvas && (
                    <Slides
                        showActions={showActions}
                        forceRender={forceRenderCanvas}
                        pollSet={pollSet}
                        changeFromPollsActiveRef={changeFromPollsActiveRef}
                    >
                        {timerValue >= 0 && (
                            <TimerValue data-qa="pre-recording-timer-countdown">
                                <span>{timerValue}</span>
                            </TimerValue>
                        )}
                        {isBroadcasting && isActive && !activePoll && (
                            <WideRedStyledChip>
                                <p>{`You are presenting - ${getPresentingTime()}`}</p>
                            </WideRedStyledChip>
                        )}
                    </Slides>
                )}
            </MediaFrameWrapper>
            {isFileTooLarge && (
                <ErrorMessage>
                    <WarningIcon />
                    <div>
                        Make sure you upload a file that is smaller than &nbsp;<b>25MB</b>
                    </div>
                </ErrorMessage>
            )}
            {speakerViewMode === entities.speakerViewMode.live && (
                <Votes pollSet={pollSet} changeFromPollsActiveRef={changeFromPollsActiveRef} />
            )}
        </Wrapper>
    );
}

LeftContent.propTypes = {};

export default LeftContent;

import entities from '../constants/entities';

export const sortVirtualEventUsersByLastName = virtualEventUsers =>
    (virtualEventUsers || []).sort((left, right) => {
        const left1stLetter = left.User.lastName[0].toLowerCase();
        const right1stLetter = right.User.lastName[0].toLowerCase();
        if (left1stLetter < right1stLetter) {
            return -1;
        }

        if (left1stLetter > right1stLetter) {
            return 1;
        }
        return 0;
    });

export const orderVirtualEventUsers = (roomType, virtualEventUsers) => {
    const virtualEventUsersAcc = virtualEventUsers.reduce(
        (acc, virtualEventUser) => {
            if (!acc[virtualEventUser.role]) {
                acc[virtualEventUser.role] = [];
            }
            acc[virtualEventUser.role].push(virtualEventUser);
            return acc;
        },
        {
            [entities.virtualEventUserEntity.role.presenter]: [],
            [entities.virtualEventUserEntity.role.moderator]: [],
            [entities.virtualEventUserEntity.role.roundTableHost]: [],
            [entities.virtualEventUserEntity.role.roundTableParticipant]: [],
        },
    );
    if (roomType === entities.virtualEventSessionEntity.roomType.moderated) {
        return [
            ...sortVirtualEventUsersByLastName(virtualEventUsersAcc.moderator),
            ...sortVirtualEventUsersByLastName(virtualEventUsersAcc.presenter),
        ];
    }
    if (roomType === entities.virtualEventSessionEntity.roomType.roundTable) {
        return [
            ...sortVirtualEventUsersByLastName(virtualEventUsersAcc.roundTableHost),
            ...sortVirtualEventUsersByLastName(virtualEventUsersAcc.roundTableParticipant),
        ];
    }
    return [];
};

export const parseVirtualEventSession = virtualEventSession => {
    return {
        ...virtualEventSession,
        VirtualEventUsers: orderVirtualEventUsers(
            virtualEventSession.roomType,
            virtualEventSession.VirtualEventUsers,
        ),
    };
};

export default {
    orderVirtualEventUsers,
    parseVirtualEventSession,
    sortVirtualEventUsersByLastName,
};

import AgoraRTC from 'agora-rtc-sdk-ng';
import EventEmitter from 'events';

import Stream from './Stream';

AgoraRTC.setParameter('SUBSCRIBE_TCC', false);

console.log(
    'agora sdk version: ' + AgoraRTC.VERSION + ' compatible: ' + AgoraRTC.checkSystemRequirements(),
);

const customEvents = [
    'localStream-added',
    'localStream-removed',
    'screenShare-canceled',
    'stopScreenSharing',
    'clientJoined',
    'showProxyButton',
    'longJoin',
    'updateClient',
    'clientIsHost',
    'stream-published',
    'stream-unpublished',
    'streamDestroyed',
];

export default class RTCClient {
    constructor() {
        this._client = null;
        this._joined = false;
        this._isHost = false;
        this._localStream = null;
        // this._enableBeauty = false;
        this._params = {};
        this._published = false;
        this._publishing = false;
        this._uid = 0;
        this._eventBus = new EventEmitter();
        this._showProfile = false;
        this._subscribed = false;
        this._shareType = '';
        this._created = false;
        this._leaving = false;
        this._dualStreamEnabled = false;
        this._destroyingStream = false;
        this._role = 'audience';
    }

    resetPublishState() {
        this._published = false;
        this._unpublishing = false;
        this._publishing = false;
    }

    getMode() {
        if (!this._client) {
            return '';
        }

        return this._client._mode;
    }

    createClient(data) {
        this._client = AgoraRTC.createClient({
            mode: data.mode,
            codec: data.codec,
        });

        if (data.mode === 'rtc') {
            this._role = 'host';
        }

        return this._client;
    }

    closeStream() {
        if (!this._localStream) {
            return;
        }

        if (this._localStream.isPlaying()) {
            this._localStream.stop();
        }
        this._localStream.close();
    }

    destroyStream(noRoleChange) {
        if (this._destroyingStream) {
            return Promise.resolve();
        }

        this._destroyingStream = true;
        return new Promise(async resolve => {
            const continueDestroying = async () => {
                await this.unpublish();

                if (!noRoleChange) {
                    await this.setClientRole('audience');
                }

                this.closeStream();
                this._localStream = null;
                this._destroyingStream = false;
                this._isHost = false;
                this._eventBus.emit('localStream-removed');
                this._eventBus.emit('updateClient');

                resolve();
            };

            if (this._publishing) {
                this.once('stream-published', continueDestroying);
            } else if (this._unpublishing) {
                this.once('stream-unpublished', continueDestroying);
            } else {
                await continueDestroying();
            }
        });
    }

    destroy() {
        this._created = false;
        this._client = null;
    }

    on(evt, callback) {
        if (customEvents.indexOf(evt) !== -1) {
            this._eventBus.on(evt, callback);
            return;
        }

        this._client.on(evt, callback);
    }

    off(evt, callback) {
        if (customEvents.indexOf(evt) !== -1) {
            this._eventBus.removeListener(evt, callback);
            return;
        }

        this._client.off(evt, callback);
    }

    once(evt, callback) {
        if (customEvents.indexOf(evt) !== -1) {
            this._eventBus.once(evt, callback);
            return;
        }

        const handleFn = (...params) => {
            callback.apply(this, [...params]);
            this._client.off(evt, handleFn);
        };

        this._client.on(evt, handleFn);
    }

    removeAllListeners(evt) {
        if (customEvents.indexOf(evt) !== -1) {
            this._eventBus.removeAllListeners(evt);
            return;
        }

        this._client.off(evt);
    }

    async setClientRole(role) {
        if (this.getMode() === 'rtc') {
            return;
        }

        try {
            await this._client.setClientRole(role);
            this._role = role;
        } catch (err) {
            //
        }
    }

    setLowStreamParameter(param) {
        if (!this._client) {
            return;
        }

        this._client.setLowStreamParameter(param);
    }

    addInjectStreamUrl(url, config) {
        return new Promise((resolve, reject) => {
            this.once('injected-stream', () => {
                resolve(true);
            });

            this._client.addInjectStreamUrl(url, config);
        });
    }

    removeInjectStreamUrl(url) {
        return new Promise((resolve, reject) => {
            this.once('remove-injected-stream', () => {
                resolve(true);
            });

            this._client.removeInjectStreamUrl(url);
        });
    }

    async createRTCStream(data) {
        this._shareType = 'pending';
        this._uid = this._localStream ? this._localStream.getId() : data.uid;

        if (this._localStream) {
            await this.unpublish();
            await this.closeStream();
            this._localStream = null;
        }

        const canvasStream = data.canvas && data.canvas.captureStream(30);
        const videoSource = canvasStream && canvasStream.getVideoTracks()[0];

        if (canvasStream && !videoSource) {
            throw new Error('no proper canvas provided');
        }

        try {
            let audioTrack;
            let videoTrack;

            if (canvasStream) {
                videoTrack = await AgoraRTC.createCustomVideoTrack({
                    mediaStreamTrack: videoSource,
                    optimizationMode: 'detail',
                    bitrateMin: 600,
                });
            } else {
                if (data.microphoneId) {
                    audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
                        microphoneId: data.microphoneId,
                        encoderConfig: data.audioProfile,
                    });
                }

                if (data.cameraId) {
                    videoTrack = await AgoraRTC.createCameraVideoTrack({
                        encoderConfig: data.resolution,
                        cameraId: data.cameraId,
                    });
                }
            }

            const rtcStream = new Stream({
                video: videoTrack,
                audio: audioTrack,
                uid: this._uid,
            });

            this._shareType = data.canvas ? 'canvas' : '';
            this._canvas = data.canvas;

            rtcStream.type = data.type;

            this._localStream = rtcStream;
            this._eventBus.emit('localStream-added', {
                stream: this._localStream,
            });

            if (data.muteVideo === false) {
                this._localStream.audio.muteVideo();
            }

            if (data.muteAudio === false) {
                this._localStream.muteAudio();
            }
        } catch (err) {
            this._localStream = null;
            this._shareType = '';
            console.error('init local stream failed ', err);
        }

        return !!this._localStream;
    }

    async createScreenSharingStream(data) {
        this._shareType = 'pending';
        // create screen sharing stream
        this._uid = this._localStream ? this._localStream.getId() : data.uid;

        if (this._localStream) {
            this._uid = this._localStream.getId();
        }

        try {
            const result = await AgoraRTC.createScreenVideoTrack(
                {
                    encoderConfig: '720p_2',
                },
                'auto',
            );

            let videoTrack = result;
            let audioTrack;

            if (result.length > 1) {
                videoTrack = result[0];
                audioTrack = result[1];
            }

            const screenSharingStream = new Stream({
                video: videoTrack,
                audio: audioTrack,
                uid: this._uid,
            });

            screenSharingStream.on('track-ended', async evt => {
                this._eventBus.emit('stopScreenSharing', evt);
            });

            this._shareType = 'screen';
            await this.unpublish();

            this.closeStream();
            this._localStream = screenSharingStream;

            // run callback
            this._eventBus.emit('localStream-added', {
                stream: this._localStream,
            });
        } catch (err) {
            if (this._localStream) {
                await this.unpublish();
                this._localStream = null;
            }

            this._eventBus.emit('stopScreenSharing', err);
            this._shareType = '';
        }
    }

    reSubscribe(stream, param) {
        this.unsubscribe(stream);
        this.subscribe(stream, param);
    }

    subscribe(user, type) {
        if (!this._client) {
            return;
        }

        return this._client.subscribe(user, type);
    }

    unsubscribe(user, type) {
        if (!this._client) {
            return;
        }

        return this._client.unsubscribe(user, type);
    }

    setStreamFallbackOption(stream, type) {
        if (!this._client) {
            return;
        }

        return this._client.setStreamFallbackOption(stream.streamId, type);
    }

    async enableDualStream() {
        if (!this._dualStreamEnabled) {
            await this._client.enableDualStream();
            this._dualStreamEnabled = true;
        }
    }

    async setRemoteVideoStreamType(stream, streamType) {
        if (!this._client) {
            return;
        }

        await this.enableDualStream();

        return this._client.setRemoteVideoStreamType(stream.streamId, streamType);
    }

    makeHost(props) {
        if (this._makingHost) {
            return Promise.resolve();
        }

        this._makingHost = true;

        return new Promise(async (resolve, reject) => {
            let screenSharing;
            let canvasSharing;

            if (props) {
                screenSharing = props.screenSharing;
                canvasSharing = props.canvasSharing;
            }

            const handleMakeHost = () => {
                this._params = {
                    ...this._params,
                    ...props,
                };
                this._isHost = 'pending';
                this._canvas = undefined;

                const createStreamFn = screenSharing
                    ? this.createScreenSharingStream
                    : this.createRTCStream;

                createStreamFn
                    .apply(this, [
                        {
                            ...this._params,
                            canvas: canvasSharing,
                        },
                    ])
                    .then(async () => {
                        this._makingHost = false;

                        if (this._localStream) {
                            await this.setRemoteVideoStreamType(this._localStream, 0);
                            this._isHost = true;
                            this._eventBus.emit('clientIsHost');
                            this._eventBus.emit('updateClient');
                            resolve(this._params.uid);
                        } else {
                            reject();
                            this._shareType = '';
                            this._isHost = false;
                        }
                    })
                    .catch(err => {
                        reject(err);
                        this._shareType = '';
                        this._isHost = false;
                        this._makingHost = false;
                    });
            };

            if (this._destroyingStream || this._publishing || this._unpublishing) {
                this._makingHost = false;
                return resolve();
            } else {
                handleMakeHost();
            }
        });
    }

    attemptJoin(data, timeoutMs) {
        return new Promise(resolve => {
            const timeout = setTimeout(() => {
                resolve(null);
            }, timeoutMs);

            this._client
                .join(
                    data.appID,
                    data.channel,
                    data.token ? data.token : null,
                    data.uid ? +data.uid : null,
                )
                .then(uid => {
                    resolve(uid);
                })
                .catch(err => {
                    resolve(null);
                })
                .finally(() => clearTimeout(timeout));
        });
    }

    joinWithProxyHandle(data) {
        let attempts = 1;
        const threshold = 10000;

        const handlePromise = async (resolve, reject) => {
            if (attempts <= 2) {
                const uid = await this.attemptJoin(data, attempts * threshold);

                if (uid === null) {
                    await this._client.leave();

                    attempts += 1;
                    setTimeout(() => handlePromise(resolve, reject), 100);
                } else {
                    resolve(uid);
                }
            } else {
                resolve(null);
            }
        };

        return new Promise(handlePromise);
    }

    join(data) {
        this._joined = 'pending';
        this._params = data;

        return new Promise(async (resolve, reject) => {
            try {
                const uid = await this.joinWithProxyHandle(data);

                if (uid === null) {
                    return;
                }

                this._uid = uid;
                console.log('join channel: ' + data.channel + ' success, uid: ' + uid);
                this._joined = true;
                this._eventBus.emit('clientJoined');

                data.uid = uid;

                this.setLowStreamParameter({ bitrate: 50, framerate: 15, height: 90, width: 160 });

                if (data.host) {
                    this.makeHost({ screenSharing: data.screenSharing, canvasSharing: data.canvas })
                        .then(() => {
                            resolve(data.uid);
                        })
                        .catch(err => {
                            reject(err);
                        });
                } else {
                    resolve(data.uid);
                }
            } catch (err) {
                this._joined = false;
                console.error('client join failed', err);
                reject(err);
            }
        });
    }

    async publish() {
        if (this._leaving) {
            this.resetPublishState();
            return true;
        }

        if (this._role !== 'host') {
            await this.setClientRole('host');
        }

        if (
            !this._client ||
            this._published ||
            this._publishing ||
            this._unpublishing ||
            this._role !== 'host' ||
            this._destroyingStream
        ) {
            return true;
        }

        this._publishing = true;

        if (!this._localStream) {
            this.resetPublishState();
            return true;
        }

        try {
            const enabledTracks = this._localStream.getEnabledTracks();

            if (enabledTracks.length) {
                setTimeout(() => {
                    this._publishing = false;
                    this._eventBus.emit('updateClient');
                }, 3000);

                await this._client.publish(enabledTracks);
                this._published = true;
            }

            this._publishing = false;

            if (this._published) {
                this._eventBus.emit('stream-published', {
                    stream: this._localStream,
                });
            }
        } catch (err) {
            console.error(err);
            this.resetPublishState();
        }

        if (this._published) {
            return true;
        }
    }

    async publishTrack(type) {
        const trackToPublish = this._localStream
            .getTracks()
            .find(track => track.trackMediaType === type);

        if (trackToPublish) {
            await this._client.publish([trackToPublish]);
        }
    }

    async unpublish() {
        if (this._leaving) {
            this.resetPublishState();
            return;
        }

        if (!this._client || !this._published || this._unpublishing || this._publishing) {
            return;
        }

        this._unpublishing = true;

        if (!this._localStream) {
            this.resetPublishState();
            return true;
        }

        try {
            const promises = this._localStream
                .getTracks()
                .map(track => this._client.unpublish(track));

            await Promise.all(promises);

            this._published = false;
            this._unpublishing = false;

            this._eventBus.emit('stream-unpublished', {
                stream: this._localStream,
            });
        } catch (err) {
            console.error(err);
            this.resetPublishState();
        }

        if (!this._published) {
            return true;
        }
    }

    async leave(destroyStream) {
        if (this._leaving === true || this._joined === false) {
            return Promise.resolve();
        }

        this._leaving = true;

        if (!this._client) {
            return Promise.resolve();
        }

        if (destroyStream) {
            await this.destroyStream();
        }

        try {
            // leave channel
            await this._client.leave();
            this._joined = false;
            this._client.stopProxyServer();

            if (!destroyStream) {
                this.destroy();
            }

            this._eventBus.emit('updateClient');
            this._leaving = false;
            return Promise.resolve();
        } catch (err) {
            this._leaving = false;
            return Promise.resolve();
        }
    }
}

import React, { useEffect, useState } from 'react';
import AuthUserContext from '../../../components/Session/AuthUserContext';
import ThemeContext from '../../../components/Theme/ThemeContext';
import QuestionsAndAnswers from './QuestionsAndAnswers';
import EvaluationCard from './Evaluation';
import VoteCard from './Vote';
import { withRouter } from 'react-router-dom';
import { makeItem } from '../../../services/api/data';
import RatingCard from './rating/Rating';
import { GroupChatCard } from '../../Talk/containers/GroupChatCard';
import { getLocalAppState } from '../../../services/api';
import {
    getVirtualEventPollSetsByEventAndItemIds,
    getVirtualEventSession,
} from '../../../services/api/eureka';
import { useGlobalState } from '../../../utils/container';
import get from 'lodash/get';
import { default as Store } from '../../../services/api/store';

const InteractivityBar = props => {
    const [askActive, setAskActive] = useState(false);
    const [virtualSession, setVirtualSession] = useState(null);
    const [ratingActive, setRatingActive] = useState(false);
    const [evaluateActive, setEvaluateActive] = useState(false);
    const [chatActive, setChatActive] = useState(false);
    const [voteActive, setVoteActive] = useState(false);
    const [localAppState, setLocalAppState] = useState(null);
    const { objectId, objectClass } = props;
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const networkingEnabled = Store.argument && Store.argument.app.enableChat;

    useEffect(() => {
        makeItem(objectId, objectClass, (err, objectItem) => {
            getLocalAppState(async (err, localAppState) => {
                if (err || !localAppState) {
                    return;
                }
                setLocalAppState(localAppState);
                if (objectItem && objectItem.interactivity && objectItem.interactivity.length > 0) {
                    const ask = objectItem.interactivity.find(item => item.id === 'questions');
                    const rating = objectItem.interactivity.find(item => item.id === 'rating');
                    const evaluate = objectItem.interactivity.find(
                        obj => obj.kind === 'evaluations',
                    );
                    const vote = objectItem.interactivity.find(item => item.id === 'polling');
                    const chat = objectItem.interactivity.find(item => item.id === 'chat');

                    setEvaluateActive(!!evaluate);
                    setRatingActive(!!rating);
                    setChatActive(!!chat && networkingEnabled);
                    setVoteActive(!!vote);

                    const virtualEventSession = await getVirtualEventSession(objectId);
                    setVirtualSession(virtualEventSession);

                    if (ask) {
                        setAskActive(ask.filled);
                        socket.on(`updateData_${objectId}`, updateData);
                    } else {
                        setAskActive(false);
                    }
                } else {
                    // Set to default state
                    setAskActive(false);
                    setRatingActive(false);
                    setEvaluateActive(false);
                    setChatActive(false);
                    setVoteActive(false);
                }
            });
        });
    }, [objectId]);

    const updateData = async data => {
        const { virtualEventSession } = data;

        setVirtualSession(virtualEventSession);
    };

    return (
        <AuthUserContext.Consumer>
            {({ authUser }) => (
                <ThemeContext.Consumer>
                    {({ theme }) => (
                        <>
                            {voteActive && (
                                <VoteCard
                                    theme={theme}
                                    localAppState={localAppState}
                                    objectId={objectId}
                                    objectClass={objectClass}
                                />
                            )}
                            {askActive && (
                                <QuestionsAndAnswers
                                    authUser={authUser}
                                    goToLoginPage={props.goToLoginPage}
                                    theme={theme}
                                    localAppState={localAppState}
                                    virtualSession={virtualSession} //virtualSession.showQA
                                    objectClass={objectClass}
                                    objectId={objectId}
                                />
                            )}
                            {evaluateActive && (
                                <EvaluationCard
                                    theme={theme}
                                    localAppState={localAppState}
                                    setShowEvaluationScreenExternal={
                                        props.setShowEvaluationScreenExternal
                                    }
                                    setEvaluationScreen={props.setEvaluationScreen}
                                    objectClass={objectClass}
                                    objectId={objectId}
                                    goToLoginPage={props.goToLoginPage}
                                />
                            )}
                            {ratingActive && (
                                <RatingCard
                                    theme={theme}
                                    objectClass={objectClass}
                                    objectId={objectId}
                                    goToLoginPage={props.goToLoginPage}
                                />
                            )}
                            {chatActive && <GroupChatCard itemId={objectId} />}
                        </>
                    )}
                </ThemeContext.Consumer>
            )}
        </AuthUserContext.Consumer>
    );
};

export default withRouter(InteractivityBar);

import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import styled from 'styled-components';

import BasicDialog from '../../../common/Dialog/BasicDialog';
import { getCroppedImg } from '../../../../utils/createCroppedImage';

const ImageWrapper = styled.div`
    flex: 7;
    width: 336px;
    height: 320px;
    position: relative;
    border: solid 1px #efefef;
    margin: 24px 0 0 48px;
    border-radius: 8px;
    display: block;
    margin: auto;
`;

const CropDialog = ({ previewPlaceholder, onClose, onSave }) => {
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const onSaveImage = async () => {
        try {
            const croppedImage = await getCroppedImg(previewPlaceholder, croppedAreaPixels, 0);
            onSave(croppedImage);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <BasicDialog
            visible={true}
            onClose={onClose}
            title="Crop placeholder image"
            options={[
                {
                    title: 'Cancel',
                    variant: 'contained',
                    color: 'inherit',
                    onClick: onClose,
                },
                {
                    title: 'Crop',
                    variant: 'contained',
                    color: 'primary',
                    onClick: onSaveImage,
                },
            ]}
        >
            {previewPlaceholder && (
                <ImageWrapper>
                    <Cropper
                        image={previewPlaceholder}
                        crop={crop}
                        style={{ width: '100%' }}
                        aspect={12.8 / 7.2}
                        zoom={zoom}
                        onZoomChange={setZoom}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                    />
                </ImageWrapper>
            )}
        </BasicDialog>
    );
};

export default CropDialog;

export const programmeViewTypes = {
    HORIZONTAL_VIEW: 'Horizontal',
    VERTICAL_VIEW: 'Vertical',
    LIST_VIEW: 'List',
};

export const GRID_WIDTH_SMALL = 120;
export const GRID_WIDTH_LARGE = 304;
export const GRID_HEIGHT = 60;
export const EMPTY_LIST_FILTER_TITLE = 'Nothing here…';
export const EMPTY_LIST_FILTER_SUBTITLE = 'Change your keywords or filters.';
export const EMPTY_TIMELINE_FILTER_TITLE =
    'No results on the selected day. Select another day or try another keyword';
export const EMPTY_PROGRAMME_DAY = 'No items on this day';

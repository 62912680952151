import get from 'lodash/get';

const store = {};
export default store;

export const getString = function (key, defaultValue = null) {
    if (store && store.string) {
        if (store.string[key] && store.string[key].default) {
            return store.string[key].default;
        } else if (
            store.string.eurekaStrings &&
            store.string.eurekaStrings.default &&
            store.string.eurekaStrings.default[key]
        ) {
            return store.string.eurekaStrings.default[key];
        }
    }
    return defaultValue;
};

export const getTimezone = () => {
    return get(store, `argument.offsetTimezone`, false);
};

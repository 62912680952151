import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
    AnchorText,
    ButtonsContainer,
    Card,
    CardWrapper,
    CloseCardIcon,
    ErrorMessage,
    NameContainer,
    PoliciesText,
    StyledTextField,
    Title,
} from '../style/signIn';
import { getString } from '../../../services/api/store';
import * as string from '../constants/strings';
import { Flex } from '../../../scenes/common/Flex';
import Button, { buttonTypes } from '../../common/Button';
import * as palette from '../../../components/General/Variables';
import { aboutLinks } from '../constants/strings';

const Register = ({
    password,
    updatePassword,
    firstName,
    updateFirstName,
    register,
    lastName,
    updateLastName,
    theme,
    company,
    updateCompany,
    jobTitle,
    updateJobTitle,
    eurekaOnly,
    attendingOnly,
    onClose,
    isModal,
    terms,
    privacyPolicy,
}) => {
    const [continuePressed, setContinuePressed] = useState(false);
    const TITLE_REGISTER = getString('createAccountScreenTitle') || string.CREATE_ACCOUNT_TITLE;
    const CREATE_ACCOUNT_BUTTON_LABLEL =
        getString('createAccountScreenTitle') || string.CREATE_ACCOUNT_TITLE;
    const TEXT_INPUT_COMPANY = getString('profileCompany') || string.COMPANY_LABEL;
    const TEXT_INPUT_JOB_TITLE = getString('profileJobTitle') || string.JOB_TITLE_LABEL;
    const ERROR_FIELD_REQUIRED = getString('mandatoryFieldMessage') || string.FIELD_REQUIRED;
    const INPUT_LABEL_FIRST_NAME =
        getString('createAccountFirstNameTitle') || string.FIRST_NAME_LABEL;
    const INPUT_LABEL_LAST_NAME = getString('createAccountLastNameTitle') || string.LAST_NAME_LABEL;
    const TEXT_ERROR_REQUIRED_PASSWORD =
        getString('signInPasswordMissingMessage') || string.ERROR_MESSAGE_REQUIRED_PASSWORD;
    const TEXT_INPUT_PASSWORD = getString('passwordFieldHint') || string.PASSWORD_HINT;
    const createAccountPasswordRulesUppercase =
        getString('createAccountPasswordRulesUppercase') || string.TEXT_PASSWORD_UPPERCASE;
    const createAccountPasswordRulesLength =
        getString('createAccountPasswordRulesLength') || string.TEXT_PASSWORD_LENGTH;
    const createAccountPasswordRulesLowercase =
        getString('createAccountPasswordRulesLowercase') || string.TEXT_PASSWORD_LOWERCASE;
    const createAccountPasswordRulesNumber =
        getString('createAccountPasswordRulesNumber') || string.TEXT_PASSWORD_NUMBER;
    const TERMS = getString('termsAndConditionsLabelButton') || 'Terms of Use';
    const POLICY = getString('privacyLabelButton') || 'Privacy Policy';

    let passwordErrorOnUserInput = '';
    if (!string.digitExists(password)) {
        passwordErrorOnUserInput += `${createAccountPasswordRulesNumber}<br/>`;
    }
    if (!string.lowercaseExists(password)) {
        passwordErrorOnUserInput += `${createAccountPasswordRulesLowercase}<br/>`;
    }
    if (!string.uppercaseExists(password)) {
        passwordErrorOnUserInput += `${createAccountPasswordRulesUppercase}<br/>`;
    }
    if (password.length < 8) {
        passwordErrorOnUserInput += createAccountPasswordRulesLength;
    }
    const labelsWithAsterisk = document.getElementsByTagName('label');

    useEffect(() => {
        if (labelsWithAsterisk?.length) {
            for (var i = 0; i < labelsWithAsterisk.length - 1; i++) {
                var text = labelsWithAsterisk[i].textContent;
                labelsWithAsterisk[i].innerHTML = text.replace(' *', '');
            }
        }
    }, [labelsWithAsterisk]);

    return (
        <CardWrapper>
            <Card
                customHeight={'488px'}
                customTop={'unset'}
                largeCardWithCustomHeight={true}
                overflow={'scroll'}
                isModal={isModal}
            >
                <Flex alignItems={'center'} justifyContent={'center'}>
                    <Title style={{ marginBottom: 24 }}>{TITLE_REGISTER}</Title>
                    {!eurekaOnly && !attendingOnly && (
                        <CloseCardIcon onClick={onClose}>{palette.ICON_CLOSE}</CloseCardIcon>
                    )}
                </Flex>
                <NameContainer>
                    <StyledTextField
                        value={firstName}
                        required
                        onChange={fnm => {
                            updateFirstName(fnm);
                        }}
                        id="register-first"
                        label={INPUT_LABEL_FIRST_NAME}
                        type="text"
                        errorText={ERROR_FIELD_REQUIRED}
                        error={continuePressed && firstName.length === 0}
                    />
                    <StyledTextField
                        value={lastName}
                        required
                        onChange={lnm => {
                            updateLastName(lnm);
                        }}
                        id="register-last"
                        label={INPUT_LABEL_LAST_NAME}
                        type="text"
                        errorText={ERROR_FIELD_REQUIRED}
                        error={continuePressed && lastName.length === 0}
                    />
                </NameContainer>
                <StyledTextField
                    value={password}
                    required
                    onChange={pass => {
                        updatePassword(pass, true);
                    }}
                    id="register-password"
                    label={TEXT_INPUT_PASSWORD}
                    type="password"
                    errorText={
                        password && passwordErrorOnUserInput ? '' : TEXT_ERROR_REQUIRED_PASSWORD
                    }
                    error={continuePressed && (!password || !!passwordErrorOnUserInput)}
                />
                {password && passwordErrorOnUserInput && (
                    <ErrorMessage>{ReactHtmlParser(passwordErrorOnUserInput)}</ErrorMessage>
                )}
                <StyledTextField
                    value={jobTitle}
                    required
                    error={continuePressed && !jobTitle}
                    onChange={job => {
                        updateJobTitle(job, true);
                    }}
                    id="register-job-title"
                    label={TEXT_INPUT_JOB_TITLE}
                    type="text"
                    errorText={ERROR_FIELD_REQUIRED}
                />
                <StyledTextField
                    value={company}
                    error={continuePressed && !company}
                    onChange={company => {
                        updateCompany(company, true);
                    }}
                    id="register-company"
                    label={TEXT_INPUT_COMPANY}
                    type="text"
                    errorText={ERROR_FIELD_REQUIRED}
                />
                <ButtonsContainer primarycolor={theme.primary} style={{ marginTop: 16 }}>
                    <Button
                        type={buttonTypes.GREEN_LONG}
                        background={theme.primary}
                        text={CREATE_ACCOUNT_BUTTON_LABLEL}
                        onClick={() => {
                            setContinuePressed(true);
                            !passwordErrorOnUserInput && register();
                        }}
                        data-qa="button-register-continue"
                    />
                </ButtonsContainer>
                <PoliciesText style={{ marginTop: 16 }}>
                    By signing up, you agree to the{' '}
                    <AnchorText
                        href={terms || aboutLinks.termsAndConditions}
                        target="_blank"
                        color={theme.primary}
                    >
                        {TERMS}
                    </AnchorText>{' '}
                    and{' '}
                    <AnchorText
                        href={privacyPolicy || aboutLinks.privacyPolicy}
                        target="_blank"
                        color={theme.primary}
                    >
                        {' '}
                        {POLICY}
                    </AnchorText>{' '}
                    and you accept the{' '}
                    <AnchorText
                        href={aboutLinks.cookiePolicy}
                        target="_blank"
                        color={theme.primary}
                    >
                        Cookie Policy.
                    </AnchorText>
                </PoliciesText>
            </Card>
        </CardWrapper>
    );
};

export default Register;

import React, { useEffect, useRef, useState } from 'react';
import { FontIcon } from 'react-md';
import styled, { css } from 'styled-components';
import Draggable from 'react-draggable';
import get from 'lodash/get';
import html2canvas from 'html2canvas';

import { useVMMutation, useVMState } from '../../containers/main';
import { LoaderTransparent } from '../common/LoaderTransparent';
import entities from '../../constants/entities';
import UploadResource from '../common/UploadResource';
import onUpdatePosterPositionAndScale from '../../events/onUpdatePosterPositionAndScale';
import useResize from '../../../../../VirtualSession/hooks/useResize';
import scalingTools from '../../utils/scalingTools';

const Wrapper = styled.div`
    position: relative;
    height: 500px;
    overflow: hidden;
    flex-shrink: 0;
    width: 100%;
`;

const PosterWrapper = styled.div`
    position: relative;
    text-align: center;
    background-color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const ContentWrapper = styled.div`
    position: relative;
    text-align: center;
    background-color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

const CanvasWrapper = styled.div`
    cursor: move;
`;

const SlideButton = styled.div`
    background-color: transparent;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    color: #ffffff;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: rgba(255, 255, 255, 0.15);
    }

    ${props =>
        props.disabled &&
        css`
            opacity: 0.5;
        `}
`;

const ButtonsWrapper = styled.div`
    position: absolute;
    bottom: 8px;
    left: 8px;
    width: 112px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.87);
    border-radius: 50px;
    padding: 0 4px;
    z-index: 2;
`;

const DecreaseButton = styled.div`
    width: 14px;
    height: 2px;
    background-color: #fff;
    border-radius: 2px;
    position: absolute;
    ${props =>
        props.rotate &&
        css`
            transform: rotate(90deg);
        `}
`;

const ZoomText = styled.div`
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    text-align: center;
    cursor: pointer;
    z-index: 4;
    border-radius: 4px;

    &:hover {
        ${props => !props.nohover && 'background-color: rgba(244, 245, 247, 0.15);'}
    }
    &:active {
        ${props => !props.nohover && 'background-color: rgba(244, 245, 247, 0.3);'}
    }
`;

const ZoomMenuItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 28px;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;

    & > div {
        text-align: left;
        margin: 0 8px;
    }

    &:hover {
        background-color: rgba(244, 245, 247, 0.15);
    }
    &:active {
        background-color: rgba(244, 245, 247, 0.3);
    }
`;

const ZoomContainer = styled.div`
    position: absolute;
    bottom: 44px;
    left: 24px;
    width: 80px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.87);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 4px;
`;

const StyledFontIcon = styled(FontIcon)`
    color: #fff;
    font-size: 17px;
    margin-right: 8px;
`;

const Poster = () => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();

    const {
        pdf,
        url,
        shouldCalculatePosition,
        screenSharing,
        virtualEventUser,
        virtualEventSession,
    } = stateCtx;
    const [scale, setScale] = useState((virtualEventUser.data && virtualEventUser.data.scale) || 1);
    const [position, setPosition] = useState({
        x: (virtualEventUser.data && virtualEventUser.data.positionX) || 0,
        y: (virtualEventUser.data && virtualEventUser.data.positionY) || 0,
    });
    const [displayZoomMenu, setDisplayZoomMenu] = useState(false);
    const [showZoomTool, setShowZoomTool] = useState(false);
    const wrapperRef = useRef(null);
    const canvasRef = useRef(null);
    const contentRef = useRef(null);
    const videoRef = useRef(null);
    const intervalRef = useRef(null);

    const [drawing, setDrawing] = useState(true);
    const zoomPercentage = `${(scale * 100).toFixed(2)}%`;

    useEffect(() => {
        if (videoRef.current && !screenSharing) {
            mutationCtx.startCanvasSharing({ canvas: videoRef.current });
        }

        return mutationCtx.stopCanvasSharing;
    }, [videoRef.current, screenSharing]);

    useEffect(() => {
        (async () => {
            try {
                if (!wrapperRef.current || !pdf || !contentRef.current || !videoRef.current) {
                    return null;
                }

                const page = await pdf.getPage(1);

                const pageSize = {
                    height: page._pageInfo.view[3],
                    width: page._pageInfo.view[2],
                };
                const scalingFactor =
                    pageSize.width / pageSize.height >= 16 / 9 ? 'width' : 'height';
                const DYNAMIC_SCALING_MAP = scalingTools.getDynamicScalingMap(scalingFactor);

                const scaleToUse =
                    (DYNAMIC_SCALING_MAP[scalingFactor] * 100) / pageSize[scalingFactor] / 100;
                const viewport = page.getViewport({ scale: scaleToUse });

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');

                canvas.style.maxWidth = '100%';
                canvas.style.maxHeight = 'calc(100vh - 250px)';
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render the page into the <canvas> element.
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                setDrawing(true);

                await page.render(renderContext).promise;

                if (canvasRef.current) {
                    const canvasContext = canvasRef.current.getContext('2d');

                    canvasRef.current.style.maxWidth = '100%';
                    canvasRef.current.style.maxHeight = '100%';
                    canvasRef.current.height = viewport.height;
                    canvasRef.current.width = viewport.width;
                    canvasContext.drawImage(canvas, 0, 0, canvas.width, canvas.height);
                    setDrawing(false);
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }, [pdf]);

    useEffect(() => {
        // we fit the poster to the container based on the height
        if (
            canvasRef.current &&
            canvasRef.current.offsetHeight &&
            contentRef.current &&
            contentRef.current.offsetHeight &&
            pdf &&
            !drawing
        ) {
            const val = contentRef.current.offsetHeight / canvasRef.current.offsetHeight;

            if (val < 1) {
                setScale(val);
                savePositionAndScale({ position, scale: val });
            }
        }
    }, [canvasRef.current?.offsetHeight, contentRef.current?.offsetHeight, pdf, drawing]);

    useEffect(() => {
        (async () => {
            if (!drawing && videoRef.current && get(contentRef, 'current')) {
                const newCanvas = await html2canvas(contentRef.current, { imageTimeout: 0 });

                if (!videoRef.current) {
                    // it can disappear during the await
                    return;
                }

                // we always use the landscape scaling for the content
                const DYNAMIC_SCALING_MAP = scalingTools.getDynamicScalingMap('width');

                const newSize = {
                    width:
                        newCanvas.width >= DYNAMIC_SCALING_MAP.width
                            ? DYNAMIC_SCALING_MAP.width
                            : newCanvas.width,
                    height:
                        newCanvas.height >= DYNAMIC_SCALING_MAP.height
                            ? DYNAMIC_SCALING_MAP.height
                            : newCanvas.height,
                };

                if (videoRef.current.width !== newSize.width) {
                    videoRef.current.width = newSize.width;
                }

                if (videoRef.current.height !== newSize.height) {
                    videoRef.current.height = newSize.height;
                }

                const { height, width } = newSize;
                const canvasCtx = videoRef.current.getContext('2d');

                canvasCtx.drawImage(newCanvas, 0, 0, width, height);

                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }

                intervalRef.current = setInterval(() => {
                    if (!videoRef.current) {
                        return;
                    }

                    const canvasContext = videoRef.current.getContext('2d');

                    videoRef.current.style.maxWidth = '100%';
                    videoRef.current.style.maxHeight = '100%';
                    videoRef.current.height = newSize.height;
                    videoRef.current.width = newSize.width;
                    canvasContext.drawImage(newCanvas, 0, 0, newSize.width, newSize.height);
                }, 30);
            }
        })();

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [position, scale, drawing]);

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const savePositionAndScale = ({ position, scale }) => {
        onUpdatePosterPositionAndScale({ stateCtx, mutationCtx, position, scale });
    };

    const onZoomOut = () => {
        if (scale <= 0.25) {
            return;
        }
        const scaleNew = scale - 0.25;
        setScale(scaleNew);
        savePositionAndScale({ position, scale: scaleNew });
    };
    const onZoomIn = () => {
        if (scale >= 2.5) {
            return;
        }
        const scaleNew = scale + 0.25;
        setScale(scaleNew);
        savePositionAndScale({ position, scale: scaleNew });
    };

    const onStopDrag = (e, data) => {
        const positionNew = {
            x: Math.floor(data.x),
            y: Math.floor(data.y),
        };
        setPosition(positionNew);
        savePositionAndScale({ position: positionNew, scale });
    };

    const onReset = () => {
        const positionNew = {
            x: 0,
            y: 0,
        };
        setPosition(positionNew);
        const scaleNew = 1;
        setScale(scaleNew);
        savePositionAndScale({ position: positionNew, scale: scaleNew });
    };

    const [resizeRef] = useResize();

    return (
        <Wrapper
            innerRef={resizeRef}
            onMouseEnter={() => setShowZoomTool(true)}
            onMouseLeave={() => setShowZoomTool(false)}
        >
            <LoaderTransparent
                active={
                    virtualEventUser.uploadRequestStatus === entities.requestStatus.loading ||
                    virtualEventSession.uploadRequestStatus === entities.requestStatus.loading
                }
            >
                {!url && (
                    <UploadResource type={entities.virtualEventSessionEntity.roomType.roundTable} />
                )}
                {url && (
                    <PosterWrapper id="poster-content-wrapper">
                        <ContentWrapper innerRef={contentRef}>
                            <Draggable
                                onStop={onStopDrag}
                                position={{ x: position.x, y: position.y }}
                            >
                                <CanvasWrapper ref={wrapperRef}>
                                    <canvas
                                        ref={canvasRef}
                                        style={{
                                            transform: `scale(${scale})`,
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                        }}
                                        id="pdf"
                                    />
                                </CanvasWrapper>
                            </Draggable>
                        </ContentWrapper>
                        {displayZoomMenu && (
                            <ZoomContainer onMouseLeave={() => setDisplayZoomMenu(false)}>
                                <ZoomMenuItemWrapper onClick={() => setScale(1)}>
                                    <ZoomText nohover>100%</ZoomText>
                                    {scale === 1 && <StyledFontIcon>done</StyledFontIcon>}
                                </ZoomMenuItemWrapper>
                                <ZoomMenuItemWrapper onClick={() => setScale(1.25)}>
                                    <ZoomText nohover>125%</ZoomText>
                                    {scale === 1.25 && <StyledFontIcon>done</StyledFontIcon>}
                                </ZoomMenuItemWrapper>
                                <ZoomMenuItemWrapper onClick={() => setScale(1.5)}>
                                    <ZoomText nohover>150%</ZoomText>
                                    {scale === 1.5 && <StyledFontIcon>done</StyledFontIcon>}
                                </ZoomMenuItemWrapper>
                                <ZoomMenuItemWrapper onClick={() => setScale(2)}>
                                    <ZoomText nohover>200%</ZoomText>
                                    {scale === 2 && <StyledFontIcon>done</StyledFontIcon>}
                                </ZoomMenuItemWrapper>
                                <ZoomMenuItemWrapper onClick={() => setScale(2.5)}>
                                    <ZoomText nohover>250%</ZoomText>
                                    {scale === 2.5 && <StyledFontIcon>done</StyledFontIcon>}
                                </ZoomMenuItemWrapper>
                            </ZoomContainer>
                        )}
                        {
                            <ButtonsWrapper>
                                <SlideButton onClick={onZoomOut}>
                                    <DecreaseButton />
                                </SlideButton>
                                <ZoomText onClick={() => setDisplayZoomMenu(true)}>
                                    {zoomPercentage}
                                </ZoomText>
                                <SlideButton onClick={onZoomIn}>
                                    <DecreaseButton />
                                    <DecreaseButton rotate />
                                </SlideButton>
                            </ButtonsWrapper>
                        }
                    </PosterWrapper>
                )}

                <canvas ref={videoRef} style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </LoaderTransparent>
        </Wrapper>
    );
};

export default Poster;

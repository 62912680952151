import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import combinedReducer from './reducers';

const middlewares = [thunk];
// Uncomment this to debug redux on browser:
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(combinedReducer, composeEnhancers(applyMiddleware(...middlewares)));
const store = createStore(combinedReducer, applyMiddleware(...middlewares));

export default store;

import React from 'react';
import styled from 'styled-components';
import { addFavoriteForEvent, isFavoriteById, removeFavorite } from '../../services/api/data';
import Bookmark from '../General/Bookmark';
import * as eureka from '../../services/api/eureka';
import eventBus from '../../utils/eventBus';
import AnalyticsService from '../../features/analytics/services';
import { ImageAvatar } from '../Icons';
import { StyledListItem } from './style/index';
import { getLocalAppStateAsync } from '../../services/api/db';
import GamificationService from '../../scenes/Achievements/services/GamificationService';
import { AchievementType } from '../../scenes/Achievements/hooks/useAchievementActions';
import ClassifierIconLabelComponent from '../Icons/ClassifierIconLabelComponent';

const IconsContainer = styled.div`
    margin-top: 10px;
    display: block;
`;

const ClassifierContainer = styled.div`
    margin: ${props => (props.withPadding ? '16px 24px' : 0)};
`;

class Simple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            favoriteStatus: props.item.favorite ? 'checked' : '',
        };
    }

    componentDidMount() {
        this.onFavorite();
    }

    onFavorite = event => {
        const { item } = this.state;
        if (event) {
            event.preventDefault();

            const _favoriteStatus = this.state.favoriteStatus === 'checked' ? '' : 'checked';
            this.setState({
                favoriteStatus: _favoriteStatus,
            });

            if (_favoriteStatus) {
                addFavoriteForEvent(item, async () => {
                    AnalyticsService.addSample('favorite', true, item.id);
                    const currentEvent = await getLocalAppStateAsync();
                    GamificationService.trackAchievement(
                        AchievementType.FAVORITE,
                        currentEvent.id,
                        item.id,
                    );

                    eureka.sync(() => {
                        eventBus.emit('updateMyFavoritesCount');
                    });
                });
            } else {
                removeFavorite(item, () => {
                    AnalyticsService.addSample('favorite', false, item.id);

                    eureka.sync(() => {
                        eventBus.emit('updateMyFavoritesCount');
                    });
                });
            }
        } else {
            isFavoriteById(item.id, (err, isFavorite) => {
                if (isFavorite) {
                    this.setState({
                        favoriteStatus: 'checked',
                    });
                }
            });
        }
    };

    render() {
        const { item } = this.state;

        if (item.mimeType && item.mimeType.length > 0) {
            return (
                <StyledListItem
                    contentStyle={{ fontFamily: "'Cabin', sans-serif" }}
                    key={'link_' + item.id}
                    tabIndex={-1}
                    primaryText={item.name}
                    secondaryText={item.url}
                    leftAvatar={<ImageAvatar avatarType={item.mimeType} />}
                    maxwidth={'390px'}
                />
            );
        }

        if (this.props.type === 'classifier' && this.props.isDetailPage) {
            return (
                <ClassifierContainer withPadding={!this.props.isDetailPage}>
                    <ClassifierIconLabelComponent
                        params={item.params}
                        label={item.name}
                        displayFullName={!this.props.isDetailPage}
                    />
                </ClassifierContainer>
            );
        }

        return (
            <StyledListItem
                className={'md-fake-btn md-pointer--hover md-fake-btn--no-outline'}
                key={'timeslot_' + item.id}
                primaryText={item.name}
                tabIndex={-1}
                secondaryText={item.subNameList || item.url}
                maxwidth={this.props.type !== 'classifier' && '390px'}
                onClick={item.onClick}
                iconCustomHeight={this.props.type === 'classifier' && '40px'}
                leftAvatar={
                    this.props.type === 'classifier' && (
                        <ClassifierIconLabelComponent
                            classifierId={item.id}
                            displayTransparentForMissedColor={true}
                            displayFullName={true}
                        />
                    )
                }
            >
                {this.props.icon && <IconsContainer>{this.props.icon}</IconsContainer>}
                {this.props.type !== 'link' ? (
                    <Bookmark
                        favoriteStatus={this.state.favoriteStatus}
                        clickMyCongress={this.onFavorite}
                    />
                ) : (
                    <Bookmark favoriteStatus={this.state.favoriteStatus} />
                )}
            </StyledListItem>
        );
    }
}

export default Simple;

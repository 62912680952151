import { SENTRY_ENVIRONMENT } from '../../../config';

export const logChat = async message => {
    const isDev = SENTRY_ENVIRONMENT !== 'production';
    if (isDev) {
        const now = new Date();
        console.log(
            ` ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()} CHAT DEBUG -> ${message} `,
        );
    }
};

import React from 'react';
import styled from 'styled-components';
import CaptionLoading from './CaptionLoading';
import { useVMState } from '../../../../containers/main';
import get from 'lodash/get';
import { useTheme } from '../../../../../../../../components/Theme/ThemeContext';
import { useTranslationsStore } from '../../../../../../../../stores/TranslationsStore';

const MessageBubble = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 12px;
    max-width: 70%;
    word-wrap: break-word;
    margin: 5px;
`;

const MessageText = styled.span`
    width: 100%;
    flex-shrink: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
`;

function CaptionMessage({ message, showName }) {
    const { virtualEventUser, virtualEventSession } = useVMState();
    const { selectedLanguage, previousLanguages } = useTranslationsStore();
    const { theme } = useTheme();
    const currentVirtualUser = get(virtualEventUser, 'data') || null;
    const vUsers = get(virtualEventSession, 'data.VirtualEventUsers') || [];
    const { translations, complete, participantId } = message;
    const oldLanguage = previousLanguages.find(pl => translations && translations[pl]);

    const text =
        (translations && translations[selectedLanguage]) ||
        (translations && translations[oldLanguage]) ||
        (translations && translations.en) ||
        'Message not available';
    const isCurrentUser = currentVirtualUser && currentVirtualUser.UserId === participantId;
    const messageSender = vUsers.find(vUser => vUser.UserId === participantId);

    return (
        <MessageBubble
            style={isCurrentUser ? { alignSelf: 'flex-end', backgroundColor: theme.primary } : {}}
        >
            {!isCurrentUser && messageSender && showName && (
                <MessageText style={{ fontWeight: 'bold', paddingBottom: 4 }}>
                    {messageSender.User.firstName} {messageSender.User.lastName}
                </MessageText>
            )}
            <MessageText style={isCurrentUser ? { color: '#fff' } : {}}>{text}</MessageText>
            {!complete && <CaptionLoading color={isCurrentUser ? '#fff' : 'rgba(0, 0, 0, 0.87)'} />}
        </MessageBubble>
    );
}

export default CaptionMessage;

import * as React from 'react';
import Button from '../common/Button';

const BroadcastingButton = props => {
    const regularStyle = {
        position: 'relative',
        height: '32px',
        transition: 'width 0.5s ease-out',
    };
    const loadingStyle = {
        width: '56px',
        height: '32px',
        background: '#EE1045',
        border: 'none',
        position: 'relative',
        transition: 'width 0.5s ease-out',
    };
    const extraProp = { style: props.loading ? loadingStyle : regularStyle };

    return <Button {...props} extra={extraProp} />;
};

export default BroadcastingButton;

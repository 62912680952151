import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { useVMMutation, useVMState } from '../../containers/main';
import entities from '../../constants/entities';
import Button, { ButtonClassType } from '../common/Button';
import { LightTooltip, RedStyledChip } from '../moderator/common/styles';
import onMakePollsInactive from '../../events/onMakePollsInactive';

const { virtualEventUserEntity } = entities;

const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    line-height: 1.14;
    text-transform: capitalize;
    white-space: nowrap;
    cursor: pointer;
`;

const Switcher = styled.div`
    height: 32px;
    width: fit-content;
    border-radius: 16px;
    background-color: #f1f1f3;
    display: flex;
    cursor: pointer;
    margin: 0 0 0 26px;
    button {
        flex: 1;
    }
`;

const RedChip = styled(RedStyledChip)`
    width: 38px;
    margin: 0 0 0 8px !important;
    background-color: ${props => props.disabled && 'rgba(0, 0, 0, 0.38)'};
    ${props =>
        props.inverseColors &&
        !props.disabled &&
        css`
            color: #d50000 !important;
            background-color: #fff;
        `}
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

function SwitchLiveOrPreRecording() {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const { sessionId, socket, virtualEventUser, virtualEventSession, speakerViewMode } = stateCtx;

    const virtualEventSessionStatus = virtualEventSession.data.status;
    const isRoomModerated =
        virtualEventSession.data.roomType === entities.virtualEventSessionEntity.roomType.moderated;
    const isRoomClosed =
        virtualEventSessionStatus === entities.virtualEventSessionEntity.status.closed;
    const isRoomOpen =
        virtualEventSessionStatus === entities.virtualEventSessionEntity.status.initial;
    const isBroadcasting =
        virtualEventSessionStatus === entities.virtualEventSessionEntity.status.broadcasting;
    const isCurrentUserPresenting = virtualEventUser.data.isActive;

    const shouldRenderRoomRoundTableHeader =
        virtualEventSession.data.roomType ===
        entities.virtualEventSessionEntity.roomType.roundTable;

    const shouldRenderModeratorHeader =
        isRoomModerated && virtualEventUser.data.role === virtualEventUserEntity.role.moderator;

    useEffect(() => {
        if (shouldRenderRoomRoundTableHeader || shouldRenderModeratorHeader) {
            return;
        }
        if (speakerViewMode === entities.speakerViewMode.audience && isRoomClosed) {
            handleTabChange(entities.speakerViewMode.live);
        }
    }, [isRoomClosed]);

    if (shouldRenderRoomRoundTableHeader) {
        return <div></div>;
    }

    if (shouldRenderModeratorHeader) {
        return <div></div>;
    }

    const getClassType = virtualEventSessionMode =>
        speakerViewMode === virtualEventSessionMode
            ? ButtonClassType.RED_ROUND
            : ButtonClassType.TRANSPARENT_NO_BORDERS;

    const handlePageClosing = async () => {
        window.leaving = true;

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isPrerecording: false,
                    },
                ],
            },
        });

        return 'Do you really want to close?';
    };

    const handleWindowFocus = async () => {
        if (window.leaving) {
            socket.emit('updateData', {
                objectId: sessionId,
                virtualEventSession: {
                    VirtualEventUsers: [
                        {
                            id: virtualEventUser.data.id,
                            isPrerecording: true,
                        },
                    ],
                },
            });
            window.leaving = false;
        }
    };

    const handleTabChange = async mode => {
        const isPrerecording = mode === entities.speakerViewMode.prerecording;
        const newUserData = {
            isPrerecording,
        };

        window.onbeforeunload = isPrerecording ? handlePageClosing : null;

        if (isPrerecording) {
            window.addEventListener('focus', handleWindowFocus);
        } else {
            window.removeEventListener('focus', handleWindowFocus);
        }

        if (isCurrentUserPresenting) {
            await onMakePollsInactive(stateCtx, mutationCtx);
        }

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        ...newUserData,
                    },
                ],
            },
        });

        mutationCtx.setSpeakerViewMode(mode);
    };

    const handleSwitchToPreRecordingOrAudienceView = async () => {
        const speakerViewModeToSwitchTo = isRoomClosed
            ? entities.speakerViewMode.prerecording
            : entities.speakerViewMode.audience;

        await handleTabChange(speakerViewModeToSwitchTo);
    };

    return (
        <Switcher>
            <Button
                callback={() => handleTabChange(entities.speakerViewMode.live)}
                content={
                    <ButtonContent>
                        <div>Present live</div>
                    </ButtonContent>
                }
                classType={getClassType(entities.speakerViewMode.live)}
            />

            <Button
                callback={handleSwitchToPreRecordingOrAudienceView}
                disabled={!isRoomClosed && isCurrentUserPresenting}
                content={
                    <LightTooltip
                        title="Audience view is disabled because you are presenting"
                        disableHoverListener={isRoomClosed || !isCurrentUserPresenting}
                    >
                        <ButtonContent>
                            {isRoomClosed && <div>Pre-record</div>}
                            {isRoomOpen && <div>Audience view</div>}
                            {isBroadcasting && (
                                <FlexRow>
                                    Audience view
                                    <RedChip
                                        inverseColors={
                                            speakerViewMode === entities.speakerViewMode.audience
                                        }
                                        disabled={isCurrentUserPresenting}
                                    >
                                        LIVE
                                    </RedChip>
                                </FlexRow>
                            )}
                        </ButtonContent>
                    </LightTooltip>
                }
                classType={getClassType(
                    isRoomClosed
                        ? entities.speakerViewMode.prerecording
                        : entities.speakerViewMode.audience,
                )}
                data-qa="pre-record-presentation-tab-button"
            />

            {isRoomClosed && (
                <Button
                    callback={() => handleTabChange(entities.speakerViewMode.audience)}
                    disabled={isCurrentUserPresenting}
                    content={
                        <LightTooltip
                            title="Audience view is disabled because you are presenting"
                            disableHoverListener={!isCurrentUserPresenting}
                        >
                            <ButtonContent>
                                <div>Audience view</div>
                            </ButtonContent>
                        </LightTooltip>
                    }
                    classType={getClassType(entities.speakerViewMode.audience)}
                    data-qa="audience-view-presentation-tab-button"
                />
            )}
        </Switcher>
    );
}

const HeaderWithCheck = () => {
    const stateCtx = useVMState();
    const { virtualEventUser } = stateCtx;

    return virtualEventUser.data && virtualEventUser.data.id ? <SwitchLiveOrPreRecording /> : null;
};

export default HeaderWithCheck;

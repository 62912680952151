export const votesColorScheme = [
    '#1baae4',
    '#ee6d60',
    '#6cc565',
    '#f2b332',
    '#5460e4',
    '#e538a1',
    '#F2934C',
    '#A354D1',
];

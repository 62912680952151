import { getItem } from '../../../services/api/graphQlRepository';

const getWebPageByPage = function (pageId, next) {
    getItem('pages', pageId, (err, page) => {
        if (page && page.params && page.params.webpageId) {
            getItem('webpages', page.params.webpageId, (err, result) => {
                next(err, result, page);
            });
        } else {
            next(err, null, null);
        }
    });
};

const getWebPage = function (pageId, next) {
    getItem('webpages', pageId, (err, result) => {
        next(err, result);
    });
};

export { getWebPage, getWebPageByPage };

import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import { createRequestTypes } from '../../../utils/types';
import api from '../api';
import NotificationService from '../services/NotificationService';
import { notificationTypes } from '../constants';
import { getPrivateConversationWithId } from '../../Talk/selectors';
import { getLocalAppStateAsync } from '../../../services/api/db';
import { acceptFriendRequest, cancelFriendRequest } from '../../UserProfile/service';
import { calcTimeDifference } from '../../VirtualModeration/features/virtualFeature/services/QuestionsAndAnswersService';

export const GET_UNREAD_NOTIFICATIONS = createRequestTypes('GET_UNREAD_NOTIFICATIONS');
export const FETCH_NOTIFICATIONS = createRequestTypes('FETCH_NOTIFICATIONS');
export const INCREASE_UNREAD_NOTIFICATIONS_COUNT = 'INCREASE_UNREAD_NOTIFICATIONS_COUNT';

export const getUnreadNotifications = () => async dispatch => {
    dispatch({ type: GET_UNREAD_NOTIFICATIONS.REQUEST });

    const appState = await getLocalAppStateAsync();
    const eventId = appState.eventId;

    const tasks = [];
    const tasksNotif = [];

    let data = {
        eventId: null,
        appointmentOnly: true,
        reminderOnly: true,
        newsOnly: true,
        friendshipOnly: true,
    };

    try {
        tasks.push(() => api.getUnreadNotifications(null));
        if (eventId) {
            tasks.push(() => api.getUnreadNotifications(eventId));
        }
        const [unread, unreadForEvent] = await Promise.all(tasks.map(t => t()));

        const response = {};
        if (unread) {
            Object.keys(unread).forEach(key => {
                response[key] = unread[key] || 0;
                if (unreadForEvent) {
                    response[key] += unreadForEvent[key] || 0;
                }
            });
        }

        dispatch({
            type: GET_UNREAD_NOTIFICATIONS.SUCCESS,
            payload: { unreadNotifications: response },
        });

        tasksNotif.push(() => api.getAllNotifications(null, data));
        if (eventId) {
            tasksNotif.push(() => api.getAllNotifications(eventId, data));
        }
        const [all, allForEvent] = await Promise.all(tasksNotif.map(t => t()));

        const allNotifications = Array.from(new Set((all || []).concat(allForEvent || [])));
        const notifications = uniqBy(allNotifications, 'id');

        if (
            notifications &&
            notifications[notifications.length - 1].type === notificationTypes.NEWS &&
            !notifications[notifications.length - 1].read
        ) {
            const timeDifference = calcTimeDifference(
                notifications[notifications.length - 1].createdAt,
                true,
            );
            if (
                timeDifference &&
                timeDifference.includes('s ago') &&
                parseInt(timeDifference) < 5
            ) {
                NotificationService.handleNotification({
                    notificationType: notificationTypes.NEWS,
                    title: notifications[notifications.length - 1].title,
                });
            }
        }
    } catch (_) {
        dispatch({ type: GET_UNREAD_NOTIFICATIONS.FAILURE });
    }
};

export const getPrivateConversationFromNotification = userId => async (dispatch, getState) => {
    if (userId) {
        return getPrivateConversationWithId(getState(), userId);
    }
};

export const fetchNotifications = chatOnly => async dispatch => {
    let data;
    const appState = await getLocalAppStateAsync();
    const eventId = appState.eventId;
    if (chatOnly) {
        data = {
            chatOnly: true,
        };
    } else {
        data = {
            eventId: null,
            appointmentOnly: true,
            reminderOnly: true,
            newsOnly: true,
            friendshipOnly: true,
        };
    }

    dispatch({ type: FETCH_NOTIFICATIONS.REQUEST });

    const tasks = [];
    try {
        tasks.push(() => api.getAllNotifications(null, data));
        if (eventId) {
            tasks.push(() => api.getAllNotifications(eventId, data));
        }
        const [all, allForEvent] = await Promise.all(tasks.map(t => t()));

        const allNotifications = Array.from(new Set((all || []).concat(allForEvent || [])));
        const notifications = uniqBy(allNotifications, 'id');

        const notificationWithDisplayDate = notifications.map(notification => {
            let displayDate = notification.updatedAt;

            if (notification.startDate) {
                displayDate = notification.startDate;
            }

            return {
                ...notification,
                displayDate,
            };
        });
        const sortedNotifications = orderBy(
            notificationWithDisplayDate,
            ['displayDate', 'read'],
            ['desc', 'asc'],
        );
        dispatch({
            type: FETCH_NOTIFICATIONS.SUCCESS,
            payload: { notifications: sortedNotifications },
        });
    } catch (_) {
        dispatch({ type: FETCH_NOTIFICATIONS.FAILURE });
    }
};

export const removeNotificationsByIds = data => async () => {
    const { eventId } = await getLocalAppStateAsync();
    await api.removeNotificationsByIds({
        ...data,
        eventId,
    });
};

const getRemindersFromLocalStorage = () => {
    const remindersLocalStorage = localStorage.getItem('reminders');

    try {
        const parsed = JSON.parse(remindersLocalStorage);

        return parsed || [];
    } catch (_) {
        return [];
    }
};

const saveRemindersToLocalStorage = reminders => {
    localStorage.setItem('reminders', JSON.stringify(reminders));
};

export const addReminder = reminderObject => async dispatch => {
    const reminders = getRemindersFromLocalStorage();
    const reminderObjectExists =
        reminders.findIndex(
            item =>
                item.id === reminderObject.id &&
                item.isEvaluationReminder === reminderObject.isEvaluationReminder,
        ) > -1;

    if (reminderObjectExists) {
        return;
    }

    NotificationService.handleNotification({
        notificationType: notificationTypes.REMINDER,
        reminderObject,
    });

    if (!reminderObject.isEvaluationReminder) {
        dispatch({
            type: INCREASE_UNREAD_NOTIFICATIONS_COUNT,
            payload: { value: 1 },
        });
    }

    reminders.push(reminderObject);
    saveRemindersToLocalStorage(reminders);
};

export const increaseUnreadNotifications = () => dispatch => {
    dispatch({
        type: INCREASE_UNREAD_NOTIFICATIONS_COUNT,
        payload: { value: 1 },
    });
};

export const acceptFriendRequestAction = userId => async () => {
    const { id: eventId } = await getLocalAppStateAsync();
    await acceptFriendRequest({
        from: userId,
        eventId,
    });
};

export const declineFriendRequestAction = userId => async () => {
    const { id: event } = await getLocalAppStateAsync();
    await cancelFriendRequest({
        from: userId,
        event,
    });
};

import React, { useMemo } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { useVMState, useVMMutation } from '../../containers/main';
import ActivePresenter from './ActivePresenter';
import Presenter from './Presenter';
import entities from '../../constants/entities';

const Wrapper = styled.div`
    flex-shrink: 0;
    padding: 16px;
    overflow: scroll;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    width: ${props => (props.iswindows ? '476px' : '459px')};
`;

const ActivePresenterWrapper = styled.div`
    height: 240px;
    margin: 0 0 8px 0;
`;

const GridForUsers = styled.div`
    display: grid;
    width: 100%;
    overflow: hidden;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
`;

const Text = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 248px);
    font-family: Roboto;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
`;

export default () => {
    const isWindows = window.navigator.appVersion.indexOf('Win') !== -1;
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const {
        virtualEventUser,
        virtualEventSession,
        sessionId,
        socket,
        peers,
        user: currentUser,
        screenShareStream,
        shareType,
    } = stateCtx;

    const virtualEventUsers = useMemo(() => {
        const virtualEventusersUnparsed = get(virtualEventSession, 'data.VirtualEventUsers', []);

        return virtualEventusersUnparsed.filter(virtualEventUserUnparsed => {
            const notHost =
                virtualEventUserUnparsed.role !==
                entities.virtualEventUserEntity.role.roundTableHost;
            const isCurrentUser = virtualEventUserUnparsed.id === virtualEventUser.data.id;
            return (
                notHost &&
                (isCurrentUser ||
                    peers.find(
                        peerId =>
                            currentUser.id !== peerId && peerId === virtualEventUserUnparsed.UserId,
                    ))
            );
        });
    }, [virtualEventSession, peers]);

    const onRemove = async virtualUserIndex => {
        const virtualEventUsersClone = [...virtualEventUsers];
        if (
            virtualEventUsersClone[virtualUserIndex].role ===
            entities.virtualEventUserEntity.role.roundTableHost
        ) {
            return;
        }
        const virtualEventUserId = virtualEventUsersClone[virtualUserIndex].id;
        const userId = virtualEventUsersClone[virtualUserIndex].UserId;
        virtualEventUsersClone.splice(virtualUserIndex, 1);

        mutationCtx.setVirtualEventSession({
            ...virtualEventSession,
            data: {
                ...virtualEventSession.data,
                VirtualEventUsers: virtualEventUsersClone,
            },
        });
        socket.emit('kickUser', { objectId: sessionId, userId, virtualEventUserId });
    };

    const onTurnMicOff = async virtualUserIndex => {
        const virtualEventUsersClone = [...virtualEventUsers];
        virtualEventUsersClone[virtualUserIndex] = {
            ...virtualEventUsersClone[virtualUserIndex],
            isMicrophoneOn: false,
        };
        const virtualEventUserId = virtualEventUsersClone[virtualUserIndex].id;

        mutationCtx.setVirtualEventSession({
            ...virtualEventSession,
            data: {
                ...virtualEventSession.data,
                VirtualEventUsers: virtualEventUsersClone,
            },
        });
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUserId,
                        isMicrophoneOn: false,
                    },
                ],
            },
        });
    };

    const isScreenSharing = screenShareStream && shareType === 'screen';
    const isVideoMode = virtualEventUser.data.mode === entities.virtualEventUserEntity.mode.video;
    const shouldDisplayActivePresenter = !isVideoMode || (isVideoMode && isScreenSharing);
    const virtualEventSessionStatus = virtualEventSession?.data?.status;
    const isRoomOpen =
        virtualEventSessionStatus === entities.virtualEventSessionEntity.status.broadcasting;

    return (
        <Wrapper isWindows={isWindows}>
            {shouldDisplayActivePresenter && (
                <ActivePresenterWrapper>
                    <ActivePresenter />
                </ActivePresenterWrapper>
            )}
            {virtualEventUsers && !virtualEventUsers.length && isRoomOpen && (
                <Text>No participants entered the room yet.</Text>
            )}
            <GridForUsers
                style={{
                    gridTemplateColumns: 'minmax(0, 1fr)  '.repeat(2),
                    gridAutoRows: '1fr',
                }}
            >
                {isRoomOpen &&
                    virtualEventUsers.map((virtualEventUserData, index) => (
                        <Presenter
                            key={index}
                            index={index}
                            virtualEventUserData={virtualEventUserData}
                            onRemove={() => onRemove(index)}
                            onTurnMicOff={() => onTurnMicOff(index)}
                        />
                    ))}
            </GridForUsers>
        </Wrapper>
    );
};

import { useLayoutEffect } from 'react';

export const useOutsideListener = ({ ref, onClickOutside }) => {
    useLayoutEffect(() => {
        const handleClickOutside = event => {
            try {
                if (!ref) {
                    return;
                }
                const refCurrent = ref && ref.current;
                if (ref.current && !refCurrent.contains(event.target)) {
                    onClickOutside();
                }
            } catch (e) {
                console.error(e);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleClickOutside);
        };
    }, [onClickOutside, ref]);
};

export default useOutsideListener;

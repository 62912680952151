import { Buffer } from 'buffer';

class OneSignalService {
    init(appId, safariWebId, subdomain) {
        this.OneSignal = window.OneSignal || [];
        this.appId = appId;
        this.isOneSignalSet = this.appId !== 'NOTENTERED' && !!this.appId;
        this.safariWebId = safariWebId;
        this.subdomain = subdomain;
    }

    setup() {
        if (!this.isOneSignalSet) {
            return;
        }

        this.OneSignal.push(() => {
            this.OneSignal.init({
                appId: this.appId,
                safari_web_id: this.safariWebId,
                subdomainName: this.subdomain,
                notificationClickHandlerMatch: 'origin',
                notificationClickHandlerAction: 'focus',
            });
        });
    }

    addOnDisplayEventListener(callback) {
        if (!this.isOneSignalSet) {
            return;
        }

        this.OneSignal.push(() => {
            this.OneSignal.on('notificationDisplay', callback);
        });
    }

    addOnOpenEventListener(callback) {
        if (!this.isOneSignalSet) {
            return;
        }

        this.OneSignal.push(['addListenerForNotificationOpened', callback]);
    }

    addEventTag(eventId) {
        if (!this.isOneSignalSet) {
            return;
        }

        this.OneSignal.push(() => {
            this.OneSignal.sendTag(`tag_${eventId}`, eventId);
        });
    }

    removeEventTag(eventId) {
        if (!this.isOneSignalSet) {
            return;
        }

        this.OneSignal.push(() => {
            this.OneSignal.deleteTag(`tag_${eventId}`);
        });
    }

    addUserTag(userId, eurekaUrl) {
        if (!this.isOneSignalSet) {
            return;
        }

        const userTag = `${userId}${eurekaUrl}`;
        const encodedUserTag = Buffer.from(userTag).toString('base64');
        this.OneSignal.push(() => {
            this.OneSignal.sendTag('tag_userId', encodedUserTag);
        });
    }

    removeUserTag() {
        if (!this.isOneSignalSet) {
            return;
        }

        this.OneSignal.push(() => {
            this.OneSignal.deleteTag('tag_userId');
        });
    }

    addMemberTag() {
        if (!this.isOneSignalSet) {
            return;
        }

        this.OneSignal.push(() => {
            this.OneSignal.sendTag('tag_is_member', 'is_member');
        });
    }

    removeMemberTag() {
        if (!this.isOneSignalSet) {
            return;
        }

        this.OneSignal.push(() => {
            this.OneSignal.deleteTag('tag_is_member');
        });
    }

    setExternalUserId(userId) {
        if (!this.isOneSignalSet) {
            return;
        }

        this.OneSignal.push(() => {
            this.OneSignal.setExternalUserId(userId);
        });
    }

    removeExternalUserId() {
        if (!this.isOneSignalSet) {
            return;
        }

        this.OneSignal.push(() => {
            this.OneSignal.removeExternalUserId();
        });
    }

    getExternalUserId() {
        this.OneSignal.push(() => {
            const id = this.OneSignal.getExternalUserId();
            console.log('External user id', id);
        });
    }

    showSubscribeWindow() {
        this.OneSignal.push(() => {
            this.OneSignal.showNativePrompt();
        });
    }

    isPushNotificationEnabled() {
        return new Promise(resolve => {
            this.OneSignal.push(() => {
                this.OneSignal.isPushNotificationsEnabled(isEnabled => {
                    resolve(isEnabled);
                });
            });
        });
    }
}

export default new OneSignalService();

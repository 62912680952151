import React, { useState, useEffect } from 'react';
import { useLocation, useRouteMatch, withRouter } from 'react-router-dom';
import { useTheme } from '../Theme/ThemeContext';
import * as AppStyle from './style/navigationStyle';
import { FontIcon, NavigationDrawer } from 'react-md';
import AuthButton from '../../scenes/User/containers/AuthButton';
import NavItemLink from './NavItemLink';
import EurekaHeader from '../EurekaHeader';
import { SOCIETY_COLORS } from '../General/Variables';
import useSocietySettings from '../../scenes/Society/hooks/useSocietySettings';
import { usePlatformInfo } from '../../scenes/User/components/platformInfo/PlatformInfoContextProvider';
import { getBlackOrWhiteCalculated } from '../../../src/components/General/Colors';
import toast from 'toasted-notes';
import PlatformInfoNotification from '../../scenes/User/components/platformInfo/PlatformInfoNotification';
const NAVIGATION_TYPES = {
    SIDE_MENU: 'sideMenu',
    TOP_NAVIGATION: 'iconMenu',
};

const SideNavigation = ({
    title,
    isMobile,
    visibleSideMenu,
    isSidepanelOpened,
    noScroll,
    onSideMenuVisibilityChange,
    renderContent,
    Container,
    navigation,
    navigationItemsMobile,
    navigationType,
    kiosk,
    setTitle,
    appState,

    history,
    pages,
}) => {
    const location = useLocation();
    const { isSociety } = useSocietySettings();
    const { theme } = useTheme();
    let appToolbarStyle = AppStyle.toolbarStyle;
    const [displayExpandButton, setDisplayExpandButton] = useState(false);
    const { pathname } = location;
    const isVirtualModerationSession = pathname.includes('/virtual-moderation');
    const displayAuthButton = !isSociety || !isVirtualModerationSession;
    const {
        newVersionAvailable,
        updateServiceWorker,
        onServiceWorkerUpdate,
        waitingWorker,
    } = usePlatformInfo();

    useEffect(() => {
        if (newVersionAvailable) {
            const elem = document.getElementById('update-version-notification');
            if (!elem) {
                toast.notify(
                    ({ onClose }) => (
                        <PlatformInfoNotification
                            onClose={onClose}
                            onUpdate={updateServiceWorker}
                            theme={theme}
                        />
                    ),
                    {
                        position: 'top-right',
                        duration: null,
                    },
                );
            }
        }
    }, [newVersionAvailable]);

    if (isSociety) {
        appToolbarStyle = {
            ...appToolbarStyle,
            backgroundColor: theme.primary,
            color: SOCIETY_COLORS.WHITE,
        };
    }

    const navItems = () => {
        if (
            navigationItemsMobile &&
            navigationItemsMobile.length &&
            isMobile &&
            navigationType === NAVIGATION_TYPES.TOP_NAVIGATION
        ) {
            const items = [];
            navigationItemsMobile.forEach(topNavItem => {
                if (topNavItem?.subItems?.length && topNavItem.kind !== 'tabs') {
                    topNavItem.subItems.forEach(it => {
                        const item = {
                            imageUrl: null,
                            materialIcon: { webappIcon: topNavItem.webappIcon },
                            pageId: topNavItem.pageId,
                            title: it.title,
                            to: `/${it.screenName}`,
                            type: topNavItem.kind,
                        };
                        items.push(item);
                    });
                } else {
                    const item = {
                        imageUrl: null,
                        materialIcon: { webappIcon: topNavItem.webappIcon },
                        pageId: topNavItem.pageId,
                        title: topNavItem.label,
                        to: `/${topNavItem.screen}`,
                        type: topNavItem.kind,
                    };
                    items.push(item);
                }

                return items;
            });

            return items.map((item, index) => {
                if (item.to) {
                    return (
                        <NavItemLink
                            key={`${item.type}_${index}`}
                            {...item}
                            item={item}
                            visibleFullSideMenu={visibleSideMenu}
                            index={index}
                        />
                    );
                }
            });
        }

        let nav = navigation.slice(0);
        // if the app is in fullscreen, remove the user navigation items
        if (kiosk) {
            const removeActions = [
                'signout',
                'signin',
                'myconversations',
                'mynotes',
                'myprogramme',
                'myfavorites',
                'attendee_list',
                'attendees',
                'eventwall',
                'social_feed',
            ];
            if (pages && pages.length) {
                pages.forEach(item => {
                    if (nav && nav.length && nav.find(navElem => navElem.to === `/${item.name}`)) {
                        if (!!item.accessRestrictions || !!item.isPasswordProtected) {
                            removeActions.push(item.name);
                        }
                    }
                });
            }
            nav = nav.filter(item => {
                return removeActions.indexOf(item.screen) === -1;
            });
        }

        return nav.map((item, index) => {
            if (item.to || item.screen) {
                if (!item.to) {
                    item.to = `/${item.screen}`;
                }
                return (
                    <NavItemLink
                        key={`${item.type}_${index}`}
                        isSociety={isSociety}
                        {...item}
                        item={item}
                        setTitle={setTitle}
                        visibleFullSideMenu={visibleSideMenu}
                        index={index}
                    />
                );
            }
        });
    };

    const navMenu = pages => {
        const elements = navItems(pages);
        elements.unshift(<EurekaHeader key="EurekaHeader" />);
        elements.push(<AppStyle.Margin key="margin" />);
        return elements;
    };
    const { url } = useRouteMatch();

    return (
        <Container
            id={'main-container'}
            onMouseEnter={() => setDisplayExpandButton(true)}
            onMouseLeave={() => setDisplayExpandButton(false)}
            toolbarTitle={!isMobile && title}
            toolbarTitleStyle={AppStyle.toolbarTitleStyle}
            toolbarStyle={appToolbarStyle}
            toolbarZDepth={0}
            tabletDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT}
            mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
            desktopDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT}
            toolbarThemeType="colored"
            toolbarActions={
                displayAuthButton && (
                    <AuthButton
                        newVersionAvailable={newVersionAvailable}
                        updateServiceWorker={updateServiceWorker}
                        onServiceWorkerUpdate={onServiceWorkerUpdate}
                        waitingWorker={waitingWorker}
                    />
                )
            }
            primary={theme.primary}
            contrastBlackOrWhite={getBlackOrWhiteCalculated(theme.primary)}
            contentId="page-content"
            drawerHeader={
                <AppStyle.DrawerHeader alignItems={'center'}>
                    {(appState?.pageHeader || appState?.societyLogoUrl) && (
                        <AppStyle.EventIcon
                            src={appState.pageHeader || appState.societyLogoUrl}
                            onClick={() => history.push(url)}
                        />
                    )}
                    {visibleSideMenu && (appState?.eventTitle || appState.appName) && (
                        <div>{isSociety ? `${appState.appName} - Event` : appState.eventTitle}</div>
                    )}
                    {displayExpandButton && (
                        <AppStyle.ExpandButton
                            expanded={visibleSideMenu}
                            primary={theme.primary}
                            isMobile={isMobile}
                            onClick={onSideMenuVisibilityChange}
                        >
                            <FontIcon iconClassName={'material-icons material-icons-round'}>
                                chevron_right
                            </FontIcon>
                        </AppStyle.ExpandButton>
                    )}
                </AppStyle.DrawerHeader>
            }
            contentStyle={{
                width: isSidepanelOpened
                    ? visibleSideMenu
                        ? 'calc(100vw - 497px - 258px)'
                        : 'calc(100vw - 573px)'
                    : 'inherit',
                marginLeft: isMobile ? 'unset' : !visibleSideMenu ? '74px' : '260px',
                transition: 'all 0.2s linear',
                minHeight: 'auto',
                overflowY: noScroll ? 'hidden' : 'auto',
                paddingBottom: isMobile ? '56px' : '0',
            }}
            persistentIcon={
                <FontIcon iconClassName={'material-icons material-icons-round'}>menu</FontIcon>
            }
            contentClassName="main-content"
            navItems={navMenu()}
            navStyle={{
                height: '100%',
                width: '100%',
                'overflow-x': 'visible',
                'overflow-y': 'scroll',
            }}
            desktopMinWidth={1300}
            railnavigation={!isMobile && !visibleSideMenu ? 'true' : undefined}
            visible={isMobile ? !!visibleSideMenu : true}
            onVisibilityChange={onSideMenuVisibilityChange}
        >
            {renderContent()}
        </Container>
    );
};

export default withRouter(SideNavigation);

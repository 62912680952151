import React, { useEffect, useState } from 'react';
import { addFavoriteForEvent, isFavoriteById, removeFavorite } from '../../services/api/data';
import Bookmark from '../General/Bookmark';
import { ImageAvatar } from '../Icons';
import ThemeContext from '../Theme/ThemeContext';
import * as eureka from '../../services/api/eureka';
import eventBus from '../../utils/eventBus';
import AnalyticsService from '../../features/analytics/services';
import ListCard from '../General/ListCard';
import { getImageSource } from '../../services/api/db';
import largePlaceholderImage from '../../assets/images/placeholder-institution-large.png';
import smallPlaceholderImage from '../../assets/images/placeholder-institution-small.png';
import { StyledListItem } from './style/index';
import {
    AchievementType,
    useAchievementActions,
} from '../../scenes/Achievements/hooks/useAchievementActions';

const InstitutionListItem = ({ institution, cardView }) => {
    const [favoriteStatus, setFavoriteStatus] = useState(institution.favorite ? 'checked' : '');
    const [cardImage, setCardImage] = useState(null);
    const { trackAchievement } = useAchievementActions();

    useEffect(() => {
        onFavorite();

        if (institution.params && institution.params.isLargeCard && institution.params.cardImage) {
            getImageSource(institution.params.cardImage, (err, img) => {
                setCardImage(img);
            });
        }
    }, [institution.id]);

    const highlighted = institution.params && !!institution.params.highlighted;

    const onFavorite = event => {
        if (event) {
            event.preventDefault();

            const _favoriteStatus = favoriteStatus === 'checked' ? '' : 'checked';
            setFavoriteStatus(_favoriteStatus);

            if (_favoriteStatus) {
                addFavoriteForEvent(institution, () => {
                    AnalyticsService.addSample('favorite', true, institution.id);
                    trackAchievement(AchievementType.FAVORITE, institution.id);

                    eureka.sync(() => {
                        eventBus.emit('updateMyFavoritesCount');
                    });
                });
            } else {
                removeFavorite(institution, () => {
                    AnalyticsService.addSample('favorite', false, institution.id);

                    eureka.sync(() => {
                        eventBus.emit('updateMyFavoritesCount');
                    });
                });
            }
        } else {
            isFavoriteById(institution.id, (err, isFavorite) => {
                if (isFavorite) {
                    setFavoriteStatus('checked');
                }
            });
        }
    };

    if (cardView) {
        const isLargeCard = institution.params && institution.params.isLargeCard;
        const imageUrl = isLargeCard ? cardImage : institution.imageUrl;
        const placeholderImage = isLargeCard ? largePlaceholderImage : smallPlaceholderImage;

        return (
            <ListCard
                key={'institution_' + institution.id}
                primaryText={institution.name}
                secondaryText={institution.subNameList}
                imageUrl={imageUrl || placeholderImage}
                isLogo={!isLargeCard}
                type={'institutions'}
                rightIcon={
                    <Bookmark favoriteStatus={favoriteStatus} clickMyCongress={onFavorite} />
                }
            />
        );
    }

    return (
        <ThemeContext.Consumer>
            {({ theme }) => {
                return (
                    <StyledListItem
                        key={'institution_' + institution.id}
                        primaryText={institution.name}
                        secondaryText={institution.subNameList}
                        highlighted={highlighted ? highlighted.toString() : undefined}
                        color={theme.textSponsor || 'inherit'}
                        leftAvatar={
                            <ImageAvatar
                                key={'institution_' + institution.id}
                                imageId={institution.imageUrl || institution.image}
                                avatarType={'institutions'}
                            />
                        }
                    >
                        <Bookmark favoriteStatus={favoriteStatus} clickMyCongress={onFavorite} />
                    </StyledListItem>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export default InstitutionListItem;

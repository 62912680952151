import React, { useCallback, useEffect, useRef, useState } from 'react';
import { inRange } from 'lodash';

import styles from './styles';
import Loader from '../../../virtualFeature/components/common/Loader';
import ScrollDownButton from '../ScrollDownButton';
import ChatMessages from './ChatMessages';
import usePrevious from '../../hooks/usePrevious';
import useMessageKeyNavigation from '../../../../../../hooks/useMessageKeyNavigation';

const SCROLL_OFFSET = 3;

const ChatFeed = ({
    messages,
    bubbleStyles,
    chatBubble,
    maxHeight,
    loadPreviousMessages,
    isLoadingMessages,
    chatRoomId,
    onSetMessageToDelete,
    messageHasBeenDeleted,
    nonVirtualQA,
}) => {
    const [buttonHidden, setButtonHidden] = useState(true);
    const [messagesContainerHeight, setMessagesContainerHeight] = useState(0);
    const messagesEnd = useRef();
    const atBottom = useRef();
    const chatRef = useRef();
    const prevMessages = usePrevious(messages);

    let storedPosition = 0;
    let scrollingUp = false;

    useMessageKeyNavigation({ messages: messages, onBackspaceKeyDown: onSetMessageToDelete });

    const handleScroll = event => {
        const { clientHeight, scrollHeight, scrollTop } = event.target;

        if (scrollHeight !== messagesContainerHeight) {
            setMessagesContainerHeight(scrollHeight);
        }

        const clientPercentage = clientHeight * 0.15;
        const difference = scrollHeight - (scrollTop + clientHeight);
        atBottom.current = inRange(
            scrollHeight - scrollTop,
            clientHeight - SCROLL_OFFSET,
            clientHeight + SCROLL_OFFSET,
        );
        if (atBottom.current) {
            setButtonHidden(true);
        } else if (difference >= clientPercentage) {
            setButtonHidden(false);
        }

        scrollingUp = scrollTop < storedPosition;
        storedPosition = scrollTop;
        if (scrollTop < 10 && !isLoadingMessages && scrollingUp) {
            if (loadPreviousMessages) {
                loadPreviousMessages();
            }
        }
    };

    const scrollToBottom = fromButton => {
        const scrollObj = fromButton ? { behavior: 'smooth' } : {};
        if (messagesEnd.current) {
            messagesEnd.current.scrollIntoView(scrollObj);
        }
    };

    useEffect(() => {
        if (atBottom.current || atBottom.current === undefined) {
            scrollToBottom();
        }
    }, [messages]);

    useEffect(() => {
        scrollToBottom(false);
    }, [messageHasBeenDeleted]);

    useEffect(() => {
        if (
            !chatRef.current ||
            messagesContainerHeight === 0 ||
            messages.length - prevMessages.length === 1
        ) {
            return;
        }

        chatRef.current.scrollTo({ top: chatRef.current.scrollHeight - messagesContainerHeight });
    }, [messages]);

    return (
        <div id="chat-panel" style={styles.chatPanel}>
            <div
                className="chat-history"
                style={{ ...styles.chatHistory, maxHeight }}
                onScroll={handleScroll}
                ref={chatRef}
            >
                {isLoadingMessages && <Loader />}
                <div className="chat-messages">
                    <ChatMessages
                        messages={messages}
                        chatBubble={chatBubble}
                        bubbleStyles={bubbleStyles}
                        chatRoomId={chatRoomId}
                        onSetMessageToDelete={onSetMessageToDelete}
                        nonVirtualQA={nonVirtualQA}
                    />
                </div>
                <div ref={messagesEnd} style={{ float: 'left', clear: 'both' }} />
                <ScrollDownButton
                    onClick={() => {
                        scrollToBottom(true);
                    }}
                    hidden={buttonHidden}
                />
            </div>
        </div>
    );
};

export default ChatFeed;

import React from 'react';
import {
    ButtonsContainer,
    Card,
    CloseCardIcon,
    EurekaAvatar,
    Subtitle,
    Title,
} from '../style/signIn';
import * as string from '../constants/strings';
import { getString } from '../../../services/api/store';
import Button, { buttonTypes } from '../../common/Button';
import * as palette from '../../../components/General/Variables';
import { Flex } from '../../common/Flex';

const ImageSuccesfullyAdded = ({
    theme,
    goTo,
    profileImage,
    eurekaOnly,
    attendingOnly,
    onClose,
    isModal,
}) => {
    const IMAGE_ADDED_TITLE_TEXT = getString('profileEditAvatarDone') || 'Profile image added';
    const CHANGE_IMAGE_FROM_PROFILE_TEXT =
        getString('profileEditAvatarLater') || string.CHANGE_IMAGE_FROM_PROFILE_TEXT;
    const CONTINUE_BUTTON_LABEL =
        getString('generalContinueButton') || string.CONTINUE_BUTTON_LABEL;
    return (
        <Card isModal={isModal}>
            <Flex alignItems={'center'} justifyContent={'center'}>
                <Title>{IMAGE_ADDED_TITLE_TEXT}</Title>
                {!eurekaOnly && !attendingOnly && (
                    <CloseCardIcon onClick={onClose}>{palette.ICON_CLOSE}</CloseCardIcon>
                )}
            </Flex>
            <Subtitle>{CHANGE_IMAGE_FROM_PROFILE_TEXT}</Subtitle>
            <EurekaAvatar src={profileImage} ariarole="profileImage"></EurekaAvatar>
            <ButtonsContainer primarycolor={theme.primary}>
                <Button
                    type={buttonTypes.GREEN_LONG}
                    background={theme.primary}
                    text={CONTINUE_BUTTON_LABEL}
                    onClick={() => goTo('attendeesSettings')}
                    data-qa="button-continue-profile-image-saved"
                />
            </ButtonsContainer>
        </Card>
    );
};

export default ImageSuccesfullyAdded;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as palette from './Variables';
import { Divider, FontIcon } from 'react-md';
import { List, ListItem } from 'react-md';
import IconButton from '@mui/material/IconButton';

const ExpandIcon = styled(FontIcon)`
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 32px !important;
`;

const DropdownContainer = styled.div`
    display: inline-block;
    cursor: pointer;
`;

const DropdownList = styled(({ isExpanded, ...rest }) => <List {...rest} />)`
    display: ${props => (props.isExpanded ? 'block' : 'none')};
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 36px;
    right: 0;
    text-align: center;
    margin-top: 10px;
`;

const DropdownItem = styled(ListItem)`
    color: #000;
    text-align: left;

    &:hover {
        background-color: ${palette.COLOR_LIGHT_GREY};
    }
`;

const DROPDOWN_CONTAINER_ID = 'dropdown-container';

const Dropdown = ({ items }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        const rootElement = document.getElementById('root');

        rootElement.addEventListener('click', handleClick);

        return () => rootElement.removeEventListener('click', handleClick);
    }, [isExpanded]);

    const handleClick = e => {
        const { id } = e.target;

        if (id !== DROPDOWN_CONTAINER_ID && isExpanded) {
            toggleIsExpanded();
        }
    };

    return (
        <IconButton onClick={toggleIsExpanded}>
            <ExpandIcon>more_horiz</ExpandIcon>
            <DropdownContainer id={DROPDOWN_CONTAINER_ID}>
                <DropdownList isExpanded={isExpanded}>
                    {items.map(item =>
                        item.divier ? (
                            <Divider />
                        ) : (
                            <DropdownItem
                                key={`dropdown-item-${item.text}`}
                                onClick={item.onClick}
                                primaryText={item.text}
                                leftIcon={
                                    item.icon ? (
                                        <FontIcon disabled={item.disabled}>{item.icon}</FontIcon>
                                    ) : null
                                }
                                disabled={item.disabled}
                            />
                        ),
                    )}
                </DropdownList>
            </DropdownContainer>
        </IconButton>
    );
};

export default Dropdown;

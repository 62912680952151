import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { getIsGamificationEnabled } from '../selectors';
import { AchievementType, useAchievementActions } from './useAchievementActions';
import Auth from '../../../services/api/auth';
import { useGlobalState } from '../../../utils/container';

const useExpertAchievementTimeTracking = item => {
    const stateCtx = useGlobalState();
    const { virtualEventSession } = stateCtx;
    const isBroadcasting = virtualEventSession && virtualEventSession.status === 'broadcasting';
    const { trackAchievement } = useAchievementActions();
    const isGamificationEnabled = useSelector(getIsGamificationEnabled);

    useEffect(() => {
        const user = Auth.getUser();

        if (!user || !isGamificationEnabled || !item) {
            return;
        }

        const { id, start, end } = item;

        const duration = moment(end).diff(moment(start), 'minutes');
        const trigger = Math.floor(duration * 0.9);

        const timeElapsedForUser =
            JSON.parse(localStorage.getItem('TIME_IN_VIRTUAL_SESSION')) || {};
        const virtualSessionsTimeElapsed = timeElapsedForUser[user.id] || {};
        let timeElapsed = virtualSessionsTimeElapsed[id] || 0;

        if (timeElapsed >= trigger) {
            return;
        }

        const interval = setInterval(() => {
            timeElapsed += 1;

            localStorage.setItem(
                'TIME_IN_VIRTUAL_SESSION',
                JSON.stringify({
                    ...timeElapsedForUser,
                    [user.id]: {
                        ...virtualSessionsTimeElapsed,
                        [id]: timeElapsed,
                    },
                }),
            );

            if (timeElapsed === trigger) {
                trackAchievement(AchievementType.COMPLETE_VIRTUAL_SESSION, id);
                clearInterval(interval);
            }
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, [isGamificationEnabled, trackAchievement, item, isBroadcasting]);
};

export default useExpertAchievementTimeTracking;

import React, { useState, useEffect } from 'react';

import { saveQuestion, deleteQuestion } from '../../../../services/QuestionsAndAnswersService';
import ConfirmationDialog from '../../../common/ConfirmatonDialog';
import {
    CancelModalButton,
    DestructiveModalButton,
    SaveModalButton,
    ContentModalContainer,
    StyledTextArea,
} from '../../common/styles';

const QuestionModal = ({ open, handleClose, question, editModalType, handleSubmit }) => {
    const [questionText, setQuestionText] = useState('');

    useEffect(() => {
        if (question) {
            setQuestionText(question.question);
        }
    }, [question]);

    const onChange = ({ target }) => setQuestionText(target.value);

    const onSubmit = async () => {
        if (editModalType) {
            await saveQuestion({
                ...question,
                question: questionText,
            });
            setQuestionText('');
        } else {
            await deleteQuestion(question);
        }

        handleSubmit();

        handleClose();
    };

    return (
        <>
            {editModalType ? (
                <div className="eureka-react">
                    <ConfirmationDialog
                        open={open}
                        title="Edit question"
                        withCloseButton
                        onClose={handleClose}
                        content={
                            <StyledTextArea
                                id="question"
                                label="Edit question"
                                type="text"
                                onChange={onChange}
                                value={questionText}
                            />
                        }
                        buttons={[
                            <SaveModalButton key="SMBS" flat onClick={onSubmit}>
                                Save
                            </SaveModalButton>,
                            <CancelModalButton key="CMBC" flat onClick={handleClose}>
                                Cancel
                            </CancelModalButton>,
                        ]}
                    />
                </div>
            ) : (
                <div className="eureka-react">
                    <ConfirmationDialog
                        open={open}
                        title="Delete question"
                        titleIcon="delete_forever"
                        iconWrapperColor="#EE1045"
                        withCloseButton
                        onClose={handleClose}
                        content={
                            <ContentModalContainer>
                                Are you sure you want to delete this question?
                            </ContentModalContainer>
                        }
                        buttons={[
                            <DestructiveModalButton key="DMBD" flat onClick={onSubmit}>
                                Delete
                            </DestructiveModalButton>,
                            <CancelModalButton key="CMBC" flat onClick={handleClose}>
                                Cancel
                            </CancelModalButton>,
                        ]}
                    />
                </div>
            )}
        </>
    );
};

export default QuestionModal;

import React, { memo, useEffect, useState } from 'react';
import ClassifierIcon from './ClassifierIcon';
import { FontIcon } from 'react-md';
import styled from 'styled-components';
import * as palette from '../General/Variables';
import { getItemAsync } from '../../services/api/graphQlRepository';
import Tooltip from '@mui/material/Tooltip';

const IconsContainer = styled.div`
    margin: ${props =>
            props.programmeItem || props.inlist
                ? 'unset'
                : props.hasIcon || props.transparent
                ? '10px'
                : '16px'}
        0 0 0;
`;

const ClassifierMaterialIcon = styled.div`
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 0;
    border-radius: 50px;
    width: ${props => props.iconSize || '20px'};
    height: ${props => props.iconSize || '20px'};
    margin-right: 6px;
    position: relative;

    i {
        font-size: ${props => (props.label ? '20px' : '16px')} !important;
    }

    ${props =>
        props.classifiercolor
            ? `
             background-color: ${props.classifiercolor};
             i {
                color: ${palette.COLOR_WHITE} !important;
             }
        `
            : `
           ${!props.label && 'border: solid 1px #ddd'};
            i {
                color: rgba(0, 0, 0, 0.6) !important;
            }
               `}
`;

const ClassifierBullet = styled.div`
    width: 20px;
    height: 20px;
    background-color: ${props => props.classifiercolor};
    border-radius: 50%;
    margin-right: 6px;
    position: relative;
`;

const Container = styled.div`
    width: fit-content;
    height: fit-content;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 4px 12px;
    border-radius: 50px;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    margin-right: 8px;
    margin-bottom: 8px;

    ${props =>
        props.displayFullName
            ? ``
            : `
         span {
            overflow: hidden;
            max-width: 25ch;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
        }
    `};

    ${props =>
        props.classifiercolor
            ? `
        background-color: ${props.classifiercolor};
         color: ${palette.COLOR_WHITE};
         i {
            color: ${palette.COLOR_WHITE} !important;
         }
    `
            : `
           border: solid 1px #ddd;
         color: rgba(0, 0, 0, 0.6);
    `};
`;

const CloseButton = styled(FontIcon)`
    cursor: pointer;
`;

const getClassifier = async id => {
    const cl = await getItemAsync('classifier', id);
    return cl;
};

const Icon = ({
    params,
    iconSize,
    label,
    classifierColor,
    tooltip,
    displayTransparentForMissedColor,
}) => {
    return (
        <Tooltip title={tooltip}>
            <div>
                {params?.icon && !params?.icon?.webappIcon && (
                    <ClassifierIcon key={params.icon} imageId={params.icon} size={iconSize} />
                )}
                {params?.icon?.webappIcon && (
                    <ClassifierMaterialIcon
                        classifiercolor={classifierColor}
                        iconSize={iconSize}
                        label={label}
                    >
                        <FontIcon iconClassName={'material-icons material-icons-round'}>
                            {params?.icon?.webappIcon}
                        </FontIcon>
                    </ClassifierMaterialIcon>
                )}
                {(classifierColor || displayTransparentForMissedColor) && !params?.icon && (
                    <ClassifierBullet
                        classifiercolor={
                            classifierColor || (displayTransparentForMissedColor && 'transparent')
                        }
                    />
                )}
            </div>
        </Tooltip>
    );
};

const ClassifierIconLabelComponent = ({
    params,
    iconSize,
    label,
    programmeItem,
    onClose,
    inlist,
    classifierId,
    displayFullName,
    displayTransparentForMissedColor,
    defaultTooltip,
}) => {
    const [currentClassifierColor, setCurrentClassifierColor] = useState('');
    const [currentParams, setCurrentParams] = useState(params);
    const [tooltip, setTooltip] = useState(defaultTooltip);
    let classifierColor = params?.color || '';

    useEffect(() => {
        (async () => {
            if (!label && classifierId) {
                const cls = await getClassifier(classifierId);
                setTooltip(cls?.name);
                if (cls && cls.params) {
                    setCurrentParams(cls.params);
                    setCurrentClassifierColor(cls.params.color);
                }
            }
        })();
    }, [classifierId]);

    return (
        <>
            {label ? (
                <Container
                    classifiercolor={
                        params?.icon && !params?.icon?.webappIcon ? '' : classifierColor
                    }
                    key={params}
                    displayFullName={displayFullName}
                >
                    {params?.icon && (
                        <Icon
                            params={params}
                            iconSize={iconSize}
                            label={label}
                            classifierColor={classifierColor}
                        />
                    )}
                    <span>{label}</span>
                    {onClose && <CloseButton onClick={onClose}>close</CloseButton>}
                </Container>
            ) : (
                <IconsContainer
                    hasIcon={currentParams?.icon}
                    programmeItem={programmeItem}
                    key={currentParams}
                    inlist={inlist}
                    transparent={displayTransparentForMissedColor}
                >
                    <Icon
                        params={currentParams}
                        iconSize={iconSize}
                        label={label}
                        tooltip={tooltip}
                        classifierColor={
                            currentParams?.icon
                                ? currentClassifierColor
                                : currentParams?.color || classifierColor
                        }
                        displayTransparentForMissedColor={displayTransparentForMissedColor}
                    />
                </IconsContainer>
            )}
        </>
    );
};

export default memo(ClassifierIconLabelComponent);

import styled from 'styled-components';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { Button } from 'react-md';

import { ContentModalContainer } from '../../virtualFeature/components/moderator/common/styles';

export const ButtonContainer = styled.div`
    position: absolute;
    top: 16px;
    left: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1;
`;

export const PlayIcon = styled(PlayArrowIcon)`
    color: #fff;
    margin-right: 8px;
`;

export const StyledStopIcon = styled(StopIcon)`
    color: #fff;
    margin-right: 8px;
`;

export const StyledButton = styled(Button)`
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    color: #fff !important;
    height: 40px !important;
    width: 133px !important;
    background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : '#1fa294'} !important;
    border: none;
    font-family: Cabin, sans-serif;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    border-radius: 6px !important;
    font-size: 14px !important;
    text-transform: none !important;
    padding: 0 0 0 10px !important;
    margin-right: 8px;
`;

export const GreyStyledButton = styled(Button)`
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    color: rgba(0, 0, 0, 0.87) !important;
    height: 40px !important;
    width: 112px !important;
    background-color: #f1f1f3 !important;
    border: none;
    font-family: Cabin, sans-serif;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    border-radius: 6px !important;
    font-size: 14px !important;
    text-transform: none !important;
    padding: 0 0 0 10px !important;
`;

export const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    padding: 0 25px;
    margin-bottom: -3px;
`;

import { useVMState } from '../../virtualFeature/containers/main';
import { useEffect, useRef, useState } from 'react';
import { getPollSetByExternalObject } from '../services/PollingService';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';

const usePollSet = () => {
    const stateCtx = useVMState();
    const { socket, sessionId, externalObject } = stateCtx;
    const [pollSet, setPollSet] = useState(null);
    const pollSetValue = useRef(null);
    const changeFromPollsActiveRef = useRef(true);

    const changePollSet = currentPoll => {
        pollSetValue.current = currentPoll;
        setPollSet(currentPoll);
    };

    const fetchPollSet = throttle(async data => {
        if (!data) {
            const response = await getPollSetByExternalObject(externalObject.data.id);

            data = {
                pollSet: response,
            };
        }

        if (!isEqual(data.pollSet, pollSetValue.current)) {
            changePollSet(data.pollSet);
        }
    }, 500);

    useEffect(() => {
        (async () => {
            await fetchPollSet();

            socket.on(`updatePollSet_${sessionId}`, fetchPollSet);
            socket.on(`pollAnswer_${sessionId}`, fetchPollSet);
            socket.on(`pollRefresh_${sessionId}`, fetchPollSet);
        })();
        return () => {
            if (socket && socket.removeAllListeners) {
                socket.removeAllListeners(`pollAnswer_${sessionId}`);
                socket.removeAllListeners(`pollRefresh_${sessionId}`);
                socket.removeAllListeners(`updatePollSet_${sessionId}`);
            }
        };
    }, []);

    return {
        pollSet,
        changeFromPollsActiveRef,
    };
};

export default usePollSet;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getPLacesByFloorplan } from '../services/floorplan';
import * as palette from '../../../components/General/Variables.js';
import Marker from './marker';
import { Button } from 'react-md';
import ThemeContext from '../../../components/Theme/ThemeContext';
import { withRouter, generatePath } from 'react-router-dom';

const SNAP_TOLERANCE = 0.001;
const OVER_TRANSFORMATION_TOLERANCE = 0.05;
const DOUBLE_TAP_THRESHOLD = 300;
const ANIMATION_SPEED = 0.1;

const MapContainer = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

const Sticky = styled.div`
    position: sticky;
    top: 50px;
    z-index: 10;
    margin-bottom: -70px;
    margin-left: calc(100% - 78px);
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        margin-left: calc(100% - 70px);
    }
`;

const SearchButton = styled(Button)`
    background-color: ${props => props.bgcolor};
    color: ${palette.COLOR_WHITE};
    box-shadow: ${palette.ELEVATION};
    border-radius: 2px;
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
    }
`;

const ZoomButtonBox = styled.div`
    position: absolute;
    bottom: 50px;
    height: 96px;
    right: 30px;
    z-index: 10;
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        height: 80px;
    }
`;

const ZoomButton = styled(Button)`
    position: absolute;
    box-shadow: ${palette.ELEVATION};
    outline: 2px solid ${props => props.bordercolor};
    border-radius: 0px;
    top: 48px;
    right: 0px;
    background-color: rgba(255, 255, 255, 0.9);
    color: ${props => props.color};
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        top: 40px;
    }
    &:focus {
        outline: 2px solid ${props => props.bordercolor};
    }
`;

const ZoomButtonPlus = styled(ZoomButton)`
    top: 0px;
    color: ${props => props.bordercolor};
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        top: 0px;
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

const ClickableArea = styled.div`
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    position: absolute;
    left: ${props => props.x}%;
    top: ${props => props.y}%;
    transform: translate(-50%, -50%);
    cursor: pointer;
`;

const snapToTarget = (value, target, tolerance) => {
    const withinRange = Math.abs(target - value) < tolerance;
    return withinRange ? target : value;
};

const rangeBind = (lowerBound, upperBound, value) =>
    Math.min(upperBound, Math.max(lowerBound, value));

const invert = value => value * -1;

const getRelativePosition = ({ clientX, clientY }, relativeToElement) => {
    const rect = relativeToElement.getBoundingClientRect();
    return {
        x: clientX - rect.left,
        y: clientY - rect.top,
    };
};

const getMidpoint = (pointA, pointB) => ({
    x: (pointA.x + pointB.x) / 2,
    y: (pointA.y + pointB.y) / 2,
});

const getDistanceBetweenPoints = (pointA, pointB) =>
    Math.sqrt(Math.pow(pointA.y - pointB.y, 2) + Math.pow(pointA.x - pointB.x, 2));

class PinchZoomPan extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            places: [],
        };
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseWheel = this.handleMouseWheel.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.handleImageLoad = this.handleImageLoad.bind(this);
        this.renderClickableArea = this.renderClickableArea.bind(this);
    }

    //event handlers
    handleTouchStart(event) {
        this.animation && cancelAnimationFrame(this.animation);
        const touches = event.touches;
        if (touches.length === 2) {
            this.pinchStart(touches);
            this.lastPanPointerPosition = null;
        } else if (touches.length === 1) {
            this.pointerDown(touches[0]);
        }
    }

    handleTouchMove(event) {
        const touches = event.touches;
        if (touches.length === 2) {
            //suppress viewport scaling
            event.preventDefault();
            this.pinchChange(touches);
        } else if (touches.length === 1) {
            const swipingDown = this.pan(touches[0]) > 0;
            if (swipingDown && this.state.top < 0) {
                //suppress pull-down-refresh since swiping down will reveal the hidden overflow of the image
                event.preventDefault();
            }
        }
    }

    handleTouchEnd(event) {
        if (event.touches && event.touches.length > 0) return null;

        //We allow transient +/-5% over-pinching.
        //Animate the bounce back to constraints if applicable.
        this.ensureValidTransform(ANIMATION_SPEED);

        this.pointerUp(event.timeStamp);

        //suppress mouseUp, in case of tap
        event.preventDefault();
    }

    handleMouseDown(event) {
        this.animation && cancelAnimationFrame(this.animation);
        this.mouseDown = true;
        this.pointerDown(event);
    }

    handleMouseMove(event) {
        if (!this.mouseDown) return null;
        this.pan(event);
    }

    handleMouseUp(event) {
        this.pointerUp(event.timeStamp);
        if (this.mouseDown) {
            this.mouseDown = false;
        }
    }

    handleMouseWheel(event) {
        this.animation && cancelAnimationFrame(this.animation);
        const point = getRelativePosition(event, this.container);
        if (event.deltaY > 0) {
            if (this.state.scale > this.minScale) {
                this.zoomOut(point);
                event.preventDefault();
            }
        } else if (event.deltaY < 0) {
            if (this.state.scale < this.props.maxScale) {
                this.zoomIn(point);
                event.preventDefault();
            }
        }
    }

    handleWindowResize(event) {
        this.props.resize();
        this.ensureConstraints();
    }

    handleImageLoad() {
        this.props.process({
            imageRatio: this.image.height / this.image.width,
        });
        this.ensureConstraints();
    }

    //actions
    pointerDown(clientPosition) {
        this.lastPanPointerPosition = getRelativePosition(clientPosition, this.container);
    }

    pan(pointerClientPosition) {
        const pointerPosition = getRelativePosition(pointerClientPosition, this.container);
        const translateX = pointerPosition.x - this.lastPanPointerPosition.x;
        const translateY = pointerPosition.y - this.lastPanPointerPosition.y;
        const top = this.state.top + translateY;
        const left = this.state.left + translateX;

        //use 0 tolerance to prevent over-panning (doesn't look good)
        this.move(top, left, 0);
        this.lastPanPointerPosition = pointerPosition;
        return translateY > 0
            ? 1 //swiping down
            : translateY < 0
            ? -1 //swiping up
            : 0;
    }

    pointerUp(timeStamp) {
        if (
            this.lastPointerUpTimeStamp &&
            this.lastPointerUpTimeStamp + DOUBLE_TAP_THRESHOLD > timeStamp
        ) {
            //reset
            this.transformToProps(ANIMATION_SPEED);
        }

        this.lastPointerUpTimeStamp = timeStamp;
    }

    move(top, left, tolerance, speed = 0) {
        this.applyTransform(top, left, this.state.scale, tolerance, speed);
    }

    pinchStart(touches) {
        const pointA = getRelativePosition(touches[0], this.container);
        const pointB = getRelativePosition(touches[1], this.container);
        this.lastPinchLength = getDistanceBetweenPoints(pointA, pointB);
    }

    pinchChange(touches) {
        const pointA = getRelativePosition(touches[0], this.container);
        const pointB = getRelativePosition(touches[1], this.container);
        const length = getDistanceBetweenPoints(pointA, pointB);
        const scale = (this.state.scale * length) / this.lastPinchLength;
        const midpoint = getMidpoint(pointA, pointB);

        this.zoom(scale, midpoint, OVER_TRANSFORMATION_TOLERANCE);

        this.lastPinchMidpoint = midpoint;
        this.lastPinchLength = length;
    }

    zoomIn(midpoint, fromButton) {
        midpoint = midpoint || {
            x: this.container.offsetWidth / 2,
            y: this.container.offsetHeight / 2,
        };
        this.zoom(this.state.scale * (fromButton ? 1.2 : 1.05), midpoint, 0);
    }

    zoomOut(midpoint, fromButton) {
        midpoint = midpoint || {
            x: this.container.offsetWidth / 2,
            y: this.container.offsetHeight / 2,
        };
        this.zoom(this.state.scale * (fromButton ? 0.8 : 0.95), midpoint, 0);
    }

    zoom(scale, midpoint, tolerance, speed = 0) {
        scale = this.getValidTransform(0, 0, scale, tolerance).scale;

        const incrementalScalePercentage = (this.state.scale - scale) / this.state.scale;
        const translateY = (midpoint.y - this.state.top) * incrementalScalePercentage;
        const translateX = (midpoint.x - this.state.left) * incrementalScalePercentage;

        const top = this.state.top + translateY;
        const left = this.state.left + translateX;

        this.applyTransform(top, left, scale, tolerance, speed);
    }

    //state validation and transformation methods
    applyTransform(requestedTop, requestedLeft, requestedScale, tolerance, speed = 0) {
        const { top, left, scale } = this.getValidTransform(
            requestedTop,
            requestedLeft,
            requestedScale,
            tolerance,
        );

        if (this.state.scale === scale && this.state.top === top && this.state.left === left) {
            return;
        }

        let values = {};
        if (this.image && this.image.height && this.image.width) {
            values.originalHeight = this.image.height;
            values.originalWidth = this.image.width;
            values.ratio = this.image.height / this.image.width;
        }

        if (speed > 0) {
            const frame = () => {
                const translateY = top - this.state.top;
                const translateX = left - this.state.left;
                const translateScale = scale - this.state.scale;

                const nextTransform = {
                    top: snapToTarget(this.state.top + speed * translateY, top, SNAP_TOLERANCE),
                    left: snapToTarget(this.state.left + speed * translateX, left, SNAP_TOLERANCE),
                    scale: snapToTarget(
                        this.state.scale + speed * translateScale,
                        scale,
                        SNAP_TOLERANCE,
                    ),
                };

                this.setState(nextTransform, () => (this.animation = requestAnimationFrame(frame)));
            };
            this.animation = requestAnimationFrame(frame);
        } else {
            this.setState({
                top,
                left,
                scale,
            });
        }
    }

    getValidTransform(top, left, scale, tolerance) {
        const transform = {
            scale: scale || 1,
            top: top || 0,
            left: left || 0,
        };
        const lowerBoundFactor = 1.0 - tolerance;
        const upperBoundFactor = 1.0 + tolerance;

        transform.scale = rangeBind(
            this.minScale * lowerBoundFactor,
            this.props.maxScale * upperBoundFactor,
            transform.scale,
        );

        //get dimensions by which scaled image overflows container
        const negativeSpace = this.calculateNegativeSpace(transform.scale);
        const overflow = {
            width: Math.max(0, invert(negativeSpace.width)),
            height: Math.max(0, invert(negativeSpace.height)),
        };

        //prevent moving by more than the overflow
        //example: overflow.height = 100, tolerance = 0.05 => top is constrained between -105 and +5
        transform.top = rangeBind(
            invert(overflow.height) * upperBoundFactor,
            overflow.height * upperBoundFactor - overflow.height,
            transform.top,
        );
        transform.left = rangeBind(
            invert(overflow.width) * upperBoundFactor,
            overflow.width * upperBoundFactor - overflow.width,
            transform.left,
        );

        return transform;
    }

    transformToProps(speed = 0) {
        const scale =
            this.props.initialScale === 'auto'
                ? this.calculateAutofitScale()
                : this.props.initialScale;
        this.applyTransform(this.props.initialTop, this.props.initialLeft, scale, 0, speed);
    }

    ensureValidTransform(speed = 0) {
        const initScale =
            this.props.initialScale === 'auto'
                ? this.calculateAutofitScale()
                : this.props.initialScale;
        let scale = this.state.scale ? this.state.scale : initScale;

        this.applyTransform(this.state.top, this.state.left, scale, 0, speed);
    }

    renderClickableArea(item, imageSizes) {
        const { top, left, scale } = this.state;
        const { width: imageWidth, height: imageHeight } = imageSizes;
        const xOffsetPercentage = (left * 100) / imageWidth;
        const yOffsetPercentage = (top * 100) / imageHeight;
        const clickableAreaSize = scale * 100;

        const onItemClick = () => {
            const { floorplan } = this.props.match.params;
            this.props.history.push(
                generatePath(this.props.match.path, {
                    floorplan,
                    place: item.id,
                }),
            );
        };

        return (
            <ClickableArea
                key={`place-${item.id}`}
                x={item.positionX * 100 + xOffsetPercentage}
                y={item.positionY * 100 + yOffsetPercentage}
                onClick={onItemClick}
                size={clickableAreaSize}
            />
        );
    }

    getImageSizes() {
        if (!this.image) {
            return {
                width: 0,
                height: 0,
            };
        }

        return {
            width: this.image.offsetWidth * this.state.scale,
            height: this.image.offsetHeight * this.state.scale,
        };
    }

    //lifecycle methods
    render() {
        const Markers = this.state.places.map(place => {
            const visible = place.id === this.state.selectedPlace;
            if (!visible) {
                return null;
            }
            const scale = this.state.scale;
            const left = this.state.left;
            const top = this.state.top;
            const x = this.image.width * place.positionX;
            const y = this.image.height * place.positionY;

            var data = {
                y: y * scale,
                x: x * scale,
                scale: scale,
                name: place.name,
                top: top,
                left: left,
                visible: visible,
            };
            return <Marker key={place.id} data={data} />;
        });

        const childElement = React.Children.only(this.props.children);
        const { ref: originalRef } = childElement;
        const composedRef = element => {
            this.image = element;
            if (typeof originalRef === 'function') {
                originalRef(element);
            }
        };
        const imageSizes = this.getImageSizes();

        return (
            <ThemeContext.Consumer>
                {({ theme }) => (
                    <React.Fragment>
                        <Sticky>
                            <SearchButton
                                bgcolor={theme.primary}
                                icon
                                onClick={this.props.searchButtonAction}
                            >
                                {palette.ICON_SEARCH}
                            </SearchButton>
                        </Sticky>

                        <MapContainer>
                            {Markers}

                            <ImageWrapper height={imageSizes.height} width={imageSizes.width}>
                                {React.cloneElement(childElement, {
                                    onTouchStart: this.handleTouchStart,
                                    onTouchEnd: this.handleTouchEnd,
                                    onMouseDown: this.handleMouseDown,
                                    onMouseMove: this.handleMouseMove,
                                    onMouseUp: this.handleMouseUp,
                                    onWheel: this.handleMouseWheel,
                                    onDragStart: event => event.preventDefault(),
                                    onLoad: this.handleImageLoad,
                                    ref: composedRef,
                                    style: {
                                        transform: `translate3d(${this.state.left}px, ${this.state.top}px, 0) scale(${this.state.scale})`,
                                        transformOrigin: '0 0',
                                        transition: 'transform 20ms ease-in-out',
                                    },
                                })}
                                {this.state.places.map(place =>
                                    this.renderClickableArea(place, imageSizes),
                                )}
                            </ImageWrapper>

                            {this.props.zoomButtons && (
                                <ZoomButtonBox bordercolor={theme.primary}>
                                    <ZoomButtonPlus
                                        bordercolor={theme.primary}
                                        onClick={() => this.zoomIn(null, true)}
                                        disabled={this.state.scale >= this.props.maxScale}
                                        icon
                                    >
                                        add
                                    </ZoomButtonPlus>
                                    <ZoomButton
                                        color={theme.primary}
                                        onClick={() => this.zoomOut(null, true)}
                                        disabled={this.state.scale <= this.minScale}
                                        icon
                                    >
                                        remove
                                    </ZoomButton>
                                </ZoomButtonBox>
                            )}
                        </MapContainer>
                    </React.Fragment>
                )}
            </ThemeContext.Consumer>
        );
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = null;
        if (nextProps.id !== prevState.prevId) {
            newState = {
                prevId: nextProps.id,
                places: [],
                updatePlaces: true,
            };
        }
        if (nextProps.selectedPlace !== prevState.selectedPlace) {
            newState = Object.assign(newState ? newState : {}, {
                selectedPlace: nextProps.selectedPlace,
                updateSelected: true,
            });
        }

        if (
            nextProps.initialTop !== prevState.initialTop ||
            nextProps.initialLeft !== prevState.initialLeft ||
            nextProps.initialScale !== prevState.initialScale
        ) {
            const obj = {
                initialTop: nextProps.initialTop,
                initialLeft: nextProps.initialLeft,
                initialScale: nextProps.initialScale,
            };
            newState = Object.assign(newState ? newState : {}, obj);
        }
        return newState;
    }

    componentDidMount() {
        this.image.addEventListener('touchmove', this.handleTouchMove, {
            passive: false,
        });
        window.addEventListener('resize', this.handleWindowResize);
        getPLacesByFloorplan(this.props.id, (err, places) => {
            this.setState({
                places,
                prevId: this.props.id,
                updatePlaces: false,
            });
        });
        //Using the child image's original parent enables flex items, e.g., dimensions not explicitly set
        this.container = this.image.parentNode.parentNode;
        if (this.image.width && this.image.height) {
            this.applyConstraints();
            this.transformToProps();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.updatePlaces === true) {
            getPLacesByFloorplan(this.state.prevId, (err, places) => {
                let nPlaces = places && places.length ? places : [];
                this.setState({
                    places: nPlaces,
                    updatePlaces: false,
                    scale: undefined,
                });
            });
        }
        if (this.state.updateSelected === true) {
            this.transformToProps();
            this.setState({
                updateSelected: false,
            });
        }

        if (this.image.width && this.image.height) {
            this.ensureConstraints();

            if (typeof this.state.scale === 'undefined') {
                //reset to new props
                this.transformToProps();
            }
        }
    }

    componentWillUnmount() {
        this.image.removeEventListener('touchmove', this.handleTouchMove);
        window.removeEventListener('resize', this.handleWindowResize);
    }

    //sizing methods
    ensureConstraints() {
        if (this.image.width && this.image.height) {
            const negativeSpace = this.calculateNegativeSpace(1);
            if (
                !this.lastUnzoomedNegativeSpace ||
                negativeSpace.height !== this.lastUnzoomedNegativeSpace.height ||
                negativeSpace.width !== this.lastUnzoomedNegativeSpace.width
            ) {
                //image and/or container dimensions have been set / updated
                this.applyConstraints();
                this.transformToProps();
            }
        }
    }

    applyConstraints() {
        let minScale = 1;
        if (this.props.minScale === 'auto') {
            minScale = this.calculateAutofitScale();
        } else {
            minScale = this.props.minScale;
        }

        if (this.minScale !== minScale) {
            this.minScale = minScale;
            this.ensureValidTransform();
        }

        this.lastUnzoomedNegativeSpace = this.calculateNegativeSpace(1);
    }

    calculateNegativeSpace(scale = this.state.scale) {
        //get difference in dimension between container and scaled image
        const width = this.container.offsetWidth - scale * this.image.width;
        const height = this.container.offsetHeight - scale * this.image.height;
        return {
            width,
            height,
        };
    }

    calculateAutofitScale() {
        let autofitScale = 1;
        if (this.image.width > 0) {
            autofitScale = Math.min(this.container.offsetWidth / this.image.width, autofitScale);
        }
        if (this.image.height > 0) {
            autofitScale = Math.min(this.container.offsetHeight / this.image.height, autofitScale);
        }
        return autofitScale * 0.95;
    }
}

export default withRouter(PinchZoomPan);

PinchZoomPan.defaultProps = {
    initialTop: 0,
    initialLeft: 0,
    initialScale: 'auto',
    minScale: 'auto',
    maxScale: 1,
    zoomButtons: true,
};

PinchZoomPan.propTypes = {
    children: PropTypes.element.isRequired,
};

import React from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { useVMMutation, useVMState } from '../../containers/main';
import useOnDropPresentationUrlSlider from '../../hooks/useOnDropPresentationUrlSlider';
import Button, { ButtonClassType } from '../common/Button';
import Link from '../common/Link';
import onUpdateVirtualEventUserMode from '../../events/onUpdateVirtualEventUserMode';
import useOnDropPresentationUrlPoster from '../../hooks/useOnDropPresentationUrlPoster';
import entities from '../../constants/entities';

const SlidesContainer = styled.div`
    align-items: center;
    background-color: #f5f5f5;
    display: flex;
    height: 660px;
    width: 100%;
    justify-content: center;
`;

const SlidesContent = styled.div`
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
`;

const UploadSlidesAndPosterWrapper = styled.div`
    display: flex;
`;

const LinkWrapper = styled.div`
    margin-top: 16px;
`;

const UploadPosterWrapper = styled.div`
    margin-left: 16px;
`;

const UploadSlidesAndPoster = () => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();

    const { getRootProps: getRootPropsSlider, getInputProps: getInputPropsSlider } = useDropzone({
        accept: 'application/pdf, application/vnd.ms-powerpoint',
        onDrop: useOnDropPresentationUrlSlider(),
    });

    const { getRootProps: getRootPropsPoster, getInputProps: getInputPropsPoster } = useDropzone({
        accept: 'application/pdf, application/vnd.ms-powerpoint',
        onDrop: useOnDropPresentationUrlPoster(),
    });

    return (
        <SlidesContainer>
            <SlidesContent>
                <UploadSlidesAndPosterWrapper>
                    <div {...getRootPropsSlider()}>
                        <input {...getInputPropsSlider()} />
                        <Button content="Upload slides" classType={ButtonClassType.BLUE_BOLD} />
                    </div>

                    <UploadPosterWrapper {...getRootPropsPoster()}>
                        <input {...getInputPropsPoster()} />
                        <Button content="Upload poster" classType={ButtonClassType.BLUE_BOLD} />
                    </UploadPosterWrapper>
                </UploadSlidesAndPosterWrapper>

                <LinkWrapper>
                    <Link
                        onClick={() =>
                            onUpdateVirtualEventUserMode(
                                stateCtx,
                                mutationCtx,
                                entities.virtualEventUserEntity.mode.video,
                            )
                        }
                    >
                        I’m not using slides or a poster
                    </Link>
                </LinkWrapper>
            </SlidesContent>
        </SlidesContainer>
    );
};

UploadSlidesAndPoster.propTypes = {};

export default UploadSlidesAndPoster;

const PUBLIC_URL = process.env.PUBLIC_URL || '/';
const NODE_ENV = process.env.NODE_ENV || 'development';
const PACKAGE_RELEASE_VERSION = 'trixie-webapp@' + process.env.npm_package_version;
const USE_LOCAL = process.env.REACT_APP_USE_LOCAL === 'true';
const USE_CONFIGURATOR = process.env.REACT_APP_USE_LOCAL === 'true';
const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION;
const APP_VERSION = process.env.REACT_APP_VERSION;
const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL || 'https://recordings.dev.indrina.com'; // or 'https://recordings.web.indrina.com'

const EUREKA_URL = process.env.REACT_APP_EUREKA_URL || 'https://eureka.dev.indrina.com';
const EUREKA_TALK_HOST = process.env.REACT_APP_EUREKA_TALK_HOST || 'devtalk.dev.indrina.com';
const APPSYNC_CONFIG = {
    endpoint: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    defaultAuthMode: 'apiKey',
    apiKey: process.env.REACT_APP_AWS_APPSYNC_API_KEY,
    region: process.env.REACT_APP_AWS_APPSYNC_REGION, // Optional
};

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;
const AWS_IMAGES_ENDPOINT = process.env.REACT_APP_AWS_IMAGES_ENDPOINT;
const S3_ENDPOINT_IMAGES = process.env.REACT_APP_S3_ENDPOINT_IMAGES;

export {
    NODE_ENV,
    PUBLIC_URL,
    EUREKA_URL,
    EUREKA_TALK_HOST,
    SENTRY_DSN,
    SENTRY_ENVIRONMENT,
    PACKAGE_RELEASE_VERSION,
    USE_LOCAL,
    USE_CONFIGURATOR,
    APPSYNC_CONFIG,
    BUILD_VERSION,
    APP_VERSION,
    CLOUDFRONT_URL,
    AWS_IMAGES_ENDPOINT,
    S3_ENDPOINT_IMAGES,
};

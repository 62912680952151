import React, { useState, useEffect } from 'react';
import { ConfirmDialog } from './index';
import { getString } from '../../services/api/store';
import Auth from '../../services/api/auth';

const SIGN_OUT_TITLE = 'Sign out';
const SIGN_OUT_MESSAGE =
    'If you sign out, your favourite items will be no longer synced across your devices.';
const SIGN_OUT_CONFIRM_BUTTON = 'SIGN OUT';
const SIGN_OUT_CANCEL_BUTTON = 'CANCEL';

const SignOutDialog = ({ hideConfirmDialog }) => {
    const [confirmDialogOptions, setConfirmDialogOptions] = useState(null);

    useEffect(() => {
        let TEXT_TITLE_SIGN_OUT = getString('signOutDialogTitle') || SIGN_OUT_TITLE;
        let TEXT_MESSAGE_SIGN_OUT = getString('signOutDialogMessage') || SIGN_OUT_MESSAGE;
        let TEXT_CONFIRM_SIGN_OUT =
            getString('signOutDialogConfirmButton') || SIGN_OUT_CONFIRM_BUTTON;
        let TEXT_CANCEL_SIGN_OUT = getString('signOutDialogCancelButton') || SIGN_OUT_CANCEL_BUTTON;

        const options = {
            title: TEXT_TITLE_SIGN_OUT,
            text: TEXT_MESSAGE_SIGN_OUT,
            confirmText: TEXT_CONFIRM_SIGN_OUT,
            cancelText: TEXT_CANCEL_SIGN_OUT,
            confirm: () => {
                Auth.signOut(() => {
                    if (Auth.customLogoutRedirect) {
                        if (Auth.logoutwindow && !Auth.logoutwindow.closed) {
                            Auth.logoutwindow.focus();
                            return;
                        }

                        const windowOpts = `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=300, height=600, top=1, left=1`;
                        Auth.logoutwindow = window.open(
                            Auth.customLogoutRedirect,
                            '_blank',
                            windowOpts,
                        );
                        setTimeout(() => {
                            Auth.logoutwindow.close();
                            window.location.reload();
                        }, 4000);
                    } else {
                        window.location.reload();
                    }
                });
            },
            cancel: () => {},
        };
        setConfirmDialogOptions(options);
    }, []);

    return (
        <ConfirmDialog
            visible={true}
            options={confirmDialogOptions}
            onHideDialog={hideConfirmDialog}
        />
    );
};

export default SignOutDialog;

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router-dom';

import { Flex } from '../../common/Flex';
import Rank from './Rank';
import UserAvatar from '../../../components/General/UserAvatar';
import TotalPoints from './TotalPoints';
import Auth from '../../../services/api/auth';
import { useTheme } from '../../../components/Theme/ThemeContext';

const StickyToBottomCss = css`
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 2;
`;

const Container = styled(Flex)`
    position: relative;
    padding: 8px 16px 8px 44px;
    ${props => props.isCurrentUser && `${StickyToBottomCss}; background-color: blue`}
    background-color: ${props => props.backgroundColor};

    &:hover {
        background: #f5f5f5 !important;
        cursor: pointer;
    }
`;

const UserName = styled.div`
    font-size: 15px;
    font-weight: 500;
    line-height: 1.33;
    color: ${props => (props.isCurrentUser ? '#fff' : 'rgba(0, 0, 0, 0.87)')};
    margin-left: 12px;
`;

const StyledRank = styled(Rank)`
    position: absolute;
    left: 16px;
    top: 50%;
    margin-top: -${props => props.size / 2}px;
    z-index: 2;
`;

const LeaderboardItem = ({ className, rank, item, history, location }) => {
    const [currentUserHovered, setCurrentUserHovered] = useState(false);
    const currentUser = Auth.getUser();
    const { theme } = useTheme();
    const { user, points } = item;
    const isCurrentUser = currentUser.id === user.id;

    const onUserClick = id => {
        const paths = location.pathname.split('/');
        if (paths && paths.length > 3) {
            const newPaths = paths.splice(paths.length - 2, 2);
            const link = `${paths.join('/')}/profile/${id}`;
            return history.push(link);
        } else {
            return history.push(`${location.pathname}/profile/${id}`);
        }
    };

    return (
        <Container
            alignItems="center"
            justifyContent="space-between"
            className={className}
            isCurrentUser={isCurrentUser}
            backgroundColor={isCurrentUser ? theme.primary : '#fff'}
            onClick={() => onUserClick(user.id)}
            onMouseEnter={() => isCurrentUser && setCurrentUserHovered(true)}
            onMouseLeave={() => setCurrentUserHovered(false)}
        >
            <StyledRank rank={rank} size={40} />
            <Flex alignItems="center">
                <UserAvatar size={56} user={user} src={user.imageUrl} />
                <UserName
                    isCurrentUser={isCurrentUser && !currentUserHovered}
                >{`${user.firstName} ${user.lastName}`}</UserName>
            </Flex>
            <TotalPoints
                points={points}
                outlined
                outlineColor={isCurrentUser && !currentUserHovered ? '#fff' : null}
                backgroundColor={isCurrentUser && !currentUserHovered ? 'transparent' : null}
            />
        </Container>
    );
};

export default withRouter(LeaderboardItem);

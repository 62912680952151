import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';

import * as palette from '../../components/General/Variables';

const StyledContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.87);
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    position: relative;
`;

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledIconContainer = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4c4c4f;
    border-radius: 50%;
`;

const StyledIcon = styled(FontIcon)`
    width: 18px;
    color: #fff !important;
`;

const StyledCloseIcon = styled(StyledIcon)`
    width: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    opacity: 0.7;
`;

const StyledTitle = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 15px;
    color: ${palette.COLOR_WHITE};
    font-weight: 500;
    text-overflow: ellipsis;
`;

const StyledSubtitle = styled(StyledTitle)`
    margin-top: 2px;
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: rgba(255, 255, 255, 0.7);
`;

const CloseButton = ({ closeToast }) => (
    <StyledCloseIcon iconClassName={'material-icons material-icons-round'} onClick={closeToast}>
        close
    </StyledCloseIcon>
);

const NotificationToast = ({ title, subtitle, icon, onClose }) => (
    <StyledContainer>
        {onClose && <CloseButton closeToast={onClose} />}
        <StyledIconContainer>
            <StyledIcon>{icon}</StyledIcon>
        </StyledIconContainer>
        <StyledContent>
            <StyledTitle>{title}</StyledTitle>
            <StyledSubtitle>{subtitle}</StyledSubtitle>
        </StyledContent>
    </StyledContainer>
);

export default NotificationToast;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontIcon } from 'react-md';
import styled from 'styled-components';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import debounce from 'lodash';
import get from 'lodash/get';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
    askQuestion,
    calcTimeDifference,
    likeQuestion,
    saveQuestion,
    toggleQuestionLike,
} from '../../../../services/QuestionsAndAnswersService';

import InputMessageComponent from '../../../../../Talk/components/InputMessageComponent';
import Dropdown from '../../../common/Dropdown';

import WarningDialog from '../../../common/WarningDialog';
import QuestionModal from './QuestionModal';
import VisibilityToaster from '../common/VisibilityToaster';
import { useVMState } from '../../../../containers/main';
import NoQuestionsImage from '../../../../../../../../assets/images/no-questions.png';
import toast from 'toasted-notes';
import NotificationToast from '../../../../../../../../components/General/NotificationToast';
import { getString } from '../../../../../../../../services/api/store';
import {
    LikesContainer,
    QuestionAndTimeContainer,
    QuestionSenderName,
    StyledFavoriteIcon,
} from '../../../../../../../VirtualSession/components/userInteraction/questionsAndAnswers/QuestionsAndAnswers';
import { useTheme } from '../../../../../../../../components/Theme/ThemeContext';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import QuestionList from './QuestionList';
import Tooltip from '@mui/material/Tooltip';

const Wrapper = styled.div`
    height: ${props =>
        props.isfirefox && props.windowheight
            ? 'calc(100vh - ' + (props.windowheight < 700 ? '400px)' : '580px)')
            : '100%'};
    overflow: hidden;
    position: relative;
`;

export const QuestionWrapper = styled.div`
    border-radius: 8px;
    ${props =>
        props.transparent
            ? 'background-color: transparent;'
            : `background-color: ${props.backgroundColor};
        padding: 12px 16px 14px 12px;
        cursor: pointer;
        `}
    ${props => props.isHovered && 'box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2)'};
    margin: 8px 0;
    overflow: hidden;
    justify-content: space-between;
    font-family: 'Cabin', sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    white-space: pre-wrap;
    word-break: break-word;

    &:hover {
        box-shadow: 0px 2px 3px 0px #0000001a;
    }
`;

export const AnimatedQuestionWrapper = styled(QuestionWrapper)`
    animation-fill-mode: both;
    transition: max-height 300ms, opacity 300ms;
    position: relative;

    &.exit {
        max-height: 0;
        opacity: 0;
        padding: 0 8px 0 16px;
    }
`;

export const VisibilityIconWrapper = styled.div`
    cursor: pointer;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: ${props => props.color} !important;
    }
    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }

    &:active {
        background: rgba(0, 0, 0, 0.2);
    }
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    position: absolute;
    right: ${props => (props.new ? 48 : 8)}px;
    gap: 16px;
`;

export const LikeIconContainer = styled.div`
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    margin-left: 8px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    &:active {
        background-color: rgba(0, 0, 0, 0.12);
    }
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: ${props => props.alignitems};
    justify-content: ${props => props.justifycontent};
    flex-wrap: ${props => props.wrap};
`;

export const Time = styled.div`
    margin-left: 8px;
`;

export const ActionsWrapper = styled.div`
    position: relative;
    border-radius: 50%;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.54);

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
    &:active {
        background: rgba(0, 0, 0, 0.2);
    }
`;

const PlaceholderImageWrapper = styled.div`
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;

    span {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
    }
`;

export const QuestionsContainer = styled.div`
    height: ${props => (props.mobile === 'true' ? '59%' : 'calc(100% - 64px)')};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow-y: scroll;
`;

const ScrollableWrapper = styled.div`
    padding: 0 16px;
    height: calc(100% + 32px);
    overflow-y: auto;
`;

const PlaceholderImage = styled.img`
    width: 250px;
    height: 200px;
`;

export const StyledChip = styled.p`
    position: absolute;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center !important;
    font-family: 'Roboto', sans-serif;
    font-size: 8px !important;
    font-weight: bold !important;
    color: #fff !important;
    background-color: ${props => props.color};
    border-radius: 18px;
    height: 14px !important;
    padding: 0 6px !important;
    line-height: 13px !important;
    white-space: nowrap;
    margin-left: auto;
`;

const TextFieldWrapper = styled.div`
    background-color: #ffffff;
    width: 100%;
    border-radius: 8px;
    ${props =>
        props.isfirefox
            ? `
        bottom: 110px;
        position: relative;
        display: inline-block;
        content: '';
    `
            : `
        bottom: 0;
        position: absolute !important;
    `}
`;

export const QuestionItemDetailsWrapper = styled(FlexContainer)`
    width: 100%;
    align-items: center;
`;

export const Flex = styled.div`
    display: flex;
    align-items: ${props => props.alignItems};
`;

const ConfirmationText = styled.span`
    font-style: italic;
    font-size: 14px !important;
    margin: 0 4px 8px 4px !important;
    padding-left: 12px !important;
    color: ${props => (props.error ? '#d50000' : 'rgba(0, 0, 0, 0.87)')} !important;
    display: inherit;
`;

const MAX_QUESTION_SIZE = 250;

const getQuestionLikes = question => question?.QuestionLikes?.filter(q => q.active).length;

const invisibleQuestionsSortFn = (a, b) => {
    if (a.answered && b.answered) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
    }
    if (a.new || b.new) {
        return 1;
    }
    const qLike = getQuestionLikes(b) - getQuestionLikes(a);
    if (qLike === 0) {
        return new Date(b.createdAt) - new Date(a.created);
    }

    return qLike;
};

const visibleQuestionSortFn = (a, b) => {
    if (a.answered && b.answered) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
    }
    if (a.order && b.order) {
        return a.order - b.order;
    }
    return new Date(a.updatedAt) - new Date(b.updatedAt);
};

const QuestionsAndAnswers = ({
    questions,
    fetchQuestions,
    controls,
    questionSetsId,
    showVisible,
    nonVirtualQA,
}) => {
    const [currentQuestionInfo, setCurrentQuestionInfo] = useState(null);
    const [currentQuestionToEdit, setCurrentQuestionToEdit] = useState(null);
    const [currentQuestionToDelete, setCurrentQuestionToDelete] = useState(null);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [displayActions, setDisplayActions] = useState(null);
    const [visibleQuestions, setVisibleQuestions] = useState([]);
    const [visibleAnsweredQuestions, setVisibleAnsweredQuestions] = useState([]);
    const [invisibleQuestions, setInvisibleQuestions] = useState([]);
    const [invisibleAnsweredQuestions, setInvisibleAnsweredQuestions] = useState([]);
    const [question, setQuestion] = useState('');
    const isFirefox = typeof InstallTrigger !== 'undefined';
    const isSubmittingQuestion = useRef(false);
    const isSendingRequest = useRef(false);

    const visibleAllQuestions = useMemo(() => [...visibleQuestions, ...visibleAnsweredQuestions], [
        visibleQuestions,
        visibleAnsweredQuestions,
    ]);
    const invisibleAllQuestions = useMemo(
        () => [...invisibleQuestions, ...invisibleAnsweredQuestions],
        [invisibleQuestions, invisibleAnsweredQuestions],
    );
    const parsedQuestions = useMemo(() => [...visibleAllQuestions, ...invisibleAllQuestions], [
        visibleQuestions,
        invisibleQuestions,
    ]);

    const stateCtx = useVMState();
    const { virtualEventSession, virtualEventUser: currentUser, externalObject, socket } = stateCtx;

    let areQuestionsEnabled = get(virtualEventSession, 'data.showQA') || false;
    if (nonVirtualQA) {
        areQuestionsEnabled = true;
    }

    const handleResize = debounce(() => {
        setWindowHeight(window.innerHeight);
    }, 500);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getNewAndOldQuestions = useCallback(
        questions => {
            const newQuestions = questions.filter(q =>
                controls ? q.newQuestion : q.newQuestionForSpeaker,
            );
            const oldQuestions = questions.filter(q =>
                controls ? !q.newQuestion : !q.newQuestionForSpeaker,
            );
            return { newQuestions, oldQuestions };
        },
        [controls],
    );

    const setSortedQuestions = useCallback(
        (oldQuestionsList, newQuestionsList) => {
            const { newQuestions, oldQuestions } = getNewAndOldQuestions(newQuestionsList);
            return Array.from(new Set(oldQuestions.concat(newQuestions)));
        },
        [getNewAndOldQuestions],
    );

    useEffect(() => {
        const visibleSortedQuestions = questions
            .filter(q => q.visible && !q.answered)
            .sort(visibleQuestionSortFn)
            .map((q, index) => ({ ...q, order: q?.order || index + 1 }));
        const visibleQuestionsLength = visibleSortedQuestions.length;
        const visibleSortedAnsweredQuestions = questions
            .filter(q => q.visible && q.answered)
            .sort(visibleQuestionSortFn)
            .map((q, index) => ({ ...q, order: q?.order || index + visibleQuestionsLength + 1 }));
        const invisibleSortedQuestions = questions
            .filter(q => !q.visible && !q.answered)
            .sort(invisibleQuestionsSortFn);
        const invisibleSortedAnsweredQuestions = questions
            .filter(q => !q.visible && q.answered)
            .sort(invisibleQuestionsSortFn);

        setVisibleQuestions(visibleSortedQuestions);
        setVisibleAnsweredQuestions(visibleSortedAnsweredQuestions);
        setInvisibleQuestions(invisibleSortedQuestions);
        setInvisibleAnsweredQuestions(invisibleSortedAnsweredQuestions);
    }, [setSortedQuestions, questions]);

    const toggleVisibility = async question => {
        let order;
        if (!question.visible) {
            order = question.answered
                ? visibleAnsweredQuestions.length + 1
                : visibleQuestions.length + 1;
        }
        await saveQuestion({
            ...question,
            visible: !question.visible,
            order,
            new: false,
            updatedAt: new Date().toISOString(),
        });

        const data = {};
        data.objectId = externalObject.data.id;
        socket.emit('setQuestions', data);
    };

    const markAsAnswered = async question => {
        await saveQuestion({
            ...question,
            answered: true,
            visible: false,
            new: false,
            answeredAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        });

        const data = {};
        data.objectId = externalObject.data.id;
        socket.emit('setQuestions', data);
    };

    const markAsUnanswered = async question => {
        await saveQuestion({
            ...question,
            answered: false,
            new: false,
            visible: false,
            updatedAt: new Date().toISOString(),
        });

        const data = {};
        data.objectId = externalObject.data.id;
        socket.emit('setQuestions', data);
    };

    const onGetInfoClick = (e, question) => {
        e.stopPropagation();
        setCurrentQuestionInfo(question);
    };

    const onEditClick = (e, question) => {
        e.stopPropagation();
        setCurrentQuestionToEdit(question);
    };

    const onDeleteClick = (e, question) => {
        e.stopPropagation();
        setCurrentQuestionToDelete(question);
    };

    const onQuestionLike = async ({ QuestionLikes, id }) => {
        if (isSendingRequest.current) {
            return;
        }
        isSendingRequest.current = true;

        const questionLikeIndex = QuestionLikes.findIndex(
            ql => ql.UserId === currentUser.data.UserId,
        );

        const data = {
            active: questionLikeIndex !== -1 ? !QuestionLikes[questionLikeIndex].active : true,
            QuestionId: id,
            UserId: currentUser.data.UserId,
        };

        if (questionLikeIndex !== -1) {
            await toggleQuestionLike({
                questionLikeId: QuestionLikes[questionLikeIndex].id,
                data,
            });
        } else {
            await likeQuestion({ data });
        }

        const qdata = {};
        qdata.objectId = externalObject.data.id;
        socket.emit('setQuestions', qdata);

        isSendingRequest.current = false;
    };

    const onChange = text => {
        setQuestion(text);
    };

    const onShowConfirmationText = success => {
        toast.notify(
            () => (
                <NotificationToast
                    title={success ? 'Question was sent' : 'Question was not sent'}
                    subtitle={
                        success
                            ? getString(
                                  'askQuestionSuccess',
                                  'It will appear in this list when the moderator decides to make it visible.',
                              )
                            : getString(
                                  'askQuestionFail',
                                  'Something went wrong sending your question',
                              )
                    }
                    icon={success ? 'check' : 'info'}
                />
            ),
            {
                position: 'top-right',
                duration: 5000,
            },
        );
    };

    const onSubmitQuestion = async () => {
        if (isSubmittingQuestion.current === true) {
            return;
        }

        const currentQuestionAlreadyExists = parsedQuestions.find(
            item => item.question === question,
        );

        if (question && !currentQuestionAlreadyExists) {
            isSubmittingQuestion.current = true;

            try {
                await askQuestion({
                    questionSetId: questionSetsId,
                    question,
                });

                const data = {};
                data.objectId = externalObject.data.id;
                socket.emit('setQuestions', data);
            } catch (err) {
                onShowConfirmationText(false);
                console.log('Error: ', err);
            }

            isSubmittingQuestion.current = false;
        }

        setQuestion('');
        onShowConfirmationText(true);
    };

    const sender =
        currentQuestionInfo && currentQuestionInfo.User
            ? `${currentQuestionInfo.User.firstName} ${currentQuestionInfo.User.lastName}`
            : 'Anonymous';

    const handleDragEnd = useCallback(
        async (result, provided) => {
            if (!result.destination) {
                return;
            }
            const visibleSortedQuestions = [...(visibleQuestions || [])];
            const [removed] = visibleSortedQuestions.splice(result.source.index, 1);
            visibleSortedQuestions.splice(result.destination.index, 0, removed);
            const orderedVisibleQuestions = visibleSortedQuestions.map((t, index) => ({
                ...t,
                order: index + 1,
            }));
            setVisibleQuestions(orderedVisibleQuestions);
            const promises = [];

            orderedVisibleQuestions.forEach((question, index) => {
                promises.push(
                    saveQuestion({
                        ...question,
                    }),
                );
            });
            await Promise.all(promises);

            const data = {};
            data.objectId = externalObject.data.id;
        },
        [visibleQuestions],
    );

    const handleMoveQuestion = useCallback(
        async (question, moveDown) => {
            if (!question.visible) {
                return;
            }
            const questionSetToUse = question.answered
                ? visibleAnsweredQuestions
                : visibleQuestions;
            const foundQuestionIndex = questionSetToUse.findIndex(q => q.id === question.id);
            const questionToMove = questionSetToUse[foundQuestionIndex + (moveDown ? 1 : -1)];
            if (!questionToMove) {
                return;
            }

            const newQuestionSet = questionSetToUse.map(q => {
                if (q.id === question.id) {
                    return { ...q, order: questionToMove.order };
                }
                if (q.id === questionToMove.id) {
                    return { ...q, order: question.order };
                }
                return q;
            });

            await saveQuestion({
                ...question,
                order: questionToMove.order,
                updatedAt: new Date().toISOString(),
            });
            await saveQuestion({
                ...questionToMove,
                order: question.order,
                updatedAt: new Date().toISOString(),
            });

            const setFn = question.answered ? setVisibleAnsweredQuestions : setVisibleQuestions;
            setFn(newQuestionSet);
        },
        [visibleAnsweredQuestions, visibleQuestions],
    );

    return (
        <Wrapper
            isfirefox={isFirefox ? 1 : undefined}
            windowheight={windowHeight}
            data-qa="qa-container"
            controls={controls}
        >
            {!nonVirtualQA && (
                <QuestionsContainer>
                    {parsedQuestions.length === 0 && (
                        <PlaceholderImageWrapper>
                            <PlaceholderImage src={NoQuestionsImage} alt="No questions" />
                            <span>There are no questions yet</span>
                        </PlaceholderImageWrapper>
                    )}
                    {parsedQuestions.length !== 0 && (
                        <ScrollableWrapper>
                            <TransitionGroup style={{ width: '100%' }}>
                                <QuestionList
                                    questions={
                                        showVisible ? visibleAllQuestions : invisibleAllQuestions
                                    }
                                    handleDragEnd={handleDragEnd}
                                    getQuestionLikes={getQuestionLikes}
                                    controls={controls}
                                    toggleVisibility={toggleVisibility}
                                    markAsAnswered={markAsAnswered}
                                    markAsUnanswered={markAsUnanswered}
                                    displayActions={displayActions}
                                    onEditClick={onEditClick}
                                    onDeleteClick={onDeleteClick}
                                    onQuestionLike={onQuestionLike}
                                    setDisplayActions={setDisplayActions}
                                    onGetInfoClick={onGetInfoClick}
                                    notDragable={!showVisible}
                                    handleMoveQuestion={handleMoveQuestion}
                                />
                            </TransitionGroup>
                        </ScrollableWrapper>
                    )}
                </QuestionsContainer>
            )}
            {nonVirtualQA && (
                <QuestionsContainer>
                    {parsedQuestions.length === 0 && (
                        <PlaceholderImageWrapper>
                            <PlaceholderImage src={NoQuestionsImage} alt="No questions" />
                            <span>There are no questions yet</span>
                        </PlaceholderImageWrapper>
                    )}
                    {parsedQuestions.length !== 0 && (
                        <ScrollableWrapper>
                            <TransitionGroup style={{ width: '100%' }}>
                                <Stack direction="row" alignItems="center" paddingBottom={1}>
                                    <VisibilityOutlinedIcon />
                                    <Typography variant="h5" paddingLeft={1}>
                                        Visible
                                    </Typography>
                                </Stack>
                                <QuestionList
                                    questions={visibleAllQuestions}
                                    handleDragEnd={handleDragEnd}
                                    getQuestionLikes={getQuestionLikes}
                                    controls={controls}
                                    toggleVisibility={toggleVisibility}
                                    markAsAnswered={markAsAnswered}
                                    markAsUnanswered={markAsUnanswered}
                                    displayActions={displayActions}
                                    onEditClick={onEditClick}
                                    onDeleteClick={onDeleteClick}
                                    onQuestionLike={onQuestionLike}
                                    setDisplayActions={setDisplayActions}
                                    onGetInfoClick={onGetInfoClick}
                                    handleMoveQuestion={handleMoveQuestion}
                                />
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    paddingBottom={1}
                                    paddingTop={1}
                                >
                                    <VisibilityOutlinedIcon />
                                    <Typography variant="h5" paddingLeft={1}>
                                        Invisible
                                    </Typography>
                                </Stack>
                                <QuestionList
                                    questions={invisibleAllQuestions}
                                    handleDragEnd={handleDragEnd}
                                    getQuestionLikes={getQuestionLikes}
                                    controls={controls}
                                    toggleVisibility={toggleVisibility}
                                    markAsAnswered={markAsAnswered}
                                    markAsUnanswered={markAsUnanswered}
                                    displayActions={displayActions}
                                    onEditClick={onEditClick}
                                    onDeleteClick={onDeleteClick}
                                    onQuestionLike={onQuestionLike}
                                    setDisplayActions={setDisplayActions}
                                    onGetInfoClick={onGetInfoClick}
                                    notDragable
                                    handleMoveQuestion={handleMoveQuestion}
                                />
                            </TransitionGroup>
                        </ScrollableWrapper>
                    )}
                </QuestionsContainer>
            )}
            <TextFieldWrapper isfirefox={isFirefox ? 1 : 0}>
                <Flex alignItems={'center'}>
                    {areQuestionsEnabled ? (
                        <InputMessageComponent
                            sendMessage={onSubmitQuestion}
                            placeholder={'Ask a question'}
                            onChange={onChange}
                            maxChar={MAX_QUESTION_SIZE}
                        />
                    ) : (
                        <VisibilityToaster />
                    )}
                </Flex>
                {question.length > MAX_QUESTION_SIZE && (
                    <ConfirmationText
                        error
                    >{`${question.length}/${MAX_QUESTION_SIZE} characters`}</ConfirmationText>
                )}
            </TextFieldWrapper>
            {currentQuestionInfo && (
                <WarningDialog
                    open={true}
                    title="Question Info"
                    content={
                        <div>
                            <p>{`Sender: ${sender}`}</p>
                            <p>{`Creation date: ${new Date(
                                currentQuestionInfo.createdAt,
                            ).toUTCString()}`}</p>
                            <p>{`Last update: ${new Date(
                                currentQuestionInfo.updatedAt,
                            ).toUTCString()}`}</p>
                        </div>
                    }
                    onClose={() => setCurrentQuestionInfo(null)}
                    width={350}
                />
            )}
            <QuestionModal
                open={!!currentQuestionToEdit || !!currentQuestionToDelete}
                handleClose={() => {
                    setCurrentQuestionToEdit(null);
                    setCurrentQuestionToDelete(null);
                    fetchQuestions();
                }}
                handleSubmit={() => {
                    const data = {};
                    data.objectId = externalObject.data.id;
                    socket.emit('setQuestions', data);
                }}
                question={currentQuestionToEdit || currentQuestionToDelete}
                editModalType={!!currentQuestionToEdit}
            />
        </Wrapper>
    );
};

export default QuestionsAndAnswers;

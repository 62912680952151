import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTheme } from '../Theme/ThemeContext';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';

const StyledDatePicker = styled(DatePicker)`
    font-family: 'Roboto', sans-serif;
    height: 42px;

    .MuiInputBase-root {
        padding: 0 16px;
        border-radius: 4px;
        overflow: hidden;
        height: 42px;
        background-color: #efefef;
    }

    && .MuiInput-root:hover::before {
        border-color: ${props => props.color};
    }
`;

const CustomDatePicker = ({ value, placeholder, onChange }) => {
    const { theme } = useTheme();

    return (
        <StyledDatePicker
            value={moment(value)}
            inputFormat={'ddd, DD MMM'}
            onChange={newValue => {
                onChange(new Date(newValue.toString()));
            }}
            color={theme.primary}
            renderInput={params => (
                <TextField
                    placeholder={placeholder}
                    variant="standard"
                    aria-label="date-picker-text-field"
                    sx={{
                        '& .MuiInput-underline:before': { borderBottomColor: '#efefef' },
                        '& .MuiInput-underline:hover:before': { borderBottomColor: theme.primary },
                        '& .MuiInput-underline:after': { borderBottomColor: theme.primary },
                    }}
                    {...params}
                />
            )}
        />
    );
};

export default CustomDatePicker;

const onUpdatePosterPositionAndScale = async ({ stateCtx, scale, position }) => {
    const { sessionId, virtualEventUser, socket } = stateCtx;

    socket.emit('updateData', {
        objectId: sessionId,
        virtualEventSession: {
            VirtualEventUsers: [
                {
                    id: virtualEventUser.data.id,
                    scale: scale,
                    positionX: position.x,
                    positionY: position.y,
                },
            ],
        },
    });
};

export default onUpdatePosterPositionAndScale;

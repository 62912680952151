import React, { useState } from 'react';
import Participants from '../../../TalkRoom/components/Participants';
import SearchBar from '../../../../components/TextInput/SearchBar';
import styled from 'styled-components';

const SearchBarWrapper = styled.div`
    margin-top: 8px;
`;

const SearchAndParticipantsList = ({ participants }) => {
    const [searchValue, setSearchValue] = useState('');

    const sortFunction = (p1, p2) => {
        const fullName1 = `${p1.lastName} ${p1.firstName}`.toLowerCase();
        const fullName2 = `${p2.lastName} ${p2.firstName}`.toLowerCase();
        return fullName1.localeCompare(fullName2);
    };

    const filterFunction = participant => {
        const { firstName, lastName, email } = participant;
        const searchTerms = `${firstName} ${lastName} ${email}`.toLowerCase().trim();

        return searchTerms.includes(searchValue.trim().toLowerCase());
    };

    const filteredParticipants = participants.sort(sortFunction).filter(filterFunction);

    return (
        <div>
            <SearchBarWrapper>
                <SearchBar value={searchValue} onChange={setSearchValue} />
            </SearchBarWrapper>
            <Participants sortedAttendees={filteredParticipants} marginTop={72} />
        </div>
    );
};

export default SearchAndParticipantsList;

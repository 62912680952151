export const getPlayerLinkByType = ({ mainMedia, timeslot, primaryScreen }) => {
    const {
        onDemandLink,
        onDemandLinkScreen,
        isExternalLink,
        onDemandExternalLink,
    } = timeslot.params;
    const videoLink = isExternalLink ? onDemandExternalLink : onDemandLink;

    if (primaryScreen) {
        return mainMedia === 'video' ? videoLink : onDemandLinkScreen;
    }

    return mainMedia === 'video' ? onDemandLinkScreen : videoLink;
};

export const checkIfTimeslotHasOnlyOneUploadedVideo = timeslot => {
    if (!timeslot || !timeslot.params) {
        return false;
    }

    const { onDemandLinkScreen, onDemandLink } = timeslot.params;

    return !!onDemandLinkScreen && !onDemandLink;
};

export default {
    getPlayerLinkByType,
};

import React from 'react';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import {
    SENTRY_DSN,
    SENTRY_ENVIRONMENT,
    PACKAGE_RELEASE_VERSION,
    USE_LOCAL,
    APPSYNC_CONFIG,
} from './config';
import App from './App';
import './index.css';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';

if (SENTRY_DSN && !USE_LOCAL) {
    const opts = {
        dsn: SENTRY_DSN,
        environment: SENTRY_ENVIRONMENT,
        release: PACKAGE_RELEASE_VERSION,
        integrations: [new TracingIntegrations.BrowserTracing()],
        tracesSampleRate: 0.1, // send 10% of transactions
    };
    Sentry.init(opts);
}

Amplify.configure({
    API: {
        GraphQL: APPSYNC_CONFIG,
    },
});

export const API = generateClient();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
);

export const TILE_GROUP_TYPES = {
    FOUR_SMALL: '4 small',
    TWO_MEDIUM: '2 medium',
    ONE_LARGE: '1 large',
    ONE_MEDIUM_TWO_SMALL: '1 medium, 2 small',
    TWO_SMALL_ONE_MEDIUM: '2 small, 1 medium',
    VIDEO: 'video',
    UPCOMING_SESSIONS: 'upcoming sessions',
};

export const LINK_TYPES = {
    IMAGE: 'image',
    ICON_AND_LABEL: 'iconAndLabel',
};

import React, { useEffect, useRef, useState } from 'react';
import { Avatar, ListItem } from 'react-md';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { getAttendeesList } from '../../../services/attendees';
import { navigateTo } from '../../../../../components/Navigation/NavigationLink';
import Auth from '../../../../../services/api/auth';
import SearchBar from '../../common/SearchBar';
import eventBus from '../../../../../utils/eventBus';

const Container = styled.div`
    width: 100%;
    list-style-type: none;
    height: 100%;
    overflow: auto;
    padding: 8px 16px 0 16px;
`;

const ItemsContainer = styled.div`
    ${props => props.applymargin && 'margin-top: 44px;'};
`;

const CustomAvatar = styled(Avatar)`
    background-color: ${props => props.avatarcolor || '#bfbfbf'};
    border: 0px solid rgba(0, 0, 0);
`;

const Item = styled(ListItem)`
    height: 56px;

    & .md-list-tile--avatar {
        height: 56px;
    }

    & .md-avatar {
        border: none;
    }

    & .md-list-tile {
        border-radius: 8px;
    }

    & .md-list-tile--active {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

const Wrapper = styled.div`
    height: ${props =>
        props.isfirefox && props.windowheight
            ? 'calc(100vh - ' + (props.windowheight < 700 ? '300px)' : '400px)')
            : '100%'};
    position: relative;
    overflow: hidden;
`;

const ContentWrapper = styled.div`
    height: 100%;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${props => props.sticky && 'position: absolute;'};
    ${props => props.sticky && 'padding-right: 32px;'};
    width: 100%;
    padding-bottom: 8px;
    background-color: #fff;
    z-index: 10;
`;

export const getInitials = (firstName, lastName) => {
    let firstLetter = '';
    let secondLetter = '';

    if (firstName && firstName[0]) {
        firstLetter = firstName[0].toUpperCase();
    }
    if (lastName && lastName[0]) {
        secondLetter = lastName[0].toUpperCase();
    }

    return `${firstLetter}${secondLetter}`;
};

const MINIMUM_SEARCH_CHAR_COUNT = 3;

const Attendees = props => {
    const { match, history, location } = props;
    const { sortedAttendees, totalAttendees, limit } = props;
    const [attendees, setAttendees] = useState(sortedAttendees);
    const [isFetching, setIsFetching] = useState(props.externalIsFetching);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [page, setPage] = useState(props.externalPage);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        setAttendees(sortedAttendees);
    }, [sortedAttendees, totalAttendees]);

    const scroll = useRef();
    const isFirefox = typeof InstallTrigger !== 'undefined';

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        eventBus.on('updateAttendeesList', data => {
            setAttendees(data);
        });
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getAttendees = async () => {
        if (isFetching) {
            if (page < totalAttendees / limit || totalAttendees === 0) {
                let currentPage = page;
                if (attendees.length) {
                    currentPage += 1;
                }
                setTimeout(async () => {
                    const newAttendees = await getAttendeesList(
                        props.match.params.timeslotId,
                        limit,
                        currentPage,
                    );
                    const attendeeList = attendees.concat(newAttendees.rows);
                    const sortedAttendee = getSortedAttendees(attendeeList);
                    const sortedAttendeeList = sortedAttendee?.length && [
                        ...new Map(sortedAttendee.map(x => [x.userId, x])).values(),
                    ];
                    setAttendees(sortedAttendeeList);
                    setIsFetching(false);
                    setPage(currentPage);
                    props.updateAttendeeList(sortedAttendeeList, currentPage);
                }, 1500);
            }
        }
        if (Auth.isUserAuthenticated() && !isFetching) {
            const attendees = await getAttendeesList(props.match.params.timeslotId, limit, page);
            const sortedAttendeeList = getSortedAttendees(attendees.rows);
            setAttendees(sortedAttendeeList);
            setIsFetching(false);
        }
    };
    const getSortedAttendees = attendeeList => {
        const sortedAttendeeList = attendeeList.sort((attendee1, attendee2) => {
            const name1 = `${attendee1.lastName} ${attendee1.firstName}`;
            const name2 = `${attendee2.lastName} ${attendee2.firstName}`;
            return name1.toLowerCase().localeCompare(name2.toLowerCase());
        });
        return sortedAttendeeList;
    };

    const handleResize = debounce(() => {
        setWindowHeight(window.innerHeight);
    }, 500);

    const getInnerHeight = elm => {
        const computed = getComputedStyle(elm),
            padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);

        return elm.clientHeight - padding;
    };

    useEffect(() => {
        if (!isFetching) return;

        getAttendees();
    }, [isFetching]);

    const handleScroll = () => {
        if (
            getInnerHeight(scroll.current) + scroll.current.scrollTop !==
                scroll.current.offsetHeight ||
            isFetching
        ) {
            return;
        }

        setIsFetching(true);
    };

    const filterAttendees = attendee => {
        const fullName = `${attendee.firstName} ${attendee.lastName}`.toLowerCase();

        if (filter.length < MINIMUM_SEARCH_CHAR_COUNT) {
            return true;
        }
        if (fullName.includes(filter.toLowerCase())) {
            return true;
        }
        if (
            attendee.companyName &&
            attendee.companyName.toLowerCase().includes(filter.toLowerCase())
        ) {
            return true;
        }
        if (attendee.jobTitle && attendee.jobTitle.toLowerCase().includes(filter.toLowerCase())) {
            return true;
        }

        return false;
    };

    const listItems =
        attendees &&
        attendees.filter(filterAttendees).map((attendee, index) => (
            <Item
                key={`attendee-item-${index}`}
                leftAvatar={
                    attendee.imageUrl ? (
                        <Avatar src={attendee.imageUrl} />
                    ) : (
                        <CustomAvatar>
                            {getInitials(attendee.firstName, attendee.lastName)}
                        </CustomAvatar>
                    )
                }
                primaryText={`${attendee.firstName} ${attendee.lastName}`}
                secondaryText={
                    attendee.jobTitle && attendee.companyName
                        ? `${attendee.jobTitle}, ${attendee.companyName}`
                        : attendee.jobTitle || attendee.companyName || ''
                }
                onClick={() => {
                    navigateTo({
                        match,
                        history,
                        location,
                        params: {
                            type: 'profile',
                            userId: attendee.userId,
                        },
                        state: {
                            timeslot: props.timeslot,
                        },
                    });
                }}
                data-qa={`attendees-item-${attendee.userId}`}
            />
        ));

    const content = () => (
        <ContentWrapper innerRef={scroll}>
            <Container onScroll={handleScroll}>
                <FlexRow sticky={filter ? 1 : 0}>
                    <SearchBar value={filter} setValue={setFilter} />
                </FlexRow>
                <ItemsContainer applymargin={filter ? 1 : 0}>{listItems}</ItemsContainer>
                {isFetching && attendees.length < totalAttendees && 'Loading more items...'}
            </Container>
        </ContentWrapper>
    );

    return (
        <Wrapper isfirefox={isFirefox ? 1 : 0} windowheight={windowHeight}>
            {content()}
        </Wrapper>
    );
};

export default withRouter(Attendees);

import { get } from 'lodash';

export const selectBackgroundUrl = state => {
    return get(state, 'exhibitor.bannerImage', null);
};

export const selectRepresentatives = state => {
    return get(state, 'exhibitor.representatives', []);
};

export const selectFullRepresentatives = state => {
    return get(state, 'fullRepresentatives', []);
};

export const selectTitle = state => {
    return get(state, 'exhibitor.title', []);
};

export const selectContentData = state => {
    const { exhibitor } = state;

    if (!exhibitor) {
        return null;
    }

    const {
        id,
        imageUrl,
        title,
        sections,
        subNameDetail: introText,
        slideshowImages,
        info,
        typeParams,
    } = exhibitor;
    const websiteUrl = get(exhibitor, 'params.websiteUrl', null);
    const websiteName = get(exhibitor, 'params.websiteName', null);
    const videoUrl = get(exhibitor, 'params.videoUrl', null);
    const sectionClassifiers = sections.filter(section => section.type === 'classifier');
    const classifiers = sectionClassifiers.reduce((acc, cls) => [...acc, ...(cls.items || [])], []);
    const sectionLocations = sections.filter(section => section.type === 'place');
    const locations = sectionLocations.reduce((acc, loc) => [...acc, ...(loc.items || [])], []);

    return {
        id,
        imageUrl,
        title,
        introText,
        info,
        websiteUrl,
        websiteName,
        videoUrl,
        classifiers,
        locations,
        slideshowImages,
        typeParams,
    };
};

export const selectShouldRenderResourcesTab = state => {
    const links = get(state, 'exhibitor.links', []);

    return links && links.length > 0;
};

export const selectShouldRenderScheduleTab = state => {
    const sections = get(state, 'exhibitor.sections', []);
    const timeslots = sections && sections.filter(i => i.type === 'timeslot');

    return timeslots && timeslots.length > 0 ? timeslots : false;
};

export const selectResources = state => {
    const sections = get(state, 'exhibitor.sections', []);
    const resources = sections.filter(section => section.type === 'link');
    if (resources && resources.length > 0) {
        resources.map(resource => {
            const { items } = resource;

            if (items && items.length > 0) {
                items.map(item => {
                    const ordering = getOrderItem(state, item.id);
                    item.ordering = ordering;
                });
            }
        });
    }
    return resources;
};

export const getOrderItem = (state, itemId) => {
    const links = get(state, 'exhibitor.links', []);
    const likWithGivenId = links.find(i => i._id === itemId);

    return likWithGivenId && likWithGivenId.ordering;
};

export const selectContactInfo = state => {
    const params = get(state, 'exhibitor.params');

    if (!params) {
        return {};
    }

    const {
        contactPhoneNumber,
        contactEmail,
        contactFacebookUrl,
        contactTwitterUrl,
        contactLinkedinUrl,
    } = params;

    return {
        contactPhoneNumber,
        contactEmail,
        contactFacebookUrl,
        contactTwitterUrl,
        contactLinkedinUrl,
    };
};

export const selectBusinessCardFeatureData = state => {
    const params = get(state, 'exhibitor.params');

    if (!params) {
        return {};
    }

    const { hasBusinessCardFeature, businessCardEmail } = params;

    return {
        hasBusinessCardFeature,
        businessCardEmail,
    };
};

export const selectShouldRenderContactTab = state => {
    const params = get(state, 'exhibitor.params');

    if (!params) {
        return false;
    }

    const {
        contactEmail,
        contactFacebookUrl,
        contactLinkedinUrl,
        contactPhoneNumber,
        contactTwitterUrl,
    } = selectContactInfo(state);
    const { hasBusinessCardFeature, businessCardEmail } = selectBusinessCardFeatureData(state);
    const hasContactInfo =
        !!contactEmail ||
        !!contactFacebookUrl ||
        !!contactLinkedinUrl ||
        !!contactPhoneNumber ||
        !!contactTwitterUrl;
    const isBusinessCardFeatureSetup = hasBusinessCardFeature && !!businessCardEmail;

    return hasContactInfo || isBusinessCardFeatureSetup;
};

export const selectPrivateChatParticipant = state => {
    const { fullRepresentatives, privateChatUserId } = state;

    if (!privateChatUserId) {
        return null;
    }

    return fullRepresentatives.find(rep => rep.id === privateChatUserId);
};

import { getPlaceHolderImage } from '../services/VirtualEventSession';

export const downloadImg = async ({ url }) => {
    return new Promise(resolve => {
        getPlaceHolderImage({ url }).then(imageData => {
            const downloadedImg = new Image();

            downloadedImg.onload = () => {
                resolve(downloadedImg);
            };
            downloadedImg.src = imageData.body;
        });
    });
};

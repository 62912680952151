import styled from 'styled-components';

export const ContentModalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px !important;
    font-family: Roboto, sans-serif !important;
    line-height: 24px !important;
    color: rgba(0, 0, 0, 0.87);

    svg {
        border-radius: 50%;
        cursor: pointer;
        padding: 8px;
        font-size: 40px !important;
        font-weight: bold;
        background: #f1f1f3;
        margin-right: 16px;
    }
`;

import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import {
    getVirtualEventPollSets,
    getVirtualEventPollSetsByEventAndItemIds,
    getVirtualEventSession,
} from '../../../../services/api/eureka';
import { parseVirtualEventSession } from '../../../../utils/sortUtils';
import { getTimeslot } from '../../services/session';
import Voting from '../vote/Voting';
import get from 'lodash/get';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { COLOR_WHITE } from '../../../../components/General/Variables';
import { getLocalAppStateAsync } from '../../../../services/api/db';
import { WaitingForVoting } from '../vote/VotingInProgress';

const VoteSession = props => {
    const [appState, setAppState] = useState({});
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();

    const { match } = props;
    const { timeslotId } = match.params;
    const sessionId = timeslotId;
    const answeredPolls = JSON.parse(localStorage.getItem('answeredPolls')) || [];

    const { pollSets: ps } = stateCtx;
    const pollSets = Array.isArray(ps) ? ps[0] : ps;
    const polls = get(pollSets, 'Polls', []);
    const activePoll = polls.find(poll => poll.active);
    const currentPollAnswer = activePoll && answeredPolls.find(pollId => pollId === activePoll.id);

    const refreshSession = async () => {
        const virtualEventSession = await getVirtualEventSession(timeslotId);

        if (!virtualEventSession || !virtualEventSession.VirtualEventUsers) {
            return false;
        }

        const virtualEventUserActive = virtualEventSession.VirtualEventUsers.find(
            virtualEventUser => virtualEventUser.isActive || virtualEventUser.hasVideoActive,
        );

        mutationCtx.setVirtualEventSession(virtualEventSession);

        if (virtualEventUserActive) {
            mutationCtx.setVirtualEventUser(virtualEventUserActive);
        } else {
            mutationCtx.setVirtualEventUser(null);
        }

        return true;
    };

    const updateData = data => {
        const newState = {};
        const { virtualEventSession } = data;

        if (virtualEventSession) {
            const parsedVirtualEventSession = parseVirtualEventSession(virtualEventSession);
            const virtualEventUsers = parsedVirtualEventSession.VirtualEventUsers;
            const virtualEventUserActive = virtualEventUsers.find(
                virtualEventUser => virtualEventUser.isActive || virtualEventUser.hasVideoActive,
            );

            newState.virtualEventSession = parsedVirtualEventSession;

            if (virtualEventUserActive) {
                newState.virtualEventUser = virtualEventUserActive;
            } else {
                newState.virtualEventUser = null;
            }
        }

        mutationCtx.setExtraState(newState);
    };

    const fetchPollSets = useCallback(
        async data => {
            if (data && data.pollSet) {
                mutationCtx.setPollSets(data.pollSet);
                return;
            }

            const virtualEventSession = await getVirtualEventSession(timeslotId);

            if (!virtualEventSession) {
                const resp = await getVirtualEventPollSetsByEventAndItemIds(
                    appState.eventId,
                    timeslotId,
                    true,
                );
                mutationCtx.setPollSets(resp);
                return;
            }

            try {
                const fetchedPollSets = await getVirtualEventPollSets(timeslotId);

                mutationCtx.setPollSets(fetchedPollSets);
            } catch (err) {
                console.log('PollSets fetch error', err);
            }
        },
        [timeslotId],
    );

    useEffect(() => {
        (async () => {
            await fetchPollSets();
        })();
    }, [fetchPollSets]);

    useEffect(() => {
        getLocalAppStateAsync().then(appState => {
            setAppState(appState);
        });
    }, []);

    useEffect(() => {
        const { socket } = stateCtx;

        if (!socket) {
            return;
        }

        const refreshEverythingForSession = async () => {
            await refreshSession();
            await fetchPollSets();
        };

        socket.on('connect', refreshEverythingForSession);
        socket.on(`updateData_${timeslotId}`, updateData);
        socket.on(`pollAnswer_${sessionId}`, fetchPollSets);
        socket.on(`updatePollSet_${sessionId}`, fetchPollSets);
        socket.on(`pollRefresh_${sessionId}`, fetchPollSets);

        return () => {
            if (socket) {
                socket.off('connect', refreshEverythingForSession);
                socket.removeAllListeners(`updateData_${timeslotId}`);
                socket.removeAllListeners(`pollAnswer_${sessionId}`);
                socket.removeAllListeners(`updatePollSet_${sessionId}`, fetchPollSets);
                socket.removeAllListeners(`pollRefresh_${sessionId}`);
            }
        };
    }, [stateCtx.socket, timeslotId]);

    useEffect(() => {
        getTimeslot(timeslotId, async (err, timeslot) => {
            if (err || !timeslot) {
                return;
            }

            mutationCtx.setTimeslot(timeslot);
        });
    }, [timeslotId]);

    if (!activePoll) {
        return <WaitingForVoting />;
    }

    return (
        <Stack
            paddingY={4}
            alignItems="center"
            style={{ backgroundColor: COLOR_WHITE }}
            height="100vh"
        >
            <Box
                width={{ xs: 'calc(100% - 32px)', sm: 600, md: 940 }}
                position="relative"
                paddingY={3}
            >
                <Voting
                    pollSets={pollSets}
                    stateCtx={stateCtx}
                    activePoll={activePoll}
                    showVoteResults={!!currentPollAnswer}
                    {...props}
                />
            </Box>
        </Stack>
    );
};

export default withRouter(VoteSession);

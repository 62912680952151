import { useCallback, useEffect, useMemo, useReducer } from 'react';
import moment from 'moment';
import get from 'lodash/get';

import { useVMState } from '../../virtualFeature/containers/main';
import { createOrUpdateNotification } from '../../virtualFeature/services/NotificationService';
// Uses tge global Talk manager for scenes/Talk
import TalkManager, { XMPPEvent } from '../../../../Talk/services';
import { createRequestTypes } from '../../../utils/reducerActions';
import { getGroupChatMessages, getGroupChatParticipants, startGroupChat } from '../api/eureka';
import { getNickAsInt } from '../utils';
import { acceptChatObjection, reportOrDeleteGroupChatMessage } from '../../../../Talk/api/eureka';
import entities from '../../../../VirtualSession/constants/entities';

const { virtualEventUserEntity } = entities;

const formatTime = timestamp => {
    return moment(parseInt(timestamp, 10)).locale('en').format('HH:mm');
};

const actionTypes = {
    SET_SERVER_ONLINE: 'SET_SERVER_ONLINE',
    NEW_MESSAGE: 'NEW_MESSAGE',
    SET_USER_HAS_SENT_MESSAGES: 'SET_USER_HAS_SENT_MESSAGES',
    LOAD_GROUP_CHAT_MESSAGES: createRequestTypes('LOAD_GROUP_CHAT_MESSAGES'),
    RELOAD_GROUP_CHAT_MESSAGES: createRequestTypes('RELOAD_GROUP_CHAT_MESSAGES'),
    LOAD_GROUP_PARTICIPANTS: createRequestTypes('LOAD_GROUP_PARTICIPANTS'),
    DELETE_GROUP_MESSAGE: createRequestTypes('DELETE_GROUP_MESSAGE'),
    SET_IS_USER_PARTICIPANT: 'SET_IS_USER_PARTICIPANT',
    SET_IS_LOADING_MESSAGES: 'SET_IS_LOADING_MESSAGES',
    SET_MESSAGE_TO_DELETE: 'SET_MESSAGE_TO_DELETE',
    HIDE_DELETE_MESSAGE_MODAL: 'HIDE_DELETE_MESSAGE_MODAL',
    MESSAGE_HAS_BEEN_DELETED: 'MESSAGE_HAS_BEEN_DELETED',
};

const initialState = {
    messages: [],
    userHasSentMessages: false,
    isLoadingMessages: true,
    isUserParticipant: false,
    participants: [],
    conversationHasMoreMessages: true,
    messageToDelete: null,
    showDeleteMessageModal: false,
    messageHasBeenDeleted: false,
};

const parseMessage = (msg, participant) => {
    const { id, txt, removedFromUser, removedFromModerator, timestamp } = msg;
    const createdAt = formatTime(timestamp * 0.001);

    return {
        id,
        body: txt,
        nick: msg.nick,
        user: {
            ...participant,
            displayName: `${participant.firstName} ${participant.lastName}`,
            initials: `${participant.firstName[0]}${participant.lastName[0]}`.toUpperCase(),
        },
        removedFromUser: !!removedFromUser,
        removedFromModerator: !!removedFromModerator,
        big: false,
        timestamp,
        createdAt,
    };
};

const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_SERVER_ONLINE:
            return {
                ...state,
                isServerOnline: action.payload.isServerOnline,
            };
        case actionTypes.NEW_MESSAGE: {
            const { message } = action.payload;
            const userId = getNickAsInt(message.nick);
            const participant = state.participants.find(p => p.UserId === userId).User;
            const messageParsed = parseMessage(message, participant);

            return {
                ...state,
                messages: [...state.messages, messageParsed],
            };
        }

        case actionTypes.SET_USER_HAS_SENT_MESSAGES:
            return {
                ...state,
                userHasSentMessages: action.payload.userHasSentMessages,
            };
        case actionTypes.LOAD_GROUP_CHAT_MESSAGES.REQUEST:
            return {
                ...state,
                isLoadingMessages: true,
            };
        case actionTypes.LOAD_GROUP_CHAT_MESSAGES.SUCCESS:
            return {
                ...state,
                isLoadingMessages: false,
                messages: [...action.payload.messages, ...state.messages],
                conversationHasMoreMessages: action.payload.conversationHasMoreMessages,
            };
        case actionTypes.LOAD_GROUP_CHAT_MESSAGES.FAILURE:
            return {
                ...state,
                isLoadingMessages: false,
                loadMessagesError: action.payload.error,
            };

        case actionTypes.RELOAD_GROUP_CHAT_MESSAGES.REQUEST:
            return {
                ...state,
                isLoadingMessages: true,
            };
        case actionTypes.RELOAD_GROUP_CHAT_MESSAGES.SUCCESS:
            return {
                ...state,
                isLoadingMessages: false,
                messages: action.payload.messages,
            };
        case actionTypes.RELOAD_GROUP_CHAT_MESSAGES.FAILURE:
            return {
                ...state,
                isLoadingMessages: false,
                loadMessagesError: action.payload.error,
            };
        case actionTypes.SET_IS_USER_PARTICIPANT:
            return {
                ...state,
                isUserParticipant: action.payload.isUserParticipant,
            };

        case actionTypes.LOAD_GROUP_PARTICIPANTS.REQUEST:
            return {
                ...state,
                loadingParticipants: true,
            };
        case actionTypes.LOAD_GROUP_PARTICIPANTS.SUCCESS:
            return {
                ...state,
                loadingParticipants: false,
                participants: [...state.participants, ...action.payload.participants],
            };
        case actionTypes.LOAD_GROUP_PARTICIPANTS.FAILURE:
            return {
                ...state,
                loadingParticipants: false,
                loadParticipantsError: action.payload.error,
            };
        case actionTypes.SET_IS_LOADING_MESSAGES:
            return {
                ...state,
                isLoadingMessages: action.payload,
            };
        case actionTypes.SET_MESSAGE_TO_DELETE:
            return {
                ...state,
                messageToDelete: action.payload,
                showDeleteMessageModal: true,
            };
        case actionTypes.HIDE_DELETE_MESSAGE_MODAL:
            return {
                ...state,
                showDeleteMessageModal: false,
            };
        case actionTypes.MESSAGE_HAS_BEEN_DELETED:
            return {
                ...state,
                messageHasBeenDeleted: new Date().getTime(),
            };
        default:
            return state;
    }
};

const useChat = ({
    chatUrl,
    itemId,
    itemName,
    moderatorOnly,
    pageSize = 20,
    nonVirtualQA = false,
}) => {
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        isServerOnline: TalkManager.connected,
    });
    const {
        isServerOnline,
        userHasSentMessages,
        participants,
        isUserParticipant,
        messages,
        conversationHasMoreMessages,
        messageHasBeenDeleted,
    } = state;

    const stateCtx = useVMState();
    const { externalObject, socket, virtualEventUser } = stateCtx;
    const eventId = externalObject.data.Event.reference;
    const virtualEventUserRole = get(virtualEventUser, 'data.role');
    const isVirtualEvenUserModerator =
        virtualEventUserRole === virtualEventUserEntity.role.moderator;
    const isVirtualEventUserRoundTableHost =
        virtualEventUserRole === virtualEventUserEntity.role.roundTableHost;

    const chatRoomId = useMemo(() => {
        if (!participants.length) {
            return undefined;
        }

        return participants[0].ChatRoomId;
    }, [participants]);

    const conversation = {
        jid: `${chatRoomId}@conference.${chatUrl}`,
        id: chatRoomId,
        roomId: itemId,
        title: itemName,
    };

    const currentUser = useMemo(() => {
        return JSON.parse(localStorage.getItem('user'));
    }, []);

    const sessionItem = {
        objectId: itemId,
        objectTitle: itemName,
        eventId,
        eventTitle: 'eureka_chat',
    };

    const handleGroupMessage = useCallback(
        async data => {
            if (chatRoomId !== data.conversation.id) {
                return;
            }

            if (!participants.find(p => p.UserId === data.message.participant)) {
                await loadParticipants();
            }

            dispatch({ type: actionTypes.NEW_MESSAGE, payload: { message: data.message } });
        },
        [chatRoomId, participants],
    );

    useEffect(() => {
        const handleServerStatus = ({ online }) => {
            dispatch({ type: actionTypes.SET_SERVER_ONLINE, payload: { isServerOnline: online } });
        };

        TalkManager.subscribeToEvent(XMPPEvent.ServerStatus, handleServerStatus);

        return () => {
            TalkManager.unsubscribeFromEvent(XMPPEvent.ServerStatus, handleServerStatus);
        };
    }, []);

    useEffect(() => {
        if (!chatRoomId) {
            return;
        }

        TalkManager.subscribeToEvent(XMPPEvent.GroupMessage, handleGroupMessage);

        if (socket && socket.removeAllListeners) {
            socket.on('updateActivityObjections', reloadMessagesWithTimeout);
        }

        return () => {
            TalkManager.unsubscribeFromEvent(XMPPEvent.GroupMessage, handleGroupMessage);
            if (socket && socket.removeAllListeners) {
                socket.removeAllListeners('updateActivityObjections', reloadMessagesWithTimeout);
            }
        };
    }, [chatRoomId, handleGroupMessage]);

    const loadParticipants = async (init = false) => {
        dispatch({ type: actionTypes.LOAD_GROUP_PARTICIPANTS.REQUEST });
        try {
            const roomParticipants = init
                ? await startGroupChat({
                      ...sessionItem,
                      moderatorOnly,
                  })
                : await getGroupChatParticipants({ objectId: itemId, moderatorOnly });
            dispatch({
                type: actionTypes.LOAD_GROUP_PARTICIPANTS.SUCCESS,
                payload: { participants: roomParticipants },
            });

            const isCurrentUserParticipant = !!roomParticipants.find(
                p => p.UserId === currentUser.id,
            );
            dispatch({
                type: actionTypes.SET_IS_USER_PARTICIPANT,
                payload: { isUserParticipant: isCurrentUserParticipant },
            });
            if (!roomParticipants || !roomParticipants.length) {
                dispatch({ type: actionTypes.SET_IS_LOADING_MESSAGES, payload: false });
            }
        } catch (error) {
            dispatch({ type: actionTypes.LOAD_GROUP_PARTICIPANTS.FAILURE, payload: { error } });
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            loadParticipants(true);
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        if (!chatRoomId || !isServerOnline) {
            return;
        }

        TalkManager.joinVMRoom({ jid: conversation.jid, userId: currentUser.id });
    }, [chatRoomId, isServerOnline]);

    useEffect(() => {
        if (!isServerOnline || !participants.length) {
            return;
        }

        loadMessages();
    }, [isServerOnline, participants]);

    const lastTimestamp = useMemo(() => {
        if (!messages.length) {
            return undefined;
        }

        return messages[0].timestamp;
    }, [messages]);

    const onSetMessageToDelete = (message, overrideChatRoomId) => {
        dispatch({
            type: actionTypes.SET_MESSAGE_TO_DELETE,
            payload: { message, chatRoomId: overrideChatRoomId ? overrideChatRoomId : chatRoomId },
        });
    };

    const onHideDeleteMessageModal = () => {
        dispatch({ type: actionTypes.HIDE_DELETE_MESSAGE_MODAL });
    };

    const onDeleteMessage = async () => {
        if (!state.messageToDelete) {
            return;
        }

        dispatch({ type: actionTypes.DELETE_GROUP_MESSAGE.REQUEST });

        try {
            const { chatObjectionId } = await reportOrDeleteGroupChatMessage(
                state.messageToDelete.message,
                state.messageToDelete.chatRoomId,
                true,
            );
            if (isVirtualEvenUserModerator || isVirtualEventUserRoundTableHost || nonVirtualQA) {
                await acceptChatObjection(chatObjectionId);
            }

            onHideDeleteMessageModal();
        } catch (error) {
            dispatch({ type: actionTypes.DELETE_GROUP_MESSAGE.FAILURE, payload: { error } });
        }
    };

    const getParsedGroupChatMessages = async () => {
        const room = conversation.jid;
        const response = await getGroupChatMessages({ room, limit: pageSize, lastTimestamp });
        let messagesResponse;
        try {
            messagesResponse = [
                ...response.filter(m => !m.removedFromModerator || isVirtualEvenUserModerator),
            ]
                .reverse()
                .map(msg => {
                    const userId = getNickAsInt(msg.nick);
                    const user = participants.find(p => p.UserId === userId);
                    const participant = user
                        ? user.User
                        : userId === currentUser.id
                        ? currentUser
                        : undefined;
                    return participant ? parseMessage(msg, participant) : undefined;
                });
        } catch (e) {
            console.log(e);
        }

        return messagesResponse.filter(m => m);
    };

    const reloadMessages = async () => {
        if (!conversationHasMoreMessages || !conversation.jid) {
            return;
        }

        dispatch({ type: actionTypes.RELOAD_GROUP_CHAT_MESSAGES.REQUEST });

        try {
            const msgs = await getParsedGroupChatMessages();

            dispatch({
                type: actionTypes.RELOAD_GROUP_CHAT_MESSAGES.SUCCESS,
                payload: {
                    messages: msgs,
                    conversationHasMoreMessages: msgs.length === pageSize,
                },
            });
            dispatch({ type: actionTypes.MESSAGE_HAS_BEEN_DELETED });
        } catch (error) {
            dispatch({ type: actionTypes.RELOAD_GROUP_CHAT_MESSAGES.FAILURE, payload: { error } });
        }
    };

    const reloadMessagesWithTimeout = async () => {
        setTimeout(reloadMessages, 1000);
        // we do an extra call to make sure that we display the correct data after a chat objection was made
        setTimeout(reloadMessages, 2000);
    };

    const loadMessages = async () => {
        if (!conversationHasMoreMessages || !conversation.jid) {
            return;
        }

        dispatch({ type: actionTypes.LOAD_GROUP_CHAT_MESSAGES.REQUEST });

        try {
            const messagesResponse = await getParsedGroupChatMessages();
            const userHasSentMessages = messagesResponse.some(
                m => m && m.user && m.user.id === currentUser.id,
            );
            dispatch({
                type: actionTypes.SET_USER_HAS_SENT_MESSAGES,
                payload: { userHasSentMessages },
            });

            const msgs = messagesResponse.filter(m => m);

            dispatch({
                type: actionTypes.LOAD_GROUP_CHAT_MESSAGES.SUCCESS,
                payload: {
                    messages: msgs,
                    conversationHasMoreMessages: msgs.length === pageSize,
                },
            });
        } catch (error) {
            dispatch({ type: actionTypes.LOAD_GROUP_CHAT_MESSAGES.FAILURE, payload: { error } });
        }
    };

    const startConversation = async pendingMessageData => {
        if (sessionItem && sessionItem.objectId) {
            try {
                const roomParticipants = await startGroupChat({
                    ...sessionItem,
                    moderatorOnly,
                });
                dispatch({
                    type: actionTypes.LOAD_GROUP_PARTICIPANTS.SUCCESS,
                    payload: { participants: roomParticipants },
                });
                dispatch({
                    type: actionTypes.SET_IS_USER_PARTICIPANT,
                    payload: { isUserParticipant: true },
                });

                await TalkManager.joinVMRoom({ jid: conversation.jid, userId: currentUser.id });
                // after started a conversation the message can be sent
                await TalkManager.sendVMMessage(pendingMessageData);
                dispatch({
                    type: actionTypes.SET_USER_HAS_SENT_MESSAGES,
                    payload: { userHasSentMessages: true },
                });
            } catch (e) {
                console.log(e);
            }
        }
    };

    const sendMessage = async text => {
        const messageData = {
            to: conversation.jid,
            message: text,
        };

        createOrUpdateNotification({
            subtitle: text,
            type: 'chat',
            params: {
                isPrivateMessage: false,
                roomId: conversation.roomId || conversation.objectId,
                roomName: itemName,
            },
        });

        if (participants?.length) {
            participants.map(user => {
                if (user.UserId !== currentUser.id) {
                    if (socket) {
                        socket.emit('messageNotification', {
                            chatId: conversation.id,
                            senderId: currentUser.id,
                            userId: user.UserId,
                            type: 'chat',
                            roomId: conversation.roomId,
                        });
                    }
                }
            });
        }

        if (!isUserParticipant) {
            // when user is not a participant yet it needs to
            // start a conversation (become a participant) before sending message
            startConversation(messageData);
        } else {
            await TalkManager.sendVMMessage(messageData);
            if (!userHasSentMessages) {
                dispatch({
                    type: actionTypes.SET_USER_HAS_SENT_MESSAGES,
                    payload: { userHasSentMessages: true },
                });
            }
        }
    };

    return [
        { ...state, chatRoomId, messageHasBeenDeleted },
        {
            sendMessage,
            loadMessages,
            onSetMessageToDelete,
            onHideDeleteMessageModal,
            onDeleteMessage,
        },
    ];
};

export default useChat;

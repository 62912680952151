import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import StreamPlayer from '../common/StreamPlayer';
import { useVMMutation, useVMState } from '../../containers/main';
import { colorTheme } from '../../constants/colors';
import { startRecording, stopRecording } from '../../services/VirtualEventSession';
import { getTimer, sleep } from '../../../../utils';
import {
    CancelModalButton,
    ContentModalContainer,
    SaveModalButton,
} from '../moderator/common/styles';
import ConfirmationDialog from '../common/ConfirmatonDialog';
import { useTheme } from '../../../../../../components/Theme/ThemeContext';
import HostedSessionMediaPlayer from '../common/HostedSessionMediaPlayer';

const Wrapper = styled.div`
    flex-shrink: 0;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    align-items: center;
    aspect-ratio: 16 / 9;

    ${props =>
        props.inverse
            ? `
        width: calc(100% - 32px);
        `
            : `
        height: calc(100% - 48px);
        margin-top: -16px;
    `}
`;

const StreamWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
`;

const RecordingContainer = styled.div`
    position: absolute;
    z-index: 23;
    bottom: 24px;
    left: calc(50% - 62px);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 124px;
    width: 160px;
    padding-top: ${props => (props.smallpaddingtop ? '8px' : '18px')};
    background-color: rgba(64, 64, 64, 0.8);
    border-radius: 8px;
`;

const RecordingStatus = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    color: ${colorTheme.WHITE};
    margin-bottom: ${props => (props.nomargin ? 0 : '16px')};
`;

const RecordButtonWrapper = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid ${colorTheme.WHITE};
    height: 50px;
    width: 50px;
    overflow: hidden;
    background-color: transparent;
`;

const PlayRecordingButton = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: ${colorTheme.SYSTEM_ERROR};
    transform: translateY(0.5px);
    cursor: pointer;
`;

const TimerValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: rgba(114, 114, 114, 1);
    transform: translateY(0.5px);
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 21px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: ${colorTheme.WHITE};
`;

const StopRecordingButton = styled.div`
    height: 20px;
    width: 20px;
    background-color: ${colorTheme.SYSTEM_ERROR};
    border-radius: 4px;
    cursor: pointer;
`;

const RecordingTimer = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(114, 114, 114, 1);
    margin-bottom: 8px;
`;

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    padding: 0 25px;
    margin-bottom: -3px;
`;

const VIEWPORT_HEIGHT_EXTRAS = 154; // = 84 (footer) + 64 (header) + 16 (vertical padding)

const PreRecordingView = () => {
    const { theme } = useTheme();
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const {
        virtualEventUser,
        socket,
        sessionId,
        streams,
        localStream,
        timer,
        prerecording,
        virtualEventSession,
        isReviewingVideo,
    } = stateCtx;
    const isWindows = window.navigator.appVersion.indexOf('Win') !== -1;
    const desiredAspectRatio = 16 / 9;
    const windowAspectRatio = window.innerWidth / (window.innerHeight - VIEWPORT_HEIGHT_EXTRAS);
    const isDesiredAspectRatio = windowAspectRatio > desiredAspectRatio;
    const currentUserId = get(virtualEventUser, 'data.UserId') || 0;
    const isUserRecording = get(virtualEventUser, 'data.recordingInfo.recording', false);
    const startTimerMoment = get(virtualEventUser, 'data.recordingInfo.startTimerMoment', null);
    const preRecordedPresentationUrl = get(virtualEventUser, 'data.preRecordedPresentationUrl');
    const hasPreRecording = !!preRecordedPresentationUrl;
    const recordingInfo = get(virtualEventUser, 'data.recordingInfo');
    const hasUploadedVideo = !!recordingInfo;
    const uploadedVideoUrl = get(virtualEventUser, 'data.recordingInfo.videoUrl', '');
    const videoUrls = get(virtualEventSession, 'data.videoUrls') || [];
    const list = get(virtualEventUser, 'data.recordingInfo.list', []);
    const isUploadedVideo = uploadedVideoUrl && !list.length;
    const videoInfo = list.find(info => info.type === 'video');
    const preRecordedVideo =
        preRecordedPresentationUrl &&
        videoInfo &&
        videoInfo.sid &&
        `${preRecordedPresentationUrl}/video/${videoInfo.sid}_${sessionId}.m3u8`;
    const videoUrl = isUploadedVideo ? uploadedVideoUrl : preRecordedVideo;
    const videoIsSubmitted = videoUrls.length > 0;
    const hasPreliminaryVideo = hasPreRecording || (hasUploadedVideo && uploadedVideoUrl);
    const shouldDisplayReviewVideoModal =
        hasPreliminaryVideo && !videoIsSubmitted && !isReviewingVideo;
    const isPrerecording = get(virtualEventUser, 'data.isPrerecording', false);
    const [timerValue, setTimerValue] = useState(-2);
    const [time, setTime] = useState('00:00');
    const [isWindowDesiredAspectRatio, setIsWindowDesiredAspectRatio] = useState(
        isDesiredAspectRatio,
    );
    const intervalRef = useRef(0);
    const secondsPassedRef = useRef(0);

    const onResize = debounce(() => {
        const desiredAspectRatio = 16 / 9;
        const windowAspectRatio = window.innerWidth / (window.innerHeight - VIEWPORT_HEIGHT_EXTRAS);

        setIsWindowDesiredAspectRatio(windowAspectRatio > desiredAspectRatio);
    }, 500);

    const streamToUse = useMemo(() => streams.find(st => st.streamId === currentUserId), [
        streams,
        localStream,
    ]);

    const startCountDown = async callback => {
        if (!stateCtx.useDevices) {
            mutationCtx.startUsingDevices();
        }

        mutationCtx.setTimer({
            callback,
            time: 5,
        });

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isVideoOn: true,
                        isMicrophoneOn: true,
                    },
                ],
            },
        });
    };

    const startPreRecording = async () => {
        const userId = get(virtualEventUser, 'data.UserId');

        try {
            mutationCtx.startPreRecording();
            await startRecording(sessionId, {
                preRecording: true,
                userId,
            });
            socket.emit('updateData', { objectId: sessionId });
        } catch (err) {
            mutationCtx.stopPreRecording();
        }
    };

    const stopPreRecording = async () => {
        const userId = get(virtualEventUser, 'data.UserId');

        mutationCtx.stopPreRecording();

        secondsPassedRef.current = 0;
        setTime('00:00');

        await sleep(2000);
        await stopRecording(sessionId, { preRecording: true, userId });

        socket.emit('updateData', { objectId: sessionId });
    };

    const toggleRecordingStatus = () => {
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isPrerecording: !isPrerecording,
                    },
                ],
            },
            userId: virtualEventUser.data.UserId,
        });
    };

    const addVideoToRoom = () => {
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isPrerecording: !isPrerecording,
                    },
                ],
                videoUrls: [videoUrl],
            },
            userId: virtualEventUser.data.UserId,
        });
    };

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        if (!timer) {
            setTimerValue(-2);
            return;
        }

        setTimerValue(timer.time);
        intervalRef.current = setInterval(() => {
            setTimerValue(val => val - 1);
        }, 1000);

        return () => clearInterval(intervalRef.current);
    }, [timer]);

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        if (!isPrerecording || !prerecording || !isUserRecording) {
            setTime('00:00');
            secondsPassedRef.current = 0;
            return;
        }

        if (startTimerMoment) {
            intervalRef.current = setInterval(() => {
                secondsPassedRef.current = secondsPassedRef.current + 1;
                setTime(getTimer(secondsPassedRef.current * 1000));
            }, 1000);
        }

        return () => clearInterval(intervalRef.current);
    }, [prerecording, isPrerecording, isUserRecording, startTimerMoment]);

    useEffect(() => {
        if (timer && timerValue === -1) {
            const { callback } = timer;

            callback();
            mutationCtx.setTimer(null);
        }
    }, [timer, timerValue]);

    useEffect(() => {
        window.addEventListener('resize', onResize, true);

        return () => window.removeEventListener('resize', onResize, true);
    }, []);

    return (
        <Wrapper isWindows={isWindows} inverse={!isWindowDesiredAspectRatio ? 1 : 0}>
            {!isReviewingVideo && (
                <StreamWrapper>
                    {streamToUse && (
                        <>
                            <StreamPlayer
                                key={streamToUse.streamId}
                                className={'main-stream-profile'}
                                showProfile={stateCtx.profile}
                                isVideoOn={true}
                                muted
                                stream={streamToUse}
                                uid={streamToUse.getId()}
                                domId={`stream-player-${streamToUse.getId()}`}
                            />
                            {timerValue < 0 &&
                                !prerecording &&
                                !isUserRecording &&
                                !shouldDisplayReviewVideoModal && (
                                    <RecordingContainer>
                                        <RecordingStatus>Record video</RecordingStatus>
                                        <RecordButtonWrapper
                                            onClick={() => startCountDown(startPreRecording)}
                                            onKeyDown={event => {
                                                if (event.key === 'Enter') {
                                                    startCountDown(startPreRecording);
                                                }
                                            }}
                                        >
                                            <PlayRecordingButton />
                                        </RecordButtonWrapper>
                                    </RecordingContainer>
                                )}
                            {timerValue >= 0 &&
                                !prerecording &&
                                !isUserRecording &&
                                !shouldDisplayReviewVideoModal && (
                                    <RecordingContainer>
                                        <RecordingStatus>Recording in</RecordingStatus>
                                        <RecordButtonWrapper>
                                            <TimerValue data-qa="pre-recording-timer-countdown">
                                                <span>{timerValue}</span>
                                            </TimerValue>
                                        </RecordButtonWrapper>
                                    </RecordingContainer>
                                )}
                            {prerecording && isUserRecording && !shouldDisplayReviewVideoModal && (
                                <RecordingContainer smallpaddingtop>
                                    <RecordingStatus nomargin>Recording</RecordingStatus>
                                    <RecordingTimer>{time}</RecordingTimer>
                                    <RecordButtonWrapper onClick={stopPreRecording}>
                                        <StopRecordingButton />
                                    </RecordButtonWrapper>
                                </RecordingContainer>
                            )}
                        </>
                    )}
                </StreamWrapper>
            )}
            {isReviewingVideo && videoUrl && <HostedSessionMediaPlayer url={videoUrl} primary />}
            <ConfirmationDialog
                open={shouldDisplayReviewVideoModal}
                title="Add introduction video"
                titleIcon="play_circle"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                outlinedIcon
                withCloseButton
                content={
                    <ExtraStyledModalContentContainer>
                        Do you want to add the introduction video directly or review it first?
                    </ExtraStyledModalContentContainer>
                }
                onClose={toggleRecordingStatus}
                buttons={[
                    <SaveModalButton
                        key="AVDA"
                        flat
                        onClick={addVideoToRoom}
                        backgroundcolor={theme.primary}
                        width={206}
                        noTextCapitalize
                    >
                        Add as introduction video
                    </SaveModalButton>,
                    <CancelModalButton
                        key="RVDR"
                        flat
                        onClick={() => mutationCtx.setIsReviewingVideo(true)}
                        noTextCapitalize
                    >
                        Review video
                    </CancelModalButton>,
                ]}
            />
        </Wrapper>
    );
};

export default PreRecordingView;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import UserInteraction from '../../moderator/userInteraction';
import * as palette from '../../../../../../../components/General/Variables';

const Wrapper = styled.div`
    flex: 2;
    overflow: scroll;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 16px;
`;

const InteractionWrapper = styled.div`
    flex: 3;
    height: 100%;
    min-height: 0;
    width: 100%;
    @media only screen and (max-width: ${palette.MAX_TABLET}) {
        bottom: 115px;
    }
`;
function LeftContent() {
    const [interactionWindowHeight, setInteractionWindowHeight] = useState(null);

    const wrapperRef = useRef();
    const handleResize = () => {
        const wrapperHeight =
            wrapperRef.current && wrapperRef.current.getBoundingClientRect().height;
        const interactionHeight = window.innerHeight - wrapperHeight;
        setInteractionWindowHeight(
            window.innerHeight < 900 ? interactionHeight : interactionHeight + 200,
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Wrapper innerRef={wrapperRef}>
            <InteractionWrapper
                interactionWindowHeight={interactionWindowHeight}
                interactionInitialHeight={!interactionWindowHeight && window.innerHeight}
            >
                <UserInteraction controls={true} nonVirtualQA />
            </InteractionWrapper>
        </Wrapper>
    );
}

LeftContent.propTypes = {};

export default LeftContent;

import React, { useEffect } from 'react';
import styled from 'styled-components';

import SessionInfo from '../common/SessionInfo';
import { useVMMutation, useVMState } from '../../containers/main';
import UploadSlidesAndPoster from './UploadSlidesAndPoster';
import entities from '../../constants/entities';
import ActivePresenter from './ActivePresenter';
import Slides from '../presenter/LeftContent/Slides';
import Poster from './Poster';
import StreamPlayer from '../common/StreamPlayer';
import useResize from '../../../../../VirtualSession/hooks/useResize';

const Wrapper = styled.div`
    margin: 0 16px 0 0;
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    overflow-y: scroll;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.05);
`;

const FlexRow = styled.div`
    display: flex;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
`;

const SessionVoteAndAsk = styled.div`
    flex: 1;
`;

const ActivePresenterWrapper = styled.div`
    width: 100%;
    min-height: 468px;
`;

const ScreenSharingWrapper = styled.div`
    width: 100%;
    .stream-player.video-on {
        height: 100%;
        width: 100%;
    }
`;

const SessionInteraction = () => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const { virtualEventUser, screenShareStream, shareType } = stateCtx;

    const [resizeRef] = useResize();
    const isScreenSharing = screenShareStream && shareType === 'screen';

    useEffect(() => {
        if (virtualEventUser.uploadRequestStatus === entities.requestStatus.loading) {
            mutationCtx.setShouldCalculatePosition(true);
        }
    }, [virtualEventUser]);

    return (
        <Wrapper>
            <FlexRow>
                {!virtualEventUser.data.mode && <UploadSlidesAndPoster />}

                {!isScreenSharing &&
                    virtualEventUser.data.mode === entities.virtualEventUserEntity.mode.video && (
                        <ActivePresenterWrapper>
                            <ActivePresenter />
                        </ActivePresenterWrapper>
                    )}

                {!isScreenSharing &&
                    virtualEventUser.data.mode === entities.virtualEventUserEntity.mode.slide && (
                        <Slides showActions hideDeleteButton />
                    )}

                {!isScreenSharing &&
                    virtualEventUser.data.mode === entities.virtualEventUserEntity.mode.poster && (
                        <Poster />
                    )}

                {isScreenSharing && (
                    <ScreenSharingWrapper innerRef={resizeRef}>
                        <StreamPlayer
                            key={screenShareStream.getId()}
                            className={'main-stream-profile'}
                            showProfile={stateCtx.profile}
                            isVideoOn={true}
                            local={true}
                            muted
                            stream={screenShareStream}
                            uid={screenShareStream.getId()}
                            domId={`stream-player-${screenShareStream.getId()}`}
                        />
                    </ScreenSharingWrapper>
                )}
            </FlexRow>
            <FlexRow>
                <SessionInfo />
                <SessionVoteAndAsk />
            </FlexRow>
        </Wrapper>
    );
};

export default SessionInteraction;

import React from 'react';
import { ListItem } from 'react-md';
import styled from 'styled-components';

import { formatLastMessageDate } from '../../../services/api/data';
import {
    PrimaryTitle,
    Timestamp,
    SecondaryTitle,
    listContentStyle,
    SecondaryTitleMessage,
} from './styles';
import ThemeContext from '../../Theme/ThemeContext';
import UserAvatar from '../../General/UserAvatar';

const Item = styled(ListItem)`
    margin-top: 12px;
    margin-bottom: 12px;

    & .md-list-tile {
        padding-left: 8px;
    }

    & .md-list-tile--avatar {
        height: 56px;
    }

    & .md-avatar {
        border: none;
    }
`;

const NewMessagesIndicator = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${props => props.color || 'red'};
    position: absolute;
    top: 8px;
    right: 8px;
`;

const Private = ({ conversation }) => {
    const { lastMessage, participant, unreadCount } = conversation;
    const messageTimestamp = formatLastMessageDate(lastMessage.timestamp);
    const title = participant.displayName || `${participant.firstName} ${participant.lastName}`;

    const Secondary = ({ message, timestamp }) => (
        <SecondaryTitle>
            <SecondaryTitleMessage>{message}</SecondaryTitleMessage>
            <Timestamp>{timestamp}</Timestamp>
        </SecondaryTitle>
    );

    return (
        <ThemeContext.Consumer>
            {({ theme }) =>
                participant &&
                participant.firstName && (
                    <Item
                        key={'conversation_private_' + conversation.id}
                        tabIndex={-1}
                        contentStyle={listContentStyle}
                        primaryText={<PrimaryTitle>{title}</PrimaryTitle>}
                        secondaryText={
                            <Secondary message={lastMessage.txt} timestamp={messageTimestamp} />
                        }
                        leftAvatar={<UserAvatar user={participant} size={56} />}
                        rightAvatar={
                            unreadCount > 0 && <NewMessagesIndicator color={theme.contrast} />
                        }
                        avatarcolor={participant.color}
                        threeLines
                    />
                )
            }
        </ThemeContext.Consumer>
    );
};

export default Private;

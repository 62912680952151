import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSocket } from '../../../components/Session/SocketContext';

import {
    getMySessions,
    getIsLoadingSessions,
    getSessionsError,
    getAllMySessions,
} from '../selectors';
import { loadModeratedSessions, markSessionsAsSeen } from '../actions';
import Auth from '../../../services/api/auth';

const useMyModeratedSessions = eventId => {
    const mySessions = useSelector(getMySessions);
    const allSessions = useSelector(getAllMySessions);
    const isLoading = useSelector(getIsLoadingSessions);
    const error = useSelector(getSessionsError);
    const dispatch = useDispatch();
    const { socket } = useSocket();

    useEffect(() => {
        const user = Auth.getUser();
        const isModerator = user && user.role === 'moderator';
        if (eventId && isModerator) {
            dispatch(loadModeratedSessions(eventId));
        }
    }, [dispatch, eventId]);

    const onSelectSession = session => {
        const isVirtual = false;
        dispatch(markSessionsAsSeen(session.reference));
        if (isVirtual) {
            socket.emit('refreshAttendees', { sessionId: session.reference });
        }
    };

    return {
        mySessions,
        isLoading,
        error,
        onSelectSession,
        allSessions,
    };
};

export default useMyModeratedSessions;

import React from 'react';

import { FontIcon } from 'react-md';
import * as palette from '../../../../components/General/Variables';
import * as StyledComponent from '../../style/modalsStyle';
import { StyledForm } from '../../style';
import * as string from '../../constants';
import { getString } from '../../../../services/api/store';
import { convertHexToRGBA } from '../../../../components/General/Colors';

const SettingsContent = ({
    theme,
    options,
    afterSignUp,
    dialogTimezone,
    updateTimezone,
    handleChange,
    state,
    isSociety,
}) => {
    const SETTINGS_SUBTITLE =
        getString('profileOnboardingCompleteMessage') || string.SETTINGS_SUBTITLE;
    const ATTENDEE_VISIBILITY_TITLE =
        getString('profileVisibleAtteendee') || string.ATTENDEE_VISIBILITY_TITLE;
    const ATTENDEE_VISIBILITY_DESCRIPTION =
        getString('profileVisibleAtteendeeDescription') || string.ATTENDEE_VISIBILITY_DESCRIPTION;
    const CHAT_APPOINTMENTS_AVAILABLE_TITLE =
        getString('profileAllowChatTitle') || string.CHAT_APPOINTMENTS_AVAILABLE_TITLE;
    const CHAT_APPOINTMENTS_AVAILABLE_DESCRIPTION =
        getString('profileAllowChatDescription') || string.CHAT_APPOINTMENTS_AVAILABLE_DESCRIPTION;
    const STEPS = getString('profileOnboardingSettingsStep') || string.SETTINGS_STEPS;
    const EVENT_TIMEZONE_TITLE =
        getString('programmeFiltersSectionTimezone') || string.EVENT_TIMEZONE_TITLE;
    const EVENT_TIMEZONE_SUBTITLE =
        getString('settingsTimezoneMessage') || string.EVENT_TIMEZONE_SUBTITLE;

    const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;

    return (
        <StyledForm
            maincolor={theme.primary}
            horizontalMargin={isDesktop && '0px'}
            minHeight="200px"
        >
            {afterSignUp && (
                <StyledComponent.SectionContainer>
                    <StyledComponent.WideDivider />
                    <StyledComponent.StepsText>{STEPS}</StyledComponent.StepsText>
                    <StyledComponent.ProfileInfoText>
                        {SETTINGS_SUBTITLE}
                    </StyledComponent.ProfileInfoText>
                </StyledComponent.SectionContainer>
            )}
            {!isSociety && (
                <>
                    <StyledComponent.WideDivider />
                    <StyledComponent.SectionContainer>
                        <StyledComponent.TitleContainer>
                            <StyledComponent.IconWrapper>
                                <FontIcon style={StyledComponent.iconStyle}>
                                    {palette.AIRPLANE}
                                </FontIcon>
                            </StyledComponent.IconWrapper>
                            <StyledComponent.SettingsSectionText>
                                {EVENT_TIMEZONE_TITLE}
                            </StyledComponent.SettingsSectionText>
                        </StyledComponent.TitleContainer>
                        <StyledComponent.Content>
                            <StyledComponent.SectionDetails>
                                {EVENT_TIMEZONE_SUBTITLE}
                            </StyledComponent.SectionDetails>
                            <StyledComponent.StyledSelectField
                                id="select-timezone"
                                label={EVENT_TIMEZONE_TITLE}
                                menuItems={options}
                                value={dialogTimezone}
                                onChange={value => {
                                    updateTimezone(value);
                                }}
                                fullWidth
                            />
                        </StyledComponent.Content>
                    </StyledComponent.SectionContainer>

                    {!theme.kiosk && (
                        <>
                            <StyledComponent.WideDivider />
                            <StyledComponent.SectionContainer>
                                <StyledComponent.TitleContainer>
                                    <StyledComponent.IconWrapper>
                                        <FontIcon style={StyledComponent.iconStyle}>
                                            {palette.ICON_EYE}
                                        </FontIcon>
                                    </StyledComponent.IconWrapper>
                                    <StyledComponent.SettingsSectionText>
                                        {ATTENDEE_VISIBILITY_TITLE}
                                    </StyledComponent.SettingsSectionText>
                                    <StyledComponent.Switch
                                        id="profileVisible"
                                        type="switch"
                                        checked={state.profileVisible}
                                        onChange={e => handleChange(e, 'profileVisible')}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                handleChange(
                                                    !state.profileVisible,
                                                    'profileVisible',
                                                );
                                            }
                                        }}
                                        name="profileVisible"
                                        color={theme.primary}
                                        colorWithOpacity={convertHexToRGBA(theme.primary, 0.5)}
                                    />
                                </StyledComponent.TitleContainer>
                                <StyledComponent.Divider />
                                <StyledComponent.Content>
                                    <StyledComponent.SectionDetails>
                                        {ATTENDEE_VISIBILITY_DESCRIPTION}
                                    </StyledComponent.SectionDetails>
                                </StyledComponent.Content>
                            </StyledComponent.SectionContainer>
                        </>
                    )}
                </>
            )}
            {!theme.kiosk && (
                <>
                    <StyledComponent.WideDivider />
                    <StyledComponent.SectionContainer>
                        <StyledComponent.TitleContainer>
                            <StyledComponent.IconWrapper>
                                <FontIcon style={StyledComponent.iconStyle}>
                                    {palette.QA_ICON}
                                </FontIcon>
                            </StyledComponent.IconWrapper>
                            <StyledComponent.SettingsSectionText>
                                {CHAT_APPOINTMENTS_AVAILABLE_TITLE}
                            </StyledComponent.SettingsSectionText>
                            <StyledComponent.Switch
                                id="chatAvailable"
                                type="switch"
                                checked={state.chatAvailable}
                                onChange={e => handleChange(e, 'chatAvailable')}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        handleChange(!state.chatAvailable, 'chatAvailable');
                                    }
                                }}
                                name="chatAvailable"
                                color={theme.primary}
                                colorWithOpacity={convertHexToRGBA(theme.primary, 0.5)}
                            />
                        </StyledComponent.TitleContainer>
                        <StyledComponent.Divider />
                        <StyledComponent.Content>
                            <StyledComponent.SectionDetails>
                                {CHAT_APPOINTMENTS_AVAILABLE_DESCRIPTION}
                            </StyledComponent.SectionDetails>
                        </StyledComponent.Content>
                    </StyledComponent.SectionContainer>
                </>
            )}
        </StyledForm>
    );
};

export default SettingsContent;

export const openExternalLink = url => {
    if (!url.match(/^https?:\/\//i) && !url.match(/^mailto:?:\/\//i)) {
        url = 'http://' + url;
    }
    if (url.match(/^mailto:?:\/\//i)) {
        const email = url.replace('mailto://', '');
        window.open(`mailto:${email}`);
    }

    window.open(url, '_blank');
};

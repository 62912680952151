import { useEffect, useMemo } from 'react';

import { createStreamFromUser } from '../utils/Stream';
import { useVMState, useVMMutation } from '../containers/main';

export default function useStream(client, audioOnlyForRemote) {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();

    const { streams } = stateCtx;
    const [localStream, currentStream] = [stateCtx.localStream, stateCtx.currentStream];

    const otherStreams = useMemo(
        () =>
            currentStream &&
            stateCtx.streams.filter(stream => stream.getId() !== currentStream.getId()),
        [streams, currentStream],
    );

    useEffect(() => {
        if (!client) {
            return;
        }
        mutationCtx.setVideoClient(client);
    }, [client]);

    useEffect(() => {
        if (!client) {
            return;
        }
        const volumeInterval = setInterval(() => {
            let streamsArray = [...streams];

            if (currentStream) {
                streamsArray = [...streamsArray, currentStream];
            }

            mutationCtx.setVolumeIndicators(streamsArray);
        }, 500);

        return () => clearInterval(volumeInterval);
    }, [streams]);

    useEffect(() => {
        if (!client) {
            return;
        }
        const addRemoteStream = async (remoteUser, mediaType) => {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

            if (audioOnlyForRemote && mediaType === 'video' && !isSafari) {
                return;
            }

            await client.subscribe(remoteUser, mediaType);
            const streamObj = createStreamFromUser(remoteUser);

            mutationCtx.addStream({ stream: streamObj });
            client.setStreamFallbackOption(streamObj, 0);
        };

        if (client && client._subscribed === false) {
            client.on('volume-indicator', evt => {
                const volumeIndicators = evt.attr || [];

                mutationCtx.setVolumeIndicators(volumeIndicators);
            });
            client.on('localStream-added', mutationCtx.addLocal);
            client.on('localStream-removed', () => mutationCtx.setLocalStream(null));
            client.on('stream-published', mutationCtx.addStream);
            client.on('user-published', addRemoteStream);
            client.on('user-unpublished', user => {
                if (!user.audioTrack && !user.videoTrack) {
                    mutationCtx.removeStream(user);
                }
            });
            client.on('user-left', user => {
                mutationCtx.removeStreamById(user);
            });
            client._subscribed = true;
        }
    }, [client, mutationCtx, localStream, streams]);

    if (!client) {
        return [];
    }

    return [localStream, currentStream, otherStreams];
}

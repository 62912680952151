import styled from 'styled-components';

const Link = styled.div`
    align-self: flex-end;
    margin: 0;
    color: #1fa294;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    text-decoration: underline;
`;

export default Link;

import React from 'react';
import styled from 'styled-components';
import Loader from './Loader';

const LoaderTransparentWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #aab5cc8c;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    & > div {
        margin: 0 !important;
    }
`;

const CenteredLoader = styled(Loader)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const BeautifulLoader = ({ children, active, size = 38 }) => {
    return (
        <>
            {children}
            {active && (
                <LoaderTransparentWrapper>
                    <CenteredLoader />
                </LoaderTransparentWrapper>
            )}
        </>
    );
};

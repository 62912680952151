export const getContentWithoutURL = (type, item) => {
    if (type !== 'booth') {
        let locationsString = '';
        if (item?.locations?.items?.length) {
            item?.locations?.items.forEach((loc, index) => {
                if (index > 0) {
                    locationsString += ', ';
                }
                locationsString += loc.name;
            });
        }
        if (type !== 'classifier' && type !== 'institution') {
            return `
${item.name}

${item.time}
${locationsString && 'Location: ' + locationsString}
            `;
        } else {
            return `
${item.name}

${locationsString && 'Location:' + locationsString}
            `;
        }
    } else {
        let locationsString = '';
        if (item?.locations?.length) {
            item?.locations.forEach((loc, index) => {
                if (index > 0) {
                    locationsString += ', ';
                }
                locationsString += loc.name;
            });
        }
        return `
${item.name}

${locationsString && 'Location:' + locationsString}
            `;
    }
};

export const getContent = (type, item) => {
    const contentWithoutUrl = getContentWithoutURL(type, item);
    return `
${contentWithoutUrl}

${window.location.href}
    `;
};

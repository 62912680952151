import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAchievements, getIsLoadingAchievements, getLoadAchievementsError } from '../selectors';
import { loadAchievements } from '../actions';

const useAchievements = ({ filter }) => {
    const achievements = useSelector(getAchievements);
    const isLoading = useSelector(getIsLoadingAchievements);
    const error = useSelector(getLoadAchievementsError);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAchievements(filter));
    }, [dispatch, filter]);

    return { isLoading, achievements, error };
};

export default useAchievements;

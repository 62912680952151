/**
 * A statndardized message object for use
 * in rendering messages in the chat feed.
 */
import { MESSAGE_TYPE } from '../../../scenes/Talk/constants';

export default class Message {
    /**
     * Message object for organizing and storing current message data.
     */
    constructor(messageData) {
        this._id = messageData._id; // message ID from DB
        this.id = messageData.id; // id of the sender (0 is reserved for "blue bubble")
        this.body = messageData.body;
        this.user = messageData.user || {};
        this.system = messageData.system || false; // displayed as a system message - out of bubbles (removed messages or infos)
        this.createdAt = messageData.timestamp || '';
        this.representative = messageData.representativeTitle || false;
        this.big = messageData.big || false;
        this.failed = messageData.failed || false;
        this.appointment = this.isAppointment(messageData.item)
            ? messageData.item.quickReplies.data
            : false;
        this.dateMessage = messageData.dateMessage || false;
        this.removedFromUser = messageData.removedFromUser || false;
        this.removedFromModerator = messageData.removedFromModerator || false;
        this.nick = messageData.nick || '';
        this.timestamp = messageData.timestamp;
        this._timestamp = messageData._timestamp;
    }

    isAppointment(msg) {
        return (
            msg.quickReplies &&
            msg.quickReplies.data &&
            msg.quickReplies.type === MESSAGE_TYPE.APPOINTMENT
        );
    }
}

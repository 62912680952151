import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';

import UploadResource from '../../common/UploadResource';
import entities from '../../../constants/entities';
import html2canvas from 'html2canvas';
import VotingPreview from '../../../../Votes/components/VotingPreview';
import { savePoll } from '../../../../Votes/services/PollingService';
import useResize from '../../../../../../VirtualSession/hooks/useResize';
import ConfirmationDialog from '../../common/ConfirmatonDialog';
import onDeletePresentationUrl from '../../../events/onDeletePresentationUrl';
import { useVMMutation, useVMState } from '../../../containers/main';
import { LoaderTransparent } from '../../common/LoaderTransparent';
import { Button as ButtonMD, FontIcon } from 'react-md';
import VotingResults from '../../../../Votes/components/VotingResults';
import { downloadImg } from '../../../utils/downloadImage';
import { ContentModalContainer } from '../../common/DialogStyles';
import scalingTools from '../../../utils/scalingTools';
import { BeautifulLoader } from '../../common/BeautifulLoader';
import { sleep } from '../../../../../utils';

const Wrapper = styled.div`
    flex: 2;
    position: relative;
    border-bottom: solid 1px #f1f1f3;
    border-radius: 8px;
`;

const SlidesWrapper = styled.div`
    position: relative;
    text-align: center;
    background-color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const SlideButton = styled.div`
    background-color: rgba(0, 0, 0, 0.54);
    border-radius: ${props => (props.rounded ? '50%' : '6px')};
    color: #ffffff;
    cursor: pointer;
    font-family: Cabin, sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.46px;
    line-height: normal;
    padding: 8px 12px;
    display: flex;
    align-items: center;

    ${props =>
        props.disabled &&
        css`
            opacity: 0.5;
        `}
`;

const ArrowIcon = styled(FontIcon)`
    width: 16px;
    color: #fff !important;
    ${props => (props.left ? 'margin-right: 12px' : 'margin-left: 12px')};
`;

const DeleteIcon = styled(FontIcon)`
    width: 16px;
    transform: translateX(-4px);
    color: #fff !important;
`;

const ButtonsWrapper = styled.div`
    bottom: 16px;
    display: flex;
    justify-content: space-between;
    left: 16px;
    position: absolute;
    width: calc(100% - 32px);
    z-index: 2;
`;

const UpperButtonsWrapper = styled.div`
    top: 16px;
    display: flex;
    justify-content: space-between;
    left: 16px;
    position: absolute;
    width: calc(100% - 32px);
    z-index: 2;
`;

const StyledButton = styled(ButtonMD)`
    border-radius: 4px !important;
    background-color: #ef2040 !important;
    color: #fff !important;
    width: 159px;
    text-transform: capitalize !important;
    white-space: nowrap;
    font-weight: bold !important;
    font-size: 15px !important;
`;

const StyledButtonCancel = styled(ButtonMD)`
    width: 159px;
    border-radius: 4px !important;
    background-color: #f0f1f3 !important;
    color: #000000 !important;
    text-transform: capitalize !important;
    font-weight: bold !important;
    font-size: 15px !important;
`;

const urlCache = {};

function Slides(props = {}) {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const {
        activePoll,
        pdf,
        sessionId,
        socket,
        speakerActivePoll,
        url,
        virtualEventSession,
        virtualEventUser,
        screenShareStream,
        screenSharing,
        isLoading,
    } = stateCtx;

    const {
        children,
        forceRender,
        pollSet,
        hideDeleteButton,
        isModerator,
        changeFromPollsActiveRef,
    } = props;
    const { isActive, isPrerecording } = virtualEventUser.data;
    const showVoteResults = get(virtualEventSession, 'data.showVoteResults', false);
    const isPollSetOpen = pollSet?.state === 'open';
    const [showDeleteSlidesModal, setShowDeleteSlidesModal] = useState(false);
    const vUserId = get(virtualEventUser, 'data.UserId');
    const questionModeratorId = get(virtualEventSession, 'data.questionModeratorId');
    const isUserModeratorAndActivatedAQuestion = isModerator && vUserId === questionModeratorId;

    const canvasRef = useRef(null);
    let newElementRef = useRef(null);
    const newCanvasRef = useRef(null);
    const intervalRef = useRef(null);
    const votesRef = useRef(null);
    const votingPreviewRef = useRef(null);
    const [resizeRef] = useResize();
    // drawing variables
    const shouldClearInterval = useRef(false);
    const redrawSpeed = isPrerecording ? 300 : 500;

    const reDrawFn = (canvas, newCanvas) => {
        const { height, width } = newCanvas;

        /**
         * We reset the interval and the redraw variables
         */
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        const drawFn = () => {
            const recordingContext = canvas.getContext('2d');

            recordingContext.drawImage(newCanvas, 0, 0, width, height);

            /**
             * This approach is required because we have some async functionality,
             * so we make sure that the interval is cleared every time that is needed.
             */
            if (shouldClearInterval.current) {
                clearInterval(intervalRef.current);
            }
            requestAnimationFrame(drawFn);
            changeFromPollsActiveRef.current = true;
        };

        intervalRef.current = setInterval(drawFn, redrawSpeed);
    };

    useEffect(() => {
        const { roomType } = virtualEventSession.data;
        const isRoomRoundTable =
            roomType === entities.virtualEventSessionEntity.roomType.roundTable;
        const hasNoSlides = !virtualEventUser.data.uploadedPresentationUrl;
        const votingIsOff = !(isPollSetOpen || activePoll);

        shouldClearInterval.current = !(isRoomRoundTable || isActive || isPrerecording);

        if (hasNoSlides && votingIsOff) {
            shouldClearInterval.current = true;
        }
    }, [virtualEventUser.data, virtualEventSession.data, screenSharing]);

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (activePoll && activePoll.imageUrl && !urlCache[activePoll.imageUrl]) {
            (async () => {
                const downloadedImg = await downloadImg({
                    url: activePoll.imageUrl,
                });

                urlCache[activePoll.imageUrl] = downloadedImg.src;
            })();
        }
    }, [activePoll]);

    const drawCanvas = async elementRef => {
        if (elementRef && canvasRef.current && elementRef.current) {
            canvasRef.current.style.height = '100%';
            canvasRef.current.style.width = '100%';
            changeFromPollsActiveRef.current = false;

            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            const initialDrawFn = async () => {
                if (!elementRef.current || !canvasRef.current) {
                    return;
                }

                if (activePoll && activePoll.imageUrl && !urlCache[activePoll.imageUrl]) {
                    setTimeout(drawCanvas, 300);
                    return;
                }

                let newCanvas;

                if (newCanvasRef && newCanvasRef.current && newElementRef === elementRef) {
                    newCanvas = newCanvasRef.current;
                } else {
                    newCanvasRef.current = await html2canvas(elementRef.current, {
                        allowTaint: true,
                    });
                    newCanvas = newCanvasRef.current;
                    newElementRef = elementRef;
                }

                if (!canvasRef.current) {
                    await sleep(500);
                    return;
                }

                if (canvasRef.current.width !== newCanvas.width) {
                    canvasRef.current.width = newCanvas.width;
                }

                if (canvasRef.current.height !== newCanvas.height) {
                    canvasRef.current.height = newCanvas.height;
                }

                const { height, width } = newCanvas;
                const canvasCtx = canvasRef.current.getContext('2d');

                canvasCtx.drawImage(newCanvas, 0, 0, width, height);

                reDrawFn(canvasRef.current, newCanvas);
            };

            initialDrawFn();
            setTimeout(initialDrawFn, redrawSpeed);
        }
    };
    const pageNr = virtualEventUser.data.activePresentationIndex;
    useEffect(() => {
        (async () => {
            try {
                if (
                    (isCurrentUserActive() || isUserModeratorAndActivatedAQuestion) &&
                    (isPollSetOpen || showVoteResults)
                ) {
                    drawCanvas(votesRef);

                    return null;
                }

                if (
                    (isCurrentUserActive() || isUserModeratorAndActivatedAQuestion) &&
                    activePoll &&
                    !isPollSetOpen
                ) {
                    drawCanvas(votingPreviewRef);

                    return null;
                }

                if (
                    !pdf ||
                    !resizeRef ||
                    !Number.isInteger(virtualEventUser.data.activePresentationIndex) ||
                    !screenShareStream
                ) {
                    return null;
                }

                if (pageNr < 0 || pageNr >= pdf.numPages) {
                    const newPageNr =
                        pageNr < 0 ? 0 : virtualEventUser.data.activePresentationIndex - 1;

                    socket.emit('updateData', {
                        objectId: sessionId,
                        virtualEventSession: {
                            VirtualEventUsers: [
                                {
                                    id: virtualEventUser.data.id,
                                    activePresentationIndex: newPageNr,
                                },
                            ],
                        },
                    });
                    return;
                }

                const page = await pdf.getPage(pageNr + 1);
                const pageSize = {
                    height: page._pageInfo.view[3],
                    width: page._pageInfo.view[2],
                };
                const scalingFactor =
                    pageSize.width / pageSize.height >= 16 / 9 ? 'width' : 'height';
                const DYNAMIC_SCALING_MAP = scalingTools.getDynamicScalingMap(scalingFactor);

                const scale =
                    (DYNAMIC_SCALING_MAP[scalingFactor] * 100) / pageSize[scalingFactor] / 100;
                const viewport = page.getViewport({ scale });

                // Apply page dimensions to the <canvas> element.
                const canvasWithPage = document.createElement('canvas');
                const context = canvasWithPage.getContext('2d');

                canvasWithPage.style.maxWidth = '100%';
                canvasWithPage.style.maxHeight = 'calc(100vh - 250px)';
                canvasWithPage.height = viewport.height;
                canvasWithPage.width = viewport.width;

                // Render the page into the <canvas> element.
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }

                await page.render(renderContext).promise;

                if (canvasRef.current) {
                    canvasRef.current.style.height = 'unset';
                    canvasRef.current.style.width = 'unset';

                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }

                    const drawFn = () => {
                        if (!canvasRef.current) {
                            return;
                        }

                        const canvasContext = canvasRef.current.getContext('2d');

                        canvasRef.current.height = canvasWithPage.height;
                        canvasRef.current.width = canvasWithPage.width;
                        canvasContext.drawImage(
                            canvasWithPage,
                            0,
                            0,
                            canvasWithPage.width,
                            canvasWithPage.height,
                        );
                        requestAnimationFrame(drawFn);
                        /**
                         * This approach is required because we have some async functionality,
                         * so we make sure that the interval is cleared every time that is needed.
                         */
                        if (shouldClearInterval.current) {
                            clearInterval(intervalRef.current);
                        }
                    };

                    drawFn();
                    intervalRef.current = setInterval(drawFn, redrawSpeed);
                }
            } catch (err) {
                console.log(err);
            }

            return () => {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
            };
        })();
    }, [
        pdf,
        screenShareStream,
        canvasRef.current,
        pageNr,
        virtualEventUser.data?.id,
        pageNr,
        activePoll,
    ]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyboardArrosPress);

        return () => {
            window.removeEventListener('keydown', handleKeyboardArrosPress);
        };
    }, [pdf, virtualEventUser.data.activePresentationIndex]);

    useEffect(() => {
        if (canvasRef.current && !screenSharing) {
            mutationCtx.startCanvasSharing({ canvas: canvasRef.current });
        }

        return mutationCtx.stopCanvasSharing;
    }, [
        canvasRef.current,
        screenSharing,
        urlCache,
        isPollSetOpen,
        isUserModeratorAndActivatedAQuestion,
        showVoteResults,
    ]);

    const handleKeyboardArrosPress = async evt => {
        if (evt.which === 37) {
            await previous();
        }
        if (evt.which === 39) {
            await next();
        }
    };

    const removeActivePoll = async () => {
        if (!activePoll) {
            return;
        }

        await savePoll({
            ...activePoll,
            active: false,
        });
        mutationCtx.setActivePoll(null);
        socket.emit('pollRefresh', { objectId: sessionId });
    };

    const next = async () => {
        if (!pdf || virtualEventUser.data.activePresentationIndex === pdf.numPages - 1) {
            return;
        }

        const newValue = virtualEventUser.data.activePresentationIndex + 1;

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        activePresentationIndex: newValue,
                    },
                ],
            },
        });

        if (isCurrentUserActive()) {
            removeActivePoll();
        }

        if (speakerActivePoll) {
            mutationCtx.setSpeakerActivePoll(null);
        }
    };

    const previous = async () => {
        if (!pdf || virtualEventUser.data.activePresentationIndex === 0) {
            return;
        }

        const newValue = virtualEventUser.data.activePresentationIndex - 1;

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        activePresentationIndex: newValue,
                    },
                ],
            },
        });

        if (isCurrentUserActive()) {
            removeActivePoll();
        }

        if (speakerActivePoll) {
            mutationCtx.setSpeakerActivePoll(null);
        }
    };

    const onDeleteSlides = async () => {
        await onDeletePresentationUrl(
            stateCtx,
            mutationCtx,
            entities.virtualEventUserEntity.mode.slide,
        );
        setShowDeleteSlidesModal(false);
    };

    const isCurrentUserActive = () => {
        const userId = get(virtualEventUser, 'data.UserId', null);
        const virtualEventUsers = get(virtualEventSession, 'data.VirtualEventUsers', []);
        const activeUser = virtualEventUsers.find(virtualUser => virtualUser.isActive) || {};

        return activeUser.UserId === userId;
    };

    const isCurrentUserQuestionActive = () => {
        const userId = get(virtualEventUser, 'data.UserId', null);

        return userId === activePoll?.UserId;
    };

    return (
        <Wrapper>
            <LoaderTransparent
                active={
                    virtualEventUser.uploadRequestStatus === entities.requestStatus.loading ||
                    virtualEventSession.uploadRequestStatus === entities.requestStatus.loading
                }
            >
                {!url && !forceRender && !isModerator && (
                    <React.Fragment>
                        <UploadResource
                            type={entities.virtualEventSessionEntity.roomType.moderated}
                        />
                    </React.Fragment>
                )}
                {(url || forceRender) &&
                    !(activePoll && isCurrentUserQuestionActive()) &&
                    !isModerator && (
                        <BeautifulLoader active={isLoading}>
                            <SlidesWrapper innerRef={resizeRef}>
                                {!(activePoll && isCurrentUserQuestionActive()) && (
                                    <canvas
                                        ref={canvasRef}
                                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                                        id="pdf"
                                    />
                                )}
                                {!hideDeleteButton && (
                                    <UpperButtonsWrapper>
                                        <div />
                                        <SlideButton
                                            onClick={() => setShowDeleteSlidesModal(true)}
                                            rounded
                                        >
                                            <DeleteIcon>delete_outline</DeleteIcon>
                                        </SlideButton>
                                    </UpperButtonsWrapper>
                                )}
                                <ButtonsWrapper>
                                    {!virtualEventSession.data.showVoteResults && !activePoll && (
                                        <>
                                            {virtualEventUser.data.activePresentationIndex > 0 ? (
                                                <SlideButton onClick={previous} disabled={!pdf}>
                                                    <ArrowIcon left={1}>
                                                        keyboard_backspace
                                                    </ArrowIcon>
                                                    <span>Previous</span>
                                                </SlideButton>
                                            ) : (
                                                <div />
                                            )}
                                            {virtualEventUser.data.activePresentationIndex <
                                            pdf?.numPages - 1 ? (
                                                <SlideButton onClick={next} disabled={!pdf}>
                                                    <span>Next</span>
                                                    <ArrowIcon>east</ArrowIcon>
                                                </SlideButton>
                                            ) : (
                                                <div />
                                            )}
                                        </>
                                    )}
                                </ButtonsWrapper>
                            </SlidesWrapper>
                        </BeautifulLoader>
                    )}
                {activePoll &&
                    !isPollSetOpen &&
                    !showVoteResults &&
                    (isCurrentUserQuestionActive() || isUserModeratorAndActivatedAQuestion) && (
                        <React.Fragment>
                            {/* This will be the component that is displayed to the active speaker*/}
                            <VotingPreview
                                imageUrl={activePoll ? urlCache[activePoll.imageUrl] : ''}
                                activePoll={activePoll}
                                pollSet={pollSet}
                            />
                            {/* This will be the component that gets recorded*/}
                            <VotingPreview
                                imageUrl={activePoll ? urlCache[activePoll.imageUrl] : ''}
                                activePoll={activePoll}
                                pollSet={pollSet}
                                elRef={votingPreviewRef}
                            />
                            <canvas
                                ref={canvasRef}
                                style={{ maxWidth: '100%', maxHeight: '100%', display: 'none' }}
                                id="pdf"
                            />
                        </React.Fragment>
                    )}
                {activePoll &&
                    (isPollSetOpen || showVoteResults) &&
                    (isCurrentUserQuestionActive() || isUserModeratorAndActivatedAQuestion) && (
                        <React.Fragment>
                            {/* This will be the component that is displayed to the active speaker*/}
                            <VotingResults
                                activePoll={activePoll}
                                imageUrl={activePoll ? urlCache[activePoll.imageUrl] : ''}
                                isCurrentUserActive={isCurrentUserActive()}
                                pollSet={pollSet}
                            />
                            {/* This will be the component that gets recorded*/}
                            <VotingResults
                                imageUrl={activePoll ? urlCache[activePoll.imageUrl] : ''}
                                activePoll={activePoll}
                                isCurrentUserActive={isCurrentUserActive()}
                                elRef={votesRef}
                                pollSet={pollSet}
                            />
                            <canvas
                                ref={canvasRef}
                                style={{ maxWidth: '100%', maxHeight: '100%', display: 'none' }}
                                id="pdf"
                            />
                        </React.Fragment>
                    )}
                {children}
            </LoaderTransparent>
            <div className="eureka-react">
                <ConfirmationDialog
                    open={showDeleteSlidesModal}
                    title="Delete slides"
                    withCloseButton={true}
                    onClose={() => setShowDeleteSlidesModal(false)}
                    content={
                        <ContentModalContainer>
                            <div>Are you sure you want to delete your slides?</div>
                        </ContentModalContainer>
                    }
                    buttons={[
                        <StyledButton key="DSB" flat onClick={onDeleteSlides}>
                            Delete Slides
                        </StyledButton>,
                        <StyledButtonCancel
                            key="DSCB"
                            flat
                            onClick={() => setShowDeleteSlidesModal(false)}
                        >
                            Cancel
                        </StyledButtonCancel>,
                    ]}
                />
            </div>
        </Wrapper>
    );
}

Slides.propTypes = {};

export default Slides;

import React, { PureComponent } from 'react';
import { DialogContainer } from 'react-md';
import styled from 'styled-components';
import * as palette from '../General/Variables';
import ThemeContext from '../Theme/ThemeContext';
import Button, { buttonTypes } from '../../scenes/common/Button';

const INITIAL_STATE = {
    visible: false,
    options: {},
};

const StyledInfo = styled.p`
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${palette.COLOR_TEXT};
`;

const Dialog = styled(DialogContainer)`
    .md-title {
        margin-left: unset !important;
    }
`;

export default class ConfirmDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            visible: nextProps.visible,
            options: nextProps.options || {},
        };
    }

    show = () => {
        this.setState({ visible: true });
    };

    hide = () => {
        this.props.onHideDialog();
        this.setState({ visible: false });
    };

    cancel = () => {
        this.state.options.cancel();
        this.hide();
    };

    confirm = () => {
        this.state.options.confirm();
        this.hide();
    };

    render() {
        const { visible, options } = this.state;

        const addActions = theme => {
            const actions = [];
            actions.push(
                <Button
                    onClick={this.hide}
                    type={buttonTypes.GREY}
                    text={options.cancelText}
                    style={{ marginRight: '8px' }}
                />,
            );
            actions.push(
                <Button
                    onClick={this.confirm}
                    type={buttonTypes.GREEN}
                    background={theme.primary}
                    text={options.confirmText}
                    icon={options.confirmIcon}
                />,
            );
            return actions;
        };

        return (
            <ThemeContext.Consumer>
                {({ theme }) => (
                    <Dialog
                        id="speed-boost"
                        visible={visible}
                        title={options.title}
                        onHide={this.hide}
                        width={palette.DIALOG_WIDTH}
                        aria-describedby="speed-boost-description"
                        modal
                        initialFocus={''}
                        focusOnMount={false}
                        containFocus={false}
                        actions={addActions(theme)}
                        footerStyle={{ padding: '16px', color: palette.COLOR_TEXT }}
                    >
                        <StyledInfo>{options.text}</StyledInfo>
                    </Dialog>
                )}
            </ThemeContext.Consumer>
        );
    }
}

/* eslint-disable no-empty */
import get from 'lodash/get';
import pick from 'lodash/pick';
import { createSelector } from 'reselect';

import { MESSAGE_TYPE } from '../constants';
import { getNickAsInt } from '../actions';
import {
    getParticipantStatus,
    getParticipantBlocked,
    getParticipantsSelector,
} from './participants';

// helpers

const parsePrivateConversation = (c, state) => {
    const lastMessage = { ...state.talk.messages[c.lastMessage] };

    try {
        const textObj = JSON.parse(lastMessage.txt);
        if (textObj.type === MESSAGE_TYPE.APPOINTMENT) {
            const { name, action } = textObj.data;
            lastMessage.txt = `${name} ${action}`;
        }
    } catch (e) {
        // ignore, message is plain text
    }

    return {
        ...c,
        participant: {
            ...state.talk.participants[c.participant],
            online: getParticipantStatus(state, c.participant),
            blocked: getParticipantBlocked(state, c.participant),
            showStatus: true,
        },
        lastMessage,
    };
};

const parseGroupConversation = (c, state) => {
    const currentUser = state.talk.user;
    let lastMessage = state.talk.messages[c.lastMessage];
    let participant;

    if (!lastMessage && c.messages && c.messages.length > 0) {
        lastMessage = state.talk.messages[c.messages[0]];
    }

    if (lastMessage) {
        participant = state.talk.participants[lastMessage.participant];
        const userIsCurrentUser = lastMessage.participant === currentUser.id;
        if (userIsCurrentUser) {
            participant = { ...participant, ...currentUser };
        }

        lastMessage = {
            ...lastMessage,
            participant,
        };
    }

    const conversationParticipants = c.participants || [];

    return {
        ...c,
        participants: conversationParticipants.map(id => ({
            ...state.talk.participants[id],
        })),
        lastMessage,
    };
};

// selectors

const getPrivateConversationsSelector = state => state.talk.privateConversations;
const getGroupConversationsSelector = state => state.talk.groupConversations;
const getEventGroupConversationsSelector = state => {
    const { eventId } = state.talk.settings;
    const conversations = getGroupConversationsSelector(state);
    return Object.values(conversations).filter(c => c.lastMessage && c.eventId === eventId);
};

const getPrivateConversationWithId = (state, conversationId) => {
    const conversation = state.talk.privateConversations[conversationId];

    if (!conversation) {
        return null;
    }

    return parsePrivateConversation(conversation, state);
};

const getConversations = state => {
    const privateConversationsArr = Object.values(state.talk.privateConversations).filter(
        c => c.lastMessage,
    );
    const privateConversations = privateConversationsArr.map(c => ({
        ...parsePrivateConversation(c, state),
        kind: 'chat',
        unreadCount: get(state, `talk.unreadMessagesCount.${c.id}`, 0),
    }));

    const groupConversationsArr = getEventGroupConversationsSelector(state);
    const groupConversations = groupConversationsArr.map(c => ({
        ...parseGroupConversation(c, state),
        kind: 'groupchat',
        unreadCount: get(state, `talk.unreadMessagesCount.${c.id}`, 0),
    }));

    return [...privateConversations, ...groupConversations].sort((c1, c2) =>
        c1.timestamp && c2.timestamp ? c2.lastMessage.timestamp - c1.lastMessage.timestamp : 0,
    );
};

// Filters out group chats where the user never participated (sent message)
const getParticipatingConversations = state => {
    const chats = getConversations(state);
    const uId = get(state, 'talk.user.id');
    return chats.filter(cv => {
        if (cv.kind === 'groupchat') {
            return cv.participants.find(p => p.id === uId);
        }
        return cv;
    });
};

const getConversationWithRoomId = createSelector(
    getGroupConversationsSelector,
    // TODO: improve this selector
    (state, roomId) => ({ state, roomId }),
    (conversations, { roomId, state }) => {
        const allConversation = Object.values(conversations);
        const conversation = allConversation.find(c => c.roomId === roomId);

        if (!conversation) {
            return null;
        }

        return parseGroupConversation(conversation, state);
    },
);

const getUserChatCardLastMessages = (state, conversationId) => {
    const conversation = state.talk.privateConversations[conversationId];
    const currentUser = state.talk.user;

    if (!conversation) {
        return [];
    }

    const messageIds = get(conversation, 'messages', []).slice(0, 2);

    return messageIds.map(id => {
        const message = state.talk.messages[id];
        const participantId = getNickAsInt(message.nick);
        let participant = state.talk.participants[participantId];

        if (currentUser.id === participantId) {
            participant = currentUser;
        }

        const lastMessage = { ...message };
        // message is plain text
        if (lastMessage.txt === '__removed__') {
            lastMessage.txt = 'Message deleted';
        }

        return {
            lastMessage: {
                ...lastMessage,
                participant,
            },
            kind: 'chat',
            participant,
            title: participant
                ? participant.displayName || `${participant.firstName} ${participant.lastName}`
                : '',
        };
    });
};

const getTimeslotChatCardLastMessages = (state, conversationId) => {
    const conversation = state.talk.groupConversations[conversationId];
    const currentUser = state.talk.user;

    if (!conversation) {
        return [];
    }

    const messageIds = get(conversation, 'messages', []);

    const filteredMessages = [];
    for (let i = 0; i < messageIds.length; i += 1) {
        const id = messageIds[i];
        const message = state.talk.messages[id];
        const participantId = getNickAsInt(message.nick);
        let participant = state.talk.participants[participantId];

        if (currentUser.id === participantId) {
            participant = currentUser;
        }

        const lastMessage = { ...message };
        if (
            lastMessage.removedFromUser ||
            lastMessage.txt === '__removed__' ||
            lastMessage.removedFromModerator
        ) {
            continue;
        }

        filteredMessages.push({
            lastMessage: {
                ...lastMessage,
                participant,
            },
            kind: 'chat',
            participant,
            title: participant
                ? participant.displayName || `${participant.firstName} ${participant.lastName}`
                : '',
        });
    }

    return filteredMessages
        .sort((m1, m2) => m2.lastMessage.timestamp - m1.lastMessage.timestamp)
        .slice(0, 2);
};

const getPrivateConversationIds = createSelector(getPrivateConversationsSelector, conversations =>
    Object.keys(conversations),
);

const getPrivateConversationParticipants = createSelector(
    getPrivateConversationsSelector,
    getParticipantsSelector,
    (conversations, participants) => {
        const participantIds = Object.values(conversations).map(c => c.participant);
        return pick(participants, participantIds);
    },
);

const getJoinedRooms = state => state.talk.joinedRooms;

const getIsLoadingConversations = (state, eventId) => {
    const eId = eventId || get(state, `talk.settings.eventId`);
    return get(state, `talk.api.${eId}.isLoadingGroupConversations`, null);
};

export {
    getConversations,
    getParticipatingConversations,
    getConversationWithRoomId,
    getUserChatCardLastMessages,
    getTimeslotChatCardLastMessages,
    getPrivateConversationIds,
    getJoinedRooms,
    getIsLoadingConversations,
    getPrivateConversationParticipants,
    getGroupConversationsSelector,
    getPrivateConversationWithId,
};

import React, { useState } from 'react';
import styled from 'styled-components';

import { useTheme } from '../../../components/Theme/ThemeContext';
import NavigationLink from '../../../components/Navigation/NavigationLink';
import { GreyButton, SocietyButton } from '../../../components/General/Buttons';
import EventInfoModal from './EventInfoModal';
import ConfiguredHtmlParser from '../../../components/ConfiguredHtmlParser';
import { AWS_IMAGES_ENDPOINT, S3_ENDPOINT_IMAGES } from '../../../config';

const EventHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 1023px) {
        flex-direction: column;
    }
`;

const EventBanner = styled.img`
    height: 236px;
    border-radius: 8px;
    margin-right: 24px;

    @media (max-width: 1023px) {
        margin-right: 0;
        width: 100%;
        height: unset;
    }
`;

const EventInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const EventName = styled.div`
    font-family: Cabin, sans-serif;
    font-weight: 700;
    font-size: 2.2vw;
    line-height: 48px;
    color: #1c1c1c;
    height: 48px;
`;

const EventDate = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 16px;
    line-height: 24px;
    margin-top: 8px;
    height: 25px;
`;

const EventInfoTextContainer = styled.div`
    height: 72px;
    overflow: hidden;
    max-width: 501px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    height: 40px;
`;

const EventInfoHeader = ({ banner, name, info, subtitle, eventName, canVisit }) => {
    const { theme } = useTheme();
    const [isEventInfoModalOpen, setIsEventInfoModalOpen] = useState(false);

    const eventRoute = `/event/${eventName}`;
    let bannerImage = banner;
    if (AWS_IMAGES_ENDPOINT && S3_ENDPOINT_IMAGES && banner) {
        bannerImage = banner.replace(S3_ENDPOINT_IMAGES, AWS_IMAGES_ENDPOINT);
    }

    return (
        <>
            <EventHeaderContainer>
                {banner && <EventBanner src={bannerImage} />}
                <EventInfoContainer>
                    <EventName>{name}</EventName>
                    <EventDate>{subtitle}</EventDate>
                    <EventInfoTextContainer>{ConfiguredHtmlParser(info)}</EventInfoTextContainer>
                    <ButtonsContainer>
                        {canVisit === 'open' && (
                            <NavigationLink
                                key="go-to-event-link"
                                path={eventRoute}
                                style={{ marginRight: '8px' }}
                            >
                                <SocietyButton primary={theme.primary} tabIndex={-1}>
                                    Go to event
                                </SocietyButton>
                            </NavigationLink>
                        )}
                        {canVisit === 'soon' && (
                            <SocietyButton
                                primary={'rgba(0, 0, 0, 0.38);'}
                                style={{ marginRight: '8px' }}
                            >
                                Coming soon
                            </SocietyButton>
                        )}

                        <GreyButton onClick={() => setIsEventInfoModalOpen(true)}>
                            Read more
                        </GreyButton>
                    </ButtonsContainer>
                </EventInfoContainer>
            </EventHeaderContainer>
            <EventInfoModal
                open={isEventInfoModalOpen}
                route={eventRoute}
                handleClose={() => {
                    setIsEventInfoModalOpen(false);
                }}
                title={name}
                subtitle={subtitle}
                imageUrl={bannerImage}
                content={ConfiguredHtmlParser(info)}
                theme={theme}
                canGoToEvent={canVisit}
            />
        </>
    );
};

export default EventInfoHeader;

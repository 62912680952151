import React from 'react';
import Zoom from 'react-medium-image-zoom';
import styled from 'styled-components';
import 'react-medium-image-zoom/dist/styles.css';
import PropTypes from 'prop-types';

const Image = styled.img`
    width: 100%;
    height: auto;
`;

const PostImagePreview = ({ source }) => {
    return (
        <Zoom>
            <Image src={source} alt="Post image" />
        </Zoom>
    );
};

PostImagePreview.propTypes = {
    source: PropTypes.string.isRequired,
};

export default PostImagePreview;

import React, { useMemo } from 'react';
import styled from 'styled-components';

import Card from '../../../components/General/Card';
import LeaderboardItem from './LeaderboardItem';
import { ScreenSize } from '../../../constants/mediaQueries';
import useRequestPaginatedData from '../../../hooks/useRequestPaginatedData';
import { useGlobalState } from '../../../utils/container';

const StyledLeaderboardItem = styled(LeaderboardItem)`
    margin-top: ${props => props.marginTop}px;
`;

const StyledContainer = styled(Card)`
    position: relative;
    width: 75%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    height: 100%;

    ${ScreenSize.Phone} {
        width: 100%;
    }
`;

export const LeaderboardFilter = Object.freeze({
    All: 'All',
    Contacts: 'Contacts',
});

const LeaderboardList = ({ filter = LeaderboardFilter.All }) => {
    const { eventId } = useGlobalState();

    const leaderboardUrl = useMemo(() => {
        return `/api/entities/achievementUser/leaderboard/event/${eventId}?filter=${filter.toLowerCase()}`;
    }, [eventId, filter]);

    const { data: { items = [] } = {}, isLoading } = useRequestPaginatedData({
        requestOptions: {
            method: 'get',
            path: leaderboardUrl,
            data: null,
            isAuthenticated: true,
        },
    });

    return (
        <StyledContainer>
            {items.map((item, index) => (
                <StyledLeaderboardItem
                    key={`${item.user.id}`}
                    marginTop={index > 0 ? 16 : 0}
                    rank={index + 1}
                    item={item}
                />
            ))}
        </StyledContainer>
    );
};

export default LeaderboardList;

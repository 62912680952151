import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { useTheme } from '../Theme/ThemeContext';
import moment from 'moment';
import Button, { buttonTypes } from '../../scenes/common/Button';
import InteractivityContext from '../../components/Interactivity/InteractivityContext';
import AuthUserContext from '../Session/AuthUserContext';
import toast from 'toasted-notes';
import NotificationToast from './NotificationToast';
import eventBus from '../../utils/eventBus';
import { ModalContent } from '../Dialog/styles';
import BasicDialog from '../Dialog/BasicDialog';
import facebookLogo from '../../assets/social/facebook_icon_share.png';
import whatsappLogo from '../../assets/social/whatsapp_icon_share.png';
import twitterLogo from '../../assets/social/twitter_icon_share.png';
import linkedinLogo from '../../assets/social/linkedin_icon_share.png';
import * as palette from './Variables';
import { FontIcon } from 'react-md';
import { MenuItem } from '@material-ui/core';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share';
import { getContent, getContentWithoutURL } from '../../utils/shareContentMessage';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import { TimezoneContext } from '../../scenes/Timezone/context';
import { default as Store, getString } from '../../services/api/store';
import Auth from '../../services/api/auth';

const ShareIconContainer = styled.div`
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.background};
    cursor: pointer;
    
    img {
        width: ${props => (props.background ? '32px' : '100%')};
        height: ${props => (props.background ? '33px' : '100%')};
    }
    
    i {
        font-size: 32px !important;
        color: ${palette.COLOR_WHITE}
        width: ${props => (props.background ? '32px' : '100%')};
        height: ${props => (props.background ? '33px' : '100%')};
    }
    
    button {
        display: flex;
    }
`;

const Link = styled.a`
    font-size: 32px;
    cursor: pointer;
`;

const CalendarItem = styled(MenuItem)`
    font-family: Roboto, sans-serif !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.6 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: rgba(0, 0, 0, 0.87) !important;
    height: 48px !important;
    border-radius: 8px !important;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
    }

    a {
        color: inherit;
        text-decoration: inherit;
    }
`;

// this function gets a string in YYYY-MM-DD format and a time in HH:mm
// format and creates a moment
const createDate = ({ time, eventDate }) => {
    const date = moment();

    const splitted = eventDate.split('-');

    date.set('year', splitted[0]);
    date.set('month', parseInt(splitted[1], 10) - 1);
    date.set('date', splitted[2]);

    const timeSplit = time.split(':');

    date.hours(timeSplit[0]);
    date.minutes(timeSplit[1]);

    return date;
};

const MoreOptionsShareButton = ({ type, notesTextWithAction, notePage, item }) => {
    const COPY_LINK_LABEL = getString('copyLink') || 'Copy link';
    const SHARE_LABEL = getString('share') || 'Share';
    const ADD_TO_CALENDAR_LABEL = getString('addToCalendar') || 'Add to calendar';
    const MORE_OPTIONS_LABEL = getString('moreOptionsNotesTitle') || 'More options';

    const { theme } = useTheme();
    const [isNotesPageDisplayed, setIsNotesPageDisplayed] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const { getUtcToSelectedTimezone } = useContext(TimezoneContext);

    const itemCopy = { ...item };

    let startDatetime = '';
    let endDatetime = '';

    if (item.startTime) {
        const dateSettings = getString('datetime');
        const locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';

        let start = getUtcToSelectedTimezone(item.startTime);
        let end = getUtcToSelectedTimezone(item.endTime);

        let datePattern =
            dateSettings && dateSettings.longDateFormat
                ? dateSettings.longDateFormat
                : 'dddd, MMMM Do YYYY';
        let timeFormat =
            dateSettings && dateSettings.timeFormat ? dateSettings.timeFormat : 'HH:mm';

        itemCopy.time = `${start.locale(locale).format(datePattern)}, ${start.format(
            timeFormat,
        )} - ${end.format(timeFormat)}`.toUpperCase();

        const startTime = start.format('HH:mm');
        const endTime = end.format('HH:mm');
        const startDate = start.format('YYYY-MM-DD');
        const endDate = end.format('YYYY-MM-DD');
        startDatetime = createDate({ time: startTime, eventDate: startDate }).toISOString();
        endDatetime = createDate({ time: endTime, eventDate: endDate }).toISOString();
    }

    const location =
        item?.locations?.items && item?.locations?.items[0] && item?.locations?.items[0].name;
    const authUser = Auth.getUser();

    const CalendarSelectModal = ({ children }) => (
        <BasicDialog
            title={ADD_TO_CALENDAR_LABEL}
            visible={showCalendarModal}
            onClose={() => setShowCalendarModal(false)}
            options={[]}
        >
            {children?.map(link => (
                <CalendarItem key={`share-calendar-${link.key}`}>{link}</CalendarItem>
            ))}
            <CalendarItem
                key={`share-calendar-outlook`}
                onClick={() =>
                    window.open(
                        `https://outlook.live.com/owa/?rru=addevent&startdt=${startDatetime}&enddt=${endDatetime}&subject=${item.name}&path=/calendar/view/Mont&location=${location}`,
                    )
                }
            >
                Outlook
            </CalendarItem>
        </BasicDialog>
    );

    const copyUrlToClipboard = () => {
        toast.notify(
            () => (
                <NotificationToast
                    title={'Link copied'}
                    subtitle={'The link to this item is copied to your clipboard'}
                    icon={'content_copy'}
                />
            ),
            {
                position: 'top-right',
                duration: 2000,
            },
        );
        navigator.clipboard.writeText(window.location.href);
    };

    const share = () => {
        setShowShareModal(true);
    };

    const openNotes = () => {
        if (!authUser) {
            return;
        }
        setIsNotesPageDisplayed(true);
        eventBus.emit('notesPageOpened');
    };

    const optionObjectsForInstitutionAndClassifier = {
        options: [COPY_LINK_LABEL, SHARE_LABEL, notesTextWithAction],
        icons: ['content_copy', 'share', 'edit'],
        actions: [copyUrlToClipboard, share, openNotes],
    };

    const getOptionsByType = () => {
        switch (type) {
            case 'classifier':
                return optionObjectsForInstitutionAndClassifier;
            case 'institution':
                return optionObjectsForInstitutionAndClassifier;
            case 'booth':
                return optionObjectsForInstitutionAndClassifier;
            default:
                return item.startTime
                    ? {
                          options: [
                              COPY_LINK_LABEL,
                              SHARE_LABEL,
                              addCalendarButton(),
                              notesTextWithAction,
                          ],
                          icons: ['content_copy', 'share', 'unset', 'edit'],
                          actions: [copyUrlToClipboard, share, null, openNotes],
                      }
                    : {
                          options: [COPY_LINK_LABEL, SHARE_LABEL, notesTextWithAction],
                          icons: ['content_copy', 'share', 'edit'],
                          actions: [copyUrlToClipboard, share, openNotes],
                      };
        }
    };

    const addCalendarButton = () => {
        const AddToCalendarModal = AddToCalendarHOC(Button, CalendarSelectModal);
        const start = moment(item.startTime);
        const end = moment(item.endTime);
        setShowCalendarModal(true);

        return (
            <AddToCalendarModal
                id={'add-calendar-modal'}
                buttonProps={{
                    icon: 'event',
                    style: {
                        // display: 'flex',
                        // alignItems: 'center',
                        // justifyContent: 'start',
                        width: '100%',
                        marginLeft: '-35px',
                        fontSize: '16px',
                        fontWeight: '500',
                    },
                }}
                event={{
                    duration: moment.duration(start.diff(end)).asHours(),
                    endDatetime: moment(endDatetime).format('YYYYMMDDTHHmmssZ'),
                    location,
                    startDatetime: moment(startDatetime).format('YYYYMMDDTHHmmssZ'),
                    title: item.name,
                }}
                items={['Google', 'iCalendar']}
                buttonText={ADD_TO_CALENDAR_LABEL}
            />
        );
    };

    if (isNotesPageDisplayed) {
        return notePage;
    }
    eventBus.on('notesPageClosed', () => setIsNotesPageDisplayed(false));

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <InteractivityContext.Consumer>
                    {({ showSignIn }) => (
                        <>
                            <Button
                                type={
                                    type === 'classifier' || type === 'institution'
                                        ? buttonTypes.GREY_LONG
                                        : buttonTypes.GREY
                                }
                                style={
                                    type !== 'classifier' && type !== 'institution'
                                        ? {
                                              width: '56px',
                                              padding: 'unset',
                                          }
                                        : {}
                                }
                                background={theme.primary}
                                icon={'more_horiz'}
                                text={
                                    (type === 'classifier' || type === 'institution') &&
                                    MORE_OPTIONS_LABEL
                                }
                                displayedInBooth={type === 'booth'}
                                dropdown={true}
                                openDropdownFromButton={true}
                                dropdownOptions={getOptionsByType()?.options}
                                dropdownIcons={getOptionsByType()?.icons}
                                dropdownActions={getOptionsByType()?.actions}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (authUser) {
                                        // setShowNotesScreen(true);
                                    } else {
                                        showSignIn(e);
                                    }
                                }}
                            />
                            <BasicDialog
                                title={'Share object'}
                                visible={showShareModal}
                                onClose={() => setShowShareModal(false)}
                                options={[]}
                            >
                                <ModalContent textAlign={'start'} justifycontent={'space-between'}>
                                    <ShareIconContainer background={'#25d366'}>
                                        <WhatsappShareButton
                                            url={window.location.href}
                                            title={getContentWithoutURL(type, itemCopy)}
                                        >
                                            <img src={whatsappLogo} alt="whatsappLogo" />
                                        </WhatsappShareButton>
                                    </ShareIconContainer>
                                    <ShareIconContainer background={'#3b5998'}>
                                        <FacebookShareButton
                                            url={window.location.href}
                                            quote={getContentWithoutURL(type, itemCopy)}
                                        >
                                            <img src={facebookLogo} alt="facebookLogo" />
                                        </FacebookShareButton>
                                    </ShareIconContainer>
                                    <ShareIconContainer background={'#000'}>
                                        <TwitterShareButton
                                            title={getContentWithoutURL(type, itemCopy)}
                                            url={window.location.href}
                                        >
                                            <img
                                                src={twitterLogo}
                                                alt="twitterLogo"
                                                style={{ width: 30, height: 30 }}
                                            />
                                        </TwitterShareButton>
                                    </ShareIconContainer>
                                    <ShareIconContainer>
                                        <LinkedinShareButton
                                            title={getContentWithoutURL(type, itemCopy)}
                                            url={window.location.href}
                                            source={window.location.href}
                                        >
                                            <img src={linkedinLogo} alt="linkedinLogo" />
                                        </LinkedinShareButton>
                                    </ShareIconContainer>
                                    <ShareIconContainer background={'#e44c41'}>
                                        <Link
                                            href={
                                                'mailto:?body=' +
                                                encodeURIComponent(getContent(type, itemCopy))
                                            }
                                        >
                                            <FontIcon>{palette.ICON_MAIL}</FontIcon>
                                        </Link>
                                    </ShareIconContainer>
                                </ModalContent>
                            </BasicDialog>
                        </>
                    )}
                </InteractivityContext.Consumer>
            )}
        </AuthUserContext.Consumer>
    );
};

export default MoreOptionsShareButton;

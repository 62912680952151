import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Button, { buttonTypes } from '../../../common/Button';
import { useTheme } from '../../../../components/Theme/ThemeContext';
import packageJSON from '../../../../../package.json';
import { getString } from '../../../../services/api/store';
import { Flex } from '../../../common/Flex';
import { getLocalAppStateAsync } from '../../../../services/api/db';
import { BUILD_VERSION } from '../../../../config';
import { ContentContainer, TitleText } from '../details';
import { Divider } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { aboutLinks } from '../../../SignInPage/constants/strings';
import FacebookIcon from '../../../../assets/icons/FacebookIcon';
import TwitterIcon from '../../../../assets/icons/TwitterIcon';
import LinkedinIcon from '../../../../assets/icons/LinkedinIcon';
import YoutubeIcon from '../../../../assets/icons/YoutubeIcon';
import { getLatestPrivacyPolicy } from '../../../../services/api/eureka';

const VersionDetailsContainer = styled.div`
    width: 100%;
`;

const VersionDetailsText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);

    div {
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: normal;
        text-align: left;
        color: rgba(0, 0, 0, 0.6);
    }
`;

const VersionContainer = styled.div`
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
`;

const StyledDivider = styled(Divider)`
    width: calc(100% + 32px);
    margin: 0 -16px;
`;

const LinkItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 16px;
    font-family: Roboto;
    font-size: 15px;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 8px;
    margin-top: 8px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

const StyledAnchor = styled.a`
    position: relative;
    text-decoration: none;
    width: calc(100% + 32px);
`;

const StyledIconAnchor = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #f0f0f0;
`;

const FooterText = styled.div`
    font-family: Cabin;
    font-size: 13px;
    line-height: 1.31;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    margin-top: ${props => (props.nomargin ? 0 : '8px')};
`;

const FooterAnchor = styled.a`
    font-family: Cabin;
    font-size: 13px;
    line-height: 1.31;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    margin-top: ${props => (props.nomargin ? 0 : '8px')};
`;

const IconsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 24px 0;
`;

const UpdateAvailableLabel = ({ theme, text }) => (
    <Button
        text={text}
        type={buttonTypes.GREEN_REVERSE}
        background={theme.primary}
        onClick={() => {}}
        style={{ height: '24px', borderRadius: '50px', padding: '0 12px' }}
        enabled={false}
    />
);

const FloqImage = styled.img`
    height: 24px;
    width: 24px;
`;

const getAboutThisAppFooterTextByLocale = locale => {
    switch (locale) {
        case 'en-UK':
            return {
                start: 'This event platform is powered by Floq. Organising an event? Check',
                end: 'to see how we can help you.',
            };
        case 'en-GB':
            return {
                start: 'This event platform is powered by Floq. Organising an event? Check',
                end: 'to see how we can help you.',
            };
        case 'en-US':
            return {
                start: 'This event platform is powered by Floq. Organizing an event? Check',
                end: 'to see how we can help you.',
            };
        case 'nl':
            return {
                start:
                    'Dit platform wordt mede mogelijk gemaakt door Floq. Organiseert u een evenement? Kijk op',
                end: 'om te zien hoe wij u kunnen helpen.',
            };
        case 'fr':
            return {
                start:
                    'Cette plateforme est propulsée par Floq. Vous organisez un événement ? Consultez',
                end: 'pour voir comment nous pouvons vous aider.',
            };
        case 'de':
            return {
                start:
                    'Diese Eventplattform wird von Floq betrieben. Organisieren Sie ein Event? Sehen Sie auf ',
                end: 'nach, wie wir Ihnen helfen können.',
            };
        default:
            return {
                start: 'This event platform is powered by Floq. Organising an event? Check',
                end: 'to see how we can help you.',
            };
    }
};

const PlatformInfoContent = ({
    updateAvailable = false,
    updateServiceWorker = () => {},
    waitingWorker,
}) => {
    const [releaseNum, setReleaseNum] = useState(null);
    const [itemHovered, setItemHovered] = useState(null);
    const [terms, setTerms] = useState(aboutLinks.termsAndConditions);
    const [privacyPolicy, setPrivacyPolicy] = useState({});

    const { theme } = useTheme();
    const { version: platformVersion } = packageJSON;

    const VERSION_TEXT = getString('appVersionText') || 'Floq.live version';
    const DATA_TEXT = getString('dataVersionText') || 'Data';
    const UPDATE_TEXT = getString('updateAvailableTitle') || 'Update';
    const TERMS = getString('termsAndConditionsLabelButton') || 'Terms of Use';
    const POLICY = getString('privacyLabelButton') || 'Privacy Policy';

    const dateSettings = getString('datetime');
    const locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en-GB';

    useEffect(() => {
        (async () => {
            const appState = await getLocalAppStateAsync();
            if (appState.releaseNum) {
                setReleaseNum(appState.releaseNum);
            }
            if (appState.termsUrl) {
                setTerms(appState.termsUrl);
            }
            let latestPolicy = await getLatestPrivacyPolicy();
            setPrivacyPolicy(latestPolicy);
        })();
    }, []);

    return (
        <ContentContainer>
            <StyledDivider variant="fullWidth" style={{ transform: 'translateY(-16px)' }} />
            <VersionContainer>
                <TitleText>Version</TitleText>
                <VersionDetailsContainer>
                    <Flex justifyContent={'space-between'}>
                        <VersionDetailsText>
                            {VERSION_TEXT}: <b>{platformVersion}</b>
                            {BUILD_VERSION && <div>{BUILD_VERSION}</div>}
                        </VersionDetailsText>
                        {updateAvailable && waitingWorker && (
                            <UpdateAvailableLabel theme={theme} text={UPDATE_TEXT} />
                        )}
                    </Flex>
                    {releaseNum && (
                        <Flex justifyContent={'space-between'} margin={'8px 0'}>
                            <VersionDetailsText>
                                {DATA_TEXT}: <b>{releaseNum}</b>
                            </VersionDetailsText>
                            {updateAvailable && (
                                <UpdateAvailableLabel theme={theme} text={UPDATE_TEXT} />
                            )}
                        </Flex>
                    )}
                </VersionDetailsContainer>
                <Flex flexdirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    {updateAvailable && updateServiceWorker && (
                        <Button
                            text={'Update'}
                            type={buttonTypes.GREEN_LONG}
                            background={theme.primary}
                            onClick={updateServiceWorker}
                        />
                    )}
                </Flex>
            </VersionContainer>
            <StyledDivider variant="fullWidth" />
            <StyledAnchor
                href={terms}
                target="_blank"
                onMouseEnter={() => setItemHovered('TOU')}
                onMouseLeave={() => setItemHovered(null)}
            >
                <LinkItem>
                    {TERMS} {itemHovered === 'TOU' && <OpenInNewIcon />}
                </LinkItem>
            </StyledAnchor>
            <StyledAnchor
                href={privacyPolicy?.url || aboutLinks.privacyPolicy}
                target="_blank"
                onMouseEnter={() => setItemHovered('PP')}
                onMouseLeave={() => setItemHovered(null)}
            >
                <LinkItem>
                    {POLICY} {itemHovered === 'PP' && <OpenInNewIcon />}
                </LinkItem>
            </StyledAnchor>
            <StyledAnchor
                href={aboutLinks.cookiePolicy}
                target="_blank"
                onMouseEnter={() => setItemHovered('CP')}
                onMouseLeave={() => setItemHovered(null)}
            >
                <LinkItem>Cookie Policy {itemHovered === 'CP' && <OpenInNewIcon />}</LinkItem>
            </StyledAnchor>
            <StyledDivider variant="fullWidth" style={{ marginTop: 16, marginBottom: 16 }} />
            <FloqImage src={require('../../../../assets/icons/footer-icon.png')} />
            <FooterText>
                {getAboutThisAppFooterTextByLocale(locale).start}{' '}
                <FooterAnchor
                    href="https://www.conferencecompass.com/one-platform/"
                    target="_blank"
                >
                    floq.live
                </FooterAnchor>{' '}
                {getAboutThisAppFooterTextByLocale(locale).end}
            </FooterText>
            <IconsContainer>
                <StyledIconAnchor
                    href="https://www.facebook.com/ConferenceCompass"
                    target="_blank"
                    style={{ marginRight: 16 }}
                >
                    <FacebookIcon />
                </StyledIconAnchor>
                <StyledIconAnchor
                    href="https://twitter.com/ConfCompass"
                    target="_blank"
                    style={{ marginRight: 16 }}
                >
                    <TwitterIcon />
                </StyledIconAnchor>
                <StyledIconAnchor
                    href="https://www.linkedin.com/company/conferencecompass"
                    target="_blank"
                    style={{ marginRight: 16 }}
                >
                    <LinkedinIcon />
                </StyledIconAnchor>
                <StyledIconAnchor href="https://www.youtube.com/user/confcompass" target="_blank">
                    <YoutubeIcon />
                </StyledIconAnchor>
            </IconsContainer>
        </ContentContainer>
    );
};

export default PlatformInfoContent;

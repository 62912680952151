import bcrypt from 'bcryptjs';
import React, { useState } from 'react';
import { Button, DialogContainer, TextField } from 'react-md';
import { withRouter } from 'react-router-dom';

import * as palette from '../../components/General/Variables';
import { addLocalItem } from '../../services/api/db';
import { getString } from '../../services/api/store';

const PasswordProtect = ({ pageId, password, eventName, eventId, hideShowModal, history }) => {
    const [userPassword, setUserPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);

    const TITLE = getString('passwordModalTitle') || eventName || 'Page is restricted';
    const MESSAGE = getString('passwordModalMessage');
    const INPUT_PLACEHOLDER = getString('passwordModalPlaceholder') || 'Password';
    const ERROR_MSG = getString('passwordModalError') || 'Invalid password';
    const BUTTON_TEXT = getString('passwordModalOkButton') || 'Confirm';

    const comparePassword = () => {
        const result = bcrypt.compareSync(userPassword, password);
        if (!result) {
            return setPasswordError(true);
        }

        if (eventId) {
            addLocalItem(
                'appState',
                `${eventId}_kiosk_password_entered`,
                { value: true },
                () => {},
            );
        }

        if (pageId) {
            addPageIdToLocalStorage(pageId);
        }

        hideShowModal();
    };

    const addPageIdToLocalStorage = pageId => {
        try {
            const allowedRestrictedPages =
                JSON.parse(localStorage.getItem('allowedRestrictedPages')) || [];

            localStorage.setItem(
                'allowedRestrictedPages',
                JSON.stringify([...allowedRestrictedPages, pageId]),
            );
        } catch (_) {
            localStorage.setItem('allowedRestrictedPages', JSON.stringify([]));
        }
    };

    const onClose = () => history.goBack();

    const onKeyDown = e => {
        if (e.keyCode === 13) {
            comparePassword();
        }
    };

    const actions = [
        <Button key="ppcancel" flat primary onClick={onClose}>
            Cancel
        </Button>,
        <Button key="pppassword" raised primary onClick={comparePassword}>
            {BUTTON_TEXT}
        </Button>,
    ];

    return (
        <DialogContainer
            id="password-dialog"
            visible={true}
            title={TITLE}
            onHide={comparePassword}
            width={palette.DIALOG_WIDTH}
            aria-describedby="password dialog"
            modal
            initialFocus={''}
            focusOnMount={false}
            containFocus={false}
            footerStyle={{ padding: '16px' }}
            actions={actions}
        >
            <label>{MESSAGE}</label>
            <TextField
                id="password-field"
                label={INPUT_PLACEHOLDER}
                onChange={text => setUserPassword(text)}
                error={!!passwordError}
                errorText={ERROR_MSG}
                onKeyDown={onKeyDown}
                type={'password'}
            />
        </DialogContainer>
    );
};

export default withRouter(PasswordProtect);

import React from 'react';
import styled from 'styled-components';

import Loader from '../../../../components/General/Loader';
import { Flex } from '../../../common/Flex';

const Container = styled(Flex)`
    width: 100%;
    height: 100%;
    background: #f5f5f5;
`;

const LoadingVirtualPage = () => (
    <Container alignItems={'center'}>
        <Loader margintop={'unset'} />
    </Container>
);

export default LoadingVirtualPage;

import React from 'react';

import { CardButton, CardContent, CardWrapper } from './PosterAndSlidesCard';
import { getString } from '../../../../../../services/api/store';
import { useVMState } from '../../containers/main';
import get from 'lodash/get';

const DISPLAY_CARD_LOCAL_STORAGE_KEY = (userId, sessionId) =>
    `displayInfoCard_${userId}_${sessionId}`;

const InfoCard = ({
    cardText = getString('hostInfoCardText'),
    buttonText = getString('hostInfoCardButton'),
}) => {
    const CARD_CONTENT_TEXT =
        cardText ||
        'On this dashboard you can add files and recordings, that, if enabled by the event organizer, become visible to attendees.';
    const CARD_BUTTON_TEXT = buttonText || 'Got it';

    const stateCtx = useVMState();
    const { sessionId, virtualEventUser } = stateCtx;
    const [displayInfoCard, setDisplayInfoCard] = React.useState(false);
    const vUserId = get(virtualEventUser, 'data.UserId');

    React.useEffect(() => {
        if (!sessionId || !vUserId) {
            return;
        }

        const displayInfoCard = localStorage.getItem(
            DISPLAY_CARD_LOCAL_STORAGE_KEY(vUserId, sessionId),
        );
        if (!displayInfoCard) {
            setDisplayInfoCard(true);
        }
    }, [sessionId, vUserId]);

    const handleCardButtonClick = React.useCallback(() => {
        if (!sessionId || !vUserId) {
            return;
        }

        localStorage.setItem(DISPLAY_CARD_LOCAL_STORAGE_KEY(vUserId, sessionId), 'true');
        setDisplayInfoCard(false);
    }, [sessionId, vUserId]);

    if (!displayInfoCard) {
        return null;
    }

    return (
        <CardWrapper>
            <CardContent>{CARD_CONTENT_TEXT}</CardContent>

            <CardButton
                onClick={handleCardButtonClick}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        handleCardButtonClick();
                    }
                }}
            >
                {CARD_BUTTON_TEXT}
            </CardButton>
        </CardWrapper>
    );
};

export default InfoCard;

import axios from 'axios';

export const constructEvaluationUrl = (
    evaluationId,
    itemId,
    itemName,
    eventId,
    configuratorUrl,
    userId,
) => {
    // eslint-disable-next-line
    return `${configuratorUrl}/evaluations/${evaluationId}#?appId=${eventId}&objectId=${itemId}&objectName=${encodeURI(
        itemName,
    )}&eurekaUserId=${userId}`;
};

export const checkIfEvaluated = async (data, configuratorUrl) => {
    const url = `${configuratorUrl}/api/general/answer/check`;

    try {
        const response = await axios({
            method: 'POST',
            url,
            data,
        });

        return response?.data?.hasAnswerSubmitted;
    } catch (err) {
        console.log('err', err);
        return true;
    }
};

import React, { useEffect, useState, useContext } from 'react';
import ThemeContext from '../../../components/Theme/ThemeContext';
import Auth from '../../../services/api/auth';
import AppointmentService from '../services/AppointmentService';
import { withRouter } from 'react-router-dom';
import { navigateTo } from '../../../components/Navigation/NavigationLink';
import { deleteAppointment as deleteEurekaAppointment } from '../../Talk/actions';
import eventBus from '../../../utils/eventBus';
import { useDispatch } from 'react-redux';
import ShowMoreAppointmentsCard from '../components/ShowMoreAppointentsCard';
import CurrentAppointmentCard from '../components/Card';
import { TimezoneContext } from '../../Timezone/context';

const AppointmentCard = ({ userId, setShowAppointmentModal, match, history, location }) => {
    const [appointments, setAppointments] = useState([]);
    const [showCancelMeetingModal, setShowCancelMeetingModal] = useState(null);
    const [showMoreAppointments, setShowMoreAppointments] = useState(false);
    const dispatch = useDispatch();
    const { getUnixToSelectedTimezone } = useContext(TimezoneContext);

    useEffect(() => {
        (async () => {
            await getAppointments();

            eventBus.on('refreshAppointments', getAppointments);
        })();
    }, [userId]);

    const getAppointments = async () => {
        const user = Auth.getUser();

        if (!user) {
            return;
        }

        const organizedAppointments = await AppointmentService.getAppointmentsByUserIdAndMemberId(
            user.id,
            userId,
        );
        const invitedAppointments = await AppointmentService.getAppointmentsByUserIdAndMemberId(
            userId,
            user.id,
        );
        const mergedAppointments = [...organizedAppointments, ...invitedAppointments];

        setAppointments(mergedAppointments.sort((ap1, ap2) => (ap1.start > ap2.start ? 1 : -1)));
    };

    const getDateAndTime = appointment => {
        const start = getUnixToSelectedTimezone(appointment.start);
        const end = getUnixToSelectedTimezone(appointment.end);

        const date = start.format('DD MMMM');
        const timeStart = start.format('HH:mm');
        const timeEnd = end.format('HH:mm');
        const dateTime = `${date} - ${timeStart} - ${timeEnd}`;
        return dateTime;
    };

    const onViewDetailsClick = id => {
        navigateTo({
            match,
            history,
            location,
            params: {
                type: 'appointment',
                objectId: id,
            },
        });
    };

    const deleteAppointment = async appointment => {
        dispatch(deleteEurekaAppointment(appointment));
        setShowCancelMeetingModal(null);
        setShowMoreAppointments(false);
        eventBus.emit('updateMyProgrammeCount');
        eventBus.emit('refreshMyProgramme');
    };
    const appointmentToDisplay = appointments.length && appointments[0];

    if (!appointmentToDisplay) {
        return null;
    }

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    {!showMoreAppointments && (
                        <CurrentAppointmentCard
                            theme={theme}
                            appointmentToDisplay={appointmentToDisplay}
                            getDateAndTime={getDateAndTime}
                            cancelMeeting={() => setShowCancelMeetingModal(appointmentToDisplay.id)}
                            openDetails={() => onViewDetailsClick(appointmentToDisplay.id)}
                            showCancelMeetingModal={showCancelMeetingModal}
                            deleteAppointment={deleteAppointment}
                            setShowCancelMeetingModal={setShowCancelMeetingModal}
                        />
                    )}
                    {appointments && appointments.length > 1 && (
                        <ShowMoreAppointmentsCard
                            theme={theme}
                            appointments={appointments}
                            userId={userId}
                            setShowAppointmentModal={setShowAppointmentModal}
                            getDateAndTime={getDateAndTime}
                            cancelMeeting={setShowCancelMeetingModal}
                            openDetails={onViewDetailsClick}
                            showCancelMeetingModal={showCancelMeetingModal}
                            deleteAppointment={deleteAppointment}
                            setShowCancelMeetingModal={setShowCancelMeetingModal}
                            showMore={showMoreAppointments}
                            setShowMore={setShowMoreAppointments}
                        />
                    )}
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default withRouter(AppointmentCard);

import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, FontIcon } from 'react-md';

import { getString } from '../../../services/api/store';
import { useTheme } from '../../../components/Theme/ThemeContext';
import AppointmentModal from '../../Appointments/containers/AppointmentModal';
import Tooltipped from '../../../components/Tooltip/Tooltipped';

const InputTextWrapper = styled.div`
    display: flex;
    position: absolute !important;
    bottom: 2px;
    left: 0;
    right: 0;
    padding: 12px 16px;
    border-top: solid 1px #edeef2;
    background-color: #ffffff;
`;

const ButtonStyled = styled(Button)`
    height: 36px !important;
    width: 36px !important;
    vertical-align: middle;
    border-radius: 50%;
`;

const PlusButtonStyled = styled(ButtonStyled)`
    margin-right: 4px;
`;

const SendButtonStyled = styled(ButtonStyled)`
    margin-left: 4px;
    opacity: ${props => (props.displayed === 'true' ? 1 : 0)} !important;
    width: ${props => (props.displayed === 'true' ? 'auto' : 0)} !important;
    transition: opacity 0.3s !important;
`;

const TextInput = styled.input`
    border: none;
    outline: none;
    width: 100%;
    height: 36px;
    padding: 6px 12px;
    border-radius: 50px;
    background-color: #f5f5f5;
    font-size: 15px;
    line-height: 24px;
    caret-color: ${props => props.caretColor};
`;

const Icon = styled(FontIcon)`
    color: ${props => props.color} !important;
    vertical-align: top;
    font-size: 24px !important;
`;

const InputMessageComponent = ({ inputMessage, handleChange, sendMessage, participant }) => {
    const [showAppointmentModal, setAppointmentModal] = useState(false);
    const { theme } = useTheme();

    const validMessage = text => {
        return !text || !text.replace(/\s/g, '').length ? 'false' : 'true';
    };

    const keyDown = e => {
        if (e.keyCode === 13 && validMessage(inputMessage)) {
            sendMessage(inputMessage);
        }
    };

    const onSend = () => sendMessage(inputMessage);

    return (
        <>
            <InputTextWrapper>
                {participant && (
                    <Tooltipped label="Add meeting" position="top">
                        <PlusButtonStyled onClick={() => setAppointmentModal(true)}>
                            <Icon color={theme.contrast}>add</Icon>
                        </PlusButtonStyled>
                    </Tooltipped>
                )}
                <TextInput
                    id="messageInputField"
                    placeholder={getString('talkroomInputHintText') || 'Type a message...'}
                    value={inputMessage}
                    onChange={handleChange}
                    onKeyDown={keyDown}
                    caretColor={theme.contrast}
                    autoComplete="off"
                />
                <SendButtonStyled displayed={validMessage(inputMessage)} onClick={onSend}>
                    <Icon color={theme.contrast}>send</Icon>
                </SendButtonStyled>
            </InputTextWrapper>
            {participant && showAppointmentModal && (
                <AppointmentModal
                    participant={participant}
                    onClose={() => setAppointmentModal(false)}
                />
            )}
        </>
    );
};

export default InputMessageComponent;

import React from 'react';

const FacebookIcon = () => (
    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14 0H4a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4h5.621v-6.961H7.278V8.314h2.343V6.309c0-2.324 1.421-3.591 3.495-3.591.699-.002 1.397.034 2.092.105v2.43H13.78c-1.13 0-1.35.534-1.35 1.322V8.31h2.7l-.351 2.725h-2.365V18H14a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Z"
            fill="#B3B3B3"
            fillRule="nonzero"
        ></path>
    </svg>
);

export default FacebookIcon;

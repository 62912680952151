import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import * as palette from '../../components/General/Variables';
import { getString } from '../../services/api/store';
import Loader from '../../components/General/Loader';
import ThemeContext from '../../components/Theme/ThemeContext';
import { getAllNotesForEvent, InteractivityContext } from '../../components/Interactivity';
import { DialogContainer, Divider, FontIcon, List, ListItem } from 'react-md';
import Placeholder from '../SearchPage/components/placeholder';
import { withRouter } from 'react-router-dom';
import { navigateTo } from '../../components/Navigation/NavigationLink';
import eventBus from '../../utils/eventBus';
import { TwitterShareButton } from 'react-simple-share';
import Button, { buttonTypes } from '../common/Button';
import { saveNoteForEvent } from '../../services/api/data';
import * as eureka from '../../services/api/eureka';

const NOTES_INFO_TEXT =
    'Keep your thoughts organized by adding notes to sessions, presentations, persons and more through the “Notes” button.';
const InfoContainer = styled.div`
    margin-top: 5px;
    background-color: ${palette.COLOR_WHITE};
    padding: 20px;

    p {
        font-size: 16px;
        color: ${palette.COLOR_TEXT};
    }
`;

const ListStyled = styled(List)`
    padding-bottom: 0px;
    padding-top: 0px;
`;

const ListItemStyled = styled(ListItem)`
    .md-list-tile--active {
        background: transparent !important;
    }
`;

const SectionTitle = styled.div`
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin: 16px;
    font-family: 'Cabin', sans-serif;
`;

const ButtonsWrapper = styled.div`
    display: flex;
`;

const ViewButton = styled.div`
    padding: 8px 16px;
    color: ${props => props.color};
    font-weight: 600;
    border: 1px solid ${props => props.color};
    border-radius: 12px;
    width: 150px;
    text-align: center;
    background-color: ${palette.COLOR_WHITE};
    transition: color, background-color 0.2s;

    &:hover {
        background-color: ${props => props.color};
        color: ${palette.COLOR_WHITE};
    }
`;

const DeleteButton = styled(ViewButton)`
    border: 1px solid #d50000;
    color: #d50000;
    margin-right: 8px;

    &:hover {
        background-color: #d50000;
    }
`;

const ShareButton = styled(FontIcon)`
    display: flex;
    color: ${palette.COLOR_WHITE} !important;
    background-color: ${props => props.colorPrimary};
    border-radius: 50%;
    border: 10px solid ${props => props.colorPrimary};
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: 16px;
    right: 16px;

    &:hover {
        font-size: 30px !important;
    }
`;

const Link = styled.a`
    font-size: 32px;
    cursor: pointer;
`;

const Icon = styled(FontIcon)`
    font-size: ${props => (props.customheight ? props.customheight : '38px')} !important;
    vertical-align: middle;
`;

const Dialog = styled(DialogContainer)`
    z-index: 100 !important;
    display: flex;
    align-items: center;
`;

class MyNotesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: [],
            loading: true,
            object: null,
            showShareModal: false,
            notesStringToShare: '',
        };
    }

    componentDidMount() {
        this.props.setTitle(this.props.title);
        this.refresh();
    }

    refresh = () => {
        this.props.setTitle();
        this.setState({
            notes: [],
            loading: true,
        });
        getAllNotesForEvent((err, notes) => {
            this.setState({
                notes,
                loading: false,
            });
        });
    };

    onNoteClick = (e, showNotes, note) => {
        let objectClassSingular = note.objectClass;
        const { match, history, location } = this.props;

        // Convert objectClass to singular form
        if (objectClassSingular[objectClassSingular.length - 1] === 's') {
            objectClassSingular = note.objectClass.substring(0, note.objectClass.length - 1);
        }

        // Check if view note button was clicked
        if (e.target.id.indexOf('view-note-btn-') > -1) {
            navigateTo({
                match,
                history,
                location,
                params: {
                    type: 'detail',
                    objectClass: objectClassSingular,
                    objectId: note.objectId,
                },
            });
            this.setState({ object: note });
        } else if (e.target.id.indexOf('delete-note-btn-') > -1) {
            // Check if delete note button was clicked
            saveNoteForEvent(note, '', this.refresh, err => {
                eureka.sync(function () {
                    eventBus.emit('updateNotesCount');
                });
            });
        } else {
            navigateTo({
                match,
                history,
                location,
                params: {
                    type: 'detail',
                    objectClass: objectClassSingular,
                    objectId: note.objectId,
                },
                state: { showNoteDialog: true },
            });
            this.setState({ object: note });
        }
    };

    getNotesToShare = () => {
        if (this.state.notes && this.state.notes.length > 0) {
            const notes = this.state.notes.reduce((acc, value) => {
                acc = acc + `${value.object.name}: ${value.text}\n\n`;
                return acc;
            }, '');

            this.setState({ notesStringToShare: notes });
        }
    };

    render() {
        const DELETE_NOTE_BUTTON_TEXT = getString('noteDeleteButtonTitle') || 'Delete note';
        const VIEW_OBJECT_BUTTON_TEXT = getString('notesPreviewViewButtonTitle') || 'View';
        let previousTypeName = '';
        const Notes = (theme, showNotes) => {
            return this.state.notes.map((note, i) => {
                const typeName = note.object.type.plural;
                let hasTitle = false;
                if (typeName !== previousTypeName) {
                    hasTitle = true;
                    previousTypeName = typeName;
                }

                const objectType =
                    note && note.object && note.object.type && note.object.type.singular
                        ? note.object.type.singular
                        : 'object';

                return (
                    <React.Fragment key={note.id}>
                        {hasTitle && (
                            <SectionTitle secondary={theme.secondary}>{typeName}</SectionTitle>
                        )}
                        <ListItemStyled
                            key={'note_' + note.id}
                            primaryText={note.object.name}
                            secondaryText={ReactHtmlParser(note.text)}
                            threeLines
                            onClick={e => this.onNoteClick(e, showNotes, note)}
                            rightIcon={
                                <ButtonsWrapper>
                                    <DeleteButton id={`delete-note-btn-${i}`}>
                                        {DELETE_NOTE_BUTTON_TEXT}
                                    </DeleteButton>
                                    <ViewButton color={theme.contrast} id={`view-note-btn-${i}`}>
                                        {`${VIEW_OBJECT_BUTTON_TEXT} ${objectType.toLowerCase()}`}
                                    </ViewButton>
                                </ButtonsWrapper>
                            }
                        />
                        <Divider />
                    </React.Fragment>
                );
            });
        };

        let TEXT_NOTES_INFO = getString('myNotesTextHeader') || NOTES_INFO_TEXT;

        const downloadNotesFile = () => {
            const element = document.createElement('a');
            const file = new Blob([this.state.notesStringToShare], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = 'notes.txt';
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        };

        return (
            <ThemeContext.Consumer>
                {({ theme }) => (
                    <InteractivityContext.Consumer>
                        {({ showNotes }) => (
                            <React.Fragment>
                                {this.state.loading && <Loader />}
                                {!this.state.loading && this.state.notes.length ? (
                                    <React.Fragment>
                                        <InfoContainer>
                                            <p>{TEXT_NOTES_INFO}</p>
                                        </InfoContainer>
                                        <ListStyled>{Notes(theme, showNotes)}</ListStyled>
                                    </React.Fragment>
                                ) : (
                                    <Placeholder
                                        type="emptyList"
                                        introText={
                                            getString('personalNoFavoritesTitle') ||
                                            'Nothing saved yet...'
                                        }
                                    />
                                )}
                                <ShareButton
                                    colorPrimary={theme.primary}
                                    onClick={() => {
                                        this.getNotesToShare();
                                        this.setState({ showShareModal: true });
                                    }}
                                >
                                    {palette.ICON_SHARE}
                                </ShareButton>
                                {this.state.showShareModal && (
                                    <Dialog
                                        id="speed-boost"
                                        visible={this.state.showShareModal}
                                        title={getString('share') || 'Share notes:'}
                                        onHide={() => this.setState({ showShareModal: false })}
                                        width={palette.DIALOG_WIDTH}
                                        aria-describedby="speed-boost-description"
                                        actions={[
                                            <Button
                                                key="my_notes"
                                                type={buttonTypes.GREEN}
                                                background={theme.primary}
                                                text={getString('cancel') || 'Cancel'}
                                                onClick={() =>
                                                    this.setState({ showShareModal: false })
                                                }
                                            />,
                                        ]}
                                        focusOnMount={false}
                                    >
                                        <TwitterShareButton text={this.state.notesStringToShare} />
                                        <Link
                                            href={
                                                'mailto:?body=' +
                                                encodeURIComponent(this.state.notesStringToShare)
                                            }
                                        >
                                            <Icon>{palette.ICON_MAIL}</Icon>
                                        </Link>
                                        <Link>
                                            <Icon onClick={downloadNotesFile} customheight={'30px'}>
                                                {palette.FILE_PRESENT_ICON}
                                            </Icon>
                                        </Link>
                                    </Dialog>
                                )}
                            </React.Fragment>
                        )}
                    </InteractivityContext.Consumer>
                )}
            </ThemeContext.Consumer>
        );
    }
}

export default withRouter(MyNotesPage);

import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ExpansionList, ExpansionPanel } from 'react-md';
import ReactHtmlParser from 'react-html-parser';
import styled, { css } from 'styled-components';
import { checkCCLinks } from '../../helper/CCLink';
import ThemeContext from '../../components/Theme/ThemeContext';
import Button, { buttonTypes } from '../common/Button';
import { getString } from '../../services/api/store';

const InfoList = styled(ExpansionList)`
    margin-top: 8px;
    .md-expansion-panel--expanded:not(:first-child) {
        margin-top: -8px;
    }
`;

const infoTextStyle = css`
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    line-height: 20px;
    list-style: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    max-width: 100%;
    overflow: wrap;

    h1 {
        color: ${props => props.primary};
        font-size: 20px;
        font-weight: bold;
    }

    h2 {
        font-weight: bold;
        color: ${props => props.primary};
        font-size: 16px;
    }

    h3 {
        font-weight: 500;
        color: ${props => props.primary};
        font-size: 14px;
    }
    a {
        font-size: 14px;
        color: ${props => props.contrast};
    }

    body,
    p,
    h4,
    h5,
    h6 {
        color: rgba(0, 0, 0, 0.87);
    }

    img {
        max-width: 100% !important;
    }

    table {
        border: 1px solid #dddddd;
        border-collapse: collapse;
    }

    td {
        border: 1px solid #dddddd;
        padding: 8px;
    }
`;

const InfoWrapper = styled(ExpansionPanel)`
    ${infoTextStyle}
    .md-text {
        font-family: 'Cabin', sans-serif;
        font-weight: bold;
        letter-spacing: 0.5px;
        font-size: 14px;
        text-transform: uppercase;
    }

    & * {
        font-size: 14px;
    }

    .md-panel-header,
    .md-panel-content {
        padding-left: 16px;
        font-size: 14px;
        line-height: 1.43;
        color: rgba(0, 0, 0, 0.87);
    }

    iframe {
        width: 100%;
        height: 256px;
    }
`;

const ButtonWrapper = styled.div`
    margin: 16px 16px 0 16px;
`;

export const ContactDetailsButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const ContactDetailsAnchor = styled.a`
    background: transparent;
    border: none;
    cursor: pointer;
    color: ${props => props.color};
    &:hover {
        text-decoration: underline;
    }
`;

export const SectionDescription = styled.div`
    ${infoTextStyle};
    text-align: start !important;
    overflow: ${props => (props.lines === 'unset' ? 'unset' : 'hidden')};
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.lines};
    -webkit-box-orient: vertical;
    margin: 0;

    &.hidden {
        display: -webkit-box;
        -webkit-line-clamp: 15;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    & * {
        white-space: pre-wrap;
        font-size: 14px;
    }

    iframe {
        width: 100%;
        height: 256px;
    }
`;

const SectionTitle = styled.div`
    font-family: Cabin, sans-serif;
    font-weight: bold;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    font-size: 17px;
    margin: 16px;
`;

const MarginLeftRight16px = styled.div`
    padding: 0 16px;
`;

const InfoBar = props => {
    const [readAll, setReadAll] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const infoBar = useRef();

    useEffect(() => {
        const textElem = document.getElementById('description-container');
        let showMore;
        if (textElem) {
            showMore = textElem?.offsetHeight <= textElem?.scrollHeight - 20;
        }
        setShowMore(showMore);
    }, []);

    const update = (toggle, div) => {
        const { match, history, location } = props;
        if (toggle) {
            //Without this setTimeout it's not working
            //Apparently the toggle event is fired before append/attach the div content.
            //Thus, trying to get the anchors without the timeout doesn't work.
            setTimeout(function () {
                checkCCLinks(div.current, match, history, location);
            }, 0);
        }
    };

    const { info, typeParams } = props;

    const READ_MORE_TEXT = getString('announcementsReadMore', 'Read more');
    const READ_LESS_TEXT = getString('announcementsReadLess', 'Read less');

    if (info && info.text && info.text.length) {
        const text = info?.text;
        return (
            <React.Fragment>
                <ThemeContext.Consumer>
                    {({ theme }) => (
                        <div ref={infoBar}>
                            <InfoList>
                                {typeParams.previewType && typeParams.previewType === 'preview' ? (
                                    <>
                                        <SectionTitle secondary={theme.secondary}>
                                            {info.title || 'Info'}
                                        </SectionTitle>
                                        <MarginLeftRight16px>
                                            <SectionDescription
                                                id={'description-container'}
                                                lines={readAll ? 'unset' : 10}
                                                contrast={theme.contrast}
                                                primary={theme.primary}
                                            >
                                                {ReactHtmlParser(text)}
                                            </SectionDescription>
                                        </MarginLeftRight16px>
                                        {showMore && (
                                            <ButtonWrapper>
                                                <Button
                                                    type={buttonTypes.GREY_LONG}
                                                    background={theme.primary}
                                                    text={readAll ? READ_LESS_TEXT : READ_MORE_TEXT}
                                                    onClick={() => {
                                                        setReadAll(!readAll);
                                                    }}
                                                />
                                            </ButtonWrapper>
                                        )}
                                    </>
                                ) : (
                                    <InfoWrapper
                                        label={info.title || 'Info'}
                                        footer={null}
                                        onExpandToggle={e => update(e, infoBar)}
                                        contrast={theme.contrast}
                                        primary={theme.primary}
                                    >
                                        <div>{ReactHtmlParser(info.text)}</div>
                                    </InfoWrapper>
                                )}
                            </InfoList>
                        </div>
                    )}
                </ThemeContext.Consumer>
            </React.Fragment>
        );
    } else {
        return null;
    }
};

export default withRouter(InfoBar);

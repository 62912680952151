import { NOTIFICATION_PERMISSIONS } from '../../Appointments/constants';
import { APPOINTMENT_STATE } from '../../Talk/constants';
import AppointmentService from '../../Appointments/services/AppointmentService';
import { isSafari } from 'react-device-detect';
import { getFullProfileById } from '../../../services/api/eureka';
import { getObjectClassWithId } from '../../../services/api/db';
import eventBus from '../../../utils/eventBus';
import { notificationTypes } from '../constants';
import { getString } from '../../../services/api/store';

class NotificationService {
    handleNotification(params) {
        const { notificationType } = params;

        switch (notificationType) {
            case notificationTypes.CHAT:
                return this.handleChatNotification(params);
            case notificationTypes.APPOINTMENT:
                return this.handleAppointmentNotification(params);
            case notificationTypes.REMINDER:
                return this.handleReminderNotification(params);
            case notificationTypes.NEWS:
                return this.handleNewsNotification(params);
            default:
                return;
        }
    }

    async handleChatNotification(params) {
        const { userId, type, roomId } = params;

        const eurekaUser = await getFullProfileById(userId);
        const url = `${window.location.origin}/myconversations`;

        eventBus.emit('updateConversationsCount');

        if (type === 'privateChat') {
            return this.showNotification(
                `New Message from ${eurekaUser.firstName} ${eurekaUser.lastName}`,
                () => window.open(url, '_self'),
            );
        }

        getObjectClassWithId(roomId, (err, objectClass, objectItem) => {
            const roomName = objectItem && objectItem.name;
            return this.showNotification(
                `New Message from ${eurekaUser.firstName} ${
                    eurekaUser.lastName && eurekaUser.lastName.charAt([0])
                }. in ${roomName}`,
                () => window.open(url, '_blank'),
            );
        });
    }

    async handleNewsNotification(params) {
        return this.showNotification(`New Message: ${params.title}`, () =>
            window.open(`${window.location.href}`, '_blank'),
        );
    }

    async handleAppointmentNotification(params) {
        const { appointmentId, type } = params;
        const appointment = await AppointmentService.getAppointmentById(appointmentId);
        const { User: user, members } = appointment;
        const participant = members && members.length ? members[0] : {};
        const url = `${window.location.origin}/mybookmarks/appointment/${appointmentId}`;

        switch (type) {
            case APPOINTMENT_STATE.SCHEDULED: {
                return this.showNotification(
                    `${user.firstName} ${user.lastName} scheduled an appointment`,
                    () => window.open(url, '_blank'),
                );
            }
            case APPOINTMENT_STATE.ACCEPTED:
                return this.showNotification(
                    `${participant.firstName} ${participant.lastName} accepted the appointment`,
                    () => window.open(url, '_blank'),
                );
            case APPOINTMENT_STATE.DECLINED:
                return this.showNotification(
                    `${participant.firstName} ${participant.lastName} declined the appointment`,
                    () => window.open(url, '_blank'),
                );
            case APPOINTMENT_STATE.UPDATED:
                return this.showNotification(
                    `${user.firstName} ${user.lastName} updated the appointment`,
                    () => window.open(url, '_blank'),
                );
            case APPOINTMENT_STATE.DELETED:
                return this.showNotification(
                    `${user.firstName} ${user.lastName} deleted the appointment`,
                    () => {},
                );
            default:
                return null;
        }
    }

    async handleReminderNotification(params) {
        const { reminderObject } = params;
        const { isEvaluationReminder } = reminderObject;
        const type = reminderObject.typeObj.singular;
        const target = reminderObject.typeObj.target.toLowerCase();
        const beforeStartReminderTitle = `${type} '${reminderObject.name}' starts in 10 minutes!`;
        const EVALUATION_TEXT = getString(
            'evaluationNotificationMessage',
            "Don't forget to evaluate",
        );
        const afterEndReminderTitle = `${EVALUATION_TEXT} ${type} '${reminderObject.name}'`;
        const title = isEvaluationReminder ? afterEndReminderTitle : beforeStartReminderTitle;
        const url = `${window.location.origin}/mybookmarks/${target}/${reminderObject.id}`;

        this.showNotification(title, () => window.open(url, '_blank'));
    }

    requestPermission(callback) {
        if (
            !('Notification' in window) ||
            (Notification && Notification.permission !== NOTIFICATION_PERMISSIONS.DEFAULT)
        ) {
            console.log('This browser does not support desktop notification');
            return;
        }

        if (isSafari) {
            return Notification.requestPermission(() => {
                if (callback) {
                    callback();
                }
            });
        }

        Notification.requestPermission()
            .then(() => {
                if (callback) {
                    callback();
                }
            })
            .catch(() => {
                callback();
            });
    }

    showNotification(title, onClickCallback) {
        if (Notification.permission !== NOTIFICATION_PERMISSIONS.GRANTED) {
            this.requestPermission(() => {
                const notification = new Notification(title);
                notification.onclick = onClickCallback;
            });
        } else {
            const notification = new Notification(title);
            notification.onclick = onClickCallback;
        }
    }
}

export default new NotificationService();

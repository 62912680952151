export const APPOINTMENT_PARTICIPANT_STATUS = {
    WAITING: 0,
    ACCEPTED: 1,
    DECLINED: 2,
};

export const NOTIFICATION_PERMISSIONS = {
    DEFAULT: 'default',
    GRANTED: 'granted',
    DENIED: 'denied',
};

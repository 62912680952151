import { useEffect } from 'react';

import { useVMState, useVMMutation } from '../containers/main';

export default function useScreenSharingStream(client) {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();

    const [screenShareStream] = [stateCtx.screenShareStream];

    useEffect(() => {
        if (!client) {
            return;
        }
        mutationCtx.setScreenSharingClient(client);
    }, [client]);

    useEffect(() => {
        if (!client) {
            return;
        }
        const canceledScreenSharing = () => {
            mutationCtx.setExtraState({
                screenSharing: false,
                shareType: undefined,
            });
        };
        const sharingStarted = evt => {
            mutationCtx.setScreenSharingStream(evt);
            mutationCtx.setShareType(client._shareType);
        };

        if (client && client._subscribed === false) {
            client.on('stopScreenSharing', canceledScreenSharing);
            client.on('localStream-added', sharingStarted);
            client.on('localStream-removed', () => mutationCtx.setScreenSharingStream(null));
            client._subscribed = true;
        }
    }, [client, mutationCtx]);

    if (!client) {
        return [];
    }

    return [screenShareStream];
}

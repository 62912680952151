import React from 'react';

import BubbleGroup from '../BubbleGroup';
import DefaultChatBubble from '../ChatBubble';

const ChatMessages = ({
    messages,
    bubbleStyles,
    chatBubble,
    chatRoomId,
    onSetMessageToDelete,
    nonVirtualQA,
}) => {
    const ChatBubble = chatBubble || DefaultChatBubble;
    let group = [];

    return (
        <>
            {messages.map((message, index) => {
                group.push(message);
                // Find diff in message type or no more messages
                if (
                    index === messages.length - 1 ||
                    messages[index + 1].user.id !== message.user.id
                ) {
                    const messageGroup = group;
                    group = [];

                    return (
                        <BubbleGroup
                            key={`${message.id}`}
                            messages={messageGroup}
                            chatBubble={ChatBubble}
                            bubbleStyles={bubbleStyles}
                            chatRoomId={chatRoomId}
                            onSetMessageToDelete={onSetMessageToDelete}
                            nonVirtualQA={nonVirtualQA}
                        />
                    );
                }
                return null;
            })}
        </>
    );
};

export default ChatMessages;

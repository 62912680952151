import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import { Dialog } from '@material-ui/core';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import HowToVoteOutlinedIcon from '@mui/icons-material/HowToVoteOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Button as ButtonMD, FontIcon } from 'react-md';

import { createPoll, getPollById, updatePoll } from '../services/PollingService';
import ImageUpload from '../../Votes/components/ImageUpload';
import VoteOption from './VoteOption';
import { useVMState } from '../../virtualFeature/containers/main';
import { ButtonClassType } from '../../virtualFeature/components/common/Button';
import SubmitButton from './SubmitButton';
import Loader from '../../virtualFeature/components/common/Loader';
import { CancelModalButton } from '../../virtualFeature/components/moderator/common/styles';
import { compressImage } from '../../../utils';
import { uploadPollImagePlaceholderToS3 } from '../../virtualFeature/services/VirtualEventSession';
import Stack from '@mui/material/Stack';

const StyledDialog = withStyles(() => ({
    paper: {
        borderRadius: 12,
        width: 956,
        height: 830,
    },
}))(Dialog);

const StyledTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            opacity: 0,
        },
        '& label.MuiFormLabel-filled': {
            opacity: 0,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#1fa294',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid #f1f1f3',
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(0, 0, 0, 0.38)',
            fontFamily: 'Cabin, sans-serif',
            fontSize: 24,
            fontWeight: 500,
        },
        '& .MuiInputBase-input': {
            paddingBottom: 6,
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Cabin, sans-serif',
            fontSize: 24,
            fontWeight: 500,
        },
    },
})(TextField);

const CloseIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

const CloseIcon = styled(FontIcon)`
    color: rgba(0, 0, 0, 0.38);
    font-size: 24px;
    ${props => !props.timerbutton && 'transform: translateY(6px)'};
    ${props => props.timerbutton && 'margin-left: 16px'};
    cursor: pointer;
`;

const PrimaryText = styled.p`
    color: rgba(0, 0, 0, 0.87);
    font-family: Cabin, sans-serif;
    font-size: 17px;
    line-height: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    margin: 0 4px 0 12px;
`;

const SecondaryText = styled.p`
    color: rgba(0, 0, 0, 0.6);
    font-family: Cabin, sans-serif;
    font-size: 15px;
    line-height: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-bottom: 0px;
`;

const FlexRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin: 16px;
    ${props => props.marginleft && 'margin-left: 36px;'}
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`;

const CancelButton = styled(CancelModalButton)`
    margin-left: 8px;
    border: none;
    max-width: 70px;
`;

const StyledButton = styled(ButtonMD)`
    width: 130px;
    height: 40px !important;
    border-radius: 6px !important;
    background-color: #f1f1f3 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: bold !important;
    font-size: 15px !important;
    line-height: 16px !important;
    margin-left: 12px;
    letter-spacing: 0.5px;
    text-transform: none !important;
    border: none;
`;

const SubmitButtonText = styled.div`
    color: #fff !important;
    font-weight: bold !important;
    font-size: 15px !important;
    line-height: 16px;
    letter-spacing: 0.5px;
`;

const TimerContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    width: 100px;
    height: 40px !important;
    border-radius: 6px !important;
    background-color: #f1f1f3 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: bold !important;
    font-size: 15px !important;
    line-height: 16px;
    margin-left: 12px;
    letter-spacing: 0.5px;
    border: none;
`;

const GridContainer = styled.div`
    display: grid;
    grid-gap: 8px;
    margin: 0 0 16px 36px;
`;

const CaretWrapper = styled.i`
    cursor: pointer;
`;

const SubmitButtonLoader = styled(Loader)`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    height: 25px;
    margin: 0;
    background: transparent;
    box-shadow: unset !important;
    transform: translate(-50%, -50%);
`;

const CharCountText = styled.p`
    position: absolute;
    bottom: -32px;
    right: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: right;
    color: rgba(0, 0, 0, 0.6);
`;

export const votesColorScheme = [
    '#1baae4',
    '#ee6d60',
    '#6cc565',
    '#f2b332',
    '#5460e4',
    '#e538a1',
    '#F2934C',
    '#A354D1',
];

const contentStyle = {
    padding: 24,
    overflow: 'hidden',
    overflowY: 'scroll',
};

const iconStyle = {
    width: 24,
    height: 24,
};

const MAX_TIMER_SECONDS = 240;
const MIN_TIMER_SECONDS = 20;
const SECONDS_INCREMENT = 5;
const MAX_TITLE_CHARACTER_LIMIT = 150;

const getDefaultData = () => ({
    title: '',
    imageUrl: '',
    useTimer: false,
    seconds: MIN_TIMER_SECONDS,
    options: [
        { text: '', correct: false },
        { text: '', correct: false },
        { text: '', correct: false },
        { text: '', correct: false },
        { text: '', correct: false },
        { text: '', correct: false },
        { text: '', correct: false },
        { text: '', correct: false },
    ],
});

const SubCategoryTitle = ({
    iconName,
    primaryText,
    secondaryText,
    button,
    noMargin,
    displayColumn,
}) => {
    let Icon;
    switch (iconName) {
        case 'image':
            Icon = PhotoOutlinedIcon;
            break;
        case 'vote':
            Icon = HowToVoteOutlinedIcon;
            break;
        case 'timer':
            Icon = TimerOutlinedIcon;
            break;
        default:
            Icon = PhotoOutlinedIcon;
    }
    if (displayColumn) {
        return (
            <FlexRow nomargin={noMargin ? 1 : 0}>
                <Icon style={iconStyle} />
                <Stack paddingLeft={1.5}>
                    {primaryText && <PrimaryText style={{ margin: 0 }}>{primaryText}</PrimaryText>}
                    {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
                </Stack>
                {!!button && button}
            </FlexRow>
        );
    }

    return (
        <FlexRow nomargin={noMargin ? 1 : 0}>
            <Icon style={iconStyle} />
            {primaryText && <PrimaryText>{primaryText}</PrimaryText>}
            {secondaryText && <SecondaryText> - {secondaryText}</SecondaryText>}
            {!!button && button}
        </FlexRow>
    );
};

const QuestionModal = ({ open, handleClose, pollSetId, questionId, hideCheckmarkBox }) => {
    const [data, setData] = useState(getDefaultData());
    const [file, setFile] = useState(null);
    const [question, setQuestion] = useState(null);
    const [deletedOptions, setDeletedOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const areTwoOptionsSet =
        (data.options &&
            data.options[0] &&
            data.options[1] &&
            !!data.options[0].text &&
            !!data.options[1].text) ||
        false;
    const isTitleSet = !!data.title;
    const isSubmitEnabled = areTwoOptionsSet && isTitleSet;

    const stateCtx = useVMState();
    const { sessionId } = stateCtx;

    useEffect(() => {
        (async () => {
            if (questionId) {
                const fetchedQuestion = await fetchQuestion(questionId);

                setQuestion(fetchedQuestion);
                setDeletedOptions([]);
                mapQuestionToData(fetchedQuestion);
            } else {
                setData(getDefaultData());
            }
        })();
    }, [questionId]);

    const fetchQuestion = async () => {
        const response = await getPollById(questionId);

        return response;
    };

    const mapQuestionToData = question => {
        const { useTimer, seconds, title, imageUrl } = question;
        const options = get(question, 'PollOptions', [])
            .sort((o1, o2) => o1.order - o2.order)
            .map(o => ({
                id: o.id,
                text: o.text,
                correct: o.correct,
            }));

        setData({
            title,
            options,
            useTimer,
            seconds,
            imageUrl,
        });
    };

    const onTitleChange = event => {
        const { value } = event.target;

        if (value.length > MAX_TITLE_CHARACTER_LIMIT) {
            const trimmedValue = value.slice(0, MAX_TITLE_CHARACTER_LIMIT);
            setData({
                ...data,
                title: trimmedValue,
            });
            return;
        }

        setData({
            ...data,
            title: value,
        });
    };

    const onImageChange = async file => {
        setFile(file);
        setData({
            ...data,
            imageUrl: URL.createObjectURL(file),
        });
    };

    const createPollAction = async () => {
        const { title, options, seconds, useTimer } = data;
        let response;
        let poll = {
            title,
            seconds,
            useTimer,
            PollSetId: pollSetId,
            PollOptions: options.map((option, index) => ({
                text: option.text,
                order: index,
                correct: option.correct,
            })),
        };

        if (file) {
            const timestamp = new Date().getTime();
            const resizedFile = await compressImage(file, 25);
            response = await uploadPollImagePlaceholderToS3(
                resizedFile,
                sessionId,
                pollSetId,
                timestamp,
            );
        }

        if (response) {
            poll = { ...poll, imageUrl: response.link };

            setData({
                ...data,
                imageUrl: response.link,
            });
        }

        await createPoll(poll);
    };

    const updatePollAction = async () => {
        const { title, options, seconds, useTimer, imageUrl } = data;
        const pollOptions = options.map((option, index) => {
            const existingPollOption = option.id
                ? get(question, 'PollOptions', []).find(op => op.id === option.id)
                : {};

            return {
                ...existingPollOption,
                text: option.text,
                order: index,
                correct: option.correct,
            };
        });
        let response;
        let poll = {
            ...question,
            title,
            seconds,
            useTimer,
            imageUrl,
            PollSetId: pollSetId,
            PollOptions: pollOptions,
            deleted: deletedOptions,
        };

        if (file) {
            const timestamp = new Date().getTime();
            const resizedFile = await compressImage(file, 25);

            response = await uploadPollImagePlaceholderToS3(
                resizedFile,
                sessionId,
                pollSetId,
                timestamp,
            );
        }

        if (response) {
            poll = { ...poll, imageUrl: response.link };

            setData({
                ...data,
                imageUrl: response.link,
            });
        }

        await updatePoll(poll);
    };

    const onSubmit = async () => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);

        if (questionId) {
            await updatePollAction();
        } else {
            await createPollAction();
        }

        setIsLoading(false);
        setData(getDefaultData());
        setFile(null);
        handleClose();
    };

    const handleUseTimer = value => {
        setData({
            ...data,
            useTimer: value,
        });
    };

    const convertSecondsToTime = seconds => {
        const minutes = Math.floor(seconds / 60);
        let remainingSeconds = seconds - minutes * 60;
        if (remainingSeconds < 10) {
            remainingSeconds = '0' + remainingSeconds;
        }
        return `${minutes} : ${remainingSeconds}`;
    };

    const handleTimerSeconds = increase => {
        if (increase && !(data.seconds + SECONDS_INCREMENT > MAX_TIMER_SECONDS)) {
            setData({
                ...data,
                seconds: data.seconds + SECONDS_INCREMENT,
            });
        }
        if (!increase && !(data.seconds - SECONDS_INCREMENT < MIN_TIMER_SECONDS)) {
            setData({
                ...data,
                seconds: data.seconds - SECONDS_INCREMENT,
            });
        }
    };

    const getOnSubmitButtonContent = () =>
        isLoading ? (
            <SubmitButtonLoader
                extraCircleStyle={{
                    color: '#fff',
                    marginLeft: '5px',
                    height: '15px',
                    width: '15px',
                }}
            />
        ) : (
            <SubmitButtonText>Save</SubmitButtonText>
        );

    const TimerComponent = () =>
        !data.useTimer ? (
            <StyledButton
                flat
                onClick={() => {
                    handleUseTimer(true);
                }}
            >
                Add timer
            </StyledButton>
        ) : (
            <>
                <TimerContainer>
                    {convertSecondsToTime(data.seconds)}
                    <FlexColumn>
                        <CaretWrapper
                            className="icon-caret-up"
                            onClick={() => {
                                handleTimerSeconds(true);
                            }}
                        />
                        <CaretWrapper
                            className="icon-caret-down"
                            onClick={() => {
                                handleTimerSeconds(false);
                            }}
                        />
                    </FlexColumn>
                </TimerContainer>
                <CloseIcon
                    onClick={() => {
                        handleUseTimer(false);
                    }}
                    timerbutton="true"
                >
                    close
                </CloseIcon>
            </>
        );

    return (
        <StyledDialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogContent style={contentStyle}>
                <CloseIconContainer>
                    <CloseIcon onClick={handleClose}>close</CloseIcon>
                </CloseIconContainer>
                <FlexRow marginleft nomargin>
                    <StyledTextField
                        value={data.title}
                        onChange={onTitleChange}
                        id="add-question"
                        label="Add a question"
                        type="text"
                        fullWidth
                    />
                    <CharCountText style={data.title.length >= 150 ? { color: 'red' } : {}}>
                        {data.title.length} / {MAX_TITLE_CHARACTER_LIMIT}
                    </CharCountText>
                </FlexRow>
                <SubCategoryTitle iconName="image" primaryText="Image" secondaryText="optional" />
                <ImageUpload placeHolderUrl={data.imageUrl} onImageChange={onImageChange} />
                <SubCategoryTitle
                    iconName="vote"
                    primaryText="Answers"
                    displayColumn
                    secondaryText={`Options can have max. ${MAX_TITLE_CHARACTER_LIMIT} characters. Optionally, click the checkmark to indicate it as the correct one.`}
                />
                <GridContainer
                    style={{
                        gridTemplateColumns: 'minmax(0, 1fr)  '.repeat(2),
                        gridAutoRows: '1fr',
                    }}
                >
                    {data.options.map((option, index) => (
                        <VoteOption
                            key={`vote-option-${index}`}
                            label={String.fromCharCode(65 + index)}
                            option={option}
                            placeholder="Option"
                            setOption={newOption => {
                                let newData = { ...data };
                                newData.options[index] = newOption;
                                setData(newData);
                            }}
                            setAsCorrectOption={
                                !hideCheckmarkBox
                                    ? () => {
                                          let newData = { ...data };
                                          const wasCorrectBefore =
                                              data.options[index].correct === true;
                                          newData.options.forEach(
                                              option => (option.correct = false),
                                          );
                                          if (!wasCorrectBefore) {
                                              newData.options[index].correct = true;
                                          }
                                          setData(newData);
                                      }
                                    : false
                            }
                            disableThisOption={index !== 0 && data.options[index - 1].text === ''}
                            color={votesColorScheme[index]}
                            isForModal
                        />
                    ))}
                </GridContainer>
                <SubCategoryTitle noMargin iconName="timer" button={<TimerComponent />} />
                <ButtonsContainer>
                    <SubmitButton
                        classType={ButtonClassType.BLUE_BOLD}
                        content={getOnSubmitButtonContent()}
                        loading={isLoading}
                        callback={onSubmit}
                        disabled={!isSubmitEnabled}
                        data-qa="submit-poll-question-button"
                        width="66px"
                    />
                    <CancelButton flat onClick={handleClose}>
                        Cancel
                    </CancelButton>
                </ButtonsContainer>
            </DialogContent>
        </StyledDialog>
    );
};

export default QuestionModal;

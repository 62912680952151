import React, { useEffect } from 'react';
import { useGlobalState, useGlobalMutation } from '../../../utils/container';

export default function useScreenSharingStream(client) {
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();

    const [screenShareStream] = [stateCtx.screenShareStream];

    useEffect(() => {
        if (client) {
            mutationCtx.setScreenSharingClient(client);
        }
    }, [client]);

    useEffect(() => {
        const canceledScreenSharing = () => {
            mutationCtx.stopScreenSharing();
            mutationCtx.setShareType(undefined);
        };
        const sharingStarted = evt => {
            mutationCtx.setScreenSharingStream(evt);
            mutationCtx.setShareType(client._shareType);
        };

        if (client && client._subscribed === false) {
            client.on('stopScreenSharing', canceledScreenSharing);
            client.on('localStream-added', sharingStarted);
            client._subscribed = true;
        }
    }, [client, mutationCtx]);

    useEffect(() => {
        if (
            client &&
            client._subscribed === true &&
            client._joined === true &&
            client._streamTypeSet !== true &&
            screenShareStream
        ) {
            client.setRemoteVideoStreamType(screenShareStream, 0);
            client._streamTypeSet = true;
        }
    }, [client, stateCtx]);

    return [screenShareStream];
}

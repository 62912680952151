import React, { useEffect, useState } from 'react';
import { Avatar } from 'react-md';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { getFullProfileById } from '../../services/api/eureka';
import Auth from '../../services/api/auth';
import AuthUserContext from '../Session/AuthUserContext';
import { StyledListItem } from './style/index';
import { ImageAvatar } from '../Icons';

const CustomAvatar = styled(Avatar)`
    border: 0px solid rgba(0, 0, 0);
`;

const UserListItem = props => {
    const [completeUser, setCompleteUser] = useState(null);
    const { match, history, location } = props;

    useEffect(() => {
        const userKeys = Object.keys(props.user);
        // only fetch user if its only property is ID
        userKeys.length === 1 && userKeys[0] === 'id'
            ? fetchUserById(props.user.id)
            : setCompleteUser(props.user);
    }, [props.user]);

    const fetchUserById = async id => {
        const eurekaUser = await getFullProfileById(id);
        setCompleteUser(eurekaUser);
    };

    const UserAvatar = data => {
        const initials = `${data.firstName[0].toUpperCase()}${data.lastName[0].toUpperCase()}`;
        return data.imageUrl ? (
            <ImageAvatar imageId={data.imageUrl || data.image} avatarType={'persons'} />
        ) : (
            <CustomAvatar>{initials}</CustomAvatar>
        );
    };

    const onChatCardClick = (e, showSignIn) => {
        if (!Auth.isUserAuthenticated()) {
            showSignIn(e, { match, history, location });
            return;
        }
    };

    return (
        completeUser && (
            <AuthUserContext.Consumer>
                {({ showSignIn }) => (
                    <StyledListItem
                        key={'user_item_' + props.user.id}
                        primaryText={
                            completeUser.displayName ||
                            `${completeUser.firstName} ${completeUser.lastName}`
                        }
                        secondaryText={completeUser.jobTitle}
                        leftAvatar={UserAvatar(completeUser)}
                        avatarcolor={completeUser.color}
                        onClick={e => onChatCardClick(e, showSignIn)}
                    />
                )}
            </AuthUserContext.Consumer>
        )
    );
};

export default withRouter(UserListItem);

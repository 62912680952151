import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { useVMState, useVMMutation } from '../../containers/main';
import Presenter from './Presenter';
import entities from '../../constants/entities';
import bestFitLayout from '../../../../utils/bestFitLayout';

const Wrapper = styled.div`
    flex-shrink: 0;
    padding: 16px;
    overflow: scroll;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    align-items: center;
    aspect-ratio: 16 / 9;

    ${props =>
        props.inverse
            ? `
        width: calc(100% - 32px);
        `
            : `
        height: calc(100% - 48px);
        margin-top: -16px;
    `}
`;

const GridForUsers = styled.div`
    display: grid;
    height: ${props => (props.lessHeight ? 'calc(100% - 40px)' : '100%')};
    width: 100%;
    overflow: hidden;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
`;

const Text = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    font-family: Roboto;
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
`;

const VIEWPORT_HEIGHT_EXTRAS = 154; // = 84 (footer) + 64 (header) + 16 (vertical padding)

const NoSlidesView = () => {
    const isWindows = window.navigator.appVersion.indexOf('Win') !== -1;
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const {
        virtualEventUser,
        virtualEventSession,
        sessionId,
        socket,
        peers,
        user: currentUser,
    } = stateCtx;
    const desiredAspectRatio = 16 / 9;
    const windowAspectRatio = window.innerWidth / (window.innerHeight - VIEWPORT_HEIGHT_EXTRAS);
    const isDesiredAspectRatio = windowAspectRatio > desiredAspectRatio;

    const [isWindowDesiredAspectRatio, setIsWindowDesiredAspectRatio] = useState(
        isDesiredAspectRatio,
    );

    const virtualEventUsers = useMemo(() => {
        const virtualEventUsersUnparsed = get(virtualEventSession, 'data.VirtualEventUsers', []);

        return virtualEventUsersUnparsed.filter(virtualEventUserUnparsed => {
            const isCurrentUser = virtualEventUserUnparsed.id === virtualEventUser.data.id;
            return (
                isCurrentUser ||
                peers.find(
                    peerId =>
                        currentUser.id !== peerId && peerId === virtualEventUserUnparsed.UserId,
                )
            );
        });
    }, [virtualEventSession, peers]);

    const onRemove = async virtualUserIndex => {
        const virtualEventUsersClone = [...virtualEventUsers];
        if (
            virtualEventUsersClone[virtualUserIndex].role ===
            entities.virtualEventUserEntity.role.roundTableHost
        ) {
            return;
        }
        const virtualEventUserId = virtualEventUsersClone[virtualUserIndex].id;
        const userId = virtualEventUsersClone[virtualUserIndex].UserId;
        virtualEventUsersClone.splice(virtualUserIndex, 1);

        mutationCtx.setVirtualEventSession({
            ...virtualEventSession,
            data: {
                ...virtualEventSession.data,
                VirtualEventUsers: virtualEventUsersClone,
            },
        });
        socket.emit('kickUser', { objectId: sessionId, userId, virtualEventUserId });
    };

    const onTurnMicOff = async virtualUserIndex => {
        const virtualEventUsersClone = [...virtualEventUsers];
        virtualEventUsersClone[virtualUserIndex] = {
            ...virtualEventUsersClone[virtualUserIndex],
            isMicrophoneOn: false,
        };
        const virtualEventUserId = virtualEventUsersClone[virtualUserIndex].id;

        mutationCtx.setVirtualEventSession({
            ...virtualEventSession,
            data: {
                ...virtualEventSession.data,
                VirtualEventUsers: virtualEventUsersClone,
            },
        });
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUserId,
                        isMicrophoneOn: false,
                    },
                ],
            },
        });
    };

    const onResize = debounce(() => {
        const desiredAspectRatio = 16 / 9;
        const windowAspectRatio = window.innerWidth / (window.innerHeight - VIEWPORT_HEIGHT_EXTRAS);

        setIsWindowDesiredAspectRatio(windowAspectRatio > desiredAspectRatio);
    }, 500);

    useEffect(() => {
        window.addEventListener('resize', onResize, true);

        return () => window.removeEventListener('resize', onResize, true);
    }, []);

    const gridLayout = bestFitLayout(virtualEventUsers.length);

    return (
        <Wrapper isWindows={isWindows} inverse={!isWindowDesiredAspectRatio ? 1 : 0}>
            <GridForUsers
                style={{
                    gridTemplateColumns: 'minmax(0, 1fr)  '.repeat(gridLayout),
                    gridAutoRows: '1fr',
                }}
                lessHeight={virtualEventUsers.length === 1 ? 1 : 0}
            >
                {virtualEventUsers.map((virtualEventUserData, index) => (
                    <Presenter
                        key={index}
                        index={index}
                        virtualEventUserData={virtualEventUserData}
                        onRemove={() => onRemove(index)}
                        onTurnMicOff={() => onTurnMicOff(index)}
                        noFixedAspectRatio
                    />
                ))}
            </GridForUsers>
            {virtualEventUsers.length === 1 && <Text>No participants entered the room yet.</Text>}
        </Wrapper>
    );
};

export default NoSlidesView;

import React, { useState } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import VolumeUpIconOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';

import AudioPlayer from '../common/AudioPlayer';
import { CardButton, CardContent, CardWrapper, TitleContainer } from './PosterAndSlidesCard';
import { useVMMutation, useVMState } from '../../containers/main';
import {
    CancelModalButton,
    ContentModalContainer,
    DestructiveModalButton,
} from '../moderator/common/styles';
import ConfirmationDialog from '../common/ConfirmatonDialog';
import onDeleteAudioDescription from '../../events/onDeleteAudioDescription';

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    padding: 0 25px;
    margin-bottom: -3px;
`;

const AudioDescriptionCard = () => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const { virtualEventUser, virtualEventSession, sessionId, socket, timeslot } = stateCtx;
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { uploadedPresentationUrl } = virtualEventUser.data;
    const audioUrls = get(virtualEventSession, 'data.audioUrls') || [];
    const audioUrl = audioUrls.length && audioUrls[0];
    const audioIsSubmitted = audioUrls.length > 0;
    const posterPdf = timeslot && timeslot.posterPdf && JSON.parse(timeslot.posterPdf);
    const posterName = posterPdf && posterPdf.name;
    const areSlidesDeleted = !uploadedPresentationUrl && !posterName;

    const setHostIsRecordingAudio = value => {
        mutationCtx.setIsRecordingAudio(value);
    };

    const onDeleteAudio = async () => {
        await onDeleteAudioDescription(stateCtx, mutationCtx);

        setShowDeleteModal(false);
    };

    const onRecordAudio = () => {
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isMicrophoneOn: true,
                    },
                ],
            },
        });

        setHostIsRecordingAudio(true);
    };

    return (
        <CardWrapper>
            <TitleContainer>
                <VolumeUpIconOutlinedIcon style={{ marginRight: 18 }} />
                Audio description
            </TitleContainer>
            {areSlidesDeleted && (
                <CardContent>
                    Record an audio description to accompany your slides or poster. Requires a
                    poster or slides before recording.
                </CardContent>
            )}
            {!areSlidesDeleted && !audioIsSubmitted && (
                <>
                    <CardContent>
                        Record an audio description to accompany your slides or poster.
                    </CardContent>
                    <CardButton
                        onClick={onRecordAudio}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                onRecordAudio();
                            }
                        }}
                    >
                        Record audio
                    </CardButton>
                </>
            )}
            {!!audioUrl && (
                <AudioPlayer url={audioUrl} primary onDelete={() => setShowDeleteModal(true)} />
            )}
            <ConfirmationDialog
                open={showDeleteModal}
                title="Delete audio description"
                titleIcon="delete"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                outlinedIcon
                onClose={() => setShowDeleteModal(false)}
                content={
                    <ExtraStyledModalContentContainer>
                        Your audio description will be permanently deleted and cannot be recovered.
                    </ExtraStyledModalContentContainer>
                }
                buttons={[
                    <DestructiveModalButton
                        key="b1"
                        flat
                        onClick={onDeleteAudio}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                onDeleteAudio();
                            }
                        }}
                    >
                        Delete
                    </DestructiveModalButton>,
                    <CancelModalButton
                        key="b2"
                        flat
                        onClick={() => setShowDeleteModal(false)}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                setShowDeleteModal(false);
                            }
                        }}
                    >
                        Cancel
                    </CancelModalButton>,
                ]}
            />
        </CardWrapper>
    );
};

export default AudioDescriptionCard;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import get from 'lodash/get';

import ConfirmationDialog from '../common/ConfirmatonDialog';
import onUpdateVirtualEventUserMode from '../../events/onUpdateVirtualEventUserMode';
import entities from '../../constants/entities';
import onUpdatePresentationUrl from '../../events/onUpdatePresentationUrl';
import onDeletePresentationUrl from '../../events/onDeletePresentationUrl';
import { colorTheme } from '../../constants/colors';
import { useVMMutation, useVMState } from '../../containers/main';
import {
    CancelModalButton,
    ContentModalContainer,
    DestructiveModalButton,
} from '../moderator/common/styles';
import { useTheme } from '../../../../../../components/Theme/ThemeContext';
import MaterialSwitchButton from '../common/MaterialSwitchButton';
import useAccessibleElement from '../../../../../../hooks/useAccessibleElement';
import IconButton from '@mui/material/IconButton';
import usePrevious from '../../../Talk/hooks/usePrevious';
import { isBoolean } from 'lodash';

const { virtualEventUserEntity } = entities;

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: ${colorTheme.WHITE};
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.05),
        0px 2px 3px rgba(0, 0, 0, 0.1);
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    font-family: Cabin, sans-serif;
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    color: ${colorTheme.WASHED_BLACK};
`;

export const CardContent = styled.div`
    margin-bottom: ${props => (props.nomarginbottom ? 0 : 16)}px;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    color: ${colorTheme.WASHED_BLACK};
`;

export const CardSpan = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: ${colorTheme.WASHED_BLACK};
    background-color: ${colorTheme.SECONDARY};
    cursor: pointer;
    border: none;
`;

export const CardButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: ${colorTheme.WASHED_BLACK};
    background-color: ${colorTheme.SECONDARY};
    cursor: pointer;
    border: none;
`;

export const CardFooterMessage = styled.div`
    margin-bottom: ${props => (props.nomarginbottom ? 0 : 16)}px;
    margin-top: ${props => (props.nomargintop ? 0 : 16)}px;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: ${colorTheme.DARK_GREY};
`;

const SlideNameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    margin-bottom: 8px;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: ${props => (props.disabled ? 'rgba(0, 0, 0, 0.38)' : colorTheme.WASHED_BLACK)};
    background-color: ${colorTheme.SECONDARY};
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const UploadContainer = styled.div`
    input[type='file'] {
        display: none;
    }
`;

const iconStyle = {
    color: colorTheme.DARK_GREY,
    cursor: 'pointer',
};

const PosterAndSlidesCard = () => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const { theme } = useTheme();
    const { virtualEventUser, pdf, virtualEventSession, socket, sessionId, timeslot } = stateCtx;
    const { uploadedPresentationFilename } = virtualEventUser.data;
    const [acceptedFiles, setAcceptedFiles] = useState(null);
    const [showDeleteSlidesModal, setShowDeleteSlidesModal] = useState(false);
    const [presentationSettingsMode, setPresentationSettingsMode] = useState(
        virtualEventUser.data.mode,
    );
    const areSlidesVisible = get(virtualEventSession, 'data.slidesVisible') || false;
    const posterPdf = timeslot && timeslot.posterPdf && JSON.parse(timeslot.posterPdf);
    const posterName = posterPdf && posterPdf.name;
    const hostCanOverridePoster = get(timeslot, 'hostCanOverridePoster') || false;
    const allowHostToStartMeeting = isBoolean(get(timeslot, 'allowHostToStartMeeting'))
        ? get(timeslot, 'allowHostToStartMeeting')
        : true;

    const onPosterChange = useCallback(e => {
        setAcceptedFiles(e.target.files);
    }, []);

    const onDeleteRoundTableSlides = async () => {
        setPresentationSettingsMode(virtualEventUserEntity.mode.video);
        await onDeletePresentationUrl(stateCtx, mutationCtx, virtualEventUserEntity.mode.video);
        mutationCtx.setPdf(null);
        setShowDeleteSlidesModal(false);
        setAcceptedFiles(null);
    };

    const onConfirm = async () => {
        if (acceptedFiles) {
            await onUpdatePresentationUrl(
                stateCtx,
                mutationCtx,
                acceptedFiles,
                presentationSettingsMode,
            );
        } else if (presentationSettingsMode !== virtualEventUser.data.mode) {
            await onUpdateVirtualEventUserMode(stateCtx, mutationCtx, presentationSettingsMode);
        }
    };

    const handleSwitchChange = () => {
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                slidesVisible: !areSlidesVisible,
            },
        });
    };

    useEffect(() => {
        (async () => {
            await onConfirm();
        })();
    }, [acceptedFiles]);

    useEffect(() => {
        setPresentationSettingsMode(virtualEventUser.data.mode);
    }, [virtualEventUser.data.mode]);

    useEffect(() => {
        (async () => {
            if (pdf && pdf.numPages > 1) {
                setPresentationSettingsMode(virtualEventUserEntity.mode.slide);
                await onUpdateVirtualEventUserMode(
                    stateCtx,
                    mutationCtx,
                    virtualEventUserEntity.mode.slide,
                );
            }
            if (pdf && pdf.numPages === 1) {
                setPresentationSettingsMode(virtualEventUserEntity.mode.poster);
                await onUpdateVirtualEventUserMode(
                    stateCtx,
                    mutationCtx,
                    virtualEventUserEntity.mode.poster,
                );
            }
            if (!pdf) {
                setPresentationSettingsMode(entities.virtualEventUserEntity.mode.video);
                await onUpdateVirtualEventUserMode(
                    stateCtx,
                    mutationCtx,
                    virtualEventUserEntity.mode.video,
                );
            }
        })();
    }, [pdf]);

    useAccessibleElement({
        ariaControls: 'poster-upload-aria-controls',
        inputElementId: 'poster-upload-input',
    });

    return (
        <CardWrapper>
            <TitleContainer>
                <FilterNoneOutlinedIcon style={{ marginRight: 18, fontSize: 20 }} />
                Poster / slides
            </TitleContainer>
            {!hostCanOverridePoster && (
                <>
                    {!posterName && (
                        <CardContent>
                            Uploading a file is only available when enabled by the organizer.
                        </CardContent>
                    )}
                    {posterName && (
                        <>
                            <SlideNameContainer disabled>
                                {posterName}
                                <div />
                            </SlideNameContainer>
                            {(uploadedPresentationFilename || posterName) &&
                                allowHostToStartMeeting(
                                    <FlexRow>
                                        <CardContent nomarginbottom>
                                            Display also in live meeting
                                        </CardContent>
                                        <MaterialSwitchButton
                                            value={areSlidesVisible}
                                            checked={areSlidesVisible}
                                            onChange={handleSwitchChange}
                                            color={theme.primary}
                                        />
                                    </FlexRow>,
                                )}
                            <CardFooterMessage nomarginbottom nomargintop>
                                Uploading a file is only available when enabled by the organizer.
                            </CardFooterMessage>
                        </>
                    )}
                </>
            )}
            {hostCanOverridePoster && (
                <>
                    {!uploadedPresentationFilename && !posterName && (
                        <>
                            <CardContent>Add a poster or slides to your presentation.</CardContent>
                            <UploadContainer>
                                <label htmlFor="poster-upload-input">
                                    <span
                                        role="button"
                                        aria-controls="poster-upload-aria-controls"
                                        tabIndex="0"
                                        id="poster-upload-span-element"
                                    >
                                        <CardSpan data-qa="upload-pdf-button">Upload PDF</CardSpan>
                                    </span>
                                </label>
                                <input
                                    id="poster-upload-input"
                                    name="coverUrl"
                                    type="file"
                                    accept="application/pdf"
                                    onChange={onPosterChange}
                                />
                            </UploadContainer>
                        </>
                    )}
                    {(uploadedPresentationFilename || posterName) && (
                        <>
                            <SlideNameContainer>
                                {uploadedPresentationFilename || posterName}
                                {uploadedPresentationFilename && (
                                    <IconButton
                                        onClick={() => setShowDeleteSlidesModal(true)}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                setShowDeleteSlidesModal(true);
                                            }
                                        }}
                                    >
                                        <DeleteOutlineIcon style={iconStyle} />
                                    </IconButton>
                                )}
                            </SlideNameContainer>
                            {!uploadedPresentationFilename && (
                                <UploadContainer>
                                    <label htmlFor="poster-upload-input">
                                        <span
                                            role="button"
                                            aria-controls="poster-upload-aria-controls"
                                            tabIndex="0"
                                            id="poster-upload-span-element"
                                        >
                                            <CardSpan data-qa="upload-pdf-button">
                                                Upload PDF
                                            </CardSpan>
                                        </span>
                                    </label>
                                    <input
                                        id="poster-upload-input"
                                        type="file"
                                        accept="application/pdf"
                                        onChange={onPosterChange}
                                    />
                                </UploadContainer>
                            )}
                            {(uploadedPresentationFilename || posterName) &&
                                allowHostToStartMeeting && (
                                    <FlexRow>
                                        <CardContent nomarginbottom>
                                            Display also in live meeting
                                        </CardContent>
                                        <MaterialSwitchButton
                                            value={areSlidesVisible}
                                            checked={areSlidesVisible}
                                            onChange={handleSwitchChange}
                                            color={theme.primary}
                                        />
                                    </FlexRow>
                                )}
                        </>
                    )}
                </>
            )}
            <div className="eureka-react">
                <ConfirmationDialog
                    open={showDeleteSlidesModal}
                    title="Delete slides"
                    titleIcon="delete_forever"
                    iconColor="rgba(0, 0, 0, 0.87)"
                    iconWrapperColor="#EFEFEF"
                    withCloseButton
                    onClose={() => setShowDeleteSlidesModal(false)}
                    content={
                        <ContentModalContainer morePadding>
                            Are you sure you want to remove the uploaded slides?
                        </ContentModalContainer>
                    }
                    buttons={[
                        <DestructiveModalButton
                            key="DMBD"
                            flat
                            onClick={onDeleteRoundTableSlides}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    onDeleteRoundTableSlides();
                                }
                            }}
                        >
                            Delete
                        </DestructiveModalButton>,
                        <CancelModalButton
                            key="DMBC"
                            flat
                            onClick={() => setShowDeleteSlidesModal(false)}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    setShowDeleteSlidesModal(false);
                                }
                            }}
                        >
                            Cancel
                        </CancelModalButton>,
                    ]}
                />
            </div>
        </CardWrapper>
    );
};

export default PosterAndSlidesCard;

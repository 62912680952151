import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as palette from '../../../components/General/Variables';
import NavigationBar from '../../../components/DetailHeader/NavigationBar.js';
import { withRouter, generatePath } from 'react-router-dom';
import { getTimeslotsByPlace } from '../services/floorplan';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import Section from '../../DetailPage/Section';
import { DetailDrawer } from '../../../components/General/DetailDrawer';

const StyledDetailDrawer = styled(DetailDrawer)`
    overflow: hidden;
    z-index: 10;
`;

const Scrollable = styled.section.attrs({
    className: '',
})`
    height: calc(100% - 48px);
    overflow: auto;
`;

const PlaceScheduleList = ({ place, match }) => {
    const [timeslotsByDay, setTimeslotsByDay] = useState({});

    useEffect(() => {
        fetchData();
    }, [place?.id]);

    const fetchData = async () => {
        const timeslots = await getTimeslotsByPlace(match.params.place);
        setTimeslotsByDay(groupBy(timeslots, 'start'));
    };

    const sections = Object.keys(timeslotsByDay)
        .sort()
        .map(key => {
            const date = moment(key);
            return {
                type: 'timeslot',
                title: date.format('DD MMM'),
                items: timeslotsByDay[key],
            };
        });

    return (
        <StyledDetailDrawer>
            <NavigationBar
                closePath={generatePath(match.path, {
                    floorplan: match.params.floorplan,
                    place: null,
                })}
                typeName={place.name}
                background={palette.COLOR_WHITE}
            />
            <Scrollable>
                {sections.map(sec => {
                    const type = sec.type.replace(' ', '');
                    const title = sec.title.replace(' ', '');
                    const key = `item-list-place-${type}-${title}`;

                    return (
                        <Section
                            key={key}
                            type={sec.type}
                            items={sec.items}
                            clickOpenDetail={item => console.log('open', item)}
                            prevPath={match.url}
                        />
                    );
                })}
            </Scrollable>
        </StyledDetailDrawer>
    );
};

export default withRouter(PlaceScheduleList);

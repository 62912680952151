import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import Loader from '../../../components/General/Loader';
import Participants from './Participants';
import ChatRoomContainer from '../containers/ChatRoom'; // Wrapped group chat
import { getString } from '../../../services/api/store';
import { ChatWrapper } from '../../Talk/common';
import NavigationHeader from '../../Talk/components/NavigationHeader';
import Tabs from '../../../components/Tabs';

const TalkRoomComponent = ({
    conversation,
    title,
    chatType,
    checking = true,
    location,
    history,
}) => {
    const CONVERSATION_TITLE = getString('groupChatScreenTitle') || 'Conversation';
    const [isLoadingConversationData, setIsLoadingConversationData] = useState(true);
    const [attendees, setAttendees] = useState([]);

    useEffect(() => {
        const jid = get(conversation, 'jid');
        if (jid) {
            setIsLoadingConversationData(false);
            if (conversation.lastMessage) {
                const chatAttendees = conversation.participants.map(p => {
                    p.userId = p.id;
                    return p;
                });
                const sortedAttendees = sortAttendees(chatAttendees);
                setAttendees(sortedAttendees);
            } else {
                setAttendees([]);
            }
            if (!conversation.title) {
                conversation.title = title;
            }
        }
    }, [conversation]);

    const sortAttendees = attendees =>
        attendees.sort((attendee1, attendee2) => {
            const name1 = `${attendee1.lastName} ${attendee1.firstName}`;
            const name2 = `${attendee2.lastName} ${attendee2.firstName}`;

            return name1.toLowerCase().localeCompare(name2.toLowerCase());
        });

    const ChatContent = (
        <ChatRoomContainer
            conversationRoomId={conversation.roomId}
            conversationId={conversation.id}
            conversation={conversation}
            chatType={chatType}
        />
    );

    const AttendeesContent = <Participants sortedAttendees={attendees} />;

    const tabsData = [
        {
            label: 'Chat',
            labelIcon: 'chat_bubble',
            content: ChatContent,
        },
        {
            label: attendees.length,
            labelIcon: 'group',
            content: AttendeesContent,
        },
    ];

    const renderGroupChat = () => {
        return (
            <React.Fragment>
                <ChatWrapper groupChat>
                    <NavigationHeader
                        goBack={history.goBack}
                        closePath={location.pathname}
                        title={`${CONVERSATION_TITLE} - ${title || conversation.title}`}
                        isGroupChat
                    />
                    <Tabs data={tabsData} />
                </ChatWrapper>
            </React.Fragment>
        );
    };

    return isLoadingConversationData || checking ? <Loader /> : renderGroupChat();
};

export default withRouter(TalkRoomComponent);

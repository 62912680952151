class Stream {
    constructor(params) {
        if (params) {
            const { video, audio, uid, user } = params;

            this.audioTrack = audio;
            this.videoTrack = video;
            this.streamId = uid;
            this.user = user;
        }
    }

    getUser() {
        return this.user;
    }

    close() {
        this.getTracks().forEach(track => track.close());
    }

    stop() {
        this.getTracks().forEach(track => track.stop());
    }

    setAudioTrack(track) {
        this.audioTrack = track;
    }

    setVideoTrack(track) {
        this.videoTrack = track;
    }

    setEncoderConfiguration(config) {
        const videoTrack = this.getTracks().find(track => track.trackMediaType === 'video');

        if (!videoTrack) {
            return;
        }

        videoTrack.setEncoderConfiguration(config);
    }

    setLowVideoEncoding() {
        this.setEncoderConfiguration({
            bitrateMin: 25,
            bitrateMax: 50,
            height: 90,
            width: 160,
        });
    }

    setHighVideoEncoding() {
        this.setEncoderConfiguration({
            bitrateMin: 200,
            bitrateMax: 900,
            height: 360,
            width: 640,
        });
    }

    isAudioOn() {
        const audioTrack = this.getTracks().find(track => track.trackMediaType === 'audio');

        if (audioTrack) {
            return audioTrack._enabled;
        }

        return false;
    }

    isVideoOn() {
        const videoTrack = this.getTracks().find(track => track.trackMediaType === 'video');

        if (videoTrack) {
            return videoTrack._enabled;
        }

        return false;
    }

    unmuteAudio() {
        const audioTrack = this.getTracks().find(track => track.trackMediaType === 'audio');

        if (audioTrack) {
            return audioTrack.setEnabled(true);
        }

        return Promise.resolve();
    }

    unmuteVideo() {
        const videoTrack = this.getTracks().find(track => track.trackMediaType === 'video');

        if (videoTrack) {
            return videoTrack.setEnabled(true);
        }

        return Promise.resolve();
    }

    isPlaying() {
        let isPlaying = false;

        this.getTracks().forEach(track => {
            if (track.isPlaying) {
                isPlaying = true;
            }
        });

        return isPlaying;
    }

    play({ domId, muted }) {
        this.getTracks().forEach(track => {
            const shouldMute = muted && track.trackMediaType === 'audio' && track.isPlaying;

            if (!muted || track.trackMediaType !== 'audio') {
                track.play(domId);
            }

            if (shouldMute) {
                track.stop();
            }
        });
    }

    on(evt, callback) {
        this.getTracks().forEach(track => {
            track.on(evt, callback);
        });
    }

    muteAudio() {
        const audioTrack = this.getNotClosedTracks().find(
            track => track.trackMediaType === 'audio',
        );

        if (audioTrack) {
            audioTrack.setEnabled(false);
        }
    }

    muteVideo() {
        const videoTrack = this.getNotClosedTracks().find(
            track => track.trackMediaType === 'video',
        );

        if (videoTrack) {
            videoTrack.setEnabled(false);
        }
    }

    getAudioLevel() {
        const audioTrack = this.getTracks().find(track => track.trackMediaType === 'audio');

        if (audioTrack) {
            return audioTrack.getVolumeLevel();
        }

        return 0;
    }

    getId() {
        return this.streamId;
    }

    getTracks() {
        const tracksArray = [];

        if (this.user) {
            if (this.user.videoTrack) {
                tracksArray.push(this.user.videoTrack);
            }

            if (this.user.audioTrack) {
                tracksArray.push(this.user.audioTrack);
            }

            return tracksArray;
        }

        if (this.videoTrack) {
            tracksArray.push(this.videoTrack);
        }

        if (this.audioTrack) {
            tracksArray.push(this.audioTrack);
        }

        return tracksArray;
    }

    getEnabledTracks() {
        return this.getTracks().filter(track => track._enabled);
    }

    getNotClosedTracks() {
        return this.getTracks().filter(track => !track._isClosed);
    }
}

export const createStreamFromUser = user => {
    if (!user) {
        return null;
    }

    return new Stream({
        user,
        audio: user.audioTrack,
        video: user.videoTrack,
        uid: user.uid,
    });
};

export const cloneStream = stream => {
    if (!stream) {
        return stream;
    }

    return new Stream({
        audio: stream.audioTrack,
        video: stream.videoTrack,
        uid: stream.streamId,
    });
};

export default Stream;

import get from 'lodash/get';
import { removePreRecordings } from '../services/VirtualEventSession';

const onDeletePrerecordingUrlByVirtualEventUserId = async (id, stateCtx) => {
    const { sessionId, virtualEventSession, socket } = stateCtx;

    const virtualEventUsers = get(virtualEventSession, 'data.VirtualEventUsers', []);
    const virtualEventUser = virtualEventUsers.find(user => user.id === id);

    if (!virtualEventUser) {
        return;
    }

    await removePreRecordings(sessionId, virtualEventUser.UserId);
    socket.emit('updateData', {
        objectId: sessionId,
        virtualEventSession: {
            VirtualEventUsers: [
                {
                    id: virtualEventUser.id,
                    recordingInfo: null,
                    hasVideoActive: false,
                    preRecordedPresentationUrl: null,
                    preRecordedPresentationOrder: 0,
                },
            ],
        },
    });
};

export default onDeletePrerecordingUrlByVirtualEventUserId;

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import MediaPlayer from '../../components/MediaPlayer';
import styled from 'styled-components';
import { makeItem } from '../../services/api/data';
import Header from '../VirtualSession/components/common/Header';
import Auth from '../../services/api/auth';
import get from 'lodash/get';
import AnalyticsService from '../../features/analytics/services';
import { getFullProfile, getVirtualEventSession } from '../../services/api/eureka';
import { checkIfUserHasAuthorizationByTypeRestrictions } from '../AccessRestrictions/utils';
import AccessDeniedModal from '../AccessRestrictions/AccessDeniedModal';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;

    & > * {
        padding: 16px;
    }
`;

const Container = styled.div`
    height: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
    margin-top: 80px;
    ${props =>
        props.margin
            ? `
        margin-right: 24px;
        width: 60%;
    `
            : 'width: 40%'};
`;

const LeftComponent = styled.div`
    height: ${props => props.height}px;
`;

const RightComponent = styled.div`
    height: ${props => props.height}px;
`;

const FLAG_ADDITION_DATE = '04/8/2021';

const VideoPage = ({ match, location, history }) => {
    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState(null);
    const [muted, setMuted] = useState(true);
    const [leftHeight, setLeftHeight] = useState(800);
    const [rightHeight, setRightHeight] = useState(400);
    const [restrictedUser, setRestrictedUser] = useState(false);
    const isOnDemand = get(item, 'params.virtualSession') === 'on-demand';

    const user = Auth.getUser();

    useEffect(() => {
        if (item) {
            (async () => {
                const virtualEventSession = await getVirtualEventSession(item.id);
                const playBothVideosNormally = !!get(
                    virtualEventSession,
                    'recordingInfo.playBothVideosNormally',
                );
                const startDate = moment(item.start);
                const isAfterFlagAddition = startDate.isSameOrAfter(moment(FLAG_ADDITION_DATE));
                const shouldUnMute = playBothVideosNormally || isAfterFlagAddition;

                if (shouldUnMute) {
                    setMuted(false);
                }
            })();
        }
    }, [item]);

    useEffect(() => {
        const { objectClass, objectId } = match.params;

        if (!objectClass || !objectId) {
            return;
        }

        setLoading(true);
        makeItem(objectId, objectClass, async (err, item) => {
            if (err) {
                console.log('err', err);
            } else {
                setItem(item);

                if (Auth.isUserAuthenticated()) {
                    const fullProfile = await getFullProfile();
                    const canUserAccessSession = checkIfUserHasAuthorizationByTypeRestrictions(
                        item?.typeParams?.virtualRoomRestrictedGroup,
                        fullProfile.userGroups,
                    );

                    setRestrictedUser(!canUserAccessSession);
                }
            }

            setLoading(false);
        });
    }, [match.params.objectClass, match.params.objectId]);

    // Executed after every render
    useEffect(() => {
        const leftElem = document.getElementById('video-page-left-content');
        const rightElem = document.getElementById('video-page-right-content');

        if (leftElem) {
            setLeftHeight(leftElem.offsetWidth / (16 / 9));
        }

        if (rightElem) {
            setRightHeight(rightElem.offsetWidth / (16 / 9));
        }
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (restrictedUser) {
        return <AccessDeniedModal onClose={() => history.goBack()} />;
    }

    if (item && item.params && !item.params.onDemandLink && !item.params.onDemandLinkScreen) {
        console.log('The item does not have on demand video links');
        return null;
    }

    return (
        <Wrapper>
            <Header goBack={history.goBack} timeslot={item} authUser={user} />
            <Container margin={true}>
                <LeftComponent id="video-page-left-content" height={leftHeight}>
                    <MediaPlayer
                        url={item.params.onDemandLinkScreen}
                        primary={true}
                        muted={muted}
                        viewRecordingSessionId={isOnDemand && item && item.id}
                    />
                </LeftComponent>
            </Container>
            <Container>
                <RightComponent id="video-page-right-content" height={rightHeight}>
                    <MediaPlayer url={item.params.onDemandLink} />
                </RightComponent>
            </Container>
        </Wrapper>
    );
};

export default withRouter(VideoPage);

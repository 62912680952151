import React from 'react';
import styled from 'styled-components';
import FontIcon from 'react-md/lib/FontIcons';
import { getString } from '../../services/api/store';
import Auth from '../../services/api/auth';

const Wrapper = styled.div`
    width: 100%;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const IconWrapper = styled.div`
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
`;

const Icon = styled(FontIcon)`
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 24px !important;
`;

const Text = styled.p`
    max-width: 200px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
`;

export const tabTypes = {
    INFO: 'info',
    SCHEDULE: 'schedule',
    INTERACTIVE: 'interactive',
    RESOURCES: 'resources',
    CONTACT: 'contact',
};

const TypeAccessRestrictionsForTabs = ({
    children,
    currentUserGroups = [],
    typeParams,
    tabType,
}) => {
    const isAuthenticated = Auth.isUserAuthenticated();
    const getTypeParamsPropertyByTabType = type => {
        switch (type) {
            case tabTypes.INFO:
                return 'tabInfoRestrictedGroup';
            case tabTypes.SCHEDULE:
                return 'tabScheduleRestrictedGroup';
            case tabTypes.INTERACTIVE:
                return 'tabInteractiveRestrictedGroup';
            case tabTypes.RESOURCES:
                return 'tabResourcesRestrictedGroup';
            case tabTypes.CONTACT:
                return 'tabContactRestrictedGroup';
            default:
                return null;
        }
    };

    const checkIfUserHasAuthorization = restrictionGroup => {
        if (!restrictionGroup) {
            return true;
        }

        if (restrictionGroup === 'eurekaOnly' && isAuthenticated) {
            return true;
        }

        return currentUserGroups.some(group => group.id === restrictionGroup);
    };
    const typeParamsProperty = getTypeParamsPropertyByTabType(tabType);
    const hasAuthorization = checkIfUserHasAuthorization(typeParams[typeParamsProperty]);
    const TEXT = getString(
        'noPermissionTabContent',
        'You do not have permission to access this content.',
    );

    if (!hasAuthorization) {
        return (
            <Wrapper>
                <IconWrapper>
                    <Icon>lock</Icon>
                </IconWrapper>
                <Text>{TEXT}</Text>
            </Wrapper>
        );
    }

    return children;
};

export default TypeAccessRestrictionsForTabs;

import React from 'react';
import { Button } from 'react-md';
import styled from 'styled-components';

import * as palette from '../../../components/General/Variables';
import ThemeContext from '../../../components/Theme/ThemeContext';

const ButtonStyled = styled(Button)`
    border: 1px solid ${props => props.primarycolor} !important;
    margin-left: 16px !important;
    margin-top: 27px !important;
    margin-bottom: 27px !important;
    border-radius: ${props => (props.theming === 'inversed' ? '2px' : '4px')} !important;
    color: ${props =>
        props.theming === 'inversed' ? props.primarycolor : palette.COLOR_WHITE} !important;
    background-color: ${props =>
        props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor} !important;
    float: ${props => props.position};
    display: block;

    &.md-btn--dialog,
    &.md-btn--text {
        text-transform: none;
        font-size: 16px;
        height: 36px;
        font-weight: 500;
        padding: 8px 16px;
    }

    &:hover {
        background-color: ${props =>
            props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor};
    }
`;

const GroupButton = ({ id, label, onClick }) => {
    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <ButtonStyled
                    key={id}
                    raised
                    primary
                    primarycolor={theme.primary}
                    onClick={onClick}
                >
                    {label}
                </ButtonStyled>
            )}
        </ThemeContext.Consumer>
    );
};
export default GroupButton;

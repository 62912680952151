import React, { useContext, useEffect, useState } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import moment from 'moment';
import Cookies from 'js-cookie';
import { matchPath, Route, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { EUREKA_TALK_HOST } from '../../../config';
import get from 'lodash/get';
import '../../../App.css';
import Loader from '../../../components/General/Loader';
import * as palette from '../../../components/General/Variables.js';
import withInteractivity from '../../../components/Interactivity/withInteractivity';
import Routes from '../../../components/Routes/Routes';
import withSearchResults from '../../../components/SearchResults/withSearchResults';
import ThemeContext from '../../../components/Theme/ThemeContext';
import PasswordProtect from '../../../scenes/AccessRestrictions/PasswordProtect';
import { getConfigurations, getLocalAppState, updateApp } from '../../../services/api';
import { getImageSourceAsync, getLocalItem, initSettings } from '../../../services/api/db';
import { default as Store } from '../../../services/api/store';
import { ContainerProvider } from '../../../utils/container';
import { TimezoneContext, TimezoneContextProvider } from '../../../scenes/Timezone/context';
import Auth from '../../../services/api/auth';
import OneSignalWrapper from '../../../scenes/OneSignal/containers/OneSignalWrapper';
import TimezoneHandlers from '../../../scenes/Timezone/containers';
import { XMPPManager } from '../../../scenes/Talk/components/XMPPManager';
import RemindersWrapper from '../../../scenes/Reminders/RemindersWrapper';
import NotificationService from '../../../scenes/Notifications/services/NotificationService';
import { EventProtection } from '../../../components/ProtectedEvents';
import VirtualRoomNotifications from '../../Notifications/containers/VirtualRoomNotifications';
import SignIn from '../../../scenes/SignInPage/components';
import TopNavigation from '../../../components/Navigation/TopNavigation';
import SideNavigation from '../../../components/Navigation/SideNavigation';
import * as AppStyle from '../../../components/Navigation/style/navigationStyle';
import { mapMobileNavigationMenu } from '../../../components/Navigation/utils/utils';
import {
    EVENT_NOT_CONFIGURED_MESSAGE,
    EVENT_NOT_FOUND_MESSAGE,
    USER_NOT_ATTENDING_PERMISSIONS,
} from '../../../App';
import NoAttendingPermissionModal from '../../../components/ProtectedEvents/NoAttendingPermissionModal';
import useInvites from '../../Society/hooks/useInvites';
import { executeQuery } from '../../../services/api/graphQlRepository';
import { getFullProfile } from '../../../services/api/eureka';
import AnalyticsService from '../../../features/analytics/services';

const Hidden = styled.p`
    display: none;
    height: 0px;
`;

const NAVIGATION_TYPES = {
    SIDE_MENU: 'sideMenu',
    TOP_NAVIGATION: 'iconMenu',
};

const Event = () => {
    const [eventState, setEventState] = useState({
        appReady: false,
        navigation: [],
        available: false,
        eventNotFound: false,
        eventNotConfigured: false,
        userNotAttending: false,
        password: null,
        id: null, // AKA eventId
        installationId: null,
        eurekaOnly: false,
        attendingOnly: false,
        timezoneSelection: 'choice',
        hiddenPassword: null,
        navigationType: NAVIGATION_TYPES.SIDE_MENU,
        navigationItemsMobile: null,
        appState: {},
    });

    const [title, setTitle] = useState('');

    const [showInstallMobileAppModal, setShowInstallMobileAppModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isSidepanelOpened, setIsSidepanelOpened] = useState(false);
    const [showKioskPasswordModal, setShowKioskPasswordModal] = useState(false);
    const [pages, setPages] = useState([]);

    const { setTheme } = useContext(ThemeContext);
    const { initTimezoneContext } = useContext(TimezoneContext);

    const location = useLocation();
    const { eventName } = useParams();
    let { url } = useRouteMatch();
    const isLandingPage = location.pathname.toLowerCase().includes('/landing');
    const [subdomain] = window.location.hostname.split('.');

    const matcherForKiosk = matchPath(location.pathname, {
        path: '/kiosk/:eventName',
        strict: false,
    });

    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');

    const kiosk = matcherForKiosk;
    const embed = !!(mode && mode === 'embed');

    const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;
    const isMobile = palette.MIN_TABLET_INT && window.innerWidth < palette.MIN_TABLET_INT;
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
    );
    const [visibleSideMenu, setVisibleSideMenu] = useState(!isLandingPage && isDesktop);

    const handleSidepanel = isTrue => setIsSidepanelOpened(isTrue);
    const onSideMenuVisibilityChange = () => setVisibleSideMenu(!visibleSideMenu);

    useInvites(false);

    useEffect(() => {
        const update = async () => {
            setLoading(true);
            const societyDomain = window.location.hostname.split('.')[0];
            const appStateUniqueDomain = `${eventName}-${societyDomain}`.replace('-cui', '');
            updateApp(appStateUniqueDomain, err => {
                if (err) {
                    console.log(err);

                    switch (err.message || err) {
                        case EVENT_NOT_FOUND_MESSAGE:
                            setEventState({
                                ...eventState,
                                eventNotFound: true,
                                appReady: true,
                            });
                            setLoading(false);
                            return;
                        case EVENT_NOT_CONFIGURED_MESSAGE:
                            setEventState({
                                ...eventState,
                                eventNotConfigured: true,
                                appReady: true,
                            });
                            setLoading(false);
                            return;
                        case USER_NOT_ATTENDING_PERMISSIONS:
                            setEventState({
                                ...eventState,
                                userNotAttending: true,
                                appReady: true,
                            });
                            setLoading(false);
                            return;
                        default:
                            setEventState({
                                appReady: true,
                            });
                            return;
                    }
                }

                getLocalAppState(async (err, appState) => {
                    if (err) {
                        console.log(err);
                    } else {
                        await initTimezoneContext(appState.id);
                        initSettings(appState.id);
                        const token = queryParams.get('token');
                        if (token) {
                            try {
                                Cookies.set('userOnboardingInfo', token);
                            } catch (e) {
                                //console.log('invalid token');
                            }
                        }

                        const pageHeader =
                            appState.pageHeader && (await getImageSourceAsync(appState.pageHeader));

                        const data = await getConfigurations(appState.eventId);

                        const { style, settings, strings } = data;

                        const locale = get(strings, 'datetime.default.locale', 'en-US');
                        moment.locale(locale);

                        let theme = {
                            primary: '#673ab7',
                            secondary: '#7E57C2',
                            contrast: '#f6a738',
                            textSponsor: '#f6a738',
                        };

                        if (style) {
                            theme = {
                                primary: style.colors.primary,
                                secondary: style.colors.secondary,
                                contrast: style.colors.contrast,
                                textSponsor: style.colors.textSponsor,
                            };
                        }

                        if (settings) {
                            Store.argument = settings;
                        }

                        if (strings) {
                            Store.string = strings;
                        }

                        setTheme({
                            ...theme,
                            embed: embed,
                            kiosk: kiosk,
                        });

                        //check if there is kiosk mode password
                        const { eventId, isKioskPasswordProtected } = appState;
                        getLocalItem(
                            'appState',
                            `${eventId}_kiosk_password_entered`,
                            (err, item) => {
                                if ((err || !item) && kiosk && isKioskPasswordProtected) {
                                    return setShowKioskPasswordModal(true);
                                }
                            },
                        );

                        const isMobile =
                            palette.MIN_TABLET_INT && window.innerWidth < palette.MIN_TABLET_INT;

                        document.title =
                            appState.eventTitle && appState.eventTitle !== ''
                                ? appState.eventTitle
                                : 'Web App';

                        //const pathnamePrefix = props.location.pathname.split('/')[1];
                        // const currentNavigationItem =
                        //     appState.navigation.find(
                        //         navItem => navItem.to === `/${pathnamePrefix}`,
                        //     ) ||
                        //     appState.navigation[0] ||
                        //     {};

                        setShowInstallMobileAppModal(
                            isMobileDevice && isMobile && appState.branchIoDomain && !kiosk,
                        );

                        let navigation;
                        let navigationType;
                        if (kiosk && appState.kioskAvailable) {
                            let navigationItems = appState.kioskItems;
                            const pages = await executeQuery('getPagesWithEventAndName', {
                                event: appState.eventId,
                            });
                            setPages(pages);
                            if (typeof appState.navigationIcons === 'string') {
                                navigationItems = JSON.parse(appState.kioskItems);
                            }
                            navigation = navigationItems || [];
                            navigationType =
                                appState.kioskNavigationType || NAVIGATION_TYPES.SIDE_MENU;
                        } else {
                            navigationType = appState.navigationType || NAVIGATION_TYPES.SIDE_MENU;
                            navigation = appState.navigation || [];
                        }

                        let navigationItems = appState.navigationIcons;
                        if (typeof appState.navigationIcons === 'string') {
                            navigationItems = JSON.parse(appState.navigationIcons);
                        }

                        const navigationItemsMobile = await mapMobileNavigationMenu(
                            navigationItems,
                            appState.id,
                            kiosk,
                        );

                        setEventState({
                            appState: { ...appState, pageHeader },
                            navigationItemsMobile,
                            navigationType: navigationType,
                            theme,
                            eventName: appState.eventName,
                            navigationImage: appState.navigationImage,
                            title: '',
                            loading: false,
                            navigation: navigation,
                            available: appState.available,
                            password: !!appState.kioskPassword,
                            id: appState.id,
                            installationId: appState.installationId,
                            eurekaOnly: appState.eurekaOnly,
                            groupCanSignOnly: appState.groupCanSignOnly,
                            groupCanAccessOnly: appState.groupCanAccessOnly,
                            attendingOnly: appState.attendingOnly,
                            timezoneSelection: appState.timezoneSelection,
                            hiddenPassword: appState.hiddenPassword,
                            oneSignalAppId: appState.oneSignalAppId,
                            oneSignalSafariWebId: appState.oneSignalSafariWebId,
                            appReady: true,
                            branchIoDomain: appState.branchIoDomain,
                        });
                        setLoading(false);
                    }
                });
            });
        };
        update();
        NotificationService.requestPermission();
    }, []);

    useEffect(() => {
        if (isLandingPage && visibleSideMenu) {
            setVisibleSideMenu(false);
        }
    }, [location.pathname]);

    const {
        eurekaOnly,
        attendingOnly,
        hiddenPassword,
        password,
        navigation,
        eventNotFound,
        eventNotConfigured,
        available,
        theme,
        appReady,
        id,
        oneSignalAppId,
        oneSignalSafariWebId,
        installationId,
        navigationItemsMobile,
        navigationType,
        appState,
        userNotAttending,
        groupCanSignOnly,
        groupCanAccessOnly,
    } = eventState;
    const authUser = Auth.getUser();

    useEffect(() => {
        (async () => {
            if (!authUser || !appState?.societyId) {
                return;
            }

            const userData = await getFullProfile();
            const country =
                userData && userData.Country && userData.Country.name ? userData.Country.name : '';

            const details = {
                name: `${userData.firstName} ${userData.lastName}`,
                country,
                email: userData.email,
            };

            AnalyticsService.addSample('user', JSON.stringify(details), authUser.id);
        })();
    }, [authUser, appState?.societyId]);

    if (showKioskPasswordModal) {
        return (
            <PasswordProtect
                password={password}
                eventName={eventState.eventName}
                eventId={eventState.id}
                hideShowModal={() => {
                    setShowKioskPasswordModal(false);
                }}
            />
        );
    }

    const childProps = {};

    const hideNavigation =
        location.pathname.indexOf('/virtual-session') > -1 ||
        location.pathname.indexOf('/virtual-session-room') > -1 ||
        location.pathname.indexOf('/on-demand-poster-room') > -1 ||
        location.pathname.indexOf('/virtual-moderation') > -1 ||
        location.pathname.indexOf('/virtual-beamer-view') > -1 ||
        location.pathname.indexOf('/qa-moderation') > -1 ||
        location.pathname.indexOf('/qa-presentation') > -1 ||
        location.pathname.indexOf('/vote-session') > -1 ||
        location.pathname.indexOf('/video') > -1 ||
        location.pathname.toLowerCase().indexOf('/exhibitorbooth') > -1;

    // TODO show or hide navigation without changing the base container;
    const Container = embed || hideNavigation ? AppStyle.Base : AppStyle.StyledNavigationDrawer;

    const connectionErrors = eventNotFound || loading || eventNotConfigured;

    const eventIsAvailable = !connectionErrors && available;

    const renderContent = () => (
        <>
            <Hidden>{process.env.REACT_APP_VERSION}</Hidden>
            <Routes
                childProps={childProps}
                setTitle={setTitle}
                location={location}
                installationId={installationId}
                handleSidepanel={handleSidepanel}
                isSidepanelOpened={isSidepanelOpened}
                eventId={eventState.id}
                navigationType={navigationType}
                visibleFullSideMenu={visibleSideMenu}
                nav={
                    navigationType === NAVIGATION_TYPES.TOP_NAVIGATION
                        ? navigationItemsMobile
                        : navigation
                }
            />
            <TimezoneHandlers timezoneSelection={eventState.timezoneSelection} />
        </>
    );

    if (appReady) {
        return (
            <StyledEngineProvider injectFirst>
                {EUREKA_TALK_HOST && (
                    <XMPPManager
                        isAuthenticated={Auth.isUserAuthenticated()}
                        eventId={id}
                        chatUrl={EUREKA_TALK_HOST}
                    />
                )}
                <ContainerProvider eventId={id}>
                    <OneSignalWrapper
                        appId={oneSignalAppId}
                        safariWebId={oneSignalSafariWebId}
                        eventId={id}
                        subdomain={subdomain}
                    >
                        <RemindersWrapper>
                            <VirtualRoomNotifications theme={theme}>
                                {eventNotFound && (
                                    <div className="mdl-layout mdl-js-layout">
                                        <AppStyle.LoaderContainer>
                                            <AppStyle.EventNotFoundImage />
                                            <h4>Event not found</h4>
                                            <p>Make sure you accessed the correct URL.</p>
                                        </AppStyle.LoaderContainer>
                                    </div>
                                )}

                                {userNotAttending && <NoAttendingPermissionModal />}

                                {eventNotConfigured && (
                                    <div className="mdl-layout mdl-js-layout">
                                        <AppStyle.LoaderContainer>
                                            <AppStyle.EventNotFoundImage />
                                            <h4>Event not set up</h4>
                                            <p>
                                                This event is not yet configured. Please check back
                                                later.
                                            </p>
                                        </AppStyle.LoaderContainer>
                                    </div>
                                )}

                                {loading && (
                                    <AppStyle.LoaderContainer>
                                        <Loader />
                                    </AppStyle.LoaderContainer>
                                )}

                                {!connectionErrors && !eventIsAvailable && (
                                    <AppStyle.EmptyState>
                                        <h4>Page not available…</h4>
                                        <p>Unfortunately, it’s quite empty here.</p>
                                        <AppStyle.PlaceholderImage />
                                    </AppStyle.EmptyState>
                                )}
                                <Route exact path={`${url}/sign-in`} render={() => <SignIn />} />
                                {eventIsAvailable && (
                                    <EventProtection
                                        eurekaOnly={eurekaOnly}
                                        attendingOnly={attendingOnly}
                                        hiddenPassword={hiddenPassword}
                                        groupCanSignOnly={groupCanSignOnly}
                                        groupCanAccessOnly={groupCanAccessOnly}
                                        eventId={id}
                                    >
                                        {navigationType === NAVIGATION_TYPES.TOP_NAVIGATION && (
                                            <TopNavigation
                                                title={title}
                                                navigationItems={navigationItemsMobile}
                                                isSidepanelOpened={isSidepanelOpened}
                                                eventId={id}
                                                appState={eventState.appState}
                                                setTitle={setTitle}
                                                pages={pages}
                                            >
                                                {renderContent()}
                                            </TopNavigation>
                                        )}

                                        {navigationType === NAVIGATION_TYPES.SIDE_MENU && (
                                            <SideNavigation
                                                title={title}
                                                isMobile={isMobile}
                                                visibleSideMenu={visibleSideMenu}
                                                isSidepanelOpened={isSidepanelOpened}
                                                navigationItemsMobile={navigationItemsMobile}
                                                kiosk={kiosk}
                                                navigationType={navigationType}
                                                navigation={navigation}
                                                onSideMenuVisibilityChange={
                                                    onSideMenuVisibilityChange
                                                }
                                                renderContent={renderContent}
                                                Container={Container}
                                                setTitle={setTitle}
                                                appState={eventState.appState}
                                                pages={pages}
                                            />
                                        )}
                                    </EventProtection>
                                )}
                            </VirtualRoomNotifications>
                        </RemindersWrapper>
                    </OneSignalWrapper>
                </ContainerProvider>
            </StyledEngineProvider>
        );
    }

    if (!appReady) {
        return (
            <AppStyle.LoaderContainer>
                <Loader />
            </AppStyle.LoaderContainer>
        );
    }

    return null;
};

const EventWithTimezone = props => {
    return (
        <TimezoneContextProvider>
            <Event {...props}></Event>
        </TimezoneContextProvider>
    );
};

// Passes searchResults context in
export default withSearchResults(withInteractivity(EventWithTimezone));

import React from 'react';
import queryString from 'query-string';
import {
    Link,
    useLocation,
    useRouteMatch,
    useHistory,
    generatePath,
    matchPath,
} from 'react-router-dom';
import styled from 'styled-components';

const LinkStyled = styled(Link)`
    text-decoration: none !important;
`;

export const makeNavigationData = (
    match,
    history,
    location,
    path,
    subPath,
    params,
    pathReplaceParams,
    state,
    absolutePath,
) => {
    let search;
    let pathname;
    let searchData = queryString.parse(location.search);
    if (params) {
        // Remove null or undefined values
        const cleanParams = Object.entries(params).reduce(
            (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
            {},
        );

        if (searchData.tab) {
            cleanParams.tab = searchData.tab;
        }
        if (searchData.mode) {
            cleanParams.mode = searchData.mode;
        }

        const stringified = queryString.stringify(cleanParams);
        search = `?${stringified}`;
    }

    if (path) {
        const matcher = matchPath(history.location.pathname, {
            path: '/event/:eventName',
            strict: false,
        });
        const kioskMatcher = matchPath(history.location.pathname, {
            path: '/kiosk/:eventName',
            strict: false,
        });

        if (matcher || kioskMatcher) {
            //Is inside an event
            const matchUrl = matcher?.url || kioskMatcher?.url;
            pathname = `${matchUrl}${path === '/' ? '' : path}`;
        } else {
            pathname = `${path === '/' ? '' : path}`;
        }
    } else if (subPath) {
        pathname = `${location.pathname}${subPath}`;
    } else if (absolutePath) {
        pathname = absolutePath;
    } else {
        pathname = location.pathname;
    }

    if (pathReplaceParams) {
        pathname = generatePath(match.path, pathReplaceParams);
    }

    const prevPath = `${location.pathname}${location.search ? location.search : ''}`;

    let navState = { prevPath };
    if (state) {
        navState = { ...navState, ...state };
    }

    return { pathname, search, state: navState };
};

const NavigationLink = props => {
    const location = useLocation();
    const match = useRouteMatch();
    const history = useHistory();
    const newKey = `${
        props.key ? props.key + '-navigation-link' : '-navigation-link-' + location.pathname
    }`;
    return (
        <LinkStyled
            key={newKey}
            to={makeNavigationData(
                match,
                history,
                location,
                props.path,
                props.subPath,
                props.params,
                props.pathReplaceParams,
                props.state,
                props.absolutePath,
            )}
            style={{ ...props.style, ...props.styles }}
            className={props.className}
        >
            {props.children}
        </LinkStyled>
    );
};

const navigateTo = props => {
    const data = makeNavigationData(
        props.match,
        props.history,
        props.location,
        props.path,
        props.subPath,
        props.params,
        props.pathReplaceParams,
        props.state,
        props.absolutePath,
    );

    if (props.replace) {
        return props.history.replace(data);
    }

    props.history.push(data);
};

export default NavigationLink;

export { navigateTo };

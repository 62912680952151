import styled from 'styled-components';
import { DropdownMenu, ListItem } from 'react-md';

export const RoundedDropdownMenu = styled(DropdownMenu)`
    ${props => !!props.margin && `margin: ${props.margin};`}

    .md-list {
        border-radius: 8px !important;
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2), 0 0 4px rgba(0, 0, 0, 0.1);
        margin-top: 12px;
        padding: 8px;
        width: fit-content !important;

        li {
            div {
                border-radius: 8px;

                &:hover {
                    background-color: #ebedf0;
                }
            }
        }
    }
`;

export const StyledDropdownItem = styled(ListItem)`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
`;

export const ViewButton = styled.button`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    border: 1px solid #dde1e5;
    height: 40px;
    border-radius: 50px;
    padding: 0 16px;
    margin-right: 8px;
    background: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    outline: none;

    i {
        color: #1e1e1f !important;
        margin-left: 8px;
    }

    &:visited,
    &:focus {
        background: #ebedf0;
    }
`;

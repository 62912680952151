import React from 'react';

import Group from './Group';
import Institution from './Institution';
import Private from './Private';

const ConversationListItem = ({ conversation }) => {
    let chatType = conversation.kind === 'chat' ? 'private' : 'group';
    if (chatType !== 'private') {
        if (conversation.externalObjectClass && conversation.externalObjectClass !== 'timeslots') {
            chatType = 'institution';
        }
    }

    const renderItem = () => {
        switch (chatType) {
            case 'group':
                return <Group conversation={conversation} />;
            case 'institution':
                return <Institution conversation={conversation} />;
            case 'private':
                return <Private conversation={conversation} />;
            default:
                return <Private conversation={conversation} />;
        }
    };

    return renderItem();
};

export default ConversationListItem;

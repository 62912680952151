import { combineReducers } from 'redux';

// Import and combine all feature reducers
import talk from '../scenes/Talk/reducers';
import socialFeed from '../scenes/SocialFeed/reducers';
import auth from '../scenes/User/reducers';
import notifications from '../scenes/Notifications/reducers';
import exhibitorBooth from '../scenes/ExhibitorPage/reducers';
import gamification from '../scenes/Achievements/reducers';
import society from '../scenes/Society/reducers';

export default combineReducers({
    talk,
    socialFeed,
    auth,
    notifications,
    exhibitorBooth,
    gamification,
    society,
});

import Hls from 'hls.js';
const urlMap = {};

export default url => {
    return new Promise(resolve => {
        if (!url) {
            return resolve();
        }

        if (urlMap[url]) {
            if (urlMap[url].done) {
                return resolve(urlMap[url].value);
            }

            return resolve('');
        }

        urlMap[url] = {
            done: false,
            value: '',
        };

        const player = document.createElement('video');

        player.muted = true;
        // TODO FIX ME WITH REACT Hls library?
        const hls = new Hls({});

        hls.on(Hls.Events.ERROR, function (e, data) {
            console.error(e, data);
        });

        hls.loadSource(url);
        hls.attachMedia(player);

        const canPlayHandler = () => {
            const canvas = document.createElement('canvas');

            player.currentTime = 5;

            player.removeEventListener('canplay', canPlayHandler);

            setTimeout(() => {
                // scale the canvas accordingly
                canvas.width = player.videoWidth;
                canvas.height = player.videoHeight;
                // draw the video at that frame
                canvas.getContext('2d').drawImage(player, 0, 0, canvas.width, canvas.height);

                const dataUrl = canvas.toDataURL();

                hls.destroy();

                urlMap[url].value = dataUrl;
                urlMap[url].done = true;

                resolve(dataUrl);
            }, 2000);
        };

        player.addEventListener('canplay', canPlayHandler);
    });
};

import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
    outline: none;
    width: 100%;
    height: 40px;
    padding: 8px;
    border-radius: 6px;
    border: none;
    font-size: 14px;
    background-color: #efefef;
    margin-top: 8px;
`;

const TextInput = ({ value, onChange, disabled }) => {
    return <Input value={value} onChange={onChange} disabled={disabled} type="text" />;
};

export default TextInput;

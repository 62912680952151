import { useTranslationsStore } from '../../../../stores/TranslationsStore';
import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LanguageSelect from '../../../VirtualModeration/features/virtualFeature/components/moderator/userInteraction/translations/LanguageSelect';
import AttendeeCaptionMessage from './AttendeeCaptionsMessage';
import noMessagesImage from '../../../../assets/images/no-questions.png';
import styled from 'styled-components';
import { useGlobalState } from '../../../../utils/container';

const PlaceholderImage = styled.img`
    width: 250px;
    height: 200px;
`;

const Text = styled.span`
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
`;

const TranslatedCaptions = ({ sessionId }) => {
    const { messages, setSelectedLanguage } = useTranslationsStore();
    const { socket, virtualEventSession } = useGlobalState();
    const containerRef = useRef(null);
    const [isUserAtBottom, setIsUserAtBottom] = useState(true);

    useEffect(() => {
        if (containerRef && containerRef.current && isUserAtBottom) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [messages, isUserAtBottom]);

    const handleScroll = React.useCallback(() => {
        const { scrollTop, clientHeight, scrollHeight } = containerRef.current;

        const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
        setIsUserAtBottom(distanceFromBottom < 100);
    }, []);

    const messageToDisplay = Array.from(messages.values());
    const messagesExist = messageToDisplay.length > 0;

    const onLanguageChange = React.useCallback(
        value => {
            const attendeeCaptionLanguages =
                (virtualEventSession && virtualEventSession.attendeeCaptionLanguages) || [];
            const attendeeCaptionLanguagesSet = new Set(attendeeCaptionLanguages);
            if (!attendeeCaptionLanguagesSet.has(value)) {
                attendeeCaptionLanguagesSet.add(value);
            }

            const newAttendeeCaptionLanguages = Array.from(attendeeCaptionLanguagesSet);
            socket.emit('updateData', {
                objectId: sessionId,
                virtualEventSession: {
                    attendeeCaptionLanguages: newAttendeeCaptionLanguages,
                },
            });
            setSelectedLanguage(value);
        },
        [virtualEventSession, socket, sessionId],
    );

    return (
        <Box position="relative" height="100%" paddingX={2}>
            {messagesExist ? (
                <Stack
                    height="calc(100% - 80px)"
                    overflow="scroll"
                    ref={containerRef}
                    onScroll={handleScroll}
                >
                    {messageToDisplay.map((message, index) => (
                        <AttendeeCaptionMessage
                            key={message.id}
                            message={message}
                            showName={
                                index === 0 ||
                                message.participantId !== messageToDisplay[index - 1].participantId
                            }
                        />
                    ))}
                </Stack>
            ) : (
                <Stack
                    height="calc(100% - 80px)"
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                >
                    <PlaceholderImage src={noMessagesImage} alt="No messages" />
                    <Text>No captions yet</Text>
                </Stack>
            )}

            <Box position="absolute" bottom={12}>
                <LanguageSelect onChange={onLanguageChange} />
            </Box>
        </Box>
    );
};

export default TranslatedCaptions;

import React from 'react';
import styled from 'styled-components';
import { ContentContainer } from '../details';

const ImageContainer = styled.div`
    width: 100%;
    overflow: hidden;
`;

const Image = styled.img`
    object-fit: contain;
    width: 100%;
    margin-bottom: -6px;
`;

const PDFEmbed = styled.embed`
    width: 100%;
    height: 850px;
`;

const ImageDigitalContent = ({ badge }) => (
    <ContentContainer noPadding>
        <ImageContainer>
            <Image src={badge} alt="Badge" />
        </ImageContainer>
    </ContentContainer>
);

const PDFDigitalContent = ({ badge }) => {
    return <PDFEmbed src={`${badge}#toolbar=0&navpanes=0&scrollbar=0`} type="application/pdf" />;
};

const DigitalBadgeContent = ({ badge }) => {
    const isPDF = badge.toLowerCase().split('.').pop().startsWith('pdf');

    if (isPDF) {
        return <PDFDigitalContent badge={badge} />;
    }

    return <ImageDigitalContent badge={badge} />;
};

export default DigitalBadgeContent;

import React, { useMemo } from 'react';
import styled from 'styled-components';

import {
    ContainerRightRoundTable,
    RoundTableSecondaryScreen,
    RoundTableVideoWrapper,
} from '../../../styles';
import { useGlobalMutation, useGlobalState } from '../../../../../utils/container';
import RoundTableVideo from '../RoundTableVideo';
import { goBackFromVirtualSession } from '../../common/goBack';
import * as palette from '../../../../../components/General/Variables';
import DetailPage from '../../../../DetailPage';
import entities from '../../../../VirtualModeration/features/virtualFeature/constants/entities';
import Chat from '../../userInteraction/Chat';
import { colorTheme } from '../../../../VirtualModeration/features/virtualFeature/constants/colors';
import StreamPlayer from '../../common/StreamPlayer';
import get from 'lodash/get';
import Auth from '../../../../../services/api/auth';

const Wrapper = styled.div`
    flex-shrink: 0;
    overflow: scroll;
    width: 100%;
    height: 100%;
    background-color: ${colorTheme.WHITE};
    border-radius: 8px;
`;

const ContainerTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 100%;
    font-family: Cabin, sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    border-bottom: 1px solid ${colorTheme.SECONDARY};
`;

const DetailPageWrapper = styled(RoundTableSecondaryScreen)`
    margin: 16px 0 0 0;
`;

const SideContent = props => {
    const mutationCtx = useGlobalMutation();
    const stateCtx = useGlobalState();
    const currentUser = Auth.getUser();
    const { roundTableMode, streams, virtualEventSession } = stateCtx;
    const { timeslot, match, history, location } = props;
    const { timeslotId } = match.params;
    const virtualEventUsers = get(virtualEventSession, 'VirtualEventUsers');

    const mobile = (window.innerWidth < palette.MIN_TABLET_INT).toString();
    const isOnDemand =
        location && location.pathname && location.pathname.includes('/on-demand-poster-room');
    const isAttendeesMode = roundTableMode === entities.hostedSessionHostMode.attendees;
    const isChatMode = roundTableMode === entities.hostedSessionHostMode.chat;
    const streamsToPlay = useMemo(() => {
        return (virtualEventUsers || []).reduce((acc, vUser) => {
            const { User: user } = vUser;
            const isCurrentUser = currentUser && user.id === currentUser.id;

            if (!isCurrentUser) {
                const streamToUse = streams.find(st => st.streamId === get(vUser, 'UserId'));

                if (streamToUse) {
                    acc.push({
                        user,
                        isMicrophoneOn: vUser.isMicrophoneOn,
                        isVideoOn: vUser.isVideoOn,
                        stream: streamToUse,
                    });
                }
            }

            return acc;
        }, []);
    }, [virtualEventUsers, streams]);

    const goBackFunction = () => goBackFromVirtualSession(match, history, location, timeslotId);

    const goBackWithCapacity = async () => {
        await goBackFunction();
        mutationCtx.setShowCapacityModal(true);
    };

    return (
        <ContainerRightRoundTable size={4} tabletSize={4} mobile={mobile}>
            {!isOnDemand && isAttendeesMode && (
                <RoundTableSecondaryScreen
                    className="secondary"
                    size={12}
                    tabletSize={12}
                    mobile={mobile}
                >
                    <RoundTableVideoWrapper>
                        <RoundTableVideo
                            goBackWithCapacity={goBackWithCapacity}
                            timeslot={timeslot}
                            timeslotId={timeslotId}
                        />
                    </RoundTableVideoWrapper>
                </RoundTableSecondaryScreen>
            )}
            {!isOnDemand && isChatMode && (
                <Wrapper>
                    {streamsToPlay.map(({ stream }, index) => (
                        <StreamPlayer
                            key={`stream-${stream.getId()}-${index}`}
                            local={false}
                            stream={stream}
                            isPlaying={stream.isPlaying()}
                            isVideoOn={false}
                            uid={stream.getId()}
                            domId={`stream-player-${stream.getId()}`}
                            muted={false}
                        />
                    ))}
                    <ContainerTitle>Chat</ContainerTitle>
                    <Chat conversation={{ roomId: timeslotId }} isHostedSession />
                </Wrapper>
            )}
            {isOnDemand && timeslot?.id && (
                <DetailPage
                    objectId={timeslot.id}
                    objectClass={timeslot.objectClass}
                    DrawerWrapperOverride={DetailPageWrapper}
                    hideBackNavigation
                />
            )}
        </ContainerRightRoundTable>
    );
};

export default SideContent;

import React from 'react';
import styled, { css } from 'styled-components';

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

const FlexRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${props => (props.isrecording ? '24px' : '40px')};
    width: 100%;
    ${props => props.decreasedsize && !props.isrecording && 'height: 32px;'}
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 100%;
    border-radius: ${props => (props.inverted ? '0 6px 6px 0' : '6px 0 0 6px')};
    background-color: ${props => props.color};
    color: #fff;
    font-size: 21px;
    font-weight: 500;
    margin-right: 2px;
`;

const RightSide = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border-radius: ${props => (props.shouldDisplayCheckmark ? '0' : '0 6px 6px 0')};
    background-color: #f1f1f3;
    color: rgba(0, 0, 0, 0.38);
    font-size: 15px;
    font-weight: normal;
    border: none !important;
    z-index: 100;
    overflow: hidden;
`;

const RightSideOverlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px;
    width: ${props => props.width};
    height: 100%;
    border-radius: 0 4px 4px 0;
    box-shadow: 2px 2px 16px 0 rgba(41, 41, 44, 0.25);
    background-color: ${props => props.color};
    z-index: 11;

    & > * {
        font-family: Roboto, sans-serif;
        font-size: 21px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #fff;
    }

    ${props =>
        props.morehorizontalpadding &&
        `
        & > * {
            position: absolute;
            right: 64px;
        }
    `}
`;

const Title = styled.span`
    height: 24px !important;
    font-family: Cabin, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: ${props => (props.isrecording ? '2px' : '8px')};

    ${props =>
        props.isbeamerview &&
        css`
            font-size: 20px;
            line-height: 28px;
            color: rgba(0, 0, 0, 0.87);
            margin-bottom: ${props => (props.decreasesize ? 8 : 16)}px;
        `}
`;

const PercentageContainer = styled.div`
    position: absolute;
    right: ${props => (props.isrecording ? '2px' : '8px')};
    top: ${props => (props.isrecording || props.decreasesize ? '2px' : '6px')};
    width: ${props => (props.isrecording ? '41px' : '48px')};
    height: ${props => (props.isrecording ? '20px' : '28px')};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    z-index: 102;
`;
// ${props => props.decreasesize && !props.recording && }

const IconWrapper = styled.div`
    color: ${props => props.color};
    position: absolute;
    left: -40px;
    top: 12px;
`;

const SelfSelectedChoiceIndicator = styled.div`
    color: ${props => props.color};
    position: absolute;
    left: -80px;
    top: 10px;
    font-family: Cabin, sans-serif;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: 0.2px;
`;

const VoteResult = ({
    label,
    option,
    color,
    displayMode,
    totalVotes,
    isForRecording,
    showCorrectAnswer,
    currentPollAnswer,
    isCorrectOptionSet,
    isBeamerView,
    numberOfOptions,
    hideVotesNumber,
}) => {
    const percentage = Math.floor((option.votes / totalVotes) * 100);
    const votePercentage = totalVotes !== 0 ? `${percentage}%` : '0%';
    const isChosenOption = currentPollAnswer && currentPollAnswer.optionId === option.id;
    const shouldDecreaseSize = numberOfOptions > 4;

    return (
        <FlexColumn>
            <Title
                isrecording={isForRecording ? 1 : 0}
                isbeamerview={isBeamerView ? 1 : 0}
                decreasesize={shouldDecreaseSize ? 1 : 0}
            >
                {option.text}
            </Title>
            <FlexRow
                isrecording={isForRecording ? 1 : 0}
                decreasedsize={shouldDecreaseSize ? 1 : 0}
            >
                {option.correct && showCorrectAnswer && (
                    <IconWrapper color={color}>
                        <DoneIcon />
                    </IconWrapper>
                )}
                {isChosenOption && !option.correct && showCorrectAnswer && isCorrectOptionSet && (
                    <IconWrapper color={color}>
                        <CloseIcon />
                    </IconWrapper>
                )}
                {isChosenOption && showCorrectAnswer && (
                    <SelfSelectedChoiceIndicator color={color}>Yours</SelfSelectedChoiceIndicator>
                )}
                <LabelWrapper color={color} notEmpty={option.text !== ''}>
                    {label}
                </LabelWrapper>
                <RightSide color={color} notEmpty={option.text !== ''} displayMode={displayMode}>
                    <RightSideOverlay
                        color={color}
                        width={votePercentage}
                        morehorizontalpadding={percentage > 90 ? percentage : 0}
                    >
                        {!hideVotesNumber && <div>{option.votes}</div>}
                    </RightSideOverlay>
                    <PercentageContainer
                        isrecording={isForRecording ? 1 : 0}
                        decreasesize={shouldDecreaseSize ? 1 : 0}
                    >
                        {votePercentage}
                    </PercentageContainer>
                </RightSide>
            </FlexRow>
        </FlexColumn>
    );
};

export default VoteResult;

import React from 'react';
import { ImageAvatar } from '../Icons';
import { StyledListItem } from './style/index';

const PlaceListItem = ({ place }) => {
    return (
        <StyledListItem
            key={'place_' + place.id}
            primaryText={place.name}
            tabIndex={-1}
            leftAvatar={<ImageAvatar avatarType={'places'} />}
        />
    );
};

export default PlaceListItem;

import { createSelector } from 'reselect';

const gamificationStateSelector = state => state.gamification;

const getIsGamificationEnabled = createSelector(
    gamificationStateSelector,
    gamificationState => gamificationState.isGamificationEnabled,
);

const getAvailableAchievementTypes = createSelector(
    gamificationStateSelector,
    gamificationState => gamificationState.achievementTypes,
);

const getIsLoadingAvailableAchievementTypes = createSelector(
    gamificationStateSelector,
    gamificationState => gamificationState.isLoadingAchievementTypes,
);

const getAchievementTypesLoaded = createSelector(
    gamificationStateSelector,
    gamificationState => gamificationState.achievementTypesLoaded,
);

const getIsLoadingAchievements = createSelector(
    gamificationStateSelector,
    gamificationState => gamificationState.isLoadingAchievements,
);

const getAchievements = createSelector(
    gamificationStateSelector,
    gamificationState => gamificationState.achievements,
);

const getLoadAchievementsError = createSelector(
    gamificationStateSelector,
    gamificationState => gamificationState.loadAchievementsError,
);

const getTotalPoints = createSelector(
    gamificationStateSelector,
    gamificationState => gamificationState.totalPoints,
);

const getNumberOfCompletedAchievements = createSelector(
    gamificationStateSelector,
    gamificationState =>
        (gamificationState.achievements || []).filter(achievement => achievement.rewardUnblocked)
            .length,
);

const getNumberOfRewardsClaimed = createSelector(
    gamificationStateSelector,
    gamificationState =>
        (gamificationState.achievements || []).filter(achievement => achievement.rewardClaimed)
            .length,
);

export {
    getIsGamificationEnabled,
    getAvailableAchievementTypes,
    getIsLoadingAchievements,
    getAchievementTypesLoaded,
    getAchievements,
    getLoadAchievementsError,
    getTotalPoints,
    getIsLoadingAvailableAchievementTypes,
    getNumberOfCompletedAchievements,
    getNumberOfRewardsClaimed,
};

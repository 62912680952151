import React from 'react';

const TwitterIcon = () => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 20 20"
    >
        <path
            fill="#B3B3B3"
            fillRule="nonzero"
            d="m15.75.96h3.07l-6.7,7.66,7.88,10.42h-6.17l-4.83-6.32-5.53,6.32H.39l7.17-8.19L0,.96h6.33l4.37,5.78L15.75.96Zm-1.08,16.24h1.7L5.4,2.7h-1.82l11.09,14.5Z"
        />
    </svg>
);

export default TwitterIcon;

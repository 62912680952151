import React from 'react';
import { FontIcon } from 'react-md';
import styled from 'styled-components';
import { useTheme } from '../../../../../../components/Theme/ThemeContext';

const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    bottom: 72px;
    right: 24px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(0, 0, 0, 0.15),
        0px 3px 3px rgba(0, 0, 0, 0.05);
`;

const ICON_NAME = 'expand_more';

const ScrollDownButton = ({ onClick, hidden }) => {
    const { theme } = useTheme();

    return (
        !hidden && (
            <Button
                onClick={onClick}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        onClick();
                    }
                }}
            >
                <FontIcon style={{ color: theme ? theme.primary : '#4ea094', fontSize: 30 }}>
                    {ICON_NAME}
                </FontIcon>
            </Button>
        )
    );
};

export default ScrollDownButton;

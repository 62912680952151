import { removePreRecordings } from '../services/VirtualEventSession';

const onDeletePrerecordingUrl = async stateCtx => {
    const { sessionId, virtualEventUser, socket } = stateCtx;

    await removePreRecordings(sessionId, virtualEventUser.data.UserId);
    socket.emit('updateData', {
        objectId: sessionId,
        virtualEventSession: {
            VirtualEventUsers: [
                {
                    id: virtualEventUser.data.id,
                    recordingInfo: null,
                    hasVideoActive: false,
                    preRecordedPresentationUrl: null,
                },
            ],
        },
    });
};

export default onDeletePrerecordingUrl;

import { useGlobalState } from '../../../../../utils/container';
import { checkIfTimeslotHasOnlyOneUploadedVideo, getPlayerLinkByType } from '../../../utils/Utils';
import get from 'lodash/get';
import useResize from '../../../hooks/useResize';
import { MainScreen, MainScreenWrapper } from '../../../styles';
import MediaPlayer from '../../../../../components/MediaPlayer';
import ShareContent from '../../moderatedSession/common/ShareContent';
import Video from '../../moderatedSession/Video';
import React from 'react';
import styled from 'styled-components';
import { Cell } from 'react-md';
import * as palette from '../../../../../components/General/Variables';

const ContainerLeft = styled(Cell)`
    position: relative;
    overflow: scroll;
    -ms-overflow-style: none;
    flex: 2;
    margin: 0 16px 0 0 !important;

    &::-webkit-scrollbar {
        display: none;
    }

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        width: 100% !important;
    }
`;

const MainContent = props => {
    const { isVotingActive, setIsVotingActive } = props;
    const stateCtx = useGlobalState();

    const { mainMedia, timeslot, virtualEventSession } = stateCtx;
    const hasOnlyOneUploadedVideo = checkIfTimeslotHasOnlyOneUploadedVideo(timeslot);
    const forceUnmuteSlidesForUploadedVideo = hasOnlyOneUploadedVideo && mainMedia === 'slides';
    const forceNotMuted = !!get(virtualEventSession, 'recordingInfo.playBothVideosNormally');
    const muted =
        forceNotMuted || forceUnmuteSlidesForUploadedVideo ? false : mainMedia === 'slides';

    const [screenWrapperRef] = useResize(null, isVotingActive);
    const shouldRenderMediaPlayer =
        timeslot && timeslot.params && timeslot.params.virtualSession === 'on-demand';
    const shouldRenderLiveSession = !shouldRenderMediaPlayer;
    const mainIsVideo = mainMedia === 'video';
    const mainIsSlides = mainMedia === 'slides';
    const isOnDemand = timeslot?.params?.virtualSession === 'on-demand';

    return (
        <ContainerLeft size={8} tabletSize={8} phoneSize={12}>
            {shouldRenderMediaPlayer && (
                <MediaPlayer
                    primary
                    url={getPlayerLinkByType({
                        mainMedia,
                        timeslot,
                        primaryScreen: true,
                    })}
                    muted={muted}
                    viewRecordingSessionId={isOnDemand && timeslot && timeslot.id}
                />
            )}
            {shouldRenderLiveSession && (
                <MainScreenWrapper innerRef={screenWrapperRef}>
                    <MainScreen size={12} tabletSize={12} video={mainIsVideo ? 1 : 0}>
                        {mainIsSlides ? (
                            <ShareContent setIsVotingActive={setIsVotingActive} {...props} />
                        ) : (
                            <Video timeslot={timeslot} />
                        )}
                    </MainScreen>
                </MainScreenWrapper>
            )}
        </ContainerLeft>
    );
};

export default MainContent;

import { useEffect } from 'react';

import { loadAppStateSettings } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { getIsSociety, getSocietyDomain, getBranchIoDomain } from '../selectors';

const useSocietySettings = () => {
    const dispatch = useDispatch();
    const isSociety = useSelector(getIsSociety);
    const societyDomain = useSelector(getSocietyDomain);
    const branchIoDomain = useSelector(getBranchIoDomain);

    useEffect(() => {
        dispatch(loadAppStateSettings());
    }, [dispatch]);

    return { isSociety, societyDomain, branchIoDomain };
};

export default useSocietySettings;

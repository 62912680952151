import React from 'react';
import {
    ButtonsContainer,
    Card,
    CardImage,
    CardWrapper,
    CloseCardIcon,
    Subtitle,
    Title,
} from '../style/signIn';
import chatImage from '../../../assets/images/illustration-chat-web.png';
import * as string from '../constants/strings';
import Button, { buttonTypes } from '../../common/Button';
import { getString } from '../../../services/api/store';
import { Flex } from '../../common/Flex';
import * as palette from '../../../components/General/Variables';

const ChatAndMeetingsSettings = ({
    theme,
    onSaveProfileSettings,
    eurekaOnly,
    attendingOnly,
    onClose,
    isModal,
}) => (
    <CardWrapper>
        <Card
            customHeight={'unset'}
            customTop={'unset'}
            overflow={'scroll'}
            largeCardWithCustomHeight={true}
            isModal={isModal}
        >
            <Flex alignItems={'center'} justifyContent={'center'}>
                <Title largeCard={true}>
                    {getString('allowNewChatsPrivacyTitle') || string.CHAT_SETTINGS_TITLE}
                </Title>
                {!eurekaOnly && !attendingOnly && (
                    <CloseCardIcon onClick={onClose}>{palette.ICON_CLOSE}</CloseCardIcon>
                )}
            </Flex>
            <Subtitle largeCard={true}>
                {getString('allowNewChatsPrivacyNewSubtitle') ||
                    getString('allowNewChatsPrivacySubtitle') ||
                    string.CHAT_SETTINGS_DESCRIPTION}
            </Subtitle>
            <CardImage src={chatImage} alt={chatImage} />
            <ButtonsContainer primarycolor={theme.primary}>
                <Button
                    type={buttonTypes.GREEN_LONG}
                    background={theme.primary}
                    text={
                        getString('allowNewChatsPrivacyAccept') || string.CHAT_SETTINGS_YES_BUTTON
                    }
                    onClick={() => {
                        onSaveProfileSettings(true);
                    }}
                    data-qa="button-allow-chat-and-meetings"
                    style={{ marginTop: '12px' }}
                />
                <Button
                    type={buttonTypes.GHOST_LONG}
                    background={theme.primary}
                    text={
                        getString('allowNewChatsPrivacyDecline') || string.CHAT_SETTINGS_NO_BUTTON
                    }
                    onClick={() => {
                        onSaveProfileSettings(false);
                    }}
                    data-qa="button-stay-private"
                    style={{ marginTop: '12px' }}
                />
            </ButtonsContainer>
        </Card>
    </CardWrapper>
);

export default ChatAndMeetingsSettings;

import { createSelector } from 'reselect';
import moment from 'moment';
import { sortBy } from 'lodash';

const societyStateSelector = state => state.society;

const getEvents = createSelector(societyStateSelector, societyState => {
    const events = societyState.events;
    return sortBy(events, function (o) {
        return new moment(o.start);
    }).reverse();
});
const getIsLoadingEvents = createSelector(
    societyStateSelector,
    societyState => societyState.isLoadingEvents,
);
const getEventsError = createSelector(
    societyStateSelector,
    societyState => societyState.loadEventsError,
);
const getSocietyDomain = createSelector(
    societyStateSelector,
    societyState => societyState.societyDomain,
);
const getBranchIoDomain = createSelector(
    societyStateSelector,
    societyState => societyState.branchIoDomain,
);
const getIsSociety = createSelector(
    societyStateSelector,
    societyState => societyState && societyState.isSociety,
);

const getMySessions = createSelector(societyStateSelector, societyState => {
    const data = societyState.mySessions;
    return data;
});

const getIsLoadingSessions = createSelector(
    societyStateSelector,
    societyState => societyState.isLoadingSessions,
);

const getSessionsError = createSelector(
    societyStateSelector,
    societyState => societyState.loadSessionsError,
);

const getAllMySessions = createSelector(societyStateSelector, societyState => {
    const filteredSessions = societyState.mySessions.filter(session => {
        return (
            session.actions &&
            (session.actions.includes('vote') ||
                session.actions.includes('ask') ||
                session.actions.includes('moderator') ||
                session.actions.includes('presenter') ||
                session.actions.includes('roundTableHost'))
        );
    });

    return filteredSessions;
});

export {
    getEvents,
    getIsLoadingEvents,
    getEventsError,
    getIsSociety,
    getSocietyDomain,
    getBranchIoDomain,
    getMySessions,
    getIsLoadingSessions,
    getSessionsError,
    getAllMySessions,
};

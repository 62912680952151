import React from 'react';

import DialogTitle from './DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const actionsStyles = {
    root: {
        padding: 3,
        justifyContent: 'flex-start',
        boxShadow: '0px -12px 9px -12px #CCC',
    },
};

const styles = {
    paper: {
        borderRadius: 2,
        width: 424,
        height: 705,
        overflow: 'hidden',
    },
};

/**
 * @component
 * @description A Dialog (Modal) component based on Material UI lib with some modifications made to Title styles and how action buttons are displayed
 * @param {Object} props
 * @param {JSX} props.children content rendered inside Dialog
 * @param {Object} props.contentStyle additional style added to content
 * @param {String} props.maxWidth size of component (width). It could be one of those: 'xs', 'sm', 'md' (default), 'lg' or 'xl'
 * @param {Function} props.onClose function called when the Dialog is dismissed by pressing the Esc key or the X button at the top
 * @param {Array} props.options Material UI Buttons for confirming or dismissing the dialog for example
 * @param {String} props.title title on the dialog. Can also be empty
 * @param {Boolean} props.visible when *false* the dialog is hidden and displayed when *true*
 */
const MaterialDialog = ({
    children,
    contentStyle = {},
    maxWidth = 'xs',
    onClose = () => {},
    options = [],
    title = '',
    visible = false,
    disableEnforceFocus = false,
}) => {
    return (
        <Dialog
            open={visible}
            fullWidth={true}
            maxWidth={maxWidth}
            onClose={onClose} // Esc key callback
            scroll="paper"
            disableEnforceFocus={disableEnforceFocus}
            sx={{ '& .MuiDialog-paper': styles.paper }}
        >
            <DialogTitle id="scroll-dialog-title" onClose={onClose}>
                {title}
            </DialogTitle>
            <DialogContent style={contentStyle}>{children}</DialogContent>
            <DialogActions sx={actionsStyles.root}>{options}</DialogActions>
        </Dialog>
    );
};

export default MaterialDialog;

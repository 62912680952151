import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';
import ReactPlayer from 'react-player';

const StyledContainer = styled.div`
    width: 327px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(60, 63, 68, 0.15);
    background-color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
`;

const StyledContent = styled.div`
    padding: 8px 20px 16px 20px;
`;

const StyledTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
`;

const StyledSubtitle = styled.div`
    font-size: 15px;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.54);
`;

const StyledImage = styled.img`
    height: 182px;
    width: 100%;
`;

const StyledLinkButton = styled.a`
    background-color: #ebedf0;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 9px 0;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    margin-top: 12px;
    font-weight: 500;
    text-decoration: none;
`;

const StyledOpenLinkIcon = styled(FontIcon)`
    margin-right: 8px;
`;

const RewardCard = ({ achievement }) => {
    const {
        rewardTitle,
        rewardText,
        rewardUrl,
        rewardMediaType,
        rewardImage,
        rewardVideo,
    } = achievement;

    return (
        <StyledContainer>
            {rewardMediaType === 'image' ? (
                <StyledImage src={rewardImage} />
            ) : (
                <ReactPlayer url={rewardVideo} width="100%" height={182} />
            )}
            <StyledContent>
                <StyledTitle>{rewardTitle}</StyledTitle>
                <StyledSubtitle>{rewardText}</StyledSubtitle>
                <StyledLinkButton href={rewardUrl} target="_blank">
                    <StyledOpenLinkIcon inherit>open_in_new</StyledOpenLinkIcon>
                    Open link
                </StyledLinkButton>
            </StyledContent>
        </StyledContainer>
    );
};

export default RewardCard;

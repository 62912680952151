import React, { useState } from 'react';
import styled from 'styled-components';
import CloseRoomIcon from '@material-ui/icons/NoMeetingRoomOutlined';
import OpenRoomIcon from '@material-ui/icons/MeetingRoomOutlined';

import entities from '../../constants/entities';

import ConfirmationDialog from '../common/ConfirmatonDialog';
import { useVMState } from '../../containers/main';
import { ButtonWithImageContainer } from './Footer';

const { virtualEventSessionEntity } = entities;

const FlexCenter = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

export default () => {
    const stateCtx = useVMState();
    const { virtualEventSession, sessionId, socket } = stateCtx;
    const virtualEventSessionStatus = virtualEventSession.data.status;

    const [modalOpened, setModalOpened] = useState({
        noRecording: false,
        recording: false,
        broadcasting: false,
    });
    const handleModalChange = (type, value) =>
        setModalOpened({
            ...modalOpened,
            [type]: value,
        });

    const onUpdateVirtualEventSessionStatus = async virtualEventSessionStatus => {
        stateCtx.socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                status: virtualEventSessionStatus,
            },
        });
    };

    const onSetStatusBroadcasting = async () => {
        await onUpdateVirtualEventSessionStatus(virtualEventSessionEntity.status.broadcasting);
    };
    const onSetStatusClosed = async () => {
        await onUpdateVirtualEventSessionStatus(virtualEventSessionEntity.status.closed);
    };

    if (virtualEventSessionStatus === virtualEventSessionEntity.status.broadcasting) {
        return (
            <FlexCenter>
                <ButtonWithImageContainer data-qa="close-room-host" onClick={onSetStatusClosed}>
                    <CloseRoomIcon />
                    Close room
                </ButtonWithImageContainer>
            </FlexCenter>
        );
    }

    return (
        <FlexCenter>
            <ButtonWithImageContainer data-qa="open-room-host" onClick={onSetStatusBroadcasting}>
                <OpenRoomIcon />
                Open Room
            </ButtonWithImageContainer>
            <div className="eureka-react">
                <ConfirmationDialog
                    open={modalOpened.noRecording}
                    title="Not recording"
                    content="The session won't be recorded, please ask tech help."
                    onOk={() => handleModalChange('noRecording', false)}
                />
            </div>
        </FlexCenter>
    );
};

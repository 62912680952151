import get from 'lodash/get';
import { deleteVideos } from '../services/VirtualEventSession';

const onDeleteUploadedVideoById = async (id, stateCtx) => {
    const { sessionId, virtualEventSession, socket } = stateCtx;

    const videoUrls = get(virtualEventSession, 'data.videoUrls');

    if (!videoUrls) {
        return;
    }

    await deleteVideos(id, sessionId);

    socket.emit('updateData', { objectId: sessionId });
};

export default onDeleteUploadedVideoById;

import { useTheme } from '../../../components/Theme/ThemeContext';
import Notes from './Notes';
import React from 'react';
import AuthUserContext from '../../../components/Session/AuthUserContext';
import InteractivityContext from '../../../components/Interactivity/InteractivityContext';
import { withRouter } from 'react-router-dom';

const NotesWrapper = ({
    item,
    objectId,
    location,
    displayNoteCard,
    notesTextWithAction,
    notePage,
    notesButtonLarge,
    notesButton,
    setUpdateNote,
}) => {
    const { theme } = useTheme();

    return (
        <AuthUserContext.Consumer>
            {({ authUser, showSignIn }) => (
                <InteractivityContext.Consumer>
                    {({ showNotes }) => (
                        <>
                            {notesButton && (
                                <Notes
                                    authUser={authUser}
                                    showNotes={showNotes}
                                    showSignIn={showSignIn}
                                    theme={theme}
                                    item={item}
                                    objectId={objectId}
                                    renderSmallButton={true}
                                    setUpdateNote={setUpdateNote}
                                    showNoteDialog={location?.state?.showNoteDialog}
                                />
                            )}
                            {notesButtonLarge && (
                                <Notes
                                    authUser={authUser}
                                    showNotes={showNotes}
                                    showSignIn={showSignIn}
                                    theme={theme}
                                    item={item}
                                    objectId={objectId}
                                    setUpdateNote={setUpdateNote}
                                    renderLargeGrayButton={true}
                                    showNoteDialog={location?.state?.showNoteDialog}
                                />
                            )}
                            {notesTextWithAction && (
                                <Notes
                                    authUser={authUser}
                                    showNotes={showNotes}
                                    showSignIn={showSignIn}
                                    theme={theme}
                                    item={item}
                                    objectId={objectId}
                                    setUpdateNote={setUpdateNote}
                                    displayOnlyText={true}
                                    showNoteDialog={location?.state?.showNoteDialog}
                                />
                            )}
                            {displayNoteCard && (
                                <Notes
                                    authUser={authUser}
                                    showNotes={showNotes}
                                    showSignIn={showSignIn}
                                    theme={theme}
                                    item={item}
                                    setUpdateNote={setUpdateNote}
                                />
                            )}
                            {notePage && (
                                <Notes
                                    authUser={authUser}
                                    showNotes={showNotes}
                                    showSignIn={showSignIn}
                                    theme={theme}
                                    item={item}
                                    objectId={objectId}
                                    setUpdateNote={setUpdateNote}
                                    showNoteDialog={true}
                                />
                            )}
                        </>
                    )}
                </InteractivityContext.Consumer>
            )}
        </AuthUserContext.Consumer>
    );
};

export default withRouter(NotesWrapper);

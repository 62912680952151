import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AccessibleFakeButton, Divider, DropdownMenu, FontIcon, ListItem } from 'react-md';
import { StyledDropdownMenu } from '../../../../../../scenes/User/style/modalsStyle';

const ExpandIcon = styled(FontIcon)`
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54) !important;
    font-size: 24px !important;

    &:hover {
        color: rgba(0, 0, 0, 0.87) !important;
    }
`;

const ToggleButton = styled(AccessibleFakeButton)`
    display: flex;
    color: rgba(0, 0, 0, 0.54) !important;
`;

const DropdownItem = styled(ListItem)`
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    font-size: 15px;
    font-weight: 500;

    .md-list-tile--active {
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 4px !important;
    }
`;

const DropdownTextButton = styled.button`
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    margin-right: 4px;
    line-height: 1.71;
    color: rgba(0, 0, 0, 0.87);
    border: none;
    background-color: transparent;
`;

const StyledIcon = styled(FontIcon)`
    color: rgba(0, 0, 0, 0.87) !important;
`;

const DROPDOWN_CONTAINER_ID = 'dropdown-container';
const DROPDOWN_BUTTON_ID = 'dropdown-button';
const DROPDOWN_ICON_ID = 'dropdown-icon';
const DROPDOWN_TEXT_ICON_ID = 'dropdown-text-icon';

const Dropdown = ({ items, buttonText, customPosition, zIndex }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleIsExpanded = e => {
        if (e) {
            e.stopPropagation();
        }
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        const rootElement = document.querySelector('body');

        rootElement.addEventListener('click', handleClick);

        return () => rootElement.removeEventListener('click', handleClick);
    }, [isExpanded]);

    const handleClick = e => {
        const { id } = e.target;

        if (
            id !== DROPDOWN_CONTAINER_ID &&
            id !== DROPDOWN_BUTTON_ID &&
            id !== DROPDOWN_ICON_ID &&
            id !== DROPDOWN_TEXT_ICON_ID &&
            isExpanded
        ) {
            toggleIsExpanded();
        }
    };

    const menuItems = items.map(item =>
        item.divider ? (
            <Divider />
        ) : (
            <DropdownItem
                key={`dropdown-item-${item.text}`}
                onClick={!item.disabled ? item.onClick : () => {}}
                primaryText={item.text}
                leftIcon={item.icon ? <StyledIcon>{item.icon}</StyledIcon> : null}
                rightIcon={item.rightIcon ? <StyledIcon>{item.rightIcon}</StyledIcon> : null}
                disabled={item.disabled}
                style={item.disabled ? { cursor: 'default' } : {}}
            />
        ),
    );

    return (
        <React.Fragment>
            <StyledDropdownMenu
                id="authenticated-user-dropdown-menu"
                menuItems={menuItems}
                anchor={{
                    x: DropdownMenu.HorizontalAnchors.INNER_RIGHT,
                    y: customPosition || DropdownMenu.VerticalAnchors.BOTTOM,
                }}
                useTop={customPosition}
                sameWidth
                simplifiedMenu={false}
                onVisibilityChange={() => {}}
                visible={!!isExpanded}
                isAuthenticated={true}
                width={'fit-content'}
                visibleOnPhone
                zindex={zIndex}
            >
                <ToggleButton>
                    {!buttonText && (
                        <ExpandIcon onClick={toggleIsExpanded} id={DROPDOWN_ICON_ID}>
                            more_horiz
                        </ExpandIcon>
                    )}
                    {buttonText && (
                        <DropdownTextButton onClick={toggleIsExpanded} id={DROPDOWN_BUTTON_ID}>
                            {buttonText}
                            <ExpandIcon id={DROPDOWN_TEXT_ICON_ID}>
                                {isExpanded ? 'expand_less' : 'expand_more'}
                            </ExpandIcon>
                        </DropdownTextButton>
                    )}
                </ToggleButton>
            </StyledDropdownMenu>
        </React.Fragment>
    );
};

export default Dropdown;

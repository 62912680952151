import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';

import UserInteraction from '../../moderator/userInteraction';
import * as palette from '../../../../../../../components/General/Variables';
import Chat from '../../../../Talk/components/Chat';
import { EUREKA_TALK_HOST } from '../../../../../../../config';
import { useVMState } from '../../../containers/main';
import TalkManager, { XMPPEvent } from '../../../../../../Talk/services';
import { colorTheme } from '../../../constants/colors';
import { useMediaQuery } from '@mui/material';
import { MAX_TABLET_INT } from '../../../../../../../components/General/Variables';

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: ${props => (props.padding ? props.padding : 0)};
    overflow-y: scroll;
    @media (max-height: 800px) and (orientation: landscape) {
        max-width: 400px;
    }
`;

const InteractionWrapper = styled.div`
    flex: 3;
    height: 100%;
    min-height: 0;
    width: 100%;
    @media only screen and (max-width: ${palette.MAX_TABLET}) {
        bottom: 115px;
    }
`;

const ContainerTitle = styled.div`
    display: flex;
    align-items: center;
    height: 56px;
    width: 100%;
    font-family: Cabin, sans-serif;
    font-size: 21px;
    font-weight: 700;
    line-height: 24px;
    padding-left: 24px;
`;

const RightContent = props => {
    const stateCtx = useVMState();
    const { sessionId, virtualEventSession, externalObject } = stateCtx;
    const { showChat } =
        virtualEventSession && virtualEventSession.data ? virtualEventSession.data : {};
    const showAttendeesChat = showChat;
    const [chatRoomId, setChatRoomId] = useState();

    const [interactionWindowHeight, setInteractionWindowHeight] = useState(null);
    const wrapperRef = useRef();
    const isTabletOrSmaller = useMediaQuery(`(max-width:${MAX_TABLET_INT}px)`);

    const sessionName = externalObject.data.title;

    const handleResize = () => {
        const wrapperHeight =
            wrapperRef.current && wrapperRef.current.getBoundingClientRect().height;
        const interactionHeight = window.innerHeight - wrapperHeight;
        setInteractionWindowHeight(
            window.innerHeight < 900 ? interactionHeight : interactionHeight + 200,
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return !isTabletOrSmaller ? (
        <Wrapper innerRef={wrapperRef}>
            <ContainerTitle>Chat</ContainerTitle>
            <InteractionWrapper
                interactionWindowHeight={interactionWindowHeight}
                interactionInitialHeight={!interactionWindowHeight && window.innerHeight}
            >
                <Chat
                    nonVirtualQA
                    itemId={sessionId}
                    itemName={sessionName}
                    moderatorOnly={false}
                    chatUrl={EUREKA_TALK_HOST}
                    isVisible={true}
                    onChatRoomIdSet={chatRoomId => setChatRoomId(chatRoomId)}
                />
            </InteractionWrapper>
        </Wrapper>
    ) : null;
};

RightContent.propTypes = {};

export default RightContent;

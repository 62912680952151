import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
`;

const RankContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border: solid 1px #dadada;
    background-color: #fff;
    border-radius: ${props => props.size / 2}px;
`;

const RankNumber = styled.div`
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
`;

const CrownImage = styled.img`
    position: absolute;
    top: -21px;
    left: -6px;
    height: 34px;
    width: 34px;
    z-index: -1;
    transform: rotate(-20deg);
`;

const Rank = ({ size = 40, rank, className }) => {
    return (
        <Container className={className}>
            {rank === 1 && <CrownImage src={require('../../../assets/icons/crown.png')} />}
            <RankContainer justifyContent="center" alignItems="center" size={size}>
                <RankNumber>{rank}</RankNumber>
            </RankContainer>
        </Container>
    );
};

export default Rank;

import { SOCIAL_WALL_PAGINATION_LIMIT } from '../../constants';
import { eurekaRequest } from '../../../../services/api/eureka';
import { getLocalAppStateAsync } from '../../../../services/api/db';

const asyncEurekaRequest = (method, path, data, isAuthenticated) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(method, path, data, isAuthenticated, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
};

function makeid() {
    let text = '';
    const randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
    text = randLetter + Date.now();
    return text;
}

const registerUser = async () => {
    const { eventId } = await getLocalAppStateAsync();
    const data = { eventReference: eventId };
    const path = '/api/services/feed/register';

    return await asyncEurekaRequest('post', path, data, true);
};

// Also for not authenticated users
const eventFeed = async ({ lastObjectId, eventId }) => {
    let path = `/api/services/feed/getopenfeed?type=event&typeId=${eventId}&limit=${SOCIAL_WALL_PAGINATION_LIMIT}`;
    if (lastObjectId) {
        path += `&lastItem=${lastObjectId}`;
    }

    return await asyncEurekaRequest('get', path, null, false);
};

const addComment = async ({ feed, message, eventId }) => {
    const unique = makeid();
    const path = '/api/services/feed/comment';
    const data = {
        event: eventId,
        unique,
        ownerId: feed.actor,
        object: feed.object,
        message,
    };

    return await asyncEurekaRequest('post', path, data, true);
};

const eurekaLike = async ({ feed, eventId }) => {
    const unique = makeid();
    const path = '/api/services/feed/like';
    const data = {
        event: eventId,
        unique,
        ownerId: feed.actor,
        object: feed.object,
    };

    return await asyncEurekaRequest('post', path, data, true);
};

const removeActivity = async ({ feed, eventId }) => {
    const path = '/api/services/feed/removeWithForeign';
    const data = {
        event: eventId,
        foreignId: feed.foreign_id,
        verb: feed.verb,
    };

    return await asyncEurekaRequest('post', path, data, true);
};

const reportActivity = async ({ activity, eventId }) => {
    const path = '/api/entities/activityObjections';
    const data = {
        event: eventId,
        foreign_id: activity.foreign_id,
        verb: activity.verb,
        reason: 'inappropriate or irrelevant content',
    };

    return await asyncEurekaRequest('post', path, data, true);
};

const addPost = async ({ message, imageUrl, eventId }) => {
    const unique = makeid();
    const path = '/api/services/feed/post';

    const data = {
        event: eventId,
        unique,
        message,
    };
    if (imageUrl) {
        data.image = imageUrl;
    }

    return await asyncEurekaRequest('post', path, data, true);
};

const updateActivity = async ({ object, eventId }) => {
    const path = '/api/services/feed/updateActivity';
    const data = {
        event: eventId,
        ...object,
    };

    return await asyncEurekaRequest('post', path, data, true);
};

const getPostByObjectId = async ({ objectId, ownerId }) => {
    const path = `//api/services/feed/getpost/${objectId}/${ownerId}`;

    return await asyncEurekaRequest('get', path, null, true);
};

const socialFeedApi = {
    registerUser,
    eventFeed,
    addComment,
    eurekaLike,
    removeActivity,
    reportActivity,
    addPost,
    updateActivity,
    getPostByObjectId,
};

export { socialFeedApi };

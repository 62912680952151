import React, { useState, useContext, useEffect } from 'react';
import { HiddenPasswordModal } from './';
import AuthenticationProtection from '../ProtectedPages/AuthenticationProtection';
import { matchPath, withRouter } from 'react-router-dom';
import Auth from '../../services/api/auth';
import AuthUserContext from '../Session/AuthUserContext';
import { isUserAttendingByEmail } from '../../services/api/eureka';

const ProtectionWrapper = ({
    eventId,
    eurekaOnly,
    attendingOnly,
    groupCanSignOnly,
    groupCanAccessOnly,
    hiddenPassword,
    children,
    match,
    history,
    location,
}) => {
    const isAuthenticated = Auth.isUserAuthenticated();
    const currentUser = Auth.getUser();
    const [allowed, setAllowed] = useState(false);
    const { showSignIn } = useContext(AuthUserContext);
    const [_, setIsPasswordEntered] = useState(false);
    const enteredHiddenPassword = localStorage.getItem(`${eventId}_enteredHiddenPassword`);
    const hasProtection = eurekaOnly || attendingOnly || groupCanAccessOnly;
    const matcherForKiosk = matchPath(location.pathname, {
        path: '/kiosk/:eventName',
        strict: false,
    });

    useEffect(() => {
        if (isAuthenticated && groupCanSignOnly && currentUser.email) {
            let isAllowed = false;
            Auth.checkIfEmailExists(currentUser.email, async (error, result) => {
                if (!error && result) {
                    const { userGroups } = result;
                    if (userGroups && userGroups.length) {
                        isAllowed = userGroups.some(item => item.id === groupCanSignOnly);
                    }
                    setAllowed(isAllowed);
                    if (!isAllowed) {
                        showSignIn(null, {
                            match,
                            history,
                            location,
                            signInCurrentPage: 'unauthorizedGroup',
                        });
                        return;
                    }
                }
            });
        }
        setAllowed(true);
    }, [isAuthenticated, showSignIn]);

    if (matcherForKiosk) {
        return children;
    }

    if (hiddenPassword && (!enteredHiddenPassword || enteredHiddenPassword !== hiddenPassword)) {
        return (
            <HiddenPasswordModal
                password={hiddenPassword}
                eventId={eventId}
                onDone={() => setIsPasswordEntered(true)}
            />
        );
    }
    if (hasProtection) {
        return (
            <AuthenticationProtection routeProtection fullScreen>
                {children}
            </AuthenticationProtection>
        );
    }

    return allowed ? children : null;
};

export default withRouter(ProtectionWrapper);

import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../Theme/ThemeContext';

const GroupContainer = styled.div`
    width: fit-content;
    border: 1px solid #d3d3d3;
    border-radius: 24px;
`;

const Group = styled.div`
    display: flex;
    border-radius: 24px;
    margin: -1px;
`;

const GroupButton = styled.div`
    padding: 8px 0;
    border-radius: 24px;
    align-items: center;
    cursor: pointer;

    ${props =>
        props.active &&
        `
    border: 1px solid ${props.color};
    background-color: ${props.color}08;
  `}
`;

const GroupButtonText = styled.div`
    line-height: 24px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: ${props => (props.active ? props.color : 'rgba(0, 0, 0, 0.87)')};
    width: 100%;
    padding: 0 16px;

    ${props =>
        props.divider &&
        `
    border-right: 1px solid #D3D3D3;
  `}
`;

/**
 * Group Buttons
 * @param {{ value: any, label: string }[]} items - array of items;
 * @param {number} activeIndex - index of active item;
 * @param {function(number, { value: any, label: string })} onActiveIndexChange - callback when active index is changed;
 * @returns {JSX.Element}
 */
const GroupButtons = ({ items, activeIndex, onActiveIndexChange }) => {
    const { theme } = useTheme();

    const renderGroupButton = (item, index) => {
        const { label, value } = item;
        const key = `group-button-${label}-${value}`;
        const isActive = index === activeIndex;
        const nextItemIsActive = index + 1 === activeIndex;
        const hasDivider = index < items.length - 1 && !isActive && !nextItemIsActive;

        return (
            <GroupButton
                key={key}
                active={isActive}
                color={theme.primary}
                onClick={() => onActiveIndexChange(index, item)}
            >
                <GroupButtonText active={isActive} color={theme.primary} divider={hasDivider}>
                    {label}
                </GroupButtonText>
            </GroupButton>
        );
    };

    return (
        <GroupContainer>
            <Group>{items.map(renderGroupButton)}</Group>
        </GroupContainer>
    );
};

export default GroupButtons;

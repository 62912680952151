import React, { useEffect, useState, memo, useMemo } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import get from 'lodash/get';

import { EmptyState } from './EmptyState';
import InputMessageComponent from './InputMessageComponent';
import ChatFeed from './ChatFeed';
import useChat from '../hooks/useChat';
import { useVMState } from '../../virtualFeature/containers/main';
import VisibilityToaster from '../../virtualFeature/components/moderator/userInteraction/common/VisibilityToaster';
import ConfirmationDialog from '../../virtualFeature/components/common/ConfirmatonDialog';
import {
    CancelModalButton,
    ContentModalContainer,
    DestructiveModalButton,
} from '../../virtualFeature/components/moderator/common/styles';

const Container = styled.div`
    height: calc(100% - ${props => (props.hostedsession ? '56px' : '0px')});
    position: relative;
    overflow: hidden;
    padding: 0 16px;
`;

const MessagesContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 0 50px 0;
`;

const offlineBarStyle = {
    height: '20px',
    backgroundColor: '#F2BB61',
};

const offlineTextStyle = {
    color: 'white',
    fontSize: '110%',
    paddingLeft: '6px',
};

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute !important;
`;

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    padding: 0 25px;
    margin-bottom: -3px;
`;

const MAX_CHAR_COUNT = 250;

const Chat = ({
    itemId,
    itemName,
    moderatorOnly,
    chatUrl,
    isVisible,
    onChatRoomIdSet,
    nonVirtualQA,
    isHostedSession,
}) => {
    const stateCtx = useVMState();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [
        state,
        {
            sendMessage,
            loadMessages,
            onHideDeleteMessageModal,
            onSetMessageToDelete,
            onDeleteMessage,
        },
    ] = useChat({
        itemId,
        itemName,
        moderatorOnly,
        chatUrl,
        nonVirtualQA,
    });
    const {
        isServerOnline,
        messages,
        isLoadingMessages,
        chatRoomId,
        showDeleteMessageModal,
        messageHasBeenDeleted,
    } = state;
    const { virtualEventSession } = stateCtx;
    const sessionHasChatEnabled = get(virtualEventSession, 'data.showChat') || false;
    const isAttendeeChatEnabled = moderatorOnly || sessionHasChatEnabled || nonVirtualQA;

    const currentUser = useMemo(() => {
        return JSON.parse(localStorage.getItem('user'));
    }, []);

    const isFirefox = typeof InstallTrigger !== 'undefined';

    const TEXT_EMPTY_CHAT_ROOM = 'No chat messages sent in this room yet.';
    let TEXT_PLACEHOLDER = 'Messages are visible to all attendees, moderators and speakers';

    if (moderatorOnly) {
        TEXT_PLACEHOLDER = 'Messages are only visible to moderators and speakers';
    }
    if (isHostedSession || nonVirtualQA) {
        TEXT_PLACEHOLDER = 'Send a message';
    }

    const handleResize = debounce(() => {
        setWindowHeight(window.innerHeight);
    }, 500);

    useEffect(() => {
        if (!chatRoomId) {
            return;
        }

        onChatRoomIdSet(chatRoomId);
    }, [chatRoomId]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderOfflineStatus = () => {
        return (
            <div style={offlineBarStyle}>
                <p style={offlineTextStyle}>Looks like you &apos;re offline</p>
            </div>
        );
    };

    if (!isVisible) {
        return null;
    }

    return (
        <Container windowHeight={windowHeight} hostedsession={isHostedSession ? 1 : 0}>
            {!isServerOnline ? (
                renderOfflineStatus()
            ) : (
                <>
                    {!isLoadingMessages && !messages.length ? (
                        <EmptyState message={TEXT_EMPTY_CHAT_ROOM} />
                    ) : (
                        <MessagesContainer>
                            <ChatFeed
                                messages={messages}
                                userData={currentUser}
                                isLoadingMessages={isLoadingMessages}
                                loadPreviousMessages={loadMessages}
                                chatRoomId={chatRoomId}
                                onSetMessageToDelete={onSetMessageToDelete}
                                messageHasBeenDeleted={messageHasBeenDeleted}
                                nonVirtualQA={nonVirtualQA}
                            />
                        </MessagesContainer>
                    )}
                    <Flex isfirefox={isFirefox ? 1 : undefined}>
                        {isAttendeeChatEnabled ? (
                            <InputMessageComponent
                                sendMessage={sendMessage}
                                placeholder={TEXT_PLACEHOLDER}
                                maxChar={MAX_CHAR_COUNT}
                                placeholderFitsOnTwoLines={!moderatorOnly && !isHostedSession}
                            />
                        ) : (
                            <VisibilityToaster />
                        )}
                    </Flex>
                </>
            )}
            <ConfirmationDialog
                open={showDeleteMessageModal}
                title="Delete message"
                titleIcon="delete_forever"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                content={
                    <ExtraStyledModalContentContainer>
                        Are you sure you want to delete this message?
                    </ExtraStyledModalContentContainer>
                }
                onClose={onHideDeleteMessageModal}
                buttons={[
                    <DestructiveModalButton key="DMCD" flat onClick={onDeleteMessage}>
                        Delete
                    </DestructiveModalButton>,
                    <CancelModalButton key="DMCC" flat onClick={onHideDeleteMessageModal}>
                        Cancel
                    </CancelModalButton>,
                ]}
            />
        </Container>
    );
};

export default memo(Chat);

import React from 'react';
import { Paper } from 'react-md';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const LoaderContainer = styled(Paper)`
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-left: calc(50% - 25px);
    margin-top: 40px;
    display: flex;
    align-items: center;
`;

const Loader = ({ color = '#44bb90', text, extraCircleStyle, ...props }) => {
    const circleStyle = { marginLeft: '10px', color, ...extraCircleStyle };
    return (
        <LoaderContainer zDepth={1} {...props}>
            {text}
            <CircularProgress style={circleStyle} size={30} />
        </LoaderContainer>
    );
};

export default Loader;

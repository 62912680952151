import { eurekaRequest } from '../../../services/api/eureka';
import axios from 'axios';

const asyncEurekaRequest = (method, path, data, isAuthenticated) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(method, path, data, isAuthenticated, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
};

export const fetchNewsFeed = async (eventId, configuratorUrl) => {
    const response = await fetch(`${configuratorUrl}/api/data/announcements/published/${eventId}`);
    return response.json();
};

export const getTargetedMessages = async (eventId, configuratorUrl) => {
    const targetedMessagesIds = await asyncEurekaRequest(
        'GET',
        '/api/entities/targetedMessages',
        null,
        true,
    );
    const url = `${configuratorUrl}/api/data/announcements/getTargeted`;
    const requestConfig = {
        headers: {
            'x-event-id': eventId,
        },
    };
    const data = {
        targetedMessagesIds,
        eventId,
    };
    const response = await axios.post(url, data, requestConfig);

    return response.data;
};

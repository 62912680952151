import React from 'react';

import * as palette from '../../../components/General/Variables';
import Button, { buttonTypes } from '../../common/Button';
import { MarginTop12px } from '../../common/Margin';
import { PadddingLeftRight16px } from '../../common/Padding';
import { TalkButton } from '../containers/TalkButton';
import { getString } from '../../../services/api/store';

const ContactButtons = ({ theme, scheduleMeeting, openChat, profileData }) => (
    <PadddingLeftRight16px>
        <Button
            type={buttonTypes.GREEN_LONG}
            background={theme.primary}
            text={getString('profileStartMeetingButton') || 'Meeting'}
            icon={palette.ICON_ADD}
            onClick={scheduleMeeting}
            enabled={profileData.chatAvailable}
        />
        <MarginTop12px />
        <TalkButton theme={theme} openChat={openChat} user={profileData} />
    </PadddingLeftRight16px>
);

export default ContactButtons;

import get from 'lodash/get';
import reject from 'lodash/reject';
import { normalize, schema } from 'normalizr';
import { socialFeedApi } from '../api';
import { getLocalAppStateAsync } from '../../../services/api/db';
import Auth from '../../../services/api/auth';

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

const createRequestTypes = base => {
    const types = [REQUEST, SUCCESS, FAILURE];
    return types.reduce((acc, type) => {
        acc[type] = `${base}_${type}`;
        return acc;
    }, {});
};

export const LOAD_POSTS = createRequestTypes('LOAD_POSTS');
export const LOAD_POST = createRequestTypes('LOAD_POST');
export const ADD_COMMENT = createRequestTypes('ADD_COMMENT');
export const ADD_LIKE = createRequestTypes('ADD_LIKE');
export const ADD_POST = createRequestTypes('ADD_POST');
export const UPDATE_POST = createRequestTypes('UPDATE_POST');
export const REMOVE_LIKE = createRequestTypes('REMOVE_LIKE');
export const REMOVE_POST = createRequestTypes('REMOVE_POST');
export const REPORT_POST = createRequestTypes('REPORT_POST');
export const RESET_SOCIALFEED_STATE = 'RESET_SOCIALFEED_STATE_ACTION';

// Schemas
const user = new schema.Entity('users');

const like = new schema.Entity('likes', {
    User: user,
});

const comment = new schema.Entity('comments', {
    User: user,
});

const postProcessStrategy = value => {
    if (!value.hidden) {
        const comments = value.comments.sort((a, b) => new Date(a.time) - new Date(b.time));
        return {
            ...value,
            comments,
        };
    }
    return false;
};

const post = new schema.Entity(
    'posts',
    {
        User: user,
        comments: [comment],
        likes: [like],
    },
    {
        processStrategy: postProcessStrategy,
    },
);

// Actions
export const resetState = () => async dispatch => {
    dispatch({
        type: RESET_SOCIALFEED_STATE,
    });
};

export const loadPosts = replace => async (dispatch, getState) => {
    dispatch({ type: LOAD_POSTS.REQUEST, payload: { replace } });

    try {
        const state = getState();
        const currentEvent = await getLocalAppStateAsync();
        const eventId = currentEvent.id;
        const lastPostId = get(state, 'socialFeed.lastPostId');
        const lastId = replace ? null : lastPostId;

        const response = await socialFeedApi.eventFeed({ lastObjectId: lastId, eventId });

        const payload = {
            replace,
        };
        if (response && response.length) {
            const data = normalize(response, [post]);
            payload.lastPostId = data.result[data.result.length - 1];
            payload.entities = data.entities;
        } else {
            payload.entities = {};
            payload.lastPostId = null;
        }

        dispatch({
            type: LOAD_POSTS.SUCCESS,
            payload,
        });
    } catch (error) {
        dispatch({
            type: LOAD_POSTS.FAILURE,
            payload: { error },
        });
    }
};

export const makeComment = (feed, message) => async (dispatch, getState) => {
    const currentEvent = await getLocalAppStateAsync();
    const eventId = currentEvent.id;

    dispatch({ type: ADD_COMMENT.REQUEST });

    try {
        const response = await socialFeedApi.addComment({ feed, message, eventId });

        if (response && response.id) {
            response.User = Auth.getUser();
            const data = normalize(response, comment);
            const feedComments = [...feed.comments, response.id];
            const currentPost = {
                ...feed,
                comments: feedComments,
            };
            dispatch({
                type: ADD_COMMENT.SUCCESS,
                payload: {
                    entities: data.entities,
                    currentPost,
                },
            });
        }
    } catch (error) {
        dispatch({
            type: ADD_COMMENT.FAILURE,
            payload: { error },
        });
    }
};

export const addLike = feed => async (dispatch, getState) => {
    const currentEvent = await getLocalAppStateAsync();
    const eventId = currentEvent.id;

    dispatch({ type: ADD_LIKE.REQUEST });

    try {
        const response = await socialFeedApi.eurekaLike({ feed, eventId });

        if (response && response.id) {
            response.User = Auth.getUser();
            const data = normalize(response, like);
            const feedLikes = [...feed.likes, response.id];
            const currentPost = {
                ...feed,
                likes: feedLikes,
            };
            dispatch({
                type: ADD_LIKE.SUCCESS,
                payload: {
                    entities: data.entities,
                    currentPost,
                },
            });
        }
    } catch (error) {
        dispatch({
            type: ADD_LIKE.FAILURE,
            payload: { error },
        });
    }
};

export const removeLike = (feed, likeId) => async (dispatch, getState) => {
    const state = getState();
    const currentEvent = await getLocalAppStateAsync();
    const eventId = currentEvent.id;

    dispatch({ type: REMOVE_LIKE.REQUEST });

    try {
        const likeObject = get(state, `socialFeed.likes.${likeId}`);
        const response = await socialFeedApi.removeActivity({ feed: likeObject, eventId });

        if (response) {
            const feedLikes = reject(feed.likes, o => o === likeObject.id);
            const currentPost = {
                ...feed,
                likes: feedLikes,
            };
            dispatch({
                type: REMOVE_LIKE.SUCCESS,
                payload: {
                    currentPost,
                    toRemove: likeId,
                },
            });
        }
    } catch (error) {
        dispatch({
            type: REMOVE_LIKE.FAILURE,
            payload: { error },
        });
    }
};

export const removePost = feed => async (dispatch, getState) => {
    dispatch({ type: REMOVE_POST.REQUEST });

    try {
        const state = getState();
        const currentEvent = await getLocalAppStateAsync();
        const eventId = currentEvent.id;

        const response = await socialFeedApi.removeActivity({ feed, eventId });

        if (response) {
            dispatch({
                type: REMOVE_POST.SUCCESS,
                payload: {
                    toRemove: feed.id,
                },
            });
        }
    } catch (error) {
        dispatch({
            type: REMOVE_POST.FAILURE,
            payload: { error },
        });
    }
};

export const reportPost = feed => async (dispatch, getState) => {
    const state = getState();
    const currentEvent = await getLocalAppStateAsync();
    const eventId = currentEvent.id;

    dispatch({ type: REPORT_POST.REQUEST });

    try {
        const response = await socialFeedApi.reportActivity({ activity: feed, eventId });

        if (response) {
            dispatch({
                type: REPORT_POST.SUCCESS,
            });
            // dispatch(setSnackbarMessage(LS.translate('socialWallReportPostMessage')));
        }
    } catch (error) {
        dispatch({
            type: REPORT_POST.FAILURE,
            payload: { error },
        });
    }
};

export const createOrUpdatePost = (object, onPostSuccess) => async (dispatch, getState) => {
    const currentEvent = await getLocalAppStateAsync();
    const eventId = currentEvent.id;

    if (object.id) {
        dispatch({ type: UPDATE_POST.REQUEST });

        try {
            const response = await socialFeedApi.updateActivity({ object, eventId });

            if (response && response.id) {
                dispatch({
                    type: UPDATE_POST.SUCCESS,
                    payload: {
                        currentPost: object,
                    },
                });
                onPostSuccess();
            }
        } catch (error) {
            dispatch({
                type: UPDATE_POST.FAILURE,
                payload: { error },
            });
        }
    } else {
        dispatch({ type: ADD_POST.REQUEST });

        try {
            const response = await socialFeedApi.addPost({
                message: object.message,
                imageUrl: object.image,
                eventId,
            });

            if (response && response.id) {
                response.User = Auth.getUser();
                response.comments = [];
                response.likes = [];
                response.id = response.getStreamId;
                const data = normalize(response, post);
                dispatch({
                    type: ADD_POST.SUCCESS,
                    payload: {
                        entities: data.entities,
                    },
                });
                onPostSuccess();
            }
        } catch (error) {
            dispatch({
                type: ADD_POST.FAILURE,
                payload: { error },
            });
        }
    }
};

export const getPostByObjectId = ({ objectId, ownerId }) => async dispatch => {
    dispatch({ type: LOAD_POSTS.REQUEST });

    try {
        const response = await socialFeedApi.getPostByObjectId({ objectId, ownerId });
        const payload = {};

        if (response && response.id) {
            const responseParsed = [
                {
                    ...response,
                    id: response.getStreamId,
                },
            ];
            const data = normalize(responseParsed, [post]);
            payload.entities = data.entities;
        } else {
            payload.entities = {};
        }

        dispatch({
            type: LOAD_POST.SUCCESS,
            payload,
        });
    } catch (error) {
        dispatch({
            type: LOAD_POST.FAILURE,
            payload: { error },
        });
    }
};

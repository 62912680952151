import get from 'lodash/get';
import React, { useContext, useEffect, useState } from 'react';
import { Button, DialogContainer } from 'react-md';
import { withRouter } from 'react-router-dom';
import { navigateTo } from '../Navigation/NavigationLink';
import styled from 'styled-components';
import { isUserBlockedBySessionIdAndUserId } from '../../scenes/VirtualSession/services/virtualEventBlockedUsers';
import Auth from '../../services/api/auth';
import WarningDialog from '../Dialog/WarningDialog';
import * as palette from '../General/Variables';
import AuthUserContext from '../Session/AuthUserContext';
import { useTheme } from '../Theme/ThemeContext';
import AnalyticsService from '../../features/analytics/services';
import { canJoinRoundTable, getVirtualEventSession } from '../../services/api/eureka';
import { useGlobalMutation, useGlobalState } from '../../utils/container';
import CustomButton, { buttonTypes, Tooltip } from '../../scenes/common/Button';
import { getString } from '../../services/api/store';
import BookmarkButton from '../General/BookmarkButton';
import { checkIfUserHasAuthorizationByTypeRestrictions } from '../../scenes/AccessRestrictions/utils';
import { ButtonsContainer } from '../DetailHeader/index';
import {
    AchievementType,
    useAchievementActions,
} from '../../scenes/Achievements/hooks/useAchievementActions';
import CheckinButton from './CheckinButton';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

const TYPE_EXTERNAL = 'External';
const TYPE_APPOINTMENT_VIRTUAL_ROOT = 'Appointment virtual room';

const ButtonStyled = styled(Button)`
    border: 1px solid ${props => props.primarycolor} !important;
    margin-left: 16px !important;
    margin-top: 27px !important;
    margin-bottom: 27px !important;
    border-radius: ${props => (props.theming === 'inversed' ? '2px' : '4px')} !important;
    color: ${props =>
        props.styled && props.styled.fullRoom
            ? 'rgba(0, 0, 0, 0.38)'
            : props.theming === 'inversed'
            ? props.primarycolor
            : palette.COLOR_WHITE} !important;

    background-color: ${props =>
        props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor} !important;
    display: block;

    &.md-btn--dialog,
    &.md-btn--text {
        text-transform: none;
        font-size: 16px;
        height: 36px;
        font-weight: 500;
        padding: 8px 16px;
    }

    span {
        display: flex;
        align-items: center;
    }

    &:hover {
        background-color: ${props =>
            props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor};
    }
`;

const Paragraph = styled.p`
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
`;

const DialogContainerStyled = styled(DialogContainer)`
    .md-dialog-content--padded {
        padding-bottom: 0;
    }

    .md-dialog-footer--inline {
        padding: 0 15px;
    }
`;

const StyledChip = styled.div`
    text-align: center;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: ${props => props.color};
    background-color: #fff !important;
    border-radius: 4px;
    width: fit-content;
    min-width: 32px;
    height: 24px;
    white-space: nowrap;
    letter-spacing: 0.42px;
    padding: 4px 8px;
    margin-right: ${props => props.rightMargin || '8px'};
    margin-left: auto;
    position: relative;

    &:hover {
        filter: brightness(90%);
    }
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`;

const EnterVirtualSessionContainer = styled(FlexContainer)`
    fontfamily: 'Roboto, sans-serif';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #757575;
    width: 100%;
`;

const getTranslatedRole = role => {
    const ATTENDEE_ROLE = getString('virtualVideoIsAttendeeSuffix') || 'attendee';
    const MODERATOR_ROLE = getString('virtualVideoIsModeratorSuffix') || 'moderator';
    const SPEAKER_ROLE = getString('virtualVideoIsSpeakerSuffix') || 'speaker';
    const HOST_ROLE = getString('virtualVideoIsHostSuffix') || 'host';
    return role === 'moderator'
        ? MODERATOR_ROLE.toLowerCase()
        : role === 'speaker'
        ? SPEAKER_ROLE.toLowerCase()
        : role === 'host'
        ? HOST_ROLE.toLowerCase()
        : ATTENDEE_ROLE.toLowerCase();
};

const EnterVirtualSessionButton = withRouter(
    ({
        setModalVisible,
        enterVirtualroom,
        type,
        isExternalLink,
        virtualEventSession,
        timeslotId,
        customButton,
        customButtonType,
        customButtonText,
        match,
        history,
        location,
        joinSessionRole,
        setJoinSessionRole,
        currentVirtualUserRole,
        isSessionOpen,
        isAppointmentVirtualRoom,
        hasCheckin,
        usersJoinedState,
        requestSignIn,
        pdfPagesNumber,
    }) => {
        const [usersJoined, setUsersJoined] = useState(usersJoinedState);
        const [chipHover, setChipHover] = useState(false);

        const { theme } = useTheme();
        const currentUser = Auth.getUser();
        const stateCtx = useGlobalState();
        const { socket } = stateCtx;
        const ENTER_ROOM_TEXT = getString('virtualEnterVirtualRoom') || 'Enter virtual room';
        const CLOSED_ROOM_TEXT = getString('virtualRoomIsClosed') || 'Virtual room is closed';
        const RECORDING_ROOM_TEXT = getString('virtualRoomViewRecording') || 'View Recording';
        const ATTENDEE_ROLE = getString('virtualVideoIsAttendeeSuffix') || 'attendee';
        const JOIN_AS_TEXT = getString('joinAs') || 'Join as';
        const DEFAULT_ON_DEMAND_BUTTON_TEXT_POSTER = getString('viewPosterWeb') || 'View poster';
        const DEFAULT_ON_DEMAND_BUTTON_TEXT_SLIDES = getString('viewSlidesWeb') || 'View slides';
        const ON_DEMAND_BUTTON_TEXT =
            pdfPagesNumber > 1
                ? DEFAULT_ON_DEMAND_BUTTON_TEXT_SLIDES
                : DEFAULT_ON_DEMAND_BUTTON_TEXT_POSTER;
        const { trackAchievement } = useAchievementActions();
        const authUser = Auth.getUser();
        const { showSignIn } = useContext(AuthUserContext);
        const broadcasting = virtualEventSession && virtualEventSession.status === 'broadcasting';
        const isRoundTable = virtualEventSession && virtualEventSession.roomType === 'roundTable';
        const showLive = isSessionOpen && broadcasting;
        const fullRoom = currentUser && isRoundTable && usersJoined === 15;
        let currentUserRoleTranslated;
        if (currentVirtualUserRole) {
            currentUserRoleTranslated = getTranslatedRole(currentVirtualUserRole);
        }
        let joinSessionRoleTranslated;
        if (joinSessionRole) {
            joinSessionRoleTranslated = getTranslatedRole(joinSessionRole);
        }

        let buttonText = ENTER_ROOM_TEXT;
        if (type === 'on-demand') {
            buttonText = RECORDING_ROOM_TEXT;
            if (isRoundTable && pdfPagesNumber) {
                buttonText = ON_DEMAND_BUTTON_TEXT;
            }
        }

        if (isRoundTable && type === 'virtual' && !broadcasting && pdfPagesNumber) {
            buttonText = ON_DEMAND_BUTTON_TEXT;
        }

        let dropdownOptions;
        if (!isSessionOpen) {
            dropdownOptions = [`${JOIN_AS_TEXT} ${currentUserRoleTranslated}`];
        } else {
            if (type === 'on-demand') {
                dropdownOptions = [buttonText, `${JOIN_AS_TEXT} ${currentUserRoleTranslated}`];
            } else {
                dropdownOptions = [
                    `${JOIN_AS_TEXT} ${currentUserRoleTranslated}`,
                    `${JOIN_AS_TEXT} ${ATTENDEE_ROLE.toLowerCase()}`,
                ];
            }
        }

        useEffect(() => {
            socket.on(`updateRoundTableCount_${timeslotId}`, checkCapacityWithTimeout);

            return () => {
                if (socket) {
                    socket.off(`updateRoundTableCount_${timeslotId}`, checkCapacityWithTimeout);
                    socket.removeAllListeners(`updateRoundTableCount_${timeslotId}`);
                }
            };
        }, []);

        useEffect(() => {
            (async () => {
                await checkCapacity();
            })();
        }, [currentUser]);

        const checkCapacity = async () => {
            if (isRoundTable && currentUser && broadcasting) {
                try {
                    const response = await canJoinRoundTable(timeslotId, currentUser.id);
                    const numberOfUsers = response.users;

                    if (numberOfUsers !== usersJoined) {
                        setUsersJoined(numberOfUsers);
                    }
                } catch (err) {
                    setUsersJoined(15);
                }
            }
        };

        const checkCapacityWithTimeout = () => {
            setTimeout(checkCapacity, 2000);
        };

        const handleEnterVirtualRoom = (e, requestSignIn) => {
            if (requestSignIn) {
                showSignIn(e, {
                    match,
                    history,
                    location,
                });
                return;
            }
            if (fullRoom) {
                if (authUser) {
                    trackAchievement(AchievementType.ENTER_FULL_CAPACITY_ROOM, timeslotId);
                }
                return;
            }

            if (authUser || isExternalLink) {
                if (
                    window.location.pathname.indexOf('/virtual-session/') > -1 ||
                    window.location.pathname.indexOf('/virtual-session-round/') > -1 ||
                    window.location.pathname.indexOf('/virtual-beamer-view/') > -1
                ) {
                    setModalVisible(true);
                } else {
                    enterVirtualroom(!isAppointmentVirtualRoom && joinSessionRole);
                }
            } else {
                showSignIn(e, {
                    match,
                    history,
                    location,
                });
            }
        };

        const changeSessionRole = val => {
            if (!val.includes(currentUserRoleTranslated)) {
                setJoinSessionRole('attendee');
            } else {
                setJoinSessionRole(currentVirtualUserRole);
            }
        };

        return (
            <EnterVirtualSessionContainer>
                {customButton ? (
                    <CustomButton
                        type={customButtonType}
                        background={theme.primary}
                        text={customButtonText}
                        onClick={handleEnterVirtualRoom}
                    />
                ) : (
                    <CustomButton
                        type={fullRoom ? buttonTypes.GREY_LONG : buttonTypes.GREEN_LONG}
                        background={theme.primary}
                        text={
                            !isAppointmentVirtualRoom && joinSessionRole
                                ? type === 'on-demand' && joinSessionRole === 'attendee'
                                    ? buttonText
                                    : `${JOIN_AS_TEXT} ${joinSessionRoleTranslated}`
                                : buttonText
                        }
                        onClick={e => handleEnterVirtualRoom(e, requestSignIn)}
                        tooltip={fullRoom && CLOSED_ROOM_TEXT}
                        icon={'videocam'}
                        enabled={!fullRoom}
                        style={{
                            padding: currentVirtualUserRole
                                ? '10px 0 10px 20px'
                                : isRoundTable && broadcasting && currentUser && '10px 0',
                            whiteSpace: 'nowrap',
                        }}
                        chip={
                            isRoundTable && broadcasting && currentUser ? (
                                <StyledChip
                                    color={fullRoom ? 'rgba(0, 0, 0, 0.38)' : theme.primary}
                                    onMouseEnter={() => {
                                        setChipHover(true);
                                    }}
                                    onMouseLeave={() => setChipHover(false)}
                                    onClick={e => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <FlexContainer>{`${usersJoined} / 15`}</FlexContainer>
                                    {chipHover && (
                                        <Tooltip left={'-1px'} bottom={'42px'}>
                                            {15 - `${usersJoined}`} spots left
                                        </Tooltip>
                                    )}
                                </StyledChip>
                            ) : (
                                showLive && (
                                    <StyledChip
                                        rightMargin={!joinSessionRole ? '-16px' : '-8px'}
                                        color={theme.primary}
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <FlexContainer>LIVE</FlexContainer>
                                    </StyledChip>
                                )
                            )
                        }
                        dropdown={
                            !isAppointmentVirtualRoom &&
                            !!currentVirtualUserRole &&
                            currentVirtualUserRole !== 'host' &&
                            isSessionOpen
                        }
                        dropdownOptions={dropdownOptions}
                        disabledOptions={!isSessionOpen && ['attendee']}
                        activeOptionDropdown={
                            type === 'on-demand' && joinSessionRole === 'attendee'
                                ? buttonText
                                : `${JOIN_AS_TEXT} ${joinSessionRoleTranslated}`
                        }
                        setActiveOptionDropdown={changeSessionRole}
                    />
                )}
            </EnterVirtualSessionContainer>
        );
    },
);

const EnterVirtualSesssionClosedButton = ({ timeslotId }) => {
    const { trackAchievement } = useAchievementActions();

    const handleClick = () => {
        trackAchievement(AchievementType.CLICK_CLOSED_ROOM_BUTTON, timeslotId);
    };

    const CLOSED_ROOM_TEXT = getString('virtualRoomIsClosed') || 'Virtual room is closed';
    const ENTER_ROOM_TEXT = getString('virtualEnterVirtualRoom') || 'Enter virtual room';

    return (
        <CustomButton
            type={buttonTypes.GREY_LONG}
            text={ENTER_ROOM_TEXT}
            icon={'videocam'}
            tooltip={CLOSED_ROOM_TEXT}
            enabled={false}
            onClick={handleClick}
        />
    );
};

const TimeslotVirtualSession = ({
    match,
    history,
    location,
    id,
    params,
    virtualEventSession,
    type,
    customButton,
    customButtonType,
    customButtonText,
    favoriteStatus,
    clickMyCongress,
    notesButton,
    notesButtonLarge,
    typeParams,
    currentUserGroups,
    hasCheckin,
    moreButton,
    pdf,
}) => {
    const mutationCtx = useGlobalMutation();
    const [modalVisible, setModalVisible] = useState(false);
    const [blockedUser, setBlockedUser] = useState(false);
    const [restrictedUser, setRestrictedUser] = useState(false);
    const [requestSignIn, setRequestSignIn] = useState(false);
    const [joinSessionRole, setJoinSessionRole] = useState(false);
    const [pdfPagesNumber, setPdfPagesNumber] = useState(0);
    const usersJoinedState = useState(0);
    const { showSignIn } = useContext(AuthUserContext);

    const DEFAULT_ON_DEMAND_BUTTON_TEXT_POSTER = getString('viewPosterWeb') || 'View poster';
    const DEFAULT_ON_DEMAND_BUTTON_TEXT_SLIDES = getString('viewSlidesWeb') || 'View slides';
    const ON_DEMAND_BUTTON_TEXT =
        pdfPagesNumber > 1
            ? DEFAULT_ON_DEMAND_BUTTON_TEXT_SLIDES
            : DEFAULT_ON_DEMAND_BUTTON_TEXT_POSTER;

    const { theme } = useTheme();
    const {
        virtualSession,
        virtualSessionType,
        virtualSessionExternalLink,
        onDemandRecType,
        onDemandExternalLink,
        onDemandLink,
        onDemandLinkScreen,
    } = params;

    const isVirtualSession = params && params.virtualSession && params.virtualSession === 'virtual';
    const isExternalLink =
        virtualSessionType === TYPE_EXTERNAL || onDemandRecType === TYPE_EXTERNAL;
    const isExternalOpen = !!(isExternalLink && virtualSession !== '');
    const isAppointmentVirtualRoom = virtualSessionType === TYPE_APPOINTMENT_VIRTUAL_ROOT;
    const isOnDemandRecording = virtualSession === 'on-demand';

    const isAuthenticated = Auth.isUserAuthenticated();
    const isRoundTable = virtualEventSession && virtualEventSession.roomType === 'roundTable';
    const isVirtualRoom = virtualEventSession && virtualEventSession?.roomType;

    const isSessionOpen = !!(
        isExternalOpen ||
        isAppointmentVirtualRoom ||
        virtualSession === 'on-demand' ||
        (virtualEventSession && virtualEventSession.status !== 'closed')
    );

    const authUser = Auth.getUser();
    const virtualUsers = virtualEventSession?.VirtualEventUsers || [];
    const currentVirtualUser = virtualUsers.find(
        virtualUser => virtualUser.UserId === authUser?.id,
    );
    const currentVirtualUserRole =
        currentVirtualUser?.role === 'roundTableHost'
            ? 'host'
            : currentVirtualUser?.role === 'presenter'
            ? 'speaker'
            : currentVirtualUser?.role;

    useEffect(() => {
        if (currentVirtualUserRole !== joinSessionRole) {
            if (virtualSession === 'on-demand') {
                setJoinSessionRole('attendee');
            } else {
                setJoinSessionRole(currentVirtualUserRole);
            }
        }
    }, [currentVirtualUserRole]);

    useEffect(() => {
        const canUserAccessSession = checkIfUserHasAuthorizationByTypeRestrictions(
            typeParams?.virtualRoomRestrictedGroup,
            currentUserGroups,
        );
        setRestrictedUser(!canUserAccessSession);
        const { virtualRoomRestrictedGroup } = typeParams || {};

        const signInRequired = virtualRoomRestrictedGroup === 'eurekaOnly';
        if (!isAuthenticated && signInRequired && isExternalLink) {
            setRequestSignIn(true);
            setRestrictedUser(false);
        } else {
            const virtualRoomRestrictedGroupParsed =
                virtualRoomRestrictedGroup !== 'eurekaOnly' ? !canUserAccessSession : false;
            setRestrictedUser(virtualRoomRestrictedGroupParsed);
        }
    }, [typeParams, currentUserGroups]);

    useEffect(() => {
        (async () => {
            try {
                if (!(pdf && (pdf.url || pdf.uploadedPresentationUrl))) {
                    return;
                }
                pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
                const pdfUrl = await pdfjsLib.getDocument(pdf?.uploadedPresentationUrl || pdf?.url)
                    .promise;
                setPdfPagesNumber(pdfUrl?._pdfInfo?.numPages);
            } catch (err) {
                console.log(err);
            }
        })();
    }, [pdf]);

    const openUrl = (path, joinSessionRole, id, eventId) => {
        if (isExternalLink) {
            const url =
                virtualSession === 'on-demand' ? onDemandExternalLink : virtualSessionExternalLink;

            return window.open(url, '_blank');
        }

        if (virtualEventSession && virtualEventSession.status === 'broadcasting') {
            AnalyticsService.addSample('viewVirtualRoom', path, id);
        }

        if (joinSessionRole && joinSessionRole !== 'attendee') {
            navigateTo({
                match,
                history,
                location,
                path: `/virtual-moderation/${id}/${eventId}`,
                state: {
                    keepHistoryLength: true,
                },
            });
        } else {
            navigateTo({
                match,
                history,
                location,
                path: `/${path}/${id}`,
                state: {
                    keepHistoryLength: true,
                },
            });
        }
    };

    const getPath = virtualEventSession => {
        if (!virtualEventSession || !virtualEventSession.roomType) {
            return '';
        }

        switch (virtualEventSession.roomType) {
            case 'roundTable':
                return 'virtual-session-round';
            case 'virtual':
            case 'virtual-room':
                return 'virtual-session-room';
            default:
                return 'virtual-session';
        }
    };

    const enterVirtualRoom = async (joinSessionRole, isOnDemandPosterRoom = false) => {
        const user = Auth.getUser();
        const virtualEventSession = await getVirtualEventSession(id);
        const roomType = virtualEventSession ? virtualEventSession.roomType : '';

        if (virtualEventSession && roomType === 'roundTable') {
            const participants = get(virtualEventSession, 'VirtualEventUsers') || [];

            if (participants.length > 15) {
                mutationCtx.setShowCapacityModal(true);
                return;
            }

            const inTheSession =
                virtualSession !== 'on-demand' &&
                participants.find(
                    virtualUser =>
                        virtualUser.UserId === user.id && virtualUser.role !== 'roundTableHost',
                );

            if (inTheSession) {
                mutationCtx.setShowAlreadyInTheSessionModal(true);
                return;
            }
        }

        let path =
            getPath(virtualEventSession) || getPath({ roomType: params && params.virtualSession });
        const eventId = virtualEventSession?.ExternalObject?.Event?.reference;
        if (roomType === 'roundTable' && isOnDemandPosterRoom) {
            path = 'on-demand-poster-room';
        }

        if (user && user.id && virtualEventSession && virtualEventSession.id) {
            const isBlocked =
                virtualEventSession &&
                (await checkIfUserIsBlocked(virtualEventSession.id, user.id));

            if (isBlocked) {
                setBlockedUser(true);
            } else {
                openUrl(path, joinSessionRole, id, eventId);
            }
        } else if (isExternalLink) {
            openUrl(path, joinSessionRole, id, eventId);
        } else if (
            !virtualEventSession ||
            (!virtualEventSession.roomType && path === 'virtual-session-room')
        ) {
            if (joinSessionRole && joinSessionRole !== 'attendee') {
                navigateTo({
                    match,
                    history,
                    location,
                    path: `/virtual-moderation/${id}/${eventId}`,
                    state: {
                        keepHistoryLength: true,
                    },
                });
            } else {
                navigateTo({
                    match,
                    history,
                    location,
                    path: `/${path}/${id}`,
                    state: {
                        keepHistoryLength: true,
                    },
                });
            }
        }
    };

    const checkIfUserIsBlocked = async (sessionId, userId) => {
        const { blocked } = await isUserBlockedBySessionIdAndUserId(sessionId, userId);

        return blocked;
    };

    const bookmarkedButtonSmall = (
        <BookmarkButton
            favoriteStatus={favoriteStatus}
            clickMyCongress={clickMyCongress}
            smallBookmarkButton={true}
        />
    );
    const bookmarkedButtonLarge = (
        <BookmarkButton favoriteStatus={favoriteStatus} clickMyCongress={clickMyCongress} />
    );

    if (restrictedUser) {
        const ENTER_ROOM_TEXT = getString('virtualEnterVirtualRoom') || 'Enter virtual room';
        const RECORDING_ROOM_TEXT = getString('virtualRoomViewRecording') || 'View Recording';

        const VIRTUAL_ROOM_NO_PERMISSION_TEXT = getString(
            'noPermissionVirtualRoom',
            'You do not have permission to enter this virtual room.',
        );
        const RECORDINGS_NO_PERMISSION_TEXT = getString(
            'noPermissionRecordings',
            'You do not have permission to see these recordings.',
        );

        let buttonText = ENTER_ROOM_TEXT;
        let tooltipText = VIRTUAL_ROOM_NO_PERMISSION_TEXT;
        if (virtualSession === 'on-demand') {
            buttonText = RECORDING_ROOM_TEXT;
            tooltipText = RECORDINGS_NO_PERMISSION_TEXT;
        }

        const RestrictedVirtualSessionButton = () => (
            <CustomButton
                type={buttonTypes.GREEN_LONG}
                text={buttonText}
                background="rgba(0, 0, 0, 0.38)"
                enabled={false}
                icon={'lock'}
                style={{ pointer: 'default' }}
                tooltip={tooltipText}
                tooltipPosition="top"
            />
        );

        return (
            <>
                {virtualSession && hasCheckin && (
                    <ButtonsContainer>
                        <RestrictedVirtualSessionButton />
                    </ButtonsContainer>
                )}
                <ButtonsContainer alignItems={'center'} justifyContent={'space-between'}>
                    {virtualSession && !hasCheckin && <RestrictedVirtualSessionButton />}
                    {hasCheckin && <CheckinButton id={id} type={type} />}
                    {virtualSession && !hasCheckin ? bookmarkedButtonSmall : bookmarkedButtonLarge}
                    {virtualSession || hasCheckin ? notesButton : notesButtonLarge}
                </ButtonsContainer>
            </>
        );
    }

    const ViewRecordingNonVirtualRoomButton = () => (
        <CustomButton
            type={buttonTypes.GREEN_LONG}
            background={theme.primary}
            icon={'videocam'}
            text={'View Recordings'}
            onClick={() =>
                navigateTo({
                    match,
                    history,
                    location,
                    path: `/video/${type}/${id}`,
                })
            }
        />
    );

    if (isOnDemandRecording && (onDemandLink || onDemandLinkScreen) && !virtualEventSession) {
        return (
            <>
                {hasCheckin && (
                    <ButtonsContainer alignItems={'center'} justifyContent={'space-between'}>
                        <ViewRecordingNonVirtualRoomButton />
                    </ButtonsContainer>
                )}
                <ButtonsContainer alignItems={'center'} justifyContent={'space-between'}>
                    {hasCheckin ? (
                        <CheckinButton id={id} type={type} />
                    ) : (
                        <ViewRecordingNonVirtualRoomButton />
                    )}
                    {hasCheckin ? bookmarkedButtonLarge : bookmarkedButtonSmall}
                    {notesButton}
                </ButtonsContainer>
            </>
        );
    }

    const handleEnterVirtualRoom = (e, isOnDemandPosterRoom = false) => {
        if (authUser || isExternalLink) {
            if (
                window.location.pathname.indexOf('/virtual-session/') > -1 ||
                window.location.pathname.indexOf('/virtual-session-round/') > -1 ||
                window.location.pathname.indexOf('/on-demand-poster-room/') > -1 ||
                window.location.pathname.indexOf('/virtual-beamer-view/') > -1
            ) {
                setModalVisible(true);
            } else {
                enterVirtualRoom(
                    !isAppointmentVirtualRoom && joinSessionRole,
                    isOnDemandPosterRoom,
                );
            }
        } else {
            showSignIn(e, {
                match,
                history,
                location,
            });
        }
    };

    const PosterButton = () => {
        if (!isVirtualSession) {
            return null;
        }

        if (!isVirtualRoom && pdf && pdf.url) {
            return (
                <a
                    href={pdf.url}
                    style={{ width: '100%', textDecoration: 'none', cursor: 'pointer' }}
                >
                    <CustomButton type={buttonTypes.GREEN_LONG} text={ON_DEMAND_BUTTON_TEXT} />
                </a>
            );
        }

        return (
            <CustomButton
                type={buttonTypes.GREEN_LONG}
                text={ON_DEMAND_BUTTON_TEXT}
                onClick={event => handleEnterVirtualRoom(event, true)}
            />
        );
    };

    if (!virtualSession) {
        return (
            <>
                {pdf && isRoundTable && (
                    <ButtonsContainer alignItems={'center'} justifyContent={'space-between'}>
                        <PosterButton />
                    </ButtonsContainer>
                )}
                <ButtonsContainer alignItems={'center'} justifyContent={'space-between'}>
                    {hasCheckin && <CheckinButton id={id} type={type} />}
                    <BookmarkButton
                        favoriteStatus={favoriteStatus}
                        clickMyCongress={clickMyCongress}
                    />
                    {moreButton}
                </ButtonsContainer>
            </>
        );
    }

    const VirtualSessionButton = () =>
        isExternalLink || (isVirtualRoom && (isSessionOpen || joinSessionRole)) ? (
            <EnterVirtualSessionButton
                key={`enter_virtual_session_${id}`}
                setModalVisible={setModalVisible}
                enterVirtualroom={enterVirtualRoom}
                virtualSessionType={virtualSessionType}
                type={virtualSession}
                isExternalLink={isExternalLink}
                virtualEventSession={virtualEventSession}
                timeslotId={id}
                customButton={customButton}
                customButtonType={customButtonType}
                customButtonText={customButtonText}
                restrictedUser={restrictedUser}
                hasCheckin={hasCheckin}
                joinSessionRole={joinSessionRole}
                setJoinSessionRole={setJoinSessionRole}
                currentVirtualUserRole={currentVirtualUserRole}
                isSessionOpen={isSessionOpen}
                isAppointmentVirtualRoom={isAppointmentVirtualRoom}
                usersJoinedState={usersJoinedState}
                requestSignIn={requestSignIn}
                pdfPagesNumber={pdfPagesNumber}
            />
        ) : (
            <>
                {pdf && (isRoundTable || !isVirtualRoom) ? (
                    <PosterButton />
                ) : (
                    <EnterVirtualSesssionClosedButton timeslotId={id} />
                )}
            </>
        );

    return (
        <div key={`enter-virtual-session-${id}`}>
            <DialogContainerStyled
                id="speed-boost"
                visible={modalVisible}
                title="Enter this virtual session?"
                onHide={() => setModalVisible(false)}
                aria-describedby="speed-boost-description"
                actions={[
                    <ButtonStyled
                        key="EVSC"
                        flat
                        primary
                        primarycolor={theme.primary}
                        theming={'inversed'}
                        onClick={() => setModalVisible(false)}
                    >
                        Cancel
                    </ButtonStyled>,
                    <ButtonStyled
                        key="EVSO"
                        flat
                        primary
                        primarycolor={theme.primary}
                        onClick={() => {
                            setModalVisible(false);
                            enterVirtualRoom();
                        }}
                    >
                        Open new session
                    </ButtonStyled>,
                ]}
                width={'360px'}
                modal
                portal
            >
                <Paragraph>
                    If you enter this virtual session, you will leave the current one.
                </Paragraph>
            </DialogContainerStyled>

            <WarningDialog
                open={blockedUser}
                title="You are blocked"
                content={
                    <Paragraph>
                        You cannot join this virtual session anymore because you have been blocked
                        by a moderator.
                    </Paragraph>
                }
                onClose={() => setBlockedUser(false)}
            />

            <ButtonsContainer alignItems={'flex-start'} justifyContent={'space-between'}>
                {hasCheckin ? (
                    <ButtonsContainer flexdirection={'column'} padding={'0px'}>
                        <VirtualSessionButton />
                        <CheckinButton id={id} type={type} style={{ marginTop: '12px' }} />
                    </ButtonsContainer>
                ) : (
                    <VirtualSessionButton />
                )}
                {bookmarkedButtonSmall}
                {moreButton}
            </ButtonsContainer>
        </div>
    );
};

export default withRouter(TimeslotVirtualSession);

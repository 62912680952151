import React from 'react';
import styled from 'styled-components';

import noResultsImg from '../../assets/images/no-messages.png';

import * as palette from '../../components/General/Variables';
import { getString } from '../../services/api/store';

const EmptyState = styled.div`
    text-align: center;
    padding: 32px;
    padding-top: 32px;
    border-top: 1px solid #e5e5e5;
    h4 {
        font-size: 16px;
        font-weight: 500;
        color: ${palette.COLOR_TEXT};
    }
    p {
        font-size: 14px;
        color: ${palette.COLOR_TEXT};
    }
`;

const PlaceholderImage = styled.div`
    background-image: url(${noResultsImg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 400px;
`;

const EMPTY_STATE_DEFAULT =
    'You are not talking to anybody yet. Depending on the features in this event or app, you can start a personal conversation through the attendee list or a group chat through a session or the topical chat tile.';

const Placeholder = () => {
    const TEXT_EMPTY_STATE = getString('talkMessageEmptyState') || EMPTY_STATE_DEFAULT;

    return (
        <EmptyState>
            <h4>{TEXT_EMPTY_STATE}</h4>
            <PlaceholderImage />
        </EmptyState>
    );
};

export default Placeholder;

import React, { createRef, useEffect, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import styled from 'styled-components';
import { getImageSourceAsync, getObjectClassWithIdAsync } from '../../services/api/db';
import NavigationLink from '../Navigation/NavigationLink';
import AnalyticsService from '../../features/analytics/services';
import useDimensions from '../../hooks/useDimensions';
import { translateCCLinkUrl } from '../../helper/CCLink';

const AStyled = styled.a`
    text-decoration: none !important;
`;

const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;
`;

const Span = styled.span``;

const Slideshow = ({ data, noIndicators, itemDuration = 5000 }) => {
    const ids = JSON.stringify(data.map(item => item._id));
    const [items, setItems] = useState([]);

    const properties = {
        duration: itemDuration,
        transitionDuration: 1000,
        infinite: true,
        indicators: noIndicators ? false : true,
        arrows: noIndicators ? false : true,
        onChange: (oldIndex, newIndex) => {
            const item = items[newIndex];
            const id = item && item.id;

            AnalyticsService.addSample('bannerImageView', id, id);
        },
    };

    const onImageClick = item => {
        const id = item && item.id;
        AnalyticsService.addSample('bannerImageClick', id, id);
    };

    useEffect(() => {
        const loadSlides = async () => {
            if (data && data.length) {
                const images = [];
                for (const image of data) {
                    let url;
                    let navParams;
                    let navPath;

                    if (image.url) {
                        const ccLinkData = await translateCCLinkUrl(image.url);
                        if (!ccLinkData) {
                            url = image.url;
                        } else {
                            navPath = ccLinkData.navPath;
                            navParams = ccLinkData.navParams;
                        }
                    }

                    if (image.linkType === 'object') {
                        const { objectClass, objectItem } = await getObjectClassWithIdAsync(
                            image.objectId,
                        );
                        navParams = {
                            type: 'detail',
                            objectClass: objectClass,
                            objectId: objectItem?.id,
                        };
                    } else if (image.linkType === 'page' && image.screen) {
                        navPath = `/${image.screen}`;
                    }

                    const result = await getImageSourceAsync(image._id);
                    if (result) {
                        images.push({
                            id: image._id,
                            src: result,
                            url,
                            navPath,
                            navParams,
                        });
                    }
                }

                setItems(images);
            } else {
                setItems([]);
            }
        };

        loadSlides();
    }, [ids]);

    const ref = createRef();
    ref.current =
        document.getElementById('container-wrapper') || document.getElementById('feed-wrapper');
    const { width: wrapperWidth } = useDimensions(ref);

    const renderImages = () => {
        return items.map((item, i) => {
            let imageElement;

            const style = {
                width: wrapperWidth,
                height: '100%',
            };

            if (item.url) {
                imageElement = (
                    <AStyled target="_blank" href={item.url} key={`${item.src}_${i}`}>
                        <img key={i} style={style} src={item.src} alt={`slide-item-${i}`} />
                    </AStyled>
                );
            } else if (item.navParams) {
                imageElement = (
                    <LinkStyled key={'link_' + i} params={item.navParams}>
                        <img key={i} style={style} src={item.src} alt={`slide-item-${i}`} />
                    </LinkStyled>
                );
            } else if (item.navPath) {
                imageElement = (
                    <LinkStyled key={'link_' + i} path={item.navPath}>
                        <img key={i} style={style} src={item.src} alt={`slide-item-${i}`} />
                    </LinkStyled>
                );
            } else {
                imageElement = <img key={i} style={style} src={item.src} alt={`slide-item-${i}`} />;
            }

            return (
                <Span key={i} onClick={() => onImageClick(item)}>
                    {imageElement}
                </Span>
            );
        });
    };

    if (items.length < 2) {
        properties.arrows = false;
        properties.indicators = false;
        properties.infinite = false;
    }

    return <Slide {...properties}>{renderImages()}</Slide>;
};

export default Slideshow;

import { eurekaRequest } from '../../../../../services/api/eureka';

export const getAttendeesByEventId = eventId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/virtualEventSession/attendees/${eventId}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const getAttendeesByEventIdPaginated = (eventId, limit, page) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/virtualEventSession/attendees/${eventId}?limit=${limit}&page=${page}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const getFullProfileById = id => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/users/fullProfile/${id}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

import React from 'react';
import styled from 'styled-components';
import { Tooltipped as MDTooltipped } from 'react-md';

const StyledTooltipped = styled(MDTooltipped)`
    .md-tooltip {
        padding: 8px 12px;
        border-radius: 6px;
        background-color: rgba(0, 0, 0, 0.87) !important;
        z-index: 999999;
    }
`;

const Tooltipped = ({ children, label, position = 'bottom', setPosition }) => {
    return (
        <StyledTooltipped label={label} position={position} setPosition={setPosition}>
            {children}
        </StyledTooltipped>
    );
};

export default Tooltipped;

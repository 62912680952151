import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Avatar, Button, Cell, Grid, ListItem } from 'react-md';

import * as palette from '../../../components/General/Variables';
import ThemeContext from '../../../components/Theme/ThemeContext';
import { getString } from '../../../services/api/store';

const InteractivityCell = styled(Cell)`
    text-align: left;
    width: 100% !important;
`;
const InteractivityGrid = styled(Grid)`
    padding-left: 10px;
    padding-right: 10px;
`;
const ButtonStyled = styled(Button)`
    border: 1px solid ${props => props.primarycolor} !important;
    border-radius: 24px !important;
    color: ${props =>
        props.theming === 'inversed' ? props.primarycolor : palette.COLOR_WHITE} !important;
    background-color: ${props =>
        props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor} !important;
    display: block;
    width: fit-content;
    margin-left: 3px;

    &.md-btn--dialog,
    &.md-btn--text {
        text-transform: none;
        font-size: 16px;
        height: 36px;
        font-weight: 500;
    }

    .md-icon-separator {
        justify-content: space-between;
        span {
            padding-left: 0;
            text-align: right;
        }
    }

    &.md-btn--dialog &:hover {
        background-color: ${props =>
            props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor};
    }
`;
const SectionTitle = styled.div`
    font-weight: 500;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-family: 'Cabin', sans-serif;
`;

const SectionText = styled.div`
    white-space: pre-wrap;
    word-break: break-word;
`;

const ChatContainer = styled.div`
    padding: 16px;
    border-radius: 4px;
    list-style-type: none;
    background-color: ${palette.COLOR_LIGHT_GREY};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    font-family: 'Roboto', sans-serif;

    & > div:nth-child(2) {
        margin: 8px 0;
        width: 100%;
    }
`;

const CustomAvatar = styled(Avatar)`
    background-color: ${props => props.avatarcolor || '#616161'};
    border: 0px solid rgba(0, 0, 0);
`;

const Item = styled(ListItem)`
    height: 56px;
    margin-left: -18px;
    margin-top: 4px;
    margin-bottom: 8px;

    & .md-list-tile--avatar {
        height: 56px;
    }

    & .md-avatar {
        border: none;
    }
`;

const getInitials = (firstName, lastName) => firstName[0].toUpperCase() + lastName[0].toUpperCase();

const UserChatCard = ({ clickHandler, messages, user }) => {
    const START = getString('profileStartConversationButton') || 'Start conversation';
    const VIEW = getString('groupChatOpenConversationButton') || 'View conversation';
    const CHAT_TITLE = getString('groupChatScreenTitle') || 'Chat';

    const [hasMessages, setHasMessages] = useState(false);
    const placeholder = getString('groupChatNoMessages') || 'No messages yet';

    useEffect(() => {
        if (messages && messages.length) {
            setHasMessages(true);
        }
    }, [messages]);

    const ChatButton = ({ primarycolor }) => (
        <ButtonStyled
            raised
            primary
            primarycolor={primarycolor}
            onClick={e => {
                if (clickHandler) {
                    clickHandler(e);
                }
            }}
            data-qa={`${hasMessages ? 'view' : 'start'}-conversation-button-${user.id}`}
        >
            {hasMessages ? VIEW : START}
        </ButtonStyled>
    );

    const renderMessages = () => {
        if (hasMessages) {
            return messages.map((m, index) => {
                if (m.lastMessage && m.lastMessage.participant) {
                    const { firstName, lastName, imageUrl } = m.lastMessage.participant;
                    let { txt: messageBody } = m.lastMessage;
                    const avatar = imageUrl ? (
                        <Avatar src={imageUrl} />
                    ) : (
                        <CustomAvatar>{getInitials(firstName, lastName)}</CustomAvatar>
                    );

                    try {
                        const textObj = JSON.parse(messageBody);
                        if (textObj.type === 'appointment') {
                            const { name, action } = textObj.data;
                            messageBody = `${name} ${action}`;
                        }
                    } catch (e) {
                        // message is plain text
                        if (messageBody === '__removed__') {
                            messageBody = 'Message deleted';
                        }
                    }

                    return (
                        <Item
                            key={`groupchat-attendee-item-${index}`}
                            leftAvatar={avatar}
                            primaryText={`${firstName} ${lastName}`}
                            secondaryText={messageBody}
                        />
                    );
                }
                return null;
            });
        } else {
            return <SectionText>{placeholder}</SectionText>;
        }
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <InteractivityGrid>
                    <InteractivityCell size={1} key="chat">
                        <ChatContainer>
                            <SectionTitle secondary={theme.secondary}>{CHAT_TITLE}</SectionTitle>
                            {renderMessages()}
                            <ChatButton primarycolor={theme.contrast} />
                        </ChatContainer>
                    </InteractivityCell>
                </InteractivityGrid>
            )}
        </ThemeContext.Consumer>
    );
};

export { UserChatCard };

import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import AuthUserContext from '../Session/AuthUserContext';
import ThemeContext from '../Theme/ThemeContext';
import * as palette from './Variables';
import { FontIcon } from 'react-md';
import IconButton from '@mui/material/IconButton';

const IconDiv = styled(IconButton)`
    float: right;
    width: 40px;
    height: 40px;
    right: 16px;
    top: 11px;
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        right: 8px;
    }
    &:hover {
        background: rgba(0, 0, 0, 0.04);
        border-radius: 20px;
    }

    &:active {
        background: rgba(0, 0, 0, 0.12);
        border-radius: 20px;
    }
`;

const Icon = styled(FontIcon)`
    font-size: 24px !important;
    color: ${props => (props.color ? props.color : '#000000')} !important;
    cursor: pointer;
`;

const BookmarkIcon = props => {
    if (!props.clickMyCongress) {
        return null;
    }
    const { match, history, location } = props;
    const onClick = (authUser, showSignIn) => {
        if (authUser) {
            return props.clickMyCongress;
        } else {
            return e => {
                showSignIn(e, {
                    match,
                    history,
                    location,
                });
            };
        }
    };

    return (
        <AuthUserContext.Consumer>
            {({ authUser, showSignIn }) => {
                return (
                    <ThemeContext.Consumer>
                        {({ theme }) => (
                            <React.Fragment>
                                {!theme.embed && !theme.kiosk && (
                                    // Icons' SVG code edited to remove fill color
                                    <IconDiv
                                        style={{ top: props.top + 'px', right: props.right + 'px' }}
                                        onClick={onClick(authUser, showSignIn)}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                onClick(authUser, showSignIn)(e);
                                            }
                                        }}
                                    >
                                        <Icon
                                            color={
                                                props.favoriteStatus === 'checked' && theme.primary
                                            }
                                        >
                                            {props.favoriteStatus === 'checked'
                                                ? 'bookmark'
                                                : 'bookmark_border'}
                                        </Icon>
                                    </IconDiv>
                                )}
                            </React.Fragment>
                        )}
                    </ThemeContext.Consumer>
                );
            }}
        </AuthUserContext.Consumer>
    );
};

export default withRouter(BookmarkIcon);

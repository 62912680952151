import { map, sortBy, groupBy } from 'lodash';
import moment from 'moment';

const monthsArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const sortSessions = function (sessions) {
    const isValid = function (i) {
        return i && i.object && i.object.title && i.object.title.length;
    };

    return sessions.sort(function (a, b) {
        if (!isValid(a)) {
            return true;
        } else if (!isValid(b)) {
            return false;
        } else {
            return a.object.title.toLowerCase() > b.object.title.toLowerCase();
        }
    });
};

export const groupByDate = function (sessions) {
    let timedSessions = [];
    let posterSessions = [];
    sessions.forEach(function (session) {
        if (session.object.start) {
            timedSessions.push(session);
        } else {
            posterSessions.push(session);
        }
    });

    timedSessions = sortBy(timedSessions, function (o) {
        return new moment(o.object.start);
    });

    const groups = groupBy(timedSessions, function (session) {
        return moment.utc(session.object.start).startOf('day').format('ddd - D MMM');
    });

    const result = map(groups, function (group, day) {
        return {
            day: day,
            elements: group,
        };
    });

    if (posterSessions.length) {
        result.push({
            day: 'No date',
            elements: posterSessions,
        });
    }

    return result;
};

export const getEventTimeFrame = (startTime, endTime) => {
    let startDate = new Date(startTime).getTime();
    let endDate = new Date(endTime).getTime();

    if (isNaN(startDate)) {
        startDate = new Date(Number(startTime)).getTime();
    }

    if (isNaN(endDate)) {
        endDate = new Date(Number(endTime)).getTime();
    }

    const finalStartTime = new Date(startDate);
    const finalEndTime = new Date(endDate);

    return `${
        monthsArray[finalStartTime.getMonth()]
    } ${finalStartTime.getDay()} - ${finalEndTime.getDay()} ${finalStartTime.getFullYear()}`;
};

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontIcon } from 'react-md';
import HowToVoteIcon from '@material-ui/icons/HowToVoteOutlined';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import get from 'lodash/get';

import { votesColorScheme } from './QuestionModal';
import VoteResult from './VoteResult';
import EnlargedImageModal from '../../virtualFeature/components/common/EnlargedImageModal';
import ProgressBar from './ProgressBar';
import { useVMState } from '../../virtualFeature/containers/main';

const VotingResultsWrapper = styled.div`
    background-color: #ffffff;
    z-index: 1;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 40px 0px 16px 0px;
    ${props =>
        props.isrecording &&
        css`
            position: absolute;
            min-width: 800px;
            max-width: 1000px;
            top: -1500px;
            left: 0;
        `}
`;

const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-bottom: 8px;
`;

const Img = styled.img`
    height: 100%;
    width: 100%;
    z-index: 10;
    object-fit: cover;
`;

const ImageContainer = styled.div`
    position: relative;
    height: 95px;
    width: 221px;
    border-radius: 8px;
    margin-right: 16px;
    overflow: hidden;
    cursor: pointer;
`;

const ZoomIconContainer = styled.div`
    position: absolute;
    bottom: 8px;
    left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.54);
    color: #fff;
    height: 32px;
    width: 40px;
    border-radius: 6px;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 16px 0;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 90px;
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${props =>
        props.nomarginleft ? (props.iscorrectanswer ? '27px' : '0') : '237px'};
`;

const StyledIcon = styled(FontIcon)`
    width: 18px;
    height: 21px;
    color: rgba(0, 0, 0, 0.87);
    margin-right: 8px;
`;

const SecondsText = styled.span`
    width: 28px;
    height: 18px;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin: 5px 0 0 0;
`;

const TotalVotesContainer = styled.div`
    position: absolute;
    top: 8px;
    right: 24px;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.86px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TotalVotesRecordingContainer = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.86px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const PlaceholderContainer = styled.div`
    width: fit-content;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f3;
    height: 24px;
    padding: 0 8px;
    border-radius: 16px;
`;

const PlaceholderText = styled.span`
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-family: Cabin, sans-serif;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.43px;
    text-align: center;
`;

const inlineStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
};

const VotingResults = ({
    activePoll,
    elRef,
    isCurrentUserActive,
    pollSet,
    imageUrl,
    nonVirtual,
}) => {
    const { title, seconds, useTimer } = activePoll;
    const [isImageHovered, setIsImageHovered] = useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [optionsWithVotes, setOptionsWithVotes] = useState([]);
    const isForRecording = !!elRef;

    const stateCtx = useVMState();
    const { socket } = stateCtx;

    useEffect(() => {
        (async () => {
            if (activePoll && pollSet) {
                calculateVotes(activePoll, pollSet);
            }

            if (nonVirtual) {
                socket.on('updatePollSet', function (data) {
                    if (data.action === 'addVote' && data.pollOptionId) {
                        const set = {
                            ...pollSet,
                        };
                        set.votes[data.pollOptionId] += 1;
                        calculateVotes(activePoll, set);
                    }
                });
            }
        })();
        return () => {
            if (socket && socket.removeAllListeners) {
                socket.removeAllListeners(`updatePollSet`);
            }
        };
    }, [activePoll, pollSet]);

    const calculateVotes = (poll, pSet) => {
        const options = get(poll, 'PollOptions', [])
            .filter(option => option.text.length > 0)
            .sort((o1, o2) => o1.order - o2.order)
            .map(option => ({
                ...option,
                votes: pSet.votes[option.id],
            }));
        setOptionsWithVotes(options);
    };
    const totalVotes = optionsWithVotes.reduce((acc, option) => acc + option.votes, 0);
    const isCorrectAnswerSet = optionsWithVotes.findIndex(option => option.correct) !== -1;

    return (
        <VotingResultsWrapper
            innerRef={elRef}
            className="eureka-react"
            isrecording={isForRecording ? 1 : 0}
        >
            {isForRecording && (
                <ProgressBar
                    pollSet={pollSet}
                    activePoll={activePoll}
                    activeColor={'#1fa294'}
                    isForRecording={isForRecording}
                />
            )}
            {isForRecording && (
                <FirstRow>
                    <PlaceholderContainer>
                        <PlaceholderText>VOTING RESULTS</PlaceholderText>
                    </PlaceholderContainer>
                    {
                        <TotalVotesRecordingContainer>
                            {totalVotes}
                            <HowToVoteIcon />
                        </TotalVotesRecordingContainer>
                    }
                </FirstRow>
            )}
            {!isForRecording && (
                <TotalVotesContainer>
                    {totalVotes}
                    <HowToVoteIcon />
                </TotalVotesContainer>
            )}
            <FlexRow>
                {imageUrl && (
                    <ImageContainer
                        onMouseEnter={() => {
                            setIsImageHovered(true);
                        }}
                        onMouseLeave={() => {
                            setIsImageHovered(false);
                        }}
                        onClick={() => {
                            setIsImageModalOpen(true);
                        }}
                    >
                        <Img src={imageUrl} />
                        {isImageHovered && (
                            <ZoomIconContainer>
                                <ZoomOutMapIcon />
                            </ZoomIconContainer>
                        )}
                    </ImageContainer>
                )}
                <FlexColumn isrecording={isForRecording ? 1 : 0}>
                    <Title>{title}</Title>
                    {!isForRecording && useTimer && (
                        <div style={inlineStyle}>
                            <StyledIcon>timer</StyledIcon>
                            <SecondsText>{seconds}s</SecondsText>
                        </div>
                    )}
                </FlexColumn>
            </FlexRow>
            <FlexContainer
                isrecording={isForRecording ? 1 : 0}
                nomarginleft={!imageUrl ? 1 : 0}
                iscorrectanswer={isCorrectAnswerSet ? 1 : 0}
            >
                {optionsWithVotes.map((option, index) => (
                    <VoteResult
                        key={`vote-result-${index}`}
                        label={String.fromCharCode(65 + index)}
                        option={option}
                        totalVotes={totalVotes}
                        color={votesColorScheme[index]}
                        isCurrentUserActive
                        isForRecording={isForRecording}
                    />
                ))}
            </FlexContainer>
            <EnlargedImageModal
                open={isImageModalOpen}
                handleClose={() => {
                    setIsImageModalOpen(false);
                }}
                imageUrl={imageUrl}
            />
        </VotingResultsWrapper>
    );
};

export default VotingResults;

import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';

import * as palette from '../../../../components/General/Variables';
import Button, { buttonTypes } from '../../../common/Button';

const StyledContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.87);
    padding: 12px 12px 18px 12px;
    border-radius: 8px;
    position: relative;
    width: 337px;
    display: flex;
    align-items: flex-start;
`;

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 74%;
`;

const StyledIconContainer = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4c4c4f;
    border-radius: 50%;
`;

const StyledIcon = styled(FontIcon)`
    width: 18px;
    color: #fff !important;
`;

const StyledCloseIcon = styled(StyledIcon)`
    width: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    opacity: 0.7;
`;

const StyledTitle = styled.div`
    font-size: 15px;
    color: ${palette.COLOR_WHITE};
    font-weight: 500;
    text-overflow: ellipsis;
`;

const StyledSubtitle = styled(StyledTitle)`
    margin-top: 2px;
    margin-bottom: 6px;
    color: rgba(255, 255, 255, 0.7);
`;

const StyledButtonContainer = styled.div`
    margin-top: 16px;
`;

const CloseButton = ({ closeToast }) => (
    <StyledCloseIcon iconClassName={'material-icons material-icons-round'} onClick={closeToast}>
        close
    </StyledCloseIcon>
);

const PlatformInfoNotification = ({ onUpdate, onClose, theme }) => (
    <StyledContainer id={'update-version-notification'}>
        <CloseButton closeToast={onClose} />
        <StyledIconContainer>
            <StyledIcon>{'update'}</StyledIcon>
        </StyledIconContainer>
        <StyledContent>
            <StyledTitle>New version available</StyledTitle>
            <StyledSubtitle>Update to see the changes</StyledSubtitle>
            <StyledButtonContainer>
                <Button
                    onClick={onUpdate}
                    type={buttonTypes.GREEN_LONG}
                    background={theme && theme.primary ? theme.primary : palette.COLOR_PRIMARY}
                    text={'Update'}
                />
            </StyledButtonContainer>
        </StyledContent>
    </StyledContainer>
);

export default PlatformInfoNotification;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { SessionsContainer, TileTitle, UpcomingSessionsTile } from '../common/styles';
import Loader from '../../../components/General/Loader';
import TimeslotListItem from '../../../components/ObjectListItem/Timeslot';
import NavigationLink, { navigateTo } from '../../../components/Navigation/NavigationLink';
import NoUpcomingSessionsPlaceholder from './NoUpcomingSessionsPlaceholder';

import { withRouter } from 'react-router-dom';
import { getLocalAppStateAsync } from '../../../services/api/db';
import { fetchUpcomingSessions } from '../api';
import { TimezoneContext } from '../../Timezone/context';
import { getString } from '../../../services/api/store';
import sortBy from 'lodash/sortBy';
import Button, { buttonTypes } from '../../common/Button';

const UpcomingSessionsWidget = ({ tileGroup, match, history, location }) => {
    const [upcomingSessions, setUpcomingSessions] = useState([]);
    const [noSessionsMessage, setNoSessionMessage] = useState('There are no events happening.');
    const [upcomingSessionsLoading, setUpcomingSessionsLoading] = useState(false);
    const [displayShowAllButton, setDisplayShowAllButton] = useState(false);
    const { getTimeslotUtc } = useContext(TimezoneContext);

    const TEXT_UPCOMING_SESSIONS_TITLE = getString('upcomingSessionsTitle') || 'Upcoming sessions';
    const TEXT_SEE_ALL_BUTTON = getString('upcomingSessionsSeeAllButton') || 'See all';

    const containerRef = useRef(null);

    const pageId = tileGroup.items && tileGroup.items.length && tileGroup.items[0].pageId;

    useEffect(() => {
        (async () => {
            if (tileGroup) {
                window.addEventListener('resize', handleResize);
                setUpcomingSessionsLoading(true);
                const appState = await getLocalAppStateAsync();
                const { sessions, message } = await fetchUpcomingSessions(
                    appState.eventId,
                    pageId,
                    null,
                    getTimeslotUtc,
                );
                const sortedSessions = sortBy(sessions, ['start', 'name']);
                setUpcomingSessions(sortedSessions);
                setNoSessionMessage(message);
                setUpcomingSessionsLoading(false);
                const containerElem = document.getElementById('upcoming-sessions-container');
                setDisplayShowAllButton(containerElem?.scrollHeight > containerElem?.offsetHeight);
            }
        })();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        const containerElem = document.getElementById('upcoming-sessions-container');
        setDisplayShowAllButton(containerElem?.scrollHeight > containerElem?.offsetHeight);
    };

    return (
        <UpcomingSessionsTile innerRef={containerRef}>
            <TileTitle>{TEXT_UPCOMING_SESSIONS_TITLE}</TileTitle>
            {upcomingSessionsLoading ? (
                <Loader />
            ) : (
                <>
                    {upcomingSessions && upcomingSessions.length > 0 ? (
                        <>
                            <SessionsContainer id="upcoming-sessions-container">
                                {upcomingSessions.map((it, index) => {
                                    const item = it;

                                    return (
                                        <NavigationLink
                                            key={`detail-page-upcoming-session-${index}`}
                                            params={{
                                                type: 'detail',
                                                objectClass: 'timeslot',
                                                objectId: item.id,
                                            }}
                                        >
                                            <TimeslotListItem
                                                timeslot={item}
                                                isUpcomingSession={true}
                                                containerWidth={
                                                    containerRef.current &&
                                                    containerRef.current.offsetWidth
                                                }
                                            />
                                        </NavigationLink>
                                    );
                                })}
                            </SessionsContainer>
                            {displayShowAllButton && (
                                <Button
                                    onClick={() =>
                                        navigateTo({
                                            match,
                                            history,
                                            location,
                                            params: {
                                                pageType: 'next',
                                                pageId: pageId,
                                            },
                                        })
                                    }
                                    type={buttonTypes.GREEN}
                                    text={TEXT_SEE_ALL_BUTTON}
                                    style={{ borderRadius: '18px', margin: '12px 16px' }}
                                />
                            )}
                        </>
                    ) : (
                        <NoUpcomingSessionsPlaceholder text={noSessionsMessage} />
                    )}
                </>
            )}
        </UpcomingSessionsTile>
    );
};

export default withRouter(UpcomingSessionsWidget);

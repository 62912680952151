import React, { useCallback, useEffect, useState } from 'react';
import toast from 'toasted-notes';
import { useDispatch, useSelector } from 'react-redux';

import GamificationService from '../services/GamificationService';
import AchievementNotification from '../components/AchievementNotification';
import { getLocalAppStateAsync } from '../../../services/api/db';
import Auth from '../../../services/api/auth';
import {
    getAvailableAchievementTypes,
    getIsGamificationEnabled,
    getIsLoadingAchievements,
    getIsLoadingAvailableAchievementTypes,
    getAchievementTypesLoaded,
} from '../selectors';
import { loadAchievementTypes } from '../actions';

export const AchievementType = Object.freeze({
    // Analytic
    COMPLETE_PROFILE: 'complete-profile',
    JOIN_VIRTUAL_SESSION: 'join-virtual-session',
    TIME_ON_PLATFORM: 'time-on-platform',
    VIEW_SCHEDULE_ITEM: 'view-schedule-item',
    VIEW_EXHIBITOR: 'view-exhibitor',
    VIEW_USER_PROFILE: 'view-user-profile',
    DAYS_IN_A_ROW: 'days-in-a-row',

    // Engage
    QUESTION: 'question',
    NOTE: 'note',
    VOTE: 'vote',
    FAVORITE: 'favorite',
    FIRST_QUESTION: 'first-question',
    LIKED_QUESTION: 'liked-question',
    VOTE_MOST_VOTED_OPTION: 'vote-most-voted-option',
    VOTE_LEAST_VOTED_OPTION: 'vote-least-voted-option',
    RATE: 'rate',
    EVALUATE: 'evaluate',

    // Social
    SEND_CONTACT_REQUEST: 'send-contact-request',
    RECEIVE_CONTACT_REQUEST: 'receive-contact-request',
    CHAT: 'chat',
    CHAT_CONTACT: 'chat-contact',
    CHAT_VIRTUAL_SESSION: 'chat-virtual-session',
    APPOINTMENT: 'appointment',
    BUSINESS_CARD: 'business-card',
    SOCIAL_POST: 'social-post',
    SOCIAL_POST_WITH_PHOTO: 'social-post-with-photo',
    LIKE_SOCIAL_POST: 'like-social-post',
    COMMENT_SOCIAL_POST: 'comment-social-post',
    FIRST_COMMENT_SOCIAL_POST: 'first-comment-social-post',
    SHARE_ITEM: 'share-item',

    // Expert
    COMPLETE_VIRTUAL_SESSION: 'complete-virtual-session',

    // Hidden
    ENTER_FULL_CAPACITY_ROOM: 'enter-full-capacity-room',
    CLICK_CLOSED_ROOM_BUTTON: 'click-closed-room-button',

    // Milestone
    ALL_ANALYTIC_ACHIEVEMENTS: 'all-analytic-achievements',
    ALL_ENGAGE_ACHIEVEMENTS: 'all-engage-achievements',
    ALL_SOCIAL_ACHIEVEMENTS: 'all-social-achievements',
    ALL_EXPERT_ACHIEVEMENTS: 'all-expert-achievements',
    HALF_OF_ALL_ACHIEVEMENTS: 'half-of-all-achievements',
    ALL_ACHIEVEMENTS: 'all-achievements',
});

export const useAchievementActions = () => {
    const isGamificationEnabled = useSelector(getIsGamificationEnabled);
    const availableAchievementTypes = useSelector(getAvailableAchievementTypes);
    const isLoadingAchievementTypes = useSelector(getIsLoadingAvailableAchievementTypes);
    const achievementTypesLoaded = useSelector(getAchievementTypesLoaded);
    const dispatch = useDispatch();
    const user = Auth.getUser();
    const isEnabled = !!user && isGamificationEnabled;

    useEffect(() => {
        if (!isEnabled || isLoadingAchievementTypes || achievementTypesLoaded) {
            return;
        }

        dispatch(loadAchievementTypes());
    }, [isEnabled, achievementTypesLoaded, dispatch, isLoadingAchievementTypes]);

    const trackAchievement = useCallback(
        async (type, itemId, skipGamificationEnabledCheck = false) => {
            if (
                !skipGamificationEnabledCheck &&
                (!isGamificationEnabled ||
                    (availableAchievementTypes && availableAchievementTypes.indexOf(type) < 0))
            ) {
                return;
            }

            try {
                const { id: eventId } = await getLocalAppStateAsync();

                const response = await GamificationService.trackAchievement(type, eventId, itemId);
                const { achievement, notification } = response;
                const shouldDisplayNotification =
                    notification &&
                    (notification.percentage === 0 || notification.percentage === 100);

                if (!shouldDisplayNotification) {
                    return;
                }

                // show notification
                toast.notify(
                    ({ onClose }) => (
                        <AchievementNotification
                            title={notification.title}
                            subtitle={notification.subtitle}
                            level={notification.level}
                            icon={achievement.icon}
                            percentage={notification.percentage}
                            onClose={onClose}
                        />
                    ),
                    {
                        position: 'top-right',
                        duration: 2000,
                    },
                );

                return response;
            } catch (error) {
                console.log('track error', error);
            }
        },
        [availableAchievementTypes, isGamificationEnabled],
    );

    const deleteAchievementUser = useCallback(
        async type => {
            if (!isGamificationEnabled) {
                return;
            }

            const { id: eventId } = await getLocalAppStateAsync();
            return GamificationService.deleteAchievementUser(type, eventId);
        },
        [isGamificationEnabled],
    );

    return { trackAchievement, deleteAchievementUser };
};

import React from 'react';
import styled, { css } from 'styled-components';
import MicNoneIcon from '@material-ui/icons/MicNone';
import MicOffIcon from '@material-ui/icons/MicOffOutlined';
import VideocamIcon from '@material-ui/icons/VideocamOutlined';
import VideocamOffIcon from '@material-ui/icons/VideocamOffOutlined';
import { FontIcon } from 'react-md';

import { useVMMutation, useVMState } from '../../containers/main';
import { AlertCameraMicrophoneDialog } from '../common/Dialog';
import entities from '../../constants/entities';
import FooterPresenter from './FooterPresenter';
import FooterModerator from './FooterModerator';
import FooterRoundTable from './FooterRoundTable';
import { ShareScreenSVG, EndShareScreevSVG } from './ShareScreenSVG';
import SessionWarning from '../common/SessionWarning';
import FooterWarningCard from '../common/FooterWarningCard';
import { LightTooltip } from '../moderator/common/styles';
import useMicAndCamera from '../../hooks/useMicAndCamera';
import SelectSourceMenu from './SelectSourceMenu';

const { virtualEventUserEntity } = entities;

const Wrapper = styled.footer`
    background-color: #ffffff;
    box-sizing: border-box;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.3);
    margin-left: 1px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 3;
    height: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const LeftContent = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
`;

const CenterContent = styled.div`
    align-items: center;
    display: flex;
    flex: 0.1;
    justify-content: center;
`;

export const ButtonContainer = styled.div`
    background-color: #ffffff;
    border: solid 1px #cccccc;
    border-radius: 50%;
    cursor: pointer;
    height: 48px;
    margin: 0 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    position: relative;

    ${props =>
        props.off &&
        css`
            background: #d50000;
            color: #fff;
        `}
    ${props =>
        props.disabled &&
        css`
            background: #b3b3b3;
            color: #fff;
        `}
    ${props =>
        props.mutedBySystem &&
        css`
            &:after {
                background: #ee1045;
                content: '!';
                display: block;
                position: absolute;
                height: 24px;
                width: 24px;
                top: -5px;
                right: -5px;
                border-radius: 50%;
                color: #fff;
                text-align: center;
                font-weight: bold;
                font-family: Roboto, sans-serif;
                line-height: 24px;
            }
        `}
`;

export const ButtonWithImageContainer = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 101px;
    height: 100%;
    justify-content: center;
    text-align: center;

    img {
        width: 24px;
        height: 24px;
    }

    &:hover {
        background-color: rgba(240, 241, 243, 0.6);
    }
`;

const RightContent = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    height: 100%;

    ${props =>
        props.disabled &&
        css`
            opacity: 0.5;
        `}
`;

const StyledIcon = styled(FontIcon)`
    color: #323232 !important;
    font-size: 24px !important;
    width: 24px !important;
`;

const RtmpEnabledContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 189px;
    height: 48px;
    margin-right: 16px;
    border-radius: 24px !important;
    border: solid 1px #d3d3d3;
    font-family: Cabin, sans-serif;
    font-size: 15px;
    font-weight: bold;
    cursor: default;
`;

const StyledFontIcon = styled(FontIcon)`
    color: #323232 !important;
    padding-right: 11px;
`;

const maxFileSize = 25 * 1048576;

function Footer() {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const {
        config,
        virtualEventUser,
        virtualEventSession,
        showModal,
        screenShareStream,
        shareType,
        speakerViewMode,
        mutedBySystem,
    } = stateCtx;
    const { isMicrophoneOn, isVideoOn, role: userRole } = virtualEventUser.data;
    const { toggleScreenSharing, toggleMic, toggleCamera } = useMicAndCamera();
    const isModerator = userRole === virtualEventUserEntity.role.moderator;
    const isRoomModerated =
        virtualEventSession.data.roomType === entities.virtualEventSessionEntity.roomType.moderated;
    const isRoomRoundTable =
        virtualEventSession.data.roomType ===
        entities.virtualEventSessionEntity.roomType.roundTable;
    const isScreenSharing = screenShareStream && shareType === 'screen';

    const { activePoll } = stateCtx;

    const screenSharingDisabled = activePoll || virtualEventSession.data.showVoteResults;
    const isAudienceView = speakerViewMode === entities.speakerViewMode.audience;

    return (
        <Wrapper className="eureka-react">
            <FlexContainer>
                <LeftContent>
                    {isRoomModerated && isModerator && (
                        <FlexContainer>
                            <FooterModerator
                                isVideoOn={isVideoOn}
                                isMicrophoneOn={isMicrophoneOn}
                            />
                            {!virtualEventUser.data.rtmpInfo && (
                                <FooterWarningCard
                                    isVideoOn={isVideoOn}
                                    isMicrophoneOn={isMicrophoneOn}
                                />
                            )}
                        </FlexContainer>
                    )}
                    {isRoomModerated &&
                        virtualEventUser.data.role === virtualEventUserEntity.role.presenter && (
                            <FlexContainer>
                                <FooterPresenter />
                                <FooterWarningCard
                                    isVideoOn={isVideoOn}
                                    isMicrophoneOn={isMicrophoneOn}
                                    isPresenter
                                />
                            </FlexContainer>
                        )}
                    {isRoomRoundTable && <FooterRoundTable />}
                </LeftContent>

                <CenterContent className="eureka-react">
                    {!virtualEventUser.data.rtmpInfo && (
                        <ButtonContainer
                            off={!isVideoOn}
                            disabled={!config.cameraId}
                            onClick={toggleCamera}
                            data-qa="video-button"
                        >
                            {isVideoOn && (
                                <LightTooltip title={'Turn off video'}>
                                    <VideocamIcon />
                                </LightTooltip>
                            )}
                            {!isVideoOn && (
                                <LightTooltip title={'Turn on video'}>
                                    <VideocamOffIcon />
                                </LightTooltip>
                            )}
                        </ButtonContainer>
                    )}
                    {!virtualEventUser.data.rtmpInfo && (
                        <ButtonContainer
                            off={!isMicrophoneOn && !mutedBySystem}
                            disabled={!config.microphoneId}
                            mutedBySystem={mutedBySystem}
                            onClick={toggleMic}
                            data-qa="microphone-button"
                        >
                            {(isMicrophoneOn || mutedBySystem) && (
                                <LightTooltip title={'Mute microphone'}>
                                    <MicNoneIcon />
                                </LightTooltip>
                            )}
                            {!isMicrophoneOn && !mutedBySystem && (
                                <LightTooltip title={'Unmute microphone'}>
                                    <MicOffIcon />
                                </LightTooltip>
                            )}
                        </ButtonContainer>
                    )}
                    {virtualEventUser.data.rtmpInfo && (
                        <RtmpEnabledContainer>
                            <StyledFontIcon>cast</StyledFontIcon>
                            RTMP ENABLED
                        </RtmpEnabledContainer>
                    )}
                    <SelectSourceMenu />
                </CenterContent>

                <RightContent disabled={screenSharingDisabled}>
                    {!isAudienceView &&
                        (isScreenSharing ? (
                            <ButtonWithImageContainer
                                onClick={toggleScreenSharing}
                                data-qa="end-share-screen-button"
                            >
                                <EndShareScreevSVG />
                                End screen sharing
                            </ButtonWithImageContainer>
                        ) : (
                            <ButtonWithImageContainer
                                onClick={toggleScreenSharing}
                                data-qa="start-share-screen-button"
                            >
                                <ShareScreenSVG />
                                Share screen
                            </ButtonWithImageContainer>
                        ))}
                </RightContent>

                <AlertCameraMicrophoneDialog
                    open={showModal}
                    handleClose={() => mutationCtx.hidePermissionModal()}
                />
            </FlexContainer>
            {isRoomModerated && isModerator && <SessionWarning isModerator={isModerator} />}
            {isRoomModerated &&
                virtualEventUser.data.role === virtualEventUserEntity.role.presenter && (
                    <SessionWarning
                        isPresenter={
                            virtualEventUser.data.role === virtualEventUserEntity.role.presenter
                        }
                    />
                )}
        </Wrapper>
    );
}

const FooterWithCheck = () => {
    const stateCtx = useVMState();
    const { virtualEventUser } = stateCtx;
    return virtualEventUser.data && virtualEventUser.data.id ? <Footer /> : null;
};

export default FooterWithCheck;

import React from 'react';
import { Button, DialogContainer, FontIcon } from 'react-md';
import styled, { css } from 'styled-components';

const StyledButton = styled(Button)`
    height: 40px !important;
    width: 100% !important;
    color: #fff !important;
    background-color: ${props =>
        props.backgroundcolor ? props.backgroundcolor : '#44bb90'} !important;
    border-radius: 6px !important;
    font-size: 15px !important;
    text-transform: none !important;
`;

const NoButton = styled(Button)`
    height: 40px !important;
    width: 100% !important;
    background-color: #eaeaea !important;
    border-radius: 6px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    text-transform: none !important;
    font-size: 15px !important;
`;

const Dialog = styled(DialogContainer)`
    #simple-confirmation-dialog {
        width: 424px;
        border-radius: 12px;
        padding-bottom: 24px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
        background-color: #ffffff;
        z-index: 1000;
        ${props =>
            props.buttons
                ? `
            footer {
                justify-content: space-between !important;
            }
        `
                : ``}
        h2 {
            padding: 0 0 16px 0 !important;
            margin-left: 0 !important;
        }

        ${props =>
            props.extrastyles &&
            css`
                ${props.extrastyles}
            `}
    }
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-direction: ${props => props.columnDirection && 'column'};
    align-items: ${props => props.columnDirection && 'center'};

    i {
        cursor: pointer;
    }
`;

const Title = styled.div`
    font-size: 21px !important;
    height: 24px !important;
    font-weight: bold !important;
    font-family: Cabin, sans-serif;
    color: rgba(0, 0, 0, 0.87);
    margin: ${props => (props.icon ? '0px' : '24px 0 0 24px;')};
`;

const IconWrapper = styled(FontIcon)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px !important;
    height: 56px !important;
    color: ${props => (props.color ? props.color : '#fff')} !important;
    border-radius: 50%;
    font-size: 30px !important;
    font-weight: ${props => (props.nonBoldIcon ? 'normal' : 'bold')} !important;
    background-color: ${props => (props.backgroundcolor ? props.backgroundcolor : '#1fa294')};
    margin: 56px 0 16px 0;
    cursor: none;
`;

const ConfirmationDialog = ({
    extraStyles,
    open,
    title,
    titleIcon,
    iconWrapperColor,
    nonBoldIcon,
    iconColor,
    outlinedIcon,
    content,
    onYes,
    onNo,
    onOk,
    buttons,
    noButtons,
    withCloseButton,
    onClose,
    noButtonText,
    yesButtonText,
    yesButtonBackgroundColor,
}) => {
    const actionButtons = buttons || [];
    const iconStyle = titleIcon
        ? { position: 'absolute', top: '24px', right: '24px' }
        : { padding: '24px 24px 0 0' };

    if (!buttons) {
        if (onOk) {
            actionButtons.push(
                <StyledButton flat onClick={onOk}>
                    Ok
                </StyledButton>,
            );
        } else {
            actionButtons.splice(
                0,
                0,
                <StyledButton flat backgroundcolor={yesButtonBackgroundColor} onClick={onYes}>
                    {yesButtonText ? yesButtonText : 'Yes'}
                </StyledButton>,
                <NoButton flat onClick={onNo}>
                    {noButtonText ? noButtonText : 'No'}
                </NoButton>,
            );
        }
    }

    const onHide = onOk || onNo || (() => {});

    return (
        <Dialog
            id="simple-confirmation-dialog"
            aria-describedby="confirmation-dialog"
            visible={!!open}
            title={
                withCloseButton ? (
                    <Flex columnDirection={!!titleIcon}>
                        {titleIcon && (
                            <IconWrapper
                                iconClassName={
                                    outlinedIcon ? 'material-icons-outlined' : 'material-icons'
                                }
                                backgroundcolor={iconWrapperColor}
                                color={iconColor}
                                nonBoldIcon={nonBoldIcon ? 1 : 0}
                            >
                                {titleIcon}
                            </IconWrapper>
                        )}
                        <Title icon={!!titleIcon}>{title}</Title>
                        <FontIcon
                            onClick={onClose}
                            style={iconStyle}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    onClose();
                                }
                            }}
                        >
                            close
                        </FontIcon>
                    </Flex>
                ) : (
                    title
                )
            }
            onHide={onHide}
            actions={noButtons ? undefined : actionButtons}
            buttons={noButtons ? undefined : buttons}
            extrastyles={extraStyles}
            footerStyle={{ justifyContent: 'flex-start', padding: '24px 24px 0 24px' }}
            focusOnMount={!noButtons}
        >
            {content}
        </Dialog>
    );
};

export default ConfirmationDialog;

import { useMemo } from 'react';
import get from 'lodash/get';

import { useGlobalState } from '../../../../../utils/container';

const useVirtualEventUsersToDisplay = () => {
    const stateCtx = useGlobalState();
    const { virtualEventSession, virtualEventUser, peers, localStream } = stateCtx;

    const isHostOnline = peers.findIndex(peer => peer === virtualEventUser?.UserId) !== -1;
    const virtualEventUsers = useMemo(() => {
        let result = get(virtualEventSession, 'VirtualEventUsers', []);

        result.sort((a, b) => {
            const aLastName = a.User.lastName.toLowerCase();
            const bLastName = b.User.lastName.toLowerCase();

            if (aLastName > bLastName) {
                return 1;
            }
            if (aLastName < bLastName) {
                return -1;
            }
            return 0;
        });

        result = result.filter(vUser => {
            const hasJoined = peers.find(peerId => peerId === vUser.UserId);

            if (localStream && hasJoined && vUser.UserId === localStream.streamId) {
                vUser.muted = true;
            }

            if (vUser.id === get(virtualEventUser, 'id')) {
                // if it's the host
                return false;
            }

            return hasJoined;
        });

        return [virtualEventUser, ...result];
    }, [virtualEventSession, localStream, peers, virtualEventUser]);

    return virtualEventUsers.filter(vUser => {
        const isHost = vUser?.role === 'roundTableHost';
        return isHost ? isHostOnline : true;
    });
};

export default useVirtualEventUsersToDisplay;

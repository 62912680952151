import { eurekaRequest } from '../../../../../services/api/eureka';

export const createOrUpdateNotification = data => {
    return new Promise((resolve, reject) => {
        eurekaRequest('POST', '/api/entities/notifications', data, true, (err, response) => {
            if (err) {
                reject(err);
            } else {
                resolve(response);
            }
        });
    });
};

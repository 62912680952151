import React, { useState } from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';
import QuestionModal from '../components/QuestionModal';
import Question from '../components/Question';
import Dropdown from '../components/Dropdown';
import { get } from 'lodash';
import { useVMState } from '../../virtualFeature/containers/main';
import ConfirmationDialog from '../../virtualFeature/components/common/ConfirmatonDialog';
import {
    CancelModalButton,
    ContentModalContainer,
    DestructiveModalButton,
    SaveModalButton,
} from '../../virtualFeature/components/moderator/common/styles';
import { StyledButton } from '../components/voteStyles';
import entities from '../../../../VirtualSession/constants/entities';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: 0 16px;
`;

const QuestionsTitle = styled.div`
    font-family: 'Cabin', sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: rgba(0, 0, 0, 0.87) !important;
    margin-top: 16px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
        display: flex;
        align-items: center;
        svg {
            margin-right: 12px;
        }
    }
`;

const Upload = styled(FontIcon)`
    border-radius: 50%;
    cursor: pointer;
    padding: 12px;
    font-size: 20px !important;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);

    &:hover {
        color: rgba(0, 0, 0, 0.87);
        background: #f1f1f3;
    }
`;

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    padding: 0 25px;
    margin-bottom: -3px;
`;

const ExtraStyledButton = styled(StyledButton)`
    width: 100% !important;
    text-align: center;
    justify-content: center;
    margin-right: 0;
`;

const ModeratorQuestionsList = props => {
    const {
        pollSet,
        questions,
        currentQuestionId,
        setCurrentQuestionId,
        doResetVotes,
        doDeletePoll,
        moveQuestionUp,
        moveQuestionDown,
        isCurrentUserActive,
        toggleVoteActivity,
        hideVoteResults,
    } = props;
    const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showResetConfirmationModal, setShowResetConfirmationModal] = useState(false);
    const [warningMessage, setWarningMessage] = useState(null);
    const [questionToBeActivated, setQuestionToBeActivated] = useState(null);

    const stateCtx = useVMState();
    const {
        socket,
        sessionId,
        virtualEventSession,
        virtualEventUser,
        user,
        activePoll: currentlyActivePoll,
        screenShareStream,
        shareType,
    } = stateCtx;

    const isScreenSharing = screenShareStream && shareType === 'screen';
    const virtualEventUsers = get(virtualEventSession, 'data.VirtualEventUsers') || [];
    const vUserId = get(virtualEventUser, 'data.UserId');

    const sortQuestionsByUserLastName = (questions, role) =>
        (questions || [])
            .filter(q => {
                const vUser = virtualEventUsers.find(u => q?.user?.id === u.UserId);
                return vUser?.role === role;
            })
            .sort((left, right) => {
                const leftUserName = left?.user?.lastName;
                const rightUserName = right?.user?.lastName;
                return leftUserName.localeCompare(rightUserName);
            });
    const sortedQuestions = [
        ...sortQuestionsByUserLastName(questions, entities.virtualEventUserEntity.role.moderator),
        ...sortQuestionsByUserLastName(questions, entities.virtualEventUserEntity.role.presenter),
    ];

    const setQuestionToDelete = id => {
        setCurrentQuestionId(id);
        setShowDeleteConfirmationModal(true);
    };

    const cancelDelete = () => {
        setCurrentQuestionId(null);
        setShowDeleteConfirmationModal(false);
    };

    const resetVotes = async () => {
        await doResetVotes();
        setShowResetConfirmationModal(false);
    };

    const deletePoll = async () => {
        await doDeletePoll();
        setShowDeleteConfirmationModal(false);
    };

    const setQuestionToEdit = questionId => {
        setCurrentQuestionId(questionId);
        setShowAddQuestionModal(true);
    };

    const closeQuestionForm = () => {
        setShowAddQuestionModal(false);
        setCurrentQuestionId(null);

        socket.emit('pollRefresh', { objectId: sessionId });
    };

    const setQuestionToResetVotes = id => {
        setCurrentQuestionId(id);
        setShowResetConfirmationModal(true);
    };

    const cancelResetVotes = () => {
        setCurrentQuestionId(null);
        setShowResetConfirmationModal(false);
    };

    const toggleActivity = async (id, noVoteResultsChange) => {
        const questionToBeActivated = questions.find(q => q.id === id);
        const questionUserId = get(questionToBeActivated, 'user.id');
        const anotherUserQuestion = questionUserId !== vUserId;

        if (anotherUserQuestion) {
            setQuestionToBeActivated(id);
            return;
        }

        if (isScreenSharing) {
            setWarningMessage('You cannot start a vote while you are screen sharing.');
            return;
        }

        toggleVoteActivity(id);

        if (isCurrentUserActive() && !noVoteResultsChange) {
            hideVoteResults();
        }
    };

    const viewResults = async questionId => {
        const showVoteResults = get(virtualEventSession, 'data.showVoteResults', null);

        if (
            !currentlyActivePoll ||
            (currentlyActivePoll && currentlyActivePoll.id !== questionId)
        ) {
            await toggleActivity(questionId, true);
        }

        if (showVoteResults === true) {
            return;
        }

        stateCtx.socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                status: virtualEventSession.data.status,
                showVoteResults: true,
            },
        });
    };

    return (
        <Wrapper className="eureka-react">
            <QuestionsTitle>
                <div style={{ alignItems: 'center' }}>Voting questions</div>
                <Upload onClick={() => setShowAddQuestionModal(true)}>{'add'}</Upload>
            </QuestionsTitle>
            <div style={{ overflowY: 'scroll', height: '100%' }}>
                {sortedQuestions.map((question, index) => (
                    <Question
                        index={index}
                        key={`question-${index}`}
                        question={question}
                        onClick={() => toggleActivity(question.id)}
                        actionComponent={
                            <Dropdown
                                id={`question-${index}`}
                                key={`question-dropdown-${index}`}
                                active={question.active}
                                resultsNumber={question.totalVotes}
                                index={index}
                                isLastInList={index === questions.length - 1}
                                onMoveUp={() => moveQuestionUp(index)}
                                onMoveDown={() => moveQuestionDown(index)}
                                onViewResults={() => viewResults(question.id)}
                                onResetVotes={() => setQuestionToResetVotes(question.id)}
                                onEdit={() => setQuestionToEdit(question.id)}
                                onDelete={() => setQuestionToDelete(question.id)}
                            />
                        }
                    />
                ))}
            </div>
            <QuestionModal
                open={showAddQuestionModal}
                handleClose={closeQuestionForm}
                pollSetId={pollSet ? pollSet.id : null}
                questionId={currentQuestionId}
            />
            <ConfirmationDialog
                open={showDeleteConfirmationModal}
                title="Delete poll"
                titleIcon="delete_forever"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                content={
                    <ExtraStyledModalContentContainer>
                        Are you sure you want to delete this poll?
                    </ExtraStyledModalContentContainer>
                }
                onClose={cancelDelete}
                buttons={[
                    <DestructiveModalButton key="DVQD" flat onClick={deletePoll}>
                        Delete
                    </DestructiveModalButton>,
                    <CancelModalButton key="DVQC" flat onClick={cancelDelete}>
                        Cancel
                    </CancelModalButton>,
                ]}
            />
            <ConfirmationDialog
                open={showResetConfirmationModal}
                title="Clear votes"
                titleIcon="restart_alt"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                content={
                    <ExtraStyledModalContentContainer>
                        Are you sure you want to clear the answers? All votes will be deleted.
                    </ExtraStyledModalContentContainer>
                }
                onClose={cancelResetVotes}
                onNo={cancelResetVotes}
                onYes={resetVotes}
                buttons={[
                    <SaveModalButton key="SRDR" flat onClick={resetVotes}>
                        Yes
                    </SaveModalButton>,
                    <CancelModalButton key="SRDC" flat onClick={cancelResetVotes}>
                        Cancel
                    </CancelModalButton>,
                ]}
            />
            <ConfirmationDialog
                open={!!warningMessage}
                title="Warning"
                titleIcon="warning"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                outlinedIcon
                onClose={() => setWarningMessage(null)}
                content={
                    <ExtraStyledModalContentContainer>
                        {warningMessage}
                    </ExtraStyledModalContentContainer>
                }
                buttons={[
                    <ExtraStyledButton key="WMDO" flat onClick={() => setWarningMessage(null)}>
                        Ok
                    </ExtraStyledButton>,
                ]}
            />
            <ConfirmationDialog
                open={!!questionToBeActivated}
                title="Active presenter will be interrupted"
                titleIcon="warning_amber"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                outlinedIcon
                nonBoldIcon
                onClose={() => setQuestionToBeActivated(null)}
                content={
                    <ExtraStyledModalContentContainer>
                        Are you sure you want to activate this question? The presentation of the
                        active presenter will be overruled by this question.
                    </ExtraStyledModalContentContainer>
                }
                buttons={[
                    <DestructiveModalButton
                        key="ASPQ"
                        flat
                        onClick={() => toggleVoteActivity(questionToBeActivated)}
                    >
                        Activate anyway
                    </DestructiveModalButton>,
                    <CancelModalButton
                        key="CASPQ"
                        flat
                        onClick={() => setQuestionToBeActivated(null)}
                    >
                        Cancel
                    </CancelModalButton>,
                ]}
            />
        </Wrapper>
    );
};

export default ModeratorQuestionsList;

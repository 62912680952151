import * as StyledComponent from '../../style/modalsStyle';
import { BackgroundAndAvatar } from './BackgroundAndAvatar';
import { PersonalInformation } from './PersonalInformation';
import { SocialLinks } from './SocialLinks';
import { StyledForm } from '../../style';
import React from 'react';
import { getString } from '../../../../services/api/store';
import * as constants from '../../constants';
import FloqButton from '../../../../components/Button/FloqButton';
import { EditFontIcon } from '@react-md/material-icons';

export const ProfileForm = ({
    theme,
    isMobile,
    afterSignUp,
    fieldValues,
    onImageChange,
    authUser,
    onChange,
    countries,
    onSelectChange,
    setShowSocialLinksModal,
    setShowContactInfoModal,
    importedFields,
    setShowDeleteAccountModal,
    setShowChangePasswordModal,
}) => {
    const PROFILE_SUBTITLE =
        getString('profileCheckMyProfileScreenSubtitle') || constants.PROFILE_SUBTITLE;
    const SOCIAL_LINKS = getString('profileSectionLinksTitle') || constants.SOCIAL_LINKS;
    const CONTACT_INFORMATION_TITLE =
        getString('profileContactInfoDivider') || constants.CONTACT_INFO;
    const CONTACT_INFORMATION_SUBTITLE =
        getString('profileContactInfoMessage') || constants.VISIBLE_FOR_CONTACTS;

    return (
        <StyledForm maincolor={theme.primary} horizontalMargin={isMobile && '0px'}>
            {afterSignUp && (
                <StyledComponent.ProfileInfoText>
                    {PROFILE_SUBTITLE}
                </StyledComponent.ProfileInfoText>
            )}

            <BackgroundAndAvatar
                theme={theme}
                fieldValues={fieldValues}
                onImageChange={onImageChange}
                authUser={authUser}
            />

            <PersonalInformation
                fieldValues={fieldValues}
                onChange={onChange}
                countries={countries}
                onSelectChange={onSelectChange}
                afterSignUp={afterSignUp}
                importedFields={importedFields}
            />
            <StyledComponent.FormWrapper>
                <StyledComponent.SectionText noMarginLeft={true}>
                    {SOCIAL_LINKS}
                </StyledComponent.SectionText>
                <SocialLinks fieldValues={fieldValues} />
                <FloqButton
                    onClick={() => setShowSocialLinksModal(true)}
                    extraStyle={{ marginTop: '12px', width: '100%' }}
                >
                    <EditFontIcon style={{ marginRight: 8 }} />
                    {getString('edit', 'Edit')}
                </FloqButton>
                <StyledComponent.SectionText noMarginLeft={true}>
                    {CONTACT_INFORMATION_TITLE}
                </StyledComponent.SectionText>
                <StyledComponent.SectionDetails>
                    {CONTACT_INFORMATION_SUBTITLE}
                </StyledComponent.SectionDetails>
                <FloqButton
                    onClick={() => setShowContactInfoModal(true)}
                    extraStyle={{ marginTop: '12px', marginBottom: '16px', width: '100%' }}
                >
                    <EditFontIcon style={{ marginRight: 8 }} />
                    {getString('edit', 'Edit')}
                </FloqButton>
                <StyledComponent.SectionText noMarginLeft={true}>
                    Account options
                </StyledComponent.SectionText>
                <FloqButton
                    onClick={() => setShowChangePasswordModal(true)}
                    extraStyle={{
                        marginTop: '16px',
                        marginBottom: '12px',
                        width: '100%',
                    }}
                >
                    Change password
                </FloqButton>
                <FloqButton
                    onClick={() => setShowDeleteAccountModal(true)}
                    extraStyle={{
                        marginBottom: '16px',
                        width: '100%',
                        color: theme.primary,
                    }}
                >
                    Delete account
                </FloqButton>
            </StyledComponent.FormWrapper>
        </StyledForm>
    );
};

import React from 'react';
import { Paper } from 'react-md';
import styled from 'styled-components';
import Loader from '../../../../components/General/Loader';

const ScreenLoaderWrapper = styled(Paper)`
    background: transparent;
    position: absolute;
    top: 65px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
`;

const Overlay = styled.div`
    background: gray;
    opacity: 0.5;
    height: 100%;
    width: 100%;
    z-index: 9998;
`;

const LoaderHolder = styled.div`
    background: #fff;
    display: flex;
    flex-flow: column;
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
    background: white;
    padding: 25px;
`;

const ScreenLoader = ({ color = '#44bb90', title, text, ...props }) => {
    return (
        <ScreenLoaderWrapper zDepth={1} {...props}>
            <Overlay />
            <LoaderHolder>
                <h3>{title}</h3>
                <h5>{text}</h5>
                <Loader />
            </LoaderHolder>
        </ScreenLoaderWrapper>
    );
};

export default ScreenLoader;

import React, { useMemo } from 'react';
import styled from 'styled-components';

import DefaultChatBubble from '../ChatBubble';

const BubbleWrapper = styled.div`
    position: relative;
    align-items: center;
    overflow: auto;
`;

const BubbleGroup = props => {
    const currentUser = useMemo(() => {
        return JSON.parse(localStorage.getItem('user'));
    }, []);

    /**
     * Parses and collects messages of one type to be grouped together.
     * @return {messageNodes} - a JSX wrapped group of messages
     */
    const { messages, chatRoomId, onSetMessageToDelete, nonVirtualQA } = props;
    const renderGroup = messages => {
        const { bubblesCentered, bubbleStyles, chatBubble } = props;
        const ChatBubble = chatBubble || DefaultChatBubble;
        const sampleMessage = messages[0];

        const messageNodes = messages.map((message, index) => {
            return (
                <ChatBubble
                    key={index}
                    message={message}
                    chatRoomId={chatRoomId}
                    onSetMessageToDelete={onSetMessageToDelete}
                    bubblesCentered={bubblesCentered}
                    bubbleStyles={bubbleStyles}
                    showAvatar={sampleMessage.user.id !== currentUser.id && index === 0}
                    avatarClick={() => {
                        // click on avatar is disabled for now
                    }}
                    nonVirtualQA={nonVirtualQA}
                />
            );
        });

        return <BubbleWrapper>{messageNodes}</BubbleWrapper>;
    };

    return renderGroup(messages);
};

export default BubbleGroup;

import React, { useCallback } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import PriorityHighOutlinedIcon from '@material-ui/icons/PriorityHighOutlined';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { FontIcon } from 'react-md';

import entities from '../../constants/entities';
import onUpdatePresentationUrl from '../../events/onUpdatePresentationUrl';
import GreenCard from './GreenCard';
import { useVMMutation, useVMState } from '../../containers/main';
import { ShareScreenSVG } from '../footer/ShareScreenSVG';
import speakerPresentLiveImage from '../../../../../../assets/images/speaker-present-live.png';
import preRecordImage from '../../../../../../assets/images/speaker-pre-record.png';
import speakerAudienceImage from '../../../../../../assets/images/speaker-audience-view.png';

const SlidesContainer = styled.div`
    align-items: center;
    background-color: #f5f5f5;
    display: flex;
    height: 660px;
    justify-content: space-between;
    flex-direction: column;
`;

const CardsContainer = styled.div`
    align-self: flex-start;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    max-height: 140px;
    width: 97%;
`;

const SlidesContent = styled.div`
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    justify-content: center;
`;

const NoSlidesText = styled.div`
    font-family: Cabin, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    margin: 0 0 20px 0;
`;

const SmallDetailsText = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 6px;
`;

const DetailsText = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.43;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px;
    div {
        display: flex;
        align-items: center;
        svg {
            width: 15px;
            height: 15px;
            margin: 0 4px;
        }
    }
`;

const SelectButton = styled.div`
    align-items: center;
    background-color: #1fa294;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    height: 36px;
    justify-content: center;
    padding: 0 16px;

    span {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
    }
`;

const ErrorMessage = styled.div`
    border-radius: 1px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 10px 12px;
    opacity: 0.87;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #d50000;
    position: relative;
    margin-top: 12px;
    display: flex;
    align-items: center;

    &:after {
        content: '';
        width: 0;
        height: 0;
        bottom: 85%;
        left: 48%;
        position: absolute;
        border: 7px solid #fff;
        transform: rotate(135deg);
        transition: border 0.4s ease-in-out;
    }
`;

const WarningIcon = styled(PriorityHighOutlinedIcon)`
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    border: 2px solid #d50000;
    margin-right: 8px;
`;

const StyledIcon = styled(FontIcon)`
    color: ${props => (props.color ? props.color : '#fff')} !important;
    margin-right: 13px !important;
    font-size: 24px !important;
`;

const roomTypeEntity = entities.virtualEventSessionEntity.roomType;
const roomStatus = entities.virtualEventSessionEntity.status;
const virtualEventUserModeEntity = entities.virtualEventUserEntity.mode;
const maxFileSize = 25 * 1048576;

function UploadResource({ type }) {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();

    const { virtualEventSession, virtualEventUser } = stateCtx;

    const currentRoomStatus = get(virtualEventSession, 'data.status');
    const roomType = get(virtualEventSession, 'data.roomType');
    const isPrerecordingMode = get(virtualEventUser, 'data.isPrerecording');
    const isRoomOpen = currentRoomStatus === roomStatus.initial;
    let resourceText;
    let virtualEventUserMode;

    switch (type) {
        case roomTypeEntity.roundTable:
            resourceText = 'poster';
            virtualEventUserMode = virtualEventUserModeEntity.poster;
            break;
        case roomTypeEntity.moderated:
            resourceText = 'slide';
            virtualEventUserMode = virtualEventUserModeEntity.slide;
            break;
        default:
            throw Error('Unsupported type');
    }

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: 'application/pdf, application/vnd.ms-powerpoint',
        maxSize: maxFileSize,
        onDrop: useCallback(
            acceptedFiles => {
                return onUpdatePresentationUrl(
                    stateCtx,
                    mutationCtx,
                    acceptedFiles,
                    virtualEventUserMode,
                );
            },
            [stateCtx],
        ),
    });

    const isFileTooLarge =
        fileRejections && fileRejections.length > 0 && fileRejections[0].errors.length > 0;

    return (
        <SlidesContainer {...getRootProps()}>
            <input {...getInputProps()} />
            {roomType === roomTypeEntity.moderated && (
                <CardsContainer>
                    <GreenCard
                        imageUrl={speakerPresentLiveImage}
                        title="Present Live"
                        description="Use the Present live mode from the bottom left to present live when the session has started."
                    />
                    <GreenCard
                        imageUrl={preRecordImage}
                        title="Pre-record"
                        subtitle={
                            isRoomOpen &&
                            !isPrerecordingMode &&
                            'Only available when room is closed'
                        }
                        description="In the Pre-record mode, you can create or upload a pre-recording when the room is not yet open."
                    />
                    <GreenCard
                        imageUrl={speakerAudienceImage}
                        title="Audience view"
                        description="Follow along with the audience on the Audience view tab when the session is live and others are presenting."
                    />
                </CardsContainer>
            )}
            <SlidesContent>
                <NoSlidesText>Drag and drop your {resourceText} here or</NoSlidesText>

                <SelectButton>
                    <StyledIcon>file_upload</StyledIcon>
                    <span>Upload slides</span>
                </SelectButton>

                {isFileTooLarge && (
                    <ErrorMessage>
                        <WarningIcon />
                        <div>
                            Make sure you upload a file that is smaller than &nbsp;<b>25MB</b>
                        </div>
                    </ErrorMessage>
                )}

                <SmallDetailsText>
                    Supported file format: PDF
                    <br />
                    <div>Recommended size: 1920x1080, max 25 MB</div>
                </SmallDetailsText>
                <DetailsText>
                    <div>
                        Or use the <ShareScreenSVG /> Share screen button at the bottom right
                    </div>
                </DetailsText>
            </SlidesContent>
        </SlidesContainer>
    );
}

UploadResource.propTypes = {
    type: PropTypes.oneOf([roomTypeEntity.moderated, roomTypeEntity.roundTable]),
};

export default UploadResource;

import React from 'react';
import styled from 'styled-components';

import { useTheme } from '../../../components/Theme/ThemeContext';
import { Tooltipped } from 'react-md';

const TotalPointsLabel = styled.button`
    padding: 6px 12px;
    border-radius: 17.5px;
    background-color: ${props => props.backgroundColor};
    font-family: 'Cabin', sans-serif;
    font-size: ${props => (props.outlined ? 13 : 17)}px;
    font-weight: bold;
    color: ${props => props.color};
    ${props => props.outlined && `border: 2px solid ${props.color}`}
    cursor: pointer;
    border: none;
`;

const StyledTooltipped = styled(Tooltipped)`
    .md-tooltip {
        padding: 8px 12px;
        border-radius: 6px;
        background-color: rgba(0, 0, 0, 0.87);
    }

    &.md-tooltip-container {
        height: 70% !important;
    }
`;

const TotalPoints = ({
    points,
    outlined = false,
    outlineColor,
    backgroundColor,
    tooltip = false,
    onClick = () => {},
}) => {
    const { theme } = useTheme();

    const PointsElement = () => (
        <TotalPointsLabel
            outlined={outlined}
            color={outlineColor || (outlined ? theme.contrast : '#fff')}
            backgroundColor={backgroundColor || (outlined ? '#fff' : theme.contrast)}
            onClick={onClick}
        >
            {points}
        </TotalPointsLabel>
    );

    return tooltip ? (
        <StyledTooltipped label="My score" position="bottom" setPosition={true}>
            <span>
                <PointsElement />
            </span>
        </StyledTooltipped>
    ) : (
        <PointsElement />
    );
};

export default TotalPoints;

import React from 'react';
import styled from 'styled-components';

const TooltipWrapper = styled.div`
    position: relative;
`;

const Tooltip = styled.div`
    padding: 8px 12px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.87);
    color: white;
    font-size: 13px;
    line-height: 1.54;
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 4px));
    width: ${props => props.width}px;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -4px;
        width: 0;
        height: 0;
        border-top: solid 4px rgba(0, 0, 0, 0.87);
        border-left: solid 4px transparent;
        border-right: solid 4px transparent;
    }
`;

const CustomTooltip = ({ children, text, visible, width = 180 }) => {
    return (
        <TooltipWrapper width={width}>
            {visible && !!text && <Tooltip>{text}</Tooltip>}
            {children}
        </TooltipWrapper>
    );
};

export default CustomTooltip;

import React, { useState, useEffect } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';

import { useVMMutation, useVMState } from '../../../../containers/main';
import BasicDialog from '../../../common/Dialog/BasicDialog';
import entities from '../../../../constants/entities';
import Loader from '../../../common/Loader';
import { compressImage } from '../../../../../../utils';

import Card from './Card';
import CropDialog from './CropDialog';
import ExportChat from './ExportChat';
import FeatureItem from './FeatureItem';
import ImageUpload from './ImageUpload';
import LinkCard from './LinkCard';
import { uploadPlaceholderToS3 } from '../../../../services/VirtualEventSession';
import {
    getQuestionSetByExternalObject,
    saveQuestionSet,
} from '../../../../services/QuestionsAndAnswersService';
import { FontIcon } from 'react-md';
import copyUtils from '../../../../utils/copyUtils';
import { getLocalAppStateAsync } from '../../../../../../../../services/api/db';
import {
    getPollSetByExternalObject,
    savePollSet,
} from '../../../../../Votes/services/PollingService';
import LanguageSelect from '../translations/LanguageSelect';
import ExportCaptions from './ExportCaptions';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { CancelModalButton, DestructiveModalButton } from '../../common/styles';
import ConfirmationDialog from '../../../common/ConfirmatonDialog';
import { ContentModalContainer } from '../../../common/DialogStyles';
import { deleteVirtualEventCaptions } from '../../../../../../../../services/api/eureka';

const Separator = styled.div`
    background-color: #efefef;
    height: 8px;
`;

const buttonStyle = {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 'calc(100% - 56px)',
    marginLeft: '56px',
    textTransform: 'none',
    marginTop: '12px',
    marginBottom: '16px',
};

// Strings
const DIALOG_TITLE = 'Session settings';
const PLACEHOLDER_IMAGE_TITLE = 'Placeholder slides image';
const FEATURES_TITLE = 'Features';
const ADVANCED_TITLE = 'Advanced';
const CHAT_LABEL = 'Chat';
const HIDE_VOTES_NUMBER_LABEL = 'Hide number of votes';
const QA_LABEL = 'Q&A';
const ATTENDEES_LABEL = 'Attendees';
const CAPTIONS_LABEL = 'Captions';
const SPEAKER_FRAME_LABEL = 'Speaker Frame';
const SLIDES_FRAME_LABEL = 'Slides Frame';
const SLIDES_IMAGE_UPLOAD_MESSAGE = `Recommended size: 1280x720, PNG or JPG, below 2MB. Larger images can be cropped after uploading.`;
const PRESENTATION_LINK_TITLE = 'Presentation screen link';
const VOTE_LINK_TITLE = 'Vote link';
const PRESENTATION_TITLE = 'Presentation';
const DELETE_CAPTIONS_BUTTON = 'Delete captions';

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    margin-bottom: -3px;
`;

const SettingsModal = ({ onClose, questionSetsId, nonVirtualQA, externalObject }) => {
    const [featureValues, setValues] = useState({
        chat: true,
        qa: true,
        attendees: true,
        showCaptions: false,
    });
    const [QAValues, setQAValues] = useState({
        qa: false,
    });
    const [speakerLanguageValue, setSpeakerLanguageValue] = useState('en');
    const [pollSet, setPollSet] = useState(null);
    const [hideVotesNumber, setHideVotesNumber] = useState(false);
    const [questionSet, setQuestionSet] = useState({});
    const [eventName, setEventName] = useState(null);
    const [eventUniqueName, setEventUniqueName] = useState('');
    const [loading, setLoading] = useState(false);
    const [activeRadioBoxIndex, setActiveRadioBoxIndex] = useState(0);
    // Image state
    const [previewPlaceholder, setPreviewPlaceholder] = useState(null);
    const [previewSpeakerPlaceholder, setPreviewSpeakerPlaceholder] = useState(null);
    const [file, setFile] = useState(null);
    const [uploadImageModal, setUploadImageModal] = useState(null);
    const [uploadSpeakerImageModal, setUploadSpeakerImageModal] = useState(null);
    const [temporaryImage, setTemporaryImage] = useState(null);
    const [temporarySpeakersImage, setTemporarySpeakersImage] = useState(null);
    const [showDeleteCaptionsModal, setShowDeleteCaptionsModal] = useState(false);

    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();

    const { sessionId, virtualEventSession } = stateCtx;
    const virtualEventSessionId = virtualEventSession && virtualEventSession.data.id;

    const port = process.env.NODE_ENV === 'development' ? `:${window.location.port}` : '';
    const beamerViewLink = `${window.location.protocol}//${window.location.hostname}${port}/event/${
        eventUniqueName || eventName
    }/virtual-beamer-view/${externalObject.data.reference}`;
    const voteLink = `${window.location.protocol}//${window.location.hostname}${port}/event/${
        eventUniqueName || eventName
    }/vote-session/${externalObject.data.reference}`;
    const placeHolderUrl = temporaryImage || virtualEventSession.data.presentationPlaceholderUrl;
    const speakersPlaceHolderUrl =
        temporarySpeakersImage || virtualEventSession.data.presentationSpeakerPlaceholderUrl;

    const history = useHistory();

    useEffect(() => {
        let match = matchPath(history.location.pathname, {
            path: '/event/:eventName',
        });

        if (!match) {
            match = matchPath(history.location.pathname, {
                path: '/kiosk/:eventName',
            });
        }

        if (match && match.params.eventName) {
            setEventName(match.params.eventName);
        }
    }, [history]);

    useEffect(() => {
        (async () => {
            const { events } = await getLocalAppStateAsync();
            if (events) {
                const currentEvent = events.find(e => e.id === externalObject.data.Event.reference);

                setEventUniqueName(currentEvent.uniqueName);
            }
        })();
    }, [externalObject]);

    useEffect(() => {
        const loadQA = async () => {
            const qSet = await getQuestionSetByExternalObject(externalObject.data.id);

            if (questionSet) {
                setQuestionSet(qSet);
                setQAValues({
                    qa: qSet.state && qSet.state === 'open',
                });
            }
        };

        const fetchPollSet = async () => {
            const pollSet = await getPollSetByExternalObject(externalObject.data.id);
            setHideVotesNumber(pollSet.hideVotesNumber);
            setPollSet(pollSet);
        };

        loadQA();
        if (nonVirtualQA) {
            fetchPollSet();
            return;
        }

        const {
            showChat,
            showQA,
            showAttendees,
            showCaptions,
            showBlockedAttendees,
            hideVotesNumber,
            speakerLanguage,
        } = virtualEventSession.data;

        setSpeakerLanguageValue(speakerLanguage);
        setValues({
            chat: showChat,
            qa: showQA,
            attendees: showAttendees,
            showCaptions,
        });
        setHideVotesNumber(hideVotesNumber);
        setActiveRadioBoxIndex(showBlockedAttendees ? 1 : 0);
    }, []);

    console.log('LOGGER questionSet', questionSet);
    const onFeatureChange = event => {
        const { name, checked } = event.target;
        setValues({
            ...featureValues,
            [name]: checked,
        });
    };

    const onToggleHideVotesNumbers = event => {
        const { checked } = event.target;
        setHideVotesNumber(checked);
    };

    const onQASettingChange = event => {
        const { name, checked } = event.target;
        setQAValues({
            [name]: checked,
        });
    };

    const onImageChange = async file => {
        const resizedFile = await compressImage(file);

        setFile(resizedFile);
        setPreviewPlaceholder(URL.createObjectURL(resizedFile));
        setUploadImageModal(true);
    };

    const onSpeakerImageChange = async file => {
        const resizedFile = await compressImage(file);

        setFile(resizedFile);
        setPreviewSpeakerPlaceholder(URL.createObjectURL(resizedFile));
        setUploadSpeakerImageModal(true);
    };

    const convertBase64ToFile = speakerPlaceholder => {
        var arr = speakerPlaceholder
                ? temporarySpeakersImage.split(',')
                : temporaryImage.split(','),
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], file.name, { type: file.type });
    };

    const uploadPlaceholderImage = async (speakerPlaceholder = false) => {
        const imageFile = convertBase64ToFile(speakerPlaceholder);

        mutationCtx.setVirtualEventSession({
            ...virtualEventSession,
            uploadRequestStatus: entities.requestStatus.loading,
        });

        const response = await uploadPlaceholderToS3(imageFile, sessionId);

        mutationCtx.setVirtualEventSession({
            ...virtualEventSession,
            uploadRequestStatus: entities.requestStatus.success,
            data: {
                ...virtualEventSession.data,
                [speakerPlaceholder
                    ? 'presentationSpeakerPlaceholderUrl'
                    : 'presentationPlaceholderUrl']: response.link,
            },
        });

        stateCtx.socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                mode: virtualEventSession.data.mode,
                status: virtualEventSession.data.status,
                [speakerPlaceholder
                    ? 'presentationSpeakerPlaceholderUrl'
                    : 'presentationPlaceholderUrl']: response.link,
            },
        });

        if (speakerPlaceholder) {
            setPreviewSpeakerPlaceholder(null);
            setUploadSpeakerImageModal(false);
        } else {
            setPreviewPlaceholder(null);
            setUploadImageModal(false);
        }
    };

    const uploadTemporaryPlaceholderImage = image => {
        setTemporaryImage(image);
        setUploadImageModal(false);
    };

    const uploadTemporarySpeakerPlaceholderImage = image => {
        setTemporarySpeakersImage(image);
        setUploadSpeakerImageModal(false);
    };

    const onSave = async () => {
        setLoading(true);

        if (nonVirtualQA) {
            await saveQuestionSet({
                ...questionSet,
                state: QAValues.qa ? 'open' : 'closed',
            });
            const data = {};
            data.objectId = externalObject.data.id;
            await savePollSet({ ...pollSet, hideVotesNumber });
            stateCtx.socket.emit('setQuestions', data);
            stateCtx.socket.emit('updatePollSet', {
                objectId: sessionId,
                id: questionSetsId,
            });
        } else {
            const updatedValues = {
                showBlockedAttendees: activeRadioBoxIndex === 1,
                showChat: featureValues.chat,
                showQA: featureValues.qa,
                showAttendees: featureValues.attendees,
                showCaptions: featureValues.showCaptions,
                hideVotesNumber,
                speakerLanguage: speakerLanguageValue,
            };

            if (previewPlaceholder) {
                await uploadPlaceholderImage();
            }

            if (previewSpeakerPlaceholder) {
                await uploadPlaceholderImage(true);
            }

            stateCtx.socket.emit('updateData', {
                objectId: sessionId,
                virtualEventSession: {
                    status: virtualEventSession.data.status,
                    ...updatedValues,
                },
            });

            const questionSetState = featureValues.qa ? 'open' : 'closed';
            await saveQuestionSet({
                ...questionSet,
                state: featureValues.qa ? 'open' : 'closed',
            });

            stateCtx.socket.emit('updatePollSet', {
                objectId: sessionId,
                id: questionSetsId,
                state: questionSetState,
            });
        }

        setLoading(false);
        onClose();
    };

    const makePresentationUrl = () =>
        `${window.location.protocol}//${window.location.hostname}${port}/qa-presentation/${externalObject.data.id}`;

    if (uploadImageModal) {
        return (
            <CropDialog
                previewPlaceholder={previewPlaceholder}
                onClose={() => setUploadImageModal(false)}
                onSave={uploadTemporaryPlaceholderImage}
            />
        );
    }

    if (uploadSpeakerImageModal) {
        return (
            <CropDialog
                previewPlaceholder={previewSpeakerPlaceholder}
                onClose={() => setUploadSpeakerImageModal(false)}
                onSave={uploadTemporarySpeakerPlaceholderImage}
            />
        );
    }

    const dialogButtons = [
        { title: 'Save', variant: 'contained', color: 'primary', onClick: onSave },
        { title: 'Cancel', variant: 'contained', color: 'inherit', onClick: onClose },
    ];

    return (
        <div className="eureka-react">
            <BasicDialog
                visible
                title={DIALOG_TITLE}
                onClose={onClose}
                contentStyle={{ padding: 0 }}
                options={dialogButtons}
            >
                {!nonVirtualQA && (
                    <>
                        <Card title={PLACEHOLDER_IMAGE_TITLE} icon={<ImageRoundedIcon />}>
                            <ImageUpload
                                subtitle={SLIDES_IMAGE_UPLOAD_MESSAGE}
                                placeHolderUrl={placeHolderUrl}
                                onImageChange={onImageChange}
                                imageTypeText={SLIDES_FRAME_LABEL}
                                onDeleteImage={() => {
                                    stateCtx.socket.emit('updateData', {
                                        objectId: sessionId,
                                        virtualEventSession: {
                                            presentationPlaceholderUrl: null,
                                        },
                                    });
                                }}
                            />
                            <ImageUpload
                                placeHolderUrl={speakersPlaceHolderUrl}
                                onImageChange={onSpeakerImageChange}
                                imageTypeText={SPEAKER_FRAME_LABEL}
                                onDeleteImage={() => {
                                    stateCtx.socket.emit('updateData', {
                                        objectId: sessionId,
                                        virtualEventSession: {
                                            presentationSpeakerPlaceholderUrl: null,
                                        },
                                    });
                                }}
                            />
                        </Card>
                        <Separator />
                        <Card title={FEATURES_TITLE} icon={<CheckCircleIcon />}>
                            <FeatureItem
                                name="chat"
                                onChange={onFeatureChange}
                                label={CHAT_LABEL}
                                checked={featureValues.chat}
                            />
                            <FeatureItem
                                name="qa"
                                onChange={onFeatureChange}
                                label={QA_LABEL}
                                checked={featureValues.qa}
                            />
                            <FeatureItem
                                name="attendees"
                                onChange={onFeatureChange}
                                label={ATTENDEES_LABEL}
                                checked={featureValues.attendees}
                            />
                            <FeatureItem
                                name="showCaptions"
                                onChange={onFeatureChange}
                                label={CAPTIONS_LABEL}
                                checked={featureValues.showCaptions}
                                description="Allow attendees to view captions of the audio in this session"
                            >
                                <LanguageSelect
                                    value={speakerLanguageValue}
                                    onChange={setSpeakerLanguageValue}
                                    id="speakerLanguage"
                                    useSpeechRecognitionLanguages
                                />
                            </FeatureItem>
                        </Card>
                    </>
                )}
                {nonVirtualQA && (
                    <>
                        <Card title={FEATURES_TITLE} icon={<CheckCircleIcon />}>
                            <FeatureItem
                                name="qa"
                                onChange={onQASettingChange}
                                label={QA_LABEL}
                                checked={QAValues.qa}
                            />
                        </Card>
                    </>
                )}
                <Separator />
                <Card title={PRESENTATION_TITLE} icon={<PresentToAllIcon />}>
                    <FeatureItem
                        name="hideVotesNumber"
                        onChange={onToggleHideVotesNumbers}
                        label={HIDE_VOTES_NUMBER_LABEL}
                        checked={hideVotesNumber}
                        noBorderTop
                    />
                    {!nonVirtualQA && (
                        <LinkCard title={PRESENTATION_LINK_TITLE} url={beamerViewLink} />
                    )}
                    {nonVirtualQA && (
                        <>
                            <LinkCard title={PRESENTATION_LINK_TITLE} url={makePresentationUrl()} />
                        </>
                    )}
                </Card>
                <Separator />
                <Card title={ADVANCED_TITLE} icon={<SettingsRoundedIcon />}>
                    <ExportChat sessionId={sessionId} />
                    {!nonVirtualQA && (
                        <>
                            <ExportCaptions virtualEventSessionId={virtualEventSessionId} />
                            <Button
                                variant="contained"
                                startIcon={<DeleteIcon />}
                                disableElevation
                                style={buttonStyle}
                                onClick={() => setShowDeleteCaptionsModal(true)}
                            >
                                {DELETE_CAPTIONS_BUTTON}
                            </Button>
                        </>
                    )}
                    <LinkCard title={VOTE_LINK_TITLE} url={voteLink} />
                </Card>
                {loading && <Loader />}
                <ConfirmationDialog
                    open={showDeleteCaptionsModal}
                    title="Delete captions"
                    titleIcon="delete_forever"
                    iconColor="rgba(0, 0, 0, 0.87)"
                    iconWrapperColor="#EFEFEF"
                    withCloseButton
                    zIndex={1000}
                    content={
                        <ExtraStyledModalContentContainer>
                            Are you sure you want to delete all captions that were recorded in this
                            session? Once deleted, they cannot be retrieved.
                        </ExtraStyledModalContentContainer>
                    }
                    onClose={() => setShowDeleteCaptionsModal(false)}
                    buttons={[
                        <DestructiveModalButton
                            key="DVQD"
                            flat
                            onClick={() => {
                                deleteVirtualEventCaptions(virtualEventSessionId);
                                setShowDeleteCaptionsModal(false);
                            }}
                        >
                            Delete
                        </DestructiveModalButton>,
                        <CancelModalButton
                            key="DVQC"
                            flat
                            onClick={() => setShowDeleteCaptionsModal(false)}
                        >
                            Cancel
                        </CancelModalButton>,
                    ]}
                />
            </BasicDialog>
        </div>
    );
};

export default SettingsModal;

import React from 'react';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';

import Button, { buttonTypes } from '../../../common/Button';
import { selectResources } from '../../selectors';
import { openExternalLink } from '../../common';

const CategoryContainer = styled.div`
    padding: 18px 0;

    &:not(:last-child) {
        border-bottom: solid 1px #e2e3e7;
    }
`;

const Category = styled.div`
    opacity: 0.38;
    font-family: 'Cabin', sans-serif;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.87);
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 16px;
`;

const ItemWrapper = styled.div`
    width: 100%;
    padding: 14px 18px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    div:first-of-type {
        width: 80%;
    }
`;

const ButtonContainer = styled.div`
    width: 50px;
    height: 50px;
`;

const ItemName = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.87);
`;

const ItemSize = styled.div`
    opacity: 0.54;
    font-family: 'Cabin', sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.54;
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DOWNLOAD_ICON = 'get_app';
const LAUNCH_ICON = 'launch';

const Resources = ({ resources }) => {
    const openLink = url => {
        openExternalLink(url);
    };

    const renderItem = item => {
        const { name, url, mimeType } = item;
        const isPDF = mimeType && mimeType.includes('pdf');
        const icon = isPDF ? DOWNLOAD_ICON : LAUNCH_ICON;

        return (
            <ItemWrapper>
                <div>
                    <ItemName>{name}</ItemName>
                    <ItemSize>{!isPDF && url}</ItemSize>
                </div>
                <ButtonContainer>
                    <Button type={buttonTypes.GREEN} icon={icon} onClick={() => openLink(url)} />
                </ButtonContainer>
            </ItemWrapper>
        );
    };

    const renderCategory = resource => {
        const { title, items } = resource;
        let resourceItems = items;
        if (resourceItems && resourceItems.length > 0) {
            resourceItems = sortBy(resourceItems, o => o.ordering);
        }

        return (
            <>
                <Category>{title}</Category>
                {resourceItems && resourceItems.map(renderItem)}
            </>
        );
    };

    return <CategoryContainer>{resources.map(renderCategory)}</CategoryContainer>;
};

const mapStateToProps = state => ({
    resources: selectResources(state.exhibitorBooth),
});

export default connect(mapStateToProps)(Resources);

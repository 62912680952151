import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';
import { useDispatch } from 'react-redux';

import { Flex } from '../../VirtualSession/components/userInteraction/questionsAndAnswers/QuestionsAndAnswers';
import ProgressBar from './ProgressBar';
import { useTheme } from '../../../components/Theme/ThemeContext';
import { ACHIEVEMENT_TASK_TYPE } from '../constants';
import { claimReward } from '../actions';

const Card = styled.button`
    width: 233px;
    padding: 24px 16px 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(60, 63, 68, 0.15);
    background-color: #ffffff;
    margin: 16px 0;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    position: relative;
    cursor: pointer;
    border: none;

    &:hover {
        background-color: #efefef;
    }
`;

const LevelsTag = styled.div`
    width: 40px;
    height: 20px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-top: solid 1px #d8dce1;
    border-right: solid 1px #d8dce1;
    border-left: solid 1px #d8dce1;
    font-size: 13px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
    line-height: 32px;
    position: absolute;
    top: 8px;
    right: 8px;

    &:after {
        content: '';
        width: 28px;
        height: 28px;
        left: 5px;
        top: 5px;
        position: absolute;
        border-right: 1px solid #d8dce1;
        border-top: 1px solid #d8dce1;
        transform: rotate(135deg);
        transition: border 0.4s ease-in-out;
        border-top-right-radius: 4px;
    }
`;

const DetailsContainer = styled(Flex)`
    flex-direction: column;
    height: 100%;
`;

const Icon = styled.div`
    width: 72px;
    height: 72px;
    background-color: #dde1e5;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Title = styled.div`
    font-size: 15px;
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    margin-top: 17px;
    margin-bottom: 8px;
`;

const Text = styled.div`
    font-size: 15px;
    font-weight: normal;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.5);
    width: 100%;
`;

const Subtitle = styled(Text)`
    margin-bottom: 16px;
    margin-top: ${props => props.marginTop}px;
`;

const PointsLabel = styled.div`
    width: 36px;
    height: 36px;
    padding: 9px;
    background-color: ${props => props.color};
    border-radius: 50%;
    border: 3px solid #fff;
    position: absolute;
    top: 42px;
    left: 50px;
    font-weight: 900;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CardIconWrapper = styled.div`
    position: relative;
`;

const StyledAchievementIcon = styled(FontIcon)`
    color: #fff !important;
    font-size: 32px !important;
`;

const StyledClaimRewardButton = styled.div`
    background-color: #3c7dff;
    color: white;
    text-align: center;
    padding: 9px 18px;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    margin-top: 12px;
`;

const StyledViewRewardButton = styled(StyledClaimRewardButton)``;

const StyledLevelDone = styled.div`
    width: 100%;
    text-align: center;
    padding: 10px 0;
    border: 1px solid #d8dce1;
    border-radius: 6px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: normal;
    margin-top: 8px;
`;

const TaskLevelContainer = styled.div`
    margin-top: 12px;
`;

const StyledCompletedLevelIcon = styled(FontIcon)`
    font-size: 36px !important;
    color: #d8dce1 !important;
`;

const AchievementCard = ({
    onClick,
    onViewRewardClick,
    achievement: achievementData,
    showLevelDoneInfo = false,
}) => {
    const { theme } = useTheme();
    const dispatch = useDispatch();
    const {
        Achievement: achievement,
        currentLevel,
        currentValue,
        rewardClaimed,
        rewardUnblocked,
    } = achievementData;
    const { rewardOn } = achievement;
    const numberOfLevels = achievement.levels.length;
    const currentLevelData = achievement.levels[currentLevel - 1];
    const currentLevelTasks = currentLevelData.taskParameter;
    const taskType = currentLevelData.typeParameter;
    const isLevelDone = currentValue === currentLevelTasks;

    const handleClaimReward = async e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        dispatch(claimReward(achievement.id));
    };

    return (
        <Card onClick={onClick}>
            <LevelsTag>{`${currentLevel}/${numberOfLevels}`}</LevelsTag>
            <DetailsContainer
                alignitems={'center'}
                justifycontent={rewardClaimed ? 'flex-start' : 'space-between'}
            >
                <CardIconWrapper>
                    <Icon>
                        <StyledAchievementIcon>{achievement.icon}</StyledAchievementIcon>
                    </Icon>
                    {(isLevelDone && showLevelDoneInfo) || rewardClaimed ? (
                        <PointsLabel color="#fff">
                            <StyledCompletedLevelIcon>
                                check_circle_outline
                            </StyledCompletedLevelIcon>
                        </PointsLabel>
                    ) : (
                        <PointsLabel color={theme.contrast}>{currentLevelData.points}</PointsLabel>
                    )}
                </CardIconWrapper>
                <>
                    <Title>{achievement.title}</Title>
                    <Subtitle marginTop={!showLevelDoneInfo && rewardClaimed ? 8 : 0}>
                        {achievement.subtitle}
                    </Subtitle>
                </>
                {isLevelDone && showLevelDoneInfo && (
                    <StyledLevelDone>{`You received ${currentLevelData.points} points`}</StyledLevelDone>
                )}
                {!isLevelDone && (
                    <>
                        <ProgressBar
                            precentage={(currentValue * 100) / currentLevelTasks}
                            barColor={'#dde1e5'}
                            progressColor={theme.contrast}
                        />
                        <TaskLevelContainer>
                            {taskType === ACHIEVEMENT_TASK_TYPE.STEP ? (
                                <Text>{`${currentValue}/${currentLevelTasks}`}</Text>
                            ) : (
                                <Text>{`${Math.floor(
                                    (currentValue * 100) / currentLevelTasks,
                                )}%`}</Text>
                            )}
                        </TaskLevelContainer>
                    </>
                )}
                {rewardOn && rewardUnblocked && !rewardClaimed && (
                    <StyledClaimRewardButton onClick={handleClaimReward}>
                        Claim reward
                    </StyledClaimRewardButton>
                )}
                {rewardOn && rewardClaimed && (
                    <StyledViewRewardButton onClick={onViewRewardClick}>
                        View reward
                    </StyledViewRewardButton>
                )}
            </DetailsContainer>
        </Card>
    );
};

export default AchievementCard;

import styled from 'styled-components';

export const Flex = styled.div`
    display: flex;
    align-content: center;
    justify-content: ${props => props.justifyContent};
    align-items: ${props => props.alignItems};
    width: ${props => props.width || '100%'} !important;
    height: ${props => props.height} !important;
    flex ${props => props.flex};
    position: ${props => props.position};
    flex-direction: ${props => props.flexdirection} !important;
    margin: ${props => props.margin};
    ${props => props.flexShrink && `flex-shrink: ${props.flexShrink}`};
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Question from '../components/Question';
import { useVMMutation, useVMState } from '../../virtualFeature/containers/main';
import QuestionModal from '../components/QuestionModal';
import Dropdown from '../components/Dropdown';
import ConfirmationDialog from '../../virtualFeature/components/common/ConfirmatonDialog';
import { get } from 'lodash';
import { FontIcon } from 'react-md';
import ProgressBar from '../components/ProgressBar';
import {
    CancelModalButton,
    DestructiveModalButton,
    SaveModalButton,
} from '../../virtualFeature/components/moderator/common/styles';
import useVote from '../hooks/useVote';
import {
    ButtonContainer,
    ExtraStyledModalContentContainer,
    GreyStyledButton,
    PlayIcon,
    StyledButton,
    StyledStopIcon,
} from '../components/voteStyles';
import entities from '../../../../VirtualSession/constants/entities';

const Wrapper = styled.div`
    margin: 20px 0;
`;

const FlexRow = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const SectionTitle = styled.div`
    color: rgba(0, 0, 0, 0.54) !important;
    width: 127px;
    height: 16px;
    font-family: Cabin, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.43px;
    text-transform: uppercase;
`;

const StyledIcon = styled(FontIcon)`
    color: #000 !important;
    font-size: 24px !important;
    cursor: pointer;
`;

const ExtraStyledButton = styled(StyledButton)`
    width: 100% !important;
    text-align: center;
    justify-content: center;
    margin-right: 0;
`;

const Votes = (props = {}) => {
    const { pollSet, isModerator, changeFromPollsActiveRef } = props;
    const isPollSetOpen = pollSet?.state === 'open';

    const [showTimer, setShowTimer] = useState(false);
    const [timerValue, setTimerValue] = useState(20);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showResetConfirmationModal, setShowResetConfirmationModal] = useState(false);
    const [warningMessage, setWarningMessage] = useState(null);

    const mutationCtx = useVMMutation();
    const stateCtx = useVMState();
    const {
        socket,
        sessionId,
        virtualEventSession,
        user,
        activePoll: currentlyActivePoll,
        virtualEventUser,
        externalObject,
        screenShareStream,
        shareType,
    } = stateCtx;
    const {
        questions,
        currentQuestionId,
        setCurrentQuestionId,
        isVoteButtonOn,
        doResetVotes,
        doDeletePoll,
        moveQuestionUp,
        moveQuestionDown,
        isCurrentUserQuestionActive,
        isUserModeratorAndActivatedAQuestion,
        toggleVoteActivity,
        hideVoteResults,
        isActiveQuestion,
    } = useVote({ pollSet });

    const isScreenSharing = screenShareStream && shareType === 'screen';
    const vUserRole = virtualEventUser && virtualEventUser.data && virtualEventUser.data.role;
    const polls = pollSet && pollSet.Polls;
    const questionModeratorId = get(virtualEventSession, 'data.questionModeratorId', null);

    useEffect(() => {
        if (!pollSet) {
            return;
        }

        setShowTimer(pollSet.showTimer);
    }, [pollSet]);

    const toggleVoteButton = () => {
        const virtualEventUsers = get(virtualEventSession, 'data.VirtualEventUsers', []);
        const activeUser = virtualEventUsers.find(user => user.isActive) || {};

        if (user.id !== activeUser.UserId && user.id !== questionModeratorId) {
            setWarningMessage(
                'You cannot start voting, as you are currently not the speaker who is being broadcasted.',
            );
            return;
        }

        if (!isVoteButtonOn && !isActiveQuestion()) {
            setWarningMessage('Please select a question in order to start a vote.');
            return;
        }

        if (!isVoteButtonOn) {
            socket.emit('updateData', {
                objectId: sessionId,
                virtualEventSession: {
                    status: virtualEventSession.data.status,
                    voteTime: showTimer ? timerValue : 0,
                    showVoteResults: false,
                },
            });
        }

        stateCtx.socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: externalObject.data.id,
            state: isVoteButtonOn ? 'closed' : 'open',
        });
    };

    const setQuestionToDelete = id => {
        setCurrentQuestionId(id);
        setShowDeleteConfirmationModal(true);
    };

    const cancelDelete = () => {
        setCurrentQuestionId(null);
        setShowDeleteConfirmationModal(false);
    };

    const resetVotes = async () => {
        await doResetVotes();
        setShowResetConfirmationModal(false);
    };

    const deletePoll = async () => {
        await doDeletePoll();
        setShowDeleteConfirmationModal(false);
    };

    const setQuestionToEdit = questionId => {
        setCurrentQuestionId(questionId);
        setShowQuestionModal(true);
    };

    const closeQuestionForm = () => {
        setShowQuestionModal(false);
        setCurrentQuestionId(null);

        socket.emit('pollRefresh', { objectId: sessionId });
    };

    const setQuestionToResetVotes = id => {
        setCurrentQuestionId(id);
        setShowResetConfirmationModal(true);
    };

    const cancelResetVotes = () => {
        setCurrentQuestionId(null);
        setShowResetConfirmationModal(false);
    };

    const toggleActivity = async (id, noVoteResultsChange) => {
        const virtualEventUsers = get(virtualEventSession, 'data.VirtualEventUsers', []);
        const activeUser = virtualEventUsers.find(user => user.isActive) || {};

        if (!isVoteButtonOn && user.id !== activeUser.UserId) {
            const activePoll = polls.find(p => p.id === id);

            mutationCtx.setActivePoll(activePoll);
            return;
        }

        if (isVoteButtonOn) {
            setWarningMessage(
                'You cannot mark this question as active because there is already a vote running.',
            );
            return;
        }

        if (isScreenSharing) {
            setWarningMessage('You cannot start a vote while you are screen sharing.');
            return;
        }

        toggleVoteActivity(id);

        if (!isVoteButtonOn && isCurrentUserQuestionActive() && !noVoteResultsChange) {
            hideVoteResults();
        }
    };

    const backToSlides = () => {
        if (changeFromPollsActiveRef.current === false) {
            setWarningMessage(
                `You cannot ${
                    isModerator ? 'close the vote' : 'switch back to slides'
                } as there are still some processes happening in the background.
             Please wait a few seconds and try again.`,
            );
            return;
        }

        const activePoll = polls.find(poll => poll.active);
        const activePollId = activePoll && activePoll.id;
        const pollId = currentlyActivePoll && currentlyActivePoll.id;
        const onlyLocal = activePollId !== pollId;

        if (onlyLocal) {
            mutationCtx.setActivePoll(null);
            return;
        }

        stateCtx.socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: externalObject.data.id,
            pollChange: {
                id: pollId,
                active: false,
            },
        });

        stateCtx.socket.emit('updateData', {
            objectId: stateCtx.sessionId,
            virtualEventSession: {
                questionModeratorId: null,
            },
        });

        mutationCtx.setActivePoll(null);

        if ((!isVoteButtonOn && isCurrentUserQuestionActive()) || user.id === questionModeratorId) {
            hideVoteResults();
        }
    };

    const viewResults = async questionId => {
        const showVoteResults = get(virtualEventSession, 'data.showVoteResults', null);

        if (
            !currentlyActivePoll ||
            (currentlyActivePoll && currentlyActivePoll.id !== questionId)
        ) {
            await toggleActivity(questionId, true);
        }

        if (showVoteResults === true) {
            return;
        }

        stateCtx.socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                status: virtualEventSession.data.status,
                showVoteResults: true,
            },
        });
    };

    const stopVoting = async questionId => {
        await viewResults(questionId);

        stateCtx.socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: externalObject.data.id,
            state: 'closed',
        });
    };

    const renderButtons = () => {
        if (
            (currentlyActivePoll && isCurrentUserQuestionActive()) ||
            isUserModeratorAndActivatedAQuestion()
        ) {
            return (
                <ButtonContainer>
                    <StyledButton
                        flat
                        onClick={
                            isVoteButtonOn
                                ? () => stopVoting(currentlyActivePoll.id)
                                : toggleVoteButton
                        }
                    >
                        {isVoteButtonOn ? <StyledStopIcon /> : <PlayIcon />}
                        {isVoteButtonOn ? 'Stop vote' : 'Start vote'}
                    </StyledButton>
                    {!isVoteButtonOn && (
                        <GreyStyledButton onClick={backToSlides}>
                            {isModerator ? 'Close vote' : 'Back to slides'}
                        </GreyStyledButton>
                    )}
                </ButtonContainer>
            );
        }

        return null;
    };

    return (
        <Wrapper className="eureka-react">
            {currentlyActivePoll &&
                isCurrentUserQuestionActive() &&
                isPollSetOpen &&
                currentlyActivePoll.useTimer && (
                    <ProgressBar
                        pollSet={pollSet}
                        activePoll={currentlyActivePoll}
                        activeColor={'#1fa294'}
                        stopVoting={stopVoting}
                    />
                )}
            {renderButtons()}
            <FlexRow>
                <SectionTitle>Voting Questions</SectionTitle>
                <StyledIcon onClick={() => setShowQuestionModal(true)}>add</StyledIcon>
            </FlexRow>
            {questions
                .filter(q =>
                    vUserRole === entities.virtualEventUserEntity.role.moderator
                        ? true
                        : q?.user?.id === user?.id,
                )
                .map((question, index) => (
                    <Question
                        index={index}
                        key={`question-${index}`}
                        onClick={() => toggleActivity(question.id)}
                        question={question}
                        actionComponent={
                            <Dropdown
                                id={`question-${index}`}
                                key={`question-dropdown-${index}`}
                                active={question.active}
                                resultsNumber={question.totalVotes}
                                index={index}
                                isLastInList={index === questions.length - 1}
                                onMoveUp={() => moveQuestionUp(index)}
                                onMoveDown={() => moveQuestionDown(index)}
                                onViewResults={() => viewResults(question.id)}
                                onResetVotes={() => setQuestionToResetVotes(question.id)}
                                onEdit={() => setQuestionToEdit(question.id)}
                                onDelete={() => setQuestionToDelete(question.id)}
                            />
                        }
                    />
                ))}
            <QuestionModal
                open={showQuestionModal}
                handleClose={closeQuestionForm}
                pollSetId={pollSet ? pollSet.id : null}
                questionId={currentQuestionId}
            />
            <ConfirmationDialog
                open={showDeleteConfirmationModal}
                title="Delete poll"
                titleIcon="delete_forever"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                content={
                    <ExtraStyledModalContentContainer>
                        Are you sure you want to delete this poll?
                    </ExtraStyledModalContentContainer>
                }
                onClose={cancelDelete}
                buttons={[
                    <DestructiveModalButton key="DVQD" flat onClick={deletePoll}>
                        Delete
                    </DestructiveModalButton>,
                    <CancelModalButton key="DVQC" flat onClick={cancelDelete}>
                        Cancel
                    </CancelModalButton>,
                ]}
            />
            <ConfirmationDialog
                open={showResetConfirmationModal}
                title="Clear votes"
                titleIcon="restart_alt"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                content={
                    <ExtraStyledModalContentContainer>
                        Are you sure you want to clear the answers? All votes will be deleted.
                    </ExtraStyledModalContentContainer>
                }
                onClose={cancelResetVotes}
                onNo={cancelResetVotes}
                onYes={resetVotes}
                buttons={[
                    <SaveModalButton key="SRDR" flat onClick={resetVotes}>
                        Yes
                    </SaveModalButton>,
                    <CancelModalButton key="SRDC" flat onClick={cancelResetVotes}>
                        Cancel
                    </CancelModalButton>,
                ]}
            />
            <ConfirmationDialog
                open={!!warningMessage}
                title="Warning"
                titleIcon="warning"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                outlinedIcon
                onClose={() => setWarningMessage(null)}
                content={
                    <ExtraStyledModalContentContainer>
                        {warningMessage}
                    </ExtraStyledModalContentContainer>
                }
                buttons={[
                    <ExtraStyledButton key="WMDO" flat onClick={() => setWarningMessage(null)}>
                        Ok
                    </ExtraStyledButton>,
                ]}
            />
        </Wrapper>
    );
};

export default Votes;

import { eurekaRequest, asyncEurekaRequest } from '../../../services/api/eureka';

export const getUserAttending = async () => {
    return asyncEurekaRequest('get', `/api/entities/users/getUserAttending`, null, true);
};

export const markAsSeen = id => {
    const data = {
        id,
    };
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'put',
            '/api/entities/externalObjects/markAsSeen',
            data,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const getEurekaEventByReference = eventId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/events/reference/${eventId}`,
            null,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const getByUserEvent = eventId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/externalObjects/me/event/${eventId}`,
            null,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

export const getVirtualEventSession = sessionId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/virtualEventSession/session/${sessionId}`,
            null,
            true,
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    font-family: Cabin;
    font-size: 38px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.38);
`;

const FullscreenWrapper = ({ text }) => <Wrapper>{text}</Wrapper>;

export default FullscreenWrapper;

import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-md';
import * as palette from './Variables';

export const BlueButton = styled.div`
    width: ${props => (props.width ? props.width : '240px')};
    background-color: ${props => (props.color ? props.color : '#054289')};
    color: white;
    text-align: center;
    padding: 9px 18px;
    border-radius: 18px;
    margin-top: 50px;
    cursor: pointer;
`;

export const GreenButton = styled.button`
    background-color: #1fa294;
    padding: 9px 16px;
    border-radius: 4px;
    color: #ffffff;
    border: 0;
    outline: none;
    cursor: pointer;

    &:hover {
        background-color: #44bb90;
    }

    &:disabled {
        background-color: rgba(31, 162, 148, 0.5);
        cursor: default;
    }
`;

const BaseButton = styled.button`
    height: 40px;
    width: fit-content;
    padding: 8px 16px;
    border-radius: 6px;
    border: 0;
    outline: none;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 15px;
`;

export const SocietyButton = styled(BaseButton)`
    background-color: ${props => props.primary || palette.SOCIETY_COLORS.PRIMARY};
    color: #ffffff;
`;

export const GreyButton = styled(BaseButton)`
    background-color: #efefef;
    color: #1e1e1f;
`;

export const GreenButtonOutlined = styled.button`
    background-color: #ffffff;
    color: #1fa294;
    border: 1px solid #1fa294;
    padding: 9px 16px;
    border-radius: 4px;
    outline: none;
    cursor: pointer;

    &:hover {
        background-color: #44bb90;
        color: #ffffff;
    }

    &:disabled {
        background-color: rgba(31, 162, 148, 0.5);
        cursor: default;
    }
`;

export const PrimaryButton = ({ children, ...props }) => {
    return <GreenButton {...props}>{children}</GreenButton>;
};

export const EnterVirtualSessionButton = styled(Button)`
    border: 1px solid ${props => props.primarycolor} !important;
    margin-left: 16px !important;
    margin-top: 27px !important;
    margin-bottom: 27px !important;
    border-radius: ${props => (props.theming === 'inversed' ? '2px' : '4px')} !important;
    color: ${props =>
        props.theming === 'inversed' ? props.primarycolor : palette.COLOR_WHITE} !important;
    background-color: ${props =>
        props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor} !important;
    display: block;

    &.md-btn--dialog,
    &.md-btn--text {
        text-transform: none;
        font-size: 16px;
        height: 36px;
        font-weight: 500;
        padding: 8px 16px;
    }

    &:hover {
        background-color: ${props =>
            props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor};
    }
`;

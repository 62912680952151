import React, { useEffect, useState } from 'react';

import { StyledInfo, StyledTitle } from './styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LockIcon from '@mui/icons-material/Lock';
import { getLocalAppStateAsync } from '../../services/api/db';

const NoAttendingPermissionModal = () => {
    const [eventName, setEventName] = useState('');
    const TITLE = 'No permission';
    const DESCRIPTION = 'You do not have permission to enter the event:';

    useEffect(() => {
        const loadEvent = async () => {
            const state = await getLocalAppStateAsync();
            setEventName(state?.eventTitle);
        };
        loadEvent();
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Card sx={{ minWidth: 500 }}>
                <CardContent>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexDirection: 'column',
                            paddingTop: '50px',
                            paddingBottom: '50px',
                        }}
                    >
                        <div
                            style={{
                                margin: '10px',
                                width: 50,
                                height: 50,
                                borderRadius: 25,
                                background: '#EFEFEF',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <LockIcon fontSize="large" />
                        </div>
                        <StyledTitle>{TITLE}</StyledTitle>
                        <StyledInfo>{DESCRIPTION}</StyledInfo>
                        <StyledInfo style={{ fontWeight: 600 }}>{eventName}</StyledInfo>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default NoAttendingPermissionModal;

import {
    Card,
    CardDateTimeLocationWrapper,
    CardTitle,
    FlexButtonsWrapper,
    TimeslotVirtualSessionInvisible,
} from '../style';
import Button, { buttonTypes } from '../../common/Button';
import { getString } from '../../../services/api/store';
import React, { useEffect, useState } from 'react';
import { ConfirmationModel } from './AppointmentConfirmationModal';
import { getVirtualEventSessionByAppointmentId } from '../../../services/api/eureka';
import TimeslotVirtualSession from '../../../components/DetailHeader/TimeslotVirtualSession';
import moment from 'moment';

const AppointmentCard = ({
    theme,
    appointmentToDisplay,
    getDateAndTime,
    cancelMeeting,
    openDetails,
    showCancelMeetingModal,
    deleteAppointment,
    setShowCancelMeetingModal,
}) => {
    const [virtualEventSession, setVirtualEventSession] = useState(null);

    const DELETE_MEETING_MESSAGE =
        getString('meetingDeleteMessage') ||
        'Meeting will be removed from your calendar and that of the invited attendee.';

    useEffect(() => {
        (async () => {
            if (appointmentToDisplay && appointmentToDisplay.isVirtual) {
                const virtualEventSession = await getVirtualEventSessionByAppointmentId(
                    appointmentToDisplay.id,
                );

                setVirtualEventSession(virtualEventSession);
            }
        })();
    }, [appointmentToDisplay]);
    const appointmentStatus =
        appointmentToDisplay &&
        appointmentToDisplay.members &&
        appointmentToDisplay.members[0] &&
        appointmentToDisplay.members[0].AppointmentGroup &&
        appointmentToDisplay.members[0].AppointmentGroup.status;

    const formatTime = (start, end) => {
        return `${start.format('ddd').toUpperCase()}, ${start.format('HH:mm')} - ${end.format(
            'HH:mm',
        )}`;
    };

    let start = moment.utc(appointmentToDisplay.start);
    if (!start.isValid()) {
        start = moment.unix(appointmentToDisplay.start);
    }
    let end = moment.utc(appointmentToDisplay.end);
    if (!end.isValid()) {
        end = moment.unix(appointmentToDisplay.end);
    }

    return (
        <React.Fragment>
            <Card>
                <CardTitle>{appointmentToDisplay.name}</CardTitle>
                <CardDateTimeLocationWrapper>
                    <div>{getDateAndTime(appointmentToDisplay)}</div>
                    <div>{appointmentToDisplay.contentLocation}</div>
                </CardDateTimeLocationWrapper>
                <FlexButtonsWrapper>
                    {appointmentStatus === 0 && (
                        <Button
                            type={buttonTypes.GREEN_REVERSE}
                            background={theme.primary}
                            text={getString('meetingDeleteButton') || 'Delete meeting'}
                            onClick={cancelMeeting}
                        />
                    )}
                    {appointmentStatus === 1 &&
                        appointmentToDisplay.isVirtual &&
                        !appointmentToDisplay.deleted && (
                            <TimeslotVirtualSession
                                time={formatTime(start, end)}
                                start={start.format()}
                                end={end.format()}
                                id={appointmentToDisplay.reference}
                                params={{
                                    backgroundType: '',
                                    virtualSession: 'virtual-room',
                                    virtualSessionType: 'Appointment virtual room',
                                }}
                                virtualEventSession={virtualEventSession}
                                customButtonType={buttonTypes.GREEN}
                                customButtonText={'Go to meeting'}
                                customButton={true}
                            />
                        )}
                    <Button
                        type={buttonTypes.GREY}
                        background={theme.primary}
                        text={getString('meetingViewDetailsButton') || 'View details'}
                        onClick={() => openDetails(appointmentToDisplay.id)}
                    />
                </FlexButtonsWrapper>
            </Card>
            <ConfirmationModel
                showModal={showCancelMeetingModal === appointmentToDisplay.id}
                title={getString('meetingDeleteTitle') || 'Delete meeting?'}
                text={DELETE_MEETING_MESSAGE}
                confirm={() => deleteAppointment(appointmentToDisplay)}
                cancel={() => setShowCancelMeetingModal(null)}
                confirmText={getString('meetingDeleteConfirmButton') || 'Delete'}
                cancelText={getString('meetingDeleteCancelButton') || 'Cancel'}
            />
        </React.Fragment>
    );
};

export default AppointmentCard;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Avatar, FontIcon, Tooltipped } from 'react-md';
import { connect } from 'react-redux';

import { getInitials } from '../../../VirtualSession/components/userInteraction/attendees/Attendees';
import Auth from '../../../../services/api/auth';
import { selectBusinessCardFeatureData, selectContactInfo, selectTitle } from '../../selectors';
import facebookIcon from '../../../../assets/social/icon-filled-facebook.png';
import twitterIcon from '../../../../assets/social/icon-filled-twitter.png';
import linkedinIcon from '../../../../assets/social/icon-filled-linkedin.png';
import Loader from '../../../../components/General/Loader';
import { sendEmail as sendEmailAction } from '../../actions';
import Button, { buttonTypes } from '../../../common/Button';
import { openExternalLink } from '../../common';
import {
    AchievementType,
    useAchievementActions,
} from '../../../Achievements/hooks/useAchievementActions';
import Textarea from '../../../../components/TextInput/Textarea';
import { getString } from '../../../../services/api/store';
import { useTheme } from '../../../../components/Theme/ThemeContext';
import toast from 'toasted-notes';
import NotificationToast from '../../../../components/General/NotificationToast';

export const ContactButtonsWrapper = styled.div`
    display: flex;
    margin-bottom: 24px;
`;

export const ContactButton = styled.button`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: solid 1px #e2e4e7;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent;

    &:not(:last-child) {
        margin-right: 12px;
    }

    img {
        width: 24px;
        height: 24px;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.04);
    }

    &:active {
        background: rgba(0, 0, 0, 0.12);
    }
`;

export const ContactButtonIcon = styled(FontIcon)`
    color: rgba(0, 0, 0, 0.87) !important;
`;

export const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 16px;
`;

export const UserWrapper = styled.div`
    padding: 24px 16px;
    border-radius: 8px;
    border: solid 1px #dcdee2;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;

export const ProfileImage = styled.img`
    width: 72px;
    height: 72px;
    border-radius: 50%;
    margin: 0 16px;
`;

export const InitialsAvatar = styled(Avatar)`
    background-color: #bfbfbf;
    border: 0 solid rgba(0, 0, 0);
    margin: 0 16px;
    width: 72px !important;
    height: 72px !important;
`;

export const UserName = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
`;

export const UserDetails = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.54);
    margin-top: 8px;
`;

export const TextAreaWrapper = styled.div`
    width: 100%;
    margin-bottom: 16px;
`;

export const ButtonWrapper = styled.div`
    width: 158px;
`;

const Contact = ({
    contactInfo,
    businessCardFeatureData,
    sendEmail,
    sendingEmail,
    isEmailSent,
    exhibitorTitle,
}) => {
    const [message, setMessage] = useState('');
    const { trackAchievement } = useAchievementActions();

    const {
        contactPhoneNumber,
        contactEmail,
        contactFacebookUrl,
        contactTwitterUrl,
        contactLinkedinUrl,
    } = contactInfo;
    const { hasBusinessCardFeature, businessCardEmail } = businessCardFeatureData;
    const hasContactInfo =
        !!contactEmail ||
        !!contactFacebookUrl ||
        !!contactLinkedinUrl ||
        !!contactPhoneNumber ||
        !!contactTwitterUrl;
    const isAuthenticated = Auth.isUserAuthenticated();
    const user = Auth.getUser() || {};
    const { theme } = useTheme();

    const getUserDetails = (jobTitle, companyName) => {
        let details = jobTitle;

        if (companyName) {
            details = details.length ? `${details} - ${companyName}` : companyName;
        }

        return details;
    };

    const { firstName, lastName, jobTitle, companyName, email } = user;
    const initials = getInitials(firstName, lastName);
    const userFullName = `${firstName} ${lastName}`;
    const userDetails = getUserDetails(jobTitle, companyName);

    useEffect(() => {
        if (isEmailSent) {
            setMessage('');
        }
    }, [isEmailSent]);

    const onChange = event => {
        const {
            target: { value },
        } = event;

        setMessage(value);
    };

    const onPhoneIconClick = phoneNumber => {
        window.open(`tel:${phoneNumber}`, '_blank');
    };

    const onEmailIconClick = email => {
        window.open(`mailto:${email}`, '_blank');
    };

    const onLinkIconClick = url => {
        openExternalLink(url);
    };

    const onSendBusinessCard = async () => {
        sendEmail(businessCardEmail, message || '', exhibitorTitle);
        toast.notify(
            () => (
                <NotificationToast
                    title={'Business card was sent'}
                    subtitle={'Thank you for dropping your business card'}
                    icon={'check'}
                />
            ),
            {
                position: 'top-right',
                duration: 3000,
            },
        );
        trackAchievement(AchievementType.BUSINESS_CARD);
    };

    const renderContactSection = () => {
        if (!hasContactInfo) {
            return;
        }

        return (
            <>
                <Title>{getString('institutionBoothContactSectionTitle', 'Contact')}</Title>
                <ContactButtonsWrapper>
                    {contactPhoneNumber && (
                        <Tooltipped label={`Call ${contactPhoneNumber}`} position="top" delay={200}>
                            <ContactButton
                                style={{ position: 'relative' }}
                                onClick={() => onPhoneIconClick(contactPhoneNumber)}
                            >
                                <ContactButtonIcon active>call</ContactButtonIcon>
                            </ContactButton>
                        </Tooltipped>
                    )}
                    {contactEmail && (
                        <ContactButton onClick={() => onEmailIconClick(contactEmail)}>
                            <ContactButtonIcon>email</ContactButtonIcon>
                        </ContactButton>
                    )}
                    {contactFacebookUrl && (
                        <ContactButton onClick={() => onLinkIconClick(contactFacebookUrl)}>
                            <img src={facebookIcon} alt="Facebook" />
                        </ContactButton>
                    )}
                    {contactTwitterUrl && (
                        <ContactButton onClick={() => onLinkIconClick(contactTwitterUrl)}>
                            <img
                                src={twitterIcon}
                                alt="Twitter"
                                style={{ width: 16, height: 16 }}
                            />
                        </ContactButton>
                    )}
                    {contactLinkedinUrl && (
                        <ContactButton onClick={() => onLinkIconClick(contactLinkedinUrl)}>
                            <img src={linkedinIcon} alt="LinkedIn" />
                        </ContactButton>
                    )}
                </ContactButtonsWrapper>
            </>
        );
    };

    const renderBusinessCardSection = () => {
        if (!hasBusinessCardFeature || !businessCardEmail) {
            return null;
        }

        const TITLE = getString('institutionBusinessCardSectionTitle', 'Drop your business card');
        const TEXTAREA_PLACEHOLDER = getString(
            'institutionBusinessCardMessagePlaceholder',
            'Message (optional)',
        );
        const INFO = getString(
            'institutionBusinessCardSendInfo',
            'By clicking "Send" the information above will be shared with this exhibitor.',
        );
        const SEND_LABEL = getString('institutionBusinessCardMessageSubmit', 'Send');

        return (
            <>
                <Title>{TITLE}</Title>

                {isAuthenticated && (
                    <UserWrapper>
                        {user.imageUrl ? (
                            <ProfileImage src={user.imageUrl} alt="User" />
                        ) : (
                            <InitialsAvatar>{initials}</InitialsAvatar>
                        )}
                        <div>
                            <UserName>{userFullName}</UserName>
                            <UserDetails>{userDetails}</UserDetails>
                            <UserDetails>{email}</UserDetails>
                        </div>
                    </UserWrapper>
                )}

                <TextAreaWrapper>
                    <Textarea
                        onChange={onChange}
                        placeholder={TEXTAREA_PLACEHOLDER}
                        value={message}
                    />
                </TextAreaWrapper>

                <p>{INFO}</p>
                <ButtonWrapper>
                    <Button
                        type={buttonTypes.GREEN_LONG}
                        text={SEND_LABEL}
                        onClick={onSendBusinessCard}
                        background={theme.primary}
                    />
                </ButtonWrapper>

                {sendingEmail && <Loader />}
            </>
        );
    };

    return (
        <>
            {renderContactSection()}
            {renderBusinessCardSection()}
        </>
    );
};

const mapStateToProps = state => ({
    sendingEmail: state.exhibitorBooth.sendingEmail,
    isEmailSent: state.exhibitorBooth.isEmailSent,
    contactInfo: selectContactInfo(state.exhibitorBooth),
    businessCardFeatureData: selectBusinessCardFeatureData(state.exhibitorBooth),
    exhibitorTitle: selectTitle(state.exhibitorBooth),
});

export default connect(mapStateToProps, {
    sendEmail: sendEmailAction,
})(Contact);

import async from 'async';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import {
    filterByFavorites,
    formatDate,
    getClassifierWithRelated,
    sortByStartTime,
} from '../../../services/api/data';
import { executeListQueryWithCallBack, getItem } from '../../../services/api/graphQlRepository';
import pull from 'lodash/pull';
import get from 'lodash/get';

const queryMap = {
    classifiers: 'findClassifiers',
    institutions: 'findInstitutions',
    persons: 'findPersons',
    programelements: 'findProgramelements',
    timeslots: 'findTimeslots',
};

const sortNumbersFn = (left, right) => {
    const leftValue = parseInt(left) || 99999999;
    const rightValue = parseInt(right) || 99999999;

    return leftValue - rightValue;
};

const sortStringsFn = (left, right) => {
    const leftValue = left ? left.toString() : '-';
    const rightValue = right ? right.toString() : '-';
    return leftValue.localeCompare(rightValue);
};

const sortDateFn = (left, right) => {
    return new Date(left) - new Date(right);
};

const findSortingFunctionByOptionType = (option, list) => {
    const firstItemWithOrdering = list && list.find(item => item[option]);

    switch (option) {
        case 'start':
            return sortDateFn;
        case 'orderingName':
            if (/^\d+$/.test(firstItemWithOrdering && firstItemWithOrdering[option])) {
                return sortNumbersFn;
            }
            return sortStringsFn;
        default:
            return sortStringsFn;
    }
};

let sortItems = function (list, options) {
    if (options && options.length) {
        const firstSortType = findSortingFunctionByOptionType(options[0], list);
        const secondSortType = findSortingFunctionByOptionType(options[1], list);
        const thirthSortType = findSortingFunctionByOptionType(options[2], list);

        const sortedList = list.sort(
            (a, b) =>
                firstSortType(a[options[0]], b[options[0]]) ||
                secondSortType(a[options[1]], b[options[1]]) ||
                thirthSortType(a[options[2]], b[options[2]]),
        );

        return sortedList;
    }

    return sortByStartTime(list);
};

const parse = input => {
    if (Array.isArray(input)) {
        return input;
    }
    if (typeof input === 'string') {
        try {
            return JSON.parse(input);
        } catch (e) {
            console.log('Parse error:', e);
            return [];
        }
    }

    console.log('Could not parse type:', typeof input);
    return [];
};

const keysToSearchFor = ['name', 'subNameDetail', 'subNameList', 'searchTerms', 'info'];

const searchTermsMatchesData = (obj, searchQuery, keys = keysToSearchFor) => {
    let matches = false;

    const cleanTerm = searchQuery.replace(/([*+?^${}()|[]\/\\])/gi, '').toLowerCase();
    const queryArray = pull(cleanTerm.split(' '), '', undefined, null, '.');

    for (let i = 0; i < keys.length; i += 1) {
        const str = get(obj, keys[i], '') || '';
        matches =
            matches ||
            queryArray.every(search => str.toLowerCase().indexOf(search.toLowerCase()) !== -1);

        if (matches) {
            break;
        }
    }

    return matches;
};

const filterItems = (items, searchValue) => {
    if (!searchValue) {
        return items;
    }
    return items.filter(item => searchTermsMatchesData(item, searchValue));
};

const executeListQueryForGivenTarget = async (target, params, page, search, type, callback) => {
    let orderOptions = ['orderingName', 'name'];
    const hasSearch = (page.params && page.params.hasSearch) || false;
    const listPage = page;
    // Get the ordering options
    if (listPage && listPage.params && listPage.params.filter && listPage.params.filter.orderBy) {
        orderOptions = listPage.params.filter.orderBy;
    }
    return executeListQueryWithCallBack(queryMap[target], params, (err, result) => {
        // These are grouped by type and classifier
        if (page.params.filter && page.params.filter.relatedRelatedId) {
            result = filter(result, function (i) {
                return find(parse(i.classifications), function (c) {
                    return page.params.filter.relatedRelatedId === c._id;
                });
            });
        }

        if (page.params.filter && page.params.filter.roleTypeId) {
            result = filter(result, function (r) {
                return find(parse(r.rolesOf), function (f) {
                    return f.type === page.params.filter.roleTypeId;
                });
            });

            result = map(result, function (n) {
                n.time = formatDate(n);
                return n;
            });
            const obj = {
                items: filterItems(sortItems(result, orderOptions), search),
                type: type.target.toLowerCase(),
                title: listPage.params.title || 'List',
                hasSearch,
            };
            if (callback) {
                callback(err, obj, page);
            }
        } else {
            result = map(result, function (n) {
                n.time = formatDate(n);
                return n;
            });
            const obj = {
                items: filterItems(sortItems(result, orderOptions), search),
                type: type.target.toLowerCase(),
                title: listPage.params.title || 'List',
                hasSearch,
            };
            if (callback) {
                callback(err, obj, page);
            }
        }
    });
};

let getListElements = function (pageId, search, next) {
    let listPage;
    async.waterfall(
        [
            function (callback) {
                if (pageId) {
                    getItem('pages', pageId, callback);
                } else {
                    callback('no pageId found');
                }
            },
            function (page, callback) {
                if (!page && page.params) {
                    return callback('list page and list page.params not found');
                }
                listPage = page;
                // Get the filtering options
                if (page.params.filter && page.params.filter.typeId) {
                    getItem('types', page.params.filter.typeId, (err, type) => {
                        callback(err, page, type, null);
                    });
                } else if (page.params.filter && page.params.filter.relatedRelatedId) {
                    listPage = page;
                    getClassifierWithRelated(
                        page.params.filter.relatedRelatedId,
                        (err, classifier) => {
                            callback(err, page, null, classifier);
                        },
                    );
                } else if (page.params.filter && page.params.filter.roleTypeId) {
                    getItem('types', page.params.filter.roleTypeId, (err, type) => {
                        callback(err, page, type, null);
                    });
                } else {
                    callback('list page filter not found');
                }
            },
            function (page, type, classifier, callback) {
                let orderOptions = ['orderingName', 'name'];
                const hasSearch = (page.params && page.params.hasSearch) || false;

                // Get the ordering options
                if (
                    listPage &&
                    listPage.params &&
                    listPage.params.filter &&
                    listPage.params.filter.orderBy
                ) {
                    orderOptions = listPage.params.filter.orderBy;
                }
                // Execute the filtering
                if (type && type.singular) {
                    const target = type.target.toLowerCase() + 's';
                    const params = {};
                    if (search) {
                        const cleanTerm = search.replace(/([*+?^${}()|[]\/\\])/gi, '');
                        const queryArray = pull(cleanTerm.split(' '), '', undefined, null, '.');
                        const [firstWord] = queryArray;
                        params.searchTerms = {
                            contains: (firstWord || cleanTerm).toLowerCase(),
                        };
                    }
                    if (
                        listPage &&
                        listPage.params &&
                        listPage.params.filter &&
                        listPage.params.filter.roleTypeId &&
                        !listPage.params.filter.typeId
                    ) {
                        if (!search) {
                            params.searchTerms = {
                                contains: '',
                            };
                        }
                        const targetsWithRoles = ['persons', 'institutions'];

                        executeListQueryForGivenTarget(
                            targetsWithRoles[0],
                            params,
                            page,
                            search,
                            type,
                            callback,
                        );
                        executeListQueryForGivenTarget(
                            targetsWithRoles[1],
                            params,
                            page,
                            search,
                            type,
                        );
                    } else {
                        params.type = { eq: type.id };

                        executeListQueryWithCallBack(queryMap[target], params, (err, result) => {
                            // These are grouped by type and classifier
                            if (page.params.filter && page.params.filter.relatedRelatedId) {
                                result = filter(result, function (i) {
                                    return find(parse(i.classifications), function (c) {
                                        return page.params.filter.relatedRelatedId === c._id;
                                    });
                                });
                            }

                            if (page.params.filter && page.params.filter.roleTypeId) {
                                result = filter(result, function (r) {
                                    return find(parse(r.rolesOf), function (f) {
                                        return f.type === page.params.filter.roleTypeId;
                                    });
                                });

                                result = map(result, function (n) {
                                    n.time = formatDate(n);
                                    return n;
                                });
                                const obj = {
                                    items: filterItems(sortItems(result, orderOptions), search),
                                    type: type.target.toLowerCase(),
                                    title: listPage.params.title || 'List',
                                    hasSearch,
                                };
                                callback(err, obj, page);
                            } else {
                                result = map(result, function (n) {
                                    n.time = formatDate(n);
                                    return n;
                                });
                                const obj = {
                                    items: filterItems(sortItems(result, orderOptions), search),
                                    type: type.target.toLowerCase(),
                                    title: listPage.params.title || 'List',
                                    hasSearch,
                                };
                                callback(err, obj, page);
                            }
                        });
                    }
                } else if (classifier) {
                    if (classifier.relatedItems && classifier.relatedItems.length) {
                        const category =
                            classifier.relatedItems && classifier.relatedItems[0].category;
                        const obj = {
                            items: filterItems(
                                sortItems(classifier.relatedItems, orderOptions),
                                search,
                            ),
                            type: category || 'List',
                            title: listPage.params.title || 'List',
                            hasSearch,
                        };
                        callback(null, obj, page);
                    } else {
                        callback(null, null, page);
                    }
                } else {
                    callback('no type found');
                }
            },
        ],
        function (err, result, page) {
            next(err, result, page);
        },
    );
};

export { getListElements, filterByFavorites };

import * as React from 'react';
import get from 'lodash/get';
import omit from 'lodash/omit';
import styled from 'styled-components';

const ButtonWrapper = styled.button`
    font-size: 14px;
    font-family: Roboto, sans-serif;
    border-radius: 0;
    margin: 0;
    font-weight: normal;
    letter-spacing: normal;
    transition: all 0.1s linear;
    padding: 8px 16px;
    width: fit-content;
    cursor: pointer;

    &[disabled] {
        border: solid 1px #cccccc !important;
        color: rgba(0, 0, 0, 0.38) !important;
        background-color: #cccccc !important;
    }
`;

const ButtonBlue = styled(ButtonWrapper)`
    color: white;
    background-color: #1fa294;
    border: solid 1px #1fa294;
    &:hover {
        background-color: #1b8a7c;
    }
    &[disabled] {
        background-color: gray !important;
        color: white !important;
    }
`;

const ButtonBoldBlue = styled(ButtonWrapper)`
    color: white;
    background-color: #1fa294;
    border: solid 1px #1fa294;
    font-size: 16px;
    border-radius: 4px;
    font-weight: bold;
    &:hover {
        background-color: #1b8a7c;
    }
    &[disabled] {
        border: solid 1px #cccccc !important;
        color: rgba(0, 0, 0, 0.38) !important;
        background-color: #cccccc !important;
    }
`;

const ButtonWhiteSquare = styled(ButtonWrapper)`
    color: #1fa294;
    background-color: white;
    border: solid 1px #1fa294;
    &:hover {
        background-color: #e6e6e6;
    }
    &[disabled] {
        border: solid 1px #cccccc !important;
        color: rgba(0, 0, 0, 0.38) !important;
        background-color: #cccccc !important;
    }
`;

const ButtonWhiteBorder = styled(ButtonWhiteSquare)`
    border-radius: 4px;
    font-weight: 500;
`;

const ButtonWhiteRound = styled(ButtonWrapper)`
    background-color: white;
    font-family: Cabin, sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    letterspacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 16px;
    border: solid 1px #ccc;
    padding: 8px 16px;
    cursor: default;
    text-transform: uppercase;
    &[disabled] {
        color: #ffffff !important;
        background-color: #f1f1f3 !important;
        color: rgba(0, 0, 0, 0.38) !important;
    }
`;

const ButtonWhiteWithRedBordersRound = styled(ButtonWrapper)`
    background-color: #fff;
    font-family: Cabin, sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #d50000;
    border-radius: 16px;
    border: solid 1px #d50000;
    padding: 8px 16px;
    cursor: default;
    text-transform: uppercase;
    &:hover {
        color: #d50000 !important;
        cursor: pointer;
    }
    &[disabled] {
        color: rgba(0, 0, 0, 0.87) !important;
    }
`;

const ButtonWhiteRoundNoBorders = styled(ButtonWrapper)`
    background-color: #fff;
    font-family: Cabin, sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.5px;
    color: #1fa294;
    border-radius: 16px;
    cursor: default;
    padding: 4px 12.3px 4px 12px;
    box-shadow: 0 1px 1px 0 rgba(20, 35, 55, 0.3);
    border-color: transparent;
    height: inherit;
    cursor: pointer;
    &[disabled] {
        color: rgba(0, 0, 0, 0.2) !important;
        background-color: transparent !important;
        border-color: transparent !important;
    }
`;

const ButtonTransparentNoBordersRoot = styled(ButtonWrapper)`
    background-color: transparent;
    font-family: Cabin, sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.54) !important;
    border-radius: 16px;
    cursor: default;
    padding: 4px 12.3px 4px 12px;
    border-color: transparent;
    cursor: pointer;
    &[disabled] {
        color: rgba(0, 0, 0, 0.2) !important;
        background-color: transparent !important;
        border-color: transparent !important;
    }
`;

const ButtonRedRound = styled(ButtonWrapper)`
    border-radius: 16px;
    letter-spacing: 0.5px;
    background-color: #d50000;
    color: #ffffff;
    font-family: Cabin, sans-serif;
    padding: 8px 16px;
    font-weight: bold;
    border: none;
    text-transform: uppercase;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    &:hover {
        background-color: #d50000;
    }
    &[disabled] {
        color: #ffffff !important;
        background-color: #d50000 !important;
    }
`;

export const ButtonClassType = {
    BLUE: 'blueRoot',
    BLUE_BOLD: 'blueBoldRoot',
    WHITE_SQUARE: 'whiteSquareRoot',
    WHITE_BORDERS: 'whiteBordersRoot',
    WHITE_ROUND: 'whiteRoundRoot',
    RED_ROUND: 'redRound',
    WHITE_RED_BORDERS_ROUND: 'redWithRedBordersRoot',
    WHITE_ROUND_NO_BORDERS: 'whiteRoundNoBordersRoot',
    TRANSPARENT_NO_BORDERS: 'whiteTransparentNoBordersRoot',
};

const ButtonCommon = props => {
    const {
        content,
        callback,
        classType,
        disabled,
        onMouseOver,
        onMouseEnter,
        onMouseLeave,
        width,
        extra: extraProps,
    } = props;

    let ButtonStyled;

    switch (classType) {
        case ButtonClassType.BLUE:
            ButtonStyled = ButtonBlue;
            break;
        case ButtonClassType.BLUE_BOLD:
            ButtonStyled = ButtonBoldBlue;
            break;
        case ButtonClassType.WHITE_SQUARE:
            ButtonStyled = ButtonWhiteSquare;
            break;
        case ButtonClassType.WHITE_BORDERS:
            ButtonStyled = ButtonWhiteBorder;
            break;
        case ButtonClassType.WHITE_ROUND:
            ButtonStyled = ButtonWhiteRound;
            break;
        case ButtonClassType.WHITE_ROUND_NO_BORDERS:
            ButtonStyled = ButtonWhiteRoundNoBorders;
            break;
        case ButtonClassType.TRANSPARENT_NO_BORDERS:
            ButtonStyled = ButtonTransparentNoBordersRoot;
            break;
        case ButtonClassType.RED_ROUND:
            ButtonStyled = ButtonRedRound;
            break;
        case ButtonClassType.WHITE_RED_BORDERS_ROUND:
            ButtonStyled = ButtonWhiteWithRedBordersRound;
            break;
        default:
            ButtonStyled = ButtonWrapper;
    }

    const extra = {
        style: {
            width: width,
            ...get(extraProps, 'style'),
        },
        ...omit(extraProps, 'style'),
    };

    return (
        <ButtonStyled
            onClick={callback}
            onMouseOver={onMouseOver}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            disabled={disabled}
            data-qa={props['data-qa']}
            style={width ? { width: width } : {}}
            {...extra}
        >
            {content}
        </ButtonStyled>
    );
};

export default ButtonCommon;

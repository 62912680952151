import React, { useState } from 'react';
import styled from 'styled-components';

import { useVMState } from '../../containers/main';
import { colorTheme } from '../../constants/colors';
import { EUREKA_TALK_HOST } from '../../../../../../config';
import Chat from '../../../Talk/components/Chat';

const Wrapper = styled.div`
    flex-shrink: 0;
    overflow: scroll;
    width: ${props => (props.iswindows ? '476px' : '459px')};
    background-color: ${colorTheme.WHITE};
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.05);
`;

const ContainerTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 100%;
    font-family: Cabin, sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    border-bottom: 1px solid ${colorTheme.SECONDARY};
`;

const CardsView = () => {
    const isWindows = window.navigator.appVersion.indexOf('Win') !== -1;
    const stateCtx = useVMState();
    const { sessionId, externalObject } = stateCtx;
    const sessionName = externalObject.data.title;
    const [chatRoomId, setChatRoomId] = useState(undefined);

    return (
        <Wrapper iswindows={isWindows ? 1 : 0}>
            <ContainerTitle>Chat</ContainerTitle>
            <Chat
                itemId={sessionId}
                itemName={sessionName}
                isHostedSession
                chatUrl={EUREKA_TALK_HOST}
                isVisible={true}
                onChatRoomIdSet={chatRoomId => setChatRoomId(chatRoomId)}
            />
        </Wrapper>
    );
};

export default CardsView;

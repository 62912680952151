import styled from 'styled-components';

export const CardWrapper = styled.div`
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    margin: 16px;
    padding: 0;
    height: ${props => props.height}px;
`;

export const ChatBubbleWrapper = {
    overflow: 'auto',
};

export const HourText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    line-height: 1;
    color: #d50000;
`;

export const LinkStyle = {
    textDecoration: 'none',
};

export const RespondButton = styled.div`
    padding: 8px 16px;
    background-color: #d50000;
    color: #ffffff;
    width: fit-content;
    border-radius: 24px;
    margin: 0 12px 12px 12px;
`;

export const AppointmentCardWrapper = styled.div`
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(47, 51, 55, 0.25);
    background-color: #ffffff;
    width: 70%;
    margin: ${props => (props.alignRight ? '16px 16px 16px auto' : '16px auto 16px 16px')};
`;

export const AppointmentCardTextWrapper = styled.div`
    padding: 16px;
`;

export const AppointmentCardTitle = styled.div`
    opacity: 0.84;
    font-family: 'Cabin', sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 1.18;
    color: #000000;
    margin-bottom: 12px;
`;

export const AppointmentCardDetails = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 1.23;
    color: rgba(0, 0, 0, 0.54);
`;

export const AppointmentCardButtonsWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 8px;
`;

export const AppointmentCardButton = styled.button`
    width: 100%;
    padding: 12px 16px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 500;
    border-radius: 6px;
    background-color: ${props => props.color};
    color: #ffffff;
    cursor: pointer;
    border: none;

    &:not(:last-child) {
        margin-right: 8px;
    }
`;

export const AppointmentCardButtonLight = styled(AppointmentCardButton)`
    color: ${props => props.color};
    background-color: ${props => `${props.color}15`}; // primary color with 15% opacity
`;

export const AppointmentCardLabel = styled.div`
    padding: 1px 8px;
    border-radius: 12px;
    width: fit-content;
    height: fit-content;
    background-color: ${props => (props.red ? '#d50000' : '#00a86b')};
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    font-weight: 500;
    color: #ffffff;
    text-transform: lowercase;
    margin-left: 8px;
`;

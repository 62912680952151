import { useEffect, useState } from 'react';
import get from 'lodash/get';

import { createPoll, deletePoll, getPollById, reorderPollsInSet } from '../services/PollingService';
import { useVMMutation, useVMState } from '../../virtualFeature/containers/main';
import entities from '../../virtualFeature/constants/entities';

const useVote = (props = {}) => {
    const { pollSet } = props;
    const mutationCtx = useVMMutation();
    const stateCtx = useVMState();
    const {
        socket,
        sessionId,
        virtualEventSession,
        virtualEventUser,
        activePoll: currentlyActivePoll,
        externalObject,
    } = stateCtx;
    const [questions, setQuestions] = useState([]);
    const [currentQuestionId, setCurrentQuestionId] = useState(null);
    const isVoteButtonOn = pollSet && pollSet.state === 'open';
    const polls = pollSet && pollSet.Polls;
    const remoteActivePoll = polls && polls.find(p => p.active);
    const vUserRole = get(virtualEventUser, 'data.role');
    const vUserId = get(virtualEventUser, 'data.UserId');
    const questionModeratorId = get(virtualEventSession, 'data.questionModeratorId');
    const isModerator = vUserRole === entities.virtualEventUserEntity.role.moderator;

    const mapPolls = polls =>
        polls.map(poll => {
            const { votes } = pollSet;
            const { PollOptions: pollOptions } = poll;
            const options = pollOptions
                .sort((o1, o2) => o1.order - o2.order)
                .map(pollOption => pollOption.text);
            const totalVotes = pollOptions.reduce(
                (prevValue, option) => prevValue + votes[option.id],
                0,
            );

            return {
                id: poll.id,
                text: poll.title,
                pollSetId: poll.PollSetId,
                options,
                isExpanded: false,
                active: poll.active,
                totalVotes,
                user: pollSet.Users.find(user => user.id === poll.UserId),
            };
        });

    const reorderPolls = async questions => {
        const payload = questions.map((question, index) => ({
            id: question.id,
            order: index,
        }));

        await reorderPollsInSet(payload);
    };

    const moveQuestionUp = async index => {
        if (index === 0) {
            return;
        }

        const questionsClone = [...questions];
        [questionsClone[index - 1], questionsClone[index]] = [
            questionsClone[index],
            questionsClone[index - 1],
        ];
        setQuestions(questionsClone);
        await reorderPolls(questionsClone);

        socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: stateCtx.externalObject.data.id,
        });
    };

    const moveQuestionDown = async index => {
        if (index === questions.length - 1) {
            return;
        }

        const questionsClone = [...questions];
        [questionsClone[index + 1], questionsClone[index]] = [
            questionsClone[index],
            questionsClone[index + 1],
        ];
        setQuestions(questionsClone);
        await reorderPolls(questionsClone);

        socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: stateCtx.externalObject.data.id,
        });
    };

    const doResetVotes = async () => {
        const pollIndex = questions.findIndex(question => question.id === currentQuestionId);

        // Attendees have stored in local storage the ids of polls that they have already voted
        // Create new identical poll and destroy the old one, so the attendees can vote again
        const poll = await getPollById(currentQuestionId);

        const newPoll = await createPoll({
            title: poll.title,
            PollSetId: poll.PollSetId,
            seconds: poll.seconds,
            imageUrl: poll.imageUrl,
            useTimer: poll.useTimer,
            PollOptions: poll.PollOptions.map((option, index) => ({
                text: option.text,
                order: index,
                correct: option.correct,
            })),
        });
        await deletePoll(currentQuestionId);

        const questionsClone = [...questions];
        questionsClone[pollIndex] = {
            active: false,
            id: newPoll.id,
            isExpanded: false,
            options: poll.PollOptions.map((option, index) => ({
                text: option.text,
                order: index,
                correct: option.correct,
            })),
            pollSetId: poll.PollSetId,
            text: poll.title,
            user: pollSet.Users.find(user => user.id === poll.UserId),
        };

        await reorderPolls(questionsClone);
        setCurrentQuestionId(null);

        let socketMessage = {
            objectId: sessionId,
            externalObjectId: stateCtx.externalObject.data.id,
        };

        if (currentlyActivePoll.id === poll.id) {
            socketMessage = {
                ...socketMessage,
                pollChange: {
                    id: newPoll.id,
                    active: true,
                },
            };
        }

        stateCtx.socket.emit('updatePollSet', socketMessage);

        if (currentlyActivePoll.id === poll.id) {
            const { Polls: polls } = pollSet;
            const newActivePoll = polls.find(p => p.id === newPoll.id);

            mutationCtx.setActivePoll(newActivePoll);
        }
    };

    const doDeletePoll = async () => {
        try {
            await deletePoll(currentQuestionId);
        } catch (e) {
            console.error(e);
        }

        setCurrentQuestionId(null);
        stateCtx.socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: stateCtx.externalObject.data.id,
        });
    };

    const isCurrentUserActive = () => {
        const userId = get(virtualEventUser, 'data.UserId', null);
        const virtualEventUsers = get(virtualEventSession, 'data.VirtualEventUsers', []);
        const activeUser = virtualEventUsers.find(virtualUser => virtualUser.isActive) || {};

        return activeUser.UserId === userId;
    };

    const toggleVoteActivity = (id, noVoteResultsChange = false) => {
        const { Polls: polls } = pollSet;
        const poll = polls.find(p => p.id === id);
        const samePoll = currentlyActivePoll && currentlyActivePoll.id === id;
        const newActive = !poll.active;

        const newQuestionIndex = questions.findIndex(p => p.id === id);
        const previousActiveQuestionIndex = questions.findIndex(
            p => currentlyActivePoll && p.id === currentlyActivePoll.id,
        );
        const questionsClone = [...questions];

        if (previousActiveQuestionIndex !== -1) {
            const previousActiveQuestion = questions[previousActiveQuestionIndex];
            questionsClone.splice(previousActiveQuestionIndex, 1, {
                ...previousActiveQuestion,
                active: false,
            });
        }

        if (newQuestionIndex !== -1) {
            const newActiveQuestion = questions[newQuestionIndex];
            questionsClone.splice(newQuestionIndex, 1, {
                ...newActiveQuestion,
                active: true,
            });
            setQuestions(questionsClone);
        }

        stateCtx.socket.emit('updatePollSet', {
            objectId: sessionId,
            externalObjectId: externalObject.data.id,
            pollChange: {
                id: poll.id,
                active: newActive,
            },
        });

        if (isModerator) {
            stateCtx.socket.emit('updateData', {
                objectId: stateCtx.sessionId,
                virtualEventSession: {
                    questionModeratorId: vUserId,
                },
            });
        }

        if (samePoll) {
            stateCtx.socket.emit('updateData', {
                objectId: stateCtx.sessionId,
                virtualEventSession: {
                    questionModeratorId: null,
                },
            });
        }

        mutationCtx.setActivePoll(newActive ? poll : null);
    };

    const hideVoteResults = async () => {
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                status: virtualEventSession.data.status,
                showVoteResults: false,
            },
        });
    };

    const isActiveQuestion = () => !!questions.find(question => question.active);

    const isCurrentUserQuestionActive = () => {
        const userId = get(virtualEventUser, 'data.UserId', null);

        return userId === currentlyActivePoll?.UserId;
    };

    const isUserModeratorAndActivatedAQuestion = () =>
        isModerator && vUserId === questionModeratorId;

    useEffect(() => {
        if (!pollSet) {
            return;
        }

        const polls = pollSet.Polls;
        const activePoll = polls.find(poll => poll.active);
        mutationCtx.setActivePoll(activePoll);

        setQuestions(mapPolls(polls));
    }, [pollSet]);

    const isActive = get(virtualEventUser, 'data.isActive') || false;

    useEffect(() => {
        if (isActive && currentlyActivePoll && !remoteActivePoll) {
            mutationCtx.setActivePoll(null);
        }
    }, [isActive, remoteActivePoll, currentlyActivePoll]);

    return {
        questions,
        currentQuestionId,
        setCurrentQuestionId,
        isVoteButtonOn,
        doResetVotes,
        doDeletePoll,
        moveQuestionUp,
        moveQuestionDown,
        isCurrentUserActive,
        toggleVoteActivity,
        hideVoteResults,
        isActiveQuestion,
        isCurrentUserQuestionActive,
        isUserModeratorAndActivatedAQuestion,
    };
};

export default useVote;

import React, { useEffect, useState } from 'react';

import * as StyledComponent from '../../style/modalsStyle';
import { FormWrapper } from '../../style/modalsStyle';
import * as palette from '../../../../components/General/Variables';
import facebookLogo from '../../../../assets/social/icon-filled-facebook.png';
import linkedinLogo from '../../../../assets/social/icon-filled-linkedin.png';
import twitterLogo from '../../../../assets/social/icon-filled-twitter.png';
import { StyledForm } from '../../style';
import { getString } from '../../../../services/api/store';
import { SOCIAL_LINKS_TITLE } from '../../constants';
import ComplexDialog from '../../../../components/Dialog/ComplexDialog';
import { Flex } from '../../../common/Flex';

const SocialLinksModal = ({
    theme,
    fieldValues,
    onClose,
    closeIcon,
    setCloseIcon,
    setFieldValues,
    setEditedLinks,
    editedLinks,
    afterSignUp,
}) => {
    const [fieldValuesSocial, setFieldValuesSocial] = useState(null);
    const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;

    useEffect(() => {
        setFieldValuesSocial(fieldValues);
    }, []);

    const onChange = (value, event) => {
        const { name } = event.target;

        setFieldValuesSocial({
            ...fieldValuesSocial,
            [name]: value,
        });

        const linkNames = ['facebookLink', 'linkedinLink', 'twitterLink', 'webLink'];
        if (linkNames.includes(name)) {
            setEditedLinks({
                ...editedLinks,
                [name]: true,
            });
        }
    };

    const onSave = () => {
        setFieldValues(fieldValuesSocial);
        onClose();
    };

    return (
        <ComplexDialog
            title={
                <Flex>
                    <StyledComponent.BackButton
                        style={{
                            color: palette.COLOR_TEXT,
                            marginTop: -10,
                        }}
                        icon
                        onClick={onClose}
                    >
                        arrow_back
                    </StyledComponent.BackButton>
                    <StyledComponent.ModalTitle>{SOCIAL_LINKS_TITLE}</StyledComponent.ModalTitle>
                </Flex>
            }
            visible={true}
            contentStyle={{ padding: 0 }}
            onClose={null}
            displayBoxShadow
            iconName={'arrow-back'}
            options={[
                {
                    title: getString('saveButton') || 'Save',
                    variant: 'text',
                    type: 'save',
                    onClick: onSave,
                },
            ]}
        >
            <StyledForm
                maincolor={theme.primary}
                horizontalMargin={isDesktop && '0px'}
                minHeight="100px"
            >
                <FormWrapper>
                    <StyledComponent.StyledTextField
                        id="profile-facebookLink"
                        name="facebookLink"
                        label={'https://facebook.com/yourname'}
                        placeholder="https://facebook.com/yourname"
                        value={fieldValuesSocial && fieldValuesSocial.facebookLink}
                        onChange={onChange}
                        afterSignUp={afterSignUp}
                        onFocus={() => setTimeout(() => setCloseIcon('facebookLink'), 100)}
                        onBlur={() => setTimeout(() => setCloseIcon(null), 100)}
                        leftIcon={<StyledComponent.LinkLogo src={facebookLogo} alt="Facebook" />}
                        rightIcon={
                            closeIcon === 'facebookLink' && (
                                <StyledComponent.LinkIcon
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setFieldValuesSocial({
                                            ...fieldValuesSocial,
                                            ['facebookLink']: '',
                                        });
                                        setEditedLinks({
                                            ...editedLinks,
                                            ['facebookLink']: true,
                                        });
                                    }}
                                >
                                    {palette.ICON_CANCEL}
                                </StyledComponent.LinkIcon>
                            )
                        }
                    />
                    <StyledComponent.StyledTextField
                        id="profile-linkedinLink"
                        name="linkedinLink"
                        label={'https://linkedin.com/in/yourname'}
                        placeholder="LinkedIn"
                        value={fieldValuesSocial && fieldValuesSocial.linkedinLink}
                        onChange={onChange}
                        afterSignUp={afterSignUp}
                        onFocus={() => setTimeout(() => setCloseIcon('linkedinLink'), 100)}
                        onBlur={() => setTimeout(() => setCloseIcon(null), 100)}
                        leftIcon={<StyledComponent.LinkLogo src={linkedinLogo} alt="LinkedIn" />}
                        rightIcon={
                            closeIcon === 'linkedinLink' && (
                                <StyledComponent.LinkIcon
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setFieldValuesSocial({
                                            ...fieldValuesSocial,
                                            ['linkedinLink']: '',
                                        });
                                        setEditedLinks({
                                            ...editedLinks,
                                            ['linkedinLink']: true,
                                        });
                                    }}
                                >
                                    {palette.ICON_CANCEL}
                                </StyledComponent.LinkIcon>
                            )
                        }
                    />
                    <StyledComponent.StyledTextField
                        id="profile-twitterLink"
                        name="twitterLink"
                        label={'@twittername'}
                        placeholder="Twitter"
                        value={fieldValuesSocial && fieldValuesSocial.twitterLink}
                        onChange={onChange}
                        afterSignUp={afterSignUp}
                        onFocus={() => setTimeout(() => setCloseIcon('twitterLink'), 100)}
                        onBlur={() => setTimeout(() => setCloseIcon(null), 100)}
                        leftIcon={
                            <StyledComponent.LinkLogo
                                src={twitterLogo}
                                alt="Twitter"
                                style={{ width: 16, height: 16 }}
                            />
                        }
                        rightIcon={
                            closeIcon === 'twitterLink' && (
                                <StyledComponent.LinkIcon
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setFieldValuesSocial({
                                            ...fieldValuesSocial,
                                            ['twitterLink']: '',
                                        });
                                        setEditedLinks({
                                            ...editedLinks,
                                            ['twitterLink']: true,
                                        });
                                    }}
                                >
                                    {palette.ICON_CANCEL}
                                </StyledComponent.LinkIcon>
                            )
                        }
                    />
                    <StyledComponent.StyledTextField
                        id="profile-webLink"
                        name="webLink"
                        label={'https://www.website.com'}
                        placeholder="Website"
                        value={fieldValuesSocial && fieldValuesSocial.webLink}
                        onChange={onChange}
                        afterSignUp={afterSignUp}
                        onFocus={() => setTimeout(() => setCloseIcon('webLink'), 100)}
                        onBlur={() => setTimeout(() => setCloseIcon(null), 100)}
                        leftIcon={
                            <StyledComponent.LinkIcon>
                                {palette.ICON_GLOBE}
                            </StyledComponent.LinkIcon>
                        }
                        rightIcon={
                            closeIcon === 'webLink' && (
                                <StyledComponent.LinkIcon
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setFieldValuesSocial({
                                            ...fieldValuesSocial,
                                            ['webLink']: '',
                                        });
                                        setEditedLinks({
                                            ...editedLinks,
                                            ['webLink']: true,
                                        });
                                    }}
                                >
                                    {palette.ICON_CANCEL}
                                </StyledComponent.LinkIcon>
                            )
                        }
                    />
                </FormWrapper>
            </StyledForm>
        </ComplexDialog>
    );
};

export default SocialLinksModal;

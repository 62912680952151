import React from 'react';
import styled from 'styled-components';
import { LinearProgress } from 'react-md';
import { useTheme } from '../../../../../../components/Theme/ThemeContext';

const StyledProgressBar = styled(LinearProgress)`
    height: 4px !important;
    background: #efefef !important;
    margin: 8px 0 !important;
    border-radius: 6px;

    .md-progress--linear-active {
        background: ${props => (props.color ? props.color : '#00a480')};
        transition: width 0.2s linear;
    }
`;

const ProgressBar = ({ value, query }) => {
    const { theme } = useTheme();

    return (
        <StyledProgressBar id="progress-bar" value={value} query={query} color={theme.primary} />
    );
};

export default ProgressBar;

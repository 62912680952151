import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FontIcon } from 'react-md';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import RepresentativesColumn from '../components/representatives/RepresentativesColumn';
import ContentColumn from '../components/content/ContentColumn';
import ChatColumn from '../components/chat/ChatColumn';
import Header from '../../../components/HeaderNoSidebarMenu/Header';
import * as palette from '../../../components/General/Variables';

import {
    clearExhibitorState as clearExhibitorStateAction,
    getExhibitor as getExhibitorAction,
} from '../actions';
import Loader from '../../../components/General/Loader';
import { selectBackgroundUrl, selectRepresentatives } from '../selectors';
import AnalyticsService from '../../../features/analytics/services';
import {
    AchievementType,
    useAchievementActions,
} from '../../Achievements/hooks/useAchievementActions';
import { getFullProfile } from '../../../services/api/eureka';
import Auth from '../../../services/api/auth';
import throttle from 'lodash/throttle';
import { default as Store, getString } from '../../../services/api/store';
import LockIcon from '@mui/icons-material/Lock';
import { COLOR_LIGHT_GREY, COLOR_TEXT_LIGHT } from '../../../components/General/Variables';

const Container = styled.div`
    width: 100%;
    scroll-snap-type: both mandatory;
`;

const BackgroundImageWrapper = styled.div`
    position: relative;
    margin-top: 57px;
    transition: all 1s;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        display: none;
    }
`;

const BackgroundImage = styled.img`
    width: 100%;
    height: auto;
`;

const Page = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 32px 32px 32px;
    position: relative;
    margin-top: ${props => (props.backgroundUrl ? '-8%' : '85px')};

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        padding: 0;
        margin: 0;
    }
`;

const PageContent = styled.div`
    width: 100%;
    max-width: 1440px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 16px;
    & > * {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        display: inline;
    }
`;

const ContentContainer = styled.div`
    grid-column: ${props => {
        if (props.hideChatColumn && props.hideRepresentatives) {
            return '4 / span 6';
        }
        if (props.hideChatColumn) {
            return '5 / span 6';
        }
        return '3 / span 6';
    }};
`;

const RepresentativesContainer = styled.div`
    grid-column: ${props => {
        if (props.hideChatColumn) {
            return '3 / span 2';
        }
        return 'span 2';
    }};
`;

const ChatContainer = styled.div`
    grid-column: 9 / span 4;
`;

const MobileNavigationWrapper = styled.div`
    background-color: #ffffff;
    display: flex;
    padding: 0 16px 16px 16px;
    justify-content: space-around;
    margin-bottom: 8px;

    @media only screen and (min-width: ${palette.MIN_TABLET}) {
        display: none;
    }
`;

const MobileNavigationItem = styled.div`
    padding: 8px 12px;
    border-radius: 20px;
    background-color: ${props => (props.active ? '#00a933' : '#ebedf0')};
    display: flex;
    align-items: center;
    cursor: pointer;

    .md-icon {
        margin-right: 8px;
        color: ${props => (props.active ? '#ffffff' : 'rgba(0, 0, 0, 0.87)')} !important;
    }

    p {
        font-family: 'Cabin', sans-serif;
        font-size: 13px;
        font-weight: bold;
        color: ${props => (props.active ? '#ffffff' : 'rgba(0, 0, 0, 0.87)')};
        margin: 0;
        padding: 0;
    }
`;

const RestrictedContainer = styled.div`
    background-color: #ffffff;
    border-radius: 8px;
    padding: 16px 24px;
    height: calc(100vh - ${props => (!props.needBannerSpace ? '200px' : '116px')}) !important;
    min-height: calc(100vh - ${props => (!props.needBannerSpace ? '200px' : '116px')}) !important;
    transition: height 0.2s linear;
    position: sticky;
    top: 85px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        padding: 0 16px 16px 16px;
    }
`;

const RestrictedText = styled.div`
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color ${palette.COLOR_TEXT_LIGHT}
`;

const contentTypes = {
    BOOTH: 'booth',
    REPRESENTATIVES: 'representatives',
    CHAT: 'chat',
};

const ExhibitorPage = ({
    match,
    history,
    location,
    loading,
    getExhibitor,
    backgroundUrl,
    chatEnabled,
    privateChatUserId,
    representatives,
    clearExhibitorState,
}) => {
    const { trackAchievement } = useAchievementActions();
    const [displayedContent, setDisplayedContent] = useState(contentTypes.BOOTH);
    const [currentUserGroups, setCurrentUserGroups] = useState([]);
    const [loadingUserGroups, setLoadingUserGroups] = useState(true);
    const [scrollTop, setScrollTop] = useState(0);
    const parentPath = useRef();
    const isMobile = palette.MIN_TABLET_INT && window.innerWidth < palette.MIN_TABLET_INT;
    const shouldDisplayBooth = displayedContent === contentTypes.BOOTH;
    const shouldDisplayRepresentatives = displayedContent === contentTypes.REPRESENTATIVES;
    const shouldDisplayChat = displayedContent === contentTypes.CHAT;
    const networkingEnabled = Store.argument && Store.argument.app.enableChat;
    const isChatEnabled = chatEnabled && networkingEnabled;
    const hasRepresentatives = representatives.length > 0 && networkingEnabled;

    const RESTRICTED_TEXT =
        getString('groupChatSignInToJoinConversation') || 'Sign in to join conversation';

    const fromLocation = get(location, 'state.fromLocation', '');

    const exhibitorId = match.params?.exhibitorId;

    useEffect(() => {
        if (location.state && location.state.prevPath) {
            const prevPath = location.state && location.state.prevPath;
            const parentPathArray = prevPath.split('/');
            parentPath.current = parentPathArray.slice(0, 2).join('/');
        }
        const elem = document.getElementById('main-container');
        if (elem) {
            elem.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (elem) {
                elem.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (!Auth.isUserAuthenticated()) {
                setLoadingUserGroups(false);
                return;
            }

            const fullProfile = await getFullProfile();
            const { userGroups } = fullProfile;

            setCurrentUserGroups(userGroups);
            setLoadingUserGroups(false);
        })();
    }, []);

    const handleScroll = throttle(() => {
        const element = document.getElementById('main-container');
        const imageElement = document.getElementById('header-image-container');

        if (!element) {
            return;
        }
        const newScroll = element.scrollTop;
        if (imageElement) {
            const imageHeight = imageElement.offsetHeight;
            const opacity = ((imageHeight - newScroll) / imageHeight) * 0.8;
            imageElement.style.opacity = opacity === 0.8 ? opacity + 0.2 : opacity;
            if (newScroll > 96) {
                imageElement.style.opacity = 0;
            }
        }

        setScrollTop(newScroll);
    }, 100);

    const goBack = () => {
        // If the user was redirected from detail page to exhibitor booth, go two times back

        history.goBack();
    };

    useEffect(() => {
        if (!exhibitorId) {
            return goBack();
        }

        getExhibitor(exhibitorId);

        /*
        View analytic samples are automatically sent when a user open the detail page of an item
        Do not send view exhibitor sample when user was redirected from detail page in order to avoid duplicate values
        */
        if (!fromLocation) {
            AnalyticsService.addSample('object', 'institution', exhibitorId);
        }

        trackAchievement(AchievementType.VIEW_EXHIBITOR, exhibitorId);

        return () => {
            clearExhibitorState();
        };
    }, [exhibitorId]);

    if (loading) {
        return <Loader />;
    }
    const displayBanner = backgroundUrl && scrollTop < 96;

    return (
        <>
            <Header goBack={goBack} light={true} isExhibitorPage={true} />
            <Container>
                {backgroundUrl && (
                    <BackgroundImageWrapper id={'header-image-container'}>
                        <BackgroundImage src={backgroundUrl} alt="Banner" />
                    </BackgroundImageWrapper>
                )}
                <Page backgroundUrl={backgroundUrl}>
                    {/* Mobile navigation */}
                    <MobileNavigationWrapper>
                        <MobileNavigationItem
                            onClick={() => setDisplayedContent(contentTypes.BOOTH)}
                            active={shouldDisplayBooth}
                        >
                            <FontIcon
                                className={shouldDisplayBooth ? '' : 'material-icons-outlined'}
                            >
                                home
                            </FontIcon>
                            <p>Booth</p>
                        </MobileNavigationItem>
                        {hasRepresentatives && (
                            <MobileNavigationItem
                                onClick={() => setDisplayedContent(contentTypes.REPRESENTATIVES)}
                                active={shouldDisplayRepresentatives}
                            >
                                <FontIcon
                                    className={
                                        shouldDisplayRepresentatives
                                            ? ''
                                            : 'material-icons-outlined'
                                    }
                                >
                                    person
                                </FontIcon>
                                <p>Representatives</p>
                            </MobileNavigationItem>
                        )}
                        {isChatEnabled && (
                            <MobileNavigationItem
                                onClick={() => setDisplayedContent(contentTypes.CHAT)}
                                active={shouldDisplayChat}
                            >
                                <FontIcon
                                    className={shouldDisplayChat ? '' : 'material-icons-outlined'}
                                >
                                    chat_bubble
                                </FontIcon>
                                <p>Chat</p>
                            </MobileNavigationItem>
                        )}
                    </MobileNavigationWrapper>

                    <PageContent
                        hideChatColumn={!isChatEnabled}
                        hideRepresentatives={!hasRepresentatives}
                    >
                        {(!isMobile || shouldDisplayRepresentatives) && hasRepresentatives && (
                            <RepresentativesContainer hideChatColumn={!isChatEnabled}>
                                <RepresentativesColumn />
                            </RepresentativesContainer>
                        )}
                        {(!isMobile || shouldDisplayBooth) && !loadingUserGroups && (
                            <ContentContainer
                                hideChatColumn={!isChatEnabled}
                                hideRepresentatives={!hasRepresentatives}
                            >
                                <ContentColumn currentUserGroups={currentUserGroups} />
                            </ContentContainer>
                        )}
                        {(!isMobile || shouldDisplayChat) && isChatEnabled && (
                            <ChatContainer>
                                {Auth.isUserAuthenticated() ? (
                                    <ChatColumn
                                        needBannerSpace={!(backgroundUrl && scrollTop < 60)}
                                    />
                                ) : (
                                    <RestrictedContainer>
                                        <div
                                            style={{
                                                margin: '10px',
                                                width: 50,
                                                height: 50,
                                                borderRadius: 25,
                                                background: '#EFEFEF',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <LockIcon fontSize="large" />
                                        </div>
                                        <RestrictedText>{RESTRICTED_TEXT}</RestrictedText>
                                    </RestrictedContainer>
                                )}
                            </ChatContainer>
                        )}
                    </PageContent>
                </Page>
            </Container>
        </>
    );
};

const mapStateToProps = state => ({
    representatives: selectRepresentatives(state.exhibitorBooth),
    loading: state.exhibitorBooth.loading,
    backgroundUrl: selectBackgroundUrl(state.exhibitorBooth),
    chatEnabled: state.exhibitorBooth.chatEnabled,
    privateChatUserId: state.exhibitorBooth.privateChatUserId,
});

export default connect(mapStateToProps, {
    getExhibitor: getExhibitorAction,
    clearExhibitorState: clearExhibitorStateAction,
})(withRouter(ExhibitorPage));

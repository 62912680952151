import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import SessionInteraction from './SessionInteraction';
import SessionParticipants from './SessionParticipants';
import { useVMState } from '../../containers/main';
import entities from '../../constants/entities';
import NoSlidesView from './NoSlidesView';
import useStreamHandling from '../../hooks/useStreamHandling';
import { getStreamConfiguration, isEncodingTheSame } from '../../../../utils/streamUtils';
import get from 'lodash/get';
import CardsView from './CardsView';
import PreRecordingView from './PreRecordingView';
import ChatView from './ChatView';
import StreamPlayer from '../common/StreamPlayer';
import NoParticipantsCard from './NoParticipantsCard';
import usePrevious from '../../../Talk/hooks/usePrevious';

const RoundTableWrapper = styled.div`
    height: 100%;
`;

const NoSlidesRoundTableContentWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    padding: 8px;
`;

const RoundTableContentWrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    flex-flow: row nowrap;
    overflow: hidden;
    padding: 16px;
    height: 100%;
`;

const ZeroSpaceWrapper = styled.div`
    height: 0;
    width: 0;
    overflow: hidden;
`;

export default () => {
    const isWindows = window.navigator.appVersion.indexOf('Win') !== -1;
    const stateCtx = useVMState();
    const {
        virtualEventSession,
        virtualEventUser,
        user: currentUser,
        screenShareStream,
        shareType,
        peers,
        roundTableHostMode,
        streams,
        timeslot,
    } = stateCtx;

    const isPrerecording = get(virtualEventUser, 'data.isPrerecording') || false;
    const vUserPresentationUrl = get(virtualEventUser, 'data.uploadedPresentationUrl') || false;
    const isScreenSharing = screenShareStream && shareType === 'screen';
    const prevRoundTableHostMode = usePrevious(roundTableHostMode);
    const hostModeChanged = prevRoundTableHostMode !== roundTableHostMode;
    const onlyParticipantsMode =
        roundTableHostMode === entities.hostedSessionHostMode.onlyParticipants;
    const isCardsMode = roundTableHostMode === entities.hostedSessionHostMode.cards;
    const isAttendeesMode = roundTableHostMode === entities.hostedSessionHostMode.attendees;
    const isChatMode = roundTableHostMode === entities.hostedSessionHostMode.chat;
    const posterPdf = timeslot && timeslot.posterPdf && JSON.parse(timeslot.posterPdf);
    const posterUrl = posterPdf && posterPdf.url;
    const virtualEventSessionStatus = virtualEventSession?.data?.status;
    const isRoomOpen =
        virtualEventSessionStatus === entities.virtualEventSessionEntity.status.broadcasting;
    const sessionHasPoster = vUserPresentationUrl || posterUrl;
    const shouldRenderOnlyParticipants =
        (!isScreenSharing && !sessionHasPoster && isAttendeesMode) ||
        onlyParticipantsMode ||
        isPrerecording;
    const hostModeHasChangedRef = useRef(false);

    const { localStream } = useStreamHandling();

    const virtualEventUsers = useMemo(() => {
        const virtualEventusersUnparsed = get(virtualEventSession, 'data.VirtualEventUsers', []);

        return virtualEventusersUnparsed.filter(virtualEventUserUnparsed =>
            peers.find(peerId => peerId === virtualEventUserUnparsed.UserId),
        );
    }, [virtualEventSession, peers]);

    const streamsToPlay = useMemo(() => {
        return virtualEventUsers.reduce((acc, vUser) => {
            const { User: user } = vUser;
            const isCurrentUser = currentUser && user.id === currentUser.id;

            if (!isCurrentUser) {
                const streamToUse = streams.find(st => st.streamId === get(vUser, 'UserId'));

                if (streamToUse) {
                    acc.push({
                        user,
                        isMicrophoneOn: vUser.isMicrophoneOn,
                        isVideoOn: vUser.isVideoOn,
                        stream: streamToUse,
                    });
                }
            }

            return acc;
        }, []);
    }, [virtualEventUsers, streams]);

    useEffect(() => {
        if (hostModeChanged) {
            hostModeHasChangedRef.current = true;
        }
    }, [hostModeChanged, prevRoundTableHostMode, roundTableHostMode]);

    const keyDownHandler = useCallback(
        e => {
            if (e.key === 'Tab' && hostModeHasChangedRef.current) {
                hostModeHasChangedRef.current = false;
                const element = document.getElementById('authenticated-user-dropdown-menu-toggle');
                if (element) {
                    element.focus();
                }
            }
        },
        [hostModeHasChangedRef.current, prevRoundTableHostMode, roundTableHostMode],
    );

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [keyDownHandler]);

    useEffect(() => {
        if (!localStream.current || !localStream.current.videoTrack) {
            return;
        }

        const encoderConfiguration = getStreamConfiguration({
            roundTable: true,
            isScreenSharing,
            streamLength: virtualEventUsers.length,
        });

        if (
            !isEncodingTheSame(encoderConfiguration, localStream.current.videoTrack._encoderConfig)
        ) {
            localStream.current.setEncoderConfiguration(encoderConfiguration);
        }
    }, [localStream.current, isScreenSharing, virtualEventUsers]);

    return (
        <RoundTableWrapper>
            {shouldRenderOnlyParticipants ? (
                <NoSlidesRoundTableContentWrapper>
                    {!isPrerecording && <NoSlidesView />}
                    {isPrerecording && <PreRecordingView />}
                </NoSlidesRoundTableContentWrapper>
            ) : (
                <RoundTableContentWrapper>
                    <ZeroSpaceWrapper>
                        {!isAttendeesMode &&
                            streamsToPlay.map(({ stream }) => (
                                <StreamPlayer
                                    key={stream.getId()}
                                    className={'main-stream-profile'}
                                    muted={stream.streamId === get(localStream.current, 'streamId')}
                                    isVideoOn={false}
                                    stream={stream}
                                    uid={stream.getId()}
                                    domId={`stream-player-${stream.getId()}`}
                                    noPositionInitial
                                />
                            ))}
                    </ZeroSpaceWrapper>
                    <SessionInteraction />
                    {isAttendeesMode && !isRoomOpen && (
                        <div>
                            <SessionParticipants />
                            <NoParticipantsCard
                                wrapperStyle={{ width: isWindows ? 476 : 459, marginTop: 16 }}
                            />
                        </div>
                    )}
                    {isAttendeesMode && isRoomOpen && <SessionParticipants />}
                    {isCardsMode && <CardsView localStream={localStream} />}
                    {isChatMode && <ChatView />}
                </RoundTableContentWrapper>
            )}
        </RoundTableWrapper>
    );
};

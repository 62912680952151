import React, { useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import { navigateTo } from '../../../../components/Navigation/NavigationLink';
import styled from 'styled-components';
import Presenter from './common/Presenter';
import { canJoinRoundTable } from '../../../../services/api/eureka';

const GridForUsers = styled.div`
    display: grid;
    width: 100%;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
`;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;

    .stream-player.video-on {
        height: 100%;
        width: 100%;

        video {
            /* This used to work for the parent element of button divs */
            /* But it does not work with newer browsers, the below doesn't hide the play button parent div */
            &::-webkit-media-controls-panel {
                display: none !important;
                -webkit-appearance: none;
            }

            /* Old shadow dom for play button */

            &::-webkit-media-controls-play-button {
                display: none !important;
                -webkit-appearance: none;
            }

            /* New shadow dom for play button */

            /* This one works! */

            &::-webkit-media-controls-start-playback-button {
                display: none !important;
                -webkit-appearance: none;
            }
        }
    }
`;

const RoundTableVideo = props => {
    const { goBackWithCapacity, match, history, location } = props;
    const { timeslotId } = match.params;
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();
    const {
        peers,
        currentVirtualEventUser,
        localStream,
        localClient,
        virtualEventSession,
        virtualEventUser,
    } = stateCtx;

    const testJoining = async () => {
        mutationCtx.setCanJoin(false);

        try {
            await canJoinRoundTable(timeslotId, currentVirtualEventUser.UserId, true);

            mutationCtx.setCanJoin(true);
        } catch (err) {
            goBackWithCapacity();
        }
    };

    useEffect(() => {
        (async () => {
            if (timeslotId && get(currentVirtualEventUser, 'UserId')) {
                await testJoining();
            }
        })();
    }, []);

    useEffect(() => {
        if (localClient) {
            localClient.once('clientJoined', testJoining);
        }

        return () => {
            if (localClient) {
                localClient.off('clientJoined', testJoining);
            }
        };
    }, [localClient]);

    const virtualEventHost = useMemo(() => {
        let result = get(virtualEventSession, 'VirtualEventUsers', []);
        const hostIndex = result.findIndex(vUser => vUser.id === get(virtualEventUser, 'id'));

        return hostIndex !== -1 ? result[hostIndex] : null;
    }, [virtualEventSession, localStream, peers, get(virtualEventUser, 'UserId')]);

    const virtualEventUsers = useMemo(() => {
        let result = get(virtualEventSession, 'VirtualEventUsers', []);

        result.sort((a, b) => {
            const aLastName = a.User.lastName.toLowerCase();
            const bLastName = b.User.lastName.toLowerCase();

            if (aLastName > bLastName) {
                return 1;
            }
            if (aLastName < bLastName) {
                return -1;
            }
            return 0;
        });

        result = result.filter(vUser => {
            if (vUser.id === get(virtualEventUser, 'id')) {
                // if it's the host
                return false;
            }

            const hasJoined = peers.find(peerId => peerId === vUser.UserId);

            if (localStream && hasJoined && vUser.UserId === localStream.streamId) {
                vUser.muted = true;
            }

            return hasJoined;
        });

        return result;
    }, [virtualEventSession, localStream, peers, get(virtualEventUser, 'UserId')]);

    const toggleUserProfile = id => {
        navigateTo({
            match,
            history,
            location,
            params: {
                type: 'profile',
                userId: id,
            },
            state: {
                timeslot: props.timeslot,
            },
        });
    };

    return (
        <Wrapper>
            {!!virtualEventHost && (
                <Presenter
                    virtualEventUser={virtualEventHost}
                    toggleUserProfile={() => toggleUserProfile(virtualEventHost.UserId)}
                    muted={virtualEventHost.muted}
                />
            )}
            <GridForUsers
                style={{
                    gridTemplateColumns: 'minmax(0, 1fr)  '.repeat(2),
                    gridAutoRows: '1fr',
                }}
            >
                {virtualEventUsers.map((virtualEventUserData, index) => (
                    <Presenter
                        key={`${virtualEventUserData?.User?.firstName}-${virtualEventUserData?.User?.lastName}`}
                        index={index}
                        virtualEventUser={virtualEventUserData}
                        toggleUserProfile={() => toggleUserProfile(virtualEventUserData.UserId)}
                        muted={virtualEventUserData.muted}
                    />
                ))}
            </GridForUsers>
        </Wrapper>
    );
};

export default withRouter(RoundTableVideo);

import React from 'react';
import styled from 'styled-components';
import { AWS_IMAGES_ENDPOINT, S3_ENDPOINT_IMAGES } from '../../../config';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 328px;
    height: 236px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 8px rgba(0, 0, 0, 0.05),
        0 2px 4px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    margin: 0 12px 24px 12px;
`;

const Image = styled.img`
    height: 156px;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
`;

const Title = styled.div`
    font-family: Cabin, sans-serif;
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 4px;
`;

const Subtitle = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 13px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
`;

const EventCard = ({ imageUrl, title, subtitle }) => {
    let displayImage = imageUrl;
    if (AWS_IMAGES_ENDPOINT && S3_ENDPOINT_IMAGES && imageUrl) {
        displayImage = imageUrl.replace(S3_ENDPOINT_IMAGES, AWS_IMAGES_ENDPOINT);
    }
    return (
        <Wrapper>
            <Image src={displayImage} />
            <TextContainer>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </TextContainer>
        </Wrapper>
    );
};

export default EventCard;

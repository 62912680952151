import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Avatar } from 'react-md';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';

import styles from './styles';
import DeleteMessageButton from '../DeleteMessageButton';
import get from 'lodash/get';
import entities from '../../../../../VirtualSession/constants/entities';
import { useVMState } from '../../../virtualFeature/containers/main';
import { useTheme } from '../../../../../../components/Theme/ThemeContext';
import { urlRegex } from '../../../../../SignInPage/constants/strings';

const { virtualEventUserEntity } = entities;

// Add cursor: pointer when Avatar comes back as clickable
const CustomAvatar = styled(Avatar)`
    border: 0px solid rgba(0, 0, 0);

    & .md-avatar {
        border: none;
    }

    &.md-avatar--default {
        ${props => props.noborder && 'background: transparent'};
    }
`;

const defaultBubbleStyles = {
    userBubble: {},
    chatbubble: {},
    text: {},
};
const { userBubble, chatbubble, text } = defaultBubbleStyles;
const userBubbleStyle = {
    ...styles.chatbubble,
    ...styles.chatbubbleOrientationNormal,
    ...chatbubble,
    ...userBubble,
};
const friendBubbleStyle = {
    ...styles.chatbubble,
    ...styles.recipientChatbubble,
    ...styles.recipientChatbubbleOrientationNormal,
    ...chatbubble,
    ...userBubble,
    marginLeft: '48px',
};

const systemBubbleStyle = {
    ...styles.systemBubble,
};

const messageDefaultStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: '#ffffff',
    fontSize: 16,
    fontWeight: '400',
    margin: 0,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
};

const currentUserDeletedMsgStyle = {
    fontSize: 13,
    color: 'rgba(255, 255, 255, 0.74)',
    fontStyle: 'italic',
};

const otherUserDeletedMsgStyle = {
    fontSize: 13,
    color: 'rgba(28,28,30,0.54)',
    fontStyle: 'italic',
};

const deletedIconStyle = {
    fontSize: 16,
    height: 16,
    width: 16,
    marginRight: 5,
};

const defaultTimeStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: 11,
    marginTop: '4px',
    marginBottom: '0px',
    textAlign: 'left',
};

const userTimeStyle = {
    ...defaultTimeStyle,
    color: 'rgba(255, 255, 255, 0.5)',
    textAlign: 'right',
};

const userAndTimeWrapperStyle = { display: 'flex', justifyContent: 'space-between' };
const userAndTimeSpaceStyle = { width: '20px' };

const ChatBubble = ({
    message,
    showAvatar,
    avatarClick,
    chatRoomId,
    onSetMessageToDelete,
    nonVirtualQA,
}) => {
    const stateCtx = useVMState();
    const { theme } = useTheme();
    const { virtualEventUser } = stateCtx;
    const virtualEventUserRole = get(virtualEventUser, 'data.role');
    const isVirtualEvenUserModerator =
        virtualEventUserRole === virtualEventUserEntity.role.moderator;
    const isVirtualEventUserHost =
        virtualEventUserRole === virtualEventUserEntity.role.roundTableHost;
    const [messageHovered, setMessageHovered] = useState(null);
    const currentUser = useMemo(() => {
        return JSON.parse(localStorage.getItem('user'));
    }, []);

    const isCurrentUserMessage = message.user.id === currentUser.id;

    const themedUserBubbleStyle = { ...userBubbleStyle, backgroundColor: theme.primary };
    const chatBubbleStyles = isCurrentUserMessage ? themedUserBubbleStyle : friendBubbleStyle;
    let messageStyle = isCurrentUserMessage
        ? messageDefaultStyle
        : {
              ...messageDefaultStyle,
              color: '#000000',
          };
    if (message.big) {
        messageStyle = { ...messageStyle, fontSize: 32 };
    }
    if (message.deleted) {
        const styleToAdd = isCurrentUserMessage
            ? currentUserDeletedMsgStyle
            : otherUserDeletedMsgStyle;
        messageStyle = { ...messageStyle, ...styleToAdd };
    }
    const messageTimeStyle = isCurrentUserMessage ? userTimeStyle : defaultTimeStyle;
    const { user } = message;
    const displaySenderName = !isCurrentUserMessage && user && user.displayName;
    const showDeletedMessageForModerator =
        isVirtualEvenUserModerator && message.removedFromModerator;
    const showMessageAsDeleted = message.removedFromUser || showDeletedMessageForModerator;
    const messageText = message?.body || '';
    const messageBodyWithLinks = messageText.replaceAll(
        urlRegex,
        `<a href="$1" target="_blank" rel="noopener noreferrer" style="word-break: break-all; overflow: hidden; white-space: normal; color: ${
            isCurrentUserMessage ? '#fff' : 'inherit'
        }">$1</a>`,
    );

    const messageBody = () => (
        <>
            {message.big && <p />}
            {!showMessageAsDeleted && (
                <span
                    style={messageStyle}
                    dangerouslySetInnerHTML={{ __html: messageBodyWithLinks }}
                ></span>
            )}
            {showMessageAsDeleted && (
                <p style={{ ...messageStyle, flexDirection: 'row', alignItems: 'center' }}>
                    <DoDisturbAltOutlinedIcon style={deletedIconStyle} />
                    Message deleted
                </p>
            )}
            {message.big && <p />}
            {/*
                    Friends messages are shown with their names below message body
                    so we have to wrap name and time in a extra div
                */}
            {displaySenderName && (
                <div style={userAndTimeWrapperStyle}>
                    <p style={messageTimeStyle}>{user.displayName}</p>
                    <div style={userAndTimeSpaceStyle} />
                    <p style={messageTimeStyle}> {message.createdAt}</p>
                </div>
            )}
            {/* otherwise just display time (right aligned) for blue bubbles */}
            {!displaySenderName && <p style={messageTimeStyle}> {message.createdAt}</p>}
        </>
    );

    const renderMessage = hasAvatar => {
        // System messages are not displayed in bubbles
        if (message.system) {
            return (
                <div style={systemBubbleStyle}>
                    <p>{message.body}</p>
                </div>
            );
        }

        if (hasAvatar) {
            return (
                <div style={{ display: 'flex' }}>
                    {user.imageUrl ? (
                        <CustomAvatar src={user.imageUrl} onClick={avatarClick} noborder={1} />
                    ) : (
                        <CustomAvatar color={user.color} onClick={avatarClick}>
                            {user.initials}
                        </CustomAvatar>
                    )}
                    <div
                        style={{ ...chatBubbleStyles, marginTop: 0, marginLeft: '8px' }}
                        id={message.id}
                    >
                        {messageBody()}
                    </div>
                </div>
            );
        } else {
            return (
                <div style={chatBubbleStyles} id={message.id}>
                    {messageBody()}
                </div>
            );
        }
    };

    return (
        <div
            style={{ ...styles.chatbubbleWrapper }}
            onMouseEnter={() =>
                setMessageHovered(
                    (isCurrentUserMessage ||
                        isVirtualEvenUserModerator ||
                        isVirtualEventUserHost ||
                        nonVirtualQA) &&
                        !showMessageAsDeleted
                        ? message.id
                        : null,
                )
            }
            onMouseLeave={() => setMessageHovered(null)}
        >
            {isCurrentUserMessage && message.id === messageHovered && (
                <DeleteMessageButton
                    onButtonClick={() =>
                        onSetMessageToDelete({ ...message, txt: message.body }, chatRoomId)
                    }
                />
            )}
            {isCurrentUserMessage && message.id !== messageHovered && <div />}
            {renderMessage(showAvatar)}
            {!isCurrentUserMessage && message.id === messageHovered && (
                <DeleteMessageButton
                    onButtonClick={() =>
                        onSetMessageToDelete({ ...message, txt: message.body }, chatRoomId)
                    }
                />
            )}
        </div>
    );
};

export default ChatBubble;

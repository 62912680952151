import React from 'react';
import LanguageSelect from './LanguageSelect';
import { useTranslationsStore } from '../../../../../../../../stores/TranslationsStore';
import Stack from '@mui/material/Stack';
import CaptionMessage from './CaptionsMessage';
import Box from '@mui/material/Box';
import noMessagesImage from '../../../../../../../../assets/images/no-questions.png';
import { useVMState } from '../../../../containers/main';
import styled from 'styled-components';
import VisibilityToaster from '../common/VisibilityToaster';
import get from 'lodash/get';

const PlaceholderImage = styled.img`
    width: 250px;
    height: 200px;
`;

const Text = styled.span`
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
`;

const TranslatedCaptions = ({ controls }) => {
    const { messages } = useTranslationsStore();
    const containerRef = React.useRef(null);
    const [isUserAtBottom, setIsUserAtBottom] = React.useState(true);
    const { virtualEventSession, virtualEventUser, socket, sessionId } = useVMState();
    const { setSelectedLanguage } = useTranslationsStore();
    const { showCaptions } =
        virtualEventSession && virtualEventSession.data ? virtualEventSession.data : {};
    const virtualEventUserData = get(virtualEventUser, 'data') || {};
    const userCaptionTranslationLanguage =
        get(virtualEventUser, 'data.captionTranslationLanguage') || 'en';

    React.useEffect(() => {
        if (userCaptionTranslationLanguage && userCaptionTranslationLanguage)
            setSelectedLanguage(userCaptionTranslationLanguage);
    }, [userCaptionTranslationLanguage, setSelectedLanguage]);

    React.useEffect(() => {
        if (containerRef && containerRef.current && isUserAtBottom) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [messages, isUserAtBottom]);

    const onLanguageChange = React.useCallback(
        value => {
            socket.emit('updateData', {
                objectId: sessionId,
                virtualEventSession: {
                    VirtualEventUsers: [
                        {
                            id: virtualEventUserData.id,
                            captionTranslationLanguage: value,
                        },
                    ],
                },
            });
        },
        [virtualEventUserData, sessionId],
    );

    const messageToDisplay = Array.from(messages.values());
    const messagesExist = messageToDisplay.length > 0;

    const handleScroll = React.useCallback(() => {
        const { scrollTop, clientHeight, scrollHeight } = containerRef.current;

        const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
        setIsUserAtBottom(distanceFromBottom < 100);
    }, []);

    return (
        <Box position="relative" height="100%">
            {messagesExist ? (
                <Stack
                    height="calc(100% - 80px)"
                    overflow="scroll"
                    ref={containerRef}
                    onScroll={handleScroll}
                >
                    {messageToDisplay.map((message, index) => (
                        <CaptionMessage
                            key={message.id}
                            message={message}
                            showName={
                                index === 0 ||
                                message.participantId !== messageToDisplay[index - 1].participantId
                            }
                        />
                    ))}
                </Stack>
            ) : (
                <Stack
                    height="calc(100% - 80px)"
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                >
                    <PlaceholderImage src={noMessagesImage} alt="No messages" />
                    <Text>No captions yet</Text>
                </Stack>
            )}

            {showCaptions || !controls ? (
                <Box position="absolute" bottom={12}>
                    <LanguageSelect onChange={onLanguageChange} />
                </Box>
            ) : (
                <Box bottom={12} position="absolute" width="100%">
                    <VisibilityToaster />
                </Box>
            )}
        </Box>
    );
};

export default TranslatedCaptions;

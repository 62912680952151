import { createRequestTypes } from '../../../utils/types';
import GamificationService from '../services/GamificationService';
import { getLocalAppStateAsync } from '../../../services/api/db';

const actionTypes = {
    LOAD_ACHIEVEMENTS: createRequestTypes('LOAD_ACHIEVEMENTS'),
    LOAD_ACHIEVEMENT_TYPES: createRequestTypes('LOAD_ACHIEVEMENT_TYPES'),
    LOAD_IS_GAMIFICATION_ENABLED: createRequestTypes('LOAD_IS_GAMIFICATION_ENABLED'),
    CLAIM_REWARD: createRequestTypes('CLAIM_REWARD'),
};

const loadAchievements = filter => async dispatch => {
    dispatch({ type: actionTypes.LOAD_ACHIEVEMENTS.REQUEST });

    try {
        const currentEvent = await getLocalAppStateAsync();
        const { achievements, totalPoints } = await GamificationService.getUserAchievements({
            eventId: currentEvent.id,
            filter,
        });

        dispatch({
            type: actionTypes.LOAD_ACHIEVEMENTS.SUCCESS,
            payload: { achievements, totalPoints },
        });
    } catch (error) {
        dispatch({ type: actionTypes.LOAD_ACHIEVEMENTS.FAILURE, payload: { error } });
    }
};

const loadAchievementTypes = () => async dispatch => {
    dispatch({ type: actionTypes.LOAD_ACHIEVEMENT_TYPES.REQUEST });

    try {
        const { id: eventId } = await getLocalAppStateAsync();
        const achievementTypes = await GamificationService.getAvailableAchievementTypes(eventId);

        dispatch({
            type: actionTypes.LOAD_ACHIEVEMENT_TYPES.SUCCESS,
            payload: { achievementTypes },
        });
    } catch (error) {
        dispatch({ type: actionTypes.LOAD_ACHIEVEMENT_TYPES.FAILURE, payload: { error } });
    }
};

const loadIsGamificationEnabled = () => async dispatch => {
    let isEnabled = false;

    dispatch({ type: actionTypes.LOAD_IS_GAMIFICATION_ENABLED.REQUEST });

    try {
        const { id: eventId } = await getLocalAppStateAsync();
        isEnabled = await GamificationService.getIsGamificationEnabled({ eventId });

        dispatch({
            type: actionTypes.LOAD_IS_GAMIFICATION_ENABLED.SUCCESS,
            payload: { isEnabled },
        });
    } catch (error) {
        dispatch({ type: actionTypes.LOAD_IS_GAMIFICATION_ENABLED.FAILURE, payload: { error } });
    }

    return isEnabled;
};

const claimReward = achievementId => async dispatch => {
    dispatch({ type: actionTypes.CLAIM_REWARD.REQUEST });

    try {
        await GamificationService.claimReward({ achievementId });
        dispatch({ type: actionTypes.CLAIM_REWARD.SUCCESS, payload: { achievementId } });
    } catch (error) {
        dispatch({ type: actionTypes.CLAIM_REWARD.FAILURE, payload: { error } });
    }
};

export {
    actionTypes,
    loadAchievements,
    loadAchievementTypes,
    loadIsGamificationEnabled,
    claimReward,
};

import React from 'react';
import { DialogContainer } from 'react-md';
import styled from 'styled-components';

const StyledDialog = styled(DialogContainer)`
    .md-paper {
        width: 80%;
        height: 100%;
        background-color: transparent;
        box-shadow: unset;

        .md-title {
            font-family: 'Cabin', sans-serif;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            color: #ffffff;
        }
    }

    .md-dialog-content {
        background-color: #ffffff;
        height: 100%;
        border-radius: 16px;
        padding: 24px !important;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
`;

const Modal = ({ visible, onHide, title, content }) => {
    return (
        <StyledDialog
            id="exhibitor-modal"
            title={title}
            visible={visible}
            onHide={onHide}
            titleStyle={{
                backgroundColor: 'transparent',
            }}
            initialFocus="#exhibitor-modal-content"
        >
            <Wrapper id="exhibitor-modal-content">{content}</Wrapper>
        </StyledDialog>
    );
};

export default Modal;

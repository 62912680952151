import * as React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    AppointmentCardButton,
    AppointmentCardButtonLight,
    AppointmentCardButtonsWrapper,
    AppointmentCardDetails,
    AppointmentCardLabel,
    AppointmentCardTextWrapper,
    AppointmentCardTitle,
    AppointmentCardWrapper,
    ChatBubbleWrapper,
} from './styles';
import { navigateTo } from '../../Navigation/NavigationLink';
import { useTheme } from '../../Theme/ThemeContext';
import {
    acceptAppointment as acceptAppointmentAction,
    declineAppointment as declineAppointmentAction,
    deleteAppointment as deleteAppointmentAction,
} from '../../../scenes/Talk/actions';
import { APPOINTMENT_STATE } from '../../../scenes/Talk/constants';
import Auth from '../../../services/api/auth';
import { Flex } from '../../../scenes/VirtualSession/components/userInteraction/questionsAndAnswers/QuestionsAndAnswers';
import { useContext } from 'react';
import { TimezoneContext } from '../../../scenes/Timezone/context';

const DATE_FORMAT = 'DD MMMM';
const TIME_FORMAT = 'HH:mm';

const AppointmentCard = ({
    data,
    acceptAppointment,
    declineAppointment,
    deleteAppointment,
    match,
    history,
    location,
    goToAppointmentDetails,
}) => {
    const { title, location: appointmentLocation, needsResponse, isVirtual } = data;
    const { theme } = useTheme();
    const user = Auth.getUser();
    const { getUnixToSelectedTimezone, getUtcToSelectedTimezone } = useContext(TimezoneContext);

    const isOwnAppointment = data.creator === user.id;
    const isDeclined = data.action === APPOINTMENT_STATE.DECLINED;
    const isAccepted = data.action === APPOINTMENT_STATE.ACCEPTED;

    const checkIfIsNow = (start, end, getUtcToSelectedTimezone) => {
        const now = getUtcToSelectedTimezone(moment.utc(), null, null);
        return now.isBetween(start, end, null, '[]');
    };

    const onAcceptAppointment = () => {
        acceptAppointment({ ...data, id: data.eurekaId });
    };

    const onDeclineAppointment = () => {
        declineAppointment({ ...data, id: data.eurekaId });
    };

    const onCancelRequestAppointment = () => {
        deleteAppointment({ ...data, id: data.eurekaId, reference: data.id });
    };

    const onGoToMeeting = () => {
        navigateTo({
            match,
            history,
            location,
            path: `/virtual-session-room/${data.id}`,
            state: {
                keepHistoryLength: true,
            },
        });
    };

    const onGoToAppointmentDetails = () => {
        goToAppointmentDetails(data.eurekaId);
    };

    const start = getUnixToSelectedTimezone(data.start);
    const end = getUnixToSelectedTimezone(data.end);

    const date = start.format(DATE_FORMAT);
    const timeStart = start.format(TIME_FORMAT);
    const timeEnd = end.format(TIME_FORMAT);
    const dateTime = `${date} - ${timeStart} - ${timeEnd}`;

    const now = moment.utc();

    const isNow = now.isBetween(moment.unix(data.start), moment.unix(data.end), null, '[]');

    if (isDeclined && !isOwnAppointment) {
        return null;
    }

    return (
        <div style={ChatBubbleWrapper} key={`appointment_message_${data.id}`}>
            <AppointmentCardWrapper alignRight={isOwnAppointment}>
                <AppointmentCardTextWrapper>
                    <Flex>
                        <AppointmentCardTitle>{title} </AppointmentCardTitle>
                        {isDeclined && <AppointmentCardLabel red>Declined</AppointmentCardLabel>}
                        {isNow && !isDeclined && <AppointmentCardLabel>Now</AppointmentCardLabel>}
                    </Flex>
                    <AppointmentCardDetails>{dateTime}</AppointmentCardDetails>
                    <AppointmentCardDetails>{appointmentLocation}</AppointmentCardDetails>
                </AppointmentCardTextWrapper>
                <AppointmentCardButtonsWrapper>
                    {needsResponse && (
                        <>
                            <AppointmentCardButton
                                color={theme.primary}
                                onClick={onAcceptAppointment}
                            >
                                Accept
                            </AppointmentCardButton>
                            <AppointmentCardButtonLight
                                color={theme.primary}
                                onClick={onDeclineAppointment}
                            >
                                Decline
                            </AppointmentCardButtonLight>
                        </>
                    )}

                    {isAccepted && (
                        <>
                            {isVirtual && (
                                <AppointmentCardButton
                                    color={theme.primary}
                                    onClick={onGoToMeeting}
                                >
                                    Go to meeting
                                </AppointmentCardButton>
                            )}
                            <AppointmentCardButtonLight
                                color={theme.primary}
                                onClick={onGoToAppointmentDetails}
                            >
                                Details
                            </AppointmentCardButtonLight>
                        </>
                    )}

                    {isOwnAppointment && needsResponse && (
                        <AppointmentCardButtonLight
                            color={theme.primary}
                            onClick={onCancelRequestAppointment}
                        >
                            Cancel request
                        </AppointmentCardButtonLight>
                    )}
                </AppointmentCardButtonsWrapper>
            </AppointmentCardWrapper>
        </div>
    );
};

export default connect(null, {
    acceptAppointment: acceptAppointmentAction,
    declineAppointment: declineAppointmentAction,
    deleteAppointment: deleteAppointmentAction,
})(withRouter(AppointmentCard));

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSocket } from '../../../components/Session/SocketContext';

import { loadEvents, loadModeratedSessions } from '../actions';
import Auth from '../../../services/api/auth';
import { getFullProfile } from '../../../services/api/eureka';

const useInvites = isSociety => {
    const dispatch = useDispatch();
    const { socket } = useSocket();

    const handleInvitesEvent = async data => {
        const user = Auth.getUser();
        if (!(user && user.id)) {
            return;
        }
        if (data.userId === user.id && data.eventId) {
            const updatedUser = await getFullProfile();
            if (updatedUser && updatedUser.role === 'moderator') {
                Auth.setRole(updatedUser.role);
            }
            if (isSociety) {
                dispatch(loadEvents());
            }

            dispatch(loadModeratedSessions(data.eventId));
        }
    };

    useEffect(() => {
        socket.on('updateInvites', handleInvitesEvent);
        return () => {
            if (socket) {
                socket.off('updateInvites', handleInvitesEvent);
            }
        };
    }, [dispatch]);
};

export default useInvites;

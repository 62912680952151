import React, { useContext } from 'react';
import styled from 'styled-components';
import ListCard from './ListCard';
import { Flex } from '../../scenes/common/Flex';
import Bookmark from './Bookmark';
import { Page, Document } from 'react-pdf';
import { getString } from '../../services/api/store';
import LiveLabel from './LiveLabel';
import { VideoIcon } from '../../scenes/Programme/style/style';
import HappeningNow from './HappeningNow';
import { IconContainer } from '../ObjectListItem/Timeslot';
import { useTheme } from '../Theme/ThemeContext';
import Auth from '../../services/api/auth';
import { TimezoneContext } from '../../scenes/Timezone/context';

const StyledDocument = styled(Document)`
    & > * {
        width: 100% !important;
        height: 100% !important;
        overflow: hidden;

        canvas {
            width: 100% !important;
            height: unset !important;
        }
    }

    width: 100%;
    height: 100%;
`;

const Icons = ({ props }) => {
    const currentUser = Auth.getUser();
    const { theme } = useTheme();
    return (
        <IconContainer customMarginTop={'8px'}>
            {props.showLive && props.isRoundTable && props.isFull && (
                <>
                    {currentUser ? (
                        <LiveLabel customColor={'rgba(0, 0, 0, 0.38)'} customText={'FULL'} />
                    ) : (
                        <LiveLabel />
                    )}
                </>
            )}
            {props.showLive && !props.isFull ? (
                <LiveLabel />
            ) : (
                <>
                    {props.timeslot?.params?.virtualSession && (
                        <VideoIcon color={'rgba(0, 0, 0, 0.38)'}>videocam</VideoIcon>
                    )}
                </>
            )}
            {!!props.classifierIcons?.length && <Flex>{props.classifierIcons}</Flex>}
            {props.timeslot.start && (
                <HappeningNow
                    start={props.timeslot?.start}
                    end={props.timeslot?.end}
                    color={theme.contrast}
                    isTimelineTag={true}
                />
            )}
        </IconContainer>
    );
};

const TimeslotGridListItem = props => {
    const { getUtcToSelectedTimezone } = useContext(TimezoneContext);
    const onDocumentLoadSuccess = () => {};
    let startTime;
    let endTime;

    if (props.timeslot.type !== 'programelement' && props.timeslot.start) {
        let start = getUtcToSelectedTimezone(props.timeslot.start);
        let end = getUtcToSelectedTimezone(props.timeslot.end);

        const dateSettings = getString('datetime');
        const datePattern =
            dateSettings && dateSettings.pickerDateFormat && dateSettings.timeFormat
                ? `${dateSettings.pickerDateFormat}, ${dateSettings.timeFormat}`
                : 'ddd DD MMM, HH:MM';
        startTime = start.format(datePattern);
        endTime = end.format('HH:mm');
    }

    let pdfUrl = props.hostPresentation?.uploadedPresentationUrl;
    if ((!pdfUrl || !props.isRoundTable) && props.timeslot?.posterPdf) {
        pdfUrl = props.timeslot?.posterPdf?.url;
    }

    return (
        <ListCard
            key={'institution_' + props.timeslot.id}
            dateAndTime={
                props.timeslot.type !== 'programelement' &&
                startTime &&
                endTime &&
                `${startTime} - ${endTime}`
            }
            primaryText={props.timeslot.name}
            secondaryText={props.timeslot.subNameList}
            elementReplaceImage={
                pdfUrl ? (
                    <StyledDocument
                        file={pdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={err => console.log('err->', err)}
                    >
                        <Page pageNumber={1} />
                    </StyledDocument>
                ) : null
            }
            isLogo={false}
            type={props.timeslot.type}
            icons={<Icons props={props} />}
            rightIcon={
                <Bookmark
                    favoriteStatus={props.favoriteStatus}
                    clickMyCongress={props.onFavorite}
                />
            }
        />
    );
};

export default TimeslotGridListItem;

import React, { useContext, useEffect, useState } from 'react';

import { DialogContainerStyled } from '../style/modalsStyle';
import { useTheme } from '../../../components/Theme/ThemeContext';
import * as palette from '../../../components/General/Variables';
import { getFullProfile, getUserSettings, updateUserSettings } from '../../../services/api/eureka';
import { getLocalAppState } from '../../../services/api';
import { getString } from '../../../services/api/store';
import { useGlobalState } from '../../../utils/container';
import { TimezoneContext } from '../../Timezone/context';
import * as string from '../constants/index';
import SettingsContent from '../components/settings/SettingsContent';
import ComplexDialog from '../../../components/Dialog/ComplexDialog';
import useSocietySettings from '../../Society/hooks/useSocietySettings';
import { renderFormButtons } from '../components/profile/renderFormButtons';

const SettingsModal = ({ onClose, afterSignUp }) => {
    const { isSociety } = useSocietySettings();

    const [state, setState] = useState({
        profileVisible: true,
        chatAvailable: true,
        eventId: null,
        eventName: '',
    });
    const [user, setUser] = useState(null);
    const [dialogTimezone, setDialogTimezone] = useState('local');
    const isMobile = window.innerWidth > palette.MAX_SMALL_PHONE_INT;
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const { theme } = useTheme();

    let timezoneContext = useContext(TimezoneContext);

    if (!timezoneContext) {
        timezoneContext = {
            getLocalTimezoneName: null,
            getEventTimezoneName: null,
            selectedTimezone: null,
            updateTimezoneSettings: null,
        };
    }

    const {
        getLocalTimezoneName,
        getEventTimezoneName,
        selectedTimezone,
        updateTimezoneSettings,
    } = timezoneContext;

    useEffect(() => {
        if (isSociety) {
            return;
        }
        setDialogTimezone(selectedTimezone);
    }, []);

    const updateTimezone = zone => {
        setDialogTimezone(zone);
    };

    const TIMEZONE_LOCAL_LABEL = getString('settingsTimezoneLocal') || string.TIMEZONE_LOCAL_LABEL;
    const TIMEZONE_EVENT_LABEL = getString('settingsTimezoneEvent') || string.TIMEZONE_EVENT_LABEL;
    const SETTINGS_TITLE = getString('settingsEditTitle') || string.SETTINGS_TITLE;
    const SAVE_BUTTON_LABEL = getString('profileSave') || string.SAVE;
    const CANCEL_BUTTON_LABEL = getString('cancel') || string.CANCEL;

    const options = isSociety
        ? []
        : [
              {
                  label: `${TIMEZONE_LOCAL_LABEL} (${getLocalTimezoneName()})`,
                  value: 'local',
              },
              {
                  label: `${TIMEZONE_EVENT_LABEL} (${getEventTimezoneName()})`,
                  value: 'event',
              },
          ];

    useEffect(() => {
        getLocalAppState(async (err, appState) => {
            if (err) {
                console.log(err);
            } else {
                let response;
                if (!theme.kiosk) {
                    response = await getUserSettings(appState.eventId);
                    const userData = await getFullProfile();
                    setUser(userData);
                }

                setState({
                    profileVisible: response && response.visible === true,
                    chatAvailable: response && response.available,
                    eventId: appState.eventId,
                    eventName: appState.eventName,
                });
            }
        });
    }, []);

    const handleChange = (value, name) => {
        setState({ ...state, [name]: value });
    };

    const onSave = async () => {
        if (!theme.kiosk) {
            const settings = {
                visible: isSociety ? null : state.profileVisible,
                available: state.chatAvailable,
                eventId: state.eventId,
                eventTitle: state.eventName,
            };

            await updateUserSettings(settings);
        }

        if (isSociety) {
            onClose();
            return;
        }

        await updateTimezoneSettings(dialogTimezone);

        if (socket && !theme.kiosk) {
            socket.emit('privacySettingsUpdated', {
                eventId: state.eventId,
                userId: user.id,
            });
        }

        onClose();
    };

    const dialogOptions = [
        {
            title: SAVE_BUTTON_LABEL,
            variant: 'text',
            type: 'save',
            onClick: onSave,
        },
        {
            title: CANCEL_BUTTON_LABEL,
            variant: 'text',
            type: 'cancel',
            onClick: onClose,
        },
    ];

    return (
        <React.Fragment>
            {isMobile ? (
                <ComplexDialog
                    title={SETTINGS_TITLE}
                    visible={true}
                    onClose={onClose}
                    contentStyle={{ padding: 0, textAlign: 'left' }}
                    displayBoxShadow
                    options={dialogOptions}
                >
                    <SettingsContent
                        theme={theme}
                        options={options}
                        afterSignUp={afterSignUp}
                        dialogTimezone={dialogTimezone}
                        updateTimezone={updateTimezone}
                        handleChange={handleChange}
                        state={state}
                        isSociety={isSociety}
                    />
                </ComplexDialog>
            ) : (
                <DialogContainerStyled
                    id="speed-boost"
                    aria-describedby="speed-boost-description"
                    style={{
                        zIndex: '100 !important',
                    }}
                    visible={true}
                    fullPage={true}
                    pageX={12}
                    pageY={12}
                    aria-labelledby="simple-full-page-dialog-title"
                    onHide={() => {}}
                >
                    <SettingsContent
                        theme={theme}
                        options={options}
                        afterSignUp={afterSignUp}
                        onClose={onClose}
                        dialogTimezone={dialogTimezone}
                        updateTimezone={updateTimezone}
                        handleChange={handleChange}
                        onSave={onSave}
                        state={state}
                        isSociety={isSociety}
                    />
                    {renderFormButtons(dialogOptions, isSociety, theme)}
                </DialogContainerStyled>
            )}
        </React.Fragment>
    );
};
export default SettingsModal;

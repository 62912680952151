import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar, FontIcon } from 'react-md';
import * as palette from '../../components/General/Variables.js';
import { getImageSource } from '../../services/api/db';
import backgroundDefaultImage from '../../assets/images/banner-placeholder.png';
import BackgroundBanner from './BackgroundBanner';

const HeaderImg = styled.div`
    height: 150px;
    width: 150px;
    margin: auto;
    margin-top: -78px;
    border-radius: 100%;
    border: solid 1px #cccccc;
    background-color: ${palette.COLOR_WHITE};
    position: relative;
    margin-left: -65px;
    left: 50%;
    margin-bottom: -64px;
`;

const PersonImage = styled.img`
    height: inherit;
    width: inherit;
    object-fit: cover !important;
    border-radius: 50%;
`;

const PersonAvatar = styled(Avatar)`
    background-color: ${palette.COLOR_WHITE};
    color: ${palette.COLOR_GREY};
    border: 0px solid rgba(0, 0, 0);
    height: 123px !important;
    width: 123px !important;
    display: inherit;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const ContainerBookmarkAndImage = styled.div`
    position: relative;
`;

const Container = styled.div`
    padding: 0 16px;
`;

const PersonDetailHeader = ({ picture, backgroundImage }) => {
    const [background, setBackground] = useState(null);

    useEffect(() => {
        if (backgroundImage) {
            getImageSource(backgroundImage, (err, result) => {
                if (result) {
                    setBackground(result);
                }
            });
        } else {
            setBackground('');
        }
    }, [backgroundImage]);

    const PersonsIcon = () => (
        <PersonAvatar
            icon={
                <FontIcon forceSize={150} forceFontSize={true}>
                    {palette.ICON_PERSON}
                </FontIcon>
            }
        />
    );

    return (
        <Container>
            <BackgroundBanner src={background || backgroundDefaultImage} />
            <ContainerBookmarkAndImage>
                {picture ? (
                    <HeaderImg>
                        <PersonImage src={picture} alt={'picture'} />
                    </HeaderImg>
                ) : (
                    <PersonsIcon />
                )}
            </ContainerBookmarkAndImage>
        </Container>
    );
};

export default PersonDetailHeader;

export default class PreventService {
    constructor() {
        this._preventBroadcasting = false;
    }

    // start of broadcasting handling

    preventBroadcasting() {
        this._preventBroadcasting = true;
    }

    allowBroadcasting() {
        this._preventBroadcasting = false;
    }

    broadcastingDisabled() {
        return this._preventBroadcasting;
    }

    // end of broadcasting handling
}

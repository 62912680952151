import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import Loader from '../../components/General/Loader';
import { getObjectClassWithId } from '../../services/api/db';
import TalkContainer from './containers/TalkRoomContainer';
import { getString } from '../../services/api/store';

const TalkRoom = ({ objectId, eventId, setTitle }) => {
    const [conversationItem, setConversationItem] = useState(null);
    const [representativesInfo, setRepresentativesInfo] = useState(null);
    const [externalObject, setExternalObject] = useState(null);
    const [chatType, setChatType] = useState(`webapp_chat`);
    const TEXT_REPRESENTATIVE_ROLE = getString('representativeRole') || 'Representative of';

    // Takes objectId from URL params. It could be a session or institution for example
    useEffect(() => {
        if (setTitle && typeof setTitle === 'function') {
            setTitle();
        }
        if (objectId) {
            // Asks app DB for the whole object.
            // Object.name is needed to create a new talk room on Eureka DB
            getObjectClassWithId(objectId, (err, objectClass, objectItem) => {
                if (!err) {
                    setChatType(`webapp_chat_${objectClass}`);
                    setExternalObject(objectItem);
                } else {
                    console.log(
                        ` >>>>>>>>>>>>>>>>>>>>>>>>>>>>> external object with ID ${objectId} not found`,
                    );
                }
            });
        }
    }, [objectId]);

    useEffect(() => {
        if (eventId && externalObject && externalObject.id) {
            const sItem = {
                objectId: externalObject.id,
                roomId: externalObject.id,
                objectTitle: externalObject.name,
                eventId,
                eventTitle: 'webapp_chat_institution',
            };
            const representativesList = get(externalObject, 'params.representatives', []);
            if (representativesList.length) {
                const representatives = representativesList.map(user => ({
                    id: user.userId,
                    title: `${TEXT_REPRESENTATIVE_ROLE} ${externalObject.name}`,
                }));
                setRepresentativesInfo(representatives);
            }
            setConversationItem(sItem);
        }
    }, [eventId, externalObject]);

    const renderLoader = () => {
        return <Loader />;
    };

    const renderTalkContainer = () => {
        return (
            <TalkContainer
                conversation={conversationItem}
                title={conversationItem.objectTitle}
                representatives={representativesInfo}
                chatType={chatType}
            />
        );
    };

    return <>{!conversationItem ? renderLoader() : renderTalkContainer()}</>;
};

const mapStateToProps = state => ({ eventId: state.talk.settings.eventId });
export default connect(mapStateToProps)(withRouter(TalkRoom));

import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import { FontIcon } from 'react-md';

const StyledDialog = withStyles(() => ({
    paper: {
        borderRadius: 16,
        width: 870,
        '@media(min-width: 1400px)': {
            width: 1000,
        },
        '@media(min-width: 1800px)': {
            width: 1300,
        },
        '@media(min-width: 2200px)': {
            width: 1600,
        },
        '@media(min-width: 2600px)': {
            width: 1900,
        },
    },
}))(Dialog);

const contentStyle = {
    padding: '24px 16px 16px 16px',
};

const iconStyle = {
    cursor: 'pointer',
};

const FlexRow = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 24px;
`;

const AspectRatioFitContainer = styled.div`
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 8px;
    overflow: hidden;
`;

const EnlargeStreamModal = ({ open, handleClose, children }) => {
    return (
        <StyledDialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogContent style={contentStyle}>
                <FlexRow>
                    <FontIcon style={iconStyle} onClick={handleClose}>
                        close
                    </FontIcon>
                </FlexRow>
                <AspectRatioFitContainer>{children}</AspectRatioFitContainer>
            </DialogContent>
        </StyledDialog>
    );
};

export default EnlargeStreamModal;

import Auth from '../../../services/api/auth';

export const checkIfUserHasAuthorizationByTypeRestrictions = (
    restrictedGroup,
    currentUserGroups = [],
) => {
    if (!restrictedGroup) {
        return true;
    }

    if (!Auth.isUserAuthenticated()) {
        return false;
    }

    return currentUserGroups.some(group => group.id === restrictedGroup);
};

import React from 'react';
import { Slider as MdSlider } from 'react-md';
import styled from 'styled-components';

const Wrapper = styled.div`
    border-radius: 4px;
    height: 4px;
    width: 100%;
`;

const StyledSlider = styled(MdSlider)`
    width: 100%;

    .md-slider-thumb {
        width: 16px;
        height: 16px;
        background-color: ${props => (props.dark ? 'rgba(0, 0 ,0 , 0.87)' : '#fff')};
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.05),
            0px 2px 3px rgba(0, 0, 0, 0.1);
    }

    .md-slider-track {
        height: 4px;
        background-color: ${props =>
            props.dark ? 'rgba(221, 221, 221, 1)' : 'rgba(255, 255, 255, 0.2)'};
        margin: 0 !important;
        border-radius: 2px;

        &-fill {
            height: 4px;
            border-radius: 2px;
            background-color: ${props =>
                props.dark ? 'rgba(0, 0 ,0 , 0.6)' : 'rgba(255, 255, 255, 0.6)'};
        }
    }

    .md-fake-btn {
        display: none;
    }
`;

const VideoSlider = ({ min, max, value, onChange, step, dark, ...props }) => {
    return (
        <Wrapper>
            <StyledSlider
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={onChange}
                dark={dark ? 1 : 0}
                {...props}
            />
        </Wrapper>
    );
};

export default VideoSlider;

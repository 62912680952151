import styled from 'styled-components';
import { MakeMainScreenButton, StyledCell } from '../../../styles';
import { useGlobalMutation, useGlobalState } from '../../../../../utils/container';
import * as palette from '../../../../../components/General/Variables';
import React, { useEffect, useRef, useState } from 'react';
import { checkIfTimeslotHasOnlyOneUploadedVideo, getPlayerLinkByType } from '../../../utils/Utils';
import get from 'lodash/get';
import Store from '../../../../../services/api/store';
import debounce from 'lodash/debounce';
import { getImageSource } from '../../../../../services/api/db';
import MediaPlayer from '../../../../../components/MediaPlayer';
import Video from '../../moderatedSession/Video';
import ShareContent from '../../moderatedSession/common/ShareContent';
import { Cell, FontIcon } from 'react-md';
import UserInteraction from '../BeamerViewUserInteraction';

const ContainerRight = styled(Cell)`
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    height: 100%;
    flex: 1;
    margin: 0 !important;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        .secondary {
            display: none;
        }
    }
`;

export const SecondaryScreen = styled.div`
    background-color: inherit;
    flex: 1;
    position: relative;
`;

const UserInteractionContainer = styled(StyledCell)`
  box-sizing: border-box;
  flex: 3;
  width: 100% !important;
  ${props => props.windowWidth < 910 && 'display: none'}
  height: ${props =>
      props.interactionwindowheight
          ? props.interactionwindowheight + 'px'
          : props.interactioninitialheight < 750
          ? '40vh'
          : '50vh'};
  margin: 16px 0 !important;
  background: transparent;

  @media only screen and (max-width: ${palette.MAX_TABLET}) {
    bottom: 40px;
    background: red;
  }
  @media only screen and (max-width: ${palette.MAX_PHONE}) {
    background: blue;
  }
`;

const SideContent = props => {
    const { authUser, isVotingActive } = props;
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();

    const { eurekaChatUrl, mainMedia, timeslot, virtualEventSession } = stateCtx;
    const mobile = (window.innerWidth < palette.MIN_TABLET_INT).toString();
    const wrapperRef = useRef(null);
    const [showMainButton, setShowMainButton] = useState(false);
    const [interactionWindowHeight, setInteractionWindowHeight] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [eventSpeakerPlaceholder, setEventSpeakerPlaceholder] = useState(null);

    const isSecondaryScreenActive =
        wrapperRef.current && wrapperRef.current.firstChild.hasChildNodes();
    const hasOnlyOneUploadedVideo = checkIfTimeslotHasOnlyOneUploadedVideo(timeslot);
    const forceUnmuteSlidesForUploadedVideo = hasOnlyOneUploadedVideo && mainMedia === 'video';
    const forceNotMuted = !!get(virtualEventSession, 'recordingInfo.playBothVideosNormally');
    const muted =
        forceNotMuted || forceUnmuteSlidesForUploadedVideo ? false : mainMedia !== 'slides';
    const virtualSessionsSpeakerHolder =
        Store.argument && Store.argument.virtualSessionsSpeakerHolder;

    const handleResize = debounce(() => {
        setInteractionWindowHeight(window.innerHeight < 900 ? 225 : 425);
        setWindowWidth(window.innerWidth);
    }, 500);

    useEffect(() => {
        if (wrapperRef.current) {
            handleResize();
        }
    }, [wrapperRef.current]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        (async () => {
            if (virtualSessionsSpeakerHolder) {
                await getImageSource(virtualSessionsSpeakerHolder, (err, img) => {
                    setEventSpeakerPlaceholder(img);
                });
            }
        })();

        return async () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const shouldRenderMediaPlayer =
        timeslot && timeslot.params && timeslot.params.virtualSession === 'on-demand';
    const shouldRenderLiveSession = !shouldRenderMediaPlayer;
    const mainIsSlides = mainMedia === 'slides';

    return (
        <ContainerRight size={4} tabletSize={4} mobile={mobile}>
            <SecondaryScreen
                innerRef={wrapperRef}
                className="secondary"
                size={12}
                tabletSize={12}
                mobile={mobile}
                onMouseOver={() => setShowMainButton(true)}
                onMouseLeave={() => setShowMainButton(false)}
            >
                {shouldRenderMediaPlayer && (
                    <MediaPlayer
                        url={getPlayerLinkByType({
                            mainMedia,
                            timeslot,
                            primaryScreen: false,
                        })}
                        muted={muted}
                    />
                )}
                {shouldRenderLiveSession &&
                    (mainIsSlides ? <Video timeslot={timeslot} /> : <ShareContent {...props} />)}
                {showMainButton && isSecondaryScreenActive && !isVotingActive && (
                    <MakeMainScreenButton onClick={mutationCtx.userSwitchMain}>
                        <FontIcon>picture_in_picture</FontIcon>
                        <span>Make main screen</span>
                    </MakeMainScreenButton>
                )}
            </SecondaryScreen>
            <UserInteractionContainer
                mobile={mobile}
                interactionwindowheight={interactionWindowHeight}
                interactioninitialheight={!interactionWindowHeight ? window.innerHeight : 0}
                windowwidth={windowWidth}
            >
                <UserInteraction
                    timeslot={timeslot}
                    userData={authUser}
                    eurekaChatUrl={eurekaChatUrl}
                    sessionType={
                        timeslot && timeslot.params && timeslot.params.virtualSession
                            ? timeslot.params.virtualSession
                            : ''
                    }
                />
            </UserInteractionContainer>
        </ContainerRight>
    );
};

export default SideContent;

import { useEffect } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';

import { isCameraAllowed, isMicrophoneAllowed } from '../utils/useDevices';
import { TOAST_TYPES } from '../utils/toasts';
import { useVMMutation, useVMState } from '../containers/main';

let stopTrying = false;
let audioTrack;

const getAudioTrack = () =>
    new Promise((resolve, reject) => {
        if (stopTrying) {
            return resolve(null);
        }

        if (audioTrack) {
            return resolve(audioTrack);
        }

        stopTrying = true;
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(stream => {
                audioTrack = stream.getAudioTracks()[0];
                resolve(audioTrack);
                stopTrying = false;
            })
            .catch(err => {
                reject(err);
                stopTrying = false;
            });
    });

const isSystemMuted = async () => {
    const track = await getAudioTrack();

    if (!track) {
        return false;
    }

    return track.muted;
};

const useMicAndCamera = () => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const {
        virtualEventUser,
        sessionId,
        socket,
        microphoneList,
        mutedBySystem,
        screenSharing,
        localStream,
    } = stateCtx;
    const { isMicrophoneOn, isVideoOn } = virtualEventUser.data;

    const setAgoraDevicesChangedListeners = () => {
        AgoraRTC.onMicrophoneChanged = deviceInfo => {
            mutationCtx.startUsingDevices(
                undefined,
                deviceInfo.state === 'ACTIVE' ? deviceInfo.device.deviceId : undefined,
                undefined,
                deviceInfo.state === 'INACTIVE',
            );
        };

        AgoraRTC.onCameraChanged = deviceInfo => {
            mutationCtx.startUsingDevices(
                undefined,
                undefined,
                deviceInfo.state === 'ACTIVE' ? deviceInfo.device.deviceId : undefined,
                deviceInfo.state === 'INACTIVE',
            );
        };
    };

    const toggleCamera = async () => {
        const cameraAllowed = await isCameraAllowed();

        if (!cameraAllowed) {
            mutationCtx.setShowToast(
                microphoneList.length ? TOAST_TYPES.NO_CAMERA : TOAST_TYPES.CAMERA,
            );
            return;
        }

        if (!stateCtx.useDevices) {
            mutationCtx.startUsingDevices();
        }

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isVideoOn: !isVideoOn,
                    },
                ],
            },
        });
    };

    const changeMic = value => {
        if (isMicrophoneOn === value) {
            return;
        }

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isMicrophoneOn: value,
                    },
                ],
            },
        });
    };

    const toggleMic = async () => {
        if (mutedBySystem) {
            mutationCtx.openMicModal();

            return;
        }

        const microphoneAllowed = await isMicrophoneAllowed();

        if (!microphoneAllowed) {
            mutationCtx.setShowToast(
                microphoneList.length ? TOAST_TYPES.NO_MICROPHONE : TOAST_TYPES.MICROPHONE,
            );
            return;
        }

        if (!stateCtx.useDevices) {
            mutationCtx.startUsingDevices();
        }

        changeMic(!isMicrophoneOn);
    };

    const toggleScreenSharing = async () => {
        if (!stateCtx.useDevices) {
            mutationCtx.startUsingDevices();
        }

        if (screenSharing) {
            mutationCtx.setExtraState({
                screenSharing: false,
                shareType: undefined,
            });
            socket.emit('updateData', {
                objectId: sessionId,
                virtualEventSession: {
                    VirtualEventUsers: [
                        {
                            id: virtualEventUser.data.id,
                            isScreenSharing: false,
                        },
                    ],
                },
            });
        } else {
            mutationCtx.startScreenSharing();
            socket.emit('updateData', {
                objectId: sessionId,
                virtualEventSession: {
                    VirtualEventUsers: [
                        {
                            id: virtualEventUser.data.id,
                            isScreenSharing: true,
                        },
                    ],
                },
            });
        }
    };

    const stopAudioTrack = () => {
        if (audioTrack) {
            audioTrack.stop();
        }

        audioTrack = null;
    };

    useEffect(() => {
        setAgoraDevicesChangedListeners();

        return () => {
            stopAudioTrack();
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            //TODO FIX ME
            const isMuted = await isSystemMuted();

            if (isMuted !== mutedBySystem) {
                mutationCtx.setMutedBySystem(isMuted);

                if (isMuted) {
                    changeMic(false);
                }
            }
        }, 500);

        return () => clearInterval(interval);
    }, [localStream, mutedBySystem]);

    return {
        toggleCamera,
        toggleMic,
        toggleScreenSharing,
    };
};

export default useMicAndCamera;

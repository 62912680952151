import React from 'react';

import ThemeContext from './ThemeContext';

const withTheme = Component =>
    class WithTheme extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                theme: {
                    primary: '#673ab7',
                    secondary: '#7E57C2',
                    contrast: '#f6a738',
                    textSponsor: '#f6a738',
                    embed: false,
                    kiosk: false,
                },
                setTheme: () => {},
            };
        }

        setTheme = theme => {
            this.setState(theme);
        };

        render() {
            const value = {
                theme: {
                    primary: this.state.primary,
                    secondary: this.state.secondary,
                    contrast: this.state.contrast,
                    textSponsor: this.state.textSponsor,
                    embed: this.state.embed,
                    kiosk: this.state.kiosk,
                },
                setTheme: this.setTheme,
            };
            return (
                <ThemeContext.Provider value={value}>
                    <Component />
                </ThemeContext.Provider>
            );
        }
    };

export default withTheme;

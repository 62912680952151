import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { getString } from '../../../services/api/store';
import {
    Container,
    InteractivityCell,
    InteractivityGrid,
    SectionText,
    SectionTitle,
} from './style';
import { constructEvaluationUrl, checkIfEvaluated } from './service';
import { makeItem } from '../../../services/api/data';
import NavigationBar from '../../../components/DetailHeader/NavigationBar';
import AnalyticsService from '../../../features/analytics/services';
import Auth from '../../../services/api/auth';
import Button, { buttonTypes } from '../../common/Button';
import * as palette from '../../../components/General/Variables';
import {
    AchievementType,
    useAchievementActions,
} from '../../Achievements/hooks/useAchievementActions';

const EvaluationCard = props => {
    const { goToLoginPage } = props;
    const { objectId, objectClass } = props;
    const [url, setUrl] = useState('');
    const [evaluated, setEvaluated] = useState(false);
    const [showEvaluationScreen, setShowEvaluationScreen] = useState(false);
    const [objectTypeName, setObjectTypeName] = useState('session');
    const [loading, setLoading] = useState(false);
    const user = Auth.getUser();
    const { trackAchievement } = useAchievementActions();

    let EVALUATION_TITLE = getString('evaluationTitle') || 'Session evaluation';
    let EVALUATION_SUBTITLE =
        getString('evaluationCallToActionSubtitle') || 'Help us to improve the program';
    const EVALUATION_BUTTON_TEXT = getString('evaluationSubmitButton') || 'Start evaluation';
    const EVALUATION_SUBMITTED =
        getString('evaluationSubmittedSubtitle') ||
        'Your evaluation was submitted. Thanks for helping us to improve the program!';

    const replaceString = (str, newSubstr) => {
        if (str.includes('__timeslotName__')) {
            return str.replace('__timeslotName__', newSubstr.toLowerCase());
        }
        return str;
    };

    EVALUATION_TITLE = replaceString(EVALUATION_TITLE, objectTypeName);
    EVALUATION_SUBTITLE = replaceString(EVALUATION_SUBTITLE, objectTypeName);

    const handleEvaluationScreen = val => {
        setShowEvaluationScreen(val);
        props.setShowEvaluationScreenExternal(val);
    };

    useEffect(() => {
        handleEvaluationScreen(false);
        makeItem(objectId, objectClass, (err, objectItem) => {
            const typeString = objectItem.typeName || 'session';
            setObjectTypeName(typeString.toLowerCase());

            const evaluation =
                objectItem &&
                objectItem.interactivity &&
                objectItem.interactivity.find(obj => obj.kind === 'evaluations');
            if (evaluation) {
                const { query } = evaluation;

                const urlToSet = constructEvaluationUrl(
                    query.typeId,
                    objectItem.id,
                    objectItem.name,
                    props.localAppState.eventId,
                    props.localAppState.configuratorUrl,
                    user?.id,
                );

                setUrl(urlToSet);
                handleSetEvaluated({
                    sessionId: objectId,
                    eurekaUserId: user?.id,
                });
            }
        });
    }, [objectId]);

    const handleSetEvaluated = async (data, timeout = 0) => {
        if (!data.userId) {
            setEvaluated(false);
            return;
        }
        // Set-timeout hack so Cookie Manager gets the cookies properly when WV is closed
        setTimeout(async () => {
            setLoading(true);
            const sessionEvaluated = await checkIfEvaluated(
                data,
                props.localAppState.configuratorUrl,
            );
            setEvaluated(sessionEvaluated);
            setLoading(false);
        }, timeout);
    };

    const EvaluationButton = () => (
        <Button
            type={buttonTypes.GREY_LONG}
            background={props.theme.primary}
            icon={palette.ICON_GAVEL}
            text={EVALUATION_BUTTON_TEXT}
            enabled={!evaluated}
            onClick={() => {
                if (!Auth.isUserAuthenticated()) {
                    return goToLoginPage();
                }

                handleEvaluationScreen(true);
            }}
        />
    );

    const onExit = () => {
        handleSetEvaluated(
            {
                sessionId: objectId,
                eurekaUserId: user.id,
            },
            100,
        );
        handleEvaluationScreen(false);
    };

    const receiveMessage = event => {
        var data = event.data;

        if (data.payload === objectId && data.status === 'success') {
            const iframe = document.getElementById('iframe');
            Cookies.set(iframe.src);
        }
    };

    if (window.addEventListener) {
        window.addEventListener('message', receiveMessage, false);
    }

    if (showEvaluationScreen) {
        AnalyticsService.addSample('evaluation', null, objectId);
        trackAchievement(AchievementType.EVALUATE, objectId);
        props.setEvaluationScreen(() => (
            <>
                <NavigationBar
                    typeName={EVALUATION_TITLE}
                    goBack={() => onExit()}
                    background={palette.COLOR_WHITE}
                />
                <iframe
                    title={EVALUATION_TITLE}
                    id="iframe"
                    src={url}
                    frameBorder="0"
                    width={'100%'}
                    height={'100%'}
                />
            </>
        ));
    }

    return (
        <InteractivityGrid>
            <InteractivityCell size={1} key="evaluation">
                <Container>
                    <SectionTitle secondary={props.theme.secondary}>
                        {EVALUATION_TITLE}
                    </SectionTitle>
                    <SectionText>
                        {!evaluated ? EVALUATION_SUBTITLE : EVALUATION_SUBMITTED}
                    </SectionText>
                    {!evaluated && !loading && <EvaluationButton />}
                    {loading && <div>Loading...</div>}
                </Container>
            </InteractivityCell>
        </InteractivityGrid>
    );
};

export default EvaluationCard;

import axios from 'axios';
import groupBy from 'lodash/groupBy';
import { getLocalAppState } from '../../../services/api';
import { isMongoId } from './utils';
import Auth from '../../../services/api/auth';
import { getFullProfile } from '../../../services/api/eureka';
import AnalyticsService from '../services';

/**
 * Creates a new installation of an app.
 *
 * @param {Object} installation Installation of this app in the Conference Compass backend server.
 *
 * @return {Promise} The ongoing request.
 */
const storeInstallation = async (configuratorUrl, id, installation) => {
    const CONFIGURATOR_URL = configuratorUrl;
    const url = `${CONFIGURATOR_URL}/api/installation`;
    const requestConfig = {
        headers: {
            'x-event-id': id,
        },
    };

    const response = await axios.post(url, installation, requestConfig);
    return response.data;
};

const AUDIENCE_ANALITYCS_SAMPLE_KEY = 'AUDIENCE_ANALITYCS_SAMPLE';

const getEventName = () => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    return pathname.split('/').slice(2, 3)[0];
};

const storeAnalyticSample = (eventName, installationId) => {
    const storedSamples = localStorage.getItem(AUDIENCE_ANALITYCS_SAMPLE_KEY);
    const sampleToStore = { eventName, installationId };
    let storedSample = sampleToStore;
    let sampleAlreadyStored = false;
    if (!storedSamples) {
        localStorage.setItem(AUDIENCE_ANALITYCS_SAMPLE_KEY, JSON.stringify([sampleToStore]));
    } else {
        const parsedSamples = JSON.parse(storedSamples);
        const alreadyStoredSample = parsedSamples.find(
            ps => ps.eventName === sampleToStore.eventName,
        );
        if (!alreadyStoredSample) {
            parsedSamples.push(sampleToStore);
            localStorage.setItem(AUDIENCE_ANALITYCS_SAMPLE_KEY, JSON.stringify(parsedSamples));
        } else {
            storedSample = alreadyStoredSample;
        }
        sampleAlreadyStored = true;
    }

    return { storedSample, sampleAlreadyStored };
};

const createInstallation = (configuratorUrl, id, installation) => {
    return new Promise((resolve, reject) => {
        getLocalAppState(async (err, appState) => {
            if (appState && appState.installationId) {
                if (err) {
                    reject(err);
                } else {
                    resolve({
                        status: 'update',
                        id: appState.installationId,
                    });
                }
            } else {
                const newInstallation = await storeInstallation(configuratorUrl, id, installation);
                const authUser = Auth.getUser();
                if (authUser) {
                    const userData = await getFullProfile();
                    const country =
                        userData.Country && userData.Country.name ? userData.Country.name : '';

                    const details = {
                        name: `${userData.firstName} ${userData.lastName}`,
                        country,
                        email: userData.email,
                    };
                    AnalyticsService.addSample(
                        'user',
                        JSON.stringify(details),
                        authUser.id,
                        newInstallation._id,
                    );
                    resolve({
                        status: 'add',
                        id: newInstallation._id,
                    });
                } else {
                    const eventName = getEventName();
                    const { storedSample, sampleAlreadyStored } = storeAnalyticSample(
                        eventName,
                        newInstallation._id,
                    );

                    if (sampleAlreadyStored) {
                        AnalyticsService.addSample('user', null, null, storedSample.installationId);
                        resolve({
                            status: 'add',
                            id: storedSample.installationId,
                        });
                    } else {
                        resolve({
                            status: 'add',
                            id: newInstallation._id,
                        });
                    }
                }
            }
        });
    });
};

/**
 * Send samples to backend
 * Return an array of installation IDs for which the samples have been added to DB
 */
const sendSamples = async (
    samples,
    installationId = null,
    eventId = null,
    configuratorUrl = null,
) => {
    return new Promise((resolve, reject) => {
        getLocalAppState(async (err, appState) => {
            if (err) {
                reject(err);
            } else {
                const CONFIGURATOR_URL = configuratorUrl || appState?.configuratorUrl;
                if (!CONFIGURATOR_URL) {
                    return reject('no configurator url');
                }
                const url = `${CONFIGURATOR_URL}/api/general/analytics/`;
                const requestConfig = {
                    headers: {
                        'x-event-id': eventId || appState.id,
                    },
                };

                const samplesGroupedByInstallationId = groupBy(samples, 'installation');
                const installationIdsArr = Object.keys(samplesGroupedByInstallationId);

                try {
                    const responses = await Promise.allSettled(
                        installationIdsArr.map(key => {
                            const installation = isMongoId(key)
                                ? key
                                : installationId || appState.installationId;

                            const data = {
                                installation,
                                analyticSamples: samplesGroupedByInstallationId[key],
                            };

                            return axios.post(url, data, requestConfig);
                        }),
                    );

                    const results = [];
                    responses.forEach((response, index) => {
                        if (response.status === 'fulfilled' && response.value.status === 200) {
                            results.push(installationIdsArr[index]);
                        }
                    });

                    resolve(results);
                } catch (e) {
                    reject(e);
                }
            }
        });
    });
};

export { createInstallation, sendSamples };

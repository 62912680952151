import { asyncEurekaRequest } from '../../../services/api/eureka';

const getUnreadNotifications = async eventId => {
    let url = `/api/entities/notifications/unread`;
    if (eventId) {
        url = `${url}/${eventId}`;
    }
    return asyncEurekaRequest('GET', url, null, true);
};

const getAllNotifications = async (eventId, data) => {
    let url = '/api/entities/notifications/readAll';
    if (eventId) {
        url = `${url}/${eventId}`;
    }
    return asyncEurekaRequest('POST', url, data, true);
};

const createOrUpdateNotification = async data =>
    await asyncEurekaRequest('POST', '/api/entities/notifications', data, true);

const removeNotificationsByIds = async data =>
    await asyncEurekaRequest('PUT', `/api/entities/notifications/remove/ids`, data, true);

const removeNotifications = async data =>
    await asyncEurekaRequest('PUT', `/api/entities/notifications/remove`, data, true);

const api = {
    getUnreadNotifications,
    getAllNotifications,
    createOrUpdateNotification,
    removeNotificationsByIds,
    removeNotifications,
};

export default api;

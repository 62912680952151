import { useState, useEffect } from 'react';

const useInfiniteScroll = callback => {
    const [isFetching, setFetching] = useState(false);

    const handleScrolling = e => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

        if (!bottom || isFetching) {
            return;
        }

        setFetching(true);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrolling, { capture: true });
        return () => window.removeEventListener('scroll', handleScrolling);
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        callback();
    }, [isFetching]);

    return [isFetching, setFetching];
};

export default useInfiniteScroll;

import styled from 'styled-components';
import { FontIcon } from 'react-md';

export const SnackWrapper = styled.div`
    .md-snackbar {
        bottom: unset;
        max-width: unset;
        top: 0;
        padding: 24px;
        height: 72px;
        border-radius: 8px;
    }

    .md-snackbar--toast {
        padding: 0;
    }

    .md-snackbar-enter,
    .md-snackbar-leave.md-snackbar-leave-active {
        transform: translate3d(-50%, -100%, 0);
    }

    .md-snackbar-enter.md-snackbar-enter-active,
    .md-snackbar-leave {
        transform: translate3d(-50%, 0, 0);
    }

    .md-btn--snackbar {
        background: unset !important;
        cursor: unset !important;
        padding: 0;
        min-width: unset !important;
    }

    .md-ink-container {
        display: none !important;
    }
`;

export const SnackButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SnackButton = styled.button`
    background: rgba(153, 153, 153, 0.12);
    cursor: pointer;

    &:first-child {
        margin-right: 10px;
    }
`;

export const Text = styled.div`
    display: flex;

    a {
        color: #43ba8f !important;
    }
`;

export const WarningIcon = styled(FontIcon)`
    color: #43ba8f !important;
    margin-right: 12px !important;
`;

export const MainText = styled.div`
    color: rgba(255, 255, 255, 0.87);
    margin-right: 8px;
`;

export const CloseIcon = styled(FontIcon)`
    color: #ffffff !important;
    cursor: pointer;
    font-size: 24px !important;
    margin-left: 16px;
`;

export const TOAST_TYPES = {
    AUDIO: 'audio',
    MICROPHONE: 'microphone',
    NO_MICROPHONE: 'no-microphone',
    CAMERA: 'camera',
    NO_CAMERA: 'no-camera',
    SCREEN: 'screen',
    BROWSER: 'browser',
};

export const TOAST_MESSAGES = {
    AUDIO: 'Your streams have no audio, click here, or anywhere on the page to enable it.',
    MICROPHONE: 'In order to use your microphone, please enable this in your browser settings.',
    NO_MICROPHONE: "You don't have any microphone on your device.",
    CAMERA: 'In order to use your camera, please enable this in your browser settings.',
    NO_CAMERA: "You don't have any camera on your device.",
    SCREEN:
        'In order to share your screen, please enable this in the system settings on your computer.',
};

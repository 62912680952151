import chunk from 'lodash/chunk';
import { createRequestTypes } from '../../../utils/types';
import { getLocalAppStateAsync } from '../../../services/api/db';
import {
    getByUserEvent,
    getEurekaEventByReference,
    markAsSeen,
    getUserAttending,
} from '../services';
import { batchGet } from '../../../services/api/graphQlRepository';

const actionTypes = {
    LOAD_EVENTS: createRequestTypes('LOAD_EVENTS'),
    LOAD_SETTINGS: createRequestTypes('LOAD_SETTINGS'),
    LOAD_MODERATED_SESSIONS: createRequestTypes('LOAD_MODERATED_SESSIONS'),
    MARK_SESSION_AS_SEEN: createRequestTypes('MARK_SESSION_AS_SEEN'),
};

const loadEvents = () => async dispatch => {
    dispatch({ type: actionTypes.LOAD_EVENTS.REQUEST });

    try {
        const { events } = await getLocalAppStateAsync();
        const attending = await getUserAttending();

        const attendingEvents = events.filter(item => {
            return attending.find(ev => {
                return ev.reference === item._id;
            });
        });

        dispatch({
            type: actionTypes.LOAD_EVENTS.SUCCESS,
            payload: attendingEvents,
        });
    } catch (error) {
        dispatch({ type: actionTypes.LOAD_EVENTS.FAILURE, payload: { error } });
    }
};

const markSessionsAsSeen = sessionId => async dispatch => {
    dispatch({ type: actionTypes.MARK_SESSION_AS_SEEN.REQUEST });

    try {
        await markAsSeen(sessionId);
        dispatch({
            type: actionTypes.MARK_SESSION_AS_SEEN.SUCCESS,
            payload: sessionId,
        });
    } catch (error) {
        dispatch({ type: actionTypes.MARK_SESSION_AS_SEEN.FAILURE, payload: { error } });
    }
};

const loadAppStateSettings = () => async dispatch => {
    dispatch({ type: actionTypes.LOAD_SETTINGS.REQUEST });

    try {
        const appState = await getLocalAppStateAsync();

        dispatch({
            type: actionTypes.LOAD_SETTINGS.SUCCESS,
            payload: appState,
        });
    } catch (error) {
        dispatch({ type: actionTypes.LOAD_SETTINGS.FAILURE, payload: { error } });
    }
};

const loadModeratedSessions = eventId => async dispatch => {
    dispatch({ type: actionTypes.LOAD_MODERATED_SESSIONS.REQUEST });

    try {
        //we need the eureka event numerical id
        const event = await getEurekaEventByReference(eventId);
        const data = await getByUserEvent(event.id);
        let mySessions = [];
        if (data && data.length) {
            const ids = data.map(object => {
                return object?.data?.reference;
            });

            const groups = chunk(ids, 50);
            const results = await Promise.all(
                groups.map(group => {
                    const input = {
                        data: [
                            {
                                target: 'timeslots',
                                ids: group,
                            },
                            {
                                target: 'programelements',
                                ids: group,
                            },
                        ],
                    };
                    //We get the sessions details and extend the information we have from eureka
                    return batchGet(input);
                }),
            );

            let objects = [];
            results.forEach(object => {
                const { timeslots, programelements } = object;
                const allSlots = [...timeslots, ...programelements];
                objects = objects.concat(allSlots);
            });

            mySessions = data.map(object => {
                const fullObject = objects.find(ts => ts.id === object?.data?.reference);
                if (fullObject) {
                    return {
                        object: {
                            ...object.data,
                            title: fullObject.name,
                            start: fullObject.start,
                            end: fullObject.end,
                            subNameList: fullObject.subNameList,
                            subNameDetail: fullObject.subNameDetail,
                            isNew: object.isNew,
                        },
                        actions: object.actions,
                    };
                } else {
                    return {
                        object: {
                            ...object.data,
                        },
                        actions: object.actions,
                    };
                }
            });
        }

        dispatch({
            type: actionTypes.LOAD_MODERATED_SESSIONS.SUCCESS,
            payload: mySessions,
        });
    } catch (error) {
        dispatch({ type: actionTypes.LOAD_MODERATED_SESSIONS.FAILURE, payload: { error } });
    }
};

export { actionTypes, loadEvents, loadAppStateSettings, loadModeratedSessions, markSessionsAsSeen };

import React, { useEffect, useState } from 'react';
import { Snackbar } from 'react-md';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { useVMMutation, useVMState } from './containers/main';
import VirtualFeatureContent from './VirtualFeatureContent';
import Footer from './components/footer/Footer';
import RoundTableFooter from './components/footer/RoundTableFooter';
import AuthUserContext from '../../../../components/Session/AuthUserContext';
import Header from '../../../VirtualSession/components/common/Header';
import { getTimeslot } from '../../../VirtualSession/services/session';

import {
    SnackWrapper,
    SnackButtons,
    SnackButton,
    Text,
    WarningIcon,
    MainText,
    CloseIcon,
    TOAST_TYPES,
    TOAST_MESSAGES,
} from './utils/toasts';
import ScreenLoader from './components/common/ScreenLoader';
import { getConfigurations } from '../../../../services/api';
import { default as Store } from '../../../../services/api/store';
import entities from './constants/entities';
import MicMutedDialog from './components/common/MicMutedDialog';

const { detect } = require('detect-browser');

const SUPPORTED_BROWSERS = {
    CHROME: 58,
    FIREFOX: 56,
    SAFARI: 11,
    OPERA: 45,
    EDGE: 80,
};

const Wrapper = styled.div`
    margin-top: 56px;
    width: 100%;
    overflow: hidden;
    background: #f1f1f3;
    box-sizing: border-box;
    ${props => props.iswindows && !props.isroundtable && 'height: calc(100vh - 168px);'}
    ${props => !props.iswindows && !props.isroundtable && 'height: calc(100vh - 142px);'}
    ${props => props.isroundtable && 'height: calc(100vh - 128px);'}
`;

const toastsArray = [];

const MainWrapper = () => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const isWindowsDevice = navigator.platform.indexOf('Win') > -1;

    const history = useHistory();

    const match = useRouteMatch();
    const { timeslotId, eventId } = match.params;

    const [isBrowserSupported, setIsBrowserSupported] = useState(true);
    const {
        joiningLoaderVisible,
        showToast,
        timeslot,
        virtualEventSession,
        isMicMutedModalOpen,
    } = stateCtx;
    const isRoundTable =
        virtualEventSession.data.roomType ===
        entities.virtualEventSessionEntity.roomType.roundTable;

    useEffect(() => {
        const isSupported = detectIfBrowserIsSuppoerted();
        setIsBrowserSupported(isSupported);
        if (!isSupported) {
            mutationCtx.setShowToast(TOAST_TYPES.BROWSER);
        }
    }, []);

    useEffect(() => {
        getTimeslot(timeslotId, async (err, timeslot) => {
            if (err || !timeslot) {
                return;
            }

            mutationCtx.setTimeslot(timeslot);
        });
    }, [timeslotId]);

    useEffect(() => {
        const setEventStoreForSociety = async () => {
            if (eventId) {
                const data = await getConfigurations(eventId);
                const { settings, strings } = data;

                if (settings) {
                    Store.argument = settings;
                }

                if (strings) {
                    Store.string = strings;
                }
            }
        };
        setEventStoreForSociety();
    }, [eventId]);

    const getToasts = type => {
        let text;
        let onClick;
        let children;

        toastsArray.splice(0, 1);

        switch (type) {
            case TOAST_TYPES.AUDIO:
                text = TOAST_MESSAGES.AUDIO;
                children = (
                    <SnackButtons>
                        <SnackButton className="md-btn md-btn--flat md-btn--text md-btn--color-secondary-active md-text--theme-secondary md-ink--secondary md-inline-block">
                            Allow
                        </SnackButton>
                    </SnackButtons>
                );
                break;
            case TOAST_TYPES.MICROPHONE:
                text = (
                    <Text>
                        <WarningIcon>error_outline</WarningIcon>
                        <MainText>{TOAST_MESSAGES.MICROPHONE}</MainText>
                        <a href="https://support.google.com/chrome/answer/2693767" target="_blank">
                            Learn how
                        </a>
                    </Text>
                );
                children = <CloseIcon>close</CloseIcon>;
                onClick = () => mutationCtx.setShowToast(false);
                break;
            case TOAST_TYPES.CAMERA:
                text = (
                    <Text>
                        <WarningIcon>error_outline</WarningIcon>
                        <MainText>{TOAST_MESSAGES.CAMERA}</MainText>
                        <a href="https://support.google.com/chrome/answer/2693767" target="_blank">
                            Learn how
                        </a>
                    </Text>
                );
                children = <CloseIcon>close</CloseIcon>;
                onClick = () => mutationCtx.setShowToast(false);
                break;
            case TOAST_TYPES.NO_MICROPHONE:
                text = (
                    <Text>
                        <WarningIcon>error_outline</WarningIcon>
                        <MainText>{TOAST_MESSAGES.NO_MICROPHONE}</MainText>
                    </Text>
                );
                children = <CloseIcon>close</CloseIcon>;
                onClick = () => mutationCtx.setShowToast(false);
                break;
            case TOAST_TYPES.NO_CAMERA:
                text = (
                    <Text>
                        <WarningIcon>error_outline</WarningIcon>
                        <MainText>{TOAST_MESSAGES.NO_CAMERA}</MainText>
                    </Text>
                );
                children = <CloseIcon>close</CloseIcon>;
                onClick = () => mutationCtx.setShowToast(false);
                break;
            case TOAST_TYPES.SCREEN:
                text = (
                    <Text>
                        <WarningIcon>error_outline</WarningIcon>
                        <MainText>{TOAST_MESSAGES.SCREEN}</MainText>
                        <a
                            href="https://support.apple.com/en-gb/guide/mac-help/mchld6aa7d23/mac"
                            target="_blank"
                        >
                            Learn how
                        </a>
                    </Text>
                );
                children = <CloseIcon>close</CloseIcon>;
                onClick = () => mutationCtx.setShowToast(false);
                break;
            case TOAST_TYPES.BROWSER:
                text = (
                    <Text>
                        <WarningIcon>error_outline</WarningIcon>
                        <MainText>
                            Make sure to use{' '}
                            <a href="https://www.google.com/chrome/" target="_blank">
                                Google Chrome
                            </a>{' '}
                            or one of the other supported browsers:
                        </MainText>
                        <a href="/#">More info</a>
                    </Text>
                );
                children = <CloseIcon>close</CloseIcon>;
                onClick = () => mutationCtx.setShowToast(false);
                break;
            default:
                return toastsArray;
        }

        toastsArray.push({
            text,
            action: {
                children,
                onClick,
            },
        });

        return toastsArray;
    };

    const detectIfBrowserIsSuppoerted = () => {
        const browser = detect();

        if (!browser) {
            return false;
        }
        console.log('browser', browser);

        const { name, version: versionDetailed, os } = browser;
        const version = versionDetailed && parseInt(versionDetailed.split('.')[0]);

        if (os === 'Mac OS' || os.indexOf('Windows') > -1) {
            if (name === 'chrome' && version < SUPPORTED_BROWSERS.CHROME) {
                return false;
            }
            if (name === 'firefox' && version < SUPPORTED_BROWSERS.FIREFOX) {
                return false;
            }
            if (name === 'opera' && version < SUPPORTED_BROWSERS.OPERA) {
                return false;
            }
            if (name === 'safari' && version < SUPPORTED_BROWSERS.SAFARI) {
                return false;
            }
            if (name === 'edge' && version < SUPPORTED_BROWSERS.EDGE) {
                return false;
            }
        }

        return true;
    };

    const goBack = () => {
        history.goBack();
    };

    return (
        <Wrapper iswindows={isWindowsDevice} isroundtable={isRoundTable ? 1 : 0}>
            {joiningLoaderVisible && (
                <ScreenLoader title="Trying to join" text="Please wait a few seconds..." />
            )}
            <div className="eureka-react">
                {showToast && (
                    <SnackWrapper>
                        <Snackbar
                            id="interactive-snackbar"
                            toasts={getToasts(showToast)}
                            autohide={false}
                            autoFocusAction
                            onDismiss={() => mutationCtx.setShowToast(false)}
                        />
                    </SnackWrapper>
                )}
            </div>
            {isBrowserSupported && (
                <>
                    <AuthUserContext.Consumer>
                        {authUser => (
                            <>
                                <Header
                                    goBack={goBack}
                                    timeslot={timeslot}
                                    authUser={authUser}
                                    noZIndex
                                />
                                <VirtualFeatureContent />
                                {isRoundTable ? <RoundTableFooter /> : <Footer />}
                                {isMicMutedModalOpen && (
                                    <MicMutedDialog onClose={mutationCtx.hideMicModal} />
                                )}
                            </>
                        )}
                    </AuthUserContext.Consumer>
                </>
            )}
        </Wrapper>
    );
};

export default MainWrapper;

export default {
    chatbubbleWrapper: {
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    chatbubble: {
        backgroundColor: '#0084FF',
        borderRadius: 20,
        marginTop: 4,
        marginBottom: 1,
        marginLeft: 'auto',
        maxWidth: 300,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 14,
        paddingRight: 14,
        width: '-webkit-fit-content',
    },
    systemBubble: {
        marginTop: 2,
        marginRight: 'auto',
        marginBottom: 4,
        marginLeft: 'auto',
        maxWidth: 300,
        paddingLeft: 14,
        paddingRight: 14,
        width: '-webkit-fit-content',
    },
    chatbubbleOrientationNormal: {
        float: 'right',
    },
    recipientChatbubble: {
        backgroundColor: '#EFEFEF',
    },
    recipientChatbubbleOrientationNormal: {
        float: 'left',
    },
    p: {
        color: '#000000',
        fontSize: 16,
        fontWeight: '300',
        margin: 0,
    },
};

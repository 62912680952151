import React, { memo, useEffect, useState } from 'react';
import ClassifierIconLabelComponent from './ClassifierIconLabelComponent';
import { batchGet, getItemAsync } from '../../services/api/graphQlRepository';
import sortBy from 'lodash/sortBy';

const mapOrder = (arr2, arr1, key) => {
    arr2.sort((a, b) => {
        const aKey = a[key];
        const bKey = b[key];
        return arr1.indexOf(aKey) - arr1.indexOf(bKey);
    });
};

const ClassifierIconsGroup = ({ classifications, type, inlist = false, programmeItem = false }) => {
    const [orderedClassifications, setOrderedClassifications] = useState([]);

    useEffect(() => {
        const orderClassifiers = async () => {
            if (!classifications || !classifications.length) {
                return;
            }
            const objectType = type ? await getItemAsync('types', type) : null;
            const input = {
                data: [
                    {
                        target: 'classifiers',
                        ids: classifications.map(cl => cl._id),
                    },
                ],
            };

            const queryResult = await batchGet(input);

            const completeClassifiers = queryResult['classifiers'];
            const orderedByName = sortBy(completeClassifiers, ['name', 'orderingName']);

            const { sections } = objectType || {
                sections: [],
            };

            const sectionsOrder = [];
            sections.forEach(section => {
                const { kind, query } = section;
                if (kind === 'related' && query?.typeId) {
                    sectionsOrder.push(query?.typeId);
                }
            });

            mapOrder(orderedByName, sectionsOrder, 'type');

            setOrderedClassifications(orderedByName);
        };
        orderClassifiers();
    }, [classifications]);

    return (
        <>
            {orderedClassifications.map(item => {
                return (
                    <ClassifierIconLabelComponent
                        defaultTooltip={item.name}
                        params={item.params}
                        inlist={inlist}
                        programmeItem={programmeItem}
                        key={`classifier-icon-${item.id}`}
                    />
                );
            })}
        </>
    );
};

export default memo(ClassifierIconsGroup);

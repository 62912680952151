import React from 'react';
import { ButtonsContainer, Card, CloseCardIcon, Subtitle, Title } from '../style/signIn';
import * as string from '../constants/strings';
import { getString } from '../../../services/api/store';
import Button, { buttonTypes } from '../../common/Button';
import { Flex } from '../../common/Flex';
import * as palette from '../../../components/General/Variables';

const ResetPasswordSuccess = ({
    theme,
    goToSignIn,
    eurekaOnly,
    attendingOnly,
    onClose,
    isModal,
}) => {
    const TEXT_RESET_PASSWORD_SUCCESS_INTRO =
        getString('profileForgotPasswordSuccessSubtitle1') ||
        string.TEXT_RESET_PASSWORD_SUCCESS_INTRO;
    const TEXT_RESET_PASSWORD_SUCCESS =
        getString('profileForgotPasswordSuccessSubtitle2') || string.TEXT_RESET_PASSWORD_SUCCESS;
    const TEXT_SIGN_IN_BUTTON = getString('signInButton') || string.SIGN_IN_BUTTON;
    return (
        <Card isModal={isModal}>
            <Flex alignItems={'center'} justifyContent={'center'}>
                <Title largeCard={true}>{TEXT_RESET_PASSWORD_SUCCESS_INTRO}</Title>
                {!eurekaOnly && !attendingOnly && (
                    <CloseCardIcon onClick={onClose}>{palette.ICON_CLOSE}</CloseCardIcon>
                )}
            </Flex>
            <Subtitle>{TEXT_RESET_PASSWORD_SUCCESS}</Subtitle>
            <ButtonsContainer primarycolor={theme.primary}>
                <Button
                    type={buttonTypes.GREEN_LONG}
                    background={theme.primary}
                    text={TEXT_SIGN_IN_BUTTON}
                    onClick={goToSignIn}
                    data-qa="button-sign-in"
                />
            </ButtonsContainer>
        </Card>
    );
};

export default ResetPasswordSuccess;

const onDeletePresentationUrl = async (stateCtx, mutationCtx, virtualEventUserModeEntity) => {
    const { sessionId, virtualEventUser, socket } = stateCtx;

    socket.emit('updateData', {
        objectId: sessionId,
        virtualEventSession: {
            VirtualEventUsers: [
                {
                    id: virtualEventUser.data.id,
                    uploadedPresentationUrl: null,
                    uploadedPresentationFilename: null,
                    activePresentationIndex: 0,
                    mode: virtualEventUserModeEntity,
                    scale: 1,
                    positionX: 0,
                    positionY: 0,
                },
            ],
        },
    });
    mutationCtx.setUrl(null);
};

export default onDeletePresentationUrl;

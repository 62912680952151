import { asyncEurekaRequest } from '../../../services/api/eureka';

export const sendEmailRequest = async data => {
    return await asyncEurekaRequest(
        'POST',
        '/api/services/email/exhibitor/businessCard',
        data,
        true,
    );
};

export const getUsersFriendsList = async () => {
    return await asyncEurekaRequest('GET', '/api/entities/friendships/friendsList', null, true);
};

import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import {
    getAllProgramFavoritesForEvent,
    getDateWithLocale,
    getDayMonthDateFormat,
    getFormatTime,
    getItemAsync,
    getMyCheckins,
    makeItemAsync,
} from '../../services/api/data';
import { getLocalAppStateAsync, getObjectClassWithIdAsync } from '../../services/api/db';
import NavigationLink from '../../components/Navigation/NavigationLink';
import { Tab, TabsContainer } from '../../components/DateTabs';
import ThemeContext, { useTheme } from '../../components/Theme/ThemeContext';
import Loader from '../../components/General/Loader';
import Placeholder from '../SearchPage/components/placeholder';
import ObjectListItem from '../../components/ObjectListItem';
import styled from 'styled-components';
import { List } from 'react-md';
import eventBus from '../../utils/eventBus';
import { TimezoneContext } from '../Timezone/context';
import { APPOINTMENT_PARTICIPANT_STATUS } from '../Appointments/constants';
import { each } from 'async';
import { getItem } from '../../services/api/graphQlRepository';
import Auth from '../../services/api/auth';
import AppointmentService from '../Appointments/services/AppointmentService';
import { getString } from '../../services/api/store';
import ClassifierIcon from '../../components/Icons/ClassifierIcon';

const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;
`;

const ListStyled = styled(List)`
    width: 100%;
    max-width: ${props => window.innerWidth}px;
`;

const ListItem = ({ item }) => (
    <span>
        <ObjectListItem item={item} type={item.typeObj.target.toLowerCase()} />
    </span>
);

const TAB_DATE_PATTERN = 'ddd DD';

const MyCheckinsPage = props => {
    const [items, setItems] = useState([]);
    const [days, setDays] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);

    const { getUtcToSelectedTimezone } = useContext(TimezoneContext);

    useEffect(() => {
        fetchData();
        props.setTitle();
    }, []);

    useEffect(() => {
        eventBus.on('refreshMyCheckins', fetchData);

        return () => eventBus.removeListener('refreshMyCheckins', fetchData);
    }, [activeTab]);

    const fetchData = async () => {
        setLoading(true);

        const checkins = await getMyCheckins();
        let checkinsArray = [];
        const daysArray = [];

        const { eventId } = await getLocalAppStateAsync();

        await Promise.all(
            checkins.map(async checkin => {
                const item = await getItemAsync('timeslots', checkin.id);
                if (!(item && item.start && item.event === eventId)) {
                    return null;
                }

                const type = await getItemAsync('types', item.type);

                item.typeObj = type;

                const day = getUtcToSelectedTimezone(item.start).startOf('day').toISOString();
                item.dayUtc = day;
                item.sortTime = getUtcToSelectedTimezone(item.start).toISOString();

                checkinsArray.push(item);

                if (!daysArray.includes(day)) {
                    daysArray.push(day);
                }

                return item;
            }),
        );

        checkinsArray = sortBy(checkinsArray, ['sortTime', 'orderingName', 'name']);

        const sortedDaysArray = daysArray.sort(
            (a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD'),
        );

        setItems(checkinsArray);
        setDays(sortedDaysArray);

        setLoading(false);
    };

    const onTabClick = index => {
        setActiveTab(index);
    };

    let dateSettings = getString('datetime');
    let locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';
    const renderListItems = () =>
        items.map((item, i) => {
            if (!item || !item.typeObj) {
                return;
            }

            if (days[activeTab] === item.dayUtc) {
                let params = item.params;
                if (typeof params === 'string') {
                    params = JSON.parse(item.params);
                }
                let classifierIcon;
                if (params && params.icons && params.icons.length) {
                    params.icons.forEach(item => {
                        classifierIcon = <ClassifierIcon key={item} imageId={item} />;
                    });
                }

                return (
                    <LinkStyled
                        key={'link_' + item.id}
                        params={{
                            type: 'detail',
                            objectClass: item.typeObj.target.toLowerCase(),
                            objectId: item.id,
                        }}
                    >
                        <ListItem item={item} />
                    </LinkStyled>
                );
            }
        });

    const content = () => {
        if (!items.length && !loading) {
            return <Placeholder type="emptyList" introText="Nothing checked in yet..." />;
        } else {
            return <ListStyled className="">{renderListItems()}</ListStyled>;
        }
    };

    const renderTabs = theme => {
        return days.map((elem, index) => {
            const label = getUtcToSelectedTimezone(elem, TAB_DATE_PATTERN, false, locale, true);
            return (
                <Tab
                    key={`tab_${index}`}
                    active={index === activeTab}
                    color={theme.contrast}
                    onClick={() => onTabClick(index)}
                >
                    <div>{label.split(' ')[0]}</div>
                    <div>{label.split(' ')[1]}</div>
                </Tab>
            );
        });
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    <TabsContainer>{renderTabs(theme)}</TabsContainer>
                    {loading && <Loader />}
                    {!loading && content()}
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default MyCheckinsPage;

import React, { useEffect, useState, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import html2canvas from 'html2canvas';
import { FontIcon } from 'react-md';

import { useVMMutation, useVMState } from '../../containers/main';
import useResize from '../../../../../VirtualSession/hooks/useResize';
import StreamPlayer from './StreamPlayer';
import entities from '../../constants/entities';
import { LoaderTransparent } from './LoaderTransparent';
import { FIFTY_MILL, TEN_MILL, THIRTY_MILL, TWENTY_MILL } from '../../constants/values';
import getThumbnail from '../../utils/getThumbnail';
import { LightTooltip, RedStyledChip } from '../moderator/common/styles';
import EnlargeStreamModal from './EnlargeStreamModal';
import SlidePlaceholder from '../../../../../../assets/images/slide-placeholder.png';
import { getImageSource } from '../../../../../../services/api/db';
import Store from '../../../../../../services/api/store';
import getThumbnailMP4 from '../../utils/getThumbnailMP4';

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    margin: 0 16px 0 0;
    width: 100%;
    position: relative;
    aspect-ratio: 1.75 / 1;
`;

const Img = styled.img`
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;
`;

const SlidesWrapper = styled.div`
    flex: 7;
    width: 100%;
    height: 204px;
    position: relative;
    border-radius: 8px;

    ${props =>
        props.placeHolderWrapper &&
        css`
            overflow: hidden;

            canvas {
                position: absolute;
                top: 0;
                left: 0;
            }
        `}
`;

const ThumbnailImg = styled.img`
    width: 100%;
    border-radius: 8px;
`;

const ScreenSharingWrapper = styled.div`
    flex: 7;
    width: 100%;

    .stream-player.video-on {
        height: 100%;
        width: 100%;

        video {
            background: #fff;
            width: 100% !important;
            height: 100% !important;
            object-fit: contain !important;
            ${props => props.isScreenSharing && 'object-fit: fill !important'};
        }
    }
`;

const ThumbnailContainer = styled.div`
    height: 100%;
    width: 100%;
`;

const PositionAbsoluteRedChip = styled(RedStyledChip)`
    position: absolute;
    z-index: 10;
    top: 9px;
    left: 9px;
    width: 38px;
`;

const EnlargeIconContainer = styled.div`
    position: absolute;
    z-index: 10;
    bottom: 8px;
    right: 8px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }
`;

const StyledIcon = styled(FontIcon)`
    color: #fff !important;
`;

const GreyBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: #f1f1f3;
    border-radius: 8px;
`;

export default () => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const [wrapperRef, justResized] = useResize();
    const {
        virtualEventSession,
        virtualEventUser,
        sessionId,
        streams,
        screenSharing,
        screenShareStream,
        shareType,
        eventSliderPlaceholder,
    } = stateCtx;
    const [showModals, setShowModals] = useState({
        slides: false,
        placeHolder: false,
        videoThumbnail: false,
    });
    // drawing variables
    const shouldClearInterval = useRef(false);
    const redrawSpeed = 1000;

    const virtualEventUsers = get(virtualEventSession, 'data.VirtualEventUsers', []);
    const activeVideoId = get(virtualEventSession, 'data.activeVideoId', null);
    const videoUrls = get(virtualEventSession, 'data.videoUrls') || [];
    const activeVideo = useMemo(() => videoUrls.find(videoObj => videoObj.id === activeVideoId), [
        videoUrls,
    ]);
    const activeUser = virtualEventUsers.find(user => user.hasVideoActive || user.isActive);
    const playAudioOnlyFromCamera = get(activeUser, 'recordingInfo.playAudioOnlyFromCamera', false);
    const sessionStatus = get(virtualEventSession, 'data.status');
    const isSessionLive = entities.virtualEventSessionEntity.status.broadcasting === sessionStatus;
    const virtualSessionsSlideHolder = Store.argument && Store.argument.virtualSessionsSlideHolder;
    const questionModeratorId = get(virtualEventSession, 'data.questionModeratorId');
    const placeHolderUrl =
        virtualEventSession.data.presentationPlaceholderUrl ||
        eventSliderPlaceholder ||
        SlidePlaceholder;

    let streamId = get(activeUser, 'UserId', 0);

    if (activeUser && get(activeUser, 'hasVideoActive')) {
        streamId = get(activeUser, 'UserId', 0);
        streamId += THIRTY_MILL;
    } else if (activeVideoId) {
        if (activeVideo && activeVideo.isRTMP) {
            streamId = parseInt(activeVideo.uid, 10);
        } else {
            streamId = virtualEventSession.data.id;
            streamId += FIFTY_MILL;
        }
    } else {
        streamId += TEN_MILL;
    }

    if (questionModeratorId) {
        streamId = questionModeratorId + TEN_MILL;
    }

    const streamToUse = useMemo(
        () =>
            (activeUser || activeVideoId) &&
            !get(activeUser, 'isPrerecording') &&
            streams.find(st => st.streamId === streamId),
        [streams, activeUser, activeVideoId],
    );

    let muted = playAudioOnlyFromCamera;
    if (activeVideoId) {
        // If there is no uploaded video for the speaker, unmute the slides video
        if (activeVideo && !activeVideo.videoUrl) {
            muted = false;
        }
    }

    // make sure the screensharing is unmuted
    if (streamId >= TEN_MILL && streamId <= TWENTY_MILL) {
        muted = streamId === get(screenShareStream, 'streamId');
    }

    const votesRef = useRef(null);
    const intervalRef = useRef(() => {});
    const isRendering = useRef(false);
    const canvasRef = useRef(null);
    const imageRef = useRef(null);

    const reDrawFn = (canvas, newCanvas) => {
        const { height, width } = newCanvas;

        /**
         * We reset the interval and the redraw variables
         */
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        const drawFn = () => {
            const recordingContext = canvas.getContext('2d');

            recordingContext.drawImage(newCanvas, 0, 0, width, height);

            /**
             * This approach is required because we have some async functionality,
             * so we make sure that the interval is cleared every time that is needed.
             */
            if (shouldClearInterval.current) {
                clearInterval(intervalRef.current);
            }
        };

        drawFn();
        intervalRef.current = setInterval(drawFn, redrawSpeed);
    };

    useEffect(() => {
        (async () => {
            try {
                if (virtualEventSession.data.showVoteResults) {
                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }

                    if (canvasRef.current && votesRef.current) {
                        canvasRef.current.style.height = '100%';
                        canvasRef.current.style.width = '100%';

                        isRendering.current = true;

                        setTimeout(async () => {
                            const newCanvas = await html2canvas(votesRef.current);

                            canvasRef.current.width = newCanvas.width;
                            canvasRef.current.height = newCanvas.height;
                            canvasRef.current.style.maxWidth = '100%';
                            isRendering.current = false;

                            reDrawFn(canvasRef.current, newCanvas);
                        }, redrawSpeed);
                    }

                    return null;
                }

                return null;
            } catch (err) {
                console.log(err);
            }
        })();
    }, [
        streamToUse,
        canvasRef.current,
        virtualEventSession.data,
        virtualEventUser.data,
        justResized,
        imageRef.current,
    ]);

    const isCurrentUserCanvasSharing =
        get(screenShareStream, 'streamId') === get(streamToUse, 'streamId') &&
        shareType !== 'screen';

    const userWithActiveVideo = virtualEventUsers.find(user => user.hasVideoActive);
    const shouldPlayVideo = userWithActiveVideo || activeVideoId;
    const preRecordedPresentationUrl = get(userWithActiveVideo, 'preRecordedPresentationUrl');

    const [videoThumbnail, setVideoThumbnail] = useState('');

    useEffect(() => {
        const getSessionSlidesPlaceholder = async () => {
            if (virtualSessionsSlideHolder) {
                await getImageSource(virtualSessionsSlideHolder, (err, img) => {
                    mutationCtx.setEventSliderPlaceHolder(img);
                });
            }
        };

        getSessionSlidesPlaceholder();
    }, [virtualSessionsSlideHolder]);

    useEffect(() => {
        if (shouldPlayVideo) {
            (async () => {
                let imageData;

                if (userWithActiveVideo) {
                    const { list } = get(userWithActiveVideo, 'recordingInfo', {});
                    const videoInfo = (list || []).find(info => info.type === 'share');
                    const videoUrl = `${preRecordedPresentationUrl}/share/${videoInfo.sid}_${sessionId}.m3u8`;
                    imageData = await getThumbnail(videoUrl);
                } else {
                    const videoUrl = videoUrls.find(video => video.id === activeVideoId).shareUrl;
                    imageData = await getThumbnailMP4(videoUrl);
                }

                setVideoThumbnail(imageData);
            })();
        }
    }, [shouldPlayVideo, userWithActiveVideo]);

    const showThumbnail = shouldPlayVideo && !streamToUse && videoThumbnail;
    const isScreenSharing = screenShareStream && shareType === 'screen';

    const onSetShowModals = (modal, value) => {
        setShowModals({
            ...showModals,
            [modal]: value,
        });
    };

    return (
        <>
            <Wrapper>
                <LoaderTransparent
                    active={
                        virtualEventSession.uploadRequestStatus === entities.requestStatus.loading
                    }
                >
                    {streamToUse && !isCurrentUserCanvasSharing && (
                        <ScreenSharingWrapper
                            innerRef={wrapperRef}
                            isScreenSharing={isScreenSharing}
                        >
                            {isSessionLive && (
                                <PositionAbsoluteRedChip>LIVE</PositionAbsoluteRedChip>
                            )}
                            <LightTooltip title="Enlarge">
                                <EnlargeIconContainer
                                    onClick={() => {
                                        onSetShowModals('slides', true);
                                    }}
                                >
                                    <StyledIcon>fullscreen</StyledIcon>
                                </EnlargeIconContainer>
                            </LightTooltip>
                            <EnlargeStreamModal
                                open={showModals.slides}
                                handleClose={() => {
                                    onSetShowModals('slides', false);
                                }}
                            >
                                {showModals.slides && (
                                    <StreamPlayer
                                        key={streamToUse.getId()}
                                        className={'main-stream-profile'}
                                        showProfile={stateCtx.profile}
                                        isVideoOn={true}
                                        local={true}
                                        muted={muted}
                                        stream={streamToUse}
                                        uid={streamToUse.getId()}
                                        domId={`stream-player-${streamToUse.getId()}`}
                                        videoObjectFit="contain"
                                        noPositionInitial
                                    />
                                )}
                            </EnlargeStreamModal>

                            {!showModals.slides ? (
                                <StreamPlayer
                                    key={streamToUse.getId()}
                                    className={'main-stream-profile'}
                                    showProfile={stateCtx.profile}
                                    isVideoOn={true}
                                    local={true}
                                    muted={muted}
                                    stream={streamToUse}
                                    uid={streamToUse.getId()}
                                    domId={`stream-player-${streamToUse.getId()}`}
                                    noPositionInitial
                                />
                            ) : (
                                <GreyBackground />
                            )}
                        </ScreenSharingWrapper>
                    )}

                    {!streamToUse && virtualEventSession.data.showVoteResults && (
                        <SlidesWrapper innerRef={wrapperRef}>
                            {isSessionLive && (
                                <PositionAbsoluteRedChip>LIVE</PositionAbsoluteRedChip>
                            )}
                            <canvas
                                ref={canvasRef}
                                height="720px"
                                width="1280px"
                                style={{
                                    maxHeight: '100%',
                                    maxWidth: '100%',
                                }}
                            />
                        </SlidesWrapper>
                    )}

                    {showThumbnail && (
                        <ThumbnailContainer>
                            {isSessionLive && (
                                <PositionAbsoluteRedChip>LIVE</PositionAbsoluteRedChip>
                            )}
                            <LightTooltip title="Enlarge">
                                <EnlargeIconContainer
                                    onClick={() => {
                                        onSetShowModals('videoThumbnail', true);
                                    }}
                                >
                                    <StyledIcon>fullscreen</StyledIcon>
                                </EnlargeIconContainer>
                            </LightTooltip>
                            {!showModals.videoThumbnail ? (
                                <ThumbnailImg alt="preview" src={videoThumbnail} />
                            ) : (
                                <GreyBackground />
                            )}
                            <EnlargeStreamModal
                                open={showModals.videoThumbnail}
                                handleClose={() => {
                                    onSetShowModals('videoThumbnail', false);
                                }}
                            >
                                {showModals.videoThumbnail && (
                                    <ThumbnailImg alt="preview" src={videoThumbnail} />
                                )}
                            </EnlargeStreamModal>
                        </ThumbnailContainer>
                    )}

                    {!virtualEventSession.data.showVoteResults &&
                        (!streamToUse || isCurrentUserCanvasSharing) &&
                        !showThumbnail && (
                            <SlidesWrapper placeHolderWrapper innerRef={wrapperRef}>
                                {isSessionLive && (
                                    <PositionAbsoluteRedChip>LIVE</PositionAbsoluteRedChip>
                                )}
                                {!showModals.placeHolder ? (
                                    <Img ref={imageRef} alt="" src={placeHolderUrl} />
                                ) : (
                                    <GreyBackground />
                                )}
                                <LightTooltip title="Enlarge">
                                    <EnlargeIconContainer
                                        onClick={() => {
                                            onSetShowModals('placeHolder', true);
                                        }}
                                    >
                                        <StyledIcon>fullscreen</StyledIcon>
                                    </EnlargeIconContainer>
                                </LightTooltip>

                                {!screenSharing && (
                                    <>
                                        <EnlargeStreamModal
                                            open={showModals.placeHolder}
                                            handleClose={() => {
                                                onSetShowModals('placeHolder', false);
                                            }}
                                        >
                                            {showModals.placeHolder && (
                                                <Img ref={imageRef} alt="" src={placeHolderUrl} />
                                            )}
                                        </EnlargeStreamModal>
                                        {showModals.placeHolder && (
                                            <GreyBackground
                                                style={{ position: 'absolute', top: 0 }}
                                            />
                                        )}
                                    </>
                                )}
                            </SlidesWrapper>
                        )}
                </LoaderTransparent>
            </Wrapper>
        </>
    );
};

export const getNickAsInt = nick => {
    let parsed;
    if (typeof nick === 'string' && nick.indexOf('_') !== -1) {
        const splitted = nick.split('_');
        if (splitted[0]) {
            parsed = parseInt(splitted[0], 10);
        }
    } else {
        parsed = parseInt(nick, 10);
    }
    return parsed;
};

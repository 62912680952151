import React, { useEffect, useState } from 'react';
import { Avatar, ListItem } from 'react-md';
import styled from 'styled-components';
import NavigationLink from '../../../components/Navigation/NavigationLink';
import debounce from 'lodash/debounce';

const Container = styled.div`
    width: 100%;
    list-style-type: none;
    height: 100%;
    overflow: auto;
`;

const CustomAvatar = styled(Avatar)`
    background-color: ${props => props.avatarcolor || '#bfbfbf'} !important;
    border: none !important;
`;

const Item = styled(ListItem)`
    height: 56px;

    & .md-list-tile--avatar {
        height: 56px;
    }

    & .md-avatar {
        border: none;
    }
`;

const Wrapper = styled.div`
    height: ${props =>
        props.isfirefox && props.windowheight
            ? `calc(100vh - ${props.windowheight < 700 ? '300px' : '400px'} - ${props.marginTop}px)`
            : `calc(100% - ${props.marginTop}px)`};
    position: relative;
    overflow: hidden;
`;

const ContentWrapper = styled.div`
    height: 100%;
`;

export const getInitials = (fName, lName) => fName[0].toUpperCase() + lName[0].toUpperCase();

const Participants = props => {
    const { sortedAttendees, marginTop } = props;
    const [attendees, setAttendees] = useState(sortedAttendees);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        setAttendees(sortedAttendees);
    }, [sortedAttendees]);

    const isFirefox = typeof InstallTrigger !== 'undefined';

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = debounce(() => {
        setWindowHeight(window.innerHeight);
    }, 500);

    const listItems =
        attendees &&
        attendees.map((att, index) => (
            <NavigationLink
                key={`attendee-item-${index}`}
                path=""
                params={{
                    type: 'profile',
                    userId: att.userId,
                }}
            >
                <Item
                    key={`attendee-item-${index}`}
                    leftAvatar={
                        att.imageUrl ? (
                            <Avatar src={att.imageUrl} />
                        ) : (
                            <CustomAvatar>{getInitials(att.firstName, att.lastName)}</CustomAvatar>
                        )
                    }
                    primaryText={`${att.firstName} ${att.lastName}`}
                    secondaryText={att.jobTitle}
                />
            </NavigationLink>
        ));

    const content = () => (
        <ContentWrapper>
            <Container>{listItems}</Container>
        </ContentWrapper>
    );

    return (
        <Wrapper
            isfirefox={isFirefox ? 1 : 0}
            windowheight={windowHeight}
            marginTop={marginTop || 0}
        >
            {content()}
        </Wrapper>
    );
};

export default Participants;

import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    border-top: 1px solid #efefef;
    background-color: #fff;
`;

const StyledText = styled.span`
    font-family: Roboto, sans-serif;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.38);
`;

const StyledIcon = styled(FontIcon)`
    width: 20px !important;
    height: 20px !important;
    font-size: 20px !important;
    color: rgba(0, 0, 0, 0.38);
    margin-right: 10px;
`;

const VISIBILITY_TOASTER = 'Disabled for speakers and attendees';

const VisibilityToaster = () => {
    return (
        <Wrapper>
            <StyledIcon>info_outlined</StyledIcon>
            <StyledText>{VISIBILITY_TOASTER}</StyledText>
        </Wrapper>
    );
};

export default VisibilityToaster;

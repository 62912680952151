import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';
import { getString } from '../../services/api/store';

const Container = styled.div`
    background-color: #f5f5f5;
    border-radius: 20px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
`;

const StyledFontIcon = styled(FontIcon)`
    color: #1e1e1f !important;
    font-size: 24px;
`;

const CloseIcon = styled(StyledFontIcon)`
    color: rgba(0, 0, 0, 0.6) !important;
    opacity: ${props => (props.hide ? 0 : 1)};
    transition: all 0.2s !important;
    cursor: ${props => (props.hide ? 'default' : 'pointer')};
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    margin-right: 10px;
    border: none;
    outline: none;
    background-color: transparent;

    &::placeholder {
        color: rgba(0, 0, 0, 0.6) !important;
    }
`;

const SearchBar = ({ value, onChange, customStyle }) => {
    const SEARCH_TEXT = getString('search') || 'Search';

    const onSearchChange = event => {
        const { value } = event.target;

        onChange(value || '');
    };

    const onCloseClick = () => {
        onChange('');
    };

    return (
        <Container style={customStyle}>
            <StyledFontIcon>search</StyledFontIcon>
            <Input value={value} onChange={onSearchChange} placeholder={SEARCH_TEXT} />
            <CloseIcon hide={!value} onClick={onCloseClick}>
                close
            </CloseIcon>
        </Container>
    );
};

export default SearchBar;

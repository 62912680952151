import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import { clearHTML } from '../../Feed';
import { useTheme } from '../../../components/Theme/ThemeContext';
import { WidgetFrame1Tile, WidgetTile } from '../common/styles';

const VideoWrapper = styled.div`
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
        border: 0;
    }
`;

const VideoWidget = ({ tileGroup }) => {
    const { theme } = useTheme();
    const isFirefox = typeof InstallTrigger !== 'undefined';

    const getVideoIframe = url => {
        return `<iframe src="${url}" allowfullscreen></iframe>`;
    };

    return (
        <WidgetFrame1Tile isFirefox={isFirefox}>
            <WidgetTile>
                <VideoWrapper color={theme.primary}>
                    {ReactHtmlParser(clearHTML(getVideoIframe(tileGroup.items[0].embedVideoUrl)))}
                </VideoWrapper>
            </WidgetTile>
        </WidgetFrame1Tile>
    );
};

export default VideoWidget;

import React, { useContext } from 'react';

//Theme can be used from anywhere in the app like this:
// <ThemeContext.Consumer>
//     {({theme}) => ()}
// </ThemeContext.Consumer>

export const themes = {
    defaultTheme: {
        theme: {
            primary: '#673ab7',
            secondary: '#7E57C2',
            contrast: '#f6a738',
            embed: false,
            kiosk: false,
        },
    },
};

const ThemeContext = React.createContext(themes.defaultTheme);

// Or it can be used in functional components
// const { theme, setTheme } = useTheme()
export const useTheme = () => useContext(ThemeContext);

export default ThemeContext;

import React from 'react';
import { Slider as MdSlider } from 'react-md';
import styled from 'styled-components';

const StyledSlider = styled(MdSlider)`
    background-color: #2d2d2d !important;
    padding: 8px 12px;
    border-radius: 4px;

    .md-slider-thumb {
        display: none !important;
    }

    .md-slider-track {
        height: 6px;
        margin: 0;
        background: linear-gradient(
            to right,
            #a6a6a6 0px,
            #a6a6a6 ${props => props.loadedSeconds}px,
            #707070 ${props => props.loadedSeconds}px,
            #f6f6f6 100%
        );
        &-fill {
            height: 6px;
            background-color: ${props => (props.contrast ? props.contrast : '#1fa294')};
        }
    }
`;

const MediaPlayerSlider = ({
    min,
    max,
    value,
    onChange,
    step,
    theme,
    onMouseEnter,
    loadedSeconds,
    ...props
}) => {
    return (
        <StyledSlider
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChange}
            onMouseEnter={onMouseEnter}
            contrast={theme.contrast}
            loadedSeconds={loadedSeconds}
            {...props}
        />
    );
};

export default MediaPlayerSlider;

import { eurekaRequest } from '../../../services/api/eureka';
import AnalyticsService from '../../../features/analytics/services';

const loadLatestQuestions = async ({ eventId, itemId }) =>
    new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/questionSets/externalObject/${eventId}/${itemId}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });

const askQuestion = async ({ questionSetId, question, sessionId }) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            '/api/entities/questions',
            { QuestionSetId: questionSetId, question },
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    AnalyticsService.addSample('question', response.question, sessionId);
                    resolve(response);
                }
            },
        );
    });
};

const likeQuestion = async ({ data }) =>
    new Promise((resolve, reject) => {
        eurekaRequest('post', '/api/entities/questionLikes', data, true, (err, response) => {
            if (err) {
                reject(err);
            } else {
                resolve(response);
            }
        });
    });

const toggleQuestionLike = async ({ questionLikeId, data }) =>
    new Promise((resolve, reject) => {
        eurekaRequest(
            'put',
            `/api/entities/questionLikes/${questionLikeId}`,
            data,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });

export { loadLatestQuestions, askQuestion, likeQuestion, toggleQuestionLike };

export default nr => {
    if (nr <= 1) {
        return 1;
    }

    if (nr <= 4) {
        return 2;
    }

    if (nr <= 9) {
        return 3;
    }

    return 4;
};

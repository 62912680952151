import { useEffect, useMemo } from 'react';
import { useGlobalState, useGlobalMutation } from '../../../utils/container';
import { createStreamFromUser } from '../../../utils/Stream';

export default function useStream(client) {
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();

    const { localStream, currentStream, streams } = stateCtx;

    const otherStreams = useMemo(
        () => streams.filter(stream => stream.getId() !== currentStream.getId()),
        [streams, currentStream],
    );

    useEffect(() => {
        const volumeInterval = setInterval(() => {
            let streamsArray = [...streams];

            if (currentStream) {
                streamsArray = [...streamsArray, currentStream];
            }

            if (streamsArray.length > 0) {
                mutationCtx.setVolumeIndicators(streamsArray);
            }
        }, 500);

        return () => clearInterval(volumeInterval);
    }, [streams]);

    useEffect(() => {
        const addRemoteStream = async (remoteUser, mediaType) => {
            await client.subscribe(remoteUser, mediaType);
            const streamObj = createStreamFromUser(remoteUser);

            mutationCtx.addStream({ stream: streamObj });
            client.setStreamFallbackOption(streamObj, 0);
        };
        const canceledScreenSharing = () => {
            mutationCtx.stopScreenSharing();
        };

        if (client && client._subscribed === false) {
            client.on('stopScreenSharing', canceledScreenSharing);
            client.on('connection-state-change', mutationCtx.connectionStateChanged);
            client.on('localStream-added', mutationCtx.addLocal);
            client.on('localStream-removed', () => mutationCtx.setLocalStream(null));
            client.on('stream-published', mutationCtx.addStream);
            client.on('user-published', addRemoteStream);
            client.on('user-unpublished', user => {
                if (!user.hasAudio && !user.hasVideo) {
                    mutationCtx.removeStream(user);
                }
            });
            client.on('user-left', user => {
                mutationCtx.removeStreamById(user);
            });
            client._subscribed = true;
        }
    }, [client, mutationCtx, localStream, streams]);

    useEffect(() => {
        if (client) {
            mutationCtx.setLocalClient(client);
        }
    }, [client]);

    useEffect(() => {
        if (
            client &&
            client._subscribed === true &&
            client._joined === true &&
            currentStream != null
        ) {
            client.setRemoteVideoStreamType(currentStream, 0);
            otherStreams.forEach(otherStream => client.setRemoteVideoStreamType(otherStream, 0));
        }
    }, [client, currentStream, otherStreams]);

    return [localStream, currentStream, otherStreams];
}

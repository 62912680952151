import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import PhonelinkIcon from '@material-ui/icons/Phonelink';
import PhonelinkOffIcon from '@material-ui/icons/PhonelinkOff';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Stop from '@material-ui/icons/Stop';

import { LightTooltip, RedStyledChip, VideoSwitchOutlinedWrapper } from '../../common/styles';
import Dropdown from '../../../common/Dropdown';
import Loader from '../../../common/Loader';
import withStyles from '@material-ui/core/styles/withStyles';

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    height: ${props => (props.isActive ? '88px' : '64px')};
    padding: 12px 16px;
    position: relative;
    width: auto;
    border-radius: 8px;
    margin: 0 1px;
    background-color: ${props => props.backgroundColor};
    box-shadow: ${props => (props.isHovered ? '0 2px 6px 0 rgba(0, 0, 0, 0.2)' : '')};
    margin-bottom: 8px;
    cursor: pointer;

    ${props =>
        props.isdisabled &&
        css`
            background: rgba(245, 245, 245, 0.5);
            cursor: unset;
        `}
`;

const NameAndVideoInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    width: calc(100% - 80px);
`;

const Flex = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

const ActionsWrapper = styled(Flex)`
    z-index: 3;
    justify-content: flex-end;
    ${props =>
        props.encoding &&
        css`
            justify-content: flex-end;
        `}
`;

const Name = styled.div`
    color: rgba(0, 0, 0, 0.87);
    font-family: Cabin, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${props =>
        props.isdisabled &&
        css`
            color: rgba(0, 0, 0, 0.38);
        `}
`;

const VideoInfo = styled.div`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, 0.54);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`;

const ActionWrapper = styled.div`
    position: relative;
    border-radius: 50%;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.54) !important;

    &:hover {
        color: rgba(0, 0, 0, 0.87) !important;
        background-color: rgba(0, 0, 0, 0.04) !important;
    }
    * > ul {
        list-style-type: none;
        top: 0;
        padding: 16px;

        li {
            &:nth-of-type(2) {
                margin: 6px 0;
            }
            div {
                display: flex;
                align-items: center;
                ${props =>
                    props.isdisabled &&
                    css`
                        opacity: 0.5;
                        cursor: not-allowed;
                    `}
            }
        }
    }
`;

const PlayIcon = styled(PlayArrow)`
    cursor: pointer;
    background: transparent;
    border-radius: 50%;
    padding: 4px;
    font-size: 32px !important;
`;

const StopIcon = styled(Stop)`
    cursor: pointer;
    background: transparent;
    border-radius: 50%;
    padding: 4px;
    font-size: 32px !important;
`;

const PlayContainer = styled.div`
    width: ${props => (props.loading ? '50px' : '40px')};
    height: ${props => (props.loading ? '50px' : '40px')};
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.54);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        color: rgba(0, 0, 0, 0.87);
        background-color: rgba(0, 0, 0, 0.04) !important;
    }
`;

const EmptySpace = styled.div`
    width: 40px;
    height: 40px;
`;

const EncodingWrapper = styled.div`
    align-items: center;
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    line-height: 1.54;
    text-align: right;
    color: rgba(0, 0, 0, 0.38);
    position: relative;
`;

const videoTypeMap = {
    preRecordedPresentationUrl: 'Pre-recording',
    uploadedVideo: 'Uploaded video',
    rtmp: 'RTMP Stream',
};

const VideoLoader = styled(Loader)`
    margin: 0;
    box-shadow: unset !important;
`;

const NarrowedLightTooltip = withStyles(() => ({
    tooltip: {
        maxWidth: '80px',
    },
}))(LightTooltip);

const RedChip = styled(RedStyledChip)`
    width: ${props => (props.isPlaying ? '135px' : '88px')};
    margin: 0;
`;

const CompressingLoader = styled(Loader)`
    box-shadow: unset !important;
    width: 25px;
    height: 25px;
    margin: 0 10px 0 0;
    background: transparent;
`;

const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.1s linear;
    background-color: transparent;
    z-index: 1;
    :hover {
        background-color: rgba(0, 0, 0, 0.04);
        color: rgba(0, 0, 0, 0.87);
    }
    ${props =>
        props.disabled &&
        css`
            cursor: not-allowed;
        `}
`;

const VideoWrapper = ({
    virtualEventUser,
    video,
    makeVideoActive,
    isDisabled,
    playing,
    playMedia,
    stopMedia,
    loading,
    onMoveUp,
    onMoveDown,
    onDelete,
    onEdit,
    isActiveVideo,
    ...props
}) => {
    const { hasVideoActive, User: user } = virtualEventUser || {};
    const isActive = hasVideoActive || isActiveVideo;
    const isVideo = get(video, 'name');
    const isRTMP = get(video, 'isRTMP');

    const videoSwitchWrapperColor = 'rgba(0, 0, 0, 0.54)';
    const videoSwitchWrapperColorHover = 'rgba(0, 0, 0, 0.04)';
    const switchIconColorHover = 'rgba(0, 0, 0, 0.87)';

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [displayActions, setDisplayActions] = useState(false);
    const [encodingStatus, setEncodingStatus] = useState('complete');

    useEffect(() => {
        if (!isVideo && !user && !isRTMP) {
            return null;
        }
        if (get(video, 'name')) {
            setEncodingStatus(get(video, 'encoding'));
        } else {
            setEncodingStatus('complete');
        }
    }, [video]);

    if (!isVideo && !user && !isRTMP) {
        return null;
    }

    const getVideoType = () => {
        if (isRTMP) {
            return 'rtmp';
        }

        return isVideo ? 'uploadedVideo' : 'preRecordedPresentationUrl';
    };

    const name = get(video, 'name') || `${user.firstName} ${user.lastName}`;
    const videoType = getVideoType(video);

    const isEncodingPending = encodingStatus === 'pending';
    const isEncodingRunning = encodingStatus === 'running';
    const isEncoding = isEncodingPending || isEncodingRunning;
    const shouldDisable = isDisabled;

    if (!video && !get(virtualEventUser, 'preRecordedPresentationUrl')) {
        return null;
    }

    const duration = isVideo ? video.duration : get(virtualEventUser, 'recordingInfo.duration', 0);

    const onSwitchClick = () => {
        if (!shouldDisable) {
            makeVideoActive();
        }

        setTooltipOpen(false);
    };

    const deleteVideo = e => {
        e.stopPropagation();
        onDelete();
    };

    const onEditVideo = e => {
        e.stopPropagation();
        onEdit();
    };

    const moveUp = e => {
        e.stopPropagation();
        onMoveUp();
    };

    const moveDown = e => {
        e.stopPropagation();
        onMoveDown();
    };

    return (
        <Wrapper
            data-qa={props['data-qa']}
            isdisabled={shouldDisable ? 1 : 0}
            onMouseEnter={() => setDisplayActions(true)}
            onMouseLeave={() => setDisplayActions(false)}
            backgroundColor={'#f1f1f3'}
            isHovered={displayActions}
            isActive={isActive}
        >
            <Flex>
                <NameAndVideoInfo>
                    {isActive && !playing && <RedChip>ACTIVE VIDEO</RedChip>}
                    {isActive && playing && <RedChip isPlaying>ACTIVE VIDEO, PLAYING</RedChip>}
                    <Name isdisabled={shouldDisable ? 1 : 0}>{name}</Name>

                    <VideoInfo>
                        {!isEncoding && (
                            <span>{`${videoTypeMap[videoType]} ${
                                videoType === 'rtmp' ? '' : `(${duration})`
                            }`}</span>
                        )}
                        {isEncodingRunning && <span>Processing...</span>}
                        {isEncodingPending && <span>Waiting...</span>}
                    </VideoInfo>
                </NameAndVideoInfo>

                <ActionsWrapper encoding={isEncoding}>
                    {isActive && displayActions && !isRTMP && (
                        <NarrowedLightTooltip
                            title={playing ? 'Stop this video' : 'Play this video'}
                        >
                            <PlayContainer loading={loading ? 1 : 0}>
                                {loading && <VideoLoader />}

                                {!playing && !loading && (
                                    <PlayIcon onClick={e => !shouldDisable && playMedia(e)} />
                                )}

                                {playing && !loading && (
                                    <StopIcon onClick={e => !shouldDisable && stopMedia(e)} />
                                )}
                            </PlayContainer>
                        </NarrowedLightTooltip>
                    )}
                    {!isActive && !displayActions && (
                        <React.Fragment>
                            {isEncoding && (
                                <EncodingWrapper>
                                    <CompressingLoader
                                        extraCircleStyle={{
                                            color: 'gray',
                                            marginLeft: '5px',
                                            height: '15px',
                                            width: '15px',
                                        }}
                                    />
                                </EncodingWrapper>
                            )}
                            {!isEncoding && <EmptySpace />}
                        </React.Fragment>
                    )}
                    {displayActions && isEncoding && (
                        <IconWrapper onClick={e => !shouldDisable && deleteVideo(e)}>
                            <DeleteIcon />
                        </IconWrapper>
                    )}
                    {displayActions && !isEncoding && (
                        <NarrowedLightTooltip title={'Options'}>
                            <ActionWrapper isdisabled={shouldDisable ? 1 : 0}>
                                <Dropdown
                                    customPosition={'top'}
                                    items={[
                                        ...(isRTMP
                                            ? [
                                                  {
                                                      text: 'Edit',
                                                      icon: 'edit',
                                                      onClick: e =>
                                                          !shouldDisable && onEditVideo(e),
                                                  },
                                              ]
                                            : []),
                                        {
                                            text: 'Move up',
                                            icon: 'arrow_upward',
                                            onClick: e => !shouldDisable && moveUp(e),
                                        },
                                        {
                                            text: 'Move down',
                                            icon: 'arrow_downward',
                                            onClick: e => !shouldDisable && moveDown(e),
                                        },
                                        {
                                            text: 'Delete',
                                            icon: 'delete',
                                            onClick: e => !shouldDisable && deleteVideo(e),
                                        },
                                    ]}
                                />
                            </ActionWrapper>
                        </NarrowedLightTooltip>
                    )}
                    {displayActions && !isEncoding && (
                        <NarrowedLightTooltip
                            open={tooltipOpen}
                            title={
                                isActive
                                    ? 'Unselect as the active video'
                                    : 'Select as the active video'
                            }
                        >
                            <VideoSwitchOutlinedWrapper
                                backgroundColor={videoSwitchWrapperColor}
                                backgroundColorHover={videoSwitchWrapperColorHover}
                                colorHover={switchIconColorHover}
                                onClick={onSwitchClick}
                                onMouseEnter={() => {
                                    setTooltipOpen(true);
                                }}
                                onMouseLeave={() => {
                                    setTooltipOpen(false);
                                }}
                                active={isActive}
                            >
                                {!isActive ? <PhonelinkIcon /> : <PhonelinkOffIcon />}
                            </VideoSwitchOutlinedWrapper>
                        </NarrowedLightTooltip>
                    )}
                </ActionsWrapper>
            </Flex>
        </Wrapper>
    );
};

export default VideoWrapper;

import styled from 'styled-components';
import * as palette from '../../../components/General/Variables';

export const StyledForm = styled.form`
    min-height: ${props => (props.minHeight ? props.horizontalMargin : '450px')};
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: ${palette.COLOR_TEXT} !important;

    .md-divider--text-field::after {
        background: ${props => props.maincolor};
    }
    .md-text--theme-primary {
        color: ${props => props.maincolor};
    }
    .md-text-field-message,
    .md-floating-label,
    input::placeholder,
    textarea::placeholder {
        //font-size: 12px !important;
    }
`;

import { getString } from '../../../services/api/store';

export const aboutLinks = {
    termsAndConditions: 'https://www.iubenda.com/terms-and-conditions/28287048',
    privacyPolicy: 'https://www.iubenda.com/privacy-policy/28287048',
    cookiePolicy: 'https://www.iubenda.com/privacy-policy/28287048/cookie-policy',
    releaseNotes: 'https://knowledge.conferencecompass.com/release-notes',
};

export const INPUT_EMAIL_MESSAGE =
    'Enter your email address below. If you have an account, you can enter your password after, otherwise you can create one.';
export const TITLE_GOOGLE_LOGIN = 'Continue with Google';
export const DIALOG_MESSAGE =
    'Sign in to add items to your favourites, make notes and synchronize these across devices.';
export const TITLE_EMAIL_LOGIN = 'Continue with email';
export const ATTENDEES_LOGIN_DESCRIPTION =
    'Only attendees can access this event. Sign in to verify if you’re on the list.';
export const ERROR_MESSAGE_WRONG_EMAIL = getString(
    'signInEmailErrorMessage',
    'Please make sure you have entered a valid email',
);
export const HIDDEN_PASSWORD_TITLE = 'Password required';
export const INPUT_PASSWORD_MESSAGE = 'Enter the password for your account';
export const INPUT_FORGOT_PASSWORD_MESSAGE =
    'Enter your email address below and click Reset password. We will send you a link with which you can reset your password.';
export const PASSWORD_HINT = 'Password';
export const ERROR_MESSAGE_DEFAULT = 'There was an error signing you in. Please try again.';
export const ERROR_MESSAGE_WRONG_PASSWORD = 'Password is incorrect';
export const FORGOT_PASSWORD_TITLE = 'Forgot password?';
export const INPUT_LABEL_EMAIL = 'Email address';
export const TEXT_RESET_PASSWORD_SUCCESS_INTRO = `We’ve sent you a link with which you can reset your password.`;
export const TEXT_RESET_PASSWORD_SUCCESS = `Create a new password through this link and sign in with your new password through the button below.`;
export const UNAUTHORIZED_BUTTON_LABEL = 'Sign in with another account';
export const UNAUTHORIZED_TITLE = 'No access';
export const UNAUTHORIZED_DESCRIPTION =
    'The email address you specified is not on the attendee list. Check with the event organisation if you think it should.';
export const HIDDEN_PASSWORD_DESCRIPTION = 'To access this event, enter the password below.';
export const CONTINUE_BUTTON_LABEL = 'Continue';
export const RESET_PASSWORD_BUTTON_LABEL = 'Reset password';
export const EVENT_LOGIN_DESCRIPTION = 'To access this event, you need to be signed in.';
export const INPUT_REGISTER_MESSAGE =
    'Add your name and a password, and review our Privacy Policy to create an account. ';
export const ERROR_MESSAGE_REQUIRED_PASSWORD = 'Please enter your password';
export const INPUT_PASSWORD_HELP =
    'Password should have at least six characters and include uppercase, lowercase and numbers.';
export const CHANGE_IMAGE_FROM_PROFILE_TEXT =
    'You can always change your picture later in your profile settings.';
export const SIGNED_IN_DESCRIPTION =
    'To make sure other people can find you, check your profile and visibility settings.';
export const SIGNED_IN_PROFILE_TEXT = 'Check profile and settings.';
export const SIGN_IN_BUTTON = 'Sign in';
export const SIGN_IN_TITLE = 'Sign in';
export const SIGNED_IN_TITLE = 'Signed in successfully';
export const SIGN_IN_INTRO_TITLE = 'Welcome to';
export const CREATE_ACCOUNT_TITLE = 'Create account';
export const COMPANY_LABEL = 'Organisation';
export const JOB_TITLE_LABEL = 'Job title';
export const FIRST_NAME_LABEL = 'First name';
export const LAST_NAME_LABEL = 'Last name';
export const PRIVACY_MESSAGE = 'I agree with the';
export const PRIVACY_BUTTON_LABEL = 'Privacy Policy';
export const FIELD_REQUIRED = 'Mandatory field';
export const VERIFICATION_EMAIL_SENT_MESSAGE =
    'A verification email was sent to __email__. Click the link in this email to finalize signing up.';
export const VERIFICATION_EMAIL_RESENT_MESSAGE =
    'A verification email was sent again to __email__.';
export const TEXT_DIDNT_GET_EMAIL = `Didn’t get the email? Check your spam folder or`;
export const TEXT_RESEND_EMAIL_BUTTON = 'resend it';
export const GOT_MAIL_TITLE = `You've got mail!`;
export const TEXT_I_CONFIRMED_BUTTON = 'I confirmed my email';
export const ERROR_ACCOUNT_NOT_CONFIRMED = `Account is not yet confirmed. Please click the link in the verification email before continuing.`;
export const TEXT_TITLE_FORGOT_PASS = 'Forgot password';
export const TEXT_PASSWORD_UPPERCASE = 'Password must contain uppercase letters';
export const TEXT_PASSWORD_LOWERCASE = 'Password must contain lowercase letters';
export const TEXT_PASSWORD_NUMBER = 'Password must contain numbers';
export const TEXT_PASSWORD_LENGTH = 'Length must be greater than 8 characters';
export const ATTENDEES_SETTINGS_TITLE = 'Appear in attendee list?';
export const ATTENDEES_SETTINGS_DESCRIPTION =
    'Appearing in the attendee list will allow other event attendees to see that you are attending this event, to access your profile and to get in touch with you. Please note that you are always visible in a virtual room attendee list.';
export const ATTENDEES_SETTINGS_YES_BUTTON = 'Yes, make me visible';
export const ATTENDEES_SETTINGS_NO_BUTTON = 'No, keep me hidden';
export const CHAT_SETTINGS_TITLE = 'Allow chats and meetings?';
export const CHAT_SETTINGS_DESCRIPTION =
    'With this functionality, you and your fellow attendees can chat privately inside the platform, as well as schedule one-to-one meetings. Enable this functionality to get in touch with your fellow attendees and get the most out of your event experience.';
export const CHAT_SETTINGS_YES_BUTTON = 'Yes, allow chats and meetings';
export const CHAT_SETTINGS_NO_BUTTON = 'No, stay private';
export const GROUP_LOGIN_DESCRIPTION =
    'Signing in to this event is restricted. Enter your email address in to verify if you’re on the list.';

export const urlRegex = /(https?:\/\/[^\s]+)/g;

export const validatePassword = string => {
    const regExp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])/;
    return regExp.test(string);
};

export const uppercaseExists = string => {
    const regExp = /(?=.*[A-Z])/;
    return regExp.test(string);
};

export const lowercaseExists = string => {
    const regExp = /(?=.*[a-z])/;
    return regExp.test(string);
};

export const digitExists = string => {
    const regExp = /(?=.*\d)/;
    return regExp.test(string);
};

export const validateEmail = email => {
    // eslint-disable-next-line
    const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
};

export default getString;

import { useCallback, useEffect, useRef, useState } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';
import debounce from 'lodash/debounce';

const useResizeListener = (handler, shouldNotResize, withoutDebounce) => {
    const handling = useRef(false);
    const [wrapper, setWrapper] = useState(null);
    const [resized, setResized] = useState(null);
    const handleResizeBase = () => {
        if (!wrapper || handling.current) {
            handling.current = false;
            return;
        }

        // disconnecting to not cause an infinite loop
        ro.disconnect();

        // preventing the next resize if it happens
        handling.current = true;

        handler(wrapper);
        setResized(Date.now());

        // observing again after the changes take effect
        requestAnimationFrame(() => ro.observe(wrapper));
    };

    const handleResize = withoutDebounce
        ? handleResizeBase
        : debounce(() => handleResizeBase(), 500);

    const ro = new ResizeObserver(handleResize);

    useEffect(() => {
        if (wrapper) {
            (async () => {
                if (wrapper) {
                    handleResize(wrapper);
                    ro.observe(wrapper);
                }
            })();
        }

        return () => ro.disconnect();
    }, [wrapper, shouldNotResize]);

    // making sure the observer is cleaned
    useEffect(() => {
        return () => ro.disconnect();
    }, []);

    return [
        useCallback(node => {
            if (node !== null) {
                setWrapper(node);
            }
        }, []),
        resized,
    ];
};

export default useResizeListener;

import React, { useEffect, useState, useContext } from 'react';
import { getAllProgramFavoritesForEvent } from '../../services/api/data';
import moment from 'moment';
import 'moment/min/locales.min';
import { getItem } from '../../services/api/graphQlRepository';
import { List } from 'react-md';
import ThemeContext from '../../components/Theme/ThemeContext';
import { TimezoneContext } from '../Timezone/context';
import styled from 'styled-components';
import { getString } from '../../services/api/store.js';
import Loader from '../../components/General/Loader';
import ClassifierIcon from '../../components/Icons/ClassifierIcon';
import ObjectListItem from '../../components/ObjectListItem';
import NavigationLink from '../../components/Navigation/NavigationLink';
import { each } from 'async';
import Placeholder from '../SearchPage/components/placeholder';
import AppointmentService from '../Appointments/services/AppointmentService';
import Auth from '../../services/api/auth';
import eventBus from '../../utils/eventBus';
import { APPOINTMENT_PARTICIPANT_STATUS } from '../Appointments/constants';
import { Tab, TabsContainer } from '../../components/DateTabs';
import sortBy from 'lodash/sortBy';

const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;
`;

const ListStyled = styled(List)`
    width: 100%;
    max-width: ${props => window.innerWidth}px;
`;

const ListItem = ({ item }) => (
    <span>
        <ObjectListItem item={item} type={item.typeObj.target.toLowerCase()} />
    </span>
);

const TAB_DATE_PATTERN = 'ddd DD';

const MyProgrammePage = props => {
    const [favorites, setFavorites] = useState([]);
    const [days, setDays] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);

    const { selectedTimezone, getUtcToSelectedTimezone, getUnixToSelectedTimezone } = useContext(
        TimezoneContext,
    );

    useEffect(() => {
        fetchData();

        eventBus.on('refreshMyProgramme', fetchData);
    }, [activeTab, selectedTimezone]);

    const shouldDisplayAppointment = appointment =>
        appointment &&
        appointment.members &&
        appointment.members.length &&
        appointment.members[0].AppointmentGroup &&
        (appointment.members[0].AppointmentGroup.status ===
            APPOINTMENT_PARTICIPANT_STATUS.WAITING ||
            appointment.members[0].AppointmentGroup.status ===
                APPOINTMENT_PARTICIPANT_STATUS.ACCEPTED);

    const fetchData = () => {
        let favoritesArray = [];
        const daysArray = [];
        const daysMomentArray = [];

        getAllProgramFavoritesForEvent((err, favorites) => {
            each(
                favorites,
                (item, callback) => {
                    getItem('timeslots', item.id, (err, timeslotFromDb) => {
                        if (err || !timeslotFromDb || !timeslotFromDb.start) {
                            return callback();
                        }

                        const timeslot = timeslotFromDb;

                        getItem('types', timeslot.type, (err, type) => {
                            if (err || !type) {
                                console.log(err);

                                return callback();
                            }

                            timeslot.typeObj = type;
                            const day = getUtcToSelectedTimezone(timeslot.start)
                                .startOf('day')
                                .toISOString();
                            timeslot.dayUtc = day;
                            timeslot.sortTime = getUtcToSelectedTimezone(
                                timeslot.start,
                            ).toISOString();

                            favoritesArray.push(timeslot);

                            if (!daysArray.includes(day)) {
                                daysArray.push(day);
                            }

                            callback();
                        });
                    });
                },
                async () => {
                    let appointments = [];
                    const user = Auth.getUser();

                    if (user) {
                        appointments = await AppointmentService.getAllAppointments(user.id);
                        // Filter appointments: keep only appointments that are not declined
                        appointments = appointments.filter(shouldDisplayAppointment);
                    }

                    appointments.forEach(appointment => {
                        const day = getUnixToSelectedTimezone(appointment.start)
                            .startOf('day')
                            .toISOString();

                        appointment.dayUtc = day;

                        if (!daysArray.includes(day)) {
                            daysArray.push(day);
                        }

                        appointment.type = 'appointment';
                        appointment.typeObj = { target: 'appointment' };
                        appointment.sortTime = getUnixToSelectedTimezone(
                            appointment.start,
                        ).toISOString();
                        favoritesArray.push(appointment);
                    });

                    favoritesArray = sortBy(favoritesArray, ['sortTime', 'orderingName', 'name']);

                    const sortedDaysArray = daysArray.sort(
                        (a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD'),
                    );

                    setFavorites(favoritesArray);
                    setDays(sortedDaysArray);
                    setLoading(false);
                },
            );
        });
    };

    const onTabClick = index => {
        setActiveTab(index);
    };

    let dateSettings = getString('datetime');
    let locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';
    const renderListItems = () =>
        favorites.map((item, i) => {
            if (!item || !item.typeObj) {
                return;
            }

            const isAppointment = item.type === 'appointment';

            if (days[activeTab] === item.dayUtc) {
                let params = item.params;
                if (typeof params === 'string') {
                    params = JSON.parse(item.params);
                }
                let classifierIcon;
                if (params && params.icons && params.icons.length) {
                    params.icons.forEach(item => {
                        classifierIcon = <ClassifierIcon key={item} imageId={item} />;
                    });
                }

                return (
                    <LinkStyled
                        key={'link_' + item.id}
                        params={{
                            type: isAppointment ? 'appointment' : 'detail',
                            objectClass: isAppointment ? null : item.typeObj.target.toLowerCase(),
                            objectId: item.id,
                        }}
                    >
                        <ListItem item={item} />
                    </LinkStyled>
                );
            }
        });

    const content = () => {
        if (!favorites.length && !loading) {
            return (
                <Placeholder
                    type="emptyList"
                    introText={getString('personalNoFavoritesTitle') || 'Nothing saved yet...'}
                />
            );
        } else {
            return <ListStyled className="">{renderListItems()}</ListStyled>;
        }
    };

    const renderTabs = theme => {
        return days.map((elem, index) => {
            const label = getUtcToSelectedTimezone(elem, TAB_DATE_PATTERN, false, locale, true);
            return (
                <Tab
                    key={`tab_${index}`}
                    active={index === activeTab}
                    color={theme.contrast}
                    onClick={() => onTabClick(index)}
                >
                    <div>{label.split(' ')[0]}</div>
                    <div>{label.split(' ')[1]}</div>
                </Tab>
            );
        });
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    <TabsContainer>{renderTabs(theme)}</TabsContainer>
                    {loading && <Loader />}
                    {!loading && content()}
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default MyProgrammePage;

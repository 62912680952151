import React from 'react';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

const ConfiguredHtmlParser = html => {
    const transformNodes = (domNode, index) => {
        const { type, name } = domNode;

        if (type === 'tag' && name === 'h1') {
            domNode.attribs.style = 'color: rgba(0, 0, 0, 0.87)';
        }
        if (type === 'tag' && name === 'p') {
            domNode.attribs.style = 'color: rgba(0, 0, 0, 0.87)';
        }

        convertNodeToElement(domNode, index, transformNodes);
    };

    return ReactHtmlParser(html, {
        transform: transformNodes,
    });
};

export default ConfiguredHtmlParser;

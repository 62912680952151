import Resizer from 'react-image-file-resizer';

export const sleep = time => new Promise(resolve => setTimeout(resolve, time));

const pad = val => {
    let valString = val + '';
    if (valString.length < 2) {
        return `0${valString}`;
    } else {
        return valString;
    }
};

export const getTimer = time => {
    let difference = time;
    const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60;

    const minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60;

    const secondsDifference = Math.floor(difference / 1000);

    if (hoursDifference > 0) {
        return `${pad(hoursDifference)}:${pad(minutesDifference)}:${pad(secondsDifference)}`;
    }

    return `${pad(minutesDifference)}:${pad(secondsDifference)}`;
};

export const compressImage = (file, quality) =>
    new Promise(resolve => {
        Resizer.imageFileResizer(
            file,
            1280,
            720,
            'JPEG',
            quality ? quality : 80,
            0,
            uri => {
                resolve(uri);
            },
            'file',
        );
    });

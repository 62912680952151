import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactPlayer from 'react-player';
import { FontIcon } from 'react-md';
import styled from 'styled-components';
import get from 'lodash/get';

import { useVMMutation, useVMState } from '../../containers/main';
import Slider from './Slider';
import { BeautifulLoader } from './BeautifulLoader';
import { CLOUDFRONT_URL } from '../../../../../../config';

const Wrapper = styled.div`
    width: 100%;
    height: fit-content;
    position: relative;
    * > video {
        border-radius: 8px;
    }
`;

const PlayerWrapper = styled.div`
    cursor: ${props => (props.primary ? 'pointer' : 'default')};
    position: relative;
`;

const PlayIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 16px;
    left: 16px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.8);
    width: 48px;
    height: 48px;

    &:hover {
        background-color: #d20001;
    }
`;

const PlayIcon = styled(FontIcon)`
    font-size: 24px !important;
    color: ${props => (props.containerhovered ? '#fff' : 'rgba(0, 0, 0, 0.87)')} !important;
`;

const DurationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    height: 22px;
    width: 87px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
`;

const Duration = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
`;

const SliderWrapper = styled.div`
    position: absolute;
    bottom: 16px;
    left: 72px;
    width: calc(100% - 88px);
`;

const DEV_RECORDING_BUCKET_URL =
    'https://s3.eu-central-1.amazonaws.com/stream-recordings.dev.indrina.com';
const PROD_RECORDING_BUCKET_URL =
    'https://s3.eu-central-1.amazonaws.com/stream-recordings.web.indrina.com';

const MediaPlayer = ({ url: providedUrl, primary, muted, onDelete, playEnabled, ...props }) => {
    const playerRef = useRef(null);
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();

    const [isContainerHovered, setIsContainerHovered] = useState(false);
    const { duration, playing, playedSeconds, primaryPlayerRef, secondaryPlayerRef } = stateCtx;

    const canPlay = playEnabled === true || playEnabled === undefined;

    const url = useMemo(() => {
        if (!providedUrl) {
            return;
        }

        return providedUrl
            .replace(DEV_RECORDING_BUCKET_URL, CLOUDFRONT_URL)
            .replace(PROD_RECORDING_BUCKET_URL, CLOUDFRONT_URL);
    }, [providedUrl]);

    useEffect(() => {
        playerRef.current.seekTo(2, 'seconds');
    }, []);

    useEffect(() => {
        if (primary) {
            mutationCtx.setPrimaryPlayerRef(playerRef);
        } else {
            mutationCtx.setSecondaryPlayerRef(playerRef);
        }
    }, [playerRef, primary, url]);

    useEffect(() => {
        // Resume video from playedSeconds when mainMedia is switched
        const { playedSeconds } = stateCtx;

        if (playedSeconds) {
            playerRef.current.seekTo(playedSeconds, 'seconds');
        }
    }, [muted]);

    const roundValue = value => Math.round(value * 10) / 10;

    const onPlayClick = () => {
        if (canPlay && primary) {
            if (playing) {
                mutationCtx.stopPlaying();
            } else {
                mutationCtx.startPlaying();
            }
        }
    };

    const onProgress = progress => {
        const { playedSeconds } = progress;
        const roundedValue = roundValue(playedSeconds);

        if (!primary) {
            return;
        }

        if (roundedValue <= Math.floor(duration)) {
            mutationCtx.setPlayedSeconds(roundedValue);
        } else {
            mutationCtx.stopPlaying();

            if (get(primaryPlayerRef, 'current')) {
                primaryPlayerRef.current.seekTo(0, 'seconds');
            }

            if (get(secondaryPlayerRef, 'current')) {
                secondaryPlayerRef.current.seekTo(0, 'seconds');
            }
        }
    };

    const onDuration = newDuration => {
        if (primary) {
            mutationCtx.setPrimaryVideoDuration(newDuration);
        } else {
            mutationCtx.setSecondaryVideoDuration(newDuration);
        }
    };

    const onEnded = () => mutationCtx.stopPlaying();

    const convertSecondsToTimeString = seconds => {
        return new Date(seconds * 1000).toISOString().substr(14, 5);
    };

    const onSliderValueChanged = (value, event) => {
        const roundedValue = roundValue(value);

        // Pause video while dragging slider
        if (event.type === 'mousemove') {
            mutationCtx.stopPlaying();
        }

        if (get(primaryPlayerRef, 'current')) {
            primaryPlayerRef.current.seekTo(roundedValue, 'seconds');
        }

        if (get(secondaryPlayerRef, 'current')) {
            secondaryPlayerRef.current.seekTo(roundedValue, 'seconds');
        }

        if (event.type === 'mouseup') {
            mutationCtx.startPlaying();
        }
    };

    return (
        <Wrapper className="eureka-react" data-qa={props['data-qa']}>
            <BeautifulLoader active={!canPlay}>
                <PlayerWrapper primary={primary} onClick={onPlayClick}>
                    <ReactPlayer
                        url={url}
                        playing={playing}
                        width={'100%'}
                        height={'auto'}
                        onProgress={onProgress}
                        onDuration={onDuration}
                        onEnded={onEnded}
                        progressInterval={100}
                        ref={playerRef}
                        muted={muted}
                        loop={true}
                    />

                    {canPlay && primary && (
                        <PlayIconWrapper
                            onMouseEnter={() => setIsContainerHovered(true)}
                            onMouseLeave={() => setIsContainerHovered(false)}
                        >
                            <PlayIcon
                                onClick={onPlayClick}
                                containerhovered={isContainerHovered.toString()}
                            >
                                {playing ? 'pause' : 'play_arrow'}
                            </PlayIcon>
                        </PlayIconWrapper>
                    )}
                </PlayerWrapper>
                {primary && (
                    <SliderWrapper>
                        {duration && (
                            <Slider
                                min={0}
                                max={roundValue(duration)}
                                step={0.1}
                                value={playedSeconds}
                                onChange={onSliderValueChanged}
                            />
                        )}
                        <DurationWrapper>
                            <Duration>{`${convertSecondsToTimeString(
                                playedSeconds,
                            )} / ${convertSecondsToTimeString(duration)}`}</Duration>
                        </DurationWrapper>
                    </SliderWrapper>
                )}
            </BeautifulLoader>
        </Wrapper>
    );
};

export default MediaPlayer;

import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import { FontIcon } from 'react-md';

const StyledDialog = withStyles(() => ({
    paper: {
        borderRadius: 16,
        width: 980,
    },
}))(Dialog);

const contentStyle = {
    padding: 24,
};

const Img = styled.img`
    height: 612px;
    width: 931px;
    border-radius: 8px;
    z-index: 1000;
`;

const FlexRow = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 24px;
`;

const iconStyle = {
    cursor: 'pointer',
};

const EnlargedImageModal = ({ open, handleClose, imageUrl }) => (
    <StyledDialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogContent style={contentStyle}>
            <FlexRow>
                <FontIcon style={iconStyle} onClick={handleClose}>
                    close
                </FontIcon>
            </FlexRow>
            <Img src={imageUrl} />
        </DialogContent>
    </StyledDialog>
);

export default EnlargedImageModal;

import React, { useState } from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import MediaPlayer from '../../../../components/MediaPlayer';
import Button, { buttonTypes } from '../../../common/Button';
import useResize from '../../../VirtualSession/hooks/useResize';
import ThemeContext from '../../../../components/Theme/ThemeContext';
import { getString } from '../../../../services/api/store';
import { SectionDescription } from '../../../DetailPage/InfoBar';

const ClassifiersWrapper = styled.div`
    display: flex;
    width: 100%;
    overflow-y: scroll;
    margin-top: 16px;

    & > *:not(:last-child) {
        margin-right: 8px;
    }

    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
`;

const Classifier = styled.div`
    padding: 8px 12px;
    border-radius: 20px;
    background-color: rgba(237, 238, 242, 0.5);
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: ${props => props.color};
    flex: none;
`;

const VideoWrapper = styled.div`
    margin-top: 24px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    & > div:first-child {
        height: 100%;
    }
`;

const TextTitle = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    margin: 24px 0 12px 0;
`;

const DetailsContainer = styled(SectionDescription)`
    ${props =>
        props.isPreview &&
        `
        -webkit-line-clamp: 6 !important;
    `}
    a {
        text-decoration: unset;
        color: ${props => props.primary} &: hover {
            text-decoration: underline;
        }
    }
`;

const classifiersColors = ['#d59715', '#6330fe', '#368bff', 'rgba(251, 42, 42, 0.87)'];

const MAX_TEXT_LINES = 3;

const Info = ({ exhibitor }) => {
    const [readAll, setReadAll] = useState(false);

    const { info, videoUrl } = exhibitor;
    const classifiers = exhibitor.classifiers.map(({ name }) => name);
    const READ_MORE = getString('announcementsReadMore') || 'Read more';
    const READ_LESS = getString('announcementsReadLess') || 'Read less';

    const [videoWrapperElement] = useResize();

    const detailsText = info?.text;
    const textElem = document.getElementById('info-container');
    const divHeight = textElem?.offsetHeight;
    const lineHeight = 20;
    const lines = divHeight / lineHeight;
    const showMore = (lines && lines > MAX_TEXT_LINES) || true;

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <>
                    <ClassifiersWrapper>
                        {classifiers.map((classifier, index) => (
                            <Classifier
                                key={`classifier-${index}`}
                                color={classifiersColors[index % classifiersColors.length]}
                            >
                                {classifier}
                            </Classifier>
                        ))}
                    </ClassifiersWrapper>

                    {videoUrl && (
                        <VideoWrapper innerRef={videoWrapperElement}>
                            <MediaPlayer url={videoUrl} primary />
                        </VideoWrapper>
                    )}

                    {info && (
                        <>
                            <TextTitle>{info.title}</TextTitle>
                            {detailsText && (
                                <>
                                    <DetailsContainer
                                        id="info-container"
                                        className={readAll ? '' : 'hidden'}
                                        contrast={theme.contrast}
                                        primary={theme.primary}
                                        isPreview={showMore && !readAll}
                                    >
                                        {ReactHtmlParser(detailsText)}
                                    </DetailsContainer>

                                    {showMore && (
                                        <Button
                                            onClick={() => {
                                                setReadAll(!readAll);
                                            }}
                                            type={buttonTypes.GREY}
                                            text={!readAll ? READ_MORE : READ_LESS}
                                            style={{ margin: '16px 0 12px 0' }}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </ThemeContext.Consumer>
    );
};

export default Info;

import React, { useEffect, useMemo, useState } from 'react';
import { FontIcon } from 'react-md';
import get from 'lodash/get';
import styled from 'styled-components';

import entities from '../../constants/entities';
import bestFitLayout from '../../../../utils/bestFitLayout';
import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import StreamPlayer from '../common/StreamPlayer';
import PanelParticipant from './PanelParticipant';
import * as palette from '../../../../components/General/Variables';
import { FORTY_MILL, TWENTY_MILL } from '../../constants/values';
import noBroadcastImage from '../../../../assets/images/no-broadcast-border.png';
import useResize from '../../hooks/useResize';
import { ShadowOverlay, VirtualUserInfoWrapper, VirtualUserTitle } from '../../styles';
import Store from '../../../../services/api/store';
import { getImageSource } from '../../../../services/api/db';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const { virtualEventSessionEntity } = entities;

const Wrapper = styled.div`
    height: 100%;

    .stream-player.video-on,
    img {
        height: 100%;
        width: 100%;
        border-radius: 8px;

        [id*='player'] {
            background: transparent !important;
        }

        video {
            ${props => (props.isPanelDiscussion ? '' : 'border-radius: 8px')};
            object-fit: cover !important;
            // border-radius: 8px;
            /* This used to work for the parent element of button divs */
            /* But it does not work with newer browsers, the below doesn't hide the play button parent div */

            &::-webkit-media-controls {
                display: none !important;
                -webkit-appearance: none;
            }

            &::-webkit-media-controls-panel {
                display: none !important;
                -webkit-appearance: none;
            }

            /* Old shadow dom for play button */

            &::-webkit-media-controls-play-button {
                display: none !important;
                -webkit-appearance: none;
            }

            /* New shadow dom for play button */

            /* This one works! */

            &::-webkit-media-controls-start-playback-button {
                display: none !important;
                -webkit-appearance: none;
            }
        }
    }
`;

const GridForUsers = styled.div`
    display: grid;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    .stream-player {
        background: #000 !important;
        // border-radius: 8px;

        [id*='player'] {
            background: #000 !important;

            video {
                background: #000 !important;
            }
        }
    }
`;

const MicSwitchWrapper = styled.div`
    bottom: 0;
    margin: 0 4px 4px;
    position: absolute;
    left: 0;
    z-index: 10;
`;

const ZeroSpaceWrapper = styled.div`
    height: 0;
    width: 0;
    overflow: hidden;
`;

const StreamContainer = styled.div`
    display: flex;
    width: 100%;
    height: inherit;
    align-items: center;
    justify-content: center;
    background-color: #5a5b61;
    border-radius: 8px;
`;

const AccountCircleIconWrapper = styled.div`
    font-size: 48px;
    opacity: ${props => (props.imageUrl ? 1 : 0.2)};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${props => props.imageUrl}');
    width: 64px;
    height: 64px;
    border-radius: 50%;
`;

const Video = () => {
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();
    const { localClient, mainMedia, streams, virtualEventSession, virtualEventUser } = stateCtx;
    const activeVideoId = get(virtualEventSession, 'activeVideoId');
    const mainIsSlides = mainMedia === 'slides';
    const small = mainIsSlides ? 1 : 0;
    const virtualSessionsSpeakerHolder =
        Store.argument && Store.argument.virtualSessionsSpeakerHolder;

    const [eventSpeakerPlaceholder, setEventSpeakerPlaceholder] = useState(noBroadcastImage);
    const placeHolderUrl =
        get(virtualEventSession, 'presentationSpeakerPlaceholderUrl') ||
        eventSpeakerPlaceholder ||
        noBroadcastImage;

    const currentStream = useMemo(() => {
        const hasVideoActive = get(virtualEventUser, 'hasVideoActive');
        let streamId = get(virtualEventUser, 'UserId', 0);

        if (hasVideoActive) {
            streamId = get(virtualEventUser, 'UserId', 0) + TWENTY_MILL;
        }
        if (activeVideoId) {
            streamId = virtualEventSession.id + FORTY_MILL;
        }
        if (
            virtualEventUser &&
            virtualEventUser.rtmpInfo &&
            virtualEventSession.status === 'broadcasting'
        ) {
            return streams.find(st => st.streamId === virtualEventUser.rtmpInfo.uid);
        }

        return (streams || []).find(
            stream =>
                virtualEventSession.status === 'broadcasting' &&
                stream &&
                (virtualEventUser || activeVideoId) &&
                stream.streamId === streamId,
        );
    }, [get(virtualEventSession, 'status'), virtualEventUser, streams, activeVideoId]);

    const virtualEventUsers = useMemo(() => get(virtualEventSession, 'VirtualEventUsers', []), [
        virtualEventSession,
    ]);

    const nonRtmpVirtualEventUsers = useMemo(() => {
        return virtualEventUsers.filter(vUser => !vUser.rtmpInfo);
    }, [virtualEventUsers]);

    const usersWithStreams = useMemo(() => {
        return nonRtmpVirtualEventUsers.filter(virtualEventUser => {
            return (streams || []).find(
                stream => stream && virtualEventUser && stream.streamId === virtualEventUser.UserId,
            );
        });
    }, [virtualEventUsers, streams]);
    const otherStreams = useMemo(
        () =>
            (streams || []).filter(
                stream =>
                    virtualEventSession.status === 'broadcasting' &&
                    stream &&
                    stream.streamId !== get(virtualEventUser, 'UserId') &&
                    virtualEventUsers.find(vUser => vUser.UserId === stream.streamId),
            ),
        [get(virtualEventSession, 'status'), get(virtualEventUser, 'UserId'), streams],
    );

    const isPanelDiscussion =
        virtualEventSession.mode === virtualEventSessionEntity.mode.panelDiscussion;

    useEffect(() => {
        (async () => {
            if (virtualSessionsSpeakerHolder) {
                await getImageSource(virtualSessionsSpeakerHolder, (err, img) => {
                    setEventSpeakerPlaceholder(img);
                });
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const configForOthers = {
                video: isPanelDiscussion,
                audio: true,
            };
            const subscribePromises = otherStreams.map(
                stream => stream.user && localClient.subscribe(stream, configForOthers),
            );

            try {
                await Promise.all(subscribePromises);
            } catch (err) {
                console.error(err);
            }

            if (currentStream && currentStream.user) {
                await localClient.subscribe(
                    currentStream,
                    {
                        video: true,
                        audio: true,
                    },
                    err => {
                        console.log(err);
                    },
                );
                localClient.setStreamFallbackOption(currentStream, 0);
            }

            mutationCtx.setSubscribeTimestamp(Date.now());
        })();
    }, [isPanelDiscussion, currentStream, otherStreams]);

    const columns = bestFitLayout(usersWithStreams.length);
    const hasVideoActive = get(virtualEventUser, 'hasVideoActive') || activeVideoId;
    const isVideoOn = get(virtualEventUser, 'isVideoOn');
    const isMicrophoneOn = get(virtualEventUser, 'isMicrophoneOn', false);
    const user = get(virtualEventUser, 'User', {});
    const broadcasting = get(virtualEventSession, 'status') === 'broadcasting';
    const [wrapperRef] = useResize();

    const notPanelDiscussionAndBroadcasting = !isPanelDiscussion && broadcasting;
    const isActiveStreamRtmp =
        currentStream && currentStream.streamId < 100 && currentStream.streamId > 0;
    const displayNoVideoImage =
        notPanelDiscussionAndBroadcasting &&
        ((!hasVideoActive && !activeVideoId) || !currentStream) &&
        !isActiveStreamRtmp &&
        !virtualEventUser;

    return (
        <Wrapper innerRef={wrapperRef} isPanelDiscussion={isPanelDiscussion}>
            {(!broadcasting || displayNoVideoImage) && (
                <img src={placeHolderUrl} alt="noBroadcastImage" />
            )}
            {!isPanelDiscussion && !currentStream && virtualEventUser && broadcasting && (
                <StreamContainer>
                    <AccountCircleIconWrapper imageUrl={user.imageUrl}>
                        {!user.imageUrl && <AccountCircleIcon fontSize="inherit" />}
                    </AccountCircleIconWrapper>
                    {!hasVideoActive && (
                        <MicSwitchWrapper
                            small={small}
                            data-qa={`virtual-session-user-microphone-${user.id}`}
                        >
                            <FontIcon
                                style={{
                                    fontSize: '16px',
                                    color: '#ffffff',
                                }}
                            >
                                {isMicrophoneOn ? palette.ICON_MIC : palette.ICON_MIC_OFF}
                            </FontIcon>
                        </MicSwitchWrapper>
                    )}
                    <VirtualUserInfoWrapper data-qa={`virtual-session-user-name-${user.id}`}>
                        <VirtualUserTitle>
                            {user.firstName} {user.lastName}
                        </VirtualUserTitle>
                    </VirtualUserInfoWrapper>
                    <ShadowOverlay />
                </StreamContainer>
            )}
            {!isPanelDiscussion && currentStream && (
                <StreamPlayer
                    key={`stream-player-${currentStream.getId()}`}
                    showProfile={stateCtx.profile}
                    local={false}
                    stream={currentStream}
                    isPlaying={currentStream.isPlaying()}
                    isVideoOn={true}
                    uid={currentStream.getId()}
                    domId={`stream-player-${currentStream.getId()}`}
                    data-qa="virtual-session-camera-feed"
                    displayImagePlaceholder
                >
                    {!isPanelDiscussion && broadcasting && (
                        <StreamContainer
                            style={{
                                height:
                                    isVideoOn ||
                                    hasVideoActive ||
                                    activeVideoId ||
                                    isActiveStreamRtmp
                                        ? 0
                                        : 'inherit',
                                ...(isActiveStreamRtmp && { overflow: 'hidden' }),
                            }}
                        >
                            {!isVideoOn && (
                                <AccountCircleIconWrapper imageUrl={user.imageUrl}>
                                    {!user.imageUrl && <AccountCircleIcon fontSize="inherit" />}
                                </AccountCircleIconWrapper>
                            )}
                            {!hasVideoActive && (
                                <MicSwitchWrapper
                                    small={small}
                                    data-qa={`virtual-session-user-microphone-${user.id}`}
                                >
                                    <FontIcon
                                        style={{
                                            fontSize: '16px',
                                            color: '#ffffff',
                                        }}
                                    >
                                        {isMicrophoneOn ? palette.ICON_MIC : palette.ICON_MIC_OFF}
                                    </FontIcon>
                                </MicSwitchWrapper>
                            )}
                            <VirtualUserInfoWrapper
                                data-qa={`virtual-session-user-name-${user.id}`}
                            >
                                <VirtualUserTitle>
                                    {user.firstName} {user.lastName}
                                </VirtualUserTitle>
                            </VirtualUserInfoWrapper>
                            <ShadowOverlay />
                        </StreamContainer>
                    )}
                </StreamPlayer>
            )}
            <ZeroSpaceWrapper>
                {!isPanelDiscussion &&
                    otherStreams.map(
                        stream =>
                            stream && (
                                <StreamPlayer
                                    key={`stream-player-${stream.getId()}-regular`}
                                    local={false}
                                    stream={stream}
                                    isPlaying={stream.isPlaying()}
                                    isVideoOn={false}
                                    uid={stream.getId()}
                                    domId={`stream-player-${stream.getId()}`}
                                />
                            ),
                    )}
            </ZeroSpaceWrapper>
            {isPanelDiscussion && broadcasting && (
                <GridForUsers
                    style={{
                        gridTemplateColumns: 'minmax(0, 1fr) '.repeat(columns),
                        gridAutoRows: '1fr',
                    }}
                    data-qa="panel-discussion-grid"
                >
                    {nonRtmpVirtualEventUsers.map((virtualEventUser, index) => (
                        <PanelParticipant
                            key={`panel-participant-${index}`}
                            localClient={localClient}
                            virtualEventUser={virtualEventUser}
                        />
                    ))}
                </GridForUsers>
            )}
        </Wrapper>
    );
};

export default Video;

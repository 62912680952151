import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { updateApp } from '../../../services/api';
import Loader from '../../../components/General/Loader';
import SignIn from '../../SignInPage/components';
import EventGrid from './EventGrid';
import ContentSelector from '../../../components/Routes/ContentSelector';
import RightPanelView from '../../../components/Routes/RightPanelView';
import SideNavigation from '../../../components/Navigation/SideNavigation';
import * as palette from '../../../components/General/Variables';
import { MAX_PHONE_INT, SOCIETY_COLORS } from '../../../components/General/Variables';
import * as AppStyle from '../../../components/Navigation/style/navigationStyle';
import ThemeContext from '../../../components/Theme/ThemeContext';
import AuthenticationProtection from '../../../components/ProtectedPages/AuthenticationProtection';
import { XMPPManager } from '../../Talk/components/XMPPManager';
import Auth from '../../../services/api/auth';
import { EUREKA_TALK_HOST } from '../../../config';
import EventInfo from './EventInfo';
import useInvites from '../hooks/useInvites';
import HeaderTitle from './HeaderTitle';

const SocietyWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: scroll;
`;

const Container = styled.div`
    margin: 24px 130px 0 130px;
    height: calc(100% - 24px);
    overflow-y: scroll;

    @media (max-width: 1023px) {
        margin: 24px 24px 0 24px;
    }
`;

const FullScreen = styled.div`
    background-color: #ffffff;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
`;

const Contacts = props => {
    return <ContentSelector type="mycontacts" {...props} />;
};

const VirtualModeration = props => {
    const { eventId } = useParams();
    return (
        <FullScreen>
            {EUREKA_TALK_HOST && (
                <XMPPManager
                    isAuthenticated={Auth.isUserAuthenticated()}
                    eventId={eventId}
                    chatUrl={EUREKA_TALK_HOST}
                />
            )}
            <ContentSelector type="virtual-moderation" {...props} />
        </FullScreen>
    );
};

const QAModeration = props => {
    const { eventId } = useParams();
    return (
        <FullScreen>
            {EUREKA_TALK_HOST && (
                <XMPPManager
                    isAuthenticated={Auth.isUserAuthenticated()}
                    eventId={eventId}
                    chatUrl={EUREKA_TALK_HOST}
                />
            )}
            <ContentSelector type="qa-moderation" {...props} />
        </FullScreen>
    );
};

const QAPresentation = props => {
    return (
        <FullScreen>
            <ContentSelector type="qa-presentation" {...props} />
        </FullScreen>
    );
};

const societyRoutes = [
    {
        path: '/',
        title: 'Events',
        exact: true,
        main: EventGrid,
    },
    {
        path: '/contacts',
        title: 'Contacts',
        main: Contacts,
    },
    {
        path: '/virtual-moderation/:timeslotId/:eventId',
        title: 'virtual moderation',
        main: VirtualModeration,
    },
    {
        path: '/qa-moderation/:timeslotId/:eventId',
        title: 'Q&A moderation',
        main: QAModeration,
    },
    {
        path: '/qa-presentation/:externalObjectId',
        title: 'Q&A presentation',
        main: QAPresentation,
    },
];

const navigation = [
    {
        iconName: 'dashboard',
        title: 'Events',
        to: '/',
        type: 'events',
    }, // {
    //     iconName: 'people',
    //     title: 'Contacts',
    //     to: '/contacts',
    //     type: 'mycontacts',
    // },
];

const Society = () => {
    const isMobile = palette.MIN_TABLET_INT && window.innerWidth < palette.MIN_TABLET_INT;
    const [loading, setLoading] = useState(true);
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const [title, setTitle] = useState(navigation[0].title);
    const [hasError, setHasError] = useState(null);
    const [appReady, setAppReady] = useState(false);
    const [eventAppState, setEventAppState] = useState({});

    const { setTheme } = useContext(ThemeContext);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useInvites(true);

    useEffect(() => {
        updateApp(null, (err, config) => {
            setLoading(false);
            if (err || !config) {
                setLoading(false);
                setHasError(true);
                return;
            }

            const token = queryParams.get('token');
            if (token) {
                try {
                    Cookies.set('userOnboardingInfo', token);
                } catch (e) {
                    //console.log('invalid token');
                }
            }

            setLoading(false);
            const theme = {
                primary: SOCIETY_COLORS.PRIMARY,
                secondary: SOCIETY_COLORS.PRIMARY,
                contrast: SOCIETY_COLORS.BRANDING,
            };
            if (config?.settings?.colors?.multiAppBar) {
                theme.primary = config?.settings?.colors?.multiAppBar;
                theme.secondary = config?.settings?.colors?.mutiActionBarBtn;
                theme.contrast = config?.settings?.colors?.mutiActionBarBtn;
            }
            document.title = `${config.appName ? config.appName : ''} - Events`;
            setEventAppState(config);
            setTheme(theme);
            setAppReady(true);
        });
    }, []);

    const onToggleSideBarVisibility = () => setIsSideBarOpen(val => !val);

    const renderSocietyContent = () => (
        <Container>
            <Switch>
                {societyRoutes.map((route, index) => (
                    <Route key={index} path={route.path} exact={route.exact}>
                        {<route.main setTitle={setTitle} />}
                    </Route>
                ))}
                <Route path="/events/:eventName">
                    <EventInfo />
                </Route>
                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>
            <RightPanelView />
        </Container>
    );

    return (
        <SocietyWrapper>
            {loading && <Loader />}
            {hasError && <h1>There is an error with this society domain!</h1>}
            {appReady && (
                <>
                    <Route exact path="/sign-in" render={() => <SignIn isSociety />} />
                    <AuthenticationProtection>
                        <SideNavigation
                            title={<HeaderTitle title={title} isSideBarOpen={isSideBarOpen} />}
                            setTitle={setTitle}
                            isMobile={isMobile}
                            visibleSideMenu={isSideBarOpen}
                            isSidepanelOpened={false}
                            onSideMenuVisibilityChange={onToggleSideBarVisibility}
                            navigationType={'iconMenu'}
                            navigation={navigation}
                            renderContent={renderSocietyContent}
                            Container={AppStyle.StyledNavigationDrawer}
                            appState={eventAppState}
                        />
                    </AuthenticationProtection>
                </>
            )}
        </SocietyWrapper>
    );
};

export default Society;

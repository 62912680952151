import React from 'react';
import { CardContent, CardWrapper, TitleContainer } from './PosterAndSlidesCard';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const NoParticipantsCard = ({ wrapperStyle }) => {
    return (
        <CardWrapper style={wrapperStyle}>
            <TitleContainer>
                <InfoOutlinedIcon style={{ marginRight: 18, fontSize: 20 }} />
                No participants
            </TitleContainer>
            <CardContent>
                Start the meeting from the button at the bottom left to allow participants in.
            </CardContent>
        </CardWrapper>
    );
};

export default NoParticipantsCard;

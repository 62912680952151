import { useCallback, useEffect } from 'react';

const useAccessibleElement = ({ ariaControls, inputElementId }) => {
    const keyPressHandler = useCallback(
        e => {
            if (e.key === 'Enter') {
                if (e.target.getAttribute('aria-controls') === ariaControls) {
                    e.preventDefault();
                    return document.getElementById(inputElementId).click();
                }
                if (e.target.id === inputElementId && !ariaControls) {
                    e.preventDefault();
                    document.getElementById(inputElementId).click();
                }
            }
        },
        [ariaControls, inputElementId],
    );

    useEffect(() => {
        document.addEventListener('keypress', keyPressHandler);

        return () => {
            document.removeEventListener('keypress', keyPressHandler);
        };
    }, [keyPressHandler]);
};

export default useAccessibleElement;

import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';

import QuestionsAndAnswers from './BeamerQuestionsAndAnswers';
import { useGlobalState } from '../../../../utils/container';

const BeamerViewUserInteraction = () => {
    const stateCtx = useGlobalState();
    const { socket, virtualEventSession } = stateCtx;
    const { ExternalObjectId: externalObjectId } = virtualEventSession;
    const [newQuestionsCount, setNewQuestionsCount] = useState(0);
    const newQuestionsCountRef = useRef(newQuestionsCount);
    const newQuestionsIdsRef = useRef([]);

    useEffect(() => {
        const joinQuestions = () => {
            if (socket) {
                socket.emit('joinQuestions', {
                    objectId: externalObjectId,
                });
            }
        };
        const updateQuestions = async questionData => {
            if (questionData.action === 'updateQuestion') {
                const { question } = questionData;

                // if question is not visible => it becomes visible now
                if (
                    question.visible &&
                    !newQuestionsIdsRef.current.find(id => id === question.id)
                ) {
                    newQuestionsIdsRef.current.push(question.id);
                    increaseNewQuestionsCount();
                }

                if (
                    !question.visible &&
                    newQuestionsIdsRef.current.find(id => id === question.id)
                ) {
                    newQuestionsIdsRef.current = newQuestionsIdsRef.current.filter(
                        id => id !== question.id,
                    );
                    decreaseNewQuestionsCount();
                }
            }
        };

        (async () => {
            if (!externalObjectId) {
                return () => {};
            }

            if (!socket) {
                return;
            }

            socket.on('connect', joinQuestions);

            joinQuestions();

            socket.on('updateQuestions', updateQuestions);
        })();

        return () => {
            if (socket) {
                socket.off('connect', joinQuestions);
                socket.off('updateQuestions', updateQuestions);
            }
        };
    }, [externalObjectId, newQuestionsCount]);

    const setNewQuestionsCountExtra = count => {
        newQuestionsCountRef.current = count;
        setNewQuestionsCount(count);
    };

    const increaseNewQuestionsCount = () => {
        setNewQuestionsCountExtra(newQuestionsCountRef.current + 1);
    };

    const decreaseNewQuestionsCount = () => {
        newQuestionsCountRef.current > 0 &&
            setNewQuestionsCountExtra(newQuestionsCountRef.current - 1);
    };

    return (
        <QuestionsAndAnswers
            setNewQuestionsCount={count => setNewQuestionsCountExtra(count)}
            newQuestionIds={newQuestionsIdsRef.current}
        />
    );
};

export default withRouter(BeamerViewUserInteraction);

import Auth from '../services/api/auth';
import AnalyticsService from '../features/analytics/services';

const LOCAL_STORAGE_KEY_PREFIX = 'viewed-recording-map';
const ONE_MINUTE = 60;

const useViewRecordingAnalytics = sessionId => {
    const getKey = userId => {
        return `${LOCAL_STORAGE_KEY_PREFIX}-${userId}`;
    };

    const getViewedMap = userId => {
        const key = getKey(userId);

        try {
            return JSON.parse(localStorage.getItem(key)) || {};
        } catch (_) {
            return {};
        }
    };

    const setViewedMap = (userId, data) => {
        const key = getKey(userId);

        localStorage.setItem(key, JSON.stringify(data));
    };

    const increaseViewedTime = value => {
        if (!sessionId) {
            return;
        }

        const user = Auth.getUser();
        if (!user) {
            return;
        }

        const { id: userId } = user;
        const viewedMap = getViewedMap(userId);
        const currentViewedSeconds = viewedMap[sessionId] || 0;
        let newViewedSeconds = currentViewedSeconds + value;

        if (newViewedSeconds > ONE_MINUTE) {
            AnalyticsService.addSample('viewRecording', 'virtual-session', sessionId);
            newViewedSeconds -= ONE_MINUTE;
        }

        viewedMap[sessionId] = newViewedSeconds;
        setViewedMap(userId, viewedMap);
    };

    return { increaseViewedTime };
};

export default useViewRecordingAnalytics;

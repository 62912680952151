import React from 'react';
import get from 'lodash/get';

import { ContainerLeft, MainScreen, MainScreenWrapper } from '../../../styles';
import MediaPlayer from '../../../../../components/MediaPlayer';
import Session from '../../common/Session';
import { useGlobalState } from '../../../../../utils/container';
import useResize from '../../../hooks/useResize';
import ShareContent from '../common/ShareContent';
import Video from '../Video';
import { checkIfTimeslotHasOnlyOneUploadedVideo, getPlayerLinkByType } from '../../../utils/Utils';
import VirtualBannerImage from './VirtualBannerImage';

const MainContent = props => {
    const { isVotingActive, setIsVotingActive } = props;
    const stateCtx = useGlobalState();

    const { mainMedia, timeslot, virtualEventSession } = stateCtx;
    const hasOnlyOneUploadedVideo = checkIfTimeslotHasOnlyOneUploadedVideo(timeslot);
    const forceUnmuteSlidesForUploadedVideo = hasOnlyOneUploadedVideo && mainMedia === 'slides';
    const forceNotMuted = !!get(virtualEventSession, 'recordingInfo.playBothVideosNormally');
    const muted =
        forceNotMuted || forceUnmuteSlidesForUploadedVideo ? false : mainMedia === 'slides';

    const [screenWrapperRef] = useResize(null, isVotingActive);
    const shouldRenderMediaPlayer =
        timeslot && timeslot.params && timeslot.params.virtualSession === 'on-demand';
    const shouldRenderLiveSession = !shouldRenderMediaPlayer;
    const mainIsVideo = mainMedia === 'video';
    const mainIsSlides = mainMedia === 'slides';

    return (
        <ContainerLeft size={8} tabletSize={8} phoneSize={12}>
            {shouldRenderMediaPlayer && (
                <MediaPlayer
                    primary
                    url={getPlayerLinkByType({
                        mainMedia,
                        timeslot,
                        primaryScreen: true,
                    })}
                    muted={muted}
                    viewRecordingSessionId={timeslot && timeslot.id}
                />
            )}
            {shouldRenderLiveSession && (
                <MainScreenWrapper innerRef={screenWrapperRef}>
                    <MainScreen size={12} tabletSize={12} video={mainIsVideo ? 1 : 0}>
                        {mainIsSlides ? (
                            <ShareContent setIsVotingActive={setIsVotingActive} {...props} />
                        ) : (
                            <Video timeslot={timeslot} />
                        )}
                    </MainScreen>
                </MainScreenWrapper>
            )}

            {!isVotingActive && (
                <>
                    <VirtualBannerImage params={timeslot.params} rootRoute={props.rootRoute} />
                    <Session sections={timeslot.sections || []} info={timeslot.info || {}} />
                </>
            )}
        </ContainerLeft>
    );
};

export default MainContent;

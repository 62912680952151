import * as StyledComponent from '../../style/modalsStyle';
import { FormWrapper } from '../../style/modalsStyle';
import { withRouter } from 'react-router-dom';
import { getString } from '../../../../services/api/store';
import React, { useContext, useEffect, useState } from 'react';
import * as palette from '../../../../components/General/Variables';
import ComplexDialog from '../../../../components/Dialog/ComplexDialog';
import { Flex } from '../../../common/Flex';
import { FontIcon } from 'react-md';

import { StyledForm } from '../../style';
import * as string from '../../../SignInPage/constants/strings';
import Button, { buttonTypes } from '../../../common/Button';
import { checkPasswordMatch, deleteAccount } from '../../../UserProfile/service';
import AuthUserContext from '../../../../components/Session/AuthUserContext';
import { deleteAccountSteps } from '../../constants/index';
import { getLocalAppStateAsync } from '../../../../services/api/db';

const ChangePasswordModal = ({ theme, onClose, match, history, location, onGoBack }) => {
    const [currentStep, setCurrentStep] = useState(deleteAccountSteps.CHECK_PASSWORD_STEP);
    const [currentPassword, setCurrentPassword] = useState('');
    const [wrongCurrentPassword, setWrongCurrentPassword] = useState(false);
    const [hasProtection, seHasProtection] = useState(false);

    const { showSignIn } = useContext(AuthUserContext);
    const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;
    const TEXT_ERROR_WRONG_PASSWORD =
        getString('passwordModalError') || string.ERROR_MESSAGE_WRONG_PASSWORD;
    const TEXT_FORGOT_PASSWORD =
        getString('profileForgotPasswordDialogTitle') || string.FORGOT_PASSWORD_TITLE;
    const SIGN_IN_TEXT = getString('sideMenuSignIn') || 'Sign in';

    useEffect(() => {
        (async () => {
            const localAppState = await getLocalAppStateAsync();
            if (localAppState) {
                seHasProtection(localAppState.eurekaOnly || localAppState.attendingOnly);
            }
        })();
    }, []);

    const onCheckPassword = async () => {
        try {
            const response = await checkPasswordMatch({ password: currentPassword });
            if (response?.isMatch) {
                setCurrentStep(deleteAccountSteps.DELETE_STEP);
            } else {
                setWrongCurrentPassword(true);
            }
        } catch (e) {
            if (e && e.message === 'Forbidden') {
                setWrongCurrentPassword(true);
            }
        }
    };

    const onDeleteAccount = async () => {
        try {
            await deleteAccount();
            setCurrentStep(deleteAccountSteps.CONFIRMATION_STEP);
        } catch (e) {
            console.log('e', e);
        }
    };

    const goToSignIn = e => {
        onClose();
        showSignIn(e, {
            match,
            history,
            location,
        });
    };

    const getModalButtonOptions = () => {
        switch (currentStep) {
            case deleteAccountSteps.CHECK_PASSWORD_STEP:
                return [
                    {
                        title: 'Next',
                        variant: 'text',
                        type: 'save',
                        onClick: onCheckPassword,
                    },
                    {
                        title: getString('cancel') || 'Cancel',
                        variant: 'text',
                        type: 'cancel',
                        onClick: onGoBack,
                    },
                ];
            case deleteAccountSteps.DELETE_STEP:
                return [
                    {
                        title: 'Delete account',
                        variant: 'text',
                        type: 'delete',
                        onClick: onDeleteAccount,
                    },
                    {
                        title: getString('cancel') || 'Cancel',
                        variant: 'text',
                        type: 'cancel',
                        onClick: onGoBack,
                    },
                ];
            case deleteAccountSteps.CONFIRMATION_STEP:
                if (hasProtection) {
                    return [
                        {
                            title: SIGN_IN_TEXT,
                            variant: 'text',
                            type: 'save',
                            onClick: goToSignIn,
                        },
                    ];
                } else {
                    return [
                        {
                            title: SIGN_IN_TEXT,
                            variant: 'text',
                            type: 'save',
                            onClick: goToSignIn,
                        },
                        {
                            title: 'Back to event',
                            variant: 'text',
                            type: 'cancel',
                            onClick: onClose,
                        },
                    ];
                }
        }
    };

    return (
        <ComplexDialog
            title={
                <Flex>
                    <StyledComponent.BackButton
                        style={{
                            color: palette.COLOR_TEXT,
                            marginTop: -10,
                        }}
                        icon
                        onClick={onGoBack}
                    >
                        arrow_back
                    </StyledComponent.BackButton>
                    <StyledComponent.ModalTitle>Delete account</StyledComponent.ModalTitle>
                </Flex>
            }
            visible={true}
            contentStyle={{ padding: 0 }}
            onClose={null}
            displayBoxShadow
            iconName={'arrow-back'}
            options={getModalButtonOptions()}
        >
            {currentStep === deleteAccountSteps.CHECK_PASSWORD_STEP && (
                <StyledForm
                    maincolor={theme.primary}
                    horizontalMargin={isDesktop && '0px'}
                    minHeight="100px"
                >
                    <FormWrapper>
                        <StyledComponent.Text>
                            To verify it’s you, please enter your password.{' '}
                        </StyledComponent.Text>
                        <StyledComponent.StyledTextField
                            id="current-password"
                            required
                            name="currentPassword"
                            error={currentPassword === '' || wrongCurrentPassword}
                            value={currentPassword}
                            onChange={value => setCurrentPassword(value)}
                            label={'Current password'}
                            type="password"
                            errorText={
                                wrongCurrentPassword
                                    ? TEXT_ERROR_WRONG_PASSWORD
                                    : 'Password cannot be empty'
                            }
                            customLabelFontSize={'15px'}
                            errorBorderBottom={true}
                            primary={theme.primary}
                        />
                        <Button
                            type={buttonTypes.GHOST_LONG}
                            background={theme.primary}
                            text={TEXT_FORGOT_PASSWORD}
                            onClick={e => {
                                onClose();
                                showSignIn(e, {
                                    match,
                                    history,
                                    location,
                                    signInCurrentPage: 'forgotPassword',
                                });
                            }}
                            data-qa="button-forgot-password-profile-modal"
                            style={{ width: 'fit-content' }}
                        />
                    </FormWrapper>
                </StyledForm>
            )}
            {currentStep === deleteAccountSteps.DELETE_STEP && (
                <StyledComponent.ModalContainerWrapper>
                    <StyledComponent.ModalIconContainer>
                        <FontIcon>delete_forever</FontIcon>
                    </StyledComponent.ModalIconContainer>
                    <StyledComponent.Text>
                        Are you sure you want to delete your account? This cannot be undone.
                    </StyledComponent.Text>
                </StyledComponent.ModalContainerWrapper>
            )}
            {currentStep === deleteAccountSteps.CONFIRMATION_STEP && (
                <StyledComponent.ModalContainerWrapper>
                    <StyledComponent.ModalIconContainer primary={theme.primary}>
                        <FontIcon>check</FontIcon>
                    </StyledComponent.ModalIconContainer>
                    <StyledComponent.Text>
                        Your account was sucessfully deleted.
                    </StyledComponent.Text>
                </StyledComponent.ModalContainerWrapper>
            )}
        </ComplexDialog>
    );
};

export default withRouter(ChangePasswordModal);

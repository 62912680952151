import React, { useCallback, useEffect, useState } from 'react';
import { DialogContainer, FontIcon } from 'react-md';
import styled from 'styled-components';

import AchievementCard from './AchievementCard';
import { Flex } from '../../VirtualSession/components/userInteraction/questionsAndAnswers/QuestionsAndAnswers';
import RewardCard from './RewardCard';
import useEscapeKeyDown from '../../../hooks/useEscapeKeyDown';

const StyledDialog = styled(DialogContainer)`
    .md-paper {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        box-shadow: unset;
    }
`;

const CardContainer = styled(Flex)`
    width: 100%;
`;

const ArrowIcon = styled(FontIcon)`
    font-size: 64px !important;
    border-radius: 4px !important;
    color: #ffffff !important;
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.35));
    cursor: pointer;
`;

const AchievementModal = ({
    visible,
    onHide,
    achievement: achievementData,
    initialShowRewardOnly = false,
}) => {
    const [selectedLevel, setSelectedLevel] = useState(1);
    const [showRewardOnly, setShowRewardOnly] = useState(initialShowRewardOnly);

    useEffect(() => {
        setShowRewardOnly(initialShowRewardOnly);
    }, [visible, initialShowRewardOnly]);

    const handleLeftArrowClick = () => {
        if (selectedLevel === 1) {
            return;
        }

        setSelectedLevel(selectedLevel - 1);
    };

    const handleRightArrowClick = () => {
        if (selectedLevel === achievement.levels.length) {
            return;
        }

        setSelectedLevel(selectedLevel + 1);
    };

    const handleHide = () => {
        setSelectedLevel(1);
        onHide();
    };

    useEscapeKeyDown({ handler: handleHide });

    const keyDownHandler = useCallback(
        e => {
            if (!visible || showRewardOnly) {
                return;
            }

            if (e.key === 'ArrowLeft') {
                handleLeftArrowClick();
            }
            if (e.key === 'ArrowRight') {
                handleRightArrowClick();
            }
        },
        [selectedLevel, visible, showRewardOnly],
    );

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [keyDownHandler]);

    if (!achievementData) {
        return null;
    }

    const { Achievement: achievement } = achievementData;

    const achievementCardData = {
        ...achievementData,
        currentLevel: selectedLevel,
    };

    if (achievementData.currentLevel > selectedLevel) {
        achievementCardData.currentValue = achievement.levels[selectedLevel - 1].taskParameter;
    }
    if (achievementData.currentLevel < selectedLevel) {
        achievementCardData.currentValue = 0;
    }

    if (!visible) {
        return null;
    }

    return (
        <StyledDialog
            id="achievement-modal"
            visible
            aria-label="Achievement modal"
            onHide={handleHide}
            titleStyle={{
                backgroundColor: 'transparent',
            }}
            initialFocus="#achievement-modal-content"
        >
            <div id="achievement-modal-content">
                <CardContainer alignitems={'center'}>
                    {showRewardOnly ? (
                        <RewardCard achievement={achievementData.Achievement} />
                    ) : (
                        <>
                            <ArrowIcon onClick={handleLeftArrowClick}>arrow_left</ArrowIcon>
                            <AchievementCard
                                achievement={achievementCardData}
                                showLevelDoneInfo
                                onViewRewardClick={() => setShowRewardOnly(true)}
                            />
                            <ArrowIcon onClick={handleRightArrowClick}>arrow_right</ArrowIcon>
                        </>
                    )}
                </CardContainer>
            </div>
        </StyledDialog>
    );
};

export default AchievementModal;

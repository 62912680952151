import {
    CLEAR_STATE,
    GET_EXHIBITOR,
    GET_FULL_REPRESENTATIVES,
    SEND_EMAIL,
    SET_FRIENDSHIP_REQUEST,
    SET_PRIVATE_CHAT_USER_ID,
    SET_USERS_FRIENDS_LIST,
} from '../actions';

const INITIAL_STATE = {
    loading: false,
    error: null,
    exhibitor: null,
    fullRepresentatives: [],
    loadingFullRepresentatives: false,
    sendingEmail: false,
    isEmailSent: false,
    chatEnabled: true,
    privateChatUserId: null,
    currentUsersFriendsList: [],
    sendingFriendshipRequest: false,
};

const exhibitorBoothReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_EXHIBITOR.REQUEST:
            return { ...state, loading: true };
        case GET_EXHIBITOR.FAILURE:
            return { ...state, loading: false, error: payload.error };
        case GET_EXHIBITOR.SUCCESS: {
            const { exhibitor } = payload;
            const chatEnabled = exhibitor.interactivity.find(item => item.id === 'chat');

            return { ...state, loading: false, exhibitor, chatEnabled };
        }
        case GET_FULL_REPRESENTATIVES.REQUEST:
            return { ...state, loadingFullRepresentatives: true };
        case GET_FULL_REPRESENTATIVES.FAILURE:
            return { ...state, loadingFullRepresentatives: false, error: payload.error };
        case GET_FULL_REPRESENTATIVES.SUCCESS:
            return {
                ...state,
                loadingFullRepresentatives: false,
                fullRepresentatives: payload.fullRepresentatives,
            };
        case SEND_EMAIL.REQUEST:
            return { ...state, sendingEmail: true, isEmailSent: false };
        case SEND_EMAIL.FAILURE:
            return { ...state, sendingEmail: false, isEmailSent: false };
        case SEND_EMAIL.SUCCESS:
            return { ...state, sendingEmail: false, isEmailSent: true };
        case SET_PRIVATE_CHAT_USER_ID:
            return { ...state, privateChatUserId: payload.userId };
        case SET_USERS_FRIENDS_LIST:
            return { ...state, currentUsersFriendsList: payload.list };
        case SET_FRIENDSHIP_REQUEST:
            return { ...state, sendingFriendshipRequest: payload.sendingFriendshipRequest };
        case CLEAR_STATE:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};

export default exhibitorBoothReducer;

import { getItem } from '../../../services/api/graphQlRepository';

const getMenuPage = function (pageId, next) {
    getItem('pages', pageId, (err, page) => {
        if (page && page.params) {
            next(null, page);
        } else {
            next(null, null);
        }
    });
};
export { getMenuPage };

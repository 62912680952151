import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;
    width: 100%;
    height: 36px;
    padding: 8px;
    border-radius: 16px;
    border: none;
    font-size: 14px;
    background-color: #efefef;
    z-index: 10;
`;

const Input = styled.input`
    width: 100%;
    background-color: transparent;
    font-size: 14px;
    outline: none;
    border: none;
`;

const StyledIcon = styled(FontIcon)`
    color: #1e1e1f;
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
    ${props => props.clickable && 'cursor: pointer;'}
`;

const SearchBar = ({ value, setValue }) => {
    const onChange = ({ target }) => {
        const { value } = target;

        setValue(value);
    };

    const clearText = () => setValue('');

    return (
        <InputContainer>
            <StyledIcon>search</StyledIcon>
            <Input value={value} onChange={onChange} type="text" placeholder="Search" />
            {!!value && (
                <StyledIcon clickable onClick={clearText}>
                    close
                </StyledIcon>
            )}
        </InputContainer>
    );
};

export default SearchBar;

import React from 'react';

import * as palette from '../../../components/General/Variables';
import Button, { buttonTypes } from '../../common/Button';
import { MarginTop12px } from '../../common/Margin';
import { PadddingLeftRight16px } from '../../common/Padding';
import { FlexButtonsWrapper } from '../style';
import { TalkButton } from '../containers/TalkButton';
import { getString } from '../../../services/api/store';

const FriendRequestSentButtons = ({
    theme,
    onCancelRequest,
    scheduleMeeting,
    openChat,
    profileData,
}) => (
    <PadddingLeftRight16px>
        <Button
            type={buttonTypes.GREEN_LONG_REVERSE}
            background={theme.primary}
            text={getString('profileCancelContactRequest') || 'Cancel request'}
            icon={palette.ICON_REMOVE_PERSON}
            onClick={onCancelRequest}
            iconCustomColor={theme.primary}
        />
        <MarginTop12px />
        {profileData && profileData.chatAvailable && (
            <FlexButtonsWrapper>
                <Button
                    type={buttonTypes.GREY_LONG}
                    background={theme.primary}
                    text={getString('profileStartMeetingButton') || 'Meeting'}
                    icon={palette.ICON_ADD}
                    onClick={scheduleMeeting}
                    enabled={profileData.chatAvailable}
                />
                <TalkButton theme={theme} openChat={openChat} user={profileData} />
            </FlexButtonsWrapper>
        )}
    </PadddingLeftRight16px>
);

export default FriendRequestSentButtons;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, DialogContainer } from 'react-md';
import get from 'lodash/get';
import * as palette from '../../../components/General/Variables.js';
import { useGlobalMutation, useGlobalState } from '../../../utils/container';
import styled from 'styled-components';
import { notificationTypes } from '../../../scenes/Notifications/constants';
import NotificationService from '../../../scenes/Notifications/services/NotificationService';
import Auth from '../../../services/api/auth';

const ButtonStyled = styled(Button)`
    border: 1px solid ${props => props.primarycolor} !important;
    margin-left: 16px !important;
    margin-top: 27px !important;
    margin-bottom: 27px !important;
    border-radius: ${props => (props.theming === 'inversed' ? '2px' : '4px')} !important;
    color: ${props =>
        props.theming === 'inversed' ? props.primarycolor : palette.COLOR_WHITE} !important;
    background-color: ${props =>
        props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor} !important;
    display: block;

    &.md-btn--dialog,
    &.md-btn--text {
        text-transform: none;
        font-size: 16px;
        height: 36px;
        font-weight: 500;
        padding: 8px 16px;
    }

    &:hover {
        background-color: ${props =>
            props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor};
    }
`;

const DialogContainerStyled = styled(DialogContainer)`
    .md-dialog-content--padded {
        padding-bottom: 0;
    }
    .md-dialog-footer--inline {
        padding: 0 15px;
    }
`;

const Paragraph = styled.p`
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
`;

const VirtualRoomsNotificationsWrapper = ({ children, theme }) => {
    const mutationCtx = useGlobalMutation();
    const stateCtx = useGlobalState();
    const { showAlreadyInTheSessionModal, showCapacityModal } = stateCtx;
    const isAuthenticated = Auth.isUserAuthenticated();

    const location = useLocation();

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        const { socket } = stateCtx;

        if (!socket) {
            return;
        }

        const user = Auth.getUser();

        if (user) {
            const virtualEvent =
                location.pathname.indexOf('/virtual-session') > -1 ||
                location.pathname.indexOf('/virtual-session-room') > -1 ||
                location.pathname.indexOf('/virtual-moderation') > -1 ||
                location.pathname.indexOf('/video') > -1;

            socket.on(`messageNotification_${user.id}`, data => {
                if (!virtualEvent) {
                    NotificationService.handleNotification({
                        notificationType: notificationTypes.CHAT,
                        userId: data.senderId,
                        type: data.type,
                        roomId: data.roomId,
                    });
                }
            });
        }

        return () => {
            if (user && socket) {
                socket.removeAllListeners(`messageNotification_${user.id}`);
            }
        };
    }, [isAuthenticated, stateCtx.socket, location]);

    return (
        <React.Fragment>
            <DialogContainerStyled
                id="speed-boost"
                visible={showCapacityModal}
                title="Virtual room is at capacity"
                onHide={() => mutationCtx.setShowCapacityModal(false)}
                aria-describedby="speed-boost-description"
                actions={[
                    <ButtonStyled
                        key={`button-room-at-capacity`}
                        flat
                        primary
                        primarycolor={get(theme, 'primary')}
                        onClick={() => mutationCtx.setShowCapacityModal(false)}
                    >
                        OK
                    </ButtonStyled>,
                ]}
                width={'360px'}
                modal
                portal
            >
                <Paragraph>
                    This virtual room is at capacity at this moment. You will not be able to enter.
                </Paragraph>
            </DialogContainerStyled>
            <DialogContainerStyled
                id="speed-boost"
                visible={showAlreadyInTheSessionModal}
                title="You are already in the session"
                onHide={() => mutationCtx.setShowAlreadyInTheSessionModal(false)}
                aria-describedby="speed-boost-description"
                actions={[
                    <ButtonStyled
                        key={`button-already-in-session`}
                        flat
                        primary
                        primarycolor={get(theme, 'primary')}
                        onClick={() => mutationCtx.setShowAlreadyInTheSessionModal(false)}
                    >
                        OK
                    </ButtonStyled>,
                ]}
                width={'360px'}
                modal
                portal
            >
                <Paragraph>
                    An account with your ID is already in the session. You will not be able to
                    enter.
                </Paragraph>
            </DialogContainerStyled>
            {children}
        </React.Fragment>
    );
};

export default VirtualRoomsNotificationsWrapper;

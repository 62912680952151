import React, { useState } from 'react';
import styled from 'styled-components';
import { MoreVertFontIcon } from '@react-md/material-icons';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import HowToVoteOutlinedIcon from '@mui/icons-material/HowToVoteOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { List, ListItem, Divider } from 'react-md';
import withStyles from '@material-ui/core/styles/withStyles';
import { LightTooltip } from '../../virtualFeature/components/moderator/common/styles';

const DropdownWrapper = styled.div`
    position: relative;
`;

const StyledMoreVertIcon = styled(MoreVertFontIcon)`
    position: absolute;
    top: -10px;
    right: 0;
    max-height: 40px;
    height: 40px;
    max-width: 40px;
    width: 40px;
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

const StyledList = styled(List)`
    position: absolute;
    top: 32px;
    right: 0;
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    border-radius: 8px;
    z-index: 100;
`;

const StyledListItem = styled(ListItem)`
    .md-tile-content {
        padding: 0 4px !important;
    }

    & > * {
        .md-tile-addon {
            height: 24px !important;
        }
        .md-tile-addon--icon {
            height: 24px !important;
        }
    }

    .md-list-tile {
        padding: 0 16px !important;
        margin: 0 4px !important;
        height: 48px !important;
        border-radius: 4px !important;
        width: 232px !important;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }

    .md-btn--text {
        text-transform: none !important;
    }

    .md-tile-text--primary {
        font-family: Roboto, sans-serif;
        font-weight: 500;
        color: rba(0, 0, 0, 0.87);
        font-size: 15px;
    }

    &:disabled {
        cursor: default;
    }
`;

const iconStyle = {
    marginRight: 16,
    color: 'rgba(0, 0, 0, 0.6)',
};

const NarrowedLightTooltip = withStyles(() => ({
    tooltip: {
        maxWidth: '100px',
    },
}))(LightTooltip);

const Dropdown = ({
    id,
    onMoveUp,
    onMoveDown,
    onViewResults,
    onResetVotes,
    onEdit,
    onDelete,
    active,
    resultsNumber,
    index,
    isLastInList,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDeleteButtonHovered, setIsDeleteButtonHovered] = useState(false);
    const [isEditButtonHovered, setIsEditButtonHovered] = useState(false);

    const handleClick = e => {
        // Hide the dropdown menu when you click outside it
        const { id: targetId } = e.target;

        if (targetId !== id && targetId !== `${id}-icon`) {
            setIsExpanded(false);
        }
    };

    const addClickEventListener = () => window.addEventListener('click', handleClick, false);

    const removeClickEventListener = () => window.removeEventListener('click', handleClick, false);

    const toggleIsExpanded = e => {
        e.stopPropagation();
        if (!isExpanded) {
            addClickEventListener();
        } else {
            removeClickEventListener();
        }
        setIsExpanded(!isExpanded);
    };

    const clearHoverStates = () => {
        setIsDeleteButtonHovered(false);
        setIsEditButtonHovered(false);
    };

    return (
        <DropdownWrapper id={id} onClick={toggleIsExpanded}>
            <StyledMoreVertIcon id={`${id}-icon`} />
            {isExpanded && (
                <StyledList>
                    <NarrowedLightTooltip
                        open={active && isEditButtonHovered}
                        title="Active question cannot be edited."
                    >
                        <StyledListItem
                            primaryText="Edit"
                            onClick={onEdit}
                            disabled={active}
                            leftIcon={<CreateOutlinedIcon style={iconStyle} />}
                            onMouseEnter={() => {
                                setIsEditButtonHovered(true);
                                setIsDeleteButtonHovered(false);
                            }}
                            onMouseLeave={() => setIsEditButtonHovered(false)}
                        />
                    </NarrowedLightTooltip>
                    {resultsNumber > 0 && (
                        <StyledListItem
                            primaryText="Show results"
                            onClick={onViewResults}
                            leftIcon={<HowToVoteOutlinedIcon style={iconStyle} />}
                            onMouseEnter={clearHoverStates}
                        />
                    )}
                    {resultsNumber > 0 && (
                        <StyledListItem
                            primaryText="Clear results"
                            onClick={onResetVotes}
                            leftIcon={<RefreshOutlinedIcon style={iconStyle} />}
                            onMouseEnter={clearHoverStates}
                        />
                    )}
                    {index > 0 && (
                        <StyledListItem
                            primaryText="Move up"
                            onClick={onMoveUp}
                            leftIcon={<ArrowUpwardOutlinedIcon style={iconStyle} />}
                            onMouseEnter={clearHoverStates}
                        />
                    )}
                    {!isLastInList && (
                        <StyledListItem
                            primaryText="Move down"
                            onClick={onMoveDown}
                            leftIcon={<ArrowDownwardOutlinedIcon style={iconStyle} />}
                            onMouseEnter={clearHoverStates}
                        />
                    )}
                    <Divider />
                    <NarrowedLightTooltip
                        open={active && isDeleteButtonHovered}
                        title="Active question cannot be deleted."
                    >
                        <StyledListItem
                            primaryText="Delete"
                            onClick={onDelete}
                            leftIcon={<DeleteOutlinedIcon style={iconStyle} />}
                            disabled={active}
                            onMouseEnter={() => {
                                setIsDeleteButtonHovered(true);
                                setIsEditButtonHovered(false);
                            }}
                            onMouseLeave={() => setIsDeleteButtonHovered(false)}
                        />
                    </NarrowedLightTooltip>
                </StyledList>
            )}
        </DropdownWrapper>
    );
};

export default Dropdown;

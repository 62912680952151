const SCALING_MAP = {
    height: 1080,
    width: 1920,
};

const getDynamicScalingMap = scalingFactor => {
    if (scalingFactor === 'height') {
        return {
            height: 1920,
            width: 1080,
        };
    }

    return SCALING_MAP;
};

export default {
    getDynamicScalingMap,
};

import React from 'react';
import { Detector } from 'react-detect-offline';
import { Button } from 'react-md';
import styled from 'styled-components';
import connectionFailedImg from './assets/images/no-connection.png';
import * as palette from './components/General/Variables';

const LoaderContainer = styled.div`
    text-align: center;
    width: 100%;
    padding: 40px;
    padding-top: 200px;
    border-top: 1px solid #e5e5e5;

    @media only screen and (min-width: ${palette.MIN_TABLET}) {
        padding: 60px;
        padding-top: 200px;
    }

    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        padding: 25%;
        padding-top: 200px;
    }

    h4 {
        font-size: 18px;
        font-weight: 500;
        color: ${palette.COLOR_TEXT};
    }

    p {
        font-size: 14px;
        color: ${palette.COLOR_TEXT};
    }
`;

const NoConnectionImage = styled.div`
    background-image: url(${connectionFailedImg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    max-width: 500px;
    height: 500px;
    margin: auto;
`;

export default function (ComposedComponent) {
    return props => {
        return <ComposedComponent {...props} />;
        // return (
        //     <Detector
        //         render={({ online }) => (
        //             <>
        //                 <div className="mdl-layout mdl-js-layout" hidden={online}>
        //                     <LoaderContainer>
        //                         <NoConnectionImage />
        //                         <h4>{'No internet connection.'}</h4>
        //                         <p>{'Turn on WiFi or mobile data and reload this page.'}</p>
        //
        //                         <Button
        //                             raised
        //                             primary
        //                             onClick={() => {
        //                                 window.location.reload();
        //                             }}
        //                         >
        //                             RELOAD PAGE
        //                         </Button>
        //                     </LoaderContainer>
        //                 </div>
        //
        //                 {online && <ComposedComponent {...props} />}
        //             </>
        //         )}
        //     />
        // );
    };
}

import React, { createContext, useContext, useReducer, useEffect, useMemo } from 'react';
import get from 'lodash/get';

import { reducer, volumeReducer, defaultState } from '../reducers/main';
import useDevices from '../utils/useDevices';

const VMStateContext = createContext({});
const VMVolumeContext = createContext({});
const VMutationContext = createContext({});

export const ContainerProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const [volumeState, volumeDispatch] = useReducer(volumeReducer, defaultState);

    const applyUseDevices = useDevices;

    window.rootState = state;
    window.volumeState = volumeState;

    const methods = useMemo(() => {
        return {
            changeCamera(cameraId) {
                methods.updateConfig({ cameraId });
                methods.setRecreateStreams(true);
            },
            changeMicrophone(microphoneId) {
                methods.updateConfig({ microphoneId });
                methods.setRecreateStreams(true);
            },
            setRecreateStreams(value) {
                dispatch({ type: 'recreateStreams', payload: value });
            },
            stopRecording() {
                dispatch({ type: 'recording', payload: false });
            },
            updateConfig(params) {
                dispatch({
                    type: 'config',
                    payload: params,
                });
            },
            setSpeakerViewMode(mode) {
                dispatch({ type: 'speakerViewMode', payload: mode });
            },
            setVirtualEventUser(param) {
                dispatch({ type: 'virtualEventUser', payload: param });
            },
            setVirtualEventSession(param) {
                dispatch({ type: 'virtualEventSession', payload: param });
            },
            setExtraState(param) {
                dispatch({ type: 'extraState', payload: param });
            },
            setMutedBySystem(param) {
                dispatch({ type: 'mutedBySystem', payload: param });
            },
            setPdf(param) {
                dispatch({ type: 'pdf', payload: param });
            },
            setUrl(param) {
                dispatch({ type: 'url', payload: param });
            },
            setLoading(param) {
                dispatch({ type: 'loading', payload: param });
            },
            setCurrentUser(param) {
                dispatch({ type: 'currentUser', payload: param });
            },
            setLocalStream(param) {
                dispatch({ type: 'localStream', payload: param });
            },
            setCurrentStream(param) {
                dispatch({ type: 'currentStream', payload: param });
            },
            setSessionId(param) {
                dispatch({ type: 'sessionId', payload: param });
            },
            setTimeslot(param) {
                dispatch({ type: 'timeslot', payload: param });
            },
            clearAllStream() {
                dispatch({ type: 'clearAllStream' });
            },
            addLocal(evt) {
                const { stream } = evt;

                methods.setLocalStream(stream);
                methods.setCurrentStream(stream);
            },
            setScreenSharingStream(evt) {
                const { stream } = evt || {};

                dispatch({ type: 'screenShareStream', payload: stream });
            },
            setScreenSharingClient(client) {
                dispatch({ type: 'screenSharingClient', payload: client });
            },
            setShareType(type) {
                dispatch({ type: 'shareType', payload: type });
            },
            setShouldCalculatePosition(value) {
                dispatch({ type: 'shouldCalculatePosition', payload: value });
            },
            addPeer(evt) {
                const { uid } = evt;
                dispatch({ type: 'addPeer', payload: uid });
            },
            setPeers(peers) {
                dispatch({ type: 'setPeers', payload: peers });
            },
            removePeer(evt) {
                const { uid, reason } = evt;

                if (reason !== 'BecomeAudience') {
                    dispatch({ type: 'removePeer', payload: uid });
                }
            },
            addStream(evt) {
                const { stream } = evt;
                dispatch({ type: 'addStream', payload: stream });
            },
            removeStream(param) {
                dispatch({ type: 'removeStream', user: param });
            },
            removeStreamById(param) {
                dispatch({ type: 'removeStream', user: param });
            },
            connectionStateChanged(evt) {
                console.log(state);
                console.log(evt.curState);
            },
            startUsingDevices(params, newMicrophoneId, newCameraId, recreateStreams) {
                applyUseDevices()
                    .then(({ cameraList, microphoneList }) => {
                        const defaultMic = microphoneList[0];
                        const defaultCamera = cameraList[0];

                        methods.setExtraState({
                            cameraList,
                            microphoneList,
                        });

                        const cameraId = get(defaultCamera, 'deviceId');
                        const microphoneId = get(defaultMic, 'deviceId');

                        methods.updateConfig({
                            ...params,
                            cameraId: newCameraId ? newCameraId : cameraId,
                            microphoneId: newMicrophoneId ? newMicrophoneId : microphoneId,
                            host: !!cameraId || !!microphoneId,
                        });

                        if (newMicrophoneId || newCameraId || recreateStreams) {
                            methods.setRecreateStreams(true);
                        }

                        dispatch({
                            type: 'useDevices',
                            payload: true,
                        });
                    })
                    .catch(() => {});
            },
            startScreenSharing() {
                dispatch({ type: 'screenSharing', payload: true });
            },
            stopScreenSharing() {
                dispatch({ type: 'screenSharing', payload: false });
            },
            startPreRecording() {
                dispatch({ type: 'prerecording', payload: true });
            },
            stopPreRecording() {
                dispatch({ type: 'prerecording', payload: false });
            },
            startAudioRecording() {
                dispatch({ type: 'audioRecording', payload: true });
            },
            stopAudioRecording() {
                dispatch({ type: 'audioRecording', payload: false });
            },
            setIsRecordingAudio(param) {
                dispatch({ type: 'isRecordingAudio', payload: param });
            },
            setMyStream(param) {
                dispatch({ type: 'myStream', payload: param });
            },
            setShowToast(param) {
                dispatch({ type: 'showToast', payload: param });
            },
            setPublishShareStream(param) {
                dispatch({ type: 'publishShareStream', payload: param });
            },
            setTimer(value) {
                dispatch({ type: 'timer', payload: value });
            },
            startCanvasSharing(evt) {
                const { canvas } = evt;

                dispatch({ type: 'canvasSharing', payload: canvas });
            },
            stopCanvasSharing() {
                dispatch({ type: 'canvasSharing', payload: null });
            },
            setVolumeIndicators(streams) {
                volumeDispatch({ type: 'volumeIndicators', payload: { streams } });
            },
            setVideoClient(client) {
                dispatch({ type: 'videoClient', payload: client });
            },
            setVideoIsPlaying(param) {
                dispatch({ type: 'videoIsPlaying', payload: param });
            },
            // player
            setPrimaryVideoDuration(param) {
                dispatch({ type: 'primaryVideoDuration', payload: param });
            },
            setSecondaryVideoDuration(param) {
                dispatch({ type: 'secondaryVideoDuration', payload: param });
            },
            setSwitchingPreRecording(param) {
                dispatch({ type: 'switchingPreRecording', payload: param });
            },
            startPlaying() {
                dispatch({ type: 'playing', payload: true });
            },
            stopPlaying() {
                dispatch({ type: 'playing', payload: false });
            },
            startAudioPlaying() {
                dispatch({ type: 'audioPlaying', payload: true });
            },
            stopAudioPlaying() {
                dispatch({ type: 'audioPlaying', payload: false });
            },
            setPlayedSeconds(param) {
                dispatch({ type: 'playedSeconds', payload: param });
            },
            setAudioPlayedSeconds(param) {
                dispatch({ type: 'audioPlayedSeconds', payload: param });
            },
            setPrimaryPlayerRef(param) {
                dispatch({ type: 'primaryPlayerRef', payload: param });
            },
            setSecondaryPlayerRef(param) {
                dispatch({ type: 'secondaryPlayerRef', payload: param });
            },
            setAudioPlayerRef(param) {
                dispatch({ type: 'audioPlayerRef', payload: param });
            },
            setAudioDuration(param) {
                dispatch({ type: 'audioDuration', payload: param });
            },
            setRoundTableHostMode(param) {
                dispatch({ type: 'roundTableHostMode', payload: param });
            },
            setIsReviewingVideo(param) {
                dispatch({ type: 'isReviewingVideo', payload: param });
            },
            setIsReviewingAudio(param) {
                dispatch({ type: 'isReviewingAudio', payload: param });
            },
            // end player
            stopUsingDevices() {
                dispatch({
                    type: 'useDevices',
                    payload: false,
                });
            },
            setActivePoll(poll) {
                dispatch({
                    type: 'activePoll',
                    payload: poll,
                });
            },
            setSpeakerActivePoll(poll) {
                dispatch({
                    type: 'speakerActivePoll',
                    payload: poll,
                });
            },
            showProxyModal() {
                dispatch({
                    type: 'proxyModalVisible',
                    payload: true,
                });
            },
            hideProxyModal() {
                dispatch({
                    type: 'proxyModalVisible',
                    payload: false,
                });
            },
            showJoiningLoader() {
                dispatch({
                    type: 'joiningLoaderVisible',
                    payload: true,
                });
            },
            hideJoiningLoader() {
                dispatch({
                    type: 'joiningLoaderVisible',
                    payload: false,
                });
            },
            setEnableTCPProxy() {
                dispatch({
                    type: 'enableTCPProxy',
                    payload: true,
                });
            },
            setEventSpeakerPlaceholder(imageSrc) {
                dispatch({
                    type: 'eventSpeakerPlaceholder',
                    payload: imageSrc,
                });
            },
            setEventSliderPlaceHolder(imageSrc) {
                dispatch({
                    type: 'eventSliderPlaceholder',
                    payload: imageSrc,
                });
            },
            openMicModal() {
                dispatch({
                    type: 'micModal',
                    payload: true,
                });
            },
            hideMicModal() {
                dispatch({
                    type: 'micModal',
                    payload: false,
                });
            },
        };
    }, []);

    useEffect(() => {
        window.sessionStorage.setItem(
            'custom_storage',
            JSON.stringify({
                uid: state.config.uid,
                host: state.config.host,
                channelName: state.config.channelName,
                token: state.config.token,
                resolution: state.config.resolution,
            }),
        );
    }, [state]);

    return (
        <VMStateContext.Provider value={state}>
            <VMVolumeContext.Provider value={volumeState}>
                <VMutationContext.Provider value={methods}>
                    {/*<CustomizedSnackbar toasts={toasts} />*/}
                    {/*{state.loading ? <Loading /> : null}*/}
                    {children}
                </VMutationContext.Provider>
            </VMVolumeContext.Provider>
        </VMStateContext.Provider>
    );
};

export function useVMState() {
    return useContext(VMStateContext);
}

export function useVMVolumeState() {
    return useContext(VMVolumeContext);
}

export function useVMMutation() {
    return useContext(VMutationContext);
}

import async from 'async';
import { getItem } from '../../../services/api/graphQlRepository';

var getTabsElements = function (pageId, next) {
    async.waterfall(
        [
            function (callback) {
                //Get the tab page
                if (pageId) {
                    getItem('pages', pageId, callback);
                } else {
                    callback('no pageId found');
                }
            },
            function (page, callback) {
                if (page && page.params) {
                    callback(null, page.params);
                } else {
                    callback('no pageId found');
                }
            },
        ],
        function (err, result) {
            next(err, result);
        },
    );
};

export { getTabsElements };

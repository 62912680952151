import React, { useEffect } from 'react';
import { useSlate, ReactEditor } from 'slate-react';
import { Editor, Range } from 'slate';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Stack';
import Popper from '@mui/material/Popper';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import isUrl from 'is-url';
import { SECONDARY_DARK, COLOR_TEXT_LIGHT } from '../General/Variables';
import {
    unwrapLink,
    deleteLink,
    isLinkActive,
    insertLink,
    getActiveLink,
    updateLink,
} from './utils';
import TextField from '@mui/material/TextField';

const SimplePopper = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.modal,
}));

const LinkEditor = ({ targetSelection, setLink, newLink }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [edit, setEdit] = React.useState(false);
    const [url, setUrl] = React.useState('');
    const [name, setName] = React.useState('');
    const [error, setError] = React.useState('');
    const inputRef = React.useRef({
        url: '',
        name: '',
    });

    const editor = useSlate();

    const openUrl = () => {
        if (inputRef.current.url) {
            window.open(inputRef.current.url, '_blank', 'noopener,noreferrer');
        }
    };

    useEffect(() => {
        setError('');
        if (!targetSelection) {
            return;
        }

        if (newLink) {
            setEdit(true);
            const [node, path] = Editor.node(editor, targetSelection);
            const element = ReactEditor.toDOMNode(editor, node);
            setAnchorEl(element || null);
            setUrl('');
            inputRef.current.url = '';
            setName('');
            inputRef.current.name = '';
        } else if (isLinkActive(editor)) {
            setEdit(false);
            const [node, path] = getActiveLink(editor);
            const element = ReactEditor.toDOMNode(editor, node);
            const link = node;
            setAnchorEl(element || null);
            const { url } = link;
            if (url) {
                setUrl(url);
                inputRef.current.url = url;
            }
            setLink('link');
        } else {
            setEdit(false);
            setAnchorEl(null);
            setLink(null);
        }
    }, [targetSelection, newLink]);

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (!inputRef.current.url || !isUrl(inputRef.current.url)) {
                    setError('Set a valid url');
                    return;
                }

                if (newLink) {
                    setEdit(false);
                    insertLink(editor, inputRef.current.url, inputRef.current.name);
                } else {
                    const [node, path] = getActiveLink(editor);
                    updateLink(editor, path, inputRef.current.url);
                    setEdit(false);
                }
            }
        };

        if (edit) {
            document.addEventListener('keydown', keyDownHandler);
        } else {
            document.removeEventListener('keydown', keyDownHandler);
        }

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [edit, newLink]);

    const isCollapsed = targetSelection && Range.isCollapsed(targetSelection);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <SimplePopper
            id={id}
            open={open}
            anchorEl={anchorEl}
            onResize={undefined}
            onResizeCapture={undefined}
            placement="bottom-start"
        >
            <Card sx={{ bgcolor: 'background.paper', boxShadow: 2, mt: '8px' }}>
                {edit && (
                    <Grid direction="column" sx={{ p: '4px', width: '375px' }}>
                        {newLink && isCollapsed && (
                            <TextField
                                label={'Name'}
                                value={name}
                                onChange={event => {
                                    const value = event.target.value;
                                    setName(value);
                                    inputRef.current.name = value;
                                }}
                                disabled={false}
                            ></TextField>
                        )}

                        <TextField
                            label={'URL'}
                            value={url}
                            onChange={event => {
                                const value = event.target.value;
                                setUrl(value);
                                setError('');
                                inputRef.current.url = value;
                            }}
                            disabled={false}
                            error={error}
                        ></TextField>
                    </Grid>
                )}
                {!edit && (
                    <Stack direction="row" alignItems="center" spacing={0} sx={{ p: '0px' }}>
                        <Button
                            onClick={() => {
                                setEdit(true);
                            }}
                            sx={{ mx: 0.4, color: COLOR_TEXT_LIGHT }}
                            size="small"
                        >
                            Edit link
                        </Button>
                        <Divider
                            flexItem
                            variant="middle"
                            orientation="vertical"
                            sx={{
                                borderColor: SECONDARY_DARK,
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                openUrl();
                            }}
                            sx={{ mx: 0.4 }}
                            aria-label="delete"
                            size="small"
                        >
                            <LaunchIcon fontSize="inherit" />
                        </IconButton>
                        <Divider
                            flexItem
                            variant="middle"
                            orientation="vertical"
                            sx={{
                                borderColor: SECONDARY_DARK,
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                setLink(null);
                                unwrapLink(editor);
                                setAnchorEl(null);
                            }}
                            sx={{ mx: 0.4 }}
                            aria-label="delete"
                            size="small"
                        >
                            <LinkOffIcon fontSize="inherit" />
                        </IconButton>
                        <Divider
                            flexItem
                            variant="middle"
                            orientation="vertical"
                            sx={{
                                borderColor: SECONDARY_DARK,
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                deleteLink(editor);
                            }}
                            sx={{ mx: 0.4 }}
                            aria-label="delete"
                            size="small"
                        >
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </Stack>
                )}
            </Card>
        </SimplePopper>
    );
};

export default LinkEditor;

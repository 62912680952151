import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';

import * as palette from '../../../../components/General/Variables';
import AuthUserContext from '../../../../components/Session/AuthUserContext';
import Header from '../../components/common/Header';
import Footer from '../../components/virtualRoom/Footer';
import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import {
    getPeerList,
    getVirtualEventSessionByAppointmentReference,
    getVirtualEventToken,
    removeVirtualEventToken,
    updateVirtualEventUser,
} from '../../../../services/api/eureka';
import Auth from '../../../../services/api/auth';
import { ContentContainer, Wrapper } from '../../styles';
import useStreamHandling from '../../hooks/useStreamHandling';
import { ConfirmDialog } from '../../../../components/Dialog';
import { goBackFromVirtualSession } from '../../components/common/goBack';
import WarningDialog from '../../../../components/Dialog/WarningDialog';
import OtherUserContent from './otherUserContent/OtherUserContent';
import CurrentUserContent from './currentUserContent/CurrentUserContent';
import VirtualWrapper from '../common/VirtualWrapper';
import {
    AchievementType,
    useAchievementActions,
} from '../../../Achievements/hooks/useAchievementActions';
import { getStreamConfiguration, isEncodingTheSame } from '../../../../utils/streamUtils';
import { getLocalAppStateAsync } from '../../../../services/api/db';
import LoadingVirtualPage from '../common/LoadingVirtualPage';

const VirtualRoomSession = props => {
    const { authUser, match, history, location } = props;
    const { timeslotId } = match.params;
    const [appointment, setAppointment] = useState(null);
    const mobile = (window.innerWidth < palette.MIN_TABLET_INT).toString();
    const virtualEventUserInfo = useRef(null);
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const mutationCtx = useGlobalMutation();
    const [widthPaddingTop, setWidthPaddingTop] = useState(false);

    const {
        currentVirtualEventUser,
        localStream,
        virtualEventSession,
        virtualEventUser,
        showScreenSharingModal,
    } = stateCtx;
    const { trackAchievement } = useAchievementActions();

    useStreamHandling(true);

    const user = Auth.getUser();

    const updateData = async data => {
        const { virtualEventSession } = data;

        if (!virtualEventSession || !virtualEventSession.VirtualEventUsers) {
            return false;
        }

        const virtualEventUserActive = virtualEventSession.VirtualEventUsers.find(
            virtualUser => virtualUser.UserId !== user.id,
        );

        mutationCtx.setVirtualEventSession(virtualEventSession);

        if (virtualEventUserInfo.current && virtualEventUserInfo.current.id) {
            const currentUser = virtualEventSession.VirtualEventUsers.find(
                user => user.id === virtualEventUserInfo.current.id,
            );
            const updatedUser = {
                ...virtualEventUserInfo.current,
                isMicrophoneOn: currentUser.isMicrophoneOn,
                isVideoOn: currentUser.isVideoOn,
            };
            mutationCtx.setCurrentVirtualEventUser(updatedUser);
            virtualEventUserInfo.current = updatedUser;
        }

        if (virtualEventUserActive) {
            mutationCtx.setVirtualEventUser(virtualEventUserActive);
        } else {
            mutationCtx.setVirtualEventUser(null);
        }

        return true;
    };

    const handlePeers = async () => {
        const peersResponse = await getPeerList(timeslotId);

        mutationCtx.setPeers(peersResponse.peers);
    };

    useEffect(() => {
        (async () => {
            const virtualEventTokenResponse = await getVirtualEventToken({
                appointmentReference: timeslotId,
                role: 'SUBSCRIBER',
            });
            const virtualEventToken = virtualEventTokenResponse.virtualEventToken;
            const appID = virtualEventTokenResponse.appID;

            try {
                const virtualEventSession = await getVirtualEventSessionByAppointmentReference(
                    timeslotId,
                );
                const currentUser = virtualEventSession.VirtualEventUsers.find(
                    virtualUser => virtualUser.UserId === user.id,
                );

                const currentVirtualEvenUser = await updateVirtualEventUser({
                    id: currentUser.id,
                    isMicrophoneOn: true,
                    isVideoOn: true,
                });

                // state.virtualEventUser is the active user (Host) so we store the current user here
                const currentVirtualEventUserInfo = {
                    ...currentVirtualEvenUser,
                    tokenId: virtualEventToken.id,
                    timeslotId,
                };

                setAppointment({
                    ...virtualEventSession.Appointment,
                    type: 'appointment',
                });

                mutationCtx.setExtraState({
                    canJoin: true,
                    currentVirtualEventUser: currentVirtualEventUserInfo,
                });

                virtualEventUserInfo.current = currentVirtualEventUserInfo;
                const appState = await getLocalAppStateAsync();
                if (appState.navigationType && appState.navigationType === 'sideMenu') {
                    setWidthPaddingTop(true);
                }

                const { socket } = stateCtx;

                if (!socket) {
                    return;
                }

                socket.emit('updateData', { objectId: timeslotId });
                socket.emit(
                    'joinSession',
                    {
                        userId: virtualEventUserInfo.current.UserId,
                        sessionId: timeslotId,
                    },
                    peers => mutationCtx.setPeers(peers),
                );
            } catch (err) {
                console.log('Error registering token and user' + err);
            }
            const config = {
                appID,
                token: virtualEventToken.accessToken,
                channelName: timeslotId,
                uid: virtualEventToken.UserId,
            };

            mutationCtx.startUsingDevices(config);
        })();
    }, [timeslotId]);

    useEffect(() => {
        (async () => {
            const virtualEventSession = await getVirtualEventSessionByAppointmentReference(
                timeslotId,
            );

            if (!virtualEventSession) {
                return;
            }

            await updateData({ virtualEventSession });

            const { socket } = stateCtx;

            if (!socket) {
                return;
            }

            socket.on(`updateData_${timeslotId}`, updateData);
            socket.on(`refreshPeers_${timeslotId}`, handlePeers);
        })();

        const cleanup = async () => {
            const { socket } = stateCtx;

            window.removeEventListener('beforeunload', cleanup);

            if (socket) {
                socket.emit('leaveSession', {
                    objectId: timeslotId,
                });
                socket.removeAllListeners(`updateData_${timeslotId}`);
                socket.removeAllListeners(`refreshPeers_${timeslotId}`);
            }

            const { tokenId } = virtualEventUserInfo.current;
            if (tokenId) {
                await removeVirtualEventToken({ tokenId });
            }
        };

        window.addEventListener('beforeunload', cleanup);

        return async () => {
            await cleanup();
        };
    }, []);

    useEffect(() => {
        if (location.state && location.state.keepHistoryLength) {
            history.replace(location.pathname, {
                ...location.state,
                keepHistoryLength: false,
            });
            sessionStorage.setItem(timeslotId + '_history_index', JSON.stringify(history.length));
        }
    }, [timeslotId]);

    useEffect(() => {
        trackAchievement(AchievementType.JOIN_VIRTUAL_SESSION, timeslotId);
    }, [trackAchievement]);

    useEffect(() => {
        if (!localStream || !localStream.videoTrack) {
            return;
        }

        const encoderConfiguration = getStreamConfiguration({ forceHighStream: true });

        if (!isEncodingTheSame(encoderConfiguration, localStream.videoTrack._encoderConfig)) {
            localStream.setEncoderConfiguration(encoderConfiguration);
        }
    }, [localStream]);

    const goBack = () => goBackFromVirtualSession(match, history, location, timeslotId);

    if (!virtualEventSession || !appointment) {
        return <LoadingVirtualPage />;
    }

    if (virtualEventSession && !virtualEventUser) {
        const options = {
            title: 'Access forbidden',
            text: 'You are not allowed to join this virtual room.',
            confirmText: 'Leave room',
            cancelText: 'Cancel',
            confirm: () => {
                goBack();
            },
            cancel: () => {},
        };
        return <ConfirmDialog visible={true} options={options} onHideDialog={goBack} />;
    }

    return (
        <VirtualWrapper
            timeslotId={timeslotId}
            virtualEventSession={virtualEventSession}
            virtualEventUser={currentVirtualEventUser}
        >
            <Wrapper mobile={mobile} widthpaddingtop={widthPaddingTop}>
                <Header goBack={goBack} timeslot={appointment} authUser={authUser} />
                <ContentContainer withFooter>
                    <OtherUserContent />
                    <CurrentUserContent />
                </ContentContainer>
                {virtualEventUserInfo.current && (
                    <Footer
                        updateVirtualEventUser={updateVirtualEventUser}
                        socket={socket}
                        currentVirtualEventUser={virtualEventUserInfo.current}
                        goBack={goBack}
                    />
                )}
                <WarningDialog
                    open={showScreenSharingModal}
                    title="Can't share your screen"
                    onClose={() => mutationCtx.setShowScreenSharingModal(false)}
                    content={
                        <div>
                            Your browser might not have permission to use screen recording on your
                            computer. To grant permission, open System Preferences.
                        </div>
                    }
                />
            </Wrapper>
        </VirtualWrapper>
    );
};

const ConsumerWrapper = props => {
    return (
        <AuthUserContext.Consumer>
            {authUser => <VirtualRoomSession {...props} authUser={authUser} />}
        </AuthUserContext.Consumer>
    );
};

export default withRouter(ConsumerWrapper);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListItem } from 'react-md';

import { getLocalTimeAgo } from '../common';
import { selectCommentById } from '../selectors';
import Auth from '../../../services/api/auth';
import { ImageAvatar } from '../../../components/Icons';

const StyledListItem = styled(ListItem)`
    list-style-type: none;
    margin: 0;

    .md-list-tile--two-lines {
        padding: 10px;
        transition: max-height 1s linear;
        height: ${props => (props.expanded ? 'auto' : '60px')};
    }

    .md-tile-text--secondary {
        ${props =>
            props.expanded &&
            `
        display: unset;
        overflow: visible;
        text-overflow: unset;
        white-space: unset;
      `}
    }
`;

const TitleContent = styled.div`
    display: flex;
    align-items: flex-end;
    font-family: 'Cabin', sans-serif;
`;

const NameLabel = styled.div`
    font-size: 14px;
`;

const TimeLabel = styled.div`
    font-size: 10px;
    margin-left: 8px;
    color: gray;
`;

const CommentItem = ({ comment, currentUserId }) => {
    const [isExpanded, setIsexpanded] = useState(false);
    const { user } = comment;
    const ago = getLocalTimeAgo(comment.time);
    const name = `${user.firstName} ${user.lastName}`;

    const toggleIsExpanded = () => setIsexpanded(!isExpanded);

    return (
        <StyledListItem
            expanded={isExpanded}
            primaryText={
                <TitleContent>
                    <NameLabel>{name}</NameLabel>
                    <TimeLabel>{ago}</TimeLabel>
                </TitleContent>
            }
            secondaryText={comment.message}
            leftAvatar={
                <ImageAvatar imageId={user.imageUrl || user.image} avatarType={'persons'} />
            }
            onClick={toggleIsExpanded}
        />
    );
};

CommentItem.propTypes = {
    comment: PropTypes.shape({
        user: PropTypes.shape({
            lastName: PropTypes.string,
            firstName: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        time: PropTypes.string,
        message: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    currentUserId: PropTypes.number,
};

CommentItem.defaultProps = {
    comment: {},
    currentUserId: null,
};

const mapStateToProps = (state, ownProps) => ({
    comment: selectCommentById(ownProps.id, state),
    currentUserId: Auth.getUser().id,
});

export default connect(mapStateToProps, {})(CommentItem);

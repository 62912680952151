import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { List } from 'react-md';
import styled from 'styled-components';
import ObjectListItem from '../../../components/ObjectListItem';
import NavigationLink, { navigateTo } from '../../../components/Navigation/NavigationLink';
import GroupButton from './groupButton';
import SearchResultsContext from '../../../components/SearchResults/SearchResultsContext';
import { getString } from '../../../services/api/store';
import Auth from '../../../services/api/auth';

const Scrollable = styled.section.attrs({
    className: '',
})`
    height: 100%;
    overflow: auto;
`;

const SectionTitle = styled.div`
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin: 16px;
    font-family: 'Cabin', sans-serif;
`;

const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;
`;

const ListStyled = styled(List)`
    width: 100%;
    max-width: ${() => window.innerWidth}px;
`;

const SearchList = ({
    results,
    pageId,
    match,
    history,
    location,
    limit,
    hiddenSectionTitle,
    childrenId,
}) => {
    const [listItems, setListItems] = useState([]);
    const isAuthenticated = Auth.isUserAuthenticated();
    const VIEW_ALL = getString('searchPageSeeAllResultsPrefix', 'View all');

    const ListItem = ({ type, item }) => (
        <span>
            <ObjectListItem item={item} type={type} childrenId={childrenId} />
        </span>
    );

    useEffect(() => {
        if (isEmpty(results)) {
            return;
        }
        const listComponents = [];

        forEach(results, sectionResults => {
            const queryType = get(sectionResults, 'listQuery', 'timeslot');
            const target = get(sectionResults, 'type.target', 'timeslot');
            const title = get(sectionResults, 'type.plural', '');

            const itemType = target.toLowerCase();
            const showClickBooth = get(sectionResults, 'type.params.showClickBooth', false);

            const timeArray = [];
            for (let index = 0; index < sectionResults.items.length; index++) {
                const it = sectionResults.items[index];
                const item = it;

                if (limit && index >= limit) {
                    // if section has too many results, we group them and display a "load all" button
                    const groupButton = (
                        <SearchResultsContext.Consumer key={`link_${index}_${item.id}`}>
                            {({ setResultsData }) => (
                                <GroupButton
                                    id={index}
                                    label={`${VIEW_ALL} ${
                                        sectionResults.items.length
                                    } ${title.toLowerCase()}`}
                                    onClick={() => {
                                        setResultsData([
                                            {
                                                type: sectionResults.type,
                                                items: sectionResults.items,
                                            },
                                        ]);
                                        navigateTo({
                                            match,
                                            history,
                                            location,
                                            params: {
                                                pageType: 'searchResults',
                                                pageId,
                                            },
                                        });
                                    }}
                                />
                            )}
                        </SearchResultsContext.Consumer>
                    );
                    listComponents.push(groupButton);
                    break;
                }

                let navPath = null;
                let navParams = {
                    type: 'detail',
                    objectClass: itemType,
                    objectId: item.id,
                };

                if (queryType === 'findWebpages' || itemType === 'webpage') {
                    navPath = null;
                    navParams = {
                        pageType: 'webpage',
                        pageId: item.id,
                    };
                }
                if (itemType === 'person' && item.eurekaId && isAuthenticated) {
                    navPath = null;
                    navParams = {
                        type: 'profile',
                        userId: item.eurekaId,
                        personLinkedId: item.id,
                    };
                }

                if (showClickBooth) {
                    navPath = `/exhibitorbooth/${item.id}`;
                    navParams = null;
                }

                const listItem = (
                    <div key={item.id}>
                        {index === 0 && !hiddenSectionTitle && (
                            // Renders section title on first items of each type
                            <SectionTitle key={item.id}>{title}</SectionTitle>
                        )}
                        <LinkStyled
                            key={`link_${index}_${item.id}`}
                            path={navPath}
                            params={navParams}
                        >
                            <ListItem type={itemType} item={item} />
                        </LinkStyled>
                    </div>
                );
                listComponents.push(listItem);
            }
        });
        setListItems(listComponents);
    }, [results]);

    const renderList = () => {
        return <ListStyled className="">{listItems}</ListStyled>;
    };

    return <Scrollable>{renderList()}</Scrollable>;
};

export default withRouter(SearchList);

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { debounce } from 'lodash';
import { Avatar, FontIcon, ListItem } from 'react-md';

import {
    block,
    getBySessionId,
    unblock,
} from '../../../../services/VirtualEventBlockedUsersService';
import {
    getAttendeesByEventIdPaginated,
    getFullProfileById,
} from '../../../../services/AttendeesService';

import Dropdown from '../../../common/Dropdown';
import ConfirmationDialog from '../../../common/ConfirmatonDialog';
import VisibilityToaster from '../common/VisibilityToaster';
import { useVMState } from '../../../../containers/main';
import SearchBar from '../../../common/Dialog/SearchBar';
import { ContentModalContainer } from '../../common/styles';

const Wrapper = styled.div`
    width: 100%;
    list-style-type: none;
    overflow: hidden;
    position: relative;
    height: ${props =>
        props.isfirefox && props.windowHeight
            ? 'calc(100vh - ' + (props.windowHeight < 700 ? '400px)' : '580px)')
            : '100%'};
`;

const ContentWrapper = styled.div`
    height: 100%;
    overflow: hidden;
    position: relative;
`;

const Container = styled.div`
    width: 100%;
    list-style-type: none;
    height: 100%;
    overflow: auto;
`;

const CustomAvatar = styled(Avatar)`
    background-color: rgba(97, 97, 97);
    border: 0px solid rgba(0, 0, 0);
    ${props =>
        props.blocked &&
        `
        .md-avatar-content {
            background: linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              ), #000;
            color: rgba(300, 300, 300, 0.5) !important;
        }
    `}
`;

const Item = styled(ListItem)`
    height: 56px;

    & .md-list-tile--avatar {
        height: 56px;
    }

    & .md-avatar {
        border: none;
        cursor: default;
    }

    & .md-text--disabled {
        color: rgba(0, 0, 0, 0.87) !important;
        cursor: default;
    }

    & .md-tile-content {
        width: 10px;
    }

    & .md-list-tile {
        border-radius: 8px;
    }

    & .md-list-tile--active {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

const ActionWrapper = styled.div`
    position: relative;
`;

const VisibilityToasterContainer = styled.div`
    display: flex;
    width: 100%;
`;

const ItemsContainer = styled.div`
    ${props => props.applymargin && 'margin-top: 44px;'};
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${props => props.sticky && 'position: absolute;'};
    width: 100%;
    padding-bottom: 8px;
    background-color: #fff;
    z-index: 10;
`;

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    display: block;
    margin-top: 8px;
    text-align: left;
`;

const ModalContentText = styled.span`
    font-family: Roboto, sans-serif;
    align-items: flex-start;
    font-size: 15px !important;
    font-weight: ${props => (props.bold ? '700' : '400')} !important;
    line-height: 1.6 !important;
    color: rgba(0, 0, 0, 0.87) !important;
`;

const MINIMUM_SEARCH_CHAR_COUNT = 3;

const Attendees = ({
    sortedAttendees,
    controls,
    limit,
    totalAttendees,
    setShowBlockModal,
    showBlockModal,
    externalIsFetching,
    externalPage,
    updateAttendeeList,
}) => {
    const stateCtx = useVMState();
    const { user, socket, sessionId, virtualEventSession } = stateCtx;
    const { showBlockedAttendees } = virtualEventSession.data;

    const [userToBlock, setUserToBlock] = useState(null);
    const [attendees, setAttendees] = useState(sortedAttendees);
    const [isFetching, setIsFetching] = useState(externalIsFetching);
    const [page, setPage] = useState(externalPage);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [blockedAttendees, setBlockedAttendees] = useState([]);
    const [showEditButton, setShowEditButton] = useState(null);
    const [filter, setFilter] = useState('');
    const [displayOnlyBlockedUsers, setDisplayOnlyBlockedUsers] = useState(false);

    const isAttendeeTabEnabled = get(virtualEventSession, 'data.showAttendees') || false;
    const scroll = useRef();
    const isFirefox = typeof InstallTrigger !== 'undefined';

    const handleResize = debounce(() => {
        setWindowHeight(window.innerHeight);
    }, 500);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        getBlockedAttendees();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!isFetching) return;

        getAttendees();
    }, [isFetching]);

    const checkForMatch = (array, propertyToMatch, valueToMatch) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i][propertyToMatch] === valueToMatch) {
                return true;
            }
        }
        return false;
    };

    const getBlockedAttendees = async () => {
        const response = await getBySessionId(virtualEventSession.data.id);
        const { virtualEventBlockedUsers } = response;
        let blockedUsers = await Promise.all(
            virtualEventBlockedUsers.map(async user => {
                if (user && user.UserId) {
                    return {
                        ...(await getFullProfileById(user.UserId)),
                        blocked: true,
                        userId: user.UserId,
                    };
                }
            }),
        );
        blockedUsers = blockedUsers.filter(item => item && item.id);
        setBlockedAttendees(getSortedAttendees(blockedUsers));
    };

    const filterAttendees = attendee => {
        const fullName = `${attendee.lastName}${attendee.firstName}`.toLowerCase();

        if (filter.length < MINIMUM_SEARCH_CHAR_COUNT) {
            return true;
        }
        if (fullName.includes(filter)) {
            return true;
        }
        if (attendee.companyName && attendee.companyName.toLowerCase().includes(filter)) {
            return true;
        }
        if (attendee.jobTitle && attendee.jobTitle.toLowerCase().includes(filter)) {
            return true;
        }

        return false;
    };

    let newAttendees = attendees.filter(filterAttendees);

    if (newAttendees) {
        newAttendees.forEach((item, index) => {
            if (checkForMatch(blockedAttendees, 'userId', item.userId)) {
                newAttendees.splice(index, 1);
            }
        });
    }
    const blockedUsers = showBlockedAttendees ? blockedAttendees.filter(filterAttendees) : [];
    const attendeesToDisplay = displayOnlyBlockedUsers ? blockedUsers : newAttendees;
    const isAtLeastOneBlockedAttendee = blockedAttendees && !!blockedAttendees.length;

    const content = () => (
        <ContentWrapper>
            <Container innerRef={scroll} onScroll={handleScroll}>
                <FlexRow sticky={filter ? 1 : 0}>
                    <SearchBar value={filter} setValue={setFilter} />
                    {isAtLeastOneBlockedAttendee && (
                        <Dropdown
                            buttonText={displayOnlyBlockedUsers ? 'Blocked' : 'Online'}
                            items={[
                                {
                                    text: 'Online',
                                    onClick: () => setDisplayOnlyBlockedUsers(false),
                                    rightIcon: !displayOnlyBlockedUsers ? 'check' : null,
                                },
                                {
                                    text: 'Blocked',
                                    onClick: () => setDisplayOnlyBlockedUsers(true),
                                    rightIcon: displayOnlyBlockedUsers ? 'check' : null,
                                },
                            ]}
                        />
                    )}
                </FlexRow>
                <ItemsContainer applymargin={filter ? 1 : 0}>
                    {attendeesToDisplay.map(attendee => (
                        <Item
                            onMouseEnter={() => setShowEditButton(attendee)}
                            onMouseLeave={() => setShowEditButton(null)}
                            key={`attendee-list-item-${attendee.userId}`}
                            disabled={!controls}
                            leftAvatar={
                                attendee.imageUrl ? (
                                    <Avatar src={attendee.imageUrl} />
                                ) : (
                                    <CustomAvatar blocked={attendee.blocked ? 1 : 0}>
                                        {getInitials(attendee.firstName, attendee.lastName)}
                                    </CustomAvatar>
                                )
                            }
                            rightIcon={
                                controls &&
                                showEditButton &&
                                showEditButton.userId === attendee.userId ? (
                                    <ActionWrapper>
                                        {user.id !== attendee.userId && (
                                            <Dropdown
                                                items={[
                                                    {
                                                        text: `${
                                                            attendee.blocked ? 'Unblock' : 'Block'
                                                        } user`,
                                                        rightIcon: `${
                                                            attendee.blocked
                                                                ? 'remove_circle_outline'
                                                                : 'block'
                                                        }`,
                                                        onClick: () => setUserToBlock(attendee),
                                                    },
                                                ]}
                                            />
                                        )}
                                    </ActionWrapper>
                                ) : (
                                    attendee.blocked && (
                                        <FontIcon
                                            style={{
                                                fontSize: '24px',
                                                color: 'rgba(0, 0, 0, 0.87)',
                                            }}
                                        >
                                            block
                                        </FontIcon>
                                    )
                                )
                            }
                            primaryText={`${attendee.firstName} ${attendee.lastName}`}
                            secondaryText={
                                attendee.jobTitle && attendee.companyName
                                    ? `${attendee.jobTitle}, ${attendee.companyName}`
                                    : attendee.jobTitle || attendee.companyName || ''
                            }
                        />
                    ))}
                </ItemsContainer>
                {isFetching && attendees.length < totalAttendees && 'Loading more items...'}
            </Container>
        </ContentWrapper>
    );

    const getAttendees = async () => {
        if (isFetching) {
            if (page < totalAttendees / limit) {
                setTimeout(async () => {
                    const newAttendees = await getAttendeesByEventIdPaginated(
                        sessionId,
                        limit,
                        page + 1,
                    );
                    const attendeeList = attendees.concat(newAttendees.rows);
                    const sortedAttendeeList = getSortedAttendees(attendeeList);
                    setAttendees(sortedAttendeeList);
                    setIsFetching(false);
                    setPage(page + 1);
                    updateAttendeeList(sortedAttendeeList, page + 1);
                }, 3000);
            }
        } else {
            const attendees = await getAttendeesByEventIdPaginated(sessionId, limit, page);
            const sortedAttendeeList = getSortedAttendees(attendees.rows);
            setAttendees(sortedAttendeeList);
            setIsFetching(false);
        }
    };

    const getSortedAttendees = attendeeList => {
        const sortedAttendeeList = attendeeList.sort((attendee1, attendee2) => {
            const name1 = `${attendee1.lastName} ${attendee1.firstName}`;
            const name2 = `${attendee2.lastName} ${attendee2.firstName}`;
            return name1.toLowerCase().localeCompare(name2.toLowerCase());
        });
        return sortedAttendeeList;
    };

    const getInitials = (firstName, lastName) =>
        firstName[0].toUpperCase() + lastName[0].toUpperCase();

    const blockUnblockUser = async user => {
        const { userId, blocked } = user;

        if (blocked) {
            await unblock({
                userId,
                sessionId: virtualEventSession.data.id,
            });
            if (blockedAttendees.length === 1) {
                setDisplayOnlyBlockedUsers(false);
                setFilter('');
            }
        } else {
            await block({
                userId,
                sessionId: virtualEventSession.data.id,
            });
            if (showBlockModal === false) {
                setShowBlockModal(true);
            }
        }

        getBlockedAttendees();
        setUserToBlock(null);

        socket.emit('blockUser', { userId });
        socket.emit('refreshAttendees', { sessionId });
    };

    const getInnerHeight = elm => {
        const computed = getComputedStyle(elm),
            padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);

        return elm.clientHeight - padding;
    };

    const handleScroll = () => {
        if (
            scroll?.current &&
            (getInnerHeight(scroll.current) + parseInt(scroll.current.scrollTop) !==
                scroll.current.scrollHeight ||
                isFetching)
        ) {
            return;
        }

        setIsFetching(true);
    };

    const changeVisibilityOfAttendees = async () => {
        stateCtx.socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                status: virtualEventSession.data.status,
                showBlockedAttendees: true,
            },
        });
        setShowBlockModal(null);
    };
    return (
        <Wrapper isfirefox={isFirefox ? 1 : undefined} windowHeight={windowHeight}>
            {attendees.length === 0 && <div>There are no attendees</div>}

            {content()}
            {!isAttendeeTabEnabled && (
                <VisibilityToasterContainer>
                    <VisibilityToaster />
                </VisibilityToasterContainer>
            )}
            {showBlockModal && (
                <ConfirmationDialog
                    open={true}
                    title="Blocked users"
                    content={`Blocked users are now shown above the attendee list.
                    You can hide all blocked users from the list in the session settings,
                    that can be accessed through the gear icon above this list.`}
                    onOk={changeVisibilityOfAttendees}
                    withCloseButton
                    onClose={() => setShowBlockModal(false)}
                />
            )}

            {userToBlock && (
                <ConfirmationDialog
                    open={true}
                    title={`${userToBlock.blocked ? 'Unblock' : 'Block'} user`}
                    content={
                        <ExtraStyledModalContentContainer>
                            {userToBlock.blocked ? (
                                <>
                                    <ModalContentText>
                                        Are you sure you want to unblock
                                    </ModalContentText>
                                    <ModalContentText
                                        bold
                                    >{` ${userToBlock.firstName} ${userToBlock.lastName}`}</ModalContentText>
                                    <ModalContentText>
                                        ? The unblocked user will appear in attendee list only when
                                        he will join the session again.
                                    </ModalContentText>
                                </>
                            ) : (
                                <>
                                    <ModalContentText>
                                        Are you sure you want to block
                                    </ModalContentText>
                                    <ModalContentText
                                        bold
                                    >{` ${userToBlock.firstName} ${userToBlock.lastName}?`}</ModalContentText>
                                </>
                            )}
                        </ExtraStyledModalContentContainer>
                    }
                    withCloseButton
                    onYes={() => blockUnblockUser(userToBlock)}
                    onNo={() => setUserToBlock(null)}
                    onClose={() => setUserToBlock(null)}
                />
            )}
        </Wrapper>
    );
};

export default Attendees;

import React from 'react';
import io from 'socket.io-client';

import SocketContext from './SocketContext';
import { EUREKA_URL } from '../../config';
import { populateDefaultState } from '../../utils/store';

const withSocket = Component =>
    class WithSocket extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                socket: null,
            };
        }

        componentDidMount() {
            (() => {
                const socket = io(EUREKA_URL, {
                    autoConnect: true,
                    reconnectionAttempts: 100,
                    transports: ['websocket', 'polling'],
                });

                socket.on('disconnect', reason => {
                    if (reason === 'io server disconnect') {
                        // the disconnection was initiated by the server, you need to reconnect manually
                        socket.connect();
                    }
                    // else the socket will automatically try to reconnect
                });

                socket.on('connect_error', () => {
                    // maybe internet connection lost or other, so making sure we reconnect
                    setTimeout(() => socket.connect(), 500);
                });

                /**
                 * We make sure that the socket connection is maintained in all cases.
                 * This is just if the above solutions are not working
                 */
                function checkConnection() {
                    if (!socket.connected) {
                        // try to connect again
                        socket.connect();
                    }
                }

                setInterval(checkConnection, 3000);

                populateDefaultState({ socket });

                this.setState({ socket });
            })();
        }

        render() {
            const { socket } = this.state;

            return (
                <SocketContext.Provider value={{ socket }}>
                    <Component />
                </SocketContext.Provider>
            );
        }
    };

export default withSocket;

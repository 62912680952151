import { actionTypes } from '../actions';

const initialState = {
    isGamificationEnabled: undefined,
    achievementTypes: [],
    isLoadingAchievementTypes: false,
    achievementTypesLoaded: false,
    loadAchievementTypesError: null,
    achievements: [],
    isLoadingAchievements: false,
    loadAchievementsError: null,
    totalPoints: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_IS_GAMIFICATION_ENABLED.SUCCESS:
            return {
                ...state,
                isGamificationEnabled: action.payload.isEnabled,
            };
        case actionTypes.LOAD_ACHIEVEMENT_TYPES.REQUEST:
            return {
                ...state,
                isLoadingAchievementTypes: true,
            };
        case actionTypes.LOAD_ACHIEVEMENT_TYPES.SUCCESS:
            return {
                ...state,
                achievementTypes: action.payload.achievementTypes,
                isLoadingAchievementTypes: false,
                achievementTypesLoaded: true,
            };
        case actionTypes.LOAD_ACHIEVEMENT_TYPES.FAILURE:
            return {
                ...state,
                loadAchievementTypesError: action.payload.error,
                isLoadingAchievementTypes: false,
                achievementTypesLoaded: true,
            };
        case actionTypes.LOAD_ACHIEVEMENTS.REQUEST:
            return {
                ...state,
                isLoadingAchievements: true,
            };
        case actionTypes.LOAD_ACHIEVEMENTS.SUCCESS:
            return {
                ...state,
                achievements: action.payload.achievements,
                isLoadingAchievements: false,
                totalPoints: action.payload.totalPoints,
            };
        case actionTypes.LOAD_ACHIEVEMENTS.FAILURE:
            return {
                ...state,
                isLoadingAchievements: false,
                loadAchievementsError: action.payload.error,
            };
        case actionTypes.CLAIM_REWARD.SUCCESS: {
            return {
                ...state,
                achievements: state.achievements.map(a => {
                    if (a.AchievementId === action.payload.achievementId) {
                        return {
                            ...a,
                            rewardClaimed: true,
                        };
                    }
                    return a;
                }),
            };
        }
        default:
            return state;
    }
};

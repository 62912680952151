import React from 'react';
import styled from 'styled-components';

import noMessagesImg from '../../../assets/images/no-messages.png';
import * as palette from '../../../components/General/Variables';

const PlaceholderImage = styled.div`
    background-image: url(${noMessagesImg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 200px;
`;

const EmptyStateContainer = styled.div`
    text-align: center;
    padding-top: 10px;
    border-top: 1px solid #e5e5e5;
    height: 100%;
    h4 {
        font-size: 18px;
        font-weight: 500;
        color: ${palette.COLOR_TEXT};
    }
    p {
        font-size: 14px;
        color: ${palette.COLOR_TEXT};
    }
`;

const EmptyState = ({ message = '' }) => {
    return (
        <EmptyStateContainer>
            <h4>{message}</h4>
            <PlaceholderImage />
        </EmptyStateContainer>
    );
};

export { EmptyState };

import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { FontIcon } from 'react-md';
import {
    baseButtonStyle,
    cancelButtonStyle,
    saveButtonSocietyStyle,
    saveButtonStyle,
} from '../../../../components/Dialog/styles';

const Icon = styled(FontIcon)`
    margin: 0;
    width: 24px;
`;

const FormButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 24px 40px 24px 40px;
    width: 100%;
`;

export const renderFormButtons = (options, isSociety, theme) => {
    return (
        <FormButtonsWrapper>
            {options.map(op => {
                let buttonStyle = baseButtonStyle;
                const saveButtonFinalStyle = isSociety ? saveButtonSocietyStyle : saveButtonStyle;

                switch (op.type) {
                    case 'save':
                        buttonStyle = {
                            ...buttonStyle,
                            ...saveButtonFinalStyle,
                            marginRight: 8,
                            backgroundColor: theme.primary,
                        };
                        break;
                    case 'cancel':
                        buttonStyle = { ...buttonStyle, ...cancelButtonStyle, marginLeft: 8 };
                        break;
                    default:
                        buttonStyle = { textTransform: 'none' };
                        break;
                }

                return (
                    <Button
                        key={`bt_${op.title}`}
                        color={op.color}
                        disableElevation
                        onClick={op.onClick}
                        style={buttonStyle}
                        variant={op.variant}
                        disabled={op.disabled}
                        startIcon={op.icon && <Icon inherit>{op.icon}</Icon>}
                    >
                        {op.title}
                    </Button>
                );
            })}
        </FormButtonsWrapper>
    );
};

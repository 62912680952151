import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import MicOffIcon from '@material-ui/icons/MicOff';
import CameraOffImage from '../../../../../../assets/images/camera-off.png';
import entities from '../../constants/entities';
import CameraOff from './CameraOff';
import StreamPlayer from '../common/StreamPlayer';
import useResize from '../../../../../VirtualSession/hooks/useResize';
import useStreamHandling from '../../hooks/useStreamHandling';
import bestFitLayout from '../../../../utils/bestFitLayout';
import PanelDiscussionUser from '../moderator/sessionView/PanelDiscussionUser';
import VoiceIndicator from '../common/VoiceIndicator';
import { useVMMutation, useVMState, useVMVolumeState } from '../../containers/main';
import { FORTY_MILL, TWENTY_MILL } from '../../constants/values';
import { ShadowOverlay } from '../moderator/common/styles';
import { getStreamConfiguration, isEncodingTheSame } from '../../../../utils/streamUtils';
import Store from '../../../../../../services/api/store';
import { getImageSource } from '../../../../../../services/api/db';

const { virtualEventSessionEntity } = entities;

const IconWrapper = styled.div`
    bottom: 13px;
    left: 30px;
    position: absolute;
    z-index: 2;
`;

const CameraOffImageContainer = styled.img`
    width: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    z-index: 2;
    padding: 16px 16px 0 16px;
    overflow: hidden;

    ${props =>
        props.noStream &&
        css`
            background: transparent;
        `}

    .stream-player.video-on {
        height: 100%;
        width: 100%;
        border-radius: 8px;

        video {
            ${props =>
                props.isPanelDiscussion &&
                css`
                    background: black !important;
                `};
            width: 100% !important;
            object-fit: cover !important;
            height: 100% !important;
            /* This used to work for the parent element of button divs */
            /* But it does not work with newer browsers, the below doesn't hide the play button parent div */
            &::-webkit-media-controls-panel {
                display: none !important;
                -webkit-appearance: none;
            }

            /* Old shadow dom for play button */

            &::-webkit-media-controls-play-button {
                display: none !important;
                -webkit-appearance: none;
            }

            /* New shadow dom for play button */

            /* This one works! */

            &::-webkit-media-controls-start-playback-button {
                display: none !important;
                -webkit-appearance: none;
            }
        }
    }
`;

const GridForUsers = styled.div`
    background: black;
    display: grid;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    .stream-player {
        //     background: black !important;
        //
        //     [id*=player] {
        //         background: red !important;
        //
        //         video {
        //             background: red !important;
        //         }
        //     }
        //
    }
`;

const ZeroSpaceWrapper = styled.div`
    height: 0;
    width: 0;
    overflow: hidden;
`;

const RoundedContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #5a5b61;

    span {
        font-size: 40px;
    }
`;

const RoundedImage = styled.img`
    height: 100px;
    width: 100px;
    border-radius: 50%;
`;

const StyledMicOffIcon = styled(MicOffIcon)`
    color: #ffffff;
    width: 20px !important;
    height: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ExtraStyledMicOffIcon = styled(StyledMicOffIcon)`
    position: absolute;
    bottom: 14px;
    left: 30px;
    z-index: 2;
`;

const ExtraStyledShadowOverlay = styled(ShadowOverlay)`
    width: calc(100% - 32px);
    left: 16px;
`;

const NameWrapper = styled.span`
    bottom: 16px;
    left: 56px;
    position: absolute;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    font-family: Roboto, sans-serif;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    z-index: 2;
`;

function VideoContainer() {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const volumeStateCtx = useVMVolumeState();
    const {
        speakerViewMode,
        streams,
        virtualEventUser,
        virtualEventSession,
        myStream,
        eventSpeakerPlaceholder,
    } = stateCtx;
    const { volumeIndicators } = volumeStateCtx;
    const {
        isMicrophoneOn,
        isVideoOn,
        isPrerecording: currentUserIsPrerecording,
        User: currentUser,
        isActive: isCurrentUserActive,
    } = virtualEventUser.data;
    const userImageUrl = get(currentUser, 'imageUrl', '');
    const userInitials =
        currentUser.firstName.charAt(0).toLocaleUpperCase() +
        currentUser.lastName.charAt(0).toLocaleUpperCase();

    const [resizeRef] = useResize();
    const { localStream } = useStreamHandling();
    let streamId = get(localStream.current, 'streamId');
    const { mode, status } = virtualEventSession.data;
    const isPanelDiscussion = mode === virtualEventSessionEntity.mode.panelDiscussion;
    const isBroadcasting = status === virtualEventSessionEntity.status.broadcasting;
    const shouldDisplayRegularLayout = currentUserIsPrerecording || !isPanelDiscussion;
    const activeUser = get(virtualEventSession, 'data.VirtualEventUsers', []).find(
        a => a.hasVideoActive || a.isActive,
    );
    const activeVideoId = get(virtualEventSession, 'data.activeVideoId', null);
    const virtualSessionsSpeakerHolder =
        Store.argument && Store.argument.virtualSessionsSpeakerHolder;
    const placeHolderImage =
        get(virtualEventSession, 'data.presentationSpeakerPlaceholderUrl') ||
        eventSpeakerPlaceholder ||
        CameraOffImage;

    if (speakerViewMode === entities.speakerViewMode.audience) {
        streamId = get(activeUser, 'UserId');
        if (activeUser && activeUser.rtmpInfo) {
            streamId = activeUser.rtmpInfo.uid;
        } else if (get(activeUser, 'hasVideoActive')) {
            streamId += TWENTY_MILL;
        } else if (activeVideoId) {
            streamId = virtualEventSession.data.id + FORTY_MILL;
        }
    }

    const activeStream = useMemo(() => streams.find(st => st.streamId === streamId), [
        activeUser,
        speakerViewMode,
        streams,
        localStream.current,
        activeVideoId,
    ]);
    const isActiveStreamRtmp =
        activeStream && activeStream.streamId < 100 && activeStream.streamId > 0;

    const virtualEventUsers = useMemo(
        () => get(virtualEventSession, 'data.VirtualEventUsers', []),
        [virtualEventSession],
    );

    const nonRtmpVirtualEventUsers = useMemo(() => {
        return virtualEventUsers.filter(vUser => !vUser.rtmpInfo);
    }, [virtualEventUsers]);

    const streamsToPlay = useMemo(() => {
        if (currentUserIsPrerecording && !isBroadcasting) {
            return [];
        }

        return reduce(
            nonRtmpVirtualEventUsers,
            (acc, virtualUser) => {
                const { User: user, isPrerecording } = virtualUser;
                const isCurrentUser = get(user, 'id') === get(activeStream, 'streamId');
                const streamId = get(user, 'id');

                const isNotPrerecording = !isPrerecording;
                const shouldDisplayUser = isNotPrerecording || isBroadcasting;

                if (shouldDisplayUser && (!isCurrentUser || isPanelDiscussion)) {
                    const streamToUse = streams.find(st => st.streamId === streamId);

                    if (streamToUse) {
                        acc.push({
                            user,
                            isMicrophoneOn: virtualUser.isMicrophoneOn,
                            isVideoOn: virtualUser.isVideoOn,
                            stream: streamToUse,
                        });
                    }
                }

                return acc;
            },
            [],
        );
    }, [
        virtualEventUsers,
        streams,
        isPanelDiscussion,
        currentUserIsPrerecording,
        activeStream,
        isBroadcasting,
    ]);

    useEffect(() => {
        (async () => {
            if (virtualSessionsSpeakerHolder) {
                await getImageSource(virtualSessionsSpeakerHolder, (err, img) => {
                    mutationCtx.setEventSpeakerPlaceholder(img);
                });
            }
        })();
    }, []);

    useEffect(() => {
        if (!localStream.current || !localStream.current.videoTrack) {
            return;
        }

        const encoderConfiguration = getStreamConfiguration({
            useHighStream: isCurrentUserActive,
            isPanelDiscussion,
            streamLength: streamsToPlay.length,
            forceHighStream: currentUserIsPrerecording,
        });

        if (
            !isEncodingTheSame(encoderConfiguration, localStream.current.videoTrack._encoderConfig)
        ) {
            localStream.current.setEncoderConfiguration(encoderConfiguration);
        }
    }, [
        streamsToPlay.length,
        isPanelDiscussion,
        isCurrentUserActive,
        localStream.current,
        currentUserIsPrerecording,
    ]);

    const volumeLevel = useMemo(() => {
        if (!isMicrophoneOn) {
            return 0;
        }
        const indicator = volumeIndicators.find(
            vInd => `${get(activeStream, 'streamId')}` === `${vInd.uid}`,
        );

        return indicator ? indicator.level : 0;
    }, [volumeIndicators, activeStream, isMicrophoneOn]);

    const gridLayout = bestFitLayout(streamsToPlay.length);

    if (speakerViewMode === entities.speakerViewMode.audience) {
        const { isVideoOn } = activeUser || {};
        const hasVideoActive = get(activeUser, 'hasVideoActive');
        const videoOffOrNoStream =
            (!isVideoOn && !hasVideoActive && !activeVideoId) || !activeStream;
        const shouldDisplayCameraOff =
            shouldDisplayRegularLayout && videoOffOrNoStream && !isActiveStreamRtmp;

        return (
            <Wrapper
                innerRef={resizeRef}
                noStream={!activeStream}
                isPanelDiscussion={isPanelDiscussion && streamsToPlay.length > 1}
            >
                {shouldDisplayCameraOff && (
                    <CameraOff>
                        <CameraOffImageContainer src={placeHolderImage} alt="cameraOffImage" />
                    </CameraOff>
                )}
                {shouldDisplayRegularLayout && activeStream && !activeUser?.isPrerecording && (
                    <React.Fragment>
                        <StreamPlayer
                            key={activeStream.streamId}
                            className={'main-stream-profile'}
                            showProfile={stateCtx.profile}
                            isVideoOn={!shouldDisplayCameraOff}
                            muted={activeStream.streamId === get(localStream.current, 'streamId')}
                            stream={activeStream}
                            uid={activeStream.getId()}
                            domId={`stream-player-${activeStream.getId()}`}
                            noPositionInitial
                        />
                    </React.Fragment>
                )}
                {shouldDisplayRegularLayout &&
                    activeUser &&
                    !activeUser.isMicrophoneOn &&
                    !hasVideoActive && <ExtraStyledMicOffIcon />}
                {shouldDisplayRegularLayout &&
                    activeUser &&
                    activeUser.isMicrophoneOn &&
                    !hasVideoActive && (
                        <IconWrapper>
                            <VoiceIndicator level={volumeLevel} small />
                        </IconWrapper>
                    )}
                {shouldDisplayRegularLayout && activeUser && !hasVideoActive && (
                    <React.Fragment>
                        <NameWrapper>
                            {activeUser.User.firstName + ' ' + activeUser.User.lastName}
                        </NameWrapper>
                        <ExtraStyledShadowOverlay />
                    </React.Fragment>
                )}
                {shouldDisplayRegularLayout && activeStream && activeUser?.isPrerecording && (
                    <CameraOff>
                        <CameraOffImageContainer src={placeHolderImage} alt="cameraOffImage" />
                    </CameraOff>
                )}

                <ZeroSpaceWrapper>
                    {shouldDisplayRegularLayout &&
                        streamsToPlay.map(({ stream }) => (
                            <StreamPlayer
                                key={stream.getId()}
                                className={'main-stream-profile'}
                                muted={stream.streamId === get(localStream.current, 'streamId')}
                                isVideoOn={false}
                                stream={stream}
                                uid={stream.getId()}
                                domId={`stream-player-${stream.getId()}`}
                                noPositionInitial
                            />
                        ))}
                </ZeroSpaceWrapper>

                {!shouldDisplayRegularLayout && (
                    <GridForUsers
                        style={{
                            gridTemplateColumns: 'minmax(0, 1fr)  '.repeat(gridLayout),
                            gridAutoRows: '1fr',
                        }}
                    >
                        {streamsToPlay.map(({ user, stream, isMicrophoneOn, isVideoOn }, index) => (
                            <PanelDiscussionUser
                                key={index}
                                stream={stream}
                                user={user}
                                isPanelDiscussion={true}
                                isMicrophoneOn={isMicrophoneOn}
                                isVideoOn={isVideoOn}
                                columnNumber={gridLayout}
                                index={index}
                            />
                        ))}
                    </GridForUsers>
                )}
            </Wrapper>
        );
    }

    return (
        <Wrapper
            innerRef={resizeRef}
            isPanelDiscussion={isPanelDiscussion && streamsToPlay.length > 1}
        >
            {shouldDisplayRegularLayout && !isVideoOn && (
                <CameraOff>
                    {!userImageUrl && (
                        <RoundedContainer>
                            <span>{userInitials}</span>
                        </RoundedContainer>
                    )}
                    {userImageUrl && <RoundedImage src={userImageUrl} />}
                </CameraOff>
            )}

            {shouldDisplayRegularLayout && myStream && (isVideoOn || isMicrophoneOn) && (
                <StreamPlayer
                    key={myStream.streamId}
                    className={'main-stream-profile'}
                    showProfile={stateCtx.profile}
                    isVideoOn={isVideoOn}
                    stream={myStream}
                    muted
                    uid={myStream.getId()}
                    domId={`stream-player-${myStream.getId()}`}
                    noPositionInitial
                />
            )}

            <ZeroSpaceWrapper>
                {shouldDisplayRegularLayout &&
                    streamsToPlay.map(({ stream }) => (
                        <StreamPlayer
                            key={stream.getId()}
                            className={'main-stream-profile'}
                            muted={false}
                            isVideoOn={false}
                            stream={stream}
                            uid={stream.getId()}
                            domId={`stream-player-${stream.getId()}`}
                        />
                    ))}
            </ZeroSpaceWrapper>

            {shouldDisplayRegularLayout && !isMicrophoneOn && <ExtraStyledMicOffIcon />}
            {shouldDisplayRegularLayout && isMicrophoneOn && (
                <IconWrapper>
                    <VoiceIndicator level={volumeLevel} small />
                </IconWrapper>
            )}
            {shouldDisplayRegularLayout && (
                <React.Fragment>
                    <NameWrapper>{currentUser.firstName + ' ' + currentUser.lastName}</NameWrapper>
                    <ExtraStyledShadowOverlay />
                </React.Fragment>
            )}
            {!shouldDisplayRegularLayout && (
                <GridForUsers
                    style={{
                        gridTemplateColumns: 'minmax(0, 1fr)  '.repeat(gridLayout),
                        gridAutoRows: '1fr',
                    }}
                >
                    {streamsToPlay.map(({ user, stream, isMicrophoneOn, isVideoOn }, index) => (
                        <PanelDiscussionUser
                            key={index}
                            stream={stream}
                            user={user}
                            isPanelDiscussion={true}
                            isMicrophoneOn={isMicrophoneOn}
                            isVideoOn={isVideoOn}
                            columnNumber={gridLayout}
                            index={index}
                        />
                    ))}
                </GridForUsers>
            )}
        </Wrapper>
    );
}

VideoContainer.propTypes = {};

export default VideoContainer;

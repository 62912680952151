import { useEffect, useState } from 'react';

import useInfiniteScroll from './useInfiniteScroll';
import { asyncEurekaRequest } from '../services/api/eureka';

const useRequestPaginatedData = ({ initialPage = 1, limit = 30, requestOptions } = {}) => {
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [page, setPage] = useState(initialPage);
    const [hasMore, setHasMore] = useState(true);

    const loadNextPage = () => {
        if (!hasMore) {
            return;
        }

        loadMoreData(page + 1);
    };

    const [isFetching, setFetching] = useInfiniteScroll(loadNextPage);

    useEffect(() => {
        setHasMore(!data?.totalPages || data.totalPages > page);
    }, [data, page]);

    useEffect(() => {
        setPage(initialPage);
        loadData();
    }, [requestOptions.path]);

    const loadData = async () => {
        const path =
            requestOptions.path.indexOf('?') > -1
                ? `${requestOptions.path}&page=${initialPage}&limit=${limit}`
                : `${requestOptions.path}?page=${initialPage}&limit=${limit}`;

        try {
            const result = await asyncEurekaRequest(
                requestOptions.method,
                path,
                requestOptions.data,
                requestOptions.isAuthenticated,
            );

            setData(result);
        } catch (error) {
            setError(error);
        }
    };

    const loadMoreData = async pageNumber => {
        if (!hasMore) {
            return;
        }

        const path =
            requestOptions.path.indexOf('?') > -1
                ? `${requestOptions.path}&page=${pageNumber}&limit=${limit}`
                : `${requestOptions.path}?page=${pageNumber}&limit=${limit}`;

        try {
            const result = await asyncEurekaRequest(
                requestOptions.method,
                path,
                requestOptions.data,
                requestOptions.isAuthenticated,
            );

            setFetching(false);
            setPage(pageNumber);
            setData({ ...data, ...result, items: [...data.items, ...result.items] });
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return { isLoading: isFetching, data, error, loadNextPage, loadFirstPage: loadData };
};

export default useRequestPaginatedData;

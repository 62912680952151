import { getPollSetByExternalObject } from '../../Votes/services/PollingService';

const onMakePollsInactive = async (stateCtx, mutationCtx) => {
    const { externalObject, sessionId } = stateCtx;
    const pollSets = await getPollSetByExternalObject(externalObject.data.id);
    const activePoll = pollSets.Polls.find(poll => poll.active);

    if (!activePoll) {
        return;
    }

    stateCtx.socket.emit('updatePollSet', {
        objectId: sessionId,
        externalObjectId: externalObject.data.id,
        state: 'closed',
        deActivatePoll: true,
        pollChange: {
            id: activePoll.id,
            active: false,
        },
    });

    mutationCtx.setActivePoll(null);
};

export default onMakePollsInactive;

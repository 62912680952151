import React, { useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import Society from './scenes/Society/containers';
import Event from './scenes/Event/containers';

import reduxStore from './reduxStore';
import withSocket from './components/Session/withSocket';
import SocketContext from './components/Session/SocketContext';
import withAuthentication from './components/Session/withAuthentication';
import withTheme from './components/Theme/withTheme';
import NetworkDetector from './NetworkDetector';
import withPlatformInfoUpdates from './scenes/User/components/platformInfo/PlatformInfoContextProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ContainerProvider } from './scenes/VirtualModeration/features/virtualFeature/containers/main';
import CookieNoticeWrapper from './components/Session/CookieNoticeWrapper';
import { useTheme } from './components/Theme/ThemeContext';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import FloqLiveStoreProviders from './stores/FloqLiveStoreProvider';

export const CONNECTION_ERROR_MESSAGE = 'Could not connect to server';
export const EVENT_NOT_FOUND_MESSAGE = 'AppState not found';
export const EVENT_NOT_CONFIGURED_MESSAGE = 'Navigation not configured';
export const USER_NOT_ATTENDING_PERMISSIONS = 'User not in attendees';

const sendHeightToParent = () => {
    const baseHeight = document.documentElement.scrollHeight;
    let height = 0;
    const scrollableContainerListElement = document.getElementById(
        `timetable-scrollable-container-horizontal`,
    );
    if (scrollableContainerListElement) {
        const firstChild = scrollableContainerListElement?.firstElementChild;
        const listElements = firstChild.getElementsByTagName('ul');
        const listElementsHeight = Array.from(listElements).reduce(
            (acc, el) => acc + el?.clientHeight || 0,
            0,
        );

        height += listElementsHeight;
    }

    const objectItemListElement = document.getElementById('object-item-list');
    if (objectItemListElement) {
        height += objectItemListElement.clientHeight + 128;
    }
    const finalHeight = (height || baseHeight) + 256;
    window.parent.postMessage({ type: 'UPDATE_HEIGHT', payload: finalHeight }, '*');
};

const handleSendHeightToParent = () => {
    sendHeightToParent();
    setTimeout(() => {
        sendHeightToParent();
    }, 3000);
};

function App() {
    const { theme } = useTheme();

    const history = useHistory();

    useEffect(() => {
        if (!history) {
            return;
        }
        handleSendHeightToParent();
        const unlisten = history.listen(location => {
            window.parent.postMessage({ type: 'ROUTE_CHANGE', payload: location.pathname }, '*');
            handleSendHeightToParent();
        });

        return () => {
            unlisten();
        };
    }, [history]);

    // Setting Material UI ThemeProvider
    const materialUITheme = useMemo(() => {
        return createTheme({
            palette: {
                type: 'light', // we can later use dark/light modes
                primary: {
                    main: theme.primary || '#00A480',
                },
                secondary: {
                    main: '#EFEFEF',
                },
            },
            typography: {
                h5: {
                    fontFamily: 'Cabin',
                    fontSize: 17,
                    fontWeight: 'bold',
                    lineHeight: '24px',
                    color: 'rgba(0, 0, 0, 0.87)',
                },
                body1: {
                    fontFamily: 'Roboto',
                    fontSize: 15,
                    color: 'rgba(0, 0, 0, 0.87)',
                },
            },
            overrides: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            boxShadow: 'none',
                            textTransform: 'none',
                            backgroundColor: theme.primary || '#00A480',
                        },
                    },
                },
            },
        });
    }, [theme]);

    return (
        <ContainerProvider>
            <Provider store={reduxStore}>
                <FloqLiveStoreProviders>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={materialUITheme}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Switch>
                                    <Route path="/event/:eventName">
                                        <Event />
                                    </Route>
                                    <Route path="/kiosk/:eventName">
                                        <Event kiosk />
                                    </Route>
                                    <Route path="/">
                                        <Society />
                                    </Route>
                                </Switch>
                            </LocalizationProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </FloqLiveStoreProviders>
            </Provider>
        </ContainerProvider>
    );
}

//Pass set theme function in the app component
const AppWithSocketAndUpdates = props => {
    useEffect(() => {
        if (!props.waitingWorker) {
            serviceWorkerRegistration.register({
                onUpdate: reg => props.onServiceWorkerUpdate(reg),
                onSuccess: () => {},
            });
        }
    }, [props.newVersionAvailable]);

    return (
        <SocketContext.Consumer>
            {({ socket }) => socket && <App {...props} socket={socket} />}
        </SocketContext.Consumer>
    );
};

const AppWithRouter = withPlatformInfoUpdates(AppWithSocketAndUpdates);

const AppWithCookiesEnabled = props => {
    return (
        <CookieNoticeWrapper>
            <AppWithRouter {...props} />
        </CookieNoticeWrapper>
    );
};

//Add Authentication context
export default NetworkDetector(withSocket(withTheme(withAuthentication(AppWithCookiesEnabled))));

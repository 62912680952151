export const objectDecode = (object, encodedFields) => {
    if (!object) {
        return {};
    }

    const newObject = {
        ...object,
    };

    encodedFields.forEach(field => {
        if (!object.hasOwnProperty(field)) {
            return;
        }

        try {
            newObject[field] = JSON.parse(object[field]);
        } catch (e) {
            console.log(`Invalid json for property : ${field}`);
        }
    });

    return newObject;
};

export const batchDecode = (input, result, encodedFields) => {
    if (!result) {
        return {};
    }

    const newObject = {};

    input.data.forEach(table => {
        const { target } = table;
        newObject[target] = [];
        result[target].forEach(object => {
            if (object) {
                const decoded = objectDecode(object, encodedFields);
                newObject[target].push(decoded);
            }
        });
    });
    return newObject;
};

import { useCallback, useEffect, useRef } from 'react';

const useMessageKeyNavigation = ({ messages, onBackspaceKeyDown, useUnderscoreId = false }) => {
    const focusedMessageRef = useRef(null);
    const focusedMessageIndex = useRef(0);

    const keyDownHandler = useCallback(
        e => {
            const handleElementFocus = element => {
                if (element) {
                    element.focus();
                    element.scrollIntoView({ behavior: 'smooth' });
                    element.style.opacity = '0.8';
                }
            };

            const getElement = () => {
                focusedMessageRef.current = document.getElementById(
                    messages[focusedMessageIndex.current][useUnderscoreId ? '_id' : 'id'],
                );
            };

            if (e.key === 'Tab') {
                if (focusedMessageRef.current) {
                    focusedMessageRef.current.blur();
                    focusedMessageRef.current.style.opacity = '1';
                    focusedMessageRef.current = null;
                }
            }

            if (
                e.key === 'Backspace' &&
                typeof onBackspaceKeyDown === 'function' &&
                focusedMessageRef.current &&
                focusedMessageIndex.current !== null
            ) {
                e.preventDefault();
                const messageToDelete = messages[focusedMessageIndex.current];
                if (!messageToDelete) {
                    return;
                }

                onBackspaceKeyDown({ ...messageToDelete, txt: messageToDelete.body });
            }

            if (e.key === 'ArrowDown') {
                e.preventDefault();
                if (messages && messages.length) {
                    if (focusedMessageRef.current) {
                        focusedMessageRef.current.style.opacity = '1';
                    }
                    if (focusedMessageRef.current) {
                        focusedMessageIndex.current =
                            focusedMessageIndex.current === messages.length - 1
                                ? 0
                                : focusedMessageIndex.current + 1;
                    }
                    getElement();

                    return handleElementFocus(focusedMessageRef.current);
                }
            }

            if (e.key === 'ArrowUp') {
                e.preventDefault();
                if (messages && messages.length) {
                    if (focusedMessageRef.current) {
                        focusedMessageRef.current.style.opacity = '1';
                    }
                    if (focusedMessageRef.current) {
                        focusedMessageIndex.current =
                            focusedMessageIndex.current === 0
                                ? messages.length - 1
                                : focusedMessageIndex.current - 1;
                    }
                    getElement();
                    return handleElementFocus(focusedMessageRef.current);
                }
            }
        },
        [messages],
    );

    const clickHandler = useCallback(e => {
        if (focusedMessageRef.current) {
            focusedMessageRef.current.style.opacity = '1';
            focusedMessageRef.current.blur();
            focusedMessageRef.current = null;
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);
        document.addEventListener('click', clickHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
            document.removeEventListener('click', clickHandler);
        };
    }, [keyDownHandler]);
};

export default useMessageKeyNavigation;

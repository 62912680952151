import React, { useState, useEffect, useRef } from 'react';
import BasicDialog from '../Dialog/BasicDialog';
import Cropper from 'react-easy-crop';
import 'react-easy-crop/react-easy-crop.css';
import styled from 'styled-components';
import Slider from '../General/Slider';
import { compressImage, getCroppedImage, getImageSizes } from './utils';
import Loader from '../General/Loader';

const Container = styled.div`
    position: relative;
    width: 100%;
    height: ${props => props.height}px;
`;

const CropperWrapper = styled.div`
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    border-radius: 8px;
    overflow: hidden;
    height: ${props => props.height - 50}px;

    .reactEasyCrop_CropArea {
        ${props => props.hasBorderRadius && 'border-radius: 8px;'}
        overflow: hidden;
    }
`;

const SliderWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    height: 50px;
    bottom: 0;
`;

const InfoText = styled.div`
    font-family: 'Robot', sans-serif;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
`;

const ImagePlaceholder = styled.div`
    width: 100%;
    height: 200px;
    background-color: #f5f5f5;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoaderWrapper = styled.div`
    width: fit-content;

    & > * {
        margin-top: 0;
    }
`;

const ImageCropperModal = ({
    visible,
    file,
    title,
    onDone,
    onClose,
    maxWidth,
    maxHeight,
    aspectRatio,
    roundedCrop,
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [imageSrc, setImageSrc] = useState(null);
    const [containerHeight, setContainerHeight] = useState(250);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [loading, setLoading] = useState(true);
    const containerRef = useRef();

    useEffect(() => {
        if (file) {
            const url = URL.createObjectURL(file);
            setImageSrc(url);
            adjustCropperSizes(url);
        }
    }, [file]);

    const adjustCropperSizes = async url => {
        const { width, height } = await getImageSizes(url);
        const containerWidth = containerRef.current.offsetWidth || 400;
        const ratio = height / width;
        setContainerHeight(containerWidth * ratio);
        setLoading(false);
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const onSave = async () => {
        const croppedImageBlob = await getCroppedImage(imageSrc, croppedAreaPixels);
        const croppedImage = new File([croppedImageBlob], file.name);
        const compressedImage = await compressImage(croppedImage, maxWidth, maxHeight);
        onDone(compressedImage);
    };

    return (
        <BasicDialog
            title={title}
            visible={visible}
            onClose={onClose}
            options={[
                {
                    title: 'Save',
                    variant: 'contained',
                    color: 'primary',
                    onClick: onSave,
                },
                {
                    title: 'Cancel',
                    variant: 'contained',
                    onClick: onClose,
                },
            ]}
        >
            <Container innerRef={containerRef} height={containerHeight}>
                {loading && (
                    <ImagePlaceholder>
                        <LoaderWrapper>
                            <Loader />
                        </LoaderWrapper>
                    </ImagePlaceholder>
                )}
                {!loading && (
                    <>
                        <CropperWrapper height={containerHeight} hasBorderRadius={!roundedCrop}>
                            <Cropper
                                image={imageSrc}
                                crop={crop}
                                zoom={zoom}
                                aspect={aspectRatio}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                                cropShape={roundedCrop ? 'round' : 'rect'}
                                showGrid={false}
                            />
                        </CropperWrapper>
                        <SliderWrapper>
                            <Slider min={1} max={3} value={zoom} onChange={setZoom} step={0.1} />
                        </SliderWrapper>
                    </>
                )}
            </Container>
            <InfoText>
                Adjust the position by dragging the image and change the image size with the slider.
            </InfoText>
        </BasicDialog>
    );
};

export default ImageCropperModal;

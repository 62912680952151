import { actionTypes } from '../actions';

const initialState = {
    events: [],
    isLoadingEvents: false,
    loadEventsError: undefined,
    isLoadingSettings: false,
    isSociety: true,
    societyDomain: undefined,
    branchIoDomain: '',
    mySessions: [],
    isLoadingSessions: false,
    loadSessionsError: undefined,
};

export default (state = initialState, action) => {
    let events = [];
    let data = [];
    let id = undefined;
    let sessions = [];
    let session;

    switch (action.type) {
        case actionTypes.LOAD_EVENTS.REQUEST:
            return {
                ...state,
                isLoadingEvents: true,
            };
        case actionTypes.LOAD_EVENTS.SUCCESS:
            events = action.payload.filter(event => event && event.type === 'event');

            return {
                ...state,
                events: events,
            };
        case actionTypes.LOAD_EVENTS.FAILURE:
            return {
                ...state,
                loadEventsError: action.payload.error,
            };
        case actionTypes.LOAD_SETTINGS.REQUEST:
            return {
                ...state,
                isLoadingEvents: true,
            };
        case actionTypes.LOAD_SETTINGS.SUCCESS:
            id = action.payload.id;

            return {
                ...state,
                isSociety: !id,
                societyDomain: action.payload.societyDomain,
                branchIoDomain: action.payload.branchIoDomain,
            };
        case actionTypes.LOAD_SETTINGS.FAILURE:
            return {
                ...state,
                loadEventsError: action.payload.error,
            };
        case actionTypes.LOAD_MODERATED_SESSIONS.REQUEST:
            return {
                ...state,
                isLoadingSessions: true,
            };
        case actionTypes.LOAD_MODERATED_SESSIONS.SUCCESS:
            data = action.payload;

            return {
                ...state,
                mySessions: data,
                isLoadingSessions: false,
            };
        case actionTypes.LOAD_MODERATED_SESSIONS.FAILURE:
            return {
                ...state,
                mySessions: [],
                loadSessionsError: action.payload.error,
                isLoadingSessions: false,
            };

        case actionTypes.MARK_SESSION_AS_SEEN.REQUEST:
            return {
                ...state,
            };
        case actionTypes.MARK_SESSION_AS_SEEN.SUCCESS:
            id = action.payload;

            sessions = [...state.mySessions];

            session = sessions.find(ses => {
                return ses.object.reference === id;
            });

            session.object.isNew = false;

            return {
                ...state,
                mySessions: sessions,
            };
        case actionTypes.MARK_SESSION_AS_SEEN.FAILURE:
            return {
                ...state,
            };

        default:
            return state;
    }
};

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { connect } from 'react-redux';
import OneSignalService from '../services/oneSignal';
import { getLocalAppStateAsync } from '../../../services/api/db';
import { navigateTo } from '../../../components/Navigation/NavigationLink';
import { increaseUnreadNotifications as increaseUnreadNotificationsAction } from '../../Notifications/actions';

const OneSignalWrapper = ({
    appId,
    safariWebId,
    eventId,
    subdomain,
    children,
    match,
    history,
    location,
    increaseUnreadNotifications,
}) => {
    const [initialized, setInitialized] = useState(false);
    const [newsFeedUrl, setNewsFeedUrl] = useState('/');

    useEffect(() => {
        if (appId && eventId && !initialized) {
            initializeOneSignal();
            setInitialized(true);
        }
    }, [appId, eventId]);

    useEffect(() => {
        const getNewsFeedPageUrl = async () => {
            const appState = await getLocalAppStateAsync();
            if (!(appState && appState.navigation)) {
                return;
            }

            const newsFeedPage = appState.navigation.find(item => item.type === 'Feed');

            if (newsFeedPage) {
                setNewsFeedUrl(newsFeedPage.to);
            }
        };

        getNewsFeedPageUrl();
    }, []);

    const onNotificationDisplayed = () => {
        increaseUnreadNotifications();
    };

    const onNotificationOpened = notification => {
        const openNews = get(notification, 'data.openNews', null);
        const openTargeted = get(notification, 'data.openTargeted', null);
        const itemId = openNews || openTargeted;

        navigateTo({
            match,
            history,
            location,
            path: newsFeedUrl,
            state: { itemId },
        });
    };

    const initializeOneSignal = () => {
        OneSignalService.init(appId, safariWebId, subdomain);
        OneSignalService.addEventTag(eventId);
        OneSignalService.setup();
        OneSignalService.addOnDisplayEventListener(onNotificationDisplayed);
        OneSignalService.addOnOpenEventListener(onNotificationOpened);
        requestPermission();
    };

    // Display subscription window if current browser is not subscribed to One Signal service
    const requestPermission = async () => {
        const subscribed = await OneSignalService.isPushNotificationEnabled();

        if (!subscribed) {
            OneSignalService.showSubscribeWindow();
        }
    };

    return children;
};

export default connect(null, {
    increaseUnreadNotifications: increaseUnreadNotificationsAction,
})(withRouter(OneSignalWrapper));

import { get } from 'lodash';

import { createRequestTypes } from '../../../utils/types';
import { makeItemAsync } from '../../../services/api/data';
import { getFullProfileById } from '../../../services/api/eureka';
import { getImageSourceAsync, getLocalAppStateAsync } from '../../../services/api/db';
import { getUsersFriendsList, sendEmailRequest } from '../api';
import Auth from '../../../services/api/auth';
import { sendFriendRequest, cancelFriendRequest } from '../../UserProfile/service';
import { getString } from '../../../services/api/store';

export const GET_EXHIBITOR = createRequestTypes('GET_EXHIBITOR');
export const GET_FULL_REPRESENTATIVES = createRequestTypes('GET_FULL_REPRESENTATIVES');
export const SEND_EMAIL = createRequestTypes('SEND_EMAIL');
export const SET_PRIVATE_CHAT_USER_ID = 'SET_PRIVATE_CHAT_USER_ID';
export const SET_USERS_FRIENDS_LIST = 'SET_USERS_FRIENDS_LIST';
export const SET_FRIENDSHIP_REQUEST = 'SET_FRIENDSHIP_REQUEST';
export const CLEAR_STATE = 'CLEAR_STATE';

export const getExhibitor = id => async dispatch => {
    dispatch({ type: GET_EXHIBITOR.REQUEST });

    try {
        const exhibitor = await makeItemAsync(id, 'institution');
        const slideshow = get(exhibitor, 'params.slideshow');
        const bannerImage = get(exhibitor, 'params.bannerImage');

        if (slideshow) {
            const slideshowImages = await Promise.all(
                slideshow.map(async image => {
                    const src = await getImageSourceAsync(image._id);
                    return { src, url: image.url };
                }),
            );
            exhibitor.slideshowImages = slideshowImages;
        }
        if (bannerImage) {
            const img = await getImageSourceAsync(bannerImage);
            exhibitor.bannerImage = img;
        }

        dispatch({ type: GET_EXHIBITOR.SUCCESS, payload: { exhibitor } });
    } catch (error) {
        dispatch({ type: GET_EXHIBITOR.FAILURE, payload: { error } });
    }
};

export const getFullRepresentativesInfo = representatives => async dispatch => {
    dispatch({ type: GET_FULL_REPRESENTATIVES.REQUEST });

    try {
        const fullRepresentatives = await Promise.all(
            representatives.map(async representative => {
                try {
                    return await getFullProfileById(representative.userId);
                } catch (err) {
                    console.log('err', err);
                    return null;
                }
            }),
        );
        const nonNullFullRepresentatives = fullRepresentatives.filter(r => r);

        dispatch({
            type: GET_FULL_REPRESENTATIVES.SUCCESS,
            payload: { fullRepresentatives: nonNullFullRepresentatives },
        });
    } catch (error) {
        dispatch({ type: GET_FULL_REPRESENTATIVES.FAILURE, payload: { error } });
    }
};

const getUserProfileURL = appState => {
    const firstNavigationPage = appState.navigation.length && appState.navigation[0];
    const user = Auth.getUser();
    const basePath = window.location.origin;

    if (!firstNavigationPage || !user) {
        return basePath;
    }

    return `${basePath}${firstNavigationPage.to}/profile/${user.id}`;
};

export const sendEmail = (email, message, title) => async dispatch => {
    dispatch({ type: SEND_EMAIL.REQUEST });

    try {
        const appState = await getLocalAppStateAsync();
        const userProfileURL = getUserProfileURL(appState);
        await sendEmailRequest({
            email,
            message,
            eventName: appState.eventName,
            userProfileURL,
            title,
        });
        dispatch({ type: SEND_EMAIL.SUCCESS });
    } catch (_) {
        dispatch({ type: SEND_EMAIL.FAILURE });
    }
};

export const setPrivateChatUserId = userId => dispatch => {
    dispatch({
        type: SET_PRIVATE_CHAT_USER_ID,
        payload: { userId },
    });
};

const fetchUsersFriendsListRequest = async () => {
    const friendsList = await getUsersFriendsList();
    const list = [];

    friendsList.friends.forEach(item => {
        list.push({
            userId: item.id,
            type: 'friends',
        });
    });
    friendsList.pending.forEach(item => {
        list.push({
            userId: item.id,
            type: 'pending',
        });
    });

    return list;
};

export const fetchUsersFriendsList = () => async dispatch => {
    const list = await fetchUsersFriendsListRequest();

    dispatch({
        type: SET_USERS_FRIENDS_LIST,
        payload: { list },
    });
};

export const sendFriendRequestToRepresentative = userId => async dispatch => {
    dispatch({
        type: SET_FRIENDSHIP_REQUEST,
        payload: { sendingFriendshipRequest: true },
    });
    const appState = await getLocalAppStateAsync();
    const data = {
        to: userId,
        event: appState.id,
        push: {
            configuratorUrl: appState.configuratorUrl,
            message: getString('notificationContactRequest') || 'sent you a contact request.',
        },
    };
    await sendFriendRequest(data);
    dispatch({
        type: SET_FRIENDSHIP_REQUEST,
        payload: { sendingFriendshipRequest: false },
    });

    const list = await fetchUsersFriendsListRequest();

    dispatch({
        type: SET_USERS_FRIENDS_LIST,
        payload: { list },
    });
};

export const cancelFriendRequestFromRepresentative = userId => async dispatch => {
    dispatch({
        type: SET_FRIENDSHIP_REQUEST,
        payload: { sendingFriendshipRequest: true },
    });
    const data = {
        from: userId,
    };
    await cancelFriendRequest(data);
    dispatch({
        type: SET_FRIENDSHIP_REQUEST,
        payload: { sendingFriendshipRequest: false },
    });

    const list = await fetchUsersFriendsListRequest();

    dispatch({
        type: SET_USERS_FRIENDS_LIST,
        payload: { list },
    });
};

export const clearExhibitorState = () => ({
    type: CLEAR_STATE,
});

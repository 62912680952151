import React from 'react';

import sortBy from 'lodash/sortBy';
import { withRouter } from 'react-router-dom';
import { List } from 'react-md';
import NavigationLink from '../../../components/Navigation/NavigationLink';
import styled from 'styled-components';
import TimeslotListItem from '../../../components/ObjectListItem/Timeslot';
import flatten from 'lodash/flatten';
import { getImageSource } from '../../../services/api/db';
import { hasFilters, PlaceholderEmptyList, PlaceholderEmptyProgramme } from '../utils';

const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;
    display: block;
    margin: 0 8px;
    border-radius: 8px;
    ${props => props.selectedItem && 'box-shadow: 0 2px 8px 0 rgba(47, 51, 55, 0.15) !important'};

    &:hover {
        background: #f5f5f5 !important;
    }
`;

const ListStyled = styled(List)`
    width: 100%;
    height: calc(100% - 74px);
    overflow: scroll;
    margin-bottom: 5px;
    max-width: ${props => window.innerWidth}px;
`;

const isNumber = string => !isNaN(string);

const sortStringsFn = (left, right) => {
    const leftValue = left ? left.toString() : '-';
    const rightValue = right ? right.toString() : '-';
    return leftValue.localeCompare(rightValue);
};

const ListView = props => {
    const propsItems = [];
    props.items.forEach(i =>
        propsItems.push(i.items.map(item => ({ ...item, groupingOrder: i?.group?.orderingName }))),
    );
    let displayItems = (propsItems && flatten(propsItems)) || [];

    if (props.groupingOption === 'byClassifier' || props.groupingOption === 'byPlace') {
        displayItems = sortBy(displayItems, ['start', 'groupingOrder', 'name']);
    }

    const sortByOrderingName = (firstItem, secondItem) => {
        const itemsHaveOrderingName = !!firstItem.orderingName && !!secondItem.orderingName;
        const itemsHaveSameStartDateTime =
            firstItem.dateTime &&
            secondItem.dateTime &&
            firstItem.dateTime.startDateToDisplay === secondItem.dateTime.startDateToDisplay &&
            firstItem.dateTime.startTimeToDisplay === secondItem.dateTime.startTimeToDisplay;
        const itemsHaveSameStartTime =
            firstItem.start && secondItem.start && firstItem.start === secondItem.start;

        if (itemsHaveOrderingName && (itemsHaveSameStartDateTime || itemsHaveSameStartTime)) {
            if (isNumber(firstItem.orderingName) && isNumber(secondItem.orderingName)) {
                return parseInt(firstItem.orderingName) - parseInt(secondItem.orderingName);
            }
            return sortStringsFn(firstItem.orderingName, secondItem.orderingName);
        }

        return 0;
    };

    let listItems = displayItems.sort(sortByOrderingName).map((item, i) => {
        if (item && item.params && item.params.sponsorLogo) {
            getImageSource(item.params.sponsorLogo, (err, img) => {
                item.sponsorLogo = img;
            });
        }
        const objectClass = item.type === 'appointment' ? 'appointment' : 'timeslot';

        let query = new URLSearchParams(props.location.search);
        const objectId = query.get('objectId');

        return (
            <LinkStyled
                key={'link_' + i + item.id}
                path=""
                params={{
                    type: item.type === 'appointment' ? 'appointment' : 'detail',
                    objectClass: objectClass,
                    objectId: item.id,
                }}
                selectedItem={objectId === item.id}
            >
                <TimeslotListItem timeslot={item} displayOnlyTime={true} />
            </LinkStyled>
        );
    });

    return (
        <>
            {listItems?.length ? (
                <ListStyled id={'timetable-scrollable-container-list'}>{listItems}</ListStyled>
            ) : (
                <>
                    {hasFilters(props.filters) ? (
                        <PlaceholderEmptyList />
                    ) : (
                        <PlaceholderEmptyProgramme />
                    )}
                </>
            )}
        </>
    );
};

export default withRouter(ListView);

export const MESSAGE_TYPE = Object.freeze({
    CHAT: 'chat',
    APPOINTMENT: 'appointment',
});

export const APPOINTMENT_STATE = Object.freeze({
    SCHEDULED: 'scheduled',
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
    DELETED: 'deleted',
    UPDATED: 'updated',
});

export const APPOINTMENT_PARTICIPANT_STATUS = Object.freeze({
    WAITING: 0,
    ACCEPTED: 1,
    DECLINED: 2,
});

import { useEffect, useState } from 'react';
import get from 'lodash/get';

import entities from '../../virtualFeature/constants/entities';
import { useVMState } from '../../virtualFeature/containers/main';

const usePresentingStartTime = () => {
    const [currentTime, setCurrentTime] = useState(Date.now());

    const stateCtx = useVMState();
    const { virtualEventSession, virtualEventUser } = stateCtx;
    const presentingStartTime =
        get(virtualEventSession, 'data.lastSpeakerSwitchTimestamp') || Date.now();
    const isActive = get(virtualEventUser, 'data.isActive') || false;
    const isBroadcasting =
        virtualEventSession.data.status === entities.virtualEventSessionEntity.status.broadcasting;

    useEffect(() => {
        const presentingTimerInterval = setInterval(() => {
            if (isActive && isBroadcasting) {
                setCurrentTime(Date.now());
            }
        }, 1000);

        return () => {
            clearInterval(presentingTimerInterval);
        };
    }, [isActive, isBroadcasting]);

    const getPresentingTime = () => {
        const presentingTime = Date.parse(presentingStartTime);
        const milliseconds = currentTime - presentingTime;
        const ISOStringDate = new Date(milliseconds).toISOString();
        const hours = ISOStringDate.substr(11, 2);
        const displayHours = hours !== '00';

        return new Date(milliseconds)
            .toISOString()
            .substr(displayHours ? 11 : 14, displayHours ? 8 : 5);
    };

    return {
        getPresentingTime,
    };
};

export default usePresentingStartTime;

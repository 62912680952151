import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import SessionOption from './SessionOption';
import { useTheme } from '../../../components/Theme/ThemeContext';
import Loader from '../../../components/General/Loader';
import Auth from '../../../services/api/auth';
import useMyModeratedSessions from '../hooks/useMyModeratedSessions';
import { Flex } from '../../common/Flex';
import Search from '../../../components/General/Search';
import throttle from 'lodash/throttle';
import { TimezoneContextProvider } from '../../Timezone/context';
import { groupByDate, sortSessions } from '../utils';
import { TabElement } from '../../Programme/style/style';
import { useMediaQuery } from '@mui/material';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;
    height: 100%;
    margin-bottom: 40px;
`;

const MySessionsText = styled.div`
    font-family: Cabin, sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);
`;

const Divider = styled.div`
    width: 100%;
    border: 1px solid #ddd;
`;

const BubblesAndSearchContainer = styled.div`
    position: sticky;
    top: 0;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    @media (max-width: 767px) {
        flex-direction: column;
        padding-bottom: 64px;
    }
`;

const BubblesContainer = styled(Flex)`
    overflow: scroll;
`;

const EventSessionsList = ({ event, setTitle }) => {
    const { theme } = useTheme();
    const { mySessions, isLoading, onSelectSession } = useMyModeratedSessions(event.id);
    const isPhone = useMediaQuery('(max-width:767px)');

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [timezoneSessions, setTimezoneSessions] = useState([]);
    const [filteredSessions, setFilteredSessions] = useState([]);

    let inEventLevel = false;

    if (setTitle && typeof setTitle === 'function') {
        // Session lists comes from event router
        inEventLevel = true;
        setTitle();
    }

    const user = Auth.getUser();

    const isModerator = user && user.role === 'moderator';

    const onSelectTab = index => setSelectedTabIndex(index);

    const displaySessions = isModerator && mySessions.length > 0 && !isLoading;

    const formatSessions = sessions => {
        const sorted = sortSessions(sessions);
        const grouped = groupByDate(sorted);
        setFilteredSessions(grouped);
    };

    useEffect(() => {
        if (!mySessions) {
            return;
        }
        setTimezoneSessions(mySessions);
    }, [mySessions]);

    useEffect(() => {
        formatSessions(timezoneSessions);
    }, [timezoneSessions]);

    const onSearchValueChange = throttle(async value => {
        if (!value) {
            formatSessions(timezoneSessions);
        } else {
            const searchedSessions = timezoneSessions?.filter(session => {
                const { title } = session.object;
                const searchTerms = title.toLowerCase();

                return searchTerms.includes(value.toLowerCase());
            });
            formatSessions(searchedSessions);
            setSelectedTabIndex(0);
        }
    }, 500);

    return (
        <>
            {displaySessions && (
                <Wrapper
                    style={{
                        paddingLeft: inEventLevel ? 130 : 0,
                        paddingRight: inEventLevel ? 130 : 0,
                    }}
                >
                    <MySessionsText>My sessions</MySessionsText>
                    <BubblesAndSearchContainer>
                        <BubblesContainer role="tablist" style={{ flexGrow: 1 }}>
                            {filteredSessions.map((sessionGroup, index) => (
                                <>
                                    {sessionGroup.elements?.length ? (
                                        <TabElement
                                            key={`event-info-tab-${index}`}
                                            label={sessionGroup.day}
                                            primary={theme.primary}
                                            onClick={() => onSelectTab(index)}
                                            active={selectedTabIndex === index}
                                        />
                                    ) : null}
                                </>
                            ))}
                        </BubblesContainer>

                        <Search
                            action={onSearchValueChange}
                            live={true}
                            isExpandableSearch={true}
                            isDailySchedule={true}
                            customTop={isPhone ? '56px' : '0'}
                            customLeft={isPhone ? '0px' : undefined}
                            collapsible={!isPhone}
                            displayLeft={isPhone}
                            customWidth={isPhone ? '100%' : undefined}
                        />
                    </BubblesAndSearchContainer>

                    {!isPhone ? (
                        <>
                            <SessionOption isListTitle isSociety={!inEventLevel} event={event} />
                            <Divider />
                        </>
                    ) : null}
                    {filteredSessions &&
                        filteredSessions[selectedTabIndex] &&
                        filteredSessions[selectedTabIndex].elements.map((session, index) => (
                            <SessionOption
                                key={`session-option-${index}-${session?.object?.name}`}
                                session={session}
                                event={event}
                                onSelectSession={onSelectSession}
                                isSociety={!inEventLevel}
                            />
                        ))}
                </Wrapper>
            )}
            {isLoading && (
                <Wrapper style={{ marginTop: '100px', height: '60px' }}>
                    <Loader />
                </Wrapper>
            )}
        </>
    );
};

export default EventSessionsList;

// For society level we add the timezone context
export const EventSessionsListWithTimezone = props => {
    return (
        <TimezoneContextProvider>
            <EventSessionsList {...props}></EventSessionsList>
        </TimezoneContextProvider>
    );
};

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Button, { buttonTypes } from '../../../common/Button';
import * as palette from '../../../../components/General/Variables';
import imagePlaceholder from '../../../../assets/images/placeholder-person.png';

import { getPrivateConversationWithId } from '../../../Talk/selectors';
import { useTheme } from '../../../../components/Theme/ThemeContext';

const CardContainer = styled.div`
    width: 100%;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.1);
`;

const ImageWrapper = styled.button`
    width: 100%;
    cursor: pointer;
    display: flex;
    border: none;
    background-color: transparent;
    padding: 0;
`;

const Image = styled.img`
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
`;

const Content = styled.div`
    background-color: #ffffff;
    padding: 12px;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        padding: 8px;
    }
`;

const TextWrapper = styled.div`
    margin: 0 8px;
`;

const PrimaryText = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        font-size: 16px;
    }
`;

const SecondaryText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.54);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        font-size: 12px;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 20px;

    & > :first-child {
        margin-right: 8px;
    }
`;

const StatusWrapper = styled.div`
    background-color: #2d2d2d;
    padding: 4px 8px 4px 4px;
    border-radius: 18px;
    width: fit-content;
    display: flex;
    position: absolute;
    top: 12px;
    left: 12px;
`;

const StatusCircle = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: ${props => props.bgColor};
`;

const StatusText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.87);
`;

export const connectButtonStatuses = {
    HIDDEN: 'hidden',
    PENDING: 'pending',
    VISIBLE: 'visible',
};

const RepresentativeCard = ({
    primaryText,
    secondaryText,
    imageUrl,
    isOnline,
    onChatClick,
    connectButtonStatus,
    onConnectClick,
    conversation,
    onClick,
}) => {
    const { theme } = useTheme();
    const isMobile = palette.MIN_TABLET_INT && window.innerWidth < palette.MIN_TABLET_INT;
    const statusText = isOnline ? 'Online' : 'Offline';
    const statusColor = isOnline ? '#03dc45' : '#e5e5e5';
    const displayFriendshipButton =
        connectButtonStatus === connectButtonStatuses.VISIBLE ||
        connectButtonStatus === connectButtonStatuses.PENDING;
    const friendshipButtonIcon = displayFriendshipButton
        ? connectButtonStatus === connectButtonStatuses.VISIBLE
            ? palette.ICON_ADD_PERSON
            : palette.ICON_REMOVE_PERSON
        : '';
    const buttonType = displayFriendshipButton
        ? connectButtonStatus === connectButtonStatuses.VISIBLE
            ? buttonTypes.GREEN
            : buttonTypes.GREEN_REVERSE
        : null;

    return (
        <CardContainer>
            {/*<StatusWrapper>*/}
            {/*    <StatusCircle bgColor={statusColor} />*/}
            {/*    <StatusText>{statusText}</StatusText>*/}
            {/*</StatusWrapper>*/}
            <ImageWrapper
                onClick={onClick}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        onClick();
                    }
                }}
            >
                <Image src={imageUrl || imagePlaceholder} />
            </ImageWrapper>
            <Content>
                <TextWrapper>
                    <PrimaryText>{primaryText}</PrimaryText>
                    <SecondaryText>{secondaryText}</SecondaryText>
                </TextWrapper>
                <ButtonsWrapper>
                    {displayFriendshipButton && (
                        <Button
                            type={buttonType}
                            onClick={onConnectClick}
                            icon={friendshipButtonIcon}
                            iconCustomColor={theme.primary}
                            style={{ width: 'fit-content', padding: 0 }}
                        />
                    )}
                    <Button
                        type={buttonTypes.GREY_LONG}
                        text={!isMobile && 'Chat'}
                        icon="chat_bubble_outline"
                        onClick={() => {
                            onChatClick(conversation);
                        }}
                    />
                </ButtonsWrapper>
            </Content>
        </CardContainer>
    );
};

const mapStateToProps = (state, ownProps) => {
    const conversationId = ownProps.user.id;
    return {
        conversation: getPrivateConversationWithId(state, conversationId),
    };
};

export default connect(mapStateToProps)(RepresentativeCard);

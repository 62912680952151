import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import LeftContent from './LeftContent/LeftContent';
import RightContent from './RightContent/RightContent';
import { useVMState } from '../../containers/main';
import ConversionModalDialog from '../common/ConversionModalDialog';

const Wrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-flow: row nowrap;
    padding: 16px;
    height: 100%;
    gap: 16px;
`;

function Presenter() {
    const stateCtx = useVMState();
    const { sessionId, socket, virtualEventUser } = stateCtx;
    const [showConversionModal, setShowConversionModal] = useState(false);

    useEffect(() => {
        return () => {
            socket.emit('updateData', {
                objectId: sessionId,
                virtualEventSession: {
                    VirtualEventUsers: [
                        {
                            id: virtualEventUser.data.id,
                            isPrerecording: false,
                        },
                    ],
                },
                userId: virtualEventUser.data.UserId,
            });
        };
    }, []);

    return (
        <Wrapper>
            <LeftContent />
            <RightContent setShowConversionModal={setShowConversionModal} />
            {showConversionModal && (
                <ConversionModalDialog
                    open={showConversionModal}
                    onClose={() => setShowConversionModal(false)}
                    onDone={() => setShowConversionModal(false)}
                />
            )}
        </Wrapper>
    );
}

export default Presenter;

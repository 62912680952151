import React from 'react';
import { Slider as MdSlider } from 'react-md';
import styled from 'styled-components';

const Wrapper = styled.div`
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    height: 22px;
`;

const StyledSlider = styled(MdSlider)`
    transform: translate(7px, 7px);
    width: calc(100% - 14px);

    .md-slider-thumb {
        width: 0;
        height: 0;
    }

    .md-slider-track {
        height: 8px;
        background-color: #2d2d2d;
        margin: 0 !important;

        &-fill {
            height: 8px;
            background-color: #d50000;
        }
    }
`;

const Slider = ({ min, max, value, onChange, step, ...props }) => {
    return (
        <Wrapper>
            <StyledSlider
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={onChange}
                {...props}
            />
        </Wrapper>
    );
};

export default Slider;

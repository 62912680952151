import React, { useEffect, useState } from 'react';
import { FontIcon } from 'react-md';
import styled, { css } from 'styled-components';
import get from 'lodash/get';

import ConfirmationDialog from '../../../common/ConfirmatonDialog';
import copyUtils from '../../../../utils/copyUtils';
import KeyIcon from '../../../../../../../../assets/icons/KeyIcon';
import { ContentModalContainer, SaveModalButton } from '../../common/styles';
import { useVMState } from '../../../../containers/main';
import { createRTMPUrl } from '../../../../services/VirtualEventSession';

const IconWrapper = styled(FontIcon)`
    margin: 0 16px 20px 0;
    color: rgba(0, 0, 0, 0.87) !important;

    ${props =>
        props.right &&
        css`
            position: absolute;
            top: 15px;
            right: 18px;
            margin: 0;
        `}
`;

const StyledKeyIcon = styled.div`
    align-self: flex-start;

    svg {
        padding: 0 !important;
        background: transparent !important;
    }
`;

const CopyButton = styled.div`
    position: absolute;
    top: 15px;
    right: 18px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto, sans-serif;

    ${props =>
        props.copied &&
        css`
            align-items: center;
            color: rgba(0, 0, 0, 0.85);
            display: flex;

            i {
                margin: 0 8px 0 0;
            }
        `}
`;

const SubTitle = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    width: 100%;
    padding: 0 70px;
`;

const ExtraStyledContentModalContainer = styled(ContentModalContainer)`
    flex-direction: column;
    align-items: flex-start;
`;

const Section = styled.div`
    align-items: center;
    background: #fff;
    cursor: pointer;
    display: flex;
    transition: all 0.2s linear;
    border-radius: 8px;
    height: 72px;
    width: 100%;
    padding: 12px;
    border: solid 1px #d3d3d3;
    margin: 24px 0 12px;
    position: relative;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    &:last-child {
        margin: 0;
    }
`;

const SectionLabels = styled.div`
    align-items: center;
    display: flex;
    flex-flow: column;
`;

const SectionTitle = styled.div`
    align-self: stretch;
    flex-grow: 1;
    font-family: Cabin, sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
`;

const SectionSubTitle = styled.div`
    align-self: stretch;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    position: relative;
    top: 8px;
    max-width: 230px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const AddEditRTMPModal = ({ onClose, onAdd, item }) => {
    const stateCtx = useVMState();
    const { sessionId, virtualEventUser } = stateCtx;

    const [pushRtmpUrl, setPushRtmpUrl] = useState('');
    const [playRtmpUrl, setPlayRtmpUrl] = useState('');
    const [uid, setUid] = useState(null);
    const [copied, setCopied] = useState({
        url: false,
        authKey: false,
    });
    const [rtmpPush, setRtmpPush] = useState({
        url: '',
        authKey: '',
    });

    useEffect(() => {
        if (item) {
            setPushRtmpUrl(item.rtmpPushURL);
            setRtmpUrlAndAuthKey(item.rtmpPushURL);
            return;
        }

        (async () => {
            const userId = get(virtualEventUser, 'data.UserId');
            const { uid, pushUrl, playUrl } = await createRTMPUrl(stateCtx.sessionId, {
                userId,
            });

            setPushRtmpUrl(pushUrl);
            setUid(uid);
            setPlayRtmpUrl(playUrl);
            setRtmpUrlAndAuthKey(pushUrl);
        })();
    }, [item]);

    const setRtmpUrlAndAuthKey = urlString => {
        const rtmpUrl = urlString.substr(0, 32);
        const authKey = urlString.substr(33);

        setRtmpPush({
            url: rtmpUrl,
            authKey,
        });
    };

    const onSave = async () => {
        try {
            if (!item) {
                await onAdd({
                    uid,
                    rtmpURL: playRtmpUrl,
                    rtmpPushURL: pushRtmpUrl,
                });
            }

            onClose();
        } catch (err) {
            console.log(err);
        }
    };

    const onSectionClick = isAuthKey => {
        copyUtils.copyTextToClipboard(isAuthKey ? rtmpPush.authKey : rtmpPush.url);
        setCopied({
            ...copied,
            [isAuthKey ? 'authKey' : 'url']: true,
        });
        setTimeout(
            () =>
                setCopied({
                    [isAuthKey ? 'authKey' : 'url']: true,
                }),
            2000,
        );
    };

    return (
        <div className="eureka-react">
            <ConfirmationDialog
                open
                title="RTMP Stream"
                titleIcon="cast"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                onClose={onClose}
                content={
                    <ExtraStyledContentModalContainer>
                        <SubTitle>
                            Copy and paste the URL and Key into your streaming software.
                        </SubTitle>

                        <Section onClick={() => onSectionClick(false)}>
                            <IconWrapper>link</IconWrapper>

                            <SectionLabels>
                                <SectionTitle>RTMP URL</SectionTitle>

                                <SectionSubTitle>{rtmpPush.url}</SectionSubTitle>
                            </SectionLabels>

                            <CopyButton copied={copied.url}>
                                {copied.url && (
                                    <React.Fragment>
                                        <IconWrapper>check</IconWrapper>
                                        Copied
                                    </React.Fragment>
                                )}
                                {!copied.url && 'Copy'}
                            </CopyButton>
                        </Section>

                        <Section onClick={() => onSectionClick(true)}>
                            <StyledKeyIcon>
                                <KeyIcon />
                            </StyledKeyIcon>

                            <SectionLabels>
                                <SectionTitle>Stream key</SectionTitle>

                                <SectionSubTitle>{rtmpPush.authKey}</SectionSubTitle>
                            </SectionLabels>

                            <CopyButton copied={copied.authKey}>
                                {copied.authKey && (
                                    <React.Fragment>
                                        <IconWrapper>check</IconWrapper>
                                        Copied
                                    </React.Fragment>
                                )}
                                {!copied.authKey && 'Copy'}
                            </CopyButton>
                        </Section>
                    </ExtraStyledContentModalContainer>
                }
                buttons={[
                    <SaveModalButton key="AEDSMC" flat onClick={onSave} style={{ width: '100%' }}>
                        Close
                    </SaveModalButton>,
                ]}
            />
        </div>
    );
};

export default AddEditRTMPModal;

import React from 'react';
import { Editor, Node, Path, Range, Transforms } from 'slate';
import { DefaultElement } from 'slate-react';
import Link from '@mui/material/Link';
import isUrl from 'is-url';
import { wrapLink } from './utils';

export default function useEditorConfig(editor) {
    const { insertData, insertText, isInline, isVoid, deleteBackward, insertBreak } = editor;

    editor.isVoid = element => {
        return element.type === 'image' ? true : isVoid(element);
    };

    editor.isInline = element => ['link', 'button'].includes(element.type) || isInline(element);

    editor.insertText = text => {
        if (text && isUrl(text)) {
            wrapLink(editor, text);
        } else {
            insertText(text);
        }
    };

    editor.insertData = data => {
        const text = data.getData('text/plain');
        if (text && isUrl(text)) {
            wrapLink(editor, text);
        } else {
            insertData(data);
        }
    };

    // if current selection is void node, insert a default node below
    editor.insertBreak = () => {
        if (!editor.selection || !Range.isCollapsed(editor.selection)) {
            return insertBreak();
        }

        const selectedNodePath = Path.parent(editor.selection.anchor.path);
        const selectedNode = Node.get(editor, selectedNodePath);
        if (Editor.isVoid(editor, selectedNode)) {
            Editor.insertNode(editor, {
                type: 'paragraph',
                children: [{ text: '' }],
            });
            return;
        }

        insertBreak();
    };

    // if prev node is a void node, remove the current node and select the void node
    editor.deleteBackward = unit => {
        if (
            !editor.selection ||
            !Range.isCollapsed(editor.selection) ||
            editor.selection.anchor.offset !== 0
        ) {
            return deleteBackward(unit);
        }

        const parentPath = Path.parent(editor.selection.anchor.path);
        const parentNode = Node.get(editor, parentPath);
        const parentIsEmpty = Node.string(parentNode).length === 0;

        if (parentIsEmpty && Path.hasPrevious(parentPath)) {
            const prevNodePath = Path.previous(parentPath);
            const prevNode = Node.get(editor, prevNodePath);
            if (Editor.isVoid(editor, prevNode)) {
                return Transforms.removeNodes(editor);
            }
        }

        deleteBackward(unit);
    };

    return { renderElement, renderLeaf };
}

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
    <span style={{ fontSize: '0px' }} contentEditable={false}>
        ${String.fromCodePoint(160) /* Non-breaking space */}
    </span>
);

const renderElement = props => {
    const { element, children, attributes } = props;
    const style = {};
    switch (element.type) {
        case 'paragraph':
            return (
                <p {...attributes} content-editable={'true'}>
                    {children}
                </p>
            );
        case 'heading-one':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            );
        case 'heading-two':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            );
        case 'heading-three':
            return (
                <h3 style={style} {...attributes}>
                    {children}
                </h3>
            );
        case 'heading-four':
            return (
                <h4 style={style} {...attributes}>
                    {children}
                </h4>
            );
        case 'heading-five':
            return (
                <h5 style={style} {...attributes}>
                    {children}
                </h5>
            );
        case 'heading-six':
            return (
                <h6 style={style} {...attributes}>
                    {children}
                </h6>
            );
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            );
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            );
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            );
        case 'link':
            return (
                <Link href={element.url} {...attributes} className={'link'}>
                    <InlineChromiumBugfix />
                    {children}
                    <InlineChromiumBugfix />
                </Link>
            );
        default:
            return <DefaultElement {...props} />;
    }
};

const renderLeaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    // The following is a workaround for a Chromium bug where,
    // if you have an inline at the end of a block,
    // clicking the end of a block puts the cursor inside the inline
    // instead of inside the final {text: ''} node
    // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364

    const padding = {
        padding: leaf.text === '' ? '0.1px' : undefined,
    };

    return (
        <span style={padding} {...attributes}>
            {children}
        </span>
    );
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as palette from '../../components/General/Variables';
import backgroundDefaultImage from '../../assets/images/banner-placeholder.png';
import BackgroundBanner from './BackgroundBanner';

const HeaderImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 128px;
    width: 128px;
    margin: auto;
    margin-top: -64px;
    position: relative;
    background-color: ${palette.COLOR_WHITE} !important;
    border: 1px solid rgb(204, 204, 204);
`;

const HeaderImg = styled.img`
    width: ${props => (props.imageHeightGTWidth ? '70%' : '100%')};
    max-height: 100%;
    background: #fff;
`;

const AStyled = styled.a`
    text-decoration: none !important;
`;

const IconPlaceholder = styled.i.attrs({
    className: 'material-icons mdl-list__item-icon',
})`
    font-size: 100px !important;
    padding: 16px !important;
    color: ${palette.COLOR_GREY} !important;
`;

const Container = styled.div`
    padding: 0 16px;
`;

const InstitutionDetailHeader = ({ picture, params, backgroundImage, showClickBanner }) => {
    const [imageHeightGTWidth, setImageHeightGTWidth] = useState(false);

    useEffect(() => {
        if (picture) {
            const img = new Image();
            img.src = picture;
            img.onload = function () {
                setImageHeightGTWidth(img?.height > img?.width);
            };
        }
    }, [picture]);

    return (
        <Container>
            {params && params.backgroundUrl ? (
                <AStyled target="_blank" href={params.backgroundUrl}>
                    <BackgroundBanner src={backgroundImage || backgroundDefaultImage} />
                </AStyled>
            ) : (
                <BackgroundBanner src={backgroundImage || backgroundDefaultImage} />
            )}
            {!showClickBanner &&
                (picture ? (
                    <HeaderImgContainer>
                        <HeaderImg
                            src={picture}
                            alt="picture"
                            imageHeightGTWidth={imageHeightGTWidth}
                        />
                    </HeaderImgContainer>
                ) : (
                    <HeaderImgContainer>
                        <IconPlaceholder>domain</IconPlaceholder>
                    </HeaderImgContainer>
                ))}
        </Container>
    );
};

export default InstitutionDetailHeader;

'use strict';
import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf.js';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

import { useRouteMatch } from 'react-router-dom';
import { useVMState, useVMMutation } from './containers/main';
import Presenter from './components/presenter/Presenter';
import entities from './constants/entities';
import { LoaderTransparent } from './components/common/LoaderTransparent';
import Moderator from './components/moderator/Moderator';
import RoundTable from './components/roundTable/RoundTable';
import { parseVirtualEventSession } from './utils/sortUtils';
import LoadingVirtualPage from './components/common/LoadingVirtualPage';
import {
    getBySession,
    getPeerList,
    queryRecordingStatus,
    updateRecording,
    stopRecording,
    createVirtualEventToken,
    stopAudioRecording,
} from './services/VirtualEventSession';
import Auth from '../../../../services/api/auth';
import { defaultState } from './reducers/main';

window._ = _;

const { virtualEventSessionEntity } = entities;
const roomTypeEntity = entities.virtualEventSessionEntity.roomType;

const VirtualFeatureContent = () => {
    const mutationCtx = useVMMutation();
    const stateCtx = useVMState();
    const { config, virtualEventUser, virtualEventSession, useDevices, timeslot } = stateCtx;
    const socket = useRef(null);
    const stateRef = useRef(null);
    const match = useRouteMatch();
    const { timeslotId } = match.params;

    const { url } = stateCtx;

    stateRef.current = stateCtx;

    window.mutationCtx = mutationCtx;

    useEffect(() => {
        return () => {
            mutationCtx.clearAllStream();
            mutationCtx.setExtraState(defaultState);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (!url) {
                return;
            }
            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
            const pdf = await pdfjsLib.getDocument(url).promise;
            mutationCtx.setPdf(pdf);
        })();
    }, [url]);

    useEffect(() => {
        (async () => {
            mutationCtx.setVirtualEventUser({
                ...virtualEventUser,
                fetchRequestStatus: entities.requestStatus.loading,
            });

            const sessionReferenceId = timeslotId;

            mutationCtx.setSessionId(sessionReferenceId);

            const virtualEventSessionData = await getBySession(sessionReferenceId);

            if (!virtualEventSessionData || !timeslotId) {
                return;
            }

            const externalObjectData = get(virtualEventSessionData, 'ExternalObject', {});
            const externalObjectResponse = { data: externalObjectData };

            const peersData = await getPeerList(sessionReferenceId);
            const peersResponse = { data: peersData };

            const { roomType } = virtualEventSessionData;
            const isRoomRoundTable = roomType === roomTypeEntity.roundTable;

            mutationCtx.setExtraState({
                mode: isRoomRoundTable ? 'rtc' : 'live',
                externalObject: {
                    ...stateCtx.externalObject,
                    data: externalObjectResponse.data,
                },
                peers: peersResponse.data.peers,
            });

            const userResponse = Auth.getUser();
            const recordingInfo = await queryRecordingStatus(sessionReferenceId);

            //TODO FIX ME
            //const mockedUserId = parseInt(window.location.pathname.split('/')[3]);
            const mockedUserId = null;

            if (mockedUserId) {
                userResponse.id = mockedUserId;
            }

            if (recordingInfo.recording) {
                try {
                    await updateRecording(sessionReferenceId);
                } catch (err) {
                    console.log(err);
                }
            }

            let virtualEventUserData;

            if (virtualEventSessionData.VirtualEventUsers) {
                const users = virtualEventSessionData.VirtualEventUsers;
                let i;

                for (i = 0; i < users.length; i++) {
                    const virtualEventUserExisting = users[i];

                    if (virtualEventUserExisting.UserId === userResponse.id) {
                        virtualEventUserData = virtualEventUserExisting;
                    }
                }
            }

            const joinRooms = () => {
                stateCtx.socket.emit('joinQuestions', {
                    objectId: externalObjectResponse.data.id,
                });
                stateCtx.socket.emit(
                    'joinSession',
                    {
                        sessionId: sessionReferenceId,
                        userId: virtualEventUserData.UserId,
                    },
                    peers => mutationCtx.setPeers(peers),
                );
            };

            stateCtx.socket.on('connect', joinRooms);

            joinRooms();

            if (virtualEventUserData) {
                // stopping the prerecording if user refreshes
                const list = get(virtualEventUserData, 'recordingInfo.list', []);
                const shouldStopPreRecording = list.length !== 0;

                if (shouldStopPreRecording) {
                    await stopRecording(sessionReferenceId, {
                        preRecording: true,
                        userId: virtualEventUserData.UserId,
                    });
                }

                const audioList = get(virtualEventUserData, 'audioRecordingInfo.list', []);
                const shouldStopAudioRecording = audioList.length !== 0;

                if (shouldStopAudioRecording) {
                    await stopAudioRecording(sessionReferenceId, {
                        userId: virtualEventUserData.UserId,
                    });
                }

                const createTokenData = await createVirtualEventToken(sessionReferenceId);
                const createTokenResponse = { data: createTokenData };

                if (virtualEventUserData.isPrerecording) {
                    virtualEventUserData.isPrerecording = false;
                    stateCtx.socket.emit('updateData', {
                        objectId: sessionReferenceId,
                        virtualEventSession: {
                            VirtualEventUsers: [
                                {
                                    id: virtualEventUserData.id,
                                    isPrerecording: false,
                                },
                            ],
                        },
                    });
                }

                if (virtualEventUserData.isAudioRecording) {
                    virtualEventUserData.isAudioRecording = false;
                    stateCtx.socket.emit('updateData', {
                        objectId: sessionReferenceId,
                        virtualEventSession: {
                            VirtualEventUsers: [
                                {
                                    id: virtualEventUserData.id,
                                    isAudioRecording: false,
                                },
                            ],
                        },
                    });
                }

                if (createTokenResponse && createTokenResponse.data) {
                    const appID = get(createTokenResponse, 'data.appID');
                    const token = get(createTokenResponse, 'data.virtualEventToken.accessToken');
                    const uid =
                        mockedUserId || get(createTokenResponse, 'data.virtualEventToken.UserId');
                    const shouldUseDevices =
                        virtualEventUserData.isMicrophoneOn || virtualEventUserData.isVideoOn;
                    const config = {
                        appID,
                        token,
                        uid,
                        channelName: sessionReferenceId,
                    };

                    if (shouldUseDevices) {
                        mutationCtx.startUsingDevices(config);
                    } else {
                        mutationCtx.updateConfig(config);
                    }
                }
            }

            mutationCtx.setVirtualEventUser({
                ...stateCtx.virtualEventUser,
                data: {
                    ...virtualEventUserData,
                },
                fetchRequestStatus: entities.requestStatus.success,
            });

            const virtualEventSessionStatus = virtualEventSessionData.status;
            const recording =
                virtualEventSessionStatus === virtualEventSessionEntity.status.broadcasting;

            const extraState = {
                recording,
                virtualEventSession: {
                    ...stateCtx.virtualEventSession,
                    data: parseVirtualEventSession(virtualEventSessionData),
                    fetchRequestStatus: entities.requestStatus.success,
                },
                externalObject: externalObjectResponse,
                user: userResponse,
                currentUser: userResponse,
            };

            mutationCtx.setExtraState(extraState);
        })();
    }, [timeslotId]);

    useEffect(() => {
        const posterPdf = timeslot && timeslot.posterPdf && JSON.parse(timeslot.posterPdf);

        if (
            (virtualEventUser &&
                virtualEventUser.data &&
                virtualEventUser.data.uploadedPresentationUrl) ||
            (timeslot && posterPdf)
        ) {
            const posterPdfUrl = posterPdf && posterPdf.url;
            const hostPosterPdf = virtualEventUser.data.uploadedPresentationUrl;
            if (hostPosterPdf) {
                mutationCtx.setUrl(hostPosterPdf);
            } else if (
                virtualEventSession.data.roomType === virtualEventSessionEntity.roomType.roundTable
            ) {
                mutationCtx.setUrl(posterPdfUrl);
            }
        }
    }, [
        timeslot.posterPdf?.url,
        virtualEventUser.data.uploadedPresentationUrl,
        virtualEventSession.data.roomType,
    ]);

    useEffect(() => {
        if (!useDevices) {
            return;
        }

        const newState = {};

        if (virtualEventUser.data.isVideoOn && !config.cameraId) {
            newState.isVideoOn = false;
        }

        if (virtualEventUser.data.isMicrophoneOn && !config.microphoneId) {
            newState.isMicrophoneOn = false;
        }

        if (!isEmpty(newState)) {
            stateCtx.socket.emit('updateData', {
                objectId: stateCtx.sessionId,
                virtualEventSession: {
                    VirtualEventUsers: [
                        {
                            id: virtualEventUser.data.id,
                            ...newState,
                        },
                    ],
                },
            });
        }
    }, [virtualEventUser, config, useDevices, stateCtx.sessionId]);

    useEffect(() => {
        if (!stateCtx.sessionId || !stateCtx.currentUser) {
            return;
        }

        if (socket.current) {
            return;
        }

        socket.current = stateCtx.socket;

        const handleUpdate = data => {
            const { virtualEventSession } = data;
            const newState = {
                virtualEventSession: {
                    ...stateCtx.virtualEventSession,
                    data: parseVirtualEventSession(virtualEventSession),
                },
            };

            if (virtualEventSession) {
                const virtualEventUserData = virtualEventSession.VirtualEventUsers.find(
                    virtualEventUser => virtualEventUser.UserId === stateCtx.currentUser.id,
                );

                Object.assign(newState, {
                    virtualEventUser: {
                        ...stateCtx.virtualEventUser,
                        data: virtualEventUserData,
                    },
                });
            }

            mutationCtx.setExtraState(newState);
        };

        stateCtx.socket.on(`updateData_${stateCtx.sessionId}`, handleUpdate);

        const handlePeers = async () => {
            const peersData = await getPeerList(stateCtx.sessionId);
            const peersResponse = { data: peersData };
            const peersArray = get(peersResponse, 'data.peers');
            const isPrerecordingMode =
                stateRef.current.speakerViewMode === entities.speakerViewMode.prerecording;

            if (!isPrerecordingMode && Array.isArray(peersArray)) {
                const isNotInPeers =
                    peersArray.findIndex(peer => peer === stateCtx.currentUser.id) < 0;

                if (isNotInPeers) {
                    stateCtx.socket.emit('joinSession', {
                        sessionId: stateCtx.sessionId,
                        userId: stateCtx.currentUser.id,
                    });
                } else {
                    mutationCtx.setPeers(peersResponse.data.peers);
                }
            }
        };

        stateCtx.socket.on(`refreshPeers_${stateCtx.sessionId}`, handlePeers);

        return () => {
            stateCtx.socket.removeAllListeners(`refreshPeers_${stateCtx.sessionId}`);
            stateCtx.socket.removeAllListeners(`updateData_${stateCtx.sessionId}`);
        };
    }, [stateCtx.sessionId, stateCtx.currentUser]);

    useEffect(() => {
        (async () => {
            const { speakerViewMode, virtualEventSession, virtualEventUser } = stateCtx;
            const virtualEventSessionStatus = virtualEventSession.data.status;
            const roomIsNotClosed =
                virtualEventSessionStatus !== entities.virtualEventSessionEntity.status.closed;
            const userIsPrerecordingWhileRoomIsOpened =
                speakerViewMode === 'prerecording' && roomIsNotClosed;

            const userIsActive = get(virtualEventUser, 'data.isActive');
            const userIsOnAudienceViewWhileBeingActive =
                speakerViewMode === 'audience' && userIsActive;

            const shouldSwitchToLiveMode =
                userIsPrerecordingWhileRoomIsOpened || userIsOnAudienceViewWhileBeingActive;

            if (shouldSwitchToLiveMode) {
                mutationCtx.setSpeakerViewMode(entities.speakerViewMode.live);
            }
        })();
    }, [stateCtx.speakerViewMode, stateCtx.virtualEventSession, stateCtx.virtualEventUser]);

    const isLoading =
        virtualEventUser.fetchRequestStatus === entities.requestStatus.loading ||
        virtualEventSession.fetchRequestStatus === entities.requestStatus.loading;

    if (isLoading) {
        return <LoadingVirtualPage />;
    }

    const { roomType } = virtualEventSession.data;
    const isRoomModerated = roomType === roomTypeEntity.moderated;
    const isRoomRoundTable = roomType === roomTypeEntity.roundTable;

    const { role } = virtualEventUser.data;
    const roleEntity = entities.virtualEventUserEntity.role;

    if (isRoomModerated && role === roleEntity.moderator) {
        return (
            <LoaderTransparent active={isLoading}>
                <Moderator />
            </LoaderTransparent>
        );
    }

    if (isRoomModerated && role === roleEntity.presenter) {
        return (
            <LoaderTransparent active={isLoading}>
                <Presenter />
            </LoaderTransparent>
        );
    }

    if (isRoomRoundTable && role === roleEntity.roundTableHost) {
        return (
            <LoaderTransparent active={isLoading}>
                <RoundTable />
            </LoaderTransparent>
        );
    }

    return (
        <p>
            User is not allowed to access this Virtual Session. Check if you have the proper invite
            and role
        </p>
    );
};

export default VirtualFeatureContent;

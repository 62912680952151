import React from 'react';

export default () => (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22 16C22 17.6569 20.6569 19 19 19V19C17.3431 19 16 17.6569 16 16V15H13.32C12.18 17.42 9.72 19 7 19C3.14 19 0 15.86 0 12C0 8.14 3.14 5 7 5C9.72 5 12.17 6.58 13.32 9H21C22.6569 9 24 10.3431 24 12V13C24 14.1046 23.1046 15 22 15V15V16ZM18 16C18 16.5523 18.4477 17 19 17V17C19.5523 17 20 16.5523 20 16V13H21C21.5523 13 22 12.5523 22 12V12C22 11.4477 21.5523 11 21 11H11.94L11.71 10.33C11.01 8.34 9.11 7 7 7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17C9.11 17 11.01 15.66 11.71 13.67L11.94 13H18V16ZM7 15C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9C8.65 9 10 10.35 10 12C10 13.65 8.65 15 7 15ZM7 11C6.45 11 6 11.45 6 12C6 12.55 6.45 13 7 13C7.55 13 8 12.55 8 12C8 11.45 7.55 11 7 11Z"
            fill="#000"
        />
    </svg>
);

import React from 'react';
import noResultsImage from '../../../assets/images/no-sessions.png';
import styled from 'styled-components';

const NoSessions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 50%;
        height: auto;
    }
`;

const NoUpcomingSessionsPlaceholder = ({ text = 'There are no events happening.' }) => (
    <NoSessions>
        <img src={noResultsImage} alt={text} />
        <span>{text}</span>
    </NoSessions>
);

export default NoUpcomingSessionsPlaceholder;

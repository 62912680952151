import async from 'async';
import sortBy from 'lodash/sortBy';

import {
    findAllLocal,
    getItemWithTypeInfo,
    getLocalItem,
    getLocalAppState,
} from '../../../services/api/db';

const getNote = function (objectId, next) {
    getLocalItem('notes', objectId, next);
};

const getAllNotes = function (next) {
    findAllLocal(
        'notes',
        item => {
            return item.action === 'Add';
        },
        (err, notes) => {
            if (notes && notes.length) {
                async.eachSeries(
                    notes,
                    (item, cb) => {
                        if (!item.objectClass) {
                            cb();
                        } else {
                            getItemWithTypeInfo(
                                item.objectClass,
                                item.objectId,
                                (err, withType) => {
                                    item.object = {
                                        type: withType.type,
                                        name: withType.name,
                                    };
                                    cb(err);
                                },
                            );
                        }
                    },
                    err => {
                        notes = sortBy(notes, o => o.object.name);
                        notes = sortBy(notes, o => o.object.type.plural);
                        next(err, notes);
                    },
                );
            } else {
                next(err, []);
            }
        },
    );
};

const getAllNotesForEvent = next => {
    getLocalAppState((err, config) => {
        if (err) {
            return next(err);
        }

        getAllNotes((err, notes) => {
            if (notes && notes.length) {
                notes = notes.filter(note => {
                    return note.event === config.id;
                });
            }
            next(err, notes);
        });
    });
};

export { getNote, getAllNotesForEvent };

import React, { useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import ConfirmationDialog from '../common/ConfirmatonDialog';
import { CardContent, CardWrapper, TitleContainer, CardButton } from './PosterAndSlidesCard';
import { useVMMutation, useVMState } from '../../containers/main';
import {
    CancelModalButton,
    ContentModalContainer,
    DestructiveModalButton,
} from '../moderator/common/styles';
import HostedSessionMediaPlayer from '../common/HostedSessionMediaPlayer';
import { onDeleteIntroductionVideo } from '../../events/onDeleteIntroductionVideo';
import UploadIntroductionVideoModal from './UploadIntroductionVideoModal';
import onUploadVideoIntroduction from '../../events/onUploadVideoIntroduction';

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    padding: 0 25px;
    margin-bottom: -3px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const IntroductionVideoCard = () => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const { virtualEventUser, virtualEventSession, socket, sessionId } = stateCtx;
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUploadVideoModal, setShowUploadVideoModal] = useState(false);
    const isPrerecording = get(virtualEventUser, 'data.isPrerecording') || false;
    const list = get(virtualEventUser, 'data.recordingInfo.list') || [];
    const uploadedVideoUrl = get(virtualEventUser, 'data.recordingInfo.videoUrl');
    const isUploadedVideo = uploadedVideoUrl && !list.length;
    const videoUrls = get(virtualEventSession, 'data.videoUrls') || [];
    const videoUrl = videoUrls.length && videoUrls[0];
    const videoIsSubmitted = videoUrls.length > 0;

    const onAddVideo = async newVideo => {
        if (!newVideo) {
            return;
        }

        await onUploadVideoIntroduction(stateCtx, mutationCtx, newVideo);
    };

    const onUploadVideoClick = () => {
        if (isUploadedVideo) {
            socket.emit('updateData', {
                objectId: sessionId,
                virtualEventSession: {
                    VirtualEventUsers: [
                        {
                            id: virtualEventUser.data.id,
                            isPrerecording: !isPrerecording,
                        },
                    ],
                },
                userId: virtualEventUser.data.UserId,
            });
        } else {
            setShowUploadVideoModal(true);
        }
    };

    const toggleRecordingStatus = () => {
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isPrerecording: !isPrerecording,
                        isMicrophoneOn: true,
                        isVideoOn: true,
                    },
                ],
            },
            userId: virtualEventUser.data.UserId,
        });
    };

    const onDeleteVideo = async () => {
        await onDeleteIntroductionVideo(stateCtx, mutationCtx);

        setShowDeleteModal(false);
    };

    return (
        <CardWrapper>
            <TitleContainer>
                <PlayCircleOutlineIcon style={{ marginRight: 18 }} />
                Introduction video
            </TitleContainer>
            {!isPrerecording && (
                <>
                    <CardContent>
                        {!videoIsSubmitted
                            ? 'Add an introduction video by either recording one or uploading an existing video file.'
                            : 'Delete current video to record a new one.'}
                    </CardContent>
                    {!videoIsSubmitted && (
                        <ButtonsContainer>
                            <CardButton
                                style={{ marginRight: 12 }}
                                onClick={toggleRecordingStatus}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        toggleRecordingStatus();
                                    }
                                }}
                            >
                                Record video
                            </CardButton>
                            <CardButton
                                onClick={onUploadVideoClick}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        onUploadVideoClick();
                                    }
                                }}
                            >
                                Upload video
                            </CardButton>
                        </ButtonsContainer>
                    )}
                    {videoIsSubmitted && (
                        <HostedSessionMediaPlayer
                            url={videoUrl}
                            primary
                            onDelete={() => setShowDeleteModal(true)}
                        />
                    )}
                </>
            )}
            <ConfirmationDialog
                open={showDeleteModal}
                title="Delete introduction video"
                titleIcon="delete"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                outlinedIcon
                onClose={() => setShowDeleteModal(false)}
                content={
                    <ExtraStyledModalContentContainer>
                        Your introduction video will be permanently deleted and cannot be recovered.
                    </ExtraStyledModalContentContainer>
                }
                buttons={[
                    <DestructiveModalButton
                        key="b1"
                        flat
                        onClick={onDeleteVideo}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                onDeleteVideo();
                            }
                        }}
                    >
                        Delete
                    </DestructiveModalButton>,
                    <CancelModalButton
                        key="b2"
                        flat
                        onClick={() => setShowDeleteModal(false)}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                setShowDeleteModal(false);
                            }
                        }}
                    >
                        Cancel
                    </CancelModalButton>,
                ]}
            />
            {showUploadVideoModal && (
                <UploadIntroductionVideoModal
                    onClose={() => setShowUploadVideoModal(false)}
                    onAdd={onAddVideo}
                    fileName={`Introduction_video_${virtualEventUser.data.UserId}`}
                />
            )}
        </CardWrapper>
    );
};

export default IntroductionVideoCard;

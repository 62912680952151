import React from 'react';
import InfoBar from '../InfoBar';
import SectionsWrapper from '../SectionsWrapper';
import RepresentativesList from '../Representatives';
import Slideshow from '../../../components/Slideshow';

const InfoTab = props => {
    const items = props.item.sections.filter(
        i =>
            (i.isParent || i.type !== 'timeslot') &&
            i.type !== 'link' &&
            i.type !== 'place' &&
            i.type !== 'programelement',
    );
    const renderRepresentatives = item => {
        if (!item || !item.representatives || !item.representatives.length) {
            return;
        }

        return <RepresentativesList representatives={props.item.representatives} />;
    };

    return (
        <React.Fragment>
            {props.item.info && props.item.info.text && (
                <InfoBar info={props.item.info} typeParams={props.item.typeParams} {...props} />
            )}
            {renderRepresentatives(props.item)}
            <SectionsWrapper item={{ sections: items }} clickOpenDetail={props.clickOpenDetail} />
            {props.item.params &&
                props.item.params.slideshow &&
                props.item.params.slideshow.length > 0 && (
                    <Slideshow data={props.item.params.slideshow} />
                )}
        </React.Fragment>
    );
};

export default InfoTab;

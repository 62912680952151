import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'react-md';

import uploadIconSrc from '../../../../../../assets/icons/upload.png';
import ProgressBar from '../common/ProgressBar';
import ConfirmationDialog from '../common/ConfirmatonDialog';
import getVideoDuration from '../../utils/getVideoDuration';
import { useVMState } from '../../containers/main';
import { ContentModalContainer } from '../common/DialogStyles';
import { uploadVideoToS3 as uploadVideoToS3Service } from '../../services/VirtualEventSession';
import { useTheme } from '../../../../../../components/Theme/ThemeContext';
import { useDropzone } from 'react-dropzone';
import useAccessibleElement from '../../../../../../hooks/useAccessibleElement';

const StyledFillButton = styled(Button)`
    width: 115px !important;
    min-width: 115px !important;
    height: 40px !important;
    border-radius: 6px !important;
    background-color: ${props => (props.color ? props.color : '#00a480')} !important;
    color: #fff !important;
    position: relative;
    padding: 8px 16px;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 24px;
    margin-bottom: 12px;

    &:disabled,
    &[disabled] {
        color: rgba(0, 0, 0, 0.38) !important;
        background-color: #b3b3b3 !important;
    }
`;

const FileInput = styled.input`
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 85px;
    height: 36px;
    width: 85px;
    cursor: pointer;
`;

const UploadIcon = styled.img`
    width: 32px;
    margin: 58px 0 8px 0;
`;

const ErrorMessage = styled.div`
    color: rgb(221, 44, 0);
    font-size: 14px;
    padding: 16px 0;
`;

const ExtraStyledContentModalContainer = styled(ContentModalContainer)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px dashed rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    svg {
        border-radius: 50%;
        cursor: pointer;
        padding: 8px;
        font-size: 50px !important;
        font-weight: bold;
        background: transparent;
        margin-right: 0;
        color: rgba(0, 0, 0, 0.38);
        margin-top: 32px;
    }
`;

const Text = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 17px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.38);
`;

const SmallText = styled(Text)`
    font-size: 13px;
    line-height: 20px;
`;

const videoType = {
    SLIDES: 'share',
    SPEAKER: 'video',
};

const uploadStatuses = {
    INITIAL: 'initial',
    UPLOADING: 'uploading',
    DONE: 'done',
};

const MAXIMUM_ALLOWED_SIZE_BYTES = 1048576000; // 1GB
const MAXIMUM_CHARACTERS_IN_NAME = 100;

const UploadIntroductionVideoModal = ({ onClose, onAdd, fileName }) => {
    const stateCtx = useVMState();
    const { theme } = useTheme();
    const { sessionId } = stateCtx;

    const [videoFile, setVideoFile] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [videoName, setVideoName] = useState(null);
    const [recordingName, setRecordingName] = useState('');
    const [loading, setLoading] = useState(false);
    const [videoStatus, setVideoStatus] = useState(uploadStatuses.INITIAL);
    const [duration, setDuration] = useState('00:00');
    const [showSizeWarning, setShowSizeWarning] = useState(false);
    const [videoUploadedPercentage, setVideoUploadedPercentage] = useState(0);
    const [hasSaved, setHasSaved] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const isSaveDisabled =
        !videoFile || !recordingName || loading || videoStatus === uploadStatuses.UPLOADING;
    const open = true;

    useEffect(() => {
        if (canSave) {
            onSave();
        }
    }, [isSaveDisabled, hasSaved, videoUrl, duration, canSave]);

    const onVideoChange = async (files, type) => {
        setCanSave(false);
        const file = files && files.length && files[0];

        setShowSizeWarning(false);
        if (!file) {
            return;
        }
        if (file.size > MAXIMUM_ALLOWED_SIZE_BYTES) {
            setShowSizeWarning(true);
            return;
        }

        setVideoFile(file);

        if (!recordingName) {
            const nameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
            setRecordingName(nameWithoutExtension.slice(0, MAXIMUM_CHARACTERS_IN_NAME));
        }

        setVideoStatus(uploadStatuses.UPLOADING);

        const response = await uploadVideoToS3(file, type);
        const splitKey = response.key.split('/');
        // this will be ${Date.now()}.mp4
        const actualFileName = splitKey[splitKey.length - 1];

        setVideoName(actualFileName);
        setVideoStatus(uploadStatuses.DONE);
        setVideoUrl(response.link);

        const durationTime = await getVideoDuration(response.link);
        const measuredDuration = convertDurationTimeToString(durationTime);
        setDuration(measuredDuration);
        setCanSave(true);
    };

    const uploadVideoToS3 = async (file, type) => {
        const onUploadProgress = value => {
            setVideoUploadedPercentage(value);
        };

        const response = await uploadVideoToS3Service(file, sessionId, type, onUploadProgress);

        return response;
    };

    const convertDurationTimeToString = duration => {
        if (!duration) {
            return '00:00:00';
        }

        const measuredTime = new Date(null);

        measuredTime.setSeconds(duration);

        return measuredTime.toISOString().substr(11, 8);
    };

    const onSave = async () => {
        if (!isSaveDisabled && !hasSaved && videoUrl) {
            try {
                setLoading(true);

                await onAdd({
                    shareVideoName: videoName,
                    videoUrl,
                    name: fileName || recordingName,
                    duration,
                });

                setLoading(false);
                setHasSaved(true);
                onClose();
            } catch (err) {
                console.log(err);
                setLoading(false);
                setHasSaved(true);
            }
        }
    };

    const onDrop = useCallback(acceptedFiles => {
        onVideoChange(acceptedFiles, videoType.SLIDES);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        maxSize: MAXIMUM_ALLOWED_SIZE_BYTES,
        onDrop: onDrop,
    });

    useAccessibleElement({
        ariaControls: 'video-upload-aria-controls',
        inputElementId: 'video-upload-input',
    });

    return (
        <div className="eureka-react">
            <ConfirmationDialog
                open={open}
                title="Upload video"
                withCloseButton
                onClose={onClose}
                noButtons
                extraStyles="padding-bottom: 0;"
                content={
                    !videoFile ? (
                        <ExtraStyledContentModalContainer {...getRootProps({ refKey: 'innerRef' })}>
                            <input {...getInputProps()} />
                            <UploadIcon src={uploadIconSrc} />
                            <Text style={{ marginBottom: 8 }}>Drag and drop video</Text>
                            <SmallText style={{ marginBottom: 8 }}>or</SmallText>
                            <StyledFillButton
                                color={theme.primary}
                                aria-controls="video-upload-aria-controls"
                            >
                                Browse files
                                <FileInput
                                    id="video-upload-input"
                                    type="file"
                                    accept="video/mp4"
                                    onChange={e => onVideoChange(e.target.files, videoType.SLIDES)}
                                    disabled={videoStatus === uploadStatuses.UPLOADING}
                                />
                            </StyledFillButton>
                            <SmallText style={{ marginBottom: 51 }}>mp4 file up to 1GB</SmallText>
                            {showSizeWarning && (
                                <ErrorMessage>
                                    The file can&apos;t be larger than 1 GB.
                                </ErrorMessage>
                            )}
                        </ExtraStyledContentModalContainer>
                    ) : (
                        <ExtraStyledContentModalContainer
                            style={{ border: 'none', alignItems: 'flex-start', paddingBottom: 10 }}
                        >
                            <Text style={{ fontSize: 15, marginBottom: 4, marginTop: 32 }}>
                                Processing - {fileName}
                            </Text>
                            <ProgressBar value={videoUploadedPercentage} />
                        </ExtraStyledContentModalContainer>
                    )
                }
            />
        </div>
    );
};

export default UploadIntroductionVideoModal;

import React from 'react';
import styled from 'styled-components';
import { Divider, FontIcon, ListItem } from 'react-md';
import * as palette from '../General/Variables';
import { NavLink, Route } from 'react-router-dom';

const StyledLink = styled(NavLink)`
    font-weight: 500;
`;

const ListItemStyled = styled(ListItem)`
    background-color: ${props => (props.active ? '#E0E0E0' : 'transparent')};
`;

const MyLink = ({ item, setTitle, color, divider, rightComponent }) => (
    <Route exact path={item.customTo}>
        {({ match }) => {
            return (
                <React.Fragment>
                    <ListItemStyled
                        component={StyledLink}
                        active={!!match}
                        to={item.customTo}
                        primaryText={item.title}
                        primaryTextStyle={{
                            fontSize: '14px',
                            color: palette.COLOR_TEXT,
                        }}
                        leftIcon={
                            <FontIcon
                                style={{ color: color }}
                                iconClassName={`material-icons ${
                                    item.outlinedIcon && 'material-icons-outlined'
                                }`}
                            >
                                {item.icon}
                            </FontIcon>
                        }
                        onClick={() => setTitle(item.title)}
                        rightIcon={rightComponent}
                    />
                    {divider && <Divider />}
                </React.Fragment>
            );
        }}
    </Route>
);

export default MyLink;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { CardWrapper, TitleContainer } from './PosterAndSlidesCard';
import StreamPlayer from '../common/StreamPlayer';
import { useVMMutation, useVMState } from '../../containers/main';
import { colorTheme } from '../../constants/colors';
import { startAudioRecording, stopAudioRecording } from '../../services/VirtualEventSession';
import { getTimer } from '../../../../utils';
import {
    CancelModalButton,
    ContentModalContainer,
    SaveModalButton,
} from '../moderator/common/styles';
import ConfirmationDialog from '../common/ConfirmatonDialog';
import { useTheme } from '../../../../../../components/Theme/ThemeContext';
import VolumeUpIconOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import AudioPlayer from '../common/AudioPlayer';

const StreamWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 208px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    overflow: hidden;
`;

const TimerValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: rgba(114, 114, 114, 1);
    transform: translateY(0.5px);
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 21px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: ${colorTheme.WHITE};
`;

const RecordingStatus = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    color: ${colorTheme.WHITE};
    margin-bottom: ${props => (props.nomargin ? 0 : '16px')};
`;

const RecordButtonWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid ${colorTheme.WHITE};
    height: 50px;
    width: 50px;
    overflow: hidden;
`;

const RecordingTimer = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: ${colorTheme.WHITE};
    margin-bottom: 8px;
`;

const PlayRecordingButton = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: ${colorTheme.SYSTEM_ERROR};
    transform: translateY(0.5px);
    cursor: pointer;
`;

const StopRecordingButton = styled.div`
    height: 20px;
    width: 20px;
    background-color: ${colorTheme.SYSTEM_ERROR};
    border-radius: 4px;
    cursor: pointer;
`;

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    padding: 0 25px;
    margin-bottom: -3px;
`;

const AudioRecordingView = () => {
    const { theme } = useTheme();
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const {
        virtualEventUser,
        virtualEventSession,
        streams,
        localStream,
        socket,
        sessionId,
        timer,
        isReviewingAudio,
        audioRecording,
    } = stateCtx;
    const currentUserId = get(virtualEventUser, 'data.UserId') || 0;
    const isUserRecording = get(virtualEventUser, 'data.audioRecordingInfo.recording', false);
    const startTimerMoment = get(
        virtualEventUser,
        'data.audioRecordingInfo.startTimerMoment',
        null,
    );
    const audioRecordingPresentationUrl = get(
        virtualEventUser,
        'data.audioRecordingPresentationUrl',
    );
    const hasFinishedAudioRecording = !!audioRecordingPresentationUrl;
    const audioRecordingInfo = get(virtualEventUser, 'data.audioRecordingInfo');
    const hasUploadedAudioRecording = !!audioRecordingInfo;
    const audioUrls = get(virtualEventSession, 'data.audioUrls') || [];
    const list = get(virtualEventUser, 'data.audioRecordingInfo.list', []);
    const audioInfo = list.find(info => info.type === 'audio');
    const audioUrl =
        audioRecordingPresentationUrl &&
        audioInfo &&
        audioInfo.sid &&
        `${audioRecordingPresentationUrl}/audio/${audioInfo.sid}_${sessionId}.mp4`;
    const audioIsSubmitted = audioUrls.length > 0;
    const hasPreliminaryAudio = hasFinishedAudioRecording && hasUploadedAudioRecording;
    const shouldDisplayReviewAudioModal =
        hasPreliminaryAudio && !audioIsSubmitted && !isReviewingAudio;
    const isAudioRecording = get(virtualEventUser, 'data.isAudioRecording', false);
    const [timerValue, setTimerValue] = useState(-2);
    const [time, setTime] = useState('00:00');
    const intervalRef = useRef(0);
    const streamToUse = useMemo(() => streams.find(st => st.streamId === currentUserId), [
        streams,
        localStream,
    ]);
    const secondsPassedRef = useRef(0);

    const startCountDown = async callback => {
        if (!stateCtx.useDevices) {
            mutationCtx.startUsingDevices();
        }

        mutationCtx.setTimer({
            callback,
            time: 4,
        });

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isVideoOn: false,
                        isMicrophoneOn: true,
                        audioRecordingInfo: null,
                    },
                ],
            },
        });
    };

    const startRecording = async () => {
        const userId = get(virtualEventUser, 'data.UserId');
        mutationCtx.startAudioRecording();

        try {
            await startAudioRecording(sessionId, {
                audioRecording: true,
                userId,
            });

            socket.emit('updateData', { objectId: sessionId });
        } catch (err) {
            mutationCtx.stopAudioRecording();
            console.log(err);
        }
    };

    const stopRecording = async () => {
        const userId = get(virtualEventUser, 'data.UserId');

        mutationCtx.stopAudioRecording();

        secondsPassedRef.current = 0;
        setTime('00:00');

        await stopAudioRecording(sessionId, { userId });

        socket.emit('updateData', { objectId: sessionId });
    };

    const toggleRecordingStatus = () => {
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isAudioRecording: !isAudioRecording,
                    },
                ],
            },
            userId: virtualEventUser.data.UserId,
        });

        mutationCtx.setIsRecordingAudio(false);
    };

    const addAudioToRoom = () => {
        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isAudioRecording: !isAudioRecording,
                    },
                ],
                audioUrls: [audioUrl],
            },
            userId: virtualEventUser.data.UserId,
        });

        mutationCtx.setIsRecordingAudio(false);
    };

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        if (!timer) {
            setTimerValue(-2);
            return;
        }

        setTimerValue(timer.time);
        intervalRef.current = setInterval(() => {
            setTimerValue(val => val - 1);
        }, 1000);

        return () => clearInterval(intervalRef.current);
    }, [timer]);

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        if (!isAudioRecording || !audioRecording || !isUserRecording) {
            setTime('00:00');
            secondsPassedRef.current = 0;
            return;
        }

        if (startTimerMoment) {
            intervalRef.current = setInterval(() => {
                secondsPassedRef.current = secondsPassedRef.current + 1;
                setTime(getTimer(secondsPassedRef.current * 1000));
            }, 1000);
        }

        return () => clearInterval(intervalRef.current);
    }, [audioRecording, isAudioRecording, isUserRecording, startTimerMoment]);

    useEffect(() => {
        if (timer && timerValue === -1) {
            const { callback } = timer;

            callback();
            mutationCtx.setTimer(null);
        }
    }, [timer, timerValue]);

    if (!streamToUse) {
        return null;
    }

    if (isReviewingAudio) {
        return (
            <CardWrapper>
                <TitleContainer>
                    <VolumeUpIconOutlinedIcon style={{ marginRight: 18 }} />
                    Audio description
                </TitleContainer>
                <AudioPlayer url={audioUrl} primary />
            </CardWrapper>
        );
    }

    return (
        <CardWrapper>
            <StreamWrapper>
                <StreamPlayer
                    key={streamToUse.streamId}
                    className={'main-stream-profile'}
                    showProfile={stateCtx.profile}
                    isVideoOn={false}
                    muted
                    stream={streamToUse}
                    uid={streamToUse.getId()}
                    domId={`stream-player-${streamToUse.getId()}`}
                />
                {timerValue < 0 &&
                    !audioRecording &&
                    !isUserRecording &&
                    !shouldDisplayReviewAudioModal && (
                        <>
                            <RecordingStatus>Record audio</RecordingStatus>
                            <RecordButtonWrapper
                                onClick={() => startCountDown(startRecording)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        startCountDown(startRecording);
                                    }
                                }}
                            >
                                <PlayRecordingButton />
                            </RecordButtonWrapper>
                        </>
                    )}
                {timerValue >= 0 &&
                    !audioRecording &&
                    !isUserRecording &&
                    !shouldDisplayReviewAudioModal && (
                        <>
                            <RecordingStatus>Recording in</RecordingStatus>
                            <RecordButtonWrapper>
                                <TimerValue data-qa="pre-recording-timer-countdown">
                                    <span>{timerValue + 1}</span>
                                </TimerValue>
                            </RecordButtonWrapper>
                        </>
                    )}
                {audioRecording && isUserRecording && !shouldDisplayReviewAudioModal && (
                    <>
                        <RecordingStatus nomargin>Recording</RecordingStatus>
                        <RecordingTimer>{time}</RecordingTimer>
                        <RecordButtonWrapper onClick={stopRecording}>
                            <StopRecordingButton />
                        </RecordButtonWrapper>
                    </>
                )}
                <ConfirmationDialog
                    open={shouldDisplayReviewAudioModal}
                    title="Add audio description"
                    titleIcon="volume_up"
                    iconColor="rgba(0, 0, 0, 0.87)"
                    iconWrapperColor="#EFEFEF"
                    outlinedIcon
                    withCloseButton
                    content={
                        <ExtraStyledModalContentContainer>
                            Do you want to add the audio description directly or review it first?
                        </ExtraStyledModalContentContainer>
                    }
                    onClose={toggleRecordingStatus}
                    buttons={[
                        <SaveModalButton
                            key="AVDA"
                            flat
                            onClick={addAudioToRoom}
                            backgroundcolor={theme.primary}
                            noTextCapitalize
                        >
                            Add as audio description
                        </SaveModalButton>,
                        <CancelModalButton
                            key="RVDR"
                            flat
                            onClick={() => mutationCtx.setIsReviewingAudio(true)}
                            noTextCapitalize
                        >
                            Review audio
                        </CancelModalButton>,
                    ]}
                />
            </StreamWrapper>
        </CardWrapper>
    );
};

export default AudioRecordingView;

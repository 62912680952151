import React, { useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import { List, ListItem } from 'react-md';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import NavigationBar from '../../../components/DetailHeader/NavigationBar.js';
import { navigateTo } from '../../../components/Navigation/NavigationLink';
import Loader from '../../../components/General/Loader.js';
import * as palette from '../../../components/General/Variables.js';
import { makeItem } from '../../../services/api/data';
import TimeslotDetailHeader from '../../../components/DetailHeader/Timeslot';
import TimeslotVirtualSession from '../../../components/DetailHeader/TimeslotVirtualSession';
import { APPOINTMENT_PARTICIPANT_STATUS } from '../constants';
import Dropdown from '../../../components/General/Dropdown';
import AppointmentModal from './AppointmentModal';
import Auth from '../../../services/api/auth';
import AppointmentService from '../services/AppointmentService';
import { getVirtualEventSessionByAppointmentId } from '../../../services/api/eureka';
import UserAvatar from '../../../components/General/UserAvatar';
import { getString } from '../../../services/api/store';
import {
    acceptAppointment,
    declineAppointment,
    deleteAppointment as deleteEurekaAppointment,
} from '../../Talk/actions';
import eventBus from '../../../utils/eventBus';
import { ConfirmationModel } from '../components/AppointmentConfirmationModal';
import ReminderService from '../../Reminders/ReminderService';
import GroupButtons from '../../../components/General/GroupButtons';
import { DetailDrawer } from '../../../components/General/DetailDrawer';

const Scrollable = styled.section.attrs({
    className: 'scrollable-div',
})`
    height: 100%;
    overflow: auto;
`;

const ContentWrapper = styled.div`
    position: relative;
`;

const TextWrapper = styled.div`
    padding-left: 16px;
    margin-bottom: 16px;
`;

const Title = styled.div`
    padding-top: 16px 16px 8px 16px;
    text-align: left;
    margin-top: 0;
    font-family: 'Cabin', sans-serif;
    font-size: 21px;
    line-height: 24px;
    font-style: normal;
    font-weight: bold;
    color: #1e1e1f;
`;

const SectionTitle = styled.div`
    width: 87px;
    height: 18px;
    font-family: Cabin;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
    text-transform: uppercase;
    margin-top: 42px;
    margin-bottom: 12px;
`;

const Paragraph = styled.div`
    width: 448px;
    height: 20px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
`;

const DropdownWrapper = styled.div`
    position: absolute;
    top: 58px;
    right: 18px;
`;

const CancelledLabel = styled.span`
    border-radius: 24px;
    background-color: #d50000;
    padding: 2px 8px;
    text-transform: uppercase;
    font-family: 'Cabin', sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    margin: 0 10px;
`;

const StyledList = styled(List)`
    margin-left: -16px !important;
`;

const AppointmentDetailPage = props => {
    const navBar = useRef();
    const [item, setItem] = useState({});
    const [activeRadioBoxIndex, setActiveRadioBoxIndex] = useState(null);
    const [virtualEventSession, setVirtualEventSession] = useState(null);
    const dispatch = useDispatch();

    const dropdownItems = [
        {
            text: getString('edit') || 'Edit',
            icon: 'edit',
            onClick: () => setShowAppointmentModal(true),
        },
        {
            text: getString('deleteButton') || 'Delete',
            icon: 'delete',
            onClick: () => setShowDeleteConfirmationModal(true),
        },
    ];
    const invtedUserDropdownItems = [
        {
            text: getString('deleteButton') || 'Delete',
            icon: 'delete',
            onClick: () => deleteAppointmentByInvitedUser(true),
        },
    ];

    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [isOrganizer, setIsOrganizer] = useState(false);

    const user = item ? item.User : {};
    const members = item ? item.members : [];

    let scroll = 0;
    let scrollDirection = 'UP';
    const [loading, setLoading] = useState(true);
    const { objectId } = props;
    const _handleScroll = throttle(() => {
        const newScroll = document.getElementById(`scrollable-${item.id}`).scrollTop;

        if (scroll > newScroll && scrollDirection !== 'UP' && newScroll > 48) {
            navBar.current.className = 'fixed-navbar';
            scrollDirection = 'UP';
        } else if (scroll <= newScroll && scrollDirection !== 'DOWN' && newScroll > 48) {
            navBar.current.className = 'auto-navbar';
            scrollDirection = 'DOWN';
        }

        scroll = newScroll;
    }, 100);

    useEffect(() => {
        document.getElementById('root').className = 'noscroll';
        navBar.current.className = 'fixed-navbar';

        _handleScroll();

        details(props.objectId);
        return function cleanup() {
            document.getElementById('root').className = '';
        };
    }, [objectId]);

    useEffect(() => {
        const appointmentId = props.objectId;

        eventBus.on(`refreshAppointment_${appointmentId}`, () => {
            details(appointmentId);
        });
    }, []);

    const details = objectId => {
        if (!objectId) {
            return;
        }

        setLoading(true);
        navBar.current.className = 'fixed-navbar';
        scrollDirection = 'UP';
        makeItem(objectId, 'appointment', async (err, item) => {
            if (err) {
                console.log(err);
            } else {
                const { members } = item;

                const status = members && members.length && members[0].AppointmentGroup.status;
                let activeIndex = null;

                if (status === APPOINTMENT_PARTICIPANT_STATUS.ACCEPTED) {
                    activeIndex = 0;
                } else if (status === APPOINTMENT_PARTICIPANT_STATUS.DECLINED) {
                    activeIndex = 1;
                }

                setItem(item);
                setIsOrganizer(item.UserId === Auth.getUser().id);
                setActiveRadioBoxIndex(activeIndex);

                if (item && item.isVirtual) {
                    const virtualEventSession = await getVirtualEventSessionByAppointmentId(
                        item.id,
                    );

                    setVirtualEventSession(virtualEventSession);
                }

                setLoading(false);
            }
        });
    };

    const onRadioBoxClick = async index => {
        let newAppointmentStatus;

        if (index === 0) {
            await dispatch(acceptAppointment(item));
            newAppointmentStatus = APPOINTMENT_PARTICIPANT_STATUS.DECLINED;
        } else if (index === 1) {
            const updatedAppointment = await dispatch(declineAppointment(item));
            ReminderService.addItemToScheduledList(updatedAppointment, { isAppointment: true });
            newAppointmentStatus = APPOINTMENT_PARTICIPANT_STATUS.ACCEPTED;
        }

        setActiveRadioBoxIndex(index);
        item.members[0].AppointmentGroup.status = newAppointmentStatus;
        details(props.objectId);
    };

    const formatTime = (start, end) => {
        return `${start.format('ddd').toUpperCase()}, ${start.format('HH:mm')} - ${end.format(
            'HH:mm',
        )}`;
    };

    const getAppointmentStatus = statusCode => {
        switch (statusCode) {
            case APPOINTMENT_PARTICIPANT_STATUS.WAITING:
                return getString('appointmentStatusWaiting') || 'Waiting';
            case APPOINTMENT_PARTICIPANT_STATUS.ACCEPTED:
                return getString('appointmentStatusAccepted') || 'Accepted';
            case APPOINTMENT_PARTICIPANT_STATUS.DECLINED:
                return getString('appointmentStatusDeclined') || 'Declined';
            default:
                return '';
        }
    };

    const onAppointmentModalClose = () => {
        eventBus.emit('refreshMyProgramme');
        setShowAppointmentModal(false);
        details(props.objectId);
    };

    const deleteAppointment = async () => {
        dispatch(deleteEurekaAppointment(item));
        setShowDeleteConfirmationModal(false);
        eventBus.emit('updateMyProgrammeCount');
        eventBus.emit('refreshMyProgramme');
        props.history.goBack();
    };

    const deleteAppointmentByInvitedUser = async () => {
        await AppointmentService.removeAppointment(item.reference);
        props.history.goBack();
    };

    const onUserClick = id => {
        const { match, history, location } = props;

        navigateTo({
            match,
            history,
            location,
            params: {
                type: 'profile',
                userId: id,
            },
        });
    };

    if (item && item.deleted && isOrganizer) {
        props.history.goBack();
        return null;
    }

    const currentUser = Auth.getUser();
    const isCurrentUserCreator = currentUser && user && currentUser.id === user.id;
    const isCurrentUserMember =
        currentUser && members && members.length && members[0].id === currentUser.id;

    if (!loading && !isCurrentUserCreator && !isCurrentUserMember) {
        props.history.goBack();
        return null;
    }

    return (
        <React.Fragment>
            <DetailDrawer>
                <Scrollable id={`scrollable-${item.id}`} onScroll={_handleScroll}>
                    <div ref={navBar}>
                        {loading ? (
                            <Loader />
                        ) : (
                            <ContentWrapper>
                                <NavigationBar
                                    scrollDirection={scrollDirection}
                                    closePath={props.location.pathname}
                                    typeName={item.typeName}
                                    background={palette.COLOR_WHITE}
                                />
                                <TimeslotDetailHeader
                                    start={item.start}
                                    end={item.end}
                                    isAppointment={true}
                                />
                                {isOrganizer && (
                                    <DropdownWrapper>
                                        <Dropdown items={dropdownItems} />
                                    </DropdownWrapper>
                                )}
                                {!isOrganizer && item.deleted && (
                                    <DropdownWrapper>
                                        <Dropdown items={invtedUserDropdownItems} />
                                    </DropdownWrapper>
                                )}
                                <TextWrapper>
                                    <Title>
                                        {item.name}
                                        {item.deleted && <CancelledLabel>Cancelled</CancelledLabel>}
                                    </Title>
                                </TextWrapper>
                                {item.isVirtual && !item.deleted && (
                                    <TimeslotVirtualSession
                                        id={item.reference}
                                        params={{
                                            backgroundType: '',
                                            virtualSession: 'virtual-room',
                                            virtualSessionType: 'Appointment virtual room',
                                        }}
                                        virtualEventSession={virtualEventSession}
                                    />
                                )}
                                <TextWrapper>
                                    {!isOrganizer && !item.deleted && (
                                        <>
                                            <SectionTitle>
                                                {getString('appointmentAttendingQuestion') ||
                                                    'Attending?'}
                                            </SectionTitle>

                                            <GroupButtons
                                                items={[
                                                    {
                                                        value: 'accept',
                                                        label:
                                                            getString('appointmentButtonAccept') ||
                                                            'Accept',
                                                    },
                                                    {
                                                        value: 'decline',
                                                        label:
                                                            getString('appointmentButtonDecline') ||
                                                            'Decline',
                                                    },
                                                ]}
                                                onActiveIndexChange={newIndex =>
                                                    onRadioBoxClick(newIndex)
                                                }
                                                activeIndex={activeRadioBoxIndex}
                                            />
                                        </>
                                    )}

                                    <SectionTitle>Description</SectionTitle>
                                    <Paragraph>{item.subNameList || '-'}</Paragraph>

                                    <SectionTitle>
                                        {getString('appointmentMembers') || 'Participants'}
                                    </SectionTitle>
                                    <StyledList>
                                        <ListItem
                                            primaryText={`${user.firstName} ${user.lastName}`}
                                            secondaryText={
                                                getString('appointmentOrganiser') || 'Organizer'
                                            }
                                            leftAvatar={<UserAvatar user={user} />}
                                            onClick={() => onUserClick(user.id)}
                                        />
                                        {members && members.length && (
                                            <ListItem
                                                primaryText={`${members[0].firstName} ${members[0].lastName}`}
                                                secondaryText={getAppointmentStatus(
                                                    members[0].AppointmentGroup.status,
                                                )}
                                                leftAvatar={<UserAvatar user={members[0]} />}
                                                onClick={() => onUserClick(members[0].id)}
                                            />
                                        )}
                                    </StyledList>
                                </TextWrapper>
                            </ContentWrapper>
                        )}
                    </div>
                </Scrollable>
            </DetailDrawer>
            {showAppointmentModal && (
                <AppointmentModal
                    appointment={item}
                    participant={item && item.members && item.members.length ? item.members[0] : {}}
                    onClose={onAppointmentModalClose}
                />
            )}
            <ConfirmationModel
                showModal={showDeleteConfirmationModal}
                title={
                    getString('createappointment.appointmentDeleteDialogTitle') ||
                    'Delete appointment?'
                }
                text={
                    getString('createappointment.appointmentDeleteDialogMessage') ||
                    'Appointment will be removed from your calendar and that of the invited attendee.'
                }
                confirm={deleteAppointment}
                cancel={() => setShowDeleteConfirmationModal(false)}
                confirmText={
                    getString('createappointment.appointmentDeleteButtonTitle ') || 'Delete'
                }
                cancelText={getString('createappointment.appointmentCancelButtonTitle') || 'Cancel'}
            />
        </React.Fragment>
    );
};

export default withRouter(AppointmentDetailPage);

// action types configurations
const REQUEST_TYPE = 'REQUEST';
const SUCCESS_TYPE = 'SUCCESS';
const FAILURE_TYPE = 'FAILURE';

export const createRequestTypes = base => {
    const types = [REQUEST_TYPE, SUCCESS_TYPE, FAILURE_TYPE];
    return types.reduce((acc, type) => {
        acc[type] = `${base}_${type}`;
        return acc;
    }, {});
};

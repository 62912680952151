import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import { FontIcon } from 'react-md';

import { useVMMutation, useVMState } from '../../../../containers/main';
import VideoWrapper from './VideoWrapper';
import entities from '../../../../constants/entities';
import onMakePrerecordingActive from '../../../../events/onMakePrerecordingActive';
import { FIFTY_MILL, FORTY_MILL, THIRTY_MILL, TWENTY_MILL } from '../../../../constants/values';
import onDeletePrerecordingUrlByVirtualEventUserId from '../../../../events/onDeletePrerecordingUrlByVirtualEventUserId';
import UploadVideoModal from './UploadVideoModal';
import ConfirmationDialog from '../../../common/ConfirmatonDialog';
import onAddVideoUrl from '../../../../events/onAddVideoUrl';
import onDeleteUploadedVideoById from '../../../../events/onDeleteUploadedVideo';
import ConversionModalDialog from '../../../common/ConversionModalDialog';
import {
    updateVirtualEventUser,
    stopPreRecordings,
    playPreRecordings,
} from '../../../../services/VirtualEventSession';
import {
    CancelModalButton,
    ContentModalContainer,
    DestructiveModalButton,
} from '../../common/styles';

const PrerecordingsTitle = styled.div`
    font-family: 'Cabin', sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: rgba(0, 0, 0, 0.87) !important;
    margin-top: 16px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
        display: flex;
        align-items: center;
        svg {
            margin-right: 12px;
        }
    }
`;

const Upload = styled(FontIcon)`
    border-radius: 50%;
    cursor: pointer;
    padding: 12px;
    font-size: 20px !important;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);

    &:hover {
        color: rgba(0, 0, 0, 0.87);
        background: #f1f1f3;
    }
`;

const Wrapper = styled.div`
    display: flex;
    height: 50%;
    flex-direction: column;
    overflow: hidden;
    padding: 0 16px;
`;

const VideosWrapper = styled.div`
    padding: 10px 0;
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
`;

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    padding: 0 25px;
    margin-bottom: -3px;
`;

const MODAL_STATE = {
    closed: 0,
    opened: 1,
    confirmed: 2,
};

const Recordings = () => {
    const [preRecordingsAndVideos, setPreRecordingsAndVideos] = useState([]);
    const [reorderItems, setReorderItems] = useState(true);

    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();

    const {
        showPreventStopDialogWithCallback,
        switchingPreRecording,
        virtualEventSession,
        streams,
        socket,
        sessionId,
        videoIsPlaying,
    } = stateCtx;

    const virtualEventUsers = get(virtualEventSession, 'data.VirtualEventUsers', []);
    let videoUrls = get(virtualEventSession, 'data.videoUrls') || [];
    const { mode, activeVideoId } = virtualEventSession.data;
    const isPanelDiscussion = mode === entities.virtualEventSessionEntity.mode.panelDiscussion;
    const [loading, setLoading] = useState(false);
    const [showUploadVideoModal, setShowUploadVideoModal] = useState(false);

    const [showConversionModal, setShowConversionModal] = useState(false);
    const [showStopVideoDialog, setShowStopVideoDialog] = useState(false);
    const [showSwitchVideoToUser, setShowSwitchVideoToUser] = useState(false);
    const [showDeleteVideoDialog, setShowDeleteVideoDialog] = useState(false);
    const [showStopVideoAndSwitchDialog, setShowStopVideoAndSwitchDialog] = useState(
        MODAL_STATE.closed,
    );
    const [isLoading, setIsLoading] = useState(false);
    const [prerecordingIndex, setPrerecordingIndex] = useState({
        user: null,
        index: null,
    });
    const userWithActiveVideo = get(virtualEventSession, 'data.VirtualEventUsers', []).find(
        user => user.hasVideoActive,
    );
    const videoToDeleteRef = useRef({});

    useEffect(() => {
        const virtualEventSessionId = get(virtualEventSession, 'data.id', 0);
        const activeVideo = videoUrls.find(video => video.id === activeVideoId);
        const filteredStreams = (streams || []).filter(
            st =>
                st.streamId === userWithActiveVideo?.UserId + TWENTY_MILL ||
                st.streamId === userWithActiveVideo?.UserId + THIRTY_MILL ||
                st.streamId === virtualEventSessionId + FORTY_MILL ||
                st.streamId === virtualEventSessionId + FIFTY_MILL,
        );

        mutationCtx.setVideoIsPlaying(
            filteredStreams.length === 2 ||
                (activeVideoId && filteredStreams.length === 1) ||
                (activeVideo && activeVideo.isRTMP),
        );

        if (
            (loading && (filteredStreams.length === 2 || filteredStreams.length === 0)) ||
            (activeVideoId && filteredStreams.length === 1)
        ) {
            setLoading(false);
        }
    }, [streams, userWithActiveVideo]);

    useEffect(() => {
        setShowSwitchVideoToUser(videoIsPlaying && !!showPreventStopDialogWithCallback);
        setShowStopVideoDialog(false);
    }, [showPreventStopDialogWithCallback, videoIsPlaying]);

    useEffect(() => {
        if (!isLoading) {
            setReorderItems(true);
        }
    }, [virtualEventUsers, isLoading]);

    useEffect(() => {
        if (reorderItems) {
            const usersWithPreRecordings = virtualEventUsers
                .map((user, index) => ({
                    ...user,
                    arrIndex: index,
                }))
                .filter(user => user.preRecordedPresentationUrl);
            const videosAndPrerecordings = [...usersWithPreRecordings, ...videoUrls];
            let sortedVideosAndPrerecordings = videosAndPrerecordings.sort(
                (user1, user2) =>
                    user1.preRecordedPresentationOrder - user2.preRecordedPresentationOrder,
            );
            sortedVideosAndPrerecordings = sortedVideosAndPrerecordings.map((item, index) => ({
                ...item,
                preRecordedPresentationOrder: index,
            }));
            setPreRecordingsAndVideos(sortedVideosAndPrerecordings);
            setReorderItems(false);
            setIsLoading(false);
        }
    }, [reorderItems]);

    useEffect(() => {
        if (showStopVideoAndSwitchDialog === 2) {
            if (prerecordingIndex.user !== null) {
                onMakePrerecordingActive(stateCtx, mutationCtx, prerecordingIndex.user);
            } else if (prerecordingIndex.index !== null) {
                onMakePrerecordingActive(stateCtx, mutationCtx, null, prerecordingIndex.index);
            }
            setShowStopVideoAndSwitchDialog(MODAL_STATE.closed);
        }
    }, [showStopVideoAndSwitchDialog]);

    const playMedia = async (stateCtx, e) => {
        if (e) {
            e.stopPropagation();
        }
        const { sessionId } = stateCtx;

        setLoading(true);
        await stopPreRecordings(sessionId);
        await playPreRecordings(sessionId);
        socket.emit('updateData', { objectId: sessionId, updateCall: true });
    };
    const stopMedia = async (stateCtx, e) => {
        if (e) {
            e.stopPropagation();
        }
        const showModal = showStopVideoDialog;
        setShowStopVideoDialog(!showModal);
        if (showModal) {
            const { sessionId } = stateCtx;

            setLoading(true);
            await stopPreRecordings(sessionId);
        }
    };

    const onMoveUp = async index => {
        if (index === 0) {
            return;
        }
        setIsLoading(true);
        let videoPrerecordingsClone = [...preRecordingsAndVideos];

        const tmpUserObj = videoPrerecordingsClone[index - 1];
        videoPrerecordingsClone[index - 1] = videoPrerecordingsClone[index];
        videoPrerecordingsClone[index] = tmpUserObj;

        videoPrerecordingsClone = videoPrerecordingsClone.map((item, idx) => ({
            ...item,
            preRecordedPresentationOrder: idx,
        }));
        const preRecordingsData = [];
        const videosData = [];
        videoPrerecordingsClone.forEach((item, idx) => {
            if (isNumber(item.arrIndex)) {
                preRecordingsData.push({
                    id: item.id,
                    preRecordedPresentationOrder: idx,
                });
            } else {
                videosData.push({
                    ...item,
                    order: idx,
                });
            }
        });
        const newVideos = videosData.map((video, index) => ({
            ...video,
            order: index + 1,
        }));

        stateCtx.socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                videoUrls: newVideos,
                VirtualEventUsers: [...preRecordingsData],
            },
        });

        setPreRecordingsAndVideos(videoPrerecordingsClone);
        setReorderItems(false);
    };

    const onMoveDown = async index => {
        if (index === preRecordingsAndVideos.length - 1) {
            return;
        }
        setIsLoading(true);
        let videoPrerecordingsClone = [...preRecordingsAndVideos];

        const tmpUserObj = videoPrerecordingsClone[index + 1];
        videoPrerecordingsClone[index + 1] = videoPrerecordingsClone[index];
        videoPrerecordingsClone[index] = tmpUserObj;

        videoPrerecordingsClone = videoPrerecordingsClone.map((item, idx) => ({
            ...item,
            preRecordedPresentationOrder: idx,
        }));

        const preRecordingsData = [];
        const videosData = [];
        videoPrerecordingsClone.forEach((item, idx) => {
            if (isNumber(item.arrIndex)) {
                preRecordingsData.push({
                    id: item.id,
                    preRecordedPresentationOrder: idx,
                });
            } else {
                videosData.push({
                    ...item,
                    order: idx,
                });
            }
        });

        const newVideos = videosData.map((video, index) => ({
            ...video,
            order: index + 1,
        }));

        stateCtx.socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                videoUrls: newVideos,
                VirtualEventUsers: [...preRecordingsData],
            },
        });

        setPreRecordingsAndVideos(videoPrerecordingsClone);
        setReorderItems(false);
    };

    const onDeletePreRecording = async index => {
        const user = preRecordingsAndVideos[index];

        if (user && user.id) {
            await onDeletePrerecordingUrlByVirtualEventUserId(user.id, stateCtx, mutationCtx);
            setReorderItems(true);
        }
    };

    const deleteRecordingInfoFromUser = async shareUrl => {
        const virtualEventUsers = get(virtualEventSession, 'data.VirtualEventUsers', []);
        const userWithVideo = virtualEventUsers.find(user => {
            const recordingInfoShareUrl = get(user, 'recordingInfo.shareUrl', '');
            return recordingInfoShareUrl === shareUrl;
        });

        if (!userWithVideo) {
            return;
        }

        await updateVirtualEventUser(userWithVideo.id, {
            isPrerecording: false,
            recordingInfo: null,
            preRecordedPresentationUrl: null,
        });

        socket.emit('updateData', { objectId: sessionId });
    };

    const onDeleteUploadedVideo = async index => {
        const video = videoUrls[index];

        if (video && video.id) {
            await onDeleteUploadedVideoById(video.id, stateCtx, mutationCtx);
            await deleteRecordingInfoFromUser(video.shareUrl);
            setReorderItems(true);
        }
    };

    const onAddVideo = async (newVideo, isRTMP) => {
        if (!newVideo) {
            return;
        }

        await onAddVideoUrl(stateCtx, mutationCtx, newVideo);
        setReorderItems(true);
        setShowConversionModal(!isRTMP);
    };

    const getVideoIndex = id => {
        const video = videoUrls.find(video => video.id === id);
        const index = videoUrls.indexOf(video);
        return index;
    };

    const handleStopPreRecording = async (e, yes) => {
        if (yes) {
            await stopMedia(stateCtx, e);
        } else {
            setShowStopVideoDialog(false);
        }

        if (showPreventStopDialogWithCallback) {
            showPreventStopDialogWithCallback(yes);
            mutationCtx.setExtraState({
                showPreventStopDialogWithCallback: null,
            });
        }
    };

    const onDelete = () => {
        const { item, index } = videoToDeleteRef.current;

        if (item && isNumber(item.arrIndex)) {
            onDeletePreRecording(index);
        } else {
            onDeleteUploadedVideo(getVideoIndex(item.id));
        }

        videoToDeleteRef.current = {};
        setShowDeleteVideoDialog(false);
    };

    return (
        <Wrapper className="eureka-react">
            <PrerecordingsTitle>
                <div style={{ alignItems: 'center' }}>Recordings</div>
                <Upload onClick={() => setShowUploadVideoModal(true)}>{'add'}</Upload>
            </PrerecordingsTitle>
            <VideosWrapper>
                {preRecordingsAndVideos.map((item, index) => (
                    <VideoWrapper
                        key={index}
                        virtualEventUser={item}
                        video={item}
                        loading={loading}
                        playing={videoIsPlaying}
                        makeVideoActive={() => {
                            if (videoIsPlaying) {
                                setShowStopVideoAndSwitchDialog(MODAL_STATE.opened);
                                setPrerecordingIndex({
                                    user: item && isNumber(item.arrIndex) ? item.arrIndex : null,
                                    index:
                                        item && isNumber(item.arrIndex)
                                            ? null
                                            : getVideoIndex(item.id),
                                });
                            } else {
                                if (item && isNumber(item.arrIndex)) {
                                    onMakePrerecordingActive(stateCtx, mutationCtx, item.arrIndex);
                                } else {
                                    onMakePrerecordingActive(
                                        stateCtx,
                                        mutationCtx,
                                        null,
                                        getVideoIndex(item.id),
                                    );
                                }
                            }
                        }}
                        playMedia={e => playMedia(stateCtx, e)}
                        stopMedia={e => stopMedia(stateCtx, e)}
                        isDisabled={isPanelDiscussion || switchingPreRecording || loading}
                        isActiveVideo={item.id === activeVideoId}
                        data-qa={
                            item && isNumber(item.arrIndex)
                                ? `pre-recording-list-item-${item.id}`
                                : `uploaded-video-list-item-${index}`
                        }
                        onMoveUp={() => onMoveUp(index)}
                        onMoveDown={() => onMoveDown(index)}
                        onDelete={() => {
                            setShowDeleteVideoDialog(true);
                            videoToDeleteRef.current = { item, index };
                        }}
                    />
                ))}
            </VideosWrapper>
            {showUploadVideoModal && (
                <UploadVideoModal
                    onAdd={onAddVideo}
                    onClose={() => setShowUploadVideoModal(false)}
                />
            )}
            <ConfirmationDialog
                open={showDeleteVideoDialog}
                title="Delete recording"
                titleIcon="delete_forever"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                onClose={() => {
                    setShowDeleteVideoDialog(false);
                    videoToDeleteRef.current = {};
                }}
                content={
                    <ExtraStyledModalContentContainer>
                        Are you sure you want to delete this recording? This cannot be undone.
                    </ExtraStyledModalContentContainer>
                }
                buttons={[
                    <DestructiveModalButton key="PVDMBD" flat onClick={onDelete}>
                        Delete
                    </DestructiveModalButton>,
                    <CancelModalButton
                        key="PVDMBC"
                        flat
                        onClick={() => {
                            setShowDeleteVideoDialog(false);
                            videoToDeleteRef.current = {};
                        }}
                    >
                        Cancel
                    </CancelModalButton>,
                ]}
            />
            <ConfirmationDialog
                open={showStopVideoDialog && !showSwitchVideoToUser}
                title="Stop video?"
                content="Are you sure you want to stop this video?"
                yesButtonText="Stop video"
                noButtonText="Cancel"
                yesButtonBackgroundColor={'#d50000'}
                withCloseButton
                onClose={e => handleStopPreRecording(e)}
                onNo={e => handleStopPreRecording(e)}
                onYes={e => handleStopPreRecording(e, true)}
            />
            <ConfirmationDialog
                open={showSwitchVideoToUser}
                title="Stop video?"
                content="Are you sure you want to switch to this speaker and stop the current video?"
                yesButtonText="Stop video"
                noButtonText="Cancel"
                yesButtonBackgroundColor={'#d50000'}
                withCloseButton
                onClose={e => handleStopPreRecording(e)}
                onNo={e => handleStopPreRecording(e)}
                onYes={e => handleStopPreRecording(e, true)}
            />
            <ConfirmationDialog
                open={!!showStopVideoAndSwitchDialog}
                title="Stop video?"
                content="Are you sure you want to switch to this video and stop the current one?"
                yesButtonText="Stop video"
                noButtonText="Cancel"
                yesButtonBackgroundColor={'#d50000'}
                withCloseButton
                onNo={() => setShowStopVideoAndSwitchDialog(MODAL_STATE.closed)}
                onClose={() => setShowStopVideoAndSwitchDialog(MODAL_STATE.closed)}
                onYes={() => setShowStopVideoAndSwitchDialog(MODAL_STATE.confirmed)}
            />
            <ConversionModalDialog
                open={showConversionModal}
                onClose={() => setShowConversionModal(false)}
                onDone={() => setShowConversionModal(false)}
            />
        </Wrapper>
    );
};

export default Recordings;

import React from 'react';
import styled from 'styled-components';

import Loader from './Loader';

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    display: flex;
    align-items: center;
`;

const LoadingVirtualPage = () => (
    <Container>
        <Loader />
    </Container>
);

export default LoadingVirtualPage;

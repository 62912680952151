import { Editor, Element as SlateElement, Range, Transforms } from 'slate';

export const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
};

export const deleteLink = editor => {
    Transforms.removeNodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
};

export const insertLink = (editor, url, name) => {
    if (editor.selection) {
        wrapLink(editor, url, name);
    }
};

export const wrapLink = (editor, url, name) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: name ? name : url }] : [],
    };

    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: 'end' });
    }
};

export const updateLink = (editor, at, url) => {
    let newProperties;
    newProperties = {
        url,
    };

    Transforms.setNodes(editor, newProperties, { at });
};

export const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
    return !!link;
};

export const getActiveLink = editor => {
    const [link] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
    return link;
};

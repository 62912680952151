import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import MicOffIcon from '@material-ui/icons/MicOff';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { useVMState, useVMVolumeState } from '../../containers/main';
import StreamPlayer from '../common/StreamPlayer';
import useResize from '../../../../../VirtualSession/hooks/useResize';
import VoiceIndicator from '../common/VoiceIndicator';
import {
    ShadowOverlay,
    VirtualUserInfoWrapper,
    VirtualUserTitle,
} from '../moderator/common/styles';

const ProfileImageWrapper = styled.div`
    background-color: #666666;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
`;

const IconsWrapper = styled.div`
    position: absolute;
    bottom: 0 !important;
    left: 0 !important;
    height: 20px;
    width: 85%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const AccountCircleIconWrapper = styled.div`
    font-size: 100px;
    color: #ffffff;
    opacity: ${props => (props.imageUrl ? 1 : 0.2)};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${props => !props.video && props.imageUrl}');
    width: 100px;
    height: 100px;
    border-radius: 50%;
    ${props =>
        props.video &&
        css`
            opacity: 1;
            height: 100%;
            width: 100%;
            .stream-player {
                height: 100%;
                width: 100%;
            }
        `}
`;

const StyledMicOffIcon = styled(MicOffIcon)`
    color: #ffffff;
    width: 16px !important;
    height: 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const sessionRole = 'Host';

const ActivePresenter = () => {
    const stateCtx = useVMState();
    const volumeStateCtx = useVMVolumeState();
    const { localStream, virtualEventUser } = stateCtx;
    const { volumeIndicators } = volumeStateCtx;

    const { User: user, isMicrophoneOn, isVideoOn } = virtualEventUser.data;
    const { firstName, lastName, imageUrl } = user;
    const [activeWrapper] = useResize();

    const volumeLevel = useMemo(() => {
        if (!localStream) {
            return 0;
        }
        const indicator = volumeIndicators.find(vInd => localStream.getId() === vInd.uid);

        return indicator ? indicator.level : 0;
    }, [volumeIndicators]);

    return (
        <ProfileImageWrapper innerRef={activeWrapper}>
            <AccountCircleIconWrapper video={isVideoOn} imageUrl={user.imageUrl}>
                {!imageUrl && !isVideoOn && <AccountCircleIcon fontSize="inherit" />}
                {localStream && (
                    <StreamPlayer
                        key={localStream.getId()}
                        className={'main-stream-profile'}
                        isVideoOn={isVideoOn}
                        local={true}
                        muted
                        stream={localStream}
                        uid={localStream.getId()}
                        domId={`stream-player-${localStream.getId()}`}
                    />
                )}
            </AccountCircleIconWrapper>
            <VirtualUserInfoWrapper data-qa={`active-presenter-name-paragraph-${user.id}`}>
                <IconsWrapper>
                    {isMicrophoneOn && <VoiceIndicator level={volumeLevel} small />}
                    {!isMicrophoneOn && <StyledMicOffIcon />}
                    <VirtualUserTitle>
                        {firstName} {lastName} ({sessionRole})
                    </VirtualUserTitle>
                </IconsWrapper>
            </VirtualUserInfoWrapper>
            <ShadowOverlay />
        </ProfileImageWrapper>
    );
};

export default ActivePresenter;

import React from 'react';

import InteractivityContext from './InteractivityContext';
import NotesDialog from './NotesDialog';
import { withRouter } from 'react-router-dom';

const withInteractivity = Component =>
    withRouter(
        class WithInteractivity extends React.Component {
            constructor(props) {
                super(props);
                this.state = {
                    notesVisible: false,
                };
            }

            componentDidMount() {
                // auth.subscribe(authUser => {
                //     authUser
                //     ? this.setState(() => ({ authUser }))
                //     : this.setState(() => ({ authUser: null }));
                // });
            }

            showNotes = item => {
                this.setState({ notesVisible: true, item: item });
            };

            hideNotes = () => {
                this.setState({ notesVisible: false });
            };

            hideSignIn = () => {
                this.setState({ notesVisible: false, signInVisible: false });
            };

            render() {
                const { notesVisible } = this.state;
                return (
                    <InteractivityContext.Provider
                        value={{
                            notesVisible,
                            showNotes: this.showNotes,
                        }}
                    >
                        <Component />
                        <NotesDialog
                            item={this.state.item}
                            visible={this.state.notesVisible}
                            onClose={this.hideNotes}
                        ></NotesDialog>
                    </InteractivityContext.Provider>
                );
            }
        },
    );

export default withInteractivity;

import React from 'react';
import styled from 'styled-components';
import PosterAndSlidesCard from './PosterAndSlidesCard';
import InfoCard from './InfoCard';
import IntroductionVideoCard from './IntroductionVideoCard';
import AudioDescriptionCard from './AudioDescriptionCard';
import { useVMState } from '../../containers/main';
import AudioRecordingView from './AudioRecordingView';
import get from 'lodash/get';
import { isBoolean } from 'lodash';

const Wrapper = styled.div`
    flex-shrink: 0;
    overflow: scroll;
    padding: 16px;
    margin: -16px;
    width: calc(${props => (props.iswindows ? '476px' : '459px')} + 32px);
`;

const CardsView = ({ localStream }) => {
    const stateCtx = useVMState();
    const { isRecordingAudio, timeslot } = stateCtx;
    const isWindows = window.navigator.appVersion.indexOf('Win') !== -1;
    const allowHostToRecordAudioDescription = isBoolean(
        get(timeslot, 'allowHostToRecordAudioDescription'),
    )
        ? get(timeslot, 'allowHostToRecordAudioDescription')
        : true;
    const allowHostToRecordInstructionVideo = isBoolean(
        get(timeslot, 'allowHostToRecordInstructionVideo'),
    )
        ? get(timeslot, 'allowHostToRecordInstructionVideo')
        : true;

    return (
        <Wrapper iswindows={isWindows ? 1 : 0}>
            {!isRecordingAudio && (
                <>
                    <InfoCard />
                    <PosterAndSlidesCard />
                    {allowHostToRecordInstructionVideo && (
                        <IntroductionVideoCard localStream={localStream} />
                    )}
                    {allowHostToRecordAudioDescription && <AudioDescriptionCard />}
                </>
            )}
            {isRecordingAudio && <AudioRecordingView />}
        </Wrapper>
    );
};

export default CardsView;

import React, { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import { ContainerRight, SecondaryScreen } from '../../../styles';
import * as palette from '../../../../../components/General/Variables';
import VirtualRoomVideo from './VirtualRoomVideo';

const CurrentUserContent = () => {
    const rightSideWrapper = useRef(null);
    const mobile = (window.innerWidth < palette.MIN_TABLET_INT).toString();
    const width = get(rightSideWrapper.current, 'offsetWidth', 0) * 2;

    const handleResize = debounce(() => {
        if (!rightSideWrapper.current) {
            return;
        }

        const height = rightSideWrapper.current.offsetWidth / (16 / 9);

        rightSideWrapper.current.style.height = `${height}px`;
        rightSideWrapper.current.style.minHeight = `${height}px`;
        rightSideWrapper.current.style.maxHeight = `${height}px`;
    }, 500);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return async () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (rightSideWrapper.current) {
            handleResize();
        }
    }, [rightSideWrapper.current]);

    return (
        <ContainerRight size={4} tabletSize={4} mobile={mobile}>
            <SecondaryScreen
                innerRef={rightSideWrapper}
                className="secondary"
                size={12}
                tabletSize={12}
                mobile={mobile}
            >
                <VirtualRoomVideo width={width} />
            </SecondaryScreen>
        </ContainerRight>
    );
};

export default CurrentUserContent;

import entities from '../constants/entities';
import { uploadPresentationToS3 } from '../services/VirtualEventSession';

const onUpdatePresentationUrl = async (
    stateCtx,
    mutationCtx,
    acceptedFiles,
    virtualEventUserModeEntity,
) => {
    const { sessionId, virtualEventUser, socket } = stateCtx;

    if (!acceptedFiles || acceptedFiles.length === 0) {
        return;
    }
    const file = acceptedFiles[0];

    mutationCtx.setVirtualEventUser({
        ...virtualEventUser,
        uploadRequestStatus: entities.requestStatus.loading,
    });

    const response = await uploadPresentationToS3(file, sessionId);

    if (!(response && response.link)) {
        return;
    }

    mutationCtx.setUrl(response.link);

    socket.emit('updateData', {
        objectId: sessionId,
        virtualEventSession: {
            VirtualEventUsers: [
                {
                    id: virtualEventUser.data.id,
                    uploadedPresentationUrl: response.link,
                    uploadedPresentationFilename: file.name,
                    activePresentationIndex: 0,
                    mode: virtualEventUserModeEntity,
                    scale: 1,
                    positionX: 0,
                    positionY: 0,
                },
            ],
        },
    });
};

export default onUpdatePresentationUrl;

import React from 'react';

import Button, { buttonTypes } from '../../common/Button';
import * as palette from '../../../components/General/Variables';
import { getString } from '../../../services/api/store';

const TalkButton = ({ theme, openChat, conversation, user }) => (
    <Button
        type={buttonTypes.GREY_LONG}
        background={theme.primary}
        text={getString('groupChatScreenTitle') || 'Chat'}
        icon={palette.ICON_CHAT_OUTLINE}
        enabled={(user && user.chatAvailable) || (conversation && conversation.messages.length)}
        onClick={() => {
            openChat(conversation);
        }}
    />
);

export default TalkButton;

import React from 'react';
import styled from 'styled-components';

const CameraOff = styled.div`
    align-items: center;
    background-color: #666666;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    span {
        font-family: Cabin, sans-serif;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(255, 255, 255, 0.54);
    }
`;

function index({ elRef, children }) {
    return <CameraOff ref={elRef}>{children}</CameraOff>;
}

index.propTypes = {};

export default index;

import { executeQuery } from '../../../services/api/graphQlRepository';

export const navigationIconsWithClass = [
    { configuratorIcon: 'calendar-outline', webappIcon: 'event' },
    { configuratorIcon: 'calendar-text-outline', webappIcon: 'event_note' },
    {
        configuratorIcon: 'forum-outline',
        webappIcon: 'forum',
    },
    { configuratorIcon: 'folder-account-outline', webappIcon: 'folder_shared' },
    { configuratorIcon: 'account-outline', webappIcon: 'person_outline' },
    {
        configuratorIcon: 'picture-in-picture-top-right-outline',
        webappIcon: 'picture_in_picture',
    },
    { configuratorIcon: 'account-box-outline', webappIcon: 'account_box' },
    { configuratorIcon: 'heart-outline', webappIcon: 'favorite_border' },
    { configuratorIcon: 'domain', webappIcon: 'domain' },
    {
        configuratorIcon: 'file-outline',
        webappIcon: 'insert_drive_file',
    },
    { configuratorIcon: 'magnify', webappIcon: 'search' },
    { configuratorIcon: 'map-marker-outline', webappIcon: 'location_on' },
    { configuratorIcon: 'dots-horizontal', webappIcon: 'more_horiz' },
    {
        configuratorIcon: 'reorder-horizontal',
        webappIcon: 'reorder',
    },
    { configuratorIcon: 'file-document-box-multiple-outline', webappIcon: 'article' },
    { configuratorIcon: 'alert-decagram-outline', webappIcon: 'new_releases' },
    {
        configuratorIcon: 'information-outline',
        webappIcon: 'info_outline',
    },
    { configuratorIcon: 'tag-outline', webappIcon: 'local_offer' },
    { configuratorIcon: 'file-document-outline', webappIcon: 'description' },
    { configuratorIcon: 'file-multiple-outline', webappIcon: 'file_copy' },
    {
        configuratorIcon: 'book-outline',
        webappIcon: 'book',
    },
    { configuratorIcon: 'play-box-outline', webappIcon: 'slideshow' },
    { configuratorIcon: 'clipboard-text-outline', webappIcon: 'assignment' },
    { configuratorIcon: 'email-outline', webappIcon: 'email' },
    {
        configuratorIcon: 'television-classic',
        webappIcon: 'tv',
    },
    { configuratorIcon: 'cloud-download-outline', webappIcon: 'cloud_download' },
    { configuratorIcon: 'home-outline', webappIcon: 'home' },
    {
        configuratorIcon: 'check-box-outline',
        webappIcon: 'check_box_outline',
    },
    { configuratorIcon: 'format-list-bulleted', webappIcon: 'list' },
    { configuratorIcon: 'message-outline', webappIcon: 'chat_bubble_outline' },
    { configuratorIcon: 'dns-outline', webappIcon: 'dns' },
    {
        configuratorIcon: 'checkbox-multiple-blank-outline',
        webappIcon: 'filter_none',
    },
    { configuratorIcon: 'earth', webappIcon: 'public' },
    { configuratorIcon: 'map-outline', webappIcon: 'map' },
    { configuratorIcon: 'cellphone', webappIcon: 'smartphone' },
    {
        configuratorIcon: 'account-group-outline',
        webappIcon: 'groups',
    },
    { configuratorIcon: 'camera-outline', webappIcon: 'camera_alt' },
    { configuratorIcon: 'star-outline', webappIcon: 'star_border' },
    { configuratorIcon: 'view-agenda-outline', webappIcon: 'view_agenda' },
    { configuratorIcon: 'volume-high', webappIcon: 'volume_up' },
    { configuratorIcon: 'microphone', webappIcon: 'mic' },
];

export const getWebappIconUsingClass = classIcon => {
    const selectedIcon = navigationIconsWithClass.find(it => it.configuratorIcon === classIcon);
    return selectedIcon?.webappIcon;
};

const socialFeedNames = ['eventwall', 'social_feed'];
const attendessListNames = ['attendee_list', 'attendees'];

export const mapMobileNavigationMenu = async (mobileNavigationSource, eventId, kiosk) => {
    if (!mobileNavigationSource) {
        return [];
    }
    const pages = await executeQuery('getPagesWithEventAndName', {
        event: eventId,
    });
    const removeActions = [
        'signout',
        'signin',
        'myconversations',
        'mynotes',
        'myprogramme',
        'myfavorites',
        'attendee_list',
        'attendees',
        'eventwall',
        'social_feed',
    ];
    const items = [];
    mobileNavigationSource.map(i => {
        const screen = i.screen || 'unkown';
        let iconLabel = i.customLabel || i.label;
        const page = pages.find(
            p => p.name === screen || (i.customElements && p.name === iconLabel.toLowerCase()),
        );
        let { contentKind } = page || { contentKind: 'notfound' };
        contentKind = i.url ? 'webpage' : contentKind;
        if (!iconLabel) {
            if (page && page.params && page.params.title) {
                iconLabel = page.params.title;
            } else {
                iconLabel = 'no label';
            }
        }
        iconLabel = iconLabel.replace('*newline*', '\n');

        const params = {
            ...i,
            iconLabel,
            contentKind,
            icon: i.class,
            iconType: 'material-community',
            subItems: page?.params?.items,
            screenName: i.screen || null,
            webappIcon: i.webappIcon || getWebappIconUsingClass(i.class),
            kind: page?.kind,
            pageId: page?.id,
            type: page?.kind,
            title: page?.params?.title,
            to: page?.name ? `/${page?.name}` : `/${i.screen}`,
        };
        if (socialFeedNames.includes(i.screen)) {
            params.type = 'socialFeed';
        }
        if (attendessListNames.includes(i.screen)) {
            params.type = 'attendees';
        }
        if (i.screen === 'home') {
            params.screenName = 'newsfeed';
            params.contentKind = 'newsfeed';
            params.to = '/Landing';
            params.type = 'Landing';
        }
        if (kiosk && page) {
            if (page.name === i.screen) {
                if (!!page.accessRestrictions || !!page.isPasswordProtected) {
                    removeActions.push(page.name);
                } else {
                    items.push(params);
                }
            }
        } else {
            items.push(params);
        }
    });

    return items;
};

import React from 'react';

import { PadddingLeftRight16px } from '../../common/Padding';
import { FlexButtonsWrapper } from '../style';
import { getString } from '../../../services/api/store';
import FloqButton from '../../../components/Button/FloqButton';

const AcceptDeclineFriendShipButtons = ({ theme, onCancelRequest, onAcceptFriendRequest }) => (
    <PadddingLeftRight16px>
        <FlexButtonsWrapper>
            <FloqButton
                extraStyle={{
                    color: theme.primary,
                    backgroundColor: theme.primary + '30',
                }}
                onClick={onAcceptFriendRequest}
            >
                {getString('profileAcceptContactRequest') || 'Accept'}
            </FloqButton>
            <FloqButton
                extraStyle={{
                    color: theme.primary,
                    backgroundColor: theme.primary + '30',
                }}
                onClick={onCancelRequest}
            >
                {getString('profileDeclineContactRequest') || 'Decline'}
            </FloqButton>
        </FlexButtonsWrapper>
    </PadddingLeftRight16px>
);

export default AcceptDeclineFriendShipButtons;

import async from 'async';
import { hasNote, isFavoriteById } from '../../../services/api/data';
import { executeQueryWithCallback, getItem } from '../../../services/api/graphQlRepository';

const handleInstitution = (page, callback) => {
    const { institution } = page;
    async.waterfall(
        [
            // Get the notes for the institution
            function (cb) {
                hasNote(institution.id, (err, isNote) => {
                    if (err || !isNote) {
                        return cb(null, page);
                    }

                    institution.hasNote = isNote;

                    cb(null, page);
                });
            },
            // Get the favorite status for the institution
            function (page, cb) {
                isFavoriteById(institution.id, (err, isFavorite) => {
                    if (err) {
                        return cb(null, page);
                    }

                    institution.isFavorite = isFavorite ? 'checked' : '';
                    cb(null, page);
                });
            },
        ],
        (err, page) => {
            callback(err, page);
        },
    );
};

export const getSponsorPage = function (pageId, next) {
    async.waterfall(
        [
            // Get root element from pages
            function (callback) {
                getItem('pages', pageId, (err, page) => {
                    callback(err, page);
                });
            },
            // Get the institution for the sponsor page
            function (page, callback) {
                if (!page || !page.params || !page.params.sponsorId) {
                    return callback(null, page);
                }

                getItem('institutions', page.params.sponsorId, (err, inst) => {
                    if (err || !inst) {
                        return callback(null, page);
                    }

                    page.institution = {
                        interactivity: [],
                        ...inst,
                    };

                    handleInstitution(page, callback);
                });
            },
            // Parse each link and construct it
            function (page, callback) {
                if (!page || !page.params || !page.params.items) {
                    return callback(null, page);
                }

                page.links = [];
                async.eachSeries(
                    page.params.items,
                    (link, cb) => {
                        // if the link is simple (not connected to objects or types) add it and return
                        if (!link.typeId || !link.contentId) {
                            page.links.push(link);

                            return cb();
                        }

                        // if the link is of object type, get the type and construct the correct link
                        getItem('types', link.typeId, (err, type) => {
                            if (err || !type) {
                                return cb();
                            }

                            const typeTarget = type.target.toLowerCase();
                            if (typeTarget === 'webpage') {
                                executeQueryWithCallback(
                                    'getPagesWithEventAndKind',
                                    { event: page.event, kind: 'webpage' },
                                    (err, results) => {
                                        if (err || !results) {
                                            return cb();
                                        }

                                        const webpage = results.find(
                                            item => item.params.webpageId === link.contentId,
                                        );
                                        if (!webpage) {
                                            return cb();
                                        }

                                        const newLink = {
                                            ...link,
                                            linkType: type.target.toLowerCase(),
                                            linkObject: webpage.id,
                                        };
                                        page.links.push(newLink);

                                        return cb();
                                    },
                                );
                            } else if (typeTarget === 'place') {
                                getItem('places', link.contentId, (err, place) => {
                                    if (err || !place) {
                                        return cb();
                                    }

                                    const newLink = {
                                        ...link,
                                        linkType: 'floorplan',
                                        linkObject: place.id,
                                        floorplanId: place.floorplan,
                                    };
                                    page.links.push(newLink);

                                    return cb();
                                });
                            } else {
                                const newLink = {
                                    ...link,
                                    linkType: type.target.toLowerCase(),
                                    linkObject: link.contentId,
                                };
                                page.links.push(newLink);

                                return cb();
                            }
                        });
                    },
                    err => {
                        callback(err, page);
                    },
                );
            },
        ],
        function (err, page) {
            next(err, page);
        },
    );
};

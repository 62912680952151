import React, { useEffect, useMemo, useState } from 'react';
import MicOffIcon from '@material-ui/icons/MicOff';
import styled from 'styled-components';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import entities from '../../../constants/entities';
import { useVMMutation, useVMState, useVMVolumeState } from '../../../containers/main';
import StreamPlayer from '../../common/StreamPlayer';
import CameraOff from '../../video/CameraOff';
import useResize from '../../../../../../VirtualSession/hooks/useResize';
import bestFitLayout from '../../../../../utils/bestFitLayout';
import PanelDiscussionUser from './PanelDiscussionUser';
import { FORTY_MILL, TWENTY_MILL } from '../../../constants/values';
import getThumbnail from '../../../utils/getThumbnail';
import getThumbnailMP4 from '../../../utils/getThumbnailMP4';
import VoiceIndicator from '../../common/VoiceIndicator';
import { RedStyledChip, ShadowOverlay } from '../common/styles';
import { getStreamConfiguration, isEncodingTheSame } from '../../../../../utils/streamUtils';
import CameraOffImage from '../../../../../../../assets/images/camera-off.png';
import Store from '../../../../../../../services/api/store';
import { getImageSource } from '../../../../../../../services/api/db';

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    overflow: hidden;
    z-index: 2;
`;

const { virtualEventSessionEntity } = entities;

const PlayerWrapper = styled.div`
    flex: 7;
    position: relative;
    width: 100%;

    .stream-player.video-on {
        height: 100%;
        width: 100%;

        video {
            background: #fff;
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
            /* This used to work for the parent element of button divs */
            /* But it does not work with newer browsers, the below doesn't hide the play button parent div */
            &::-webkit-media-controls-panel {
                display: none !important;
                -webkit-appearance: none;
            }

            /* Old shadow dom for play button */

            &::-webkit-media-controls-play-button {
                display: none !important;
                -webkit-appearance: none;
            }

            /* New shadow dom for play button */

            /* This one works! */

            &::-webkit-media-controls-start-playback-button {
                display: none !important;
                -webkit-appearance: none;
            }
        }
    }
`;

export const IconWrapper = styled.div`
    bottom: 8px;
    left: 8px;
    position: absolute;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s linear;
    width: 30px;
    height: 30px;
    color: #fff;
    cursor: pointer;
    background: ${props => (props.isMicrophoneOn ? 'transparent' : '#d50000')};
`;

export const ResizingIconWrapper = styled(IconWrapper)`
    height: 3px;
    position: absolute;
    top: initial;
    left: initial;
    bottom: initial;
    right: initial;
    overflow: hidden;
    transition: height 0.5s ease 0s;
`;

const StyledMicOffIcon = styled(MicOffIcon)`
    color: #ffffff;
    width: 24px !important;
    height: 24px !important;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const IconsWrapper = styled.div`
    position: absolute;
    bottom: 7px !important;
    left: 12px !important;
    height: 30px;
    width: 180px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 2;
`;

const FullName = styled.span`
    color: #fff;
    margin-left: 12px;
    font-size: 12px !important;
    font-weight: bold;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
`;

const ThumbnailImg = styled.img`
    width: 100%;
    border-radius: 8px;
`;

const GridForUsers = styled.div`
    background: #5a5b61;
    display: grid;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    min-height: inherit;

    .stream-player {
        background: #5a5b61 !important;

        [id*='player'] {
            background: #5a5b61 !important;
            video {
                background: #5a5b61 !important;
            }
        }
    }
`;

const PositionAbsoluteRedChip = styled(RedStyledChip)`
    position: absolute;
    z-index: 10;
    top: 9px;
    left: 9px;
    width: 38px;
`;

const ZeroSpaceWrapper = styled.div`
    height: 0;
    width: 0;
    overflow: hidden;
`;

export default ({ isVotingActive }) => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const volumeStateCtx = useVMVolumeState();

    const [resizeRef] = useResize();
    const {
        currentUser,
        localStream,
        virtualEventUser,
        virtualEventSession,
        streams,
        sessionId,
        eventSpeakerPlaceholder,
    } = stateCtx;
    const { volumeIndicators } = volumeStateCtx;
    const activeUser = get(virtualEventSession, 'data.VirtualEventUsers', []).find(
        a => a.hasVideoActive || a.isActive,
    );
    const activeVideoId = get(virtualEventSession, 'data.activeVideoId', null);
    const videoUrls = get(virtualEventSession, 'data.videoUrls') || [];
    const isLocalStream = localStream && activeUser && localStream.streamId === activeUser.UserId;
    const hasVideoActiveOn = get(activeUser, 'hasVideoActive');
    const activeUserFullName = activeUser?.User
        ? `${activeUser.User.firstName} ${activeUser.User.lastName}`
        : '';
    const isCurrentUserActive = get(virtualEventUser, 'data.isActive');
    let streamId = isLocalStream ? get(localStream, 'streamId') : get(activeUser, 'UserId');

    if (hasVideoActiveOn) {
        streamId = get(activeUser, 'UserId', 0) + TWENTY_MILL;
    } else if (activeVideoId) {
        streamId = virtualEventSession.data.id + FORTY_MILL;
    }

    const { mode, status } = virtualEventSession.data;
    const isPanelDiscussion = mode === virtualEventSessionEntity.mode.panelDiscussion;
    const isBroadcasting = status === virtualEventSessionEntity.status.broadcasting;
    const virtualSessionsSpeakerHolder =
        Store.argument && Store.argument.virtualSessionsSpeakerHolder;
    const placeHolderImage =
        get(virtualEventSession, 'data.presentationSpeakerPlaceholderUrl') ||
        eventSpeakerPlaceholder ||
        CameraOffImage;

    const activeStream = useMemo(() => {
        const isNotPrerecording = !get(activeUser, 'isPrerecording');
        const shouldDisplayActiveUser = isNotPrerecording || isBroadcasting;

        if ((activeUser && shouldDisplayActiveUser) || activeVideoId) {
            if (activeUser && activeUser.rtmpInfo) {
                return streams.find(st => st.streamId === activeUser.rtmpInfo.uid);
            }

            return streams.find(st => st.streamId === streamId);
        }
    }, [streams, localStream, activeUser, activeVideoId, isBroadcasting]);
    const isMicrophoneOn = get(activeUser, 'isMicrophoneOn');
    const isVideoOn = get(activeUser, 'isVideoOn');

    const virtualEventUsers = useMemo(
        () => get(virtualEventSession, 'data.VirtualEventUsers', []),
        [virtualEventSession],
    );
    const nonRtmpVirtualEventUsers = useMemo(() => {
        return virtualEventUsers.filter(vUser => !vUser.rtmpInfo);
    }, [virtualEventUsers]);

    const streamsToPlay = useMemo(() => {
        if (!isPanelDiscussion) {
            return [];
        }

        return reduce(
            nonRtmpVirtualEventUsers,
            (acc, virtualUser) => {
                const { User: user, isPrerecording } = virtualUser;
                const isCurrentUser = currentUser && user.id === currentUser.id;
                const isNotPrerecording = !isPrerecording;
                const shouldDisplayUser = isNotPrerecording || isBroadcasting;

                if (shouldDisplayUser && (!isCurrentUser || isPanelDiscussion)) {
                    const streamToUse = streams.find(st => st.streamId === get(user, 'id'));

                    if (streamToUse) {
                        acc.push({
                            user,
                            isMicrophoneOn: virtualUser.isMicrophoneOn,
                            isVideoOn: virtualUser.isVideoOn,
                            stream: streamToUse,
                        });
                    }
                }

                return acc;
            },
            [],
        );
    }, [virtualEventUsers, streams, isPanelDiscussion, isBroadcasting]);
    const gridLayout = bestFitLayout(streamsToPlay.length);

    useEffect(() => {
        (async () => {
            if (virtualSessionsSpeakerHolder) {
                await getImageSource(virtualSessionsSpeakerHolder, (err, img) => {
                    mutationCtx.setEventSpeakerPlaceholder(img);
                });
            }
        })();
    }, []);

    useEffect(() => {
        if (!localStream || !localStream.videoTrack) {
            return;
        }

        if (isVotingActive && !localStream.videoTrack.isPlaying) {
            localStream.play({
                domId: `stream-player-${localStream.getId()}`,
                muted: isLocalStream,
            });
        }

        const encoderConfiguration = getStreamConfiguration({
            useHighStream: isCurrentUserActive,
            isPanelDiscussion,
            streamLength: streamsToPlay.length,
        });

        if (!isEncodingTheSame(encoderConfiguration, localStream.videoTrack._encoderConfig)) {
            localStream.setEncoderConfiguration(encoderConfiguration);
        }
    }, [streamsToPlay.length, isPanelDiscussion, isCurrentUserActive, localStream, isVotingActive]);

    const userWithActiveVideo = virtualEventUsers.find(user => user.hasVideoActive);
    const shouldPlayVideo = userWithActiveVideo || activeVideoId;

    const [videoThumbnail, setVideoThumbnail] = useState('');

    const preRecordedPresentationUrl = get(userWithActiveVideo, 'preRecordedPresentationUrl');

    useEffect(() => {
        if (shouldPlayVideo) {
            (async () => {
                let imageData;

                if (userWithActiveVideo) {
                    const { list } = get(userWithActiveVideo, 'recordingInfo', {});
                    const videoInfo = (list || []).find(info => info.type === 'video');
                    const videoUrl = `${preRecordedPresentationUrl}/video/${videoInfo.sid}_${sessionId}.m3u8`;

                    imageData = await getThumbnail(videoUrl);
                } else {
                    const videoUrl = videoUrls.find(video => video.id === activeVideoId).videoUrl;
                    imageData = await getThumbnailMP4(videoUrl);
                }

                setVideoThumbnail(imageData);
            })();
        }
    }, [shouldPlayVideo, userWithActiveVideo]);

    const showThumbnail = shouldPlayVideo && !activeStream && videoThumbnail;
    const playingVideo = shouldPlayVideo && activeStream;
    const isActiveStreamRtmp =
        activeStream && activeStream.streamId < 100 && activeStream.streamId > 0;
    const shouldDisplayCameraOff =
        !isPanelDiscussion &&
        (!activeStream || (!isVideoOn && !playingVideo && !isActiveStreamRtmp));

    const volumeLevel = useMemo(() => {
        const indicator = volumeIndicators.find(
            vInd => `${get(activeStream, 'streamId')}` === `${vInd.uid}`,
        );

        return indicator ? indicator.level : 0;
    }, [volumeIndicators, activeStream]);

    return (
        <Wrapper>
            <PlayerWrapper innerRef={resizeRef}>
                {!isPanelDiscussion &&
                    activeStream &&
                    (isVideoOn || isMicrophoneOn || playingVideo || isActiveStreamRtmp) && (
                        <StreamPlayer
                            key={activeStream.getId()}
                            isVideoOn={
                                isVideoOn ||
                                get(activeUser, 'hasVideoActive') ||
                                activeVideoId ||
                                isActiveStreamRtmp
                            }
                            className={'main-stream-profile'}
                            local={false}
                            muted={isLocalStream}
                            stream={activeStream}
                            uid={activeStream.getId()}
                            displayLiveChip
                            domId={`stream-player-${activeStream.getId()}`}
                            data-qa="moderator-active-speaker-video"
                        />
                    )}
                <ZeroSpaceWrapper>
                    {!isPanelDiscussion &&
                        isVotingActive &&
                        streamsToPlay.map(({ stream }) => (
                            <StreamPlayer
                                key={stream.getId()}
                                className={'main-stream-profile'}
                                muted={stream.streamId === get(localStream, 'streamId')}
                                isVideoOn={false}
                                stream={stream}
                                uid={stream.getId()}
                                domId={`stream-player-${stream.getId()}`}
                            />
                        ))}
                </ZeroSpaceWrapper>

                {isPanelDiscussion && (
                    <GridForUsers
                        style={{
                            gridTemplateColumns: 'minmax(0, 1fr)  '.repeat(gridLayout),
                            gridAutoRows: '1fr',
                        }}
                        data-qa="panel-discussion-video"
                    >
                        {isBroadcasting && <PositionAbsoluteRedChip>LIVE</PositionAbsoluteRedChip>}
                        {streamsToPlay.map(({ user, stream, isMicrophoneOn, isVideoOn }, index) => (
                            <PanelDiscussionUser
                                key={index}
                                stream={stream}
                                user={user}
                                isPanelDiscussion={true}
                                isMicrophoneOn={isMicrophoneOn}
                                isVideoOn={isVideoOn}
                                columnNumber={gridLayout}
                                index={index}
                            />
                        ))}
                    </GridForUsers>
                )}

                {shouldDisplayCameraOff && (
                    <CameraOff>
                        {isBroadcasting && <PositionAbsoluteRedChip>LIVE</PositionAbsoluteRedChip>}
                        {!showThumbnail && (
                            <ThumbnailImg src={placeHolderImage} alt="cameraOffImage" />
                        )}
                        {showThumbnail && <ThumbnailImg alt="preview" src={videoThumbnail} />}
                    </CameraOff>
                )}

                {activeStream && !isPanelDiscussion && !playingVideo && (
                    <React.Fragment>
                        <IconsWrapper>
                            {isMicrophoneOn && <VoiceIndicator level={volumeLevel} />}
                            {!isMicrophoneOn && <StyledMicOffIcon />}
                            <FullName>{activeUserFullName}</FullName>
                        </IconsWrapper>
                        <ShadowOverlay />
                    </React.Fragment>
                )}
            </PlayerWrapper>
        </Wrapper>
    );
};

import React from 'react';
import styled from 'styled-components';

import MaterialSwitchButton from '../../../common/MaterialSwitchButton';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

const Container = styled.div`
    padding: 8px 0;
    margin-left: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${props => !props.noborder && 'border-top: 1px solid #ebedf0;'}
`;

// To Do: use Material UI Typography with theme
const StyledLabel = styled.p`
    color: black;
    font-size: 17px;
    font-family: Cabin;
    line-height: 24px;
    margin-bottom: 0;
`;

const FeatureItem = ({ name, onChange, label, description, checked, noBorderTop, children }) => {
    return (
        <Container noborder={noBorderTop ? 1 : 0}>
            <Stack width="100%">
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <StyledLabel>{label}</StyledLabel>
                    <MaterialSwitchButton
                        name={name}
                        onChange={onChange}
                        label=""
                        checked={checked}
                    />
                </Stack>
                {description && <Typography paddingBottom={1}>{description}</Typography>}
                {children}
            </Stack>
        </Container>
    );
};

export default FeatureItem;

import React from 'react';
import { Card, ShowMore } from '../style';
import CurrentAppointmentCard from '../components/Card';

const ShowMoreAppointmentsCard = ({
    theme,
    appointments,
    setShowMore,
    showMore,
    getDateAndTime,
    setShowCancelMeetingModal,
    openDetails,
    showCancelMeetingModal,
    deleteAppointment,
}) => (
    <React.Fragment>
        {showMore ? (
            <>
                {appointments.map(appointment => (
                    <CurrentAppointmentCard
                        theme={theme}
                        appointmentToDisplay={appointment}
                        getDateAndTime={getDateAndTime}
                        cancelMeeting={() => setShowCancelMeetingModal(appointment.id)}
                        openDetails={() => openDetails(appointment.id)}
                        showCancelMeetingModal={showCancelMeetingModal}
                        deleteAppointment={deleteAppointment}
                        setShowCancelMeetingModal={setShowCancelMeetingModal}
                    />
                ))}
                <Card>
                    <ShowMore onClick={() => setShowMore(false)}>Show less meetings</ShowMore>
                </Card>
            </>
        ) : (
            <Card>
                <ShowMore onClick={() => setShowMore(true)}>
                    Show {appointments.length - 1} more meetings
                </ShowMore>
            </Card>
        )}
    </React.Fragment>
);

export default ShowMoreAppointmentsCard;

const urlMap = {};

window.timer = 1;

export default url => {
    return new Promise(resolve => {
        if (!url) {
            return resolve();
        }

        if (urlMap[url]) {
            if (urlMap[url].done) {
                return resolve(urlMap[url].value);
            }

            return resolve('');
        }

        urlMap[url] = {
            done: false,
            value: '',
        };

        const video = document.createElement('video');

        video.crossOrigin = 'anonymous';

        video.muted = true;

        const getVideoThumbnail = () => {
            if (video.buffered.length <= 1) {
                video.currentTime = 5;
            }

            if (video.buffered.length > 1) {
                const canvas = document.createElement('canvas');

                video.removeEventListener('progress', getVideoThumbnail);

                setTimeout(() => {
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                    const dataUrl = canvas.toDataURL();

                    urlMap[url].value = dataUrl;
                    urlMap[url].done = true;

                    resolve(dataUrl);
                }, 2000);
            }
        };

        video.addEventListener('progress', getVideoThumbnail);

        video.src = url;
    });
};

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    padding-left: 24px;
    padding-right: 24px;
`;

const TitleContainer = styled.div`
    height: 56px;
    padding-right: 24px;
    display: flex;
    align-items: center;
`;

// To Do: use Material UI Typography with theme
const StyledTitle = styled.p`
    color: black;
    font-size: 17px;
    font-weight: bold;
    font-family: Cabin, sans-serif;
    line-height: 24px;
    margin: 0 24px 0 16px;
`;

const IconWrapper = styled.div`
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
    border-radius: 50%;
`;

const Card = ({ children, icon, title = '' }) => {
    return (
        <Container>
            <TitleContainer>
                {icon && <IconWrapper>{icon}</IconWrapper>}
                <StyledTitle>{title}</StyledTitle>
            </TitleContainer>
            {children}
        </Container>
    );
};

export default Card;

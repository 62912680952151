import styled from 'styled-components';
import { ListItem } from 'react-md';

export const ListItemStyled = styled(ListItem)`
    & .md-list-tile {
        padding-left: 8px !important;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.04) !important;
    }
`;

export const PrimaryTitle = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-weight: bold;
    font-family: 'Cabin', sans-serif;
`;

export const Timestamp = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-family: Roboto, sans-serif;
`;

export const SecondaryTitle = styled.div`
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
`;

export const SecondaryTitleMessage = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
`;

export const listContentStyle = {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
};

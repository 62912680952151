import React from 'react';
import styled from 'styled-components';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LightTooltip } from '../../virtualFeature/components/moderator/common/styles';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);

        & > * {
            color: rgba(0, 0, 0, 0.87);
        }
    }
`;

const StyledDeleteIcon = styled(DeleteOutlineIcon)`
    color: rgba(0, 0, 0, 0.6);
`;

const DeleteMessageButton = ({ onButtonClick }) => {
    return (
        <LightTooltip title="Delete" placement="top">
            <Wrapper onClick={onButtonClick}>
                <StyledDeleteIcon />
            </Wrapper>
        </LightTooltip>
    );
};

export default DeleteMessageButton;

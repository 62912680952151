const urlMap = {};

export default url => {
    return new Promise(resolve => {
        if (!url) {
            return resolve();
        }

        if (urlMap[url]) {
            if (urlMap[url].done) {
                return resolve(urlMap[url].value);
            }

            return resolve(0);
        }

        urlMap[url] = {
            done: false,
            value: 0,
        };

        const video = document.createElement('video');

        const getVideoThumbnail = () => {
            const duration = video.duration;

            urlMap[url].value = duration;
            urlMap[url].done = true;

            resolve(duration);
        };

        video.addEventListener('loadedmetadata', getVideoThumbnail);

        video.src = url;
    });
};

import { useEffect, useMemo, useRef, useState } from 'react';
import get from 'lodash/get';
import throttle from 'lodash/throttle';

import { useGlobalMutation, useGlobalState } from '../../../utils/container';
import useScreenSharingStream from './useScreenSharingStream';
import RtcClient from '../../../services/rtcClient';
import useStream from './useStream';
import { TOAST_TYPES } from '../components/common/toasts';

const useStreamHandling = isHost => {
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();
    const {
        canvasSharing,
        canJoin,
        currentVirtualEventUser,
        recreateStreams,
        screenSharing,
        enableTCPProxy,
    } = stateCtx;

    const { isMicrophoneOn, isVideoOn } = currentVirtualEventUser || {};

    const [update, setUpdate] = useState(Date.now());
    const localStream = useRef(null);

    const videoClient = useMemo(() => {
        const client = new RtcClient();

        if (!client._created) {
            client.createClient({ codec: stateCtx.codec, mode: isHost ? 'rtc' : stateCtx.mode });
            client._created = true;
        }

        return client;
    }, [stateCtx.codec, stateCtx.mode, isHost]);

    [localStream.current] = useStream(videoClient);

    const screenSharingStream = useRef(null);

    const screenSharingClient = useMemo(() => {
        const client = new RtcClient();

        if (!client._created) {
            client.createClient({ codec: stateCtx.codec, mode: isHost ? 'rtc' : stateCtx.mode });
            client._created = true;
        }

        return client;
    }, [stateCtx.codec, stateCtx.mode, isHost]);

    [screenSharingStream.current] = useScreenSharingStream(screenSharingClient);

    window.videoClient = videoClient;
    window.screenSharingClient = screenSharingClient;

    const stopStream = () => {
        mutationCtx.clearAllStream();
        videoClient &&
            videoClient.leave().then(() => {
                mutationCtx.removePeer({ uid: videoClient._id });
                if (localStream.current) {
                    localStream.current.stop();
                    localStream.current.close();
                }
            });
        screenSharingClient &&
            screenSharingClient.leave().then(() => {
                if (screenSharingStream.current) {
                    screenSharingStream.current.stop();
                    screenSharingStream.current.close();
                }
            });
    };

    useEffect(() => {
        mutationCtx.hideJoiningLoader();
        return stopStream;
    }, []);

    const config = useMemo(() => {
        const { isMicrophoneOn, isVideoOn } = stateCtx.currentVirtualEventUser || {};

        return {
            appID: stateCtx.config.appID,
            token: stateCtx.config.token,
            channel: stateCtx.config.channelName,
            microphoneId: stateCtx.config.microphoneId,
            cameraId: stateCtx.config.cameraId,
            resolution: stateCtx.config.resolution,
            muteVideo: stateCtx.config.muteVideo,
            muteAudio: stateCtx.config.muteAudio,
            uid: stateCtx.config.uid,
            host: isMicrophoneOn || isVideoOn,
            role: isHost ? 'host' : 'audience',
        };
    }, [stateCtx]);

    useEffect(() => {
        if (
            videoClient &&
            canJoin &&
            config.channel &&
            config.token &&
            videoClient._created &&
            videoClient._joined === false
        ) {
            videoClient
                .join(config)
                .then(uid => {
                    mutationCtx.addPeer({ uid });
                })
                .catch(err => {
                    mutationCtx.toastError(`Media ${get(err, 'info')}`);
                    console.log(err);
                });
        }
    }, [canJoin, videoClient, config, update]);

    useEffect(() => {
        (async () => {
            if (
                videoClient &&
                videoClient._joined === true &&
                config.host &&
                ((isVideoOn && config.cameraId) || (isMicrophoneOn && config.microphoneId))
            ) {
                if (videoClient._isHost === false) {
                    await videoClient.makeHost(config);
                }

                if (videoClient._isHost === true && videoClient._published === false) {
                    await videoClient.publish();
                }
            }
        })();
    }, [videoClient, config, update]);

    useEffect(() => {
        if (
            screenSharingClient &&
            config.channel &&
            screenSharingClient._created &&
            screenSharingClient._joined === false
        ) {
            screenSharingClient
                .join({
                    ...config,
                    host: !!screenSharing,
                    screenSharing: true,
                    // + 1Million
                    uid: config.uid + 10000000,
                })
                .then(async uid => {
                    console.log(uid);
                })
                .catch(err => {
                    console.error(`Media ${err}`);
                    console.log('Wut');
                });
        }
    }, [screenSharingClient, config, update]);

    const handleUpdate = () => setUpdate(Date.now());

    useEffect(() => {
        const handleClientsJoined = () => {
            if (videoClient._joined && screenSharingClient._joined) {
                mutationCtx.hideJoiningLoader();
            }
        };

        if (videoClient) {
            videoClient.on('updateClient', handleUpdate);
            videoClient.once('showProxyButton', mutationCtx.showProxyModal);
            videoClient.once('longJoin', mutationCtx.showJoiningLoader);
            videoClient.once('clientJoined', handleClientsJoined);
        }

        if (screenSharingClient) {
            screenSharingClient.once('showProxyButton', mutationCtx.showProxyModal);
            screenSharingClient.once('longJoin', mutationCtx.showJoiningLoader);
            screenSharingClient.once('clientJoined', handleClientsJoined);
        }

        return () => {
            if (videoClient) {
                videoClient.off('updateClient', handleUpdate);
                videoClient.off('showProxyButton', mutationCtx.showProxyModal);
                videoClient.off('longJoin', mutationCtx.showJoiningLoader);
                videoClient.off('clientJoined', handleClientsJoined);
            }

            if (screenSharingClient) {
                screenSharingClient.off('showProxyButton', mutationCtx.showProxyModal);
                screenSharingClient.off('longJoin', mutationCtx.showJoiningLoader);
                screenSharingClient.off('clientJoined', handleClientsJoined);
            }
        };
    }, [videoClient, screenSharingClient]);

    useEffect(() => {
        if (enableTCPProxy) {
            let shouldUpdate = false;

            if (videoClient._joined !== true) {
                videoClient._client.startProxyServer(4);
                videoClient._joined = false;
                shouldUpdate = true;
            }

            if (screenSharingClient._joined !== true) {
                screenSharingClient._client.startProxyServer(4);
                screenSharingClient._joined = false;
                shouldUpdate = true;
            }

            if (shouldUpdate) {
                setUpdate(Date.now());
            }
        }
    }, [videoClient, screenSharingClient, enableTCPProxy]);

    useEffect(() => {
        const handleStreamJoined = async () => {
            if (
                screenSharingClient._joined === true &&
                config.host &&
                ((stateCtx.useDevices && screenSharing) || canvasSharing)
            ) {
                if (
                    screenSharingClient._shareType !== 'pending' &&
                    screenSharingClient._isHost !== 'pending' &&
                    (screenSharingClient._isHost === false ||
                        (screenSharing && screenSharingClient._shareType === 'canvas') ||
                        (canvasSharing && screenSharingClient._shareType === 'screen'))
                ) {
                    await screenSharingClient.makeHost({ screenSharing, canvasSharing });
                }

                if (
                    screenSharingClient._isHost === true &&
                    screenSharingClient._published === false
                ) {
                    screenSharingClient.publish();
                }
            }
        };

        (async () => {
            try {
                if (screenSharingClient._joined === 'pending') {
                    screenSharingClient.once('clientJoined', handleStreamJoined);
                } else {
                    await handleStreamJoined();
                }
            } catch (err) {
                if (err && err.info === 'Permission denied by system') {
                    mutationCtx.setShowToast(TOAST_TYPES.SCREEN);
                }
            }
        })();

        return () => {
            screenSharingClient.off('clientJoined', handleStreamJoined);
        };
    }, [screenSharingClient, config, stateCtx.useDevices]);

    const handleMicAndCamera = throttle(async videoStream => {
        if (videoStream) {
            if (!isMicrophoneOn) {
                if (videoStream.isAudioOn()) {
                    await videoStream.muteAudio();
                }
            } else {
                if (!videoStream.isAudioOn()) {
                    await videoStream.unmuteAudio();
                    await videoClient.publishTrack('audio');
                }
            }

            if (!isVideoOn) {
                if (videoStream.isVideoOn()) {
                    await videoStream.muteVideo();
                }
            } else {
                if (!videoStream.isVideoOn()) {
                    await videoStream.unmuteVideo();
                    await videoClient.publishTrack('video');
                }
            }
        }
    }, 500);

    useEffect(() => {
        const videoStream = localStream.current;

        handleMicAndCamera(videoStream);

        if (videoStream && !isVideoOn && !isMicrophoneOn) {
            mutationCtx.removeStreamById({ uid: videoStream.getId() });
            videoClient.destroyStream(true);
        }

        if (!isVideoOn && !isMicrophoneOn) {
            mutationCtx.stopUsingDevices();
        }
    }, [isVideoOn, isMicrophoneOn, localStream.current]);

    useEffect(() => {
        const shareStream = screenSharingStream.current;

        if (shareStream) {
            if (!screenSharing) {
                mutationCtx.removeStreamById({ uid: shareStream.getId() });
                screenSharingClient.destroyStream(true);
            }

            if (shareStream.isAudioOn()) {
                shareStream.muteAudio();
            }
        }

        if (!screenSharing) {
            mutationCtx.stopUsingDevices();
        }
    }, [screenSharing]);

    useEffect(() => {
        (async () => {
            const videoStream = localStream.current;

            if (videoClient && recreateStreams && videoStream) {
                mutationCtx.removeStreamById({ uid: videoStream.getId() });
                await videoClient.destroyStream(true);
                setUpdate(Date.now());
                mutationCtx.setRecreateStreams(false);
            }
        })();
    }, [recreateStreams, localStream]);

    return { config };
};

export default useStreamHandling;

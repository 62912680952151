import get from 'lodash/get';

export const getParticipantsSelector = state => state.talk.participants;

export const getParticipantStatus = (state, participantId) =>
    get(state, `talk.participantStatus.${participantId}`, false);

export const getParticipantBlocked = (state, participantId) =>
    get(state, `talk.blockedParticipants.${participantId}`, false);

export const getGroupChatParticipantsSelector = (state, conversationId) => {
    const conversation = state.talk.groupConversations[conversationId];
    const participantIds = get(conversation, 'participants', []);

    return participantIds.map(pId => {
        const p = state.talk.participants[pId] || {};

        return {
            ...p,
            kind: 'Person',
            name: p.displayName || `${p.firstName} ${p.lastName}`,
            subNameList: p.jobTitle,
            subNameList2: p.companyName,
            showStatus: true,
            online: getParticipantStatus(state, pId),
            blocked: getParticipantBlocked(state, pId),
        };
    });
};

import React, { useEffect, useState } from 'react';
import Button, { buttonTypes } from '../../scenes/common/Button';
import { useTheme } from '../Theme/ThemeContext';
import { addCheckin, makeItemAsync, removeCheckin } from '../../services/api/data';
import { getString } from '../../services/api/store';
import { sync } from '../../services/api/eureka';
import AnalyticsService from '../../features/analytics/services';
import eventBus from '../../utils/eventBus';

const CheckinButton = ({ id, type, style }) => {
    const [detailedItem, setDetailedItem] = useState(null);
    const [isCheckedIn, setIsCheckedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const { theme } = useTheme();

    const CHECKIN_TEXT = getString('checkIn', 'Check in');
    const CHECKOUT_TEXT = getString('checkOut', 'Checked in');

    useEffect(() => {
        (async () => {
            setLoading(true);
            const item = await makeItemAsync(id, type);
            setDetailedItem(item);
            setIsCheckedIn(item.isCheckin);
            setLoading(false);
        })();
    }, [id, type]);

    const onClick = async () => {
        const newIsCheckedIn = !isCheckedIn;

        setIsCheckedIn(newIsCheckedIn);

        if (newIsCheckedIn) {
            await addCheckin(detailedItem);
        } else {
            await removeCheckin(detailedItem);
        }

        AnalyticsService.addSample('checkin', newIsCheckedIn, id);
        sync(() => {});
        eventBus.emit('refreshMyCheckins');
    };

    const text = isCheckedIn ? CHECKOUT_TEXT : CHECKIN_TEXT;

    return (
        <Button
            text={text}
            type={isCheckedIn ? buttonTypes.GREEN_LONG_REVERSE : buttonTypes.GREY_LONG}
            background={theme.primary}
            iconCustomColor={isCheckedIn && theme.primary}
            icon="beenhere"
            outlinedIcon={!isCheckedIn}
            enabled={!loading}
            onClick={onClick}
            style={style}
        />
    );
};

export default CheckinButton;

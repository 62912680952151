import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components';

const titleStyles = theme => ({
    root: {
        height: 69,
        margin: 0,
        paddingTop: 24,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 0,
    },
    closeButton: {
        position: 'absolute',
        right: 12,
        top: 12,
        color: theme.palette.grey[700],
    },
});

// To Do: use Material UI Typography with theme
const StyledTitle = styled.p`
    font-size: 21px;
    color: black;
    font-weight: bold;
    font-family: Cabin;
    margin-right: 24px;
    line-height: 24px;
`;

const DialogTitle = ({ children, classes, onClose }) => {
    return (
        <MuiDialogTitle className={classes.root}>
            <StyledTitle>{children}</StyledTitle>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

export default withStyles(titleStyles)(DialogTitle);

import React, { useEffect, useMemo, useState } from 'react';
import { Snackbar } from 'react-md';
import styled from 'styled-components';
import { detect } from 'detect-browser';
import get from 'lodash/get';

import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import { updateVirtualEventUser } from '../../../../services/api/eureka';
import {
    SnackWrapper,
    SnackButtons,
    SnackButton,
    Text,
    WarningIcon,
    MainText,
    CloseIcon,
    TOAST_TYPES,
    TOAST_MESSAGES,
} from './toasts';
import ScreenLoader from './ScreenLoader';
import MicMutedDialog from './MicMutedDialog';
import entitites from '../../constants/entities';
import { useLocation } from 'react-router-dom';

const { virtualEventSessionEntity } = entitites;

const SUPPORTED_BROWSERS = {
    CHROME: 58,
    FIREFOX: 56,
    SAFARI: 11,
    OPERA: 45,
    EDGE: 80,
};

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    border-left: 1px solid #80808047;
    background: white;
    overflow: hidden;
`;

const toastsArray = [];

let audioTrack;

const getAudioTrack = () =>
    new Promise(resolve => {
        if (audioTrack) {
            return resolve(audioTrack);
        }

        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(stream => {
                audioTrack = stream.getAudioTracks()[0];
                resolve(audioTrack);
            })
            .catch(() => resolve());
    });

const isSystemMuted = async () => {
    const track = await getAudioTrack();

    if (!track) {
        return true;
    }

    return track.muted;
};

const VirtualWrapper = props => {
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();
    const location = useLocation();

    const [isBrowserSupported, setIsBrowserSupported] = useState(true);
    const { children, timeslotId, virtualEventUser, virtualEventSession, timeslot } = props;
    const {
        showToast,
        joiningLoaderVisible,
        proxyModalVisible,
        mutedBySystem,
        micMutedDialog,
        socket,
    } = stateCtx;
    const sessionType = get(timeslot, 'params.virtualSession', '');
    const isSessionOnDemand =
        sessionType === 'on-demand' ||
        (location && location.pathname && location.pathname.includes('/on-demand-poster-room'));
    const isVirtualSession = sessionType === 'virtual';
    const virtualEventSessionType = get(virtualEventSession, 'roomType');
    const isModeratedSession = useMemo(
        () => virtualEventSessionType === virtualEventSessionEntity.type.moderated,
        [virtualEventSessionType],
    );

    useEffect(() => {
        const isSupported = detectIfBrowserIsSupported();

        setIsBrowserSupported(isSupported);
        if (!isSupported) {
            mutationCtx.setShowToast(TOAST_TYPES.BROWSER);
        }

        return () => {
            if (audioTrack) {
                audioTrack.stop();
            }
            audioTrack = null;
        };
    }, []);

    const getToasts = type => {
        let text;
        let onClick;
        let children;

        toastsArray.splice(0, 1);

        switch (type) {
            case TOAST_TYPES.AUDIO:
                text = TOAST_MESSAGES.AUDIO;
                children = (
                    <SnackButtons>
                        <SnackButton className="md-btn md-btn--flat md-btn--text md-btn--color-secondary-active md-text--theme-secondary md-ink--secondary md-inline-block">
                            Allow
                        </SnackButton>
                    </SnackButtons>
                );
                break;
            case TOAST_TYPES.MICROPHONE:
                text = (
                    <Text>
                        <WarningIcon>error_outline</WarningIcon>
                        <MainText>{TOAST_MESSAGES.MICROPHONE}</MainText>
                        <a href="https://support.google.com/chrome/answer/2693767" target="_blank">
                            Learn how
                        </a>
                    </Text>
                );
                children = <CloseIcon>close</CloseIcon>;
                onClick = () => mutationCtx.setShowToast(false);
                break;
            case TOAST_TYPES.CAMERA:
                text = (
                    <Text>
                        <WarningIcon>error_outline</WarningIcon>
                        <MainText>{TOAST_MESSAGES.CAMERA}</MainText>
                        <a href="https://support.google.com/chrome/answer/2693767" target="_blank">
                            Learn how
                        </a>
                    </Text>
                );
                children = <CloseIcon>close</CloseIcon>;
                onClick = () => mutationCtx.setShowToast(false);
                break;
            case TOAST_TYPES.SCREEN:
                text = (
                    <Text>
                        <WarningIcon>error_outline</WarningIcon>
                        <MainText>{TOAST_MESSAGES.SCREEN}</MainText>
                        <a
                            href="https://support.apple.com/en-gb/guide/mac-help/mchld6aa7d23/mac"
                            target="_blank"
                        >
                            Learn how
                        </a>
                    </Text>
                );
                children = <CloseIcon>close</CloseIcon>;
                onClick = () => mutationCtx.setShowToast(false);
                break;
            case TOAST_TYPES.BROWSER:
                text = (
                    <Text>
                        <WarningIcon>error_outline</WarningIcon>
                        <MainText>
                            Make sure to use{' '}
                            <a href="https://www.google.com/chrome/" target="_blank">
                                Google Chrome
                            </a>{' '}
                            or one of the other supported browsers:
                        </MainText>
                        <a href="/#">More info</a>
                    </Text>
                );
                children = <CloseIcon>close</CloseIcon>;
                onClick = () => mutationCtx.setShowToast(false);
                break;
            default:
                return toastsArray;
        }

        toastsArray.push({
            text,
            action: {
                children,
                onClick,
            },
        });

        return toastsArray;
    };

    const detectIfBrowserIsSupported = () => {
        const browser = detect();

        if (!browser) {
            return false;
        }
        console.log('browser', browser);

        const { name, version: versionDetailed, os } = browser;
        const version = versionDetailed && parseInt(versionDetailed.split('.')[0]);

        if (os === 'Mac OS' || os.indexOf('Windows') > -1) {
            if (name === 'chrome' && version < SUPPORTED_BROWSERS.CHROME) {
                return false;
            }
            if (name === 'firefox' && version < SUPPORTED_BROWSERS.FIREFOX) {
                return false;
            }
            if (name === 'opera' && version < SUPPORTED_BROWSERS.OPERA) {
                return false;
            }
            if (name === 'safari' && version < SUPPORTED_BROWSERS.SAFARI) {
                return false;
            }
            if (name === 'edge' && version < SUPPORTED_BROWSERS.EDGE) {
                return false;
            }
        }

        return true;
    };

    useEffect(() => {
        if (isModeratedSession && audioTrack) {
            audioTrack.stop();
            audioTrack = null;
        }

        if (!isSessionOnDemand && !isVirtualSession && !isModeratedSession) {
            const interval = setInterval(async () => {
                const isMuted = await isSystemMuted();

                if (isMuted !== mutedBySystem) {
                    mutationCtx.setMutedBySystem(isMuted);

                    if (isMuted && virtualEventUser) {
                        await updateVirtualEventUser({
                            id: virtualEventUser.id,
                            isMicrophoneOn: false,
                        });

                        if (socket) {
                            socket.emit('updateData', { objectId: timeslotId });
                        }
                    }
                }
            }, 500);

            return () => clearInterval(interval);
        }
    }, [mutedBySystem, isSessionOnDemand, isVirtualSession, isModeratedSession]);

    return (
        <Wrapper>
            {joiningLoaderVisible && (
                <ScreenLoader title="Trying to join" text="Please wait a few seconds..." />
            )}
            {showToast && (
                <SnackWrapper>
                    <Snackbar
                        id="interactive-snackbar"
                        toasts={getToasts(showToast)}
                        autohide={false}
                        autoFocusAction
                        onDismiss={() => mutationCtx.setShowToast(false)}
                    />
                </SnackWrapper>
            )}
            {micMutedDialog && (
                <MicMutedDialog onClose={() => mutationCtx.setMicMutedDialog(false)} />
            )}
            {isBrowserSupported && children}
        </Wrapper>
    );
};

export default VirtualWrapper;

import React from 'react';
import styled from 'styled-components';
import DoneIcon from '@material-ui/icons/Done';
import { Button as ButtonMD } from 'react-md';

import { ContentModalContainer } from './DialogStyles';
import ConfirmationDialog from './ConfirmatonDialog';

const DoneButton = styled(ButtonMD)`
    border-radius: 4px !important;
    background-color: #00a480 !important;
    color: #fff !important;
    width: 100%;
    text-transform: capitalize !important;
    white-space: nowrap;
    font-weight: bold !important;
    font-size: 15px !important;
`;

const ConversionModalContainer = styled(ContentModalContainer)`
    flex-flow: column;
    align-items: center;
    text-align: center;

    svg {
        margin: 0;
    }
`;

const ConversionModalTitle = styled.div`
    font-family: Cabin, sans-serif;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    margin: 15px 0;
`;

const ConversionModalInfo = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
`;

export default ({ open, success, onClose, onDone }) => {
    return (
        <div className="eureka-react">
            <ConfirmationDialog
                open={open}
                onClose={onClose}
                extraStyles="footer { padding-top: 0 !important; }"
                content={
                    <ConversionModalContainer>
                        <DoneIcon />
                        <ConversionModalTitle>Video uploaded</ConversionModalTitle>
                        {success && (
                            <ConversionModalInfo>
                                Your video is was successfully resized to the supported format.
                            </ConversionModalInfo>
                        )}
                        {!success && (
                            <ConversionModalInfo>
                                Your video is successfully uploaded and will be resized to the
                                supported format if needed.
                            </ConversionModalInfo>
                        )}
                    </ConversionModalContainer>
                }
                buttons={[
                    <DoneButton flat onClick={onDone}>
                        Done
                    </DoneButton>,
                ]}
            />
        </div>
    );
};

import moment from 'moment';
import { getString } from '../../../services/api/store';
import range from 'lodash/range';
import {
    EMPTY_LIST_FILTER_SUBTITLE,
    EMPTY_LIST_FILTER_TITLE,
    EMPTY_PROGRAMME_DAY,
    EMPTY_TIMELINE_FILTER_TITLE,
} from '../constants';
import { EmptyState, PlaceholderImage } from '../style/style';
import React from 'react';
import Placeholder from '../../SearchPage/components/placeholder';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

export const computeDurationInHours = (start, end) => {
    const s = moment.utc(start).valueOf();
    const e = moment.utc(end).valueOf();
    return (e - s) / 1000 / 60 / 60;
};

export const happeningNow = (item, selectedTimezone, getTimeslotUtc) => {
    if (item.start && item.end) {
        var start = getTimeslotUtc(item.start);
        var end = getTimeslotUtc(item.end);
        var now = moment.utc();

        const isVisible = now.isBetween(start, end, null, '[]');
        return isVisible;
    }
    return false;
};

export const computeEventBlock = (slot, blockDimension, isVertical) => {
    const start = slot.start;
    const end = slot.end;
    const durationHours = computeDurationInHours(start, end);

    return durationHours * blockDimension + (isVertical ? -2 : 0) + 'px';
};

export const computeTimerStyle = (
    timeRange,
    checkDate,
    selectedTimezone,
    rangeInterval,
    getUtcToSelectedTimezone,
) => {
    const hidden = {
        left: 0,
        display: 'none',
    };
    if (checkDate === null) {
        return hidden;
    }
    const isToday = getUtcToSelectedTimezone(checkDate).isSame(
        getUtcToSelectedTimezone(moment.utc()),
        'day',
    );
    if (!isToday) {
        return hidden;
    }

    const now = getUtcToSelectedTimezone(moment.utc());

    const hours = now.hours() * 60 * 60 * 1000;
    const minutes = now.minutes() * 60 * 1000;
    const seconds = now.seconds() * 1000;
    const milliseconds = now.milliseconds();

    const t = (hours + minutes + seconds + milliseconds) / (60 * 60 * 1000);
    let dif = t - (rangeInterval.start - 0.5);
    if (dif < 0) {
        dif = dif + 24;
    }
    return dif;
};

export const computeEventBlockOffsetDif = (startTime, rangeInterval, blockDimension) => {
    const hours = startTime.hours() * 60 * 60 * 1000;
    const minutes = startTime.minutes() * 60 * 1000;
    const seconds = startTime.seconds() * 1000;
    const milliseconds = startTime.milliseconds();

    const t = (hours + minutes + seconds + milliseconds) / (60 * 60 * 1000);
    let dif = t - (rangeInterval.start - 1);
    if (dif < 0) {
        dif = dif + 24;
    }

    return dif * blockDimension + 'px';
};

export const getRulerRange = rangeInterval => {
    const minRange = 8;
    let rulerRange = range(rangeInterval.start, rangeInterval.end + 1);
    if (rulerRange.length < minRange) {
        const more = minRange - rulerRange.length;
        rulerRange = range(rangeInterval.start, rangeInterval.end + more);
    }
    return rulerRange;
};

export const computeEventBlockOffset = (slotStart, items, blockDimension) => {
    if (items.length > 1) {
        items = items.filter(item =>
            item.id === slotStart.id
                ? true
                : slotStart.start < item.end && item.start < slotStart.end,
        );
    }
    const itemIndex = items.findIndex(item => item.id === slotStart.id);

    return itemIndex * blockDimension;
};

export const getMaxNumberOfOverlappingItems = items => {
    // Reduce items to objects with time value (start & end) and type
    // Sort items by time value (both start & end values)
    const data = items
        .reduce(
            (acc, item) => [
                ...acc,
                {
                    value: item.start,
                    type: 'start',
                },
                {
                    value: item.end,
                    type: 'end',
                },
            ],
            [],
        )
        .sort((i1, i2) => (i1.value < i2.value ? -1 : 1));

    // Compute the max number of overlap among items
    let maxValue = 0;
    let count = 0;
    data.forEach(item => {
        if (item.type === 'start') {
            count++;
        } else {
            count--;
        }
        maxValue = Math.max(maxValue, count);
    });

    return maxValue;
};

export const hasFilters = filters => {
    let hasFilters = false;
    const hasProgram = filters?.myProgram && filters?.myProgram !== 'all';
    const hasText = filters?.text?.length > 0;
    const hasSetFilters = !isEmpty(filters?.filters);
    if (!isArray(filters) && (hasProgram || hasText || hasSetFilters)) {
        hasFilters = true;
    }
    return hasFilters;
};

export const EmptyFilter = () => (
    <EmptyState>
        <h4>{EMPTY_LIST_FILTER_TITLE}</h4>
        <p>{EMPTY_LIST_FILTER_SUBTITLE}</p>
        <PlaceholderImage />
    </EmptyState>
);

export const PlaceholderEmptyList = () => (
    <Placeholder introText={getString('emptyTimelineFilterTitle') || EMPTY_TIMELINE_FILTER_TITLE} />
);

export const PlaceholderEmptyProgramme = () => (
    <Placeholder type={'emptyProgramme'} introText={EMPTY_PROGRAMME_DAY} />
);

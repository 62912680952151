import React from 'react';
import styled from 'styled-components';
import * as palette from '../../../components/General/Variables';

const Pin = styled.div`
    width: 96px;
    height: 30px;
    z-index: 1;
    position: absolute;
`;

const PinLabel = styled.div`
    height: 30px;
    border-radius: 4px;
    top: 0;
    background-color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 6px;
    font-size: 10px;
    text-align: center;
    color: ${palette.COLOR_WHITE};
    box-shadow: ${palette.ELEVATION};
    z-index: 1;
    position: absolute;
    left: 50%;
    -webkit-filter: none;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    min-width: 96px;
`;

const Triangle = styled.div`
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.85) transparent transparent transparent;
    top: 29px;
    left: 40px;
    z-index: 1;
`;

class Marker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            y: props.data.y,
            x: props.data.x,
            top: props.data.top,
            left: props.data.left,
            scale: props.data.scale,
            visible: props.data.visible,
        };
    }

    static getDerivedStateFromProps(nextProps) {
        return {
            x: nextProps.data.x,
            y: nextProps.data.y,
            top: nextProps.data.top,
            left: nextProps.data.left,
            scale: nextProps.data.scale,
            visible: nextProps.data.visible,
        };
    }

    render() {
        const markerStyle = {
            transform: `translate3d(${this.state.x - 96 / 2 + this.state.left}px, ${
                this.state.y - 40 + this.state.top
            }px, 0)`,
            opacity: this.state.visible ? 1 : 0,
            transition: 'transform 20ms ease-in-out',
        };
        return (
            <React.Fragment>
                <Pin style={markerStyle}>
                    <PinLabel>{this.props.data.name}</PinLabel>
                </Pin>
                <Triangle style={markerStyle} />
            </React.Fragment>
        );
    }
}

export default Marker;

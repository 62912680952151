import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import Draggable from 'react-draggable';
import html2canvas from 'html2canvas';

import { useGlobalState } from '../../../../utils/container';
import scalingTools from '../../../VirtualModeration/features/virtualFeature/utils/scalingTools';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { colorTheme } from '../../../VirtualModeration/features/virtualFeature/constants/colors';
import useResize from '../../hooks/useResize';

const Wrapper = styled.div`
    position: relative;
    height: 500px;
    overflow: hidden;
    flex-shrink: 0;
    width: 100%;
`;

const PosterWrapper = styled.div`
    position: relative;
    text-align: center;
    background-color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
`;

const ContentWrapper = styled.div`
    position: relative;
    text-align: center;
    background-color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

const CanvasWrapper = styled.div`
    cursor: move;
`;

const SlideButton = styled.div`
    background-color: transparent;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    color: #ffffff;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: rgba(255, 255, 255, 0.15);
    }

    ${props =>
        props.disabled &&
        css`
            opacity: 0.5;
        `}
`;

const DecreaseButton = styled.div`
    width: 14px;
    height: 2px;
    background-color: #fff;
    border-radius: 2px;
    position: absolute;
    ${props =>
        props.rotate &&
        css`
            transform: rotate(90deg);
        `}
`;

const ZoomText = styled.div`
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    text-align: center;
    cursor: pointer;
    z-index: 4;
    border-radius: 4px;

    &:hover {
        ${props => !props.nohover && 'background-color: rgba(244, 245, 247, 0.15);'}
    }
    &:active {
        ${props => !props.nohover && 'background-color: rgba(244, 245, 247, 0.3);'}
    }
`;

const ButtonsWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 16px;
    left: 16px;
    width: calc(100% - 32px);
    z-index: 2;
`;

const ZoomContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    max-height: 40px;
    background-color: rgba(64, 64, 64, 0.8);
    border-radius: 8px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
`;

const FullScreenIconContainer = styled(ZoomContainer)`
    padding: 10px;
`;

const iconStyle = {
    color: colorTheme.WHITE,
    cursor: 'pointer',
};

const OnDemandPoster = url => {
    const stateCtx = useGlobalState();
    const { virtualEventUser } = stateCtx;
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({
        x: (virtualEventUser && virtualEventUser.data && virtualEventUser.data.positionX) || 0,
        y: (virtualEventUser && virtualEventUser.data && virtualEventUser.data.positionY) || 0,
    });
    const [shouldCalculatePosition, setShouldCalculatePosition] = useState(false);
    const [showZoomTool, setShowZoomTool] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [pdf, setPdf] = useState(null);
    const wrapperRef = useRef(null);
    const canvasRef = useRef(null);
    const contentRef = useRef(null);
    const videoRef = useRef(null);
    const intervalRef = useRef(null);

    const [drawing, setDrawing] = useState(true);
    const zoomPercentage = `${scale * 100}%`;

    useEffect(() => {
        (async () => {
            if (!(url && url.url)) {
                return;
            }
            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
            const pdf = await pdfjsLib.getDocument(url).promise;
            setPdf(pdf);
        })();
    }, [url]);

    useEffect(() => {
        (async () => {
            try {
                if (!wrapperRef.current || !pdf || !contentRef.current || !videoRef.current) {
                    return null;
                }

                const page = await pdf.getPage(1);

                const pageSize = {
                    height: page._pageInfo.view[3],
                    width: page._pageInfo.view[2],
                };
                const scalingFactor =
                    pageSize.width / pageSize.height >= 16 / 9 ? 'width' : 'height';
                const DYNAMIC_SCALING_MAP = scalingTools.getDynamicScalingMap(scalingFactor);

                const scaleToUse =
                    (DYNAMIC_SCALING_MAP[scalingFactor] * 100) / pageSize[scalingFactor] / 100;
                const viewport = page.getViewport({ scale: scaleToUse });

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');

                canvas.style.maxWidth = '100%';
                canvas.style.maxHeight = 'calc(100vh - 250px)';
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render the page into the <canvas> element.
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                setDrawing(true);

                await page.render(renderContext).promise;

                if (canvasRef.current) {
                    const canvasContext = canvasRef.current.getContext('2d');

                    canvasRef.current.style.maxWidth = '100%';
                    canvasRef.current.style.maxHeight = '100%';
                    canvasRef.current.height = viewport.height;
                    canvasRef.current.width = viewport.width;
                    canvasContext.drawImage(canvas, 0, 0, canvas.width, canvas.height);
                    setDrawing(false);
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }, [pdf]);

    useEffect(() => {
        if (shouldCalculatePosition && canvasRef.current && contentRef.current && pdf && !drawing) {
            const val = canvasRef.current.offsetHeight - contentRef.current.offsetHeight;
            const newY = val > 0 ? val / 2 + 10 : position.y;
            const newPosition = {
                x: 0,
                y: Math.floor(newY),
            };

            setShouldCalculatePosition(false);
            setPosition(newPosition);
        }
    }, [pdf, drawing, canvasRef.current, contentRef.current, shouldCalculatePosition]);

    useEffect(() => {
        (async () => {
            if (!drawing && videoRef.current && get(contentRef, 'current')) {
                const newCanvas = await html2canvas(contentRef.current, { imageTimeout: 0 });

                if (!videoRef.current) {
                    // it can disappear during the await
                    return;
                }

                // we always use the landscape scaling for the content
                const DYNAMIC_SCALING_MAP = scalingTools.getDynamicScalingMap('width');

                const newSize = {
                    width:
                        newCanvas.width >= DYNAMIC_SCALING_MAP.width
                            ? DYNAMIC_SCALING_MAP.width
                            : newCanvas.width,
                    height:
                        newCanvas.height >= DYNAMIC_SCALING_MAP.height
                            ? DYNAMIC_SCALING_MAP.height
                            : newCanvas.height,
                };

                if (videoRef.current.width !== newSize.width) {
                    videoRef.current.width = newSize.width;
                }

                if (videoRef.current.height !== newSize.height) {
                    videoRef.current.height = newSize.height;
                }

                const { height, width } = newSize;
                const canvasCtx = videoRef.current.getContext('2d');

                canvasCtx.drawImage(newCanvas, 0, 0, width, height);

                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }

                intervalRef.current = setInterval(() => {
                    if (!videoRef.current) {
                        return;
                    }

                    const canvasContext = videoRef.current.getContext('2d');

                    videoRef.current.style.maxWidth = '100%';
                    videoRef.current.style.maxHeight = '100%';
                    videoRef.current.height = newSize.height;
                    videoRef.current.width = newSize.width;
                    canvasContext.drawImage(newCanvas, 0, 0, newSize.width, newSize.height);
                }, 30);
            }
        })();

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [position, scale, drawing]);

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const onZoomOut = () => {
        if (scale <= 0.25) {
            return;
        }
        const scaleNew = scale - 0.25;
        setScale(scaleNew);
    };
    const onZoomIn = () => {
        if (scale >= 2.5) {
            return;
        }
        const scaleNew = scale + 0.25;
        setScale(scaleNew);
    };

    const onStopDrag = (e, data) => {
        const positionNew = {
            x: Math.floor(data.x),
            y: Math.floor(data.y),
        };
        setPosition(positionNew);
    };

    const openFullscreen = async () => {
        const elem = document.getElementById('poster-content-wrapper');

        if (elem.requestFullscreen) {
            await elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE11 */
            elem.msRequestFullscreen();
        }
        setIsFullScreen(true);
    };

    const closeFullScreen = async () => {
        if (document.exitFullscreen) {
            await document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            /* IE11 */
            document.msExitFullscreen();
        }
        setIsFullScreen(false);
    };

    const [resizeRef] = useResize();

    return (
        <Wrapper
            id="poster-content-wrapper"
            innerRef={resizeRef}
            onMouseEnter={() => setShowZoomTool(true)}
            onMouseLeave={() => setShowZoomTool(false)}
        >
            {url && (
                <PosterWrapper>
                    <ContentWrapper innerRef={contentRef}>
                        <Draggable onStop={onStopDrag} position={{ x: position.x, y: position.y }}>
                            <CanvasWrapper ref={wrapperRef}>
                                <canvas
                                    ref={canvasRef}
                                    style={{
                                        transform: `scale(${scale})`,
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                    }}
                                    id="pdf"
                                />
                            </CanvasWrapper>
                        </Draggable>
                    </ContentWrapper>
                    {showZoomTool && (
                        <ButtonsWrapper>
                            <ZoomContainer>
                                <SlideButton onClick={onZoomOut}>
                                    <DecreaseButton />
                                </SlideButton>
                                <ZoomText>{zoomPercentage}</ZoomText>
                                <SlideButton onClick={onZoomIn}>
                                    <DecreaseButton />
                                    <DecreaseButton rotate />
                                </SlideButton>
                            </ZoomContainer>
                            <FullScreenIconContainer>
                                {!isFullScreen && (
                                    <FullscreenIcon style={iconStyle} onClick={openFullscreen} />
                                )}
                                {isFullScreen && (
                                    <FullscreenExitIcon
                                        style={iconStyle}
                                        onClick={closeFullScreen}
                                    />
                                )}
                            </FullScreenIconContainer>
                        </ButtonsWrapper>
                    )}
                </PosterWrapper>
            )}

            <canvas ref={videoRef} style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </Wrapper>
    );
};

export default OnDemandPoster;

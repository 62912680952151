import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import RepresentativeCard, { connectButtonStatuses } from './RepresentativeCard';
import { navigateTo } from '../../../../components/Navigation/NavigationLink';
import * as palette from '../../../../components/General/Variables';
import { selectRepresentatives } from '../../selectors';
import {
    cancelFriendRequestFromRepresentative as cancelFriendRequestFromRepresentativeAction,
    fetchUsersFriendsList as fetchUsersFriendsListAction,
    getFullRepresentativesInfo as getFullRepresentativesInfoAction,
    sendFriendRequestToRepresentative as sendFriendRequestToRepresentativeAction,
    setPrivateChatUserId as setPrivateChatUserIdAction,
} from '../../actions';
import Loader from '../../../../components/General/Loader';
import eventBus from '../../../../utils/eventBus';

const Container = styled.div`
    width: 100%;
    padding: 0 6px;
    display: grid;
    grid-template-columns: 100%;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        padding: 0 16px 16px 16px;
    }

    & > *:not(:last-child) {
        margin-bottom: 16px;

        @media only screen and (max-width: ${palette.MAX_PHONE}) {
            margin-bottom: 0;
        }
    }

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }
`;

const RepresentativesList = ({
    fullRepresentatives,
    getConnectButtonStatus,
    setPrivateChatUserId,
    onConnectClick,
    onRepresentativeClick,
    getRepresentativesDetails,
}) => {
    return fullRepresentatives.map(rep => {
        const connectButtonStatus = getConnectButtonStatus(rep.id);

        return (
            <RepresentativeCard
                key={`representative-${rep.id}`}
                primaryText={`${rep.firstName} ${rep.lastName}`}
                secondaryText={getRepresentativesDetails(rep.jobTitle, rep.companyName)}
                imageUrl={rep.imageUrl}
                user={rep}
                onChatClick={() => {
                    if (rep.id) {
                        setPrivateChatUserId(rep.id);
                    }
                }}
                connectButtonStatus={connectButtonStatus}
                onConnectClick={() => onConnectClick(connectButtonStatus, rep.id)}
                isOnline={false}
                onClick={() => onRepresentativeClick(rep)}
            />
        );
    });
};

const RepresentativesColumn = ({
    representatives,
    fullRepresentatives,
    loadingFullRepresentatives,
    getFullRepresentativesInfo,
    setPrivateChatUserId,
    match,
    history,
    location,
    fetchUsersFriendsList,
    currentUsersFriendsList,
    sendFriendRequestToRepresentative,
    cancelFriendRequestFromRepresentative,
    sendingFriendshipRequest,
}) => {
    useEffect(() => {
        fetchUsersFriendsList();

        eventBus.on('refreshRepresentativesList', fetchUsersFriendsList);

        return () => eventBus.removeListener('refreshRepresentativesList', fetchUsersFriendsList);
    }, []);

    useEffect(() => {
        if (representatives.length) {
            getFullRepresentativesInfo(representatives);
        }
    }, [representatives]);

    if (loadingFullRepresentatives) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    const getRepresentativesDetails = (jobTitle, companyName) => {
        if (jobTitle && companyName) {
            return `${jobTitle} - ${companyName}`;
        }

        return jobTitle || companyName;
    };

    const onRepresentativeClick = item => {
        navigateTo({
            match,
            history,
            location,
            params: {
                type: 'profile',
                userId: item.id,
            },
        });
    };

    const getConnectButtonStatus = userId => {
        const friendship = currentUsersFriendsList.find(item => item.userId === userId);

        if (!friendship) {
            return connectButtonStatuses.VISIBLE;
        }

        return friendship.type === 'friends'
            ? connectButtonStatuses.HIDDEN
            : connectButtonStatuses.PENDING;
    };

    const onConnectClick = (status, userId) => {
        if (status === connectButtonStatuses.HIDDEN || sendingFriendshipRequest) {
            return;
        }

        if (status === connectButtonStatuses.VISIBLE) {
            sendFriendRequestToRepresentative(userId);
        } else {
            cancelFriendRequestFromRepresentative(userId);
        }
        eventBus.emit(`refreshFriendshipStatus_${userId}`);
    };

    return (
        <Container>
            {fullRepresentatives.length === 0 || representatives.length === 0 ? (
                <div>There are no representatives for this exhibitor.</div>
            ) : (
                <RepresentativesList
                    fullRepresentatives={fullRepresentatives}
                    getConnectButtonStatus={getConnectButtonStatus}
                    setPrivateChatUserId={setPrivateChatUserId}
                    onConnectClick={onConnectClick}
                    onRepresentativeClick={onRepresentativeClick}
                    getRepresentativesDetails={getRepresentativesDetails}
                />
            )}
        </Container>
    );
};

const mapStateToProps = state => {
    return {
        representatives: selectRepresentatives(state.exhibitorBooth),
        fullRepresentatives: state.exhibitorBooth.fullRepresentatives,
        loadingFullRepresentatives: state.exhibitorBooth.loadingFullRepresentatives,
        currentUsersFriendsList: state.exhibitorBooth.currentUsersFriendsList,
        sendingFriendshipRequest: state.exhibitorBooth.sendingFriendshipRequest,
    };
};

export default connect(mapStateToProps, {
    getFullRepresentativesInfo: getFullRepresentativesInfoAction,
    setPrivateChatUserId: setPrivateChatUserIdAction,
    fetchUsersFriendsList: fetchUsersFriendsListAction,
    sendFriendRequestToRepresentative: sendFriendRequestToRepresentativeAction,
    cancelFriendRequestFromRepresentative: cancelFriendRequestFromRepresentativeAction,
})(withRouter(RepresentativesColumn));

import { Cache } from 'aws-amplify/utils';
import localforage from 'localforage';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

const MAX_ARRAY_SIZE = 25;
const DB_CATEGORY = 'SearchResults';
const db = localforage.createInstance({
    name: DB_CATEGORY,
});

const config = {
    capacityInBytes: 5000000,
};

const myCacheConfig = Cache.configure(config);

export const getCacheItem = async (cacheKey, forceQuery = false) => {
    if (forceQuery) {
        return null;
    }

    return Cache.getItem(cacheKey) || (await getLocalItem(cacheKey));
};

export const setCacheItem = (cacheKey, item, priority) => {
    // Save this to cache if not empty
    if (isEmpty(cacheKey) || cacheKey === null) {
        return;
    }

    if (isArray(item) && item.length > MAX_ARRAY_SIZE) {
        setLocalItem(cacheKey, item);
    } else {
        Cache.setItem(cacheKey, item, { priority });
    }
};

const getLocalItem = cacheKey => {
    return db.getItem(cacheKey);
};
const setLocalItem = (cacheKey, item) => {
    db.setItem(cacheKey, item);
};

export const clearLocalStorage = async () => {
    db.clear();
    const keys = await Cache.getAllKeys();
    await Promise.all(
        keys.map(key => {
            return Cache.removeItem(key);
        }),
    );
};

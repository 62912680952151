import styled from 'styled-components';
import React from 'react';

const TextArea = styled.textarea`
    border: none;
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
    width: 100%;
    height: 125px;
    background-color: #efefef;
    border-radius: 8px;
    padding: 12px;
`;

const Textarea = ({ value, onChange, placeholder }) => {
    return <TextArea onChange={onChange} placeholder={placeholder} value={value} />;
};

export default Textarea;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: ${props => (props.withWrapper || props.small ? '24px' : '26px')};
    height: ${props => (props.withWrapper || props.small ? '24px' : '26px')};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${props => (props.withWrapper ? 'rgba(0, 0, 0, 0.3)' : '')};
`;

const Bar = styled.div`
    width: ${props => (props.withWrapper || props.small ? '4px' : '6px')};
    height: ${props => props.height || (props.wrapper || props.small ? 4 : 6)}px;
    background-color: #ffffff;
    border-radius: ${props => (props.withWrapper || props.small ? '2px' : '4px')};
    transition: height 0.1s;

    &:not(:last-child) {
        margin-right: ${props => (props.withWrapper || props.small ? '3px' : '4px')};
    }
`;

const VoiceIndicator = ({ level, wrapper, small }) => {
    const MAX_INDICATOR_HEIGHT = wrapper || small ? 14 : 18;
    const MIN_INDICATOR_HEIGHT = wrapper || small ? 4 : 6;

    const [additionalHeight, setAdditionalHeight] = useState(0);

    const updateHeight = () => {
        // Generate random value between 0 and 12 (MAX - MIN)
        const upperBound = MAX_INDICATOR_HEIGHT - MIN_INDICATOR_HEIGHT;
        const randomValue = Math.floor(Math.random() * (upperBound + 1));

        setAdditionalHeight(randomValue);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (parseInt(level) >= 0.8) {
                updateHeight();
            } else if (additionalHeight > 0) {
                setAdditionalHeight(0);
            }
        }, 100);

        return () => clearInterval(intervalId);
    }, [level, additionalHeight]);

    const middleBarHeight = MIN_INDICATOR_HEIGHT + additionalHeight;
    let edgeBarsHeight = MIN_INDICATOR_HEIGHT + additionalHeight / 2;

    if (edgeBarsHeight > MAX_INDICATOR_HEIGHT / 2) {
        edgeBarsHeight = MAX_INDICATOR_HEIGHT / 2;
    }

    return (
        <Container withWrapper={wrapper} small={small}>
            <Bar height={edgeBarsHeight} withWrapper={wrapper} small={small} />
            <Bar height={middleBarHeight} withWrapper={wrapper} small={small} />
            <Bar height={edgeBarsHeight} withWrapper={wrapper} small={small} />
        </Container>
    );
};

export default VoiceIndicator;

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

const Bar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    background-color: ${props => (props.color ? props.color : '#dedee1')};
    width: ${props => (props.width ? props.width : '100%')};
    ${props =>
        props.slimbar &&
        css`
            height: 4px;
            border-radius: 4px;
        `}
`;

const ProgressBar = ({ activeColor, activePoll, pollSet, stopVoting, isForRecording, slimBar }) => {
    const { updatedAt: pollSetLastUpdate, state } = pollSet;
    const { useTimer, seconds: pollDuration } = activePoll;
    const isPollSetOpen = state === 'open';
    const staringTimer =
        pollDuration - Math.floor((Date.now() - new Date(pollSetLastUpdate).getTime()) / 1000);
    const [secondsRemaining, setSecondsRemaining] = useState(staringTimer);
    const [isVotingOpen, setIsVotingOpen] = useState(isPollSetOpen);

    useEffect(() => {
        if (isPollSetOpen) {
            const timerInterval = setInterval(() => {
                setSecondsRemaining(val => val - 0.1);
            }, 100);

            return () => {
                clearInterval(timerInterval);
            };
        }
    }, [isPollSetOpen, pollSetLastUpdate, activePoll]);

    useEffect(() => {
        if (activePoll) {
            const staringTimer =
                pollDuration -
                Math.floor((Date.now() - new Date(pollSetLastUpdate).getTime()) / 1000);
            setSecondsRemaining(staringTimer);
        }
    }, [activePoll]);

    const getTimePercentageRemaining = () => `${100 - (secondsRemaining * 100) / pollDuration}%`;

    if (secondsRemaining <= 0 && isVotingOpen && !isForRecording && stopVoting) {
        stopVoting(activePoll.id);
        setIsVotingOpen(false);
    }

    if (!useTimer) {
        return null;
    }

    return (
        <>
            <Bar slimbar={slimBar ? 1 : 0} />
            <Bar
                slimbar={slimBar ? 1 : 0}
                color={activeColor}
                width={getTimePercentageRemaining()}
            />
        </>
    );
};

export default ProgressBar;

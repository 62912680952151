import React from 'react';
import styled, { keyframes } from 'styled-components';

const dotAnimation = keyframes`
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
`;

const Dot = styled.span`
    background-color: rgba(0, 0, 0, 0.87);
    width: 3px;
    height: 3px;
    border-radius: 50%;
    margin: 0 1px;
    animation: ${dotAnimation} 1.5s infinite;
`;

const Dot2 = styled(Dot)`
    animation-delay: 0.5s;
`;

const Dot3 = styled(Dot)`
    animation-delay: 1s;
`;

const Container = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    margin-left: 2px;
    width: fit-content;
    height: 15px;
`;

const CaptionLoading = ({ color }) => {
    return (
        <Container>
            <Dot style={{ backgroundColor: color }} />
            <Dot2 style={{ backgroundColor: color }} />
            <Dot3 style={{ backgroundColor: color }} />
        </Container>
    );
};

export default CaptionLoading;

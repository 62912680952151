import React from 'react';
import { CircularProgress, Paper } from 'react-md';
import styled from 'styled-components';
import ThemeContext from '../Theme/ThemeContext';

const LoaderContainer = styled(Paper)`
    ${props =>
        props.simpleloader
            ? `
        width: 20px;
        height: 20px;
        border-radius: 25px;
        box-shadow: unset !important;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            top: 0 !important;
        }
    `
            : `
        background-color: white;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        margin-left: calc(50% - 25px);
        margin-top: ${props => (props.margintop ? props.margintop : '40px')};
    `}
    .md-progress {
        margin: 0 auto !important;
    }
`;

const Circle = styled(CircularProgress)`
    position: relative;
    top: 12px;
    width: 25px;
    height: 25px;

    circle {
        stroke: ${({ color }) => color};
    }
`;

const Loader = ({ simpleLoader, customColor, margintop }) => {
    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <LoaderContainer
                    zDepth={1}
                    simpleloader={simpleLoader && simpleLoader.toString()}
                    margintop={margintop}
                >
                    <Circle id="loader" color={customColor || theme.primary || 'white'} />
                </LoaderContainer>
            )}
        </ThemeContext.Consumer>
    );
};

export default Loader;

// import { callApi as callEurekaApi } from 'src/api/eureka/setup';
import * as eureka from '../../../services/api/eureka';

const callEurekaApi = ({ method = 'get', url, data = {} }, cb) => {
    url = url[0] !== '/' ? `/${url}` : url;
    return new Promise((resolve, reject) => {
        eureka.eurekaRequest(method, url, data, true, (err, response) => {
            if (err) {
                reject(`Eureka Talk error: ${JSON.stringify(err)}`);
            } else {
                resolve(response);
            }
        });
    });
};

export const getEurekaInfoWithJid = ({ jids }) =>
    callEurekaApi({
        url: '/api/entities/chatRooms/chatUsers',
        method: 'post',
        data: { jids },
    });

export const getConversations = data =>
    callEurekaApi({
        url: 'api/entities/chatRooms/findRoomsPerUser',
        method: 'post',
        data,
    });

export const getGroupChatMessages = ({ room, limit, lastTimestamp }) => {
    let url = `api/services/chat/getgroupmessages?room=${room}&limit=${limit}`;
    if (lastTimestamp) {
        url = `${url}&last_stamp=${lastTimestamp}`;
    }

    return callEurekaApi({
        url,
    });
};

export const registerChatUser = () =>
    callEurekaApi({
        url: 'api/services/chat/register',
        method: 'post',
    });

export const getGroupChatParticipants = data =>
    callEurekaApi({
        url: 'api/entities/chatRooms/roomUsers',
        method: 'post',
        data,
    });

/**
 * @method getPrivateChatMessages
 * @description Fetch all messages history between current user and friend (from)
 * @param {String} userId current logged user id
 * @param {String} from the target user (AKA friend) Jid + @ + chatHost for example: 136@devtalk.get-eureka.com
 * @param {String} limit How many messages to fetch, default should be 25
 * @param {String} lastTimestamp last fetched message timestamp
 * @returns {Array} Returns an array of messages
 */
export const getPrivateChatMessages = ({ userId, from, limit, lastTimestamp }) => {
    let url = `api/services/chat/getmessages?user=${userId}&peer=${from}&limit=${limit}`;
    if (lastTimestamp) {
        url = `${url}&last_stamp=${lastTimestamp}`;
    }
    return callEurekaApi({
        url,
    });
};

export const startGroupChat = data =>
    callEurekaApi({
        url: 'api/entities/chatRooms/enter',
        method: 'post',
        data,
    });

/**
 * @method reportOrDeleteGroupChatMessage
 * @description Reports or deletes a chat message
 * @param {Object} message complete message object with all infos needed to construct post data
 * @param {String} conversationId where this message is coming from
 * @param {Boolean} deleteMessage when *false* the message is reported. When *true* the message is really deleted
 */
export const reportOrDeleteGroupChatMessage = (message, conversationId, deleteMessage = false) => {
    const { id, nick, timestamp, txt } = message;

    // "just sent" messages have timestamp as an temporary ID
    const messageWithId = id.toString() !== timestamp.toString();
    const messageTimestamp = parseInt(timestamp, 10) / 1000; // Admin UI requires date in this format
    const data = {
        ...(messageWithId && { ejabberdId: id }), // we shouldn't include ejabberdId prop if message has no ID yet
        reason: deleteMessage ? 'User deleted' : 'Bad content',
        chatRoomId: conversationId,
        from: nick,
        message: `${txt}@:${messageTimestamp}`,
        timestamp,
        delete: deleteMessage,
    };
    return callEurekaApi({
        url: 'api/entities/chatObjections',
        method: 'post',
        data,
    });
};

/**
 * @method sendPushNotificationMessage
 * @description Accepts an chat objection object
 * @param {string} id - chat objection id
 */
export const acceptChatObjection = id => {
    return callEurekaApi({
        url: `api/entities/chatObjections/${id}`,
        method: 'delete',
    });
};

/**
 * @method sendPushNotificationMessage
 * @description Triggers a push notification to be sent to other's user device
 * @param {Object} data Data object to be snet in POST body
 * @param {string} data.configuratorUrl Configurator URL
 * @param {string} data.to Id of the user receiving the push notification
 * @param {string|null} [data.event] Event id - should be specified only for Appointment messages
 * @param {string} data.message Text message
 * @param {Object} [data.data] Appointment data - should be specified only for Appointment messages
 * @param {string} data.data.objectId Appointment id
 * @param {string} data.data.eurekaId Appointment eureka id
 * @param {string} data.data.creator Appointment creator
 * @param {string} data.data.from Current user's jid
 * @param {('chat'|'appointment')} data.type Message type
 */
export const sendPushNotificationMessage = ({ data }) => {
    return callEurekaApi({
        url: 'api/services/chat/pushNotify',
        method: 'post',
        data,
    });
};

import React, { useMemo } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';

import Button, { ButtonClassType } from '../../common/Button';
import entities from '../../../constants/entities';
import { useVMState } from '../../../containers/main';
import VirtualSessionUser from './VirtualSessionUser';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import { stopPreRecordings } from '../../../services/VirtualEventSession';

const { speakerAndSlider, panelDiscussion } = entities.virtualEventSessionEntity.mode;

const Wrapper = styled.div`
    flex: 1.875;
    margin: 0;
    overflow: hidden;
    background: #fff;
    border-radius: 8px;
    padding: 16px;
    height: 50%;

    @media (max-height: 800px) and (orientation: landscape) {
        max-width: 400px;
    }
`;

const VirtualUsersWrapper = styled.div`
    flex: 1;
    margin: 15px 0 0 0;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100% - 32px);
    max-height: calc(100vh - 254px);
`;

const Switcher = styled.div`
    height: 30px;
    width: fit-content;
    border-radius: 16px;
    background-color: #f1f1f3;
    display: flex;
    cursor: pointer;

    button {
        flex: 1;
    }
`;

const SpeakerPanelHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Cabin', sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: rgba(0, 0, 0, 0.87) !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
        display: flex;
        align-items: center;

        svg {
            margin-right: 12px;
        }
    }
`;

const SessionSidebar = ({ localStream }) => {
    const stateCtx = useVMState();
    const { virtualEventSession, sessionId, socket } = stateCtx;

    const stopPreRecording = async () => {
        const cloneVirtualEventSession = cloneDeep(virtualEventSession);
        const virtualEventUsers = cloneVirtualEventSession.data.VirtualEventUsers;
        const shouldDisableActiveItems = !!virtualEventUsers.find(vUser => vUser.hasVideoActive);

        if (!shouldDisableActiveItems) {
            return;
        }

        stopPreRecordings(sessionId);

        const newVirtualEventUsers = virtualEventUsers.map(virtualEventUser => ({
            id: virtualEventUser.id,
            isActive: false,
            hasVideoActive: false,
        }));

        return {
            VirtualEventUsers: newVirtualEventUsers,
        };
    };

    const onSelectMode = async virtualEventSessionMode => {
        let cloneVirtualEventSession = pick(virtualEventSession, ['id']);

        if (virtualEventSessionMode === panelDiscussion) {
            cloneVirtualEventSession = stopPreRecording();
        }

        cloneVirtualEventSession.mode = virtualEventSessionMode;

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: cloneVirtualEventSession,
            updateCall: true,
        });
    };
    const onSelectSpeakerAndSlides = async () => {
        await onSelectMode(speakerAndSlider);
    };
    const onSelectPanelDiscussion = async () => {
        await onSelectMode(panelDiscussion);
    };

    const getClassType = virtualEventSessionMode => {
        return virtualEventSession.data.mode === virtualEventSessionMode
            ? ButtonClassType.WHITE_ROUND_NO_BORDERS
            : ButtonClassType.TRANSPARENT_NO_BORDERS;
    };

    const virtualEventUsers = useMemo(
        () => get(virtualEventSession, 'data.VirtualEventUsers', []),
        [virtualEventSession],
    );
    const { mode } = virtualEventSession.data;
    const isPanelDiscussion = mode === panelDiscussion;

    const activeVideoId = get(virtualEventSession, 'data.activeVideoId', null);
    const userWithActivePrerecording = get(virtualEventSession, 'data.VirtualEventUsers', []).find(
        a => a.hasVideoActive,
    );

    const panelDisabled = userWithActivePrerecording || activeVideoId;

    return (
        <Wrapper>
            <SpeakerPanelHeader>
                <div>
                    <PersonIcon /> Live speakers
                </div>
                <Switcher>
                    <Button
                        callback={onSelectSpeakerAndSlides}
                        content="Speaker"
                        classType={getClassType(speakerAndSlider)}
                    />
                    <Button
                        callback={onSelectPanelDiscussion}
                        disabled={panelDisabled}
                        content="Panel"
                        classType={getClassType(panelDiscussion)}
                    />
                </Switcher>
            </SpeakerPanelHeader>

            <VirtualUsersWrapper>
                {virtualEventUsers.map((virtualUser, index) => (
                    <VirtualSessionUser
                        key={virtualUser.id}
                        isPanelDiscussion={isPanelDiscussion}
                        localStream={localStream}
                        virtualUser={virtualUser}
                        virtualUserIndex={index}
                    />
                ))}
            </VirtualUsersWrapper>
        </Wrapper>
    );
};

export default SessionSidebar;

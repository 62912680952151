import React, { useEffect, useState } from 'react';

import { addFavoriteForEvent, isFavoriteById, removeFavorite } from '../../services/api/data';
import Bookmark from '../General/Bookmark';
import { ImageAvatar } from '../Icons';
import * as eureka from '../../services/api/eureka';
import eventBus from '../../utils/eventBus';
import AnalyticsService from '../../features/analytics/services';
import ListCard from '../General/ListCard';
import placeholderImage from '../../assets/images/placeholder-person.png';
import { StyledListItem } from './style/index';
import {
    AchievementType,
    useAchievementActions,
} from '../../scenes/Achievements/hooks/useAchievementActions';

const PersonListItem = ({ person, cardView }) => {
    const [favoriteStatus, setFavoriteStatus] = useState(person.favorite ? 'checked' : '');
    const { trackAchievement } = useAchievementActions();

    useEffect(() => {
        onFavorite();
    }, [person.id]);

    const onFavorite = event => {
        if (event) {
            event.preventDefault();
            const _favoriteStatus = favoriteStatus === 'checked' ? '' : 'checked';
            setFavoriteStatus(_favoriteStatus);
            if (_favoriteStatus === 'checked') {
                addFavoriteForEvent(person, () => {
                    AnalyticsService.addSample('favorite', true, person.id);
                    trackAchievement(AchievementType.FAVORITE, person.id);

                    eureka.sync(() => {
                        eventBus.emit('updateMyFavoritesCount');
                    });
                });
            } else {
                removeFavorite(person, () => {
                    AnalyticsService.addSample('favorite', false, person.id);
                    trackAchievement(AchievementType.FAVORITE, person.id);

                    eureka.sync(() => {
                        eventBus.emit('updateMyFavoritesCount');
                    });
                });
            }
        } else {
            isFavoriteById(person.id, (err, isFavorite) => {
                if (isFavorite) {
                    setFavoriteStatus('checked');
                }
            });
        }
    };

    if (cardView) {
        return (
            <ListCard
                key={'person_' + person.id}
                primaryText={person.name}
                secondaryText={person.subNameList}
                imageUrl={person.imageUrl || placeholderImage}
                isLogo={true}
                type={'persons'}
                rightIcon={
                    <Bookmark favoriteStatus={favoriteStatus} clickMyCongress={onFavorite} />
                }
            />
        );
    }

    return (
        <StyledListItem
            key={'person_' + person.id}
            primaryText={person.name}
            style={{ position: 'relative' }}
            secondaryText={person.subNameList}
            tabIndex={-1}
            renderChildrenOutside
            fitTextInContainer
            leftAvatar={
                <ImageAvatar imageId={person.imageUrl || person.image} avatarType={'persons'} />
            }
        >
            <Bookmark
                favoriteStatus={favoriteStatus}
                clickMyCongress={onFavorite}
                top={'15'}
                right={'32'}
            />
        </StyledListItem>
    );
};

export default PersonListItem;

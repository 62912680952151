import { useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import {
    AchievementType,
    useAchievementActions,
} from '../scenes/Achievements/hooks/useAchievementActions';
import Auth from '../services/api/auth';
import { getIsGamificationEnabled } from '../scenes/Achievements/selectors';

const useTimeTracking = () => {
    const { trackAchievement, deleteAchievementUser } = useAchievementActions();
    const isGamificationEnabled = useSelector(getIsGamificationEnabled);

    useEffect(() => {
        const user = Auth.getUser();

        if (!user || !isGamificationEnabled) {
            return;
        }

        const lastActiveDates = JSON.parse(localStorage.getItem('LAST_ACTIVE_DATE')) || {};
        const currentUserLastActiveDate = lastActiveDates[user.id];

        if (
            currentUserLastActiveDate &&
            moment(new Date()).diff(moment(currentUserLastActiveDate), 'day') === 1
        ) {
            trackAchievement(AchievementType.DAYS_IN_A_ROW);
        }
        if (
            currentUserLastActiveDate &&
            moment(new Date()).diff(moment(currentUserLastActiveDate), 'day') > 1
        ) {
            deleteAchievementUser(AchievementType.DAYS_IN_A_ROW);
        }

        localStorage.setItem(
            'LAST_ACTIVE_DATE',
            JSON.stringify({
                ...lastActiveDates,
                [user.id]: new Date(),
            }),
        );

        const interval = setInterval(() => {
            const timeOnPlatform = JSON.parse(localStorage.getItem('TIME_ON_PLATFORM')) || {};
            const currentUserTimeOnPlatform = timeOnPlatform[user.id];
            if (currentUserTimeOnPlatform > 0 && currentUserTimeOnPlatform % 60 === 0) {
                trackAchievement(AchievementType.TIME_ON_PLATFORM);
            }
            localStorage.setItem(
                'TIME_ON_PLATFORM',
                JSON.stringify({
                    ...timeOnPlatform,
                    [user.id]: currentUserTimeOnPlatform + 1,
                }),
            );
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, [isGamificationEnabled, trackAchievement, deleteAchievementUser]);
};

export default useTimeTracking;

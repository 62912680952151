class Buddy {
    constructor(fromJid, subscription) {
        const [name] = fromJid.split('@');
        this.jid = fromJid;
        this.state = 'offline';
        this.subscription = subscription || 'none';
        this.name = name;
        this.role = 'Participant';
        this.img = '';
        this.pendingMessages = [];
        this.lastMessage = {};
        this.displayName = name;
        this.available = false;
    }

    update(info) {
        Object.assign(this, info);
        this.displayName = `${info.firstName} ${info.lastName}`;
        if (info.lastMessage) {
            this.lastMessage = info.lastMessage;
        }
        if (info.chatAvailable) {
            this.available = info.chatAvailable;
        }
    }
}

export default Buddy;

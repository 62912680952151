import get from 'lodash/get';

const onDeleteAudioDescription = async (stateCtx, mutationCtx) => {
    const { socket, sessionId, virtualEventUser } = stateCtx;
    const list = get(virtualEventUser, 'data.audioRecordingInfo.list', []);

    if (list.length) {
        mutationCtx.setExtraState({
            audioVideoDuration: 0,
            audioDuration: null,
            audioPlaying: false,
            audioPlayedSeconds: 2,
            audioPlayerRef: null,
        });

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        audioRecordingInfo: null,
                        hasVideoActive: false,
                        audioRecordingPresentationUrl: null,
                        isAudioRecording: false,
                    },
                ],
                audioUrls: null,
            },
        });
    }
};

export default onDeleteAudioDescription;

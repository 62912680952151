import React from 'react';
import AuthUserContext from './AuthUserContext';
import auth from '../../services/api/auth';
import SignIn from '../../scenes/SignInPage/components';
import reduxStore from '../../reduxStore';
import { Provider } from 'react-redux';
import { navigateTo } from '../Navigation/NavigationLink';
import { matchPath } from 'react-router-dom';

const withAuthentication = Component =>
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                authUser: null,
                signInVisible: false,
                signInCurrentPage: null,
            };
        }

        componentDidMount() {
            auth.subscribe(async authUser => {
                authUser
                    ? this.setState(() => ({ authUser }))
                    : this.setState(() => ({ authUser: null }));
            });
        }

        showSignIn = (e, params) => {
            const { match, history, location, replace, signInCurrentPage, fullScreen } = params;
            e && e.preventDefault && typeof e.preventDefault === 'function' && e.preventDefault();
            const matcher = matchPath(history.location.pathname, {
                path: '/event/:eventName',
                strict: false,
            });
            const kiosk = matchPath(history.location.pathname, {
                path: '/kiosk/:eventName',
                strict: false,
            });
            if ((matcher || kiosk) && !fullScreen) {
                this.setState({ notesVisible: false, signInVisible: true, signInCurrentPage });
            } else {
                navigateTo({
                    match,
                    history,
                    location,
                    path: '/sign-in',
                    replace,
                });
            }
        };

        hideSignIn = () => {
            this.setState({ notesVisible: false, signInVisible: false, signInCurrentPage: null });
        };

        render() {
            const { authUser } = this.state;

            return (
                <Provider store={reduxStore}>
                    <AuthUserContext.Provider value={{ authUser, showSignIn: this.showSignIn }}>
                        <Component />
                        {this.state.signInVisible && (
                            <SignIn
                                visible={this.state.signInVisible}
                                onHideDialog={this.hideSignIn}
                                signInCurrentPage={this.state.signInCurrentPage}
                            ></SignIn>
                        )}
                    </AuthUserContext.Provider>
                </Provider>
            );
        }
    };

export default withAuthentication;

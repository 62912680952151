import React from 'react';
import styled from 'styled-components';

const StartEndTimeWrapper = styled.div`
    color: ${props => props.customColor || 'rgba(0, 0, 0, 0.87)'};
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 12px;
    white-space: nowrap;
    min-width: 100px;
    justify-content: ${props => (props.displayOnlyTime ? 'flex-start' : 'center')};
    ${props =>
        props.displayOnlyTime &&
        `
    margin-top: 10px;
    `};

    div:nth-of-type(1) {
        font-size: ${props => (props.displayOnlyTime ? '15px' : '13px')};
        margin-bottom: 2px;
        white-space: normal;
    }

    div {
        width: 100%;
        text-align: center;
    }
`;

const StartTime = props => {
    if (props.customColor === 'transparent') {
        return null;
    }
    return (
        <StartEndTimeWrapper
            customColor={props.customColor}
            displayOnlyTime={props.displayOnlyTime}
        >
            {props.text && !props.displayOnlyTime && <div>{props.start}</div>}
            <div>{props.text}</div>
        </StartEndTimeWrapper>
    );
};

export default StartTime;

import React, { useEffect, useState } from 'react';
import toast from 'toasted-notes';

import { useGlobalState } from '../../../utils/container';
import { getLocalAppStateAsync } from '../../../services/api/db';
import Auth from '../../../services/api/auth';
import AchievementNotification from '../components/AchievementNotification';

const useAchievementsListener = () => {
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const [appState, setAppState] = useState();
    const isSociety = appState && !appState.id;

    useEffect(() => {
        const loadAppState = async () => {
            const localAppState = await getLocalAppStateAsync();
            setAppState(localAppState);
        };

        loadAppState();
    }, []);

    useEffect(() => {
        if (!appState || isSociety || !socket) {
            return;
        }

        const eventId = appState.id;
        const user = Auth.getUser();

        const handleQuestionLiked = data => {
            const { achievement, notification } = data;

            if (!notification) {
                return;
            }

            // show notification
            toast.notify(
                ({ onClose }) => (
                    <AchievementNotification
                        title={notification.title}
                        subtitle={notification.subtitle}
                        level={notification.level}
                        icon={achievement.icon}
                        percentage={notification.percentage}
                        onClose={onClose}
                    />
                ),
                {
                    position: 'top-right',
                    duration: 4000,
                },
            );
        };

        const bootstrapAsync = async () => {
            socket.on(`refreshAchievement_${eventId}_${user.id}`, handleQuestionLiked);
        };

        bootstrapAsync();

        return () => {
            socket.removeAllListeners(
                `refreshAchievement_${eventId}_${user.id}`,
                handleQuestionLiked,
            );
        };
    }, [socket, appState]);
};

export default useAchievementsListener;

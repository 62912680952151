import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getImageSource } from '../../services/api/db';

const Icon = styled.div`
    width: ${props => props.size || '20px'};
    display: inline-block;
    margin-right: 6px;
    height: ${props => props.size || '20px'};
    background-image: url(${props => props.imageUrl});
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
`;

const ClassifierIcon = ({ imageId, size }) => {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        if (imageId && !imageId.webappIcon) {
            try {
                getImageSource(imageId, (err, result) => {
                    if (result) {
                        setImageUrl(result);
                    }
                });
            } catch (err) {
                console.log('err', err, imageId);
            }
        }
    }, [imageId]);

    if (imageUrl) {
        return <Icon imageUrl={imageUrl} size={size} />;
    }
    return null;
};

export default memo(ClassifierIcon);

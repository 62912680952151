import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, FontIcon } from 'react-md';
import { useTheme } from '../../../../../components/Theme/ThemeContext';
import { colorTheme } from '../../virtualFeature/constants/colors';

const InputTextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 2px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 12px 16px;
    background-color: #ffffff;
`;

const ButtonStyled = styled(Button)`
    height: 36px !important;
    width: 36px !important;
    min-width: 36px !important;
    vertical-align: middle !important;
    border-radius: 50% !important;
    padding: 0 !important;
    margin-left: 4px;
    &:hover {
        background: ${props => (!props.disabled ? 'rgba(0, 0, 0, 0.1) !important;' : '')};
    }
    &:active {
        background: rgba(0, 0, 0, 0.2) !important;
    }
`;

const TextInput = styled.textarea`
    border: ${props => (props.redBorder ? 'solid 1px #d50000' : 'none')};
    outline: none;
    overflow: hidden;
    width: 100%;
    padding: 10px 10px 10px 10px;
    border-radius: 20px;
    background-color: #f0f0f0;
    font-size: 14px;
    resize: none;
`;

const ErrorMessage = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: #d50000;
    margin-left: 16px;
`;

const Icon = styled(FontIcon)`
    color: ${props => props.color} !important;
    vertical-align: top;
    font-size: 24px !important;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid ${colorTheme.SECONDARY};
`;

const textareaLineHeight = 20;

const InputMessageComponent = ({
    sendMessage,
    placeholder,
    onChange,
    maxChar,
    placeholderFitsOnTwoLines,
}) => {
    const { theme } = useTheme();
    const [inputMessage, setInputMessage] = useState('');
    const [maxCharExceeded, setMaxCharExceeded] = useState(false);
    const [textAreaRows, setRows] = useState({
        rows: placeholderFitsOnTwoLines ? 2 : 1,
        minRows: placeholderFitsOnTwoLines ? 2 : 1,
        maxRows: 5,
    });

    const handleChange = event => {
        const { value, rows } = event.target;
        const { minRows, maxRows } = textAreaRows;
        const previousRows = rows;
        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

        setMaxCharExceeded(false);
        if (maxChar && value.length > maxChar) {
            setMaxCharExceeded(true);
            return;
        }

        setInputMessage(value);
        if (onChange) {
            onChange(value);
        }

        if (value === '') {
            setRows({
                ...textAreaRows,
                rows: placeholderFitsOnTwoLines ? 2 : 1,
            });
            return;
        }

        // We do this in order to reset the number of rows to 1 if the placeholder fits on two lines
        // but after typing the text will fit on a single line
        if (value.length === 1) {
            setRows({
                ...textAreaRows,
                rows: 1,
            });
            return;
        }

        event.target.rows = minRows; // reset number of rows in textarea

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        setRows({
            ...textAreaRows,
            rows: currentRows < maxRows ? currentRows : maxRows,
        });
    };

    const onKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            onSendMessage();
        }
    };

    const onSendMessage = () => {
        if (validMessage(inputMessage)) {
            sendMessage(inputMessage);
            setInputMessage('');
            setRows({
                ...textAreaRows,
                rows: placeholderFitsOnTwoLines ? 2 : 1,
            });
        }
    };

    const validMessage = text => {
        return !(!text || !text.replace(/\s/g, '').length || maxCharExceeded);
    };

    return (
        <FlexColumn>
            <InputTextWrapper>
                <TextInput
                    id="messageInputField"
                    placeholder={placeholder}
                    value={inputMessage}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                    autoComplete="off"
                    rows={textAreaRows.rows}
                    redBorder={maxCharExceeded}
                />

                {validMessage(inputMessage) && (
                    <ButtonStyled
                        flat
                        onClick={onSendMessage}
                        disabled={!validMessage(inputMessage)}
                    >
                        <Icon color={validMessage(inputMessage) ? theme.primary : '#f0f0f0'}>
                            send
                        </Icon>
                    </ButtonStyled>
                )}
            </InputTextWrapper>
            {maxChar && maxCharExceeded && (
                <ErrorMessage>
                    {maxChar}/{maxChar} characters
                </ErrorMessage>
            )}
        </FlexColumn>
    );
};

export default InputMessageComponent;

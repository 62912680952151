import { PadddingLeftRight16px } from '../../common/Padding';
import React from 'react';
import { MarginTop16px } from '../../common/Margin';
import Button, { buttonTypes } from '../../common/Button';
import { getString } from '../../../services/api/store';
import { SectionTitle } from '../style/index';
import ReactHtmlParser from 'react-html-parser';
import {
    ContactDetailsAnchor,
    ContactDetailsButton,
    SectionDescription,
} from '../../DetailPage/InfoBar';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { Flex } from '../../common/Flex';
import copyUtils from '../../VirtualModeration/features/virtualFeature/utils/copyUtils';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CustomTooltip from '../../../components/CustomTooltip/CustomTooltip';

const ProfileInformationContact = ({
    theme,
    profileData,
    showMore,
    readAll,
    setReadAll,
    text,
    renderRepresentativeOfList,
}) => {
    const [hoverPhone, setHoverPhone] = React.useState(false);
    const [copied, setCopied] = React.useState(false);
    const TEXT_REPRESENTATIVE_ROLE = getString('representativeRole') || 'Representative of';
    const phoneNumber =
        profileData && profileData.Links.find(link => link.type === 'phone')?.url?.substring(4);
    const email = profileData?.email;

    return (
        <PadddingLeftRight16px>
            {profileData &&
                profileData.representativeOf &&
                profileData.representativeOf.length > 0 && (
                    <>
                        <SectionTitle>{TEXT_REPRESENTATIVE_ROLE}</SectionTitle>
                        {renderRepresentativeOfList()}
                    </>
                )}
            {profileData && profileData.headline && (
                <>
                    <SectionTitle>Biography</SectionTitle>
                    <SectionDescription primary={theme.primary} contrast={theme.contrast}>
                        {ReactHtmlParser(text)}
                    </SectionDescription>
                    <MarginTop16px />
                    {showMore && (
                        <Button
                            type={buttonTypes.GREY_LONG}
                            background={theme.primary}
                            text={
                                !readAll
                                    ? getString('announcementsReadMore') || 'Read More'
                                    : getString('announcementsReadLess') || 'Read less'
                            }
                            onClick={() => setReadAll(!readAll)}
                        />
                    )}
                </>
            )}
            {profileData && (phoneNumber || email) && (
                <>
                    <SectionTitle>Contact Details</SectionTitle>
                    <MarginTop16px />
                    {phoneNumber && (
                        <Flex
                            onMouseEnter={() => setHoverPhone(true)}
                            onMouseLeave={() => setHoverPhone(false)}
                        >
                            <PhoneEnabledIcon style={{ marginRight: 16 }} />
                            <CustomTooltip
                                visible={hoverPhone}
                                text={copied ? 'Copied' : 'Copy'}
                                width={90}
                            >
                                <ContactDetailsButton
                                    onClick={() => {
                                        copyUtils.copyTextToClipboard(phoneNumber);
                                        setCopied(true);

                                        setTimeout(() => {
                                            setCopied(false);
                                        }, 3000);
                                    }}
                                >
                                    {phoneNumber}
                                </ContactDetailsButton>
                            </CustomTooltip>
                        </Flex>
                    )}
                    {email && (
                        <>
                            <MarginTop16px />
                            <Flex>
                                <MailOutlineIcon style={{ marginRight: 16 }} />
                                <ContactDetailsAnchor
                                    href={`mailto:${email}`}
                                    color={theme.primary}
                                >
                                    {email}
                                </ContactDetailsAnchor>
                            </Flex>
                        </>
                    )}
                </>
            )}
        </PadddingLeftRight16px>
    );
};

export default ProfileInformationContact;

import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
    AudioConfig,
    SpeechTranslationConfig,
    TranslationRecognizer,
} from 'microsoft-cognitiveservices-speech-sdk';
import { useVMState } from '../containers/main';
import get from 'lodash/get';
import { TARGET_LANGUAGES, useTranslationsStore } from '../../../../../stores/TranslationsStore';
import { getVirtualEventCaptions } from '../../../../../services/api/eureka';

function transformCaptionToMessageMapObj(inputObject) {
    const { id, text, VirtualEventSessionId, UserId } = inputObject;

    let outputObject = {
        VirtualEventSessionId,
        id: `${id}`, // You can generate a unique ID here
        complete: true,
        participantId: UserId,
        translations: {},
    };

    TARGET_LANGUAGES.forEach(language => {
        outputObject.translations[language.value] = text;
    });

    return outputObject;
}

const useSendTranslations = ({ active }) => {
    const {
        sessionId,
        virtualEventUser,
        socket,
        virtualEventSession,
        currentStream,
    } = useVMState();
    const { setMessages } = useTranslationsStore();
    const translationRecognizerRef = useRef(null);
    const speechConfigRef = useRef(null);

    const senderId = get(virtualEventUser, 'data.UserId') || null;
    const isMicrophoneOn = get(virtualEventUser, 'data.isMicrophoneOn') || null;
    const speakingLanguage = get(virtualEventSession, 'data.speakerLanguage') || 'en';
    const VirtualEventSessionId = get(virtualEventSession, 'data.id') || null;

    const targetLanguages = useMemo(() => {
        const VirtualEventUsers = get(virtualEventSession, 'data.VirtualEventUsers') || [];
        const attendeeCaptionLanguages =
            get(virtualEventSession, 'data.attendeeCaptionLanguages') || [];

        const languages = new Set([...attendeeCaptionLanguages, 'en']);
        VirtualEventUsers.forEach(user => {
            languages.add(user.captionTranslationLanguage);
        });
        return Array.from(languages);
    }, [virtualEventSession]);

    const mapTranslationResultToMessage = useCallback(
        (result, complete = false) => {
            const message = {
                objectId: sessionId,
                VirtualEventSessionId,
                id: result.resultId + (result.offset || 0),
                translations: {},
                complete: complete || result.reason === 8,
                participantId: senderId,
            };

            targetLanguages.forEach(language => {
                message.translations[language] = result.translations.get(language);
            });

            return message;
        },
        [senderId, sessionId, VirtualEventSessionId, targetLanguages],
    );

    const createTranslationRecognizer = useCallback(() => {
        if (targetLanguages.length === 0) {
            return;
        }

        const speechConfig = SpeechTranslationConfig.fromSubscription(
            process.env.REACT_APP_AZURE_SPEECH_KEY,
            'northeurope',
        );
        const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
        speechConfig.speechRecognitionLanguage = speakingLanguage || 'en-US';
        targetLanguages.forEach(language => {
            speechConfig.addTargetLanguage(language);
        });

        speechConfigRef.current = speechConfig;

        return new TranslationRecognizer(speechConfig, audioConfig);
    }, [speakingLanguage, targetLanguages]);

    const setupTranslationRecognizer = useCallback(
        translationRecognizer => {
            if (!socket || !translationRecognizer || !isMicrophoneOn) {
                return;
            }

            translationRecognizer.recognizing = (s, e) => {
                const message = mapTranslationResultToMessage(e.result);
                socket.emit('updateTranslations', message);
            };
            translationRecognizer.recognized = (s, e) => {
                if (e.result.reason === 8) {
                    const message = mapTranslationResultToMessage(e.result, true);
                    socket.emit('updateTranslations', message);
                }
            };
            translationRecognizer.canceled = (s, e) => {
                console.log('LOGGER translationRecognizer.canceled ', e);
                translationRecognizer.stopContinuousRecognitionAsync();
            };
            translationRecognizer.sessionStopped = () => {
                translationRecognizer.stopContinuousRecognitionAsync();
            };
        },
        [mapTranslationResultToMessage, socket, isMicrophoneOn],
    );

    const handleUpdateTranslations = useCallback(
        data => {
            if (data.objectId !== sessionId) {
                return;
            }

            setMessages(prevMessages => {
                const newMessages = new Map(prevMessages);
                newMessages.set(data.id, data);
                return newMessages;
            });
        },
        [setMessages, sessionId],
    );

    const handleClearTranslations = useCallback(() => {
        setMessages(new Map());
    }, [setMessages]);

    useEffect(() => {
        if (!socket || !sessionId) {
            return;
        }

        if (VirtualEventSessionId) {
            getVirtualEventCaptions(VirtualEventSessionId).then(captions => {
                const mapMessages = new Map();
                captions.forEach(c => {
                    const message = transformCaptionToMessageMapObj(c);
                    mapMessages.set(message.id, message);
                });
                setMessages(mapMessages);
            });
        }

        if (!active) {
            return;
        }

        socket.emit('joinTranslations', { objectId: sessionId });
        socket.on('updateTranslations', handleUpdateTranslations);
        socket.on('clearTranslations', handleClearTranslations);

        return () => {
            socket.off('clearTranslations', handleClearTranslations);
        };
    }, [sessionId, socket, handleUpdateTranslations, VirtualEventSessionId, active]);

    useEffect(() => {
        if (!active || !currentStream || !currentStream.audioTrack) {
            return;
        }

        const translationRecognizer = createTranslationRecognizer();
        setupTranslationRecognizer(translationRecognizer);

        translationRecognizer.startContinuousRecognitionAsync((_, e) => {
            console.log('LOGGER startContinuousRecognitionAsync error', e);
        });
        translationRecognizerRef.current = translationRecognizer;

        return () => {
            translationRecognizerRef.current.stopContinuousRecognitionAsync();
            translationRecognizerRef.current = null;
        };
    }, [createTranslationRecognizer, setupTranslationRecognizer, active, currentStream]);
};

export default useSendTranslations;

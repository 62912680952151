import get from 'lodash/get';
import merge from 'lodash/merge';
import {
    LOAD_POSTS,
    LOAD_POST,
    ADD_COMMENT,
    ADD_LIKE,
    REMOVE_LIKE,
    REMOVE_POST,
    RESET_SOCIALFEED_STATE,
    ADD_POST,
    UPDATE_POST,
} from '../actions';

const INITIAL_STATE = {
    posts: {},
    users: {},
    comments: {},
    likes: {},
    lastPostId: null,
    error: null,
    loading: false,
    refreshing: false,
};

const socialFeedReducer = (state = INITIAL_STATE, action) => {
    const replace = get(action, 'payload.replace', false);
    let posts = {};
    let comments = {};
    let likes = {};
    let users = {};
    let currentPost = {};
    let toRemove = '';
    let temp = {};
    switch (action.type) {
        case RESET_SOCIALFEED_STATE:
            return INITIAL_STATE;
        case LOAD_POSTS.REQUEST:
            return {
                ...state,
                loading: !replace,
                refreshing: replace,
            };
        case LOAD_POSTS.SUCCESS:
            posts = get(action, 'payload.entities.posts', {});
            comments = get(action, 'payload.entities.comments', {});
            likes = get(action, 'payload.entities.likes', {});
            users = get(action, 'payload.entities.users', {});
            return {
                ...state,
                posts: replace ? posts : merge({ ...state.posts }, posts),
                comments: replace ? comments : merge({ ...state.comments }, comments),
                likes: replace ? likes : merge({ ...state.likes }, likes),
                users: replace ? users : merge({ ...state.users }, users),
                loading: false,
                refreshing: false,
                lastPostId: action.payload.lastPostId,
            };
        case LOAD_POST.SUCCESS:
            posts = get(action, 'payload.entities.posts', {});
            comments = get(action, 'payload.entities.comments', {});
            likes = get(action, 'payload.entities.likes', {});
            users = get(action, 'payload.entities.users', {});
            return {
                ...state,
                posts: merge({ ...state.posts }, posts),
                comments: merge({ ...state.comments }, comments),
                likes: merge({ ...state.likes }, likes),
                users: merge({ ...state.users }, users),
            };
        case REMOVE_POST.FAILURE:
        case REMOVE_LIKE.FAILURE:
        case ADD_LIKE.FAILURE:
        case ADD_COMMENT.FAILURE:
        case ADD_POST.FAILURE:
        case UPDATE_POST.FAILURE:
        case LOAD_POSTS.FAILURE:
        case LOAD_POST.FAILURE:
            return {
                ...state,
                error: action.payload.error,
                loading: false,
                refreshing: false,
            };
        case ADD_COMMENT.SUCCESS:
            currentPost = get(action, 'payload.currentPost');
            comments = get(action, 'payload.entities.comments', {});
            users = get(action, 'payload.entities.users', {});
            temp = {
                ...state,
                comments: merge({ ...state.comments }, comments),
                users: merge({ ...state.users }, users),
            };
            temp.posts[currentPost.id] = currentPost;
            return temp;
        case ADD_LIKE.SUCCESS:
            currentPost = get(action, 'payload.currentPost');
            likes = get(action, 'payload.entities.likes', {});
            users = get(action, 'payload.entities.users', {});
            temp = {
                ...state,
                likes: merge({ ...state.likes }, likes),
                users: merge({ ...state.users }, users),
            };
            temp.posts[currentPost.id] = currentPost;
            return temp;
        case REMOVE_LIKE.SUCCESS:
            currentPost = get(action, 'payload.currentPost');
            toRemove = get(action, 'payload.toRemove');
            temp = { ...state };
            temp.posts[currentPost.id] = currentPost;
            delete temp.likes[toRemove];
            return temp;
        case REMOVE_POST.SUCCESS:
            toRemove = get(action, 'payload.toRemove');
            temp = {
                ...state,
            };
            delete temp.posts[toRemove];
            return temp;
        case ADD_POST.SUCCESS:
            posts = get(action, 'payload.entities.posts', {});
            users = get(action, 'payload.entities.users', {});

            temp = {
                ...state,
                posts: merge(posts, { ...state.posts }),
                users: merge({ ...state.users }, users),
            };
            return temp;
        case UPDATE_POST.SUCCESS:
            currentPost = get(action, 'payload.currentPost');
            temp = {
                ...state,
            };
            temp.posts[currentPost.id].message = currentPost.message;
            temp.posts[currentPost.id].image = currentPost.image;
            return temp;
        default:
            return state;
    }
};

export default socialFeedReducer;

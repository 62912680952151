import { eurekaRequest } from '../../../services/api/eureka';
import { executeListQuery } from '../../../services/api/graphQlRepository';
import { getLocalAppStateAsync } from '../../../services/api/db';
import { APPOINTMENT_STATE } from '../../Talk/constants';

const asyncEurekaRequest = (method, path, data, isAuthenticated) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(method, path, data, isAuthenticated, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
};

class AppointmentService {
    async saveAppointment(data) {
        return await asyncEurekaRequest('post', '/api/entities/appointments', data, true);
    }

    async getAppointmentsByUserId(id) {
        const { eventId } = await getLocalAppStateAsync();

        const appointments = await asyncEurekaRequest(
            'get',
            `/api/entities/appointments/userId/${id}/event/${eventId}`,
            null,
            true,
        );

        return appointments.filter(appointment => !appointment.deleted);
    }

    async getAppointmentByReference(id) {
        return await asyncEurekaRequest(
            'get',
            `/api/entities/appointments/reference/${id}`,
            null,
            true,
        );
    }

    async getAppointmentsByUserIdAndMemberId(id, memberId) {
        const appointments = await this.getAppointmentsByUserId(id);

        return appointments.filter(appointment => {
            const { members } = appointment;

            return members.find(member => member.id === memberId);
        });
    }

    async getAppointmentById(id) {
        return await asyncEurekaRequest('get', `/api/entities/appointments/${id}`, null, true);
    }

    async getAppointmentsByMemberId(id) {
        const { eventId } = await getLocalAppStateAsync();

        const appointments = await asyncEurekaRequest(
            'get',
            `/api/entities/appointments/memberId/${id}/event/${eventId}`,
            null,
            true,
        );

        return appointments.filter(appointment => !appointment.deleted);
    }

    // Get all appointments: created by user + to which he was invited
    async getAllAppointments(id) {
        const createdAppointments = await this.getAppointmentsByUserId(id);
        const invitedAppointments = await this.getAppointmentsByMemberId(id);

        return [...createdAppointments, ...invitedAppointments];
    }

    async acceptAppointment(id, eventId) {
        return await asyncEurekaRequest(
            'post',
            '/api/entities/appointments/accept',
            { eventId, appointmentEurekaId: id },
            true,
        );
    }

    async declineAppointment(id, eventId) {
        return await asyncEurekaRequest(
            'post',
            '/api/entities/appointments/decline',
            { eventId, appointmentEurekaId: id },
            true,
        );
    }

    async removeAppointment(id) {
        return await asyncEurekaRequest('delete', `/api/entities/appointments/${id}`, null, true);
    }

    async createVirtualRoom(data) {
        return await asyncEurekaRequest(
            'post',
            '/api/entities/virtualEventSession/appointment',
            data,
            true,
        );
    }

    async addUser(data) {
        return await asyncEurekaRequest('post', '/api/entities/invites/appointments', data, true);
    }

    async cancelAppointment(id, eventId) {
        return await asyncEurekaRequest(
            'put',
            `/api/entities/appointments/cancel/${id}`,
            { eventId },
            true,
        );
    }

    async deleteAppointment(id) {
        return await asyncEurekaRequest('delete', `api/entities/appointments/${id}`, null, true);
    }

    async getAppointmentType(eventId) {
        const results = await executeListQuery('findTypes', {
            singular: {
                contains: 'ppointment', // Support both 'Appointment' and 'appointment' cases
            },
        });

        if (results && results.length) {
            return results[0];
        }

        return null;
    }
}

export default new AppointmentService();

import React from 'react';
import styled from 'styled-components';

import { openExternalLink } from '../../ExhibitorPage/common';
import { Tooltipped } from 'react-md';
import facebookIcon from '../../../assets/social/icon-filled-facebook.png';
import twitterIcon from '../../../assets/social/icon-filled-twitter.png';
import linkedinIcon from '../../../assets/social/icon-filled-linkedin.png';

import {
    ContactButton,
    ContactButtonIcon,
    ContactButtonsWrapper,
} from '../../ExhibitorPage/components/content/Contact';

const Container = styled.div`
    padding: 16px;
`;

const ContactTab = props => {
    const { item } = props;
    const params = item?.params;
    const {
        contactPhoneNumber,
        contactEmail,
        contactFacebookUrl,
        contactTwitterUrl,
        contactLinkedinUrl,
    } = params;
    const hasContactInfo =
        !!contactEmail ||
        !!contactFacebookUrl ||
        !!contactLinkedinUrl ||
        !!contactPhoneNumber ||
        !!contactTwitterUrl;

    const onPhoneIconClick = phoneNumber => {
        window.open(`tel:${phoneNumber}`, '_blank');
    };

    const onEmailIconClick = email => {
        window.open(`mailto:${email}`, '_blank');
    };

    const onLinkIconClick = url => {
        openExternalLink(url);
    };

    const renderContactSection = () => {
        if (!hasContactInfo) {
            return;
        }

        return (
            <Container>
                <ContactButtonsWrapper>
                    {contactPhoneNumber && (
                        <Tooltipped
                            label={`Call ${contactPhoneNumber}`}
                            position="right"
                            delay={200}
                        >
                            <ContactButton style={{ position: 'relative' }}>
                                <ContactButtonIcon
                                    active
                                    onClick={() => onPhoneIconClick(contactPhoneNumber)}
                                >
                                    call
                                </ContactButtonIcon>
                            </ContactButton>
                        </Tooltipped>
                    )}
                    {contactEmail && (
                        <ContactButton onClick={() => onEmailIconClick(contactEmail)}>
                            <ContactButtonIcon>email</ContactButtonIcon>
                        </ContactButton>
                    )}
                    {contactFacebookUrl && (
                        <ContactButton onClick={() => onLinkIconClick(contactFacebookUrl)}>
                            <img src={facebookIcon} alt="Facebook" />
                        </ContactButton>
                    )}
                    {contactTwitterUrl && (
                        <ContactButton onClick={() => onLinkIconClick(contactTwitterUrl)}>
                            <img src={twitterIcon} alt="Twitter" />
                        </ContactButton>
                    )}
                    {contactLinkedinUrl && (
                        <ContactButton onClick={() => onLinkIconClick(contactLinkedinUrl)}>
                            <img src={linkedinIcon} alt="LinkedIn" />
                        </ContactButton>
                    )}
                </ContactButtonsWrapper>
            </Container>
        );
    };

    return <>{renderContactSection()}</>;
};

export default ContactTab;

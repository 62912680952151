import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';

import switchingPreventService from '../services/SwitchingPreventService';
import { getPollSetByExternalObject } from '../../Votes/services/PollingService';
import { stopPreRecordings, createRtmpPlayer } from '../services/VirtualEventSession';

const onMakePrerecordingActive = async (stateCtx, mutationCtx, providedIndex, videoUrlIndex) => {
    const { externalObject, virtualEventSession, sessionId, socket } = stateCtx;

    const cloneVirtualEventSession = cloneDeep(virtualEventSession);
    const virtualEventUsers = cloneVirtualEventSession.data.VirtualEventUsers;
    const pollSets = await getPollSetByExternalObject(externalObject.data.id);
    const activePoll = pollSets.Polls.find(poll => poll.active);
    let newActiveVideo;

    if (isNumber(providedIndex)) {
        const virtualUserIndex = providedIndex;
        const hasVideoActiveNew = !virtualEventUsers[virtualUserIndex].hasVideoActive;

        if (hasVideoActiveNew) {
            virtualEventUsers.forEach(virtualEventUser => {
                virtualEventUser.isActive = false;
                virtualEventUser.hasVideoActive = false;
            });
        }

        virtualEventUsers[virtualUserIndex].hasVideoActive = hasVideoActiveNew;
        virtualEventUsers[virtualUserIndex].isPrerecording = false;

        cloneVirtualEventSession.data.activeVideoId = null;
        cloneVirtualEventSession.data.VirtualEventUsers = virtualEventUsers;
    } else {
        const videoUrls = get(cloneVirtualEventSession, 'data.videoUrls') || [];
        const activeVideoId = cloneVirtualEventSession.data.activeVideoId;
        const newActiveVideoId =
            videoUrls[videoUrlIndex].id !== activeVideoId ? videoUrls[videoUrlIndex].id : null;
        if (newActiveVideoId) {
            newActiveVideo = videoUrls[videoUrlIndex];
        }

        virtualEventUsers.forEach(virtualEventUser => {
            virtualEventUser.isActive = false;
            virtualEventUser.hasVideoActive = false;
        });

        cloneVirtualEventSession.data.activeVideoId = newActiveVideoId;
        cloneVirtualEventSession.data.VirtualEventUsers = virtualEventUsers;
    }

    const extraState = {
        switchingPreRecording: true,
        primaryVideoDuration: 0,
        secondaryVideoDuration: 0,
        duration: null,
        playedSeconds: 2,
        primaryPlayerRef: null,
        secondaryPlayerRef: null,
    };

    mutationCtx.setExtraState(extraState);

    switchingPreventService.preventBroadcasting();

    mutationCtx.setSwitchingPreRecording(false);

    await stopPreRecordings(sessionId);

    if (newActiveVideo && newActiveVideo.isRTMP) {
        await createRtmpPlayer(sessionId, {
            playUrl: newActiveVideo.rtmpURL,
            uid: newActiveVideo.uid,
        });
    }

    if (isNumber(providedIndex)) {
        const virtualEventUser = virtualEventUsers[providedIndex];

        Object.assign(virtualEventUser, {
            hasVideoActive: virtualEventUser.hasVideoActive,
            isPrerecording: false,
        });

        cloneVirtualEventSession.data.activeVideoId = null;
    }

    switchingPreventService.allowBroadcasting();

    // Hide vote results when switching active users
    if (cloneVirtualEventSession.data && cloneVirtualEventSession.data.showVoteResults) {
        cloneVirtualEventSession.data.showVoteResults = false;
    }

    const newVirtualEventUsers = (
        cloneVirtualEventSession.data.VirtualEventUsers || []
    ).map(virtualEventUser =>
        pick(virtualEventUser, ['id', 'isActive', 'hasVideoActive', 'isPrerecording']),
    );

    const newVirtualEventSession = {
        VirtualEventUsers: newVirtualEventUsers,
        activeVideoId: cloneVirtualEventSession.data.activeVideoId,
        showVoteResults: cloneVirtualEventSession.data.showVoteResults,
    };

    socket.emit('updateData', {
        objectId: sessionId,
        virtualEventSession: newVirtualEventSession,
        updateCall: true,
    });

    stateCtx.socket.emit('updatePollSet', {
        objectId: sessionId,
        externalObjectId: externalObject.data.id,
        state: 'closed',
        deActivatePoll: true,
        pollChange: {
            id: get(activePoll, 'id'),
            active: false,
        },
    });

    mutationCtx.setActivePoll(null);
};

export default onMakePrerecordingActive;
